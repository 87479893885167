export class Theme {
  themeName: string = '';
  primaryColor: string = '';
  accentColor: string = '';
  themeVersion: string = '';
  footer: string = '';

  constructor(configuration?: any) {
    if (configuration) {
      if (configuration.themeName !== undefined) {
        this.themeName = configuration.themeName;
      }
      if (configuration.primaryColor !== undefined) {
        this.primaryColor = configuration.primaryColor;
      }
      if (configuration.accentColor !== undefined) {
        this.accentColor = configuration.accentColor;
      }
      if (configuration.themeVersion !== undefined) {
        this.themeVersion = configuration.themeVersion;
      }
      if (configuration.footer !== undefined) {
        this.footer = configuration.footer;
      }
    }
  }
}

export interface CompanyTheme {
  rootCompanyId?: string;
  configurationName: string;
  themeName: string;
  primaryColor: string;
  accentColor: string;
  footer: string;
  logos: Logos[];
}

export interface Logos {
  size: Size;
  format: string;
  image: string;
}

export interface Size {
  type: string;
  length: number;
  width: number;
  height: number;
}