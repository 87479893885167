















































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import TravelPolicyCarStore from '@/modules/settings/travel-policy/travel-policy-car.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import SettingsStore from '@/modules/settings/settings.store';
import { CountryContinentModel } from '@/api/dictionary/dictionary.model';
import { CarCategory } from '@/const/travel-policy.const';
import { RentalCompany } from '@/api/travel-policy/travel-policy.model';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import _  from 'lodash';
import { translate } from '@/i18n';
import { TravellerGroupOptions } from '@/const/profile-personal.const';
import { TravellerCategoriesApi } from '@/api/profile/traveller-categories.api';

@Component({})
export default class TravelPolicyCarManageRule extends Vue {
  isLoadingRental: boolean = false;
  isLoadingCountry: boolean = false;
  formPending: boolean = false;
  rentalCompanies: RentalCompany[] = [];
  geographicalAreas: CountryContinentModel[] = [];
  form = {
    id: this.generateUUID(),
    priority: 1,
    rentalCompanies: [],
    carCategories: [],
    geographicalAreas: [],
    travellerCategories: [],
    isRentalCompanyEnabled: false,
    isCarCategoriesEnabled: false,
    isGeographicalAreaEnabled: false,
    isTravellerCategoriesEnabled: false,
    status: '',
  };
  formHasError: boolean = false;
  serverErrors: any[] = [];
  loadErrors: any[] = [];
  currentService: string = 'Car';
  travellerCategoriesOptions: any[] = [];
  $v;

  get isReadOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get selectedRule() {
    return TravelPolicyCarStore.currentSelectedRules;
  }

  get carCategoryList() {
    return CarCategory;
  }

  get allRules() {
    return TravelPolicyCarStore.getCarRules;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get rootCompanyId() {
    if (!this.currentCompany) {
      return this.$route.params.id;
    }

    return this.currentCompany.rootCompanyId;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigName() {
    return TravelPolicyStore.currentConfigName;
  }

  get ruleExist() {
    if (this.selectedRule) {
      return Object.keys(this.selectedRule).length === 0 && this.selectedRule.constructor === Object;
    }
  }

  get allRentalCompanies() {
    return TravelPolicyCarStore.allRentalCompanies;
  }

  get allGeographicalAreas() {
    return this.geographicalAreas.map((e, index) => { return {...e, uniqId: e.code ? index + e.code : index + e.name }; });
  }

  get travellerGroupOptions() {
    return TravellerGroupOptions;
  }

  linkClicked(e) {
    e.preventDefault();
  }

  returnToConfiguration() {
    TravelPolicyStore.setIsFromManage(true);
    TravelPolicyStore.setCurrentSelectedItem('Car');
    router.push({
      name: 'travel-policy-configuration',
      params: {
        configurationId: this.currentConfigurationId,
      },
    });
  }

  isFormFilled() {
    return (this.form.isRentalCompanyEnabled && this.form.rentalCompanies && this.form.rentalCompanies.length) ||
    (this.form.isCarCategoriesEnabled && this.form.carCategories && this.form.carCategories.length) ||
    (this.form.isGeographicalAreaEnabled && this.form.geographicalAreas && this.form.geographicalAreas.length) ||
    (this.form.isTravellerCategoriesEnabled && this.form.travellerCategories && this.form.travellerCategories.length);
  }

  isFormSufficient() {
    return ((this.form.isRentalCompanyEnabled ? !!(this.form.rentalCompanies && this.form.rentalCompanies.length) : false) ||
    (this.form.isCarCategoriesEnabled ? !!(this.form.carCategories && this.form.carCategories.length) : false) ||
    (this.form.isGeographicalAreaEnabled ? !!(this.form.geographicalAreas && this.form.geographicalAreas.length) : false) ||
    (this.form.isTravellerCategoriesEnabled ? !!(this.form.travellerCategories && this.form.travellerCategories.length) : false));
  }

  @Validation()
  validationObject() {
    return {
      form: {
        rentalCompanies: {
          required: requiredIf(() => {
            return !this.isFormFilled();
          }),
        },
        carCategories: {
          required: requiredIf(() => {
            return !this.isFormFilled();
          }),
        },
        geographicalAreas: {
          required: requiredIf(() => {
            return !this.isFormFilled();
          }),
        },
        travellerCategories: {
          required: requiredIf(() => {
            return !this.isFormFilled();
          })
        },
        sufficient: () => {
          return this.isFormSufficient();
        }
      },
    };
  }

  checkForm() {
    this.formHasError = false;
    this.$v.form.$touch();
    if (this.$v.form.$pending || this.$v.form.$error) {
      this.formHasError = true;
    }
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  mapFormData(data?: any) {
    this.form = {
      id: data && data.id ? data.id : this.generateUUID(),
      priority: data && data.rulePriority ? data.rulePriority : 1,
      rentalCompanies: data && data.rentalCompanies ? data.rentalCompanies : [],
      carCategories: data && data.carCategories ? data.carCategories : [],
      geographicalAreas: data && data.geographicalAreas ? data.geographicalAreas : [],
      isRentalCompanyEnabled: !!(data && data.rentalCompanies && data.rentalCompanies.length),
      isCarCategoriesEnabled: !!(data && data.carCategories && data.carCategories.length),
      isGeographicalAreaEnabled: !!(data && data.geographicalAreas && data.geographicalAreas.length),
      status: data && data.status === '' ? TravelPolicyCarStore.draftEditStatus : TravelPolicyCarStore.draftNewStatus,
      isTravellerCategoriesEnabled: !!(data && data.travellerCategories && data.travellerCategories.length),
      travellerCategories: data && data.travellerCategories && data.travellerCategories.length ? data.travellerCategories.map(e => {
        let index = this.travellerCategoriesOptions.findIndex(elem => {
          return elem.value === e;
        });
        if (-1 < index) {
          return this.travellerCategoriesOptions[index];
        }
      }) : []
    } as any;
  }

  createRequest() {
    let request = {
      id: this.form.id,
      rulePriority: this.form.priority,
      rentalCompanies: this.form.isRentalCompanyEnabled ? this.form.rentalCompanies : [],
      carCategories: this.form.isCarCategoriesEnabled ? this.form.carCategories : [],
      geographicalAreas: this.form.isGeographicalAreaEnabled ? this.form.geographicalAreas : [],
      travellerCategories: this.form.isTravellerCategoriesEnabled ? this.form.travellerCategories.map((e: any) => e.value) : [],
      status: TravelPolicyCarStore.draftNewStatus,
    };

    if (this.allRules && this.allRules.length) {
      let isEdit = _.filter(this.allRules, (rule) => {
        return rule.id === request.id;
      });

      if (isEdit.length) {
        TravelPolicyCarStore.editCarRule(request);
      } else {
        TravelPolicyCarStore.addCarRule(request);
      }
    } else {
        TravelPolicyCarStore.addCarRule(request);
    }
  }

  confirmAndBack() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.returnToConfiguration();
    }
  }

  confirmAndAddNext() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.mapFormData();
      this.$v.form.$reset();
      this.formPending = false;

      let obj = {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('settings-travel-policy.rule-added')
      };
      EventBus.$emit('show-toast', obj);
    }
  }

  @Watch('selectedRule', {deep: true,  immediate: true})
  existSelectedRule() {
    if (!this.selectedRule) {
      router.go(-1);
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoadingCountry',
  })

  async findCountry(query: string) {
    if (query && query.length > 2) {

      const response = await DictionaryApi.getCountriesContinentsNames(query);

      if (response && response.data) {
        this.geographicalAreas = [];
        this.geographicalAreas = response.data;
        this.isLoadingCountry = false;
      }
    } else {
      this.isLoadingCountry = false;
      this.geographicalAreas = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoadingRental',
  })

  async findRentalCompany(query: string) {
    if (query && query.length > 1) {
      query = query.toLowerCase();

      if (this.allRentalCompanies) {
        const response = this.allRentalCompanies.filter((company: RentalCompany) => company.code.toLowerCase().includes(query) || company.name.toLowerCase().includes(query));

        this.rentalCompanies = [];

        if (response) {
          response.forEach((element) => {
            this.rentalCompanies.push(element);
          });
        }
        this.isLoadingRental = false;
      }
    } else {
      this.isLoadingRental = false;
      this.rentalCompanies = [];
    }
  }

  async created() {
    try {
      const response = await TravellerCategoriesApi.getCompanyTravellerCategories(this.rootCompanyId);

      this.travellerCategoriesOptions = response.data.map(e => {
        return {
          label: e.name,
          value: e.name,
        };
      });
    } catch (error) {
      this.loadErrors = this.$handleErrors(error, true);
      return;
    }
    if (this.selectedRule) {
      let data = JSON.parse(JSON.stringify(this.selectedRule));
      this.mapFormData(data);
    } else {
      this.mapFormData();
    }
  }
}
