export enum Permission {
  AccessSettings = 'AccessSettings',
  ReadProfilePersonal = 'ReadProfilePersonal',
  ManageProfile = 'ManageProfile',
  ReadProfilePermissions = 'ReadProfilePermissions',
  ReadApprovalWorkflow = 'ReadApprovalWorkflow',
  WriteCompanyStructure = 'WriteCompanyStructure',
  ReadCompanyStructure = 'ReadCompanyStructure',
  ReadFlightProviders = 'ReadFlightProviders',
  ReadGDSConfiguration = 'ReadGDSConfiguration',
  ReadTravelPolicy = 'ReadTravelPolicy',
  ReadCustomFieldDefinitionsConfiguration = 'ReadCustomFieldDefinitions',
  WriteApprovalWorkflow = 'WriteApprovalWorkflow',
  WriteFlightProviders = 'WriteFlightProviders',
  WriteGDSConfiguration = 'WriteGDSConfiguration',
  WriteTravelPolicy = 'WriteTravelPolicy',
  WriteCustomFieldDefinitionsConfiguration = 'WriteCustomFieldDefinitions',
  ReadCommunity = 'ReadCommunity',
  Client = 'Client',
  ReadNegoFare = 'ReadNegoFare',
  WriteNegoFare = 'WriteNegoFare',
  ReadTrip = 'ReadTrip',
  ReadCarProviders = 'ReadCarProviders',
  WriteCarProviders = 'WriteCarProviders',
  WriteProjects = 'WriteProjects',
  ReadProjects = 'ReadProjects',
  ReadExpensePolicyDefinition = 'ReadExpensePolicyDefinition',
  WriteExpensePolicyDefinition = 'WriteExpensePolicyDefinition',
  ReadPaymentOptionDefinition = 'ReadPaymentOptionDefinition',
  WritePaymentOptionDefinition = 'WritePaymentOptionDefinition',
  ReadAccommodationProviders = 'ReadAccommodationProviders',
  WriteAccommodationProviders = 'WriteAccommodationProviders',
  ReadRailProviders = 'ReadRailProviders',
  WriteRailProviders = 'WriteRailProviders',
  ReadCompanyInfo = 'ReadCompanyInfo',
  WriteCompanyInfo = 'WriteCompanyInfo',
  BookTrip = 'BookTrip',
  WriteExpense = 'WriteExpense',
  WriteMissionDefinition = 'WriteMissionDefinition',
  ReadExpense = 'ReadExpense',
  ReadMissionDefinition = 'ReadMissionDefinition',
  ReadNotifications = 'ReadNotifications',
  ViewPendingMissionApprovalTask = 'ViewPendingMissionApprovalTask',
  ValidateMission = 'ValidateMission',
  ReadBackOfficeConfiguration = 'ReadBackOfficeConfiguration',
  WriteBackOfficeConfiguration = 'WriteBackOfficeConfiguration',
  ReadPnrRemarks = 'ReadPnrRemarks',
  WritePnrRemarks = 'WritePnrRemarks',
  ReadCompanyPaymentCards = 'ReadCompanyPaymentCards',
  WriteCompanyPaymentCards = 'WriteCompanyPaymentCards',
  ReadPaymentMethodConfiguration = 'ReadPaymentMethodConfiguration',
  WritePaymentMethodConfiguration = 'WritePaymentMethodConfiguration',
  ReadCompanyTheme = 'ReadCompanyTheme',
  WriteCompanyTheme = 'WriteCompanyTheme',
  CanDeleteCompany = 'CanDeleteCompany',
  EditProfileSyncConfiguration = 'CanEditProfilesSynchronization',
  ShowTripList = 'ShowTripList',
  ShowPendingValidationTripList = 'ShowPendingValidationTripList',
  CanEditBookingFee = 'CanEditBookingFee',
  CanEditPlugins = 'CanEditPlugins',
  CanEditSecurityConfiguration = 'CanEditSecurityConfiguration',
  CanEditCurrencyConfiguration = 'CanEditCurrencyConfiguration',
  CanEditProvidersNotificationConfigurations = 'CanEditProvidersNotificationConfigurations',
  CanEditFeesConfiguration = 'CanEditFeesConfiguration',
  CanEditAgencyMarkupsConfiguration = 'CanEditAgencyMarkupsConfiguration',
  CanEditSystemMarkupConfiguration = 'CanEditSystemMarkupConfiguration',
  CanEditClientEmailNotifications = 'CanEditClientEmailNotifications',
  CanEditFareMapping = 'CanEditFareMapping',
  CanAssignFareMapping = 'CanAssignFareMapping',
  CanSynchProviderBookingData = 'CanSynchProviderBookingData',
  CanEditPaymentGateways = 'CanEditPaymentGateways',
  CanBookWithoutConsent = 'CanBookWithoutConsent',
  CanChangePaymentMethodForHeldBooking = 'CanChangePaymentMethodForHeldBooking',
  CanEditDisplayPreferences = 'CanEditDisplayPreferences',
  CanEditVirtualCards = 'CanEditVirtualCards',
  CanEditMissedSavings = 'CanEditMissedSavings',
  CanAssignMissedSavings = 'CanAssignMissedSavings',
  CanAssignVirtualCardsConfiguration = 'CanAssignVirtualCardsConfiguration',
  CanAccessGlobalSettings = 'CanAccessGlobalSettings',
  CanEditTravellerCategories = 'CanEditTravellerCategories',
  CanViewTripComments = 'CanViewTripComments',
  CanAddTripComments = 'CanAddTripComments',
  CanAssignDesignatedTravellersForApprovalToProfile = 'CanAssignDesignatedTravellersForApprovalToProfile',
  CanViewDesignatedTravellersForApprovalInProfile = 'CanViewDesignatedTravellersForApprovalInProfile',
  CanAssignDesignatedApproversToProfile = 'CanAssignDesignatedApproversToProfile',
  CanViewDesignatedApproversInProfile = 'CanViewDesignatedApproversInProfile',
  CanEditDelayTicketing = 'CanEditDelayTicketing',
  CanEditBackOfficeCustomFields = 'CanEditBackOfficeCustomFields',
  CanAssignBackOfficeCustomFields = 'CanAssignBackOfficeCustomFields',
}