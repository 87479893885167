















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import AssignTravellersToRoom from './AssignTravellersToRoom.vue';


@Component({
  components: {
    AssignTravellersToRoom,
  },
})
export default class AssignTravellersToRooms extends Vue {
  @Prop() rooms!: any[];
  @Prop() travellers!: any[];



  get availableTravellers() {
    return this.travellers.filter(traveller => {
      return !this.rooms.find(room => !!room.slots
        .find(slot => slot.profileId === traveller.id)
      );
    });
  }



  change(value) {
    this.$emit('change', value);
  }

  addSlot(params) {
    this.$emit('add-slot', params);
  }

  removeSlot(params) {
    this.$emit('remove-slot', params);
  }
}

