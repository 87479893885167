import TravelAssignment from '@/modules/settings/custom-fields-configuration/TravelAssignment.vue';

export default {
  path: ':configurationId?/travel-assignment/:assignmentId?',
  name: 'travel-assignment',
  component: TravelAssignment,
  displayName: 'Travel assignment',
  meta: {
    permission: 'ReadCustomFieldDefinitions',
    parentName: 'custom-fields',
    depthCategory: 'custom-fields',
    introClass: 'intro-settings',
    depth: 3,
  }
};