














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';

import BasketStore from '@/modules/basket/basket.store';

@Component({})
export default class SabreAncillary extends Vue {
  @Prop() offer!: any;
  @Prop() profileId!: any;
  
  get isSelected() {
    return !!BasketStore.selectedAncillaries.find(offer => {
      return offer.profileId === this.profileId &&
        offer.id === this.offer.id;
    });
  }

  set isSelected(value) {
    BasketStore.setAncillarySelected({
      offer: this.offer,
      value,
      profileId: this.profileId,
    });
  }

  get isMandatory() {
    return this.offer.isMandatory;
  }

  get isLinkVisible() {
    return !!this.offer.purchaseUrl;
  }

  get isAlreadyBooked() {
    return this.offer.isAlreadyBooked;
  }

  get shouldBeDisabled() {
    return this.offer.shouldBeDisabled;
  }

  get tooltipContent() {
    if (this.shouldBeDisabled) {
      return this.offer.reasonOfDisabledStatus;
    }

    return '';
  }

  get purchaseUrl() {
    if (!this.isLinkVisible) {
      return '';
    }

    return sanitizeUrl(this.offer.purchaseUrl);
  }

  get isEasyJetOffer() {
    return this.offer.airlineCode === 'U2';
  }

  get isExcessBaggage3kgAncillary() {
    return this.offer.id === 'offer_soe_ancillary_4';
  }
}

