






















































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';

import AccountStore from '@/store/account.store';
import { HomeApi } from '@/api/home/home.api';
import DisplayPreferencesFlightStore from '@/modules/settings/display-preferences/display-preferences-flight.store';
import DisplayPreferencesStore from '@/modules/settings/display-preferences/display-preferences.store';
import SettingsStore from '@/modules/settings/settings.store';
import DictionaryStore from '@/store/dictionary.store';
import { 
  AirLine,
  AirportLocationTypes,
  GeographicalAreaTypes,
  LanguageCode,
 } from '@/api/dictionary/dictionary.model';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { TravellerGroupOptions } from '@/const/profile-personal.const';

const priceRegex = value => {
  return /^(\d+)(\.\d{1,2})?$/.test(value);
};

const zeroesRegex = value => {
  return /[^0]+/.test(value);
};

@Component({})
export default class DisplayPreferencesFlightManageRule extends Vue {
  isLoading: boolean = false;
  formPending: boolean = false;
  airLines: AirLine[] = [];
  form: any = {
    id: this.generateUUID(),
    operatingAirline: null,
    isAirlineEnabled: true,
    isMixedConnection: false,
    status: '',
    isRoutingEnabled: false,
    routings: [],
    isGeoAreaEnabled: false,
    geographicalAreas: [],
  };
  isRoutingFromLoading: boolean = false;
  isRoutingToLoading: boolean = false;
  isGeoAreaFromLoading: boolean = false;
  isGeoAreaToLoading: boolean = false;
  fromLocationOptions: any[] = [];
  toLocationOptions: any[] = [];
  geoAreaLocationOptions: any[] = [];
  formHasError: boolean = false;
  dropdownClassFix: boolean = false;
  $v;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  currencyCodes: any[] = [];
  loadErrors: any[] = [];

  get isReadOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get allAirlines() {
    return DictionaryStore.allAirLines;
  }

  get selectedRule() {
    return DisplayPreferencesFlightStore.currentSelectedRules;
  }

  get allRules() {
    return DisplayPreferencesFlightStore.getFlightRules;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigName() {
    return DisplayPreferencesStore.currentConfigName;
  }

  get ruleExists() {
    if (this.selectedRule) {
      return !(Object.keys(this.selectedRule).length === 0 && this.selectedRule.constructor === Object);
    }

    return false;
  }

  get geoAreasFilled() {
    return !!(this.form.geographicalAreas[this.form.geographicalAreas.length - 1].from && this.form.geographicalAreas[this.form.geographicalAreas.length - 1].to);
  }

  get routingsFilled() {
    return !!(this.form.routings[this.form.routings.length - 1].from && this.form.routings[this.form.routings.length - 1].to);
  }

  get travellerGroupOptions() {
    return TravellerGroupOptions;
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get isFormFilled() {
    return !!(this.form.operatingAirlines && this.form.operatingAirlines.length &&
      (!this.form.isRoutingEnabled || (this.form.routings && this.mapRoutingsForRequest(this.form.routings))) &&
      (!this.form.isGeoAreaEnabled || (this.form.geographicalAreas && this.mapGeographicalAreasForRequest(this.form.geographicalAreas))));
  }



  mapGeographicalAreas(areas: any[]) {
    if (!areas || !areas.length) {
      return [
        {
          from: null,
          to: null,
        }
      ];
    } else {
      return areas;
    }
  }

  mapRoutings(routings: any[]) {
    if (!routings || !routings.length) {
      return [
        {
          from: null,
          to: null,
        }
      ];
    } else {
      return routings;
    }
  }

  mapGeographicalAreasForRequest(areas: any[] | null) {
    if (!areas) {
      return;
    }
    const areasMapped = areas.map(e => {
      return (e.from && e.to) ?
      { 
        From: {
          code: e.from.code,
          type: GeographicalAreaTypes[e.from.type]
        },
        to: {
          code: e.to.code,
          type: GeographicalAreaTypes[e.to.type]
        }
      } : null;
    });
    const areasFiltered = areasMapped.filter(Boolean);
    return areasFiltered.length ? areasFiltered : null;
  }

  mapRoutingsForRequest(routings: any[]) {
    const routingsMapped = routings.map(e => {
      return (e.from && e.to) ?
      {
        from: {
          code: e.from.type === 'CityAirport' ? (e.from.airportCode || e.from.code) : (e.from.cityCode || e.from.code),
          type: AirportLocationTypes[e.from.type]
        },
        to: {
          code: e.to.type === 'CityAirport' ? (e.to.airportCode || e.to.code) : (e.to.cityCode || e.to.code),
          type: AirportLocationTypes[e.to.type]
        }
      }
      : null;
    });
    const routingsFiltered = routingsMapped.filter(Boolean);
    return routingsFiltered.length ? routingsFiltered : null;
  }

  returnToConfiguration() {
    DisplayPreferencesStore.setIsFromManage(true);
    router.push({
      name: 'display-preferences-configuration',
      params: {
        configurationId: this.currentConfigurationId,
      },
    });
  }

  isRouteRuleValid(route) {
    return route && route.from && route.to;
  }

  @Validation()
  validationObject() {
    return {
      form: {
        operatingAirlines: {
          required: requiredIf(() => {
            return !this.isFormFilled;
          }),
        },
        routings: {
          $each: {
            from: {
              required: requiredIf(() => {
                return this.form.isRoutingEnabled;
              }),
            },
            to: {
              required: requiredIf(() => {
                return this.form.isRoutingEnabled;
              }),
            },
          },
        },
        geographicalAreas: {
          $each: {
            from: {
              required: requiredIf(() => {
                return this.form.isGeoAreaEnabled;
              }),
            },
            to: {
              required: requiredIf(() => {
                return this.form.isGeoAreaEnabled;
              }),
            },
          },
        },
      },
    };
  }

  colorStyles(option) {
    return {
      backgroundColor: option && option.color ? option.color : '',
    };
  }

  checkForm() {
    this.formHasError = false;
    this.$v.form.$touch();
    if (this.$v.form.$pending || this.$v.form.$error) {
      this.formHasError = true;
    }
  }

  generateUUID() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  mapFormData(data?: any) {
    let routings = this.mapRoutings([]);
    let geographicalAreas = this.mapGeographicalAreas([]);
    let isRoutingEnabled = false;
    let isGeoAreaEnabled = false;
    let operatingAirlines = [];
    let isAirlineEnabled = true;
    let isMixedConnection = false;
    let status = DisplayPreferencesFlightStore.draftNewStatus;

    if (data) {
      operatingAirlines = data.operatingAirlines || [];
      isMixedConnection = data.isMixedConnection;

      if (data.status === '') {
        status = DisplayPreferencesFlightStore.draftEditStatus;
      }

      if (data.airportRoutings && data.airportRoutings.length) {
        routings = this.mapRoutings(data.airportRoutings);
        isRoutingEnabled = true;
      }

      if (data.geographicalRoutings && data.geographicalRoutings.length) {
        geographicalAreas = this.mapGeographicalAreas(data.geographicalRoutings);
        isGeoAreaEnabled = true;
      }
    }

    this.form = {
      id: data && data.id ? data.id : this.generateUUID(),
      operatingAirlines,
      isAirlineEnabled,
      status,
      isRoutingEnabled,
      isMixedConnection,
      routings,
      isGeoAreaEnabled,
      geographicalAreas,
    } as any;
  }

  createRequest() {

    let request = {
      id: this.form.id,
      operatingAirlines: this.form.isAirlineEnabled && this.form.operatingAirlines && this.form.operatingAirlines.length ? this.form.operatingAirlines : null,
      status: DisplayPreferencesFlightStore.draftNewStatus,
      airportRoutings: this.form.isRoutingEnabled ? this.form.routings : null,
      isMixedConnection: this.form.isMixedConnection,
      geographicalRoutings: this.form.isGeoAreaEnabled ? this.form.geographicalAreas : null,
    };


    if (this.allRules && this.allRules.length) {
      let isEdit = this.allRules.filter((rule) => {
        return rule.id === request.id;
      });

      if (isEdit.length) {
        DisplayPreferencesFlightStore.editFlightRule(request);
      } else {
        DisplayPreferencesFlightStore.addFlightRule(request);
      }
    } else {
        DisplayPreferencesFlightStore.addFlightRule(request);
    }
  }

  confirmAndBack() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.returnToConfiguration();
    }
  }

  confirmAndAddNext() {
    this.checkForm();
    if (!this.formHasError) {
      this.formPending = true;
      this.createRequest();
      this.mapFormData();
      this.$v.form.$reset();
      this.formPending = false;

      let obj = {
        type: translate('settings-travel-policy.success'),
        title: '',
        message: translate('settings-travel-policy.rule-added')
      };
      EventBus.$emit('show-toast', obj);
    }
  }

  @Watch('selectedRule', {deep: true,  immediate: true})
  existSelectedRule(value) {
    if (!value) {
      router.push('display-preferences-configuration');
    }
  }

  addRouting() {
    if (this.routingsFilled && this.form.isRoutingEnabled) {
      this.form.routings.push({ from: null, to: null });
    }
  }

  removeRouting(index) {
    this.form.routings.splice(index, 1);
  }

  addGeoArea() {
    if (this.geoAreasFilled && this.form.isGeoAreaEnabled) {
      this.form.geographicalAreas.push({ from: null, to: null });
    }
  }

  removeGeoArea(index) {
    this.form.geographicalAreas.splice(index, 1);
  }

  clearGeoAreas() {
    this.dropdownClassFix = false;
    this.geoAreaLocationOptions = [];
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findAirLines(query: string) {
    if (query && query.length > 1) {
      this.isLoading = true;
      query = query.toLowerCase();

      if (this.allAirlines) {
        const response = this.allAirlines.filter((airline: AirLine) => airline.code.toLowerCase().includes(query) || airline.businessName.toLowerCase().includes(query));

        this.airLines = [];

        if (response) {
          response.forEach((element) => {
            this.airLines.push(element);
          });
        }
      }

      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.airLines = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isRoutingLoading',
  })
  async findRoutingFromLocation(query: string) {
    if (query && query.length > 2) {
      this.dropdownClassFix = true;
      this.isRoutingFromLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.fromLocationOptions = [];
        this.fromLocationOptions = response.data;
      }

      this.isRoutingFromLoading = false;
    } else {
      this.isRoutingFromLoading = false;
      this.fromLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isRoutingLoading',
  })
  async findRoutingToLocation(query: string) {
    if (query && query.length > 2) {
      this.dropdownClassFix = true;
      this.isRoutingToLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocationOptions = [];
        this.toLocationOptions = response.data;
      }

      this.isRoutingToLoading = false;
    } else {
      this.isRoutingToLoading = false;
      this.toLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isGeoAreaFromLoading',
  })
  async findGeoAreaFrom(query: string) {
    if (query && query.length > 1) {
      this.dropdownClassFix = true;
      this.isGeoAreaFromLoading = true;
      const response = await DictionaryApi.getCountriesContinentsNames(query);

      if (response && response.data) {
        this.geoAreaLocationOptions = [];
        this.geoAreaLocationOptions = response.data;
      }

      this.isGeoAreaFromLoading = false;
    } else {
      this.isGeoAreaFromLoading = false;
      this.geoAreaLocationOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isGeoAreaToLoading',
  })
  async findGeoAreaTo(query: string) {
    if (query && query.length > 1) {
      this.dropdownClassFix = true;
      this.isGeoAreaToLoading = true;
      const response = await DictionaryApi.getCountriesContinentsNames(query);

      if (response && response.data) {
        this.geoAreaLocationOptions = [];
        this.geoAreaLocationOptions = response.data;
      }

      this.isGeoAreaToLoading = false;
    } else {
      this.isGeoAreaToLoading = false;
      this.geoAreaLocationOptions = [];
    }
  }

  async created() {
    if (this.selectedRule) {
      let data = JSON.parse(JSON.stringify(this.selectedRule));
      this.mapFormData(data);
    } else {
      this.mapFormData();
    }
  }
}
