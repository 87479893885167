






































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import BasketStore from '@/modules/basket/basket.store';
import EventBus from '@/services/event-handler';
import SncfAncillary from './SncfAncillary.vue';
import SncfAncillaryPrice from './SncfAncillaryPrice.vue';
import SncfAncillaryRail from './SncfAncillaryRail.vue';
import SncfAncillarySlots from './SncfAncillarySlots.vue';

@Component({
  components: {
    SncfAncillary,
    SncfAncillaryPrice,
    SncfAncillaryRail,
    SncfAncillarySlots,
  },
})
export default class SncfBasketAncillaries extends Vue {
  @Prop() offer!: any;

  selectedTraveller: any | null = null;

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketRailAncillariesLoading() {
    return BasketStore.basketRailAncillariesLoading;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    if (this.bookingStep < 1) {
      return '';
    }
    return this.wizardSteps[this.bookingStep - 1].tripItemId;
  }

  get basketRailSncfAncillaries() {
    return BasketStore.basketRailSncfAncillaries;
  }

  get selectedAncillariesPrice() {
    return BasketStore.totalAncillariesPrice;
  }

  get selectedAncillaries() {
    let ancillaries: any[] = [];
    let selectedAncillaries = this.basketRailSncfAncillaries.find(ancillary => ancillary.traveller.id === this.selectedTraveller.id);
    if (selectedAncillaries && selectedAncillaries.ancillaries) {
      ancillaries = selectedAncillaries.ancillaries.flatMap(ancillary => {
        return ancillary.segments.map(segment => {
          return {
            segment: segment,
            price: ancillary.price,
            product: ancillary.product,
            traveller: this.selectedTraveller,
            remainingSlots: ancillary.remainingSlots,
          };
        });
      });
      return ancillaries;
    }
    return ancillaries;
  }



  userFullName(user) {
    return userFullName(user);
  }

  classesForRow(index) {
    if (!this.selectedAncillaries) {
      return '';
    }
    const ancillaries = this.selectedAncillaries;
    if (index >= ancillaries.length) {
      return '';
    }

    if (index === ancillaries.length - 1) {
      return 'basket-ancillaries__cell--last-in-group';
    }
    
    const current = ancillaries[index];
    const next = ancillaries[index + 1];

    if (next.product.code === current.product.code) {
      return '';
    }

    return 'basket-ancillaries__cell--last-in-group';
  }

  selectTraveller(traveller, index) {
    this.selectedTraveller = traveller;

    const travellerRef = (this.$refs.traveller as HTMLElement[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', travellerRef);
  }

  @Watch('basketRailSncfAncillaries', { immediate: true })
  onInit() {
    if (this.basketRailSncfAncillaries && this.basketRailSncfAncillaries.length) {
      this.selectedTraveller = this.basketRailSncfAncillaries[0].traveller;
    }
  }
}

