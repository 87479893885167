import Expense from '@/modules/expense/Expense.vue';
import ExpenseSubmenu from '@/modules/expense/ExpenseSubmenu.vue';
import ExpenseMenuRoute from './expense-menu.route';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/expenses',
  components: {
    default: Expense,
    submenu: ExpenseSubmenu,
  },
  children: [
    ExpenseMenuRoute,
  ],
  meta: {
    transitions: {
      submenu: {
        $default: AnimationName.UP
      }
    },
    permission: 'Client',
  },
};
