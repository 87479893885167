var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"uiMultiSelectWrapper"},[_c('multiselect',_vm._g(_vm._b({staticClass:"xs-hidden-mobile",attrs:{"value":_vm.value,"searchable":false,"options":_vm.options,"multiple":true,"seleniumId":_vm.seleniumId,"label":_vm.label,"id":_vm.currentId,"track-by":_vm.trackBy,"disabled":_vm.disabled,"show-labels":_vm.showLabels,"placeholder":_vm.placeholder},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'multiselect',_vm.$attrs,false),_vm.listeners),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2),_c('div',{staticClass:"desktop-hidden xs-visible-mobile"},[_c('div',{staticClass:"ui-multi-select__mobile"},[_c('div',{ref:"mobileField",staticClass:"ui-multi-select__mobile-field",attrs:{"tabindex":"0","seleniumId":'Mobile' + _vm.seleniumId,"id":'Mobile' + _vm.currentId},on:{"!click":function($event){return _vm.mobileFieldClick()}}},[_c('span',[_c('span',{staticClass:"ui-multi-select__mobile-values"},[_vm._l((_vm.value),function(val,index){return _c('span',{key:'option' + index,staticClass:"ui-multi-select__mobile-value"},[(_vm.isSimple === true)?_c('span',[_vm._v(_vm._s(val)),(0 === ('' + val).length)?[_vm._v(" ")]:_vm._e()],2):_vm._e(),(_vm.isSimple === false && !_vm.$scopedSlots.tag && !_vm.$slots.tag)?_c('span',[_vm._v(_vm._s(_vm.getValueText(val))),(0 === ('' + _vm.getValueText(val)).length)?[_vm._v(" ")]:_vm._e()],2):_vm._e(),(_vm.isSimple === false && (_vm.$scopedSlots.tag || _vm.$slots.tag))?_vm._t("tag",null,null,{
                  option: val,
                }):_vm._e()],2)}),(!_vm.value || _vm.value.length === 0)?_c('span',[_vm._v(" ")]):_vm._e()],2)])]),(_vm.isActive)?_c('portal',{attrs:{"to":"mobile-dropdown"}},[_c('div',{staticClass:"ui-multi-select__mobile-view",class:_vm.mobileViewClasses},[('' !== _vm.popupTitle)?_c('div',{staticClass:"ui-multi-select__mobile-popup-title"},[_vm._v("\n            "+_vm._s(_vm.popupTitle)+"\n          ")]):_vm._e(),_c('div',{staticClass:"ui-multi-select__mobile-field-label"},[(_vm.$slots['field-label'])?_vm._t("field-label"):_vm._e(),(!_vm.$slots['field-label'])?[(!_vm.foundLabel)?[_vm._v(" ")]:_vm._e(),_vm._v("\n              "+_vm._s(_vm.foundLabel)+"\n            ")]:_vm._e()],2),_c('div',{ref:"mobileField",staticClass:"ui-multi-select__mobile-field",attrs:{"tabindex":"0","seleniumId":'Mobile' + _vm.seleniumId,"id":'Mobile' + _vm.currentId},on:{"!click":function($event){return _vm.confirm()}}},[_c('span',[_c('span',{staticClass:"ui-multi-select__mobile-values"},[_vm._l((_vm.value),function(val,index){return _c('span',{key:'option' + index,staticClass:"ui-multi-select__mobile-value"},[(_vm.isSimple === true)?_c('span',[_vm._v(_vm._s(val)),(0 === ('' + val).length)?[_vm._v(" ")]:_vm._e()],2):_vm._e(),(_vm.isSimple === false && !_vm.$scopedSlots.tag && !_vm.$slots.tag)?_c('span',[_vm._v(_vm._s(_vm.getValueText(val))),(0 === ('' + _vm.getValueText(val)).length)?[_vm._v(" ")]:_vm._e()],2):_vm._e(),(_vm.isSimple === false && (_vm.$scopedSlots.tag || _vm.$slots.tag))?_vm._t("tag",null,null,{
                      option: val,
                    }):_vm._e()],2)}),(!_vm.value || _vm.value.length === 0)?_c('span',[_vm._v(" ")]):_vm._e()],2)])]),_c('div',{staticClass:"ui-multi-select__mobile-options",on:{"!click":function($event){return _vm.mobileOptionsClick($event)}}},_vm._l((_vm.options),function(item,index){return _c('div',{key:'select_mobile_option' + index,staticClass:"ui-multi-select__mobile-option option-item",class:_vm.classesForIndex(item, index),attrs:{"data":index},on:{"touchstart":function($event){return _vm.onTouchStart(index)},"touchend":function($event){return _vm.onTouchEnd(index)}}},[_c('span',{staticClass:"ui-label ui-multi-select__mobile-option-checkbox custom-control custom-checkbox custom-control-inline",class:{
                  'custom-checkbox-checked': _vm.isSelected(index),
                }},[_c('span',{staticClass:"custom-control-label custom-control-color"})]),(_vm.$scopedSlots.option)?_c('span',[_vm._t("option",null,null,{
                  option: item,
                })],2):_vm._e(),(!_vm.$scopedSlots.option)?_c('span',[_vm._v(_vm._s(_vm.getOptionText(item)))]):_vm._e(),(0 === ('' + item).length)?_c('span',[_vm._v("\n                 \n              ")]):_vm._e()])}),0)])]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }