import AirUpsell from '@/modules/search/air/AirUpsell.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/air-upsell/:searchId/provider/:providerSearchId/recommendation/:recommendationId/force/:force',
  name: 'airUpsell',
  components: {
    default: AirUpsell,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airDetails: AnimationName.DOWN,
        airTravellers: AnimationName.DOWN,
      },
    }
  }
};
