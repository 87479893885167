















































import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import BasketStore from './basket.store';
import BasketItemWithTravelPolicyResult from './BasketItemWithTravelPolicyResult.vue';
import { TravelPolicyResult } from '@/api/trip/basket.model';

@Component({
  components: {
    BasketExpirationCountdown,
    BasketItemWithTravelPolicyResult,
  }
})
export default class BasketReviewTravelPolicyCompliance extends Vue {
  @Prop() basketExpired!: boolean;

  loaded = false;
  canShow = false;

  get basket() {
    return BasketStore.basket;
  }

  get isPostSalesAction() {
    return 'seat-extras' === BasketStore.finalBookOption;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get canShowTravelPolicyComplianceStep() {
    return BasketStore.canShowTravelPolicyComplianceStep;
  }

  get loading() {
    return BasketStore.loadingReviewTravelPolicyCompliance;
  }

  get travelPolicyCompliance() {
    return BasketStore.travelPolicyCompliance;
  }

  get travelPolicyComplianceResults() {
    if (!this.travelPolicyCompliance) {
      return [];
    }

    return this.travelPolicyCompliance.recalculateTripItemResults;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get bookingStepDef() {
    return BasketStore.bookingStepDef;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get errors() {
    return BasketStore.errors;
  }

  get basketItemsWithTPCompliance() {
    return this.basketItemsMapped
      .filter(item => {
        if (!this.bookingStepDef || !this.bookingStepDef.tripItemId) {
          return true;
        }

        return item.id === this.bookingStepDef.tripItemId;
      })
      .map(item => {
        const travelPolicyCompliance = this.travelPolicyComplianceResults
          .find(result => result.tripItemId === item.id);

        return {
          ...item,
          travelPolicyCompliance,
        };
      })
      .filter(item => item.travelPolicyCompliance && item.travelPolicyCompliance.travelPolicyResultChange.currentTravelPolicyResult !== TravelPolicyResult.Empty);
  }

}

