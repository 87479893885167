import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  ExpenseCategoryResult,
} from './expense-categories.model';

class ExpenseCategoriesApiClass {
  public getExpenseCategories(policyId: string): Promise<AxiosResponse<ExpenseCategoryResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-categories?policyId={policyId}',
      method: RequestMethod.GET,
    }, { policyId }, {});
  }

  public getExpenseCategory(categoryId: string): Promise<AxiosResponse<ExpenseCategoryResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-categories/{categoryId}',
      method: RequestMethod.GET,
    }, { categoryId }, {});
  }

  public createExpenseCategory(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-categories',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateExpenseCategory(categoryId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-categories/{categoryId}',
      method: RequestMethod.PUT,
    }, { categoryId }, params );
  }

  public removeExpenseCategory(categoryId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/expense-categories/{categoryId}',
      method: RequestMethod.DELETE,
    }, { categoryId }, {} );
  }

}

export const ExpenseCategoriesApi: ExpenseCategoriesApiClass = new ExpenseCategoriesApiClass();
