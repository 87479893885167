import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  CustomFieldResponseModel,
  UpdateFieldMessage,
  CustomFieldValueModel,
  CustomFieldValueResponseModel,
  UpdateFieldConfigurationMessage,
  CopyFieldConfigurationMessage,
  SimpleCustomFieldModel
} from './custom-fields.model';

export class CustomFieldsApiClass {
  public createCustomField(field: CustomFieldResponseModel): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFields',
      method: RequestMethod.POST
    }, {}, field);
  }

  public getCustomFields(companyId: string, configurationId: string): Promise<AxiosResponse<CustomFieldResponseModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/customFields?CompanyId={companyId}&ConfigurationId={configurationId}',
      method: RequestMethod.GET,
    }, { companyId, configurationId }, {});
  }

  public updateCustomField(message: UpdateFieldMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/customFields/${message.customFieldId}`,
      method: RequestMethod.PUT,
    }, message.customFieldId, message.field);
  }

  public deleteCustomField(customFieldId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/{customFieldId}',
      method: RequestMethod.DELETE,
    }, { customFieldId }, {});
  }

  public getCustomField(customFieldId: string): Promise<AxiosResponse<CustomFieldResponseModel>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/{customFieldId}',
      method: RequestMethod.GET,
    }, { customFieldId }, {});
  }

  public getCustomFieldCollectionModes(): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/collection-modes',
      method: RequestMethod.GET,
    }, {}, {});
  }

  public getCustomFieldTypes(): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldTypes',
      method: RequestMethod.GET,
    }, {}, {});
  }

  public getCustomFieldValues(profileId: string, fieldCodesString?: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/api/profiles/{profileId}/custom-fields${fieldCodesString ? '?' + fieldCodesString : ''}`,
      method: RequestMethod.GET,
    }, {profileId, fieldCodesString}, {});
  }

  public createCustomFieldValue(profileId: string, fieldValue: CustomFieldValueModel): Promise<AxiosResponse<CustomFieldValueResponseModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/custom-fields',
      method: RequestMethod.POST,
    }, { profileId }, fieldValue);
  }

  public updateCustomFieldValue(profileId: string, valueId: string, value: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/custom-fields/{valueId}',
      method: RequestMethod.PUT,
    }, { profileId, valueId }, { value });
  }

  public deleteCustomFieldValue(profileId: string, valueId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/custom-fields/{valueId}',
      method: RequestMethod.DELETE,
    }, { profileId, valueId }, {});
  }

  public createCustomFieldConfiguration(data: UpdateFieldConfigurationMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/configurations',
      method: RequestMethod.POST,
    }, {}, data);
  }

  public updateCustomFieldConfiguration(configurationId: string, name: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, { name });
  }

  public copyCustomFieldConfiguration(message: CopyFieldConfigurationMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/configurations/{configurationId}/custom-fields/copy',
      method: RequestMethod.POST,
    }, { configurationId: message.configurationId }, { configurationName: message.configurationName });
  }

  public getSimpleCustomField(customFieldId: string): Promise<AxiosResponse<SimpleCustomFieldModel>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/{customFieldId}/simple',
      method: RequestMethod.GET,
    }, { customFieldId }, {});
  }

  public getActiveCustomFields(CompanyId: string): Promise<AxiosResponse<SimpleCustomFieldModel>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/names?CompanyId={CompanyId}&Active=true',
      method: RequestMethod.GET,
    }, { CompanyId }, {});
  }
}

export const CustomFieldsApi: CustomFieldsApiClass = new CustomFieldsApiClass();