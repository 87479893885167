





















import { Vue, Component, Prop } from 'vue-property-decorator';

import SeatMapConst from '@/const/rail-seat-map.const';

@Component
export default class TrainSeatPassanger extends Vue {
  @Prop({ default: 0 }) x!: number;
  @Prop({ default: 0 }) y!: number;
  @Prop({ default: '1' }) text!: string;

  consts: any = SeatMapConst;

  get width() {
    return SeatMapConst.colWidth * 1.5;
  }

  get height() {
    return SeatMapConst.rowHeight * 1.5;
  }
}
