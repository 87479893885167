import PaymentGateway from '@/modules/settings/payment-gateway/PaymentGateway.vue';
import PaymentGatewayConfigurations from './payment-gateway-configurations.route';
import PaymentGatewayConfiguration from './payment-gateway-configuration.route';

export default {
  path: 'payment-gateways-configurations',
  component: PaymentGateway,
  displayName: 'settings-sidebar.payment-gateways-configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditPaymentGateways',
    icon: 'credit_score',
    breadCrumb: 'Payment gateways configurations',
    parentName: 'payment-gateways-configurations',
  },
  children: [
    PaymentGatewayConfigurations,
    PaymentGatewayConfiguration,
  ],
};