import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  CreateKnownTravellerMessage,
  CreateNationalIdMessage,
  CreateVisaMessage,
  CreateDrivingLicenses,
  ProfileDocument,
  GreenCardDocument,
} from './documents.model';

class DocumentsApiClass {
  public getDocuments(profileId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public removeDocument(profileId, documentId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/{documentId}',
      method: RequestMethod.DELETE
    }, { profileId, documentId }, {});
  }

  public getDocumentsDrivingLicenses(profileId, documentId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/drivinglicenses/{documentId}',
      method: RequestMethod.GET
    }, { profileId, documentId }, {});
  }

  public createDocumentsDrivingLicenses(profileId, params: CreateDrivingLicenses): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/drivinglicenses',
      method: RequestMethod.POST,
    }, { profileId }, params);
  }

  public updateDocumentsDrivingLicenses(profileId, documentId, params: CreateDrivingLicenses): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/drivinglicenses/{documentId}',
      method: RequestMethod.PUT,
    }, { profileId, documentId }, params);
  }

  public getNationalIdDocument(profileId, documentId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/nationalids/{documentId}',
      method: RequestMethod.GET
    }, { profileId, documentId }, {});
  }

  public createNationalIdDocument(profileId, params: CreateNationalIdMessage): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/nationalids',
      method: RequestMethod.POST,
    }, { profileId }, params);
  }

  public updateNationalIdDocument(profileId, documentId, params: CreateNationalIdMessage): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/nationalids/{documentId}',
      method: RequestMethod.PUT,
    }, { profileId, documentId }, params);
  }

  public createGreenCard(profileId, params: GreenCardDocument): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/greencards',
      method: RequestMethod.POST,
    }, { profileId }, params);
  }

  public updateGreenCard(profileId, documentId, params: GreenCardDocument): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/greencards/{documentId}',
      method: RequestMethod.PUT,
    }, { profileId, documentId }, params);
  }

  public getVisaDocument(profileId, documentId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/visas/{documentId}',
      method: RequestMethod.GET
    }, { profileId, documentId }, {});
  }

  public createVisaDocument(profileId, params: CreateVisaMessage): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/visas',
      method: RequestMethod.POST,
    }, { profileId }, params);
  }

  public updateVisaDocument(profileId, documentId, params: CreateVisaMessage): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/visas/{documentId}',
      method: RequestMethod.PUT,
    }, { profileId, documentId }, params);
  }

  public getKnownTravellerDocument(profileId, documentId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/knowntravellers/{documentId}',
      method: RequestMethod.GET
    }, { profileId, documentId }, {});
  }

  public createKnownTravellerDocument(profileId, params: CreateKnownTravellerMessage): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/knowntravellers',
      method: RequestMethod.POST,
    }, { profileId }, params);
  }

  public updateKnownTravellerDocument(profileId, documentId, params: CreateKnownTravellerMessage): Promise<AxiosResponse<ProfileDocument>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/documents/knowntravellers/{documentId}',
      method: RequestMethod.PUT,
    }, { profileId, documentId }, params);
  }
}

export const DocumentsApi: DocumentsApiClass = new DocumentsApiClass();
