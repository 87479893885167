




















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({})
export default class UiPlusMinusValue extends Vue {
  @Prop({ default: 0 }) min!: number;
  @Prop() max!: number;
  @Prop({ default: 1 }) step!: number;
  @Prop({ default: null }) unit!: String;
  @Prop({ default: true }) unitAsPrefix!: Boolean;
  @Prop() value!: number;
  @Prop({ default: 500 }) inputDelay!: number;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) addDisabled!: boolean;
  @Prop({ default: false }) isChanging!: boolean;

  internalValue = this.value;
  cursorPosition: number =  1;
  timeout: number | null = null;

  get valueInput() {
    if (this.unit !== undefined) {
      if (this.unitAsPrefix === true) {
        return this.unit + ' ' + this.internalValue;
      } else {
        return this.internalValue + ' ' + this.unit;
      }
    } else {
      return this.unit;
    }
  }

  set valueInput(value) {
    let regexPattern: string | null = null;
    if (this.unit !== undefined) {
      if (this.unitAsPrefix === true) {
        regexPattern = `^(?:${this.regExEscape(this.unit)})?\\s*((?:[-+]?)\\d+(?:(?:\.|,)\\d{1,2})?)$`;
      } else {
        regexPattern = `^((?:[-+]?)\\d+(?:(?:\\.|,)\d{1,2})?)(?:\\s*(?:${this.regExEscape(this.unit)}))?$`;
      }
    } else {
      regexPattern = '^((?:[-+]?)\\d+(?:(?:\\.|,)\\d{1,2})?)$';
    }

    const regex = new RegExp(regexPattern);

    const parseResult = regex.exec(value);
    if (parseResult !== null) {
      const rawValue = parseResult[1].replace(',', '.');
      let value = parseFloat(rawValue);
      if (value < this.min) {
        value = this.min;
      } else {
        if (value > this.max) {
          value = this.max;
        }
      }

      this.internalValue = value;
      this.onValueUpdate();
    }

    if (this.unitAsPrefix === undefined || this.unitAsPrefix === false) {
      this.$nextTick(() => {
        const el = this.$refs.valueInput as HTMLInputElement;
        el.selectionEnd = this.cursorPosition;
      });
    }
  }

  regExEscape(str) {
    if (str !== null) {
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    } else {
      return null;
    }
  }

  touchValue() {
    const tmpVal = this.internalValue;
    this.internalValue = 0;
    this.internalValue = this.max;
    this.internalValue = tmpVal;
  }

  handleInput(e) {
    this.cursorPosition = e.target.selectionStart;
  }

  valueInc() {
    let newValue = this.internalValue + this.step;
    newValue = _.round(newValue, 2);
    if (newValue > this.max) {
      newValue = this.max;
    }
    this.internalValue = newValue;
    this.onValueUpdate();
  }

  valueDec() {
    let newValue = this.internalValue - this.step;
    newValue = _.round(newValue, 2);
    if (newValue < this.min) {
      newValue = this.min;
    }
    this.internalValue = newValue;
    this.onValueUpdate();
  }

  valueInputFocusOut() {
    this.touchValue();
  }

  onValueUpdate() {
    this.$emit('update:isChanging', true);
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.$emit('input', this.internalValue);
      this.$emit('update:isChanging', false);
    }, this.inputDelay);
  }

  @Watch('value')
  onValueChanged(v) {
      this.internalValue = v;
  }
}
