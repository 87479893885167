












import { Vue, Prop, Component } from 'vue-property-decorator';
import BasketApprovalCurrentLevel from './BasketApprovalCurrentLevel.vue';

@Component({
  components: {
    BasketApprovalCurrentLevel
  }
})
export default class BasketApprovalLevelAnyApprover extends Vue {
  @Prop() approverSourceType!: string;
  @Prop() level!: number;
  @Prop() levelCount!: number;

  getAnyAllowedApproverName(approverSourceType) {
    switch (approverSourceType) {
      case 'AllApproversFromRootCompany': return this.$t('trips-table.approver-from-root-company');
      case 'ApproversFromList': return this.$t('trips-table.approver-selected-in-configuration');
      case 'TravellerDesignatedApprover': return this.$t('settings-approval.any-of-designated-approvers');
      case 'CustomFieldMarkedApprover': return this.$t('settings-approval.any-of-custom-field-marked-approvers');
      default: return '';
    }
  }
}
