import TrainResults from '@/modules/search/train/TrainResults.vue';
import TrainSearchShortParams from '@/modules/search/train/TrainSearchShortParams.vue';
import TrainSubintro from '@/modules/search/train/TrainSubintro.vue';
import TrainResultsFilters from '@/modules/search/train/TrainResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/train/:searchId/:leg',
  name: 'train',
  components: {
    default: TrainResults,
    intro: TrainSearchShortParams,
    subintro: TrainSubintro,
    sidebar: TrainResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-train-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        trainDetails: AnimationName.DOWN,
        trainTravellers: AnimationName.DOWN,
      },
    }
  }
};