














import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import HotelSearchParamsOnResults from './HotelSearchParamsOnResults.vue';

@Component({
  components: {
    HotelSearchParamsOnResults
  }
})
export default class HotelSearchShortParams extends Vue {

  get hotelSearchParameters() {
    return SearchStore.getHotelDefaultState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  showCriteria() {
    if (this.$route.name === 'hotelDetails') {
      router.push({
        name: 'hotelDetailsSearch',
        params: router.currentRoute.params,
      });
    } else {
      router.push({
        name: 'hotelResultsSearch',
        params: router.currentRoute.params,
      });
    }
  }

  showTravellers() {
    if (this.$route.name === 'hotelDetails') {
      router.push({
        name: 'hotelDetailsTravellers',
        params: router.currentRoute.params,
      });
    } else {
      router.push({
        name: 'hotelTravellers',
        params: router.currentRoute.params,
      });
    }
  }
}

