




























































import { Vue, Component, Prop } from 'vue-property-decorator';
import OfflineRequestStatus from './OfflineRequestStatus.vue';
import OfflineRequestsPopup from './OfflineRequestsPopup.vue';
import { OfflineRequestSummary } from '@/api/trip/offline-request.model';
import { RequestTypeList } from '@/const/request-type-list.const';
import moment from 'moment';
import { translate } from '@/i18n';

@Component({
  components: {
    OfflineRequestStatus,
    OfflineRequestsPopup,
  }
})
export default class OfflineRequestsWidgetItem extends Vue {
  @Prop() offlineRequest!: OfflineRequestSummary;
  @Prop() currentUserProfileId!: string;

  requestDetailsPopupVisible: boolean = false;

  get requestType() {
    const item = RequestTypeList.find(x => x.value === this.offlineRequest.requestType);
    if (item) {
      return translate(item.name);
    }
    return this.offlineRequest.requestType;
  }

  get requestor() {
    return {
      firstName: this.offlineRequest.requestorName.split(' ').join(''),
      lastName: this.offlineRequest.requestorName.split(' ').join(''),
      id: this.offlineRequest.requestorId,
    };
  }

  get elapsed() {
    const now = new Date();
    let date = new Date(this.offlineRequest.requestDate);
    if (date.getTime() > now.getTime()) {
      date = now;
    }
    return moment(date).fromNow();
  }

  reload() {
    this.requestDetailsPopupVisible = false;
    this.$emit('request-changed');
  }

  checkShowDetails() {
    this.requestDetailsPopupVisible = true;
  }
}
