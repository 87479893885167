




























import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { BasketFlatItemModel } from '@/api/trip/basket.model';

@Component({
  filters: {
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
  }
})
export default class TripDescriptionText extends Vue {
  @Prop() item!: BasketFlatItemModel;



  get startDate() {
    return this.item.startDate;
  }

  get endDate() {
    return this.item.endDate;
  }

  get sameMonth() {
    return moment(this.startDate).isSame(this.endDate, 'month');
  }

  get sameDate() {
    return moment(this.startDate).isSame(this.endDate, 'date');
  }

  get numberOfNights() {
    const end = moment(this.endDate);
    const start = moment(this.startDate);
    return end.diff(start, 'days');
  }
}
