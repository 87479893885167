



























































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { router } from '@/router';

import { CustomMessagesApi } from '@/api/profile/custom-messages.api';
import CustomMessagesStore from './custom-messages.store';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats } from '@/api/profile/company.model';
import moment from 'moment';

@Component({})
export default class CustomMessagesList extends Vue {
  params = {
    size: Number.MAX_SAFE_INTEGER,
    sort: '',
    start: 0,
  };
  fields = {
    title: {
      sortable: false,
      label: translate('global-settings.message-title-simple')
    },
    duration: {
      sortable: false,
      label: translate('global-settings.duration')
    },
    importance: {
      sortable: false,
      label: translate('global-settings.importance')
    },
    isEnabled: {
      sortable: false,
      label: translate('global-settings.message-active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  serverErrorsMessages = {
    FIELD_VALUE_TOOLONG: translate('common-error.field-too-long')
  };
  serverErrors: any = [];
  items: any[] = [];
  menuActive: boolean = false;
  message: any = null;
  messageToRemove: any = null;
  removePopup: boolean = false;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  loading: boolean = false;
  errorsOnPopup: any[] = [];
  searchName: string = '';



  get count() {
    return this.items.length;
  }

  get addNewMessageUrl() {
    return sanitizeUrl('/global-settings/custom-messages/new');
  }

  get itemsFiltered() {
    return this.items
      .filter(message => {
        return -1 < message.title.toLowerCase().indexOf(this.searchName.toLowerCase());
      });
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }



  getDatesFormatted(message) {
    const start = message.startDate ? moment(message.startDate).format(this.currentDateFormat) : '';
    const end = message.endDate ? moment(message.endDate).format(this.currentDateFormat) : '';

    return {
      start,
      end,
    };
  }

  editMessageUrl(item) {
    return sanitizeUrl('/global-settings/custom-messages/' + item.id);
  }

  rowClicked(item) {
    if (item) {
      CustomMessagesStore.setSelectedMessage(item);
      router.push({
        name: 'edit-custom-message',
        params: {
          messageId: item.id
        }
      });
    }
  }

  remove(message) {
    this.messageToRemove = message;
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeMessage() {
    try {
      this.loading = true;
      await CustomMessagesApi.removeCustomMessage(this.messageToRemove.id);
      await this.reload();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-approval.configuration-removed'),
        message: translate('settings-approval.info-removed')
      });
    } catch (error) {
      this.errorsOnPopup = this.$handleErrors(error, true);
      this.loading = false;
    }
    finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.name < b.name) {
        result = -1;
      }
      else if (a.name > b.name) {
        result = 1;
      }
      return result;
    });
  }

  toggleMenu(item) {
    if (!this.message || this.message.id !== item.id) {
      this.message = item;
      this.menuActive = true;
    } else {
      this.message = item;
      this.menuActive = !this.menuActive;
    }
  }

  selectMessage(item) {
    CustomMessagesStore.setSelectedMessage(item);
  }

  async reload() {
    this.serverErrors = [];

    try {
      this.loading = true;
      const result = await CustomMessagesApi.getAllMessages();
      if (result && result.data) {
        this.items = result.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.reload();
    CustomMessagesStore.setSelectedMessage(null);
  }
}

