import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';
import AirResults from '@/modules/search/air/AirResults.vue';
import AirSearchShortParams from '@/modules/search/air/AirSearchShortParams.vue';
import AirSubintro from '@/modules/search/air/AirSubintro.vue';
import AirResultsFilters from '@/modules/search/air/AirResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/air-results-travellers-list/:searchId',
  name: 'airResultsTravellersList',
  components: {
    default: AirResults,
    intro: SearchTravellersIntro,
    sidebar: AirResultsFilters,
    subintro: AirSubintro,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      },
    }
  }
};
