import MarkupRuleRail from '@/modules/settings/agency-markup/MarkupRuleRail.vue';

export default {
  path: ':configurationId?/rail-markup-rule',
  name: 'markup-rule-rail',
  component: MarkupRuleRail,
  displayName: 'Rail markup rule definition',
  meta: {
    breadCrumb: 'Rail markup rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditAgencyMarkupsConfiguration',
    parentName: 'markups',
    depthCategory: 'markups',
    depth: 3,
  }
};