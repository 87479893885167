



































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';
import { BasketPaymentCardModel } from '@/api/trip/basket.model';
import { PaymentCardType, PaymentCardProfileModel } from '@/api/profile/payment-cards.model';
import PaymentCardPopup from '@/modules/profile/payment-cards/PaymentCardPopup.vue';
import { translate } from '@/i18n';
import accountStore from '@/store/account.store';
import { getSecureFields } from '@/plugins/secure-fields';
import messages from '@/const/error-messages.const';
import settings from '@/settings';
import EventBus from '@/services/event-handler';
import formatPrice from '@/filters/format-price.filter';

@Component({
  components: {
    PaymentCardPopup,
  }
})
export default class BasketPaymentMethods extends Vue {
  @Prop() paymentMethods!: any;
  @Prop() item!: any;
  @Prop() index!: any;
  @Prop() pendingActions?: any;
  selectedPaymentMethod: any = null;
  selectedPaymentCard: BasketPaymentCardModel | undefined | null = null;
  selectedLodgeCard: BasketPaymentCardModel | undefined | null = null;
  displayValidationMessages: boolean = false;
  selectedPaymentCardDoesNotHaveTypeInProfile: boolean = false;
  paymentErrors: string | null = null;
  warningMessage: string | null = null;
  secureFields: any | null = null;
  cvvValid: boolean = true;
  cvvLength: number = 0;
  cardOpened: boolean = false;
  methodOpened: boolean = false;
  secureFieldsReady: boolean = false;
  shouldValidateUnsupportedPayments: boolean = false;
  showCardPopup: boolean = false;
  selectedCard: PaymentCardProfileModel | null = null;
  isFirstEnter: boolean = false;

  get profile() {
    return accountStore.current ? accountStore.current.profile : null;
  }

  get chooseToSelectPayment() {
    return BasketStore.chooseToSelectPayment;
  }

  set chooseToSelectPayment(value) {
    BasketStore.setChooseToSelectPayment(value);
  }

  get hidePaymentMethods() {
    return this.item.paymentMethods.hidePaymentMethods;
  }

  get paymentMethodType() {
    if (!this.selectedPaymentMethod || !this.selectedPaymentMethod.paymentType) {
      return '';
    }

    return {
      CentralPayment: translate('settings-payment-methods.central-payment'),
      PaymentCard: translate('settings-payment-methods.user-card'),
      LodgeCard: translate('settings-payment-methods.lodge-card'),
      TfPay: translate('settings-payment-methods.tfpay-central'),
      PreRegisteredCard: translate('settings-payment-methods.preregistered-card'),
      VirtualCard: translate('settings-payment-methods.virtual-card-payment'),
    }[this.selectedPaymentMethod.paymentType] || '';
  }

  get providerPriceText() {
    return this.getPriceText(this.item.agencyFeePaymentInfo.paymentPrice);
  }

  get agencyFeeText() {
    return this.getPriceText(this.item.agencyFeePaymentInfo.allFees);
  }

  get isChangingPaymentMethod() {
    return BasketStore.isChangingPaymentMethod;
  }

  get isSelectPaymentMethodDisabled() {
    return this.isChangingPaymentMethod ||
      (BasketStore.bookingExtras && this.item && this.item.status === 'Held') ||
      this.paymentMethods.supportedPaymentMethods.length === 0 ||
      !!this.pendingActions;
  }
  
  get basket() {
    return BasketStore.basket;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get bookingInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get isCentralPaymentMethod() {
    return this.selectedPaymentMethod != null ? this.selectedPaymentMethod.paymentType === 'CentralPayment' : false;
  }

  get isPaymentCardMethod() {
    return this.selectedPaymentMethod != null ? this.selectedPaymentMethod.paymentType === 'PaymentCard' : false;
  }

  get isLodgeCardMethod() {
    return this.selectedPaymentMethod != null ? this.selectedPaymentMethod.paymentType === 'LodgeCard' : false;
  }

  get isVirtualCardMethod() {
    return this.selectedPaymentMethod != null ? this.selectedPaymentMethod.paymentType === 'VirtualCard' : false;
  }

  get canUserSelectPaymentCards() {
    return accountStore.HasPermission('ReadPaymentCards');
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get supportedPaymentMethods() {
    return (this.paymentMethods.supportedPaymentMethods || [])
      .map(item => {
        return {
          ...item,
          $isDisabled: item.isNotAcceptable || (this.isAnySeatSelected ? !item.isAcceptableBySeats : false)
        };
      });
  }

  get paymentCards() {
    return (this.paymentMethods.availablePaymentCards || [])
      .map(item => {
        return {
          ...item,
          $isDisabled: item.isSupportedByProvider === false || item.isExpired === true || (this.isAnySeatSelected ? this.isPaymentUnsupportedForSeats(item) : false),
          unsupported: this.isAnySeatSelected ? this.isPaymentUnsupportedForSeats(item) : false
        };
      });
  }

  get lodgeCards() {
    return (this.paymentMethods.availableLodgeCards || [])
      .map(item => {
        return {
          ...item,
          $isDisabled: this.isAnySeatSelected ? this.isPaymentUnsupportedForSeats(item) || item.isExpired || !item.isSupportedByProvider : item.isExpired || !item.isSupportedByProvider,
          unsupported: this.isAnySeatSelected ? this.isPaymentUnsupportedForSeats(item) : false
        };
      });
  }

  get showInfoPaymentChange() {
    if (this.selectedPaymentMethod && 
      (this.selectedPaymentMethod.paymentType === 'CentralPayment' || this.selectedPaymentMethod.paymentType === 'VirtualCard')) {
      return false;
    }
    return BasketStore.bookingExtras && this.item && this.item.status === 'Held';
  }

  get basketItemStatus() {
    return {
      draft: this.item.status === 'Draft',
      held: this.item.status === 'Held',
      pendingApproval: this.item.status === 'PendingApproval',
      confirmed: this.item.status === 'Confirmed',
      cancelled: this.item.status === 'Cancelled',
      refused: this.item.status === 'Refused',
      ticketed: this.item.status === 'Ticketed'
    };
  }

  get pciProxyMerchantId() {
    return settings.pciProxy_MerchantId;
  }

  get pciProxyMockEnabled() {
    return settings.pciProxy_MockEnabled === 'true';
  }

  onPaymentCardSelect(selectedCard) {
    if (selectedCard) {
      let oldSelectedCard = this.selectedPaymentCard;
      if (this.selectedPaymentCardDoesNotHaveTypeInProfile && oldSelectedCard && oldSelectedCard.paymentCardId !== selectedCard.paymentCardId) {
        oldSelectedCard!.type = null;
        oldSelectedCard!.isSupportedByProvider = false;
        oldSelectedCard!.cardFee = null;
      }
      if (this.selectedPaymentMethod.paymentType === 'LodgeCard') {
        this.selectedLodgeCard = selectedCard;
      } else {
        this.selectedPaymentCard = selectedCard;
      }
      this.selectedPaymentCardDoesNotHaveTypeInProfile = !selectedCard.type;
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard') {
        if (this.pciProxyMockEnabled) {
          this.selectedPaymentCard!.transactionId = 'MOCK';
        } else {
          this.selectedPaymentCard!.transactionId = null;
          this.$nextTick(() => {
            this.initSecureFields();
          });
        }
      }
      if (this.shouldValidateUnsupportedPayments && selectedCard.unsupported) {
        BasketStore.setBasketPaymentUnsupported(true);
      } else if (!selectedCard.unsupported) {
        BasketStore.setBasketPaymentUnsupported(false);
      }

      this.updateBasketItem();
    }
  }

  get paymentMethodValidationError() {
    return this.displayValidationMessages && !this.selectedPaymentMethod;
  }

  get paymentMethodIsDisabled() {
    return this.displayValidationMessages && (this.selectedPaymentMethod ? this.selectedPaymentMethod.isNotAcceptable : false);
  }

  get paymentCardValidationError() {
    if (this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'LodgeCard' && !this.selectedLodgeCard) {
      return true;
    }
    return this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'PaymentCard' && !this.selectedPaymentCard;
  }

  get transactionIdValidationError() {
    return this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'PaymentCard'
    && this.selectedPaymentCard
    && this.cvvLength === 0 && !this.cvvValid && !this.pciProxyMockEnabled;
  }

  get invalidCvvErorr() {
    return this.displayValidationMessages && this.selectedPaymentMethod.paymentType === 'PaymentCard'
    && this.selectedPaymentCard
    && this.cvvLength > 0 && !this.cvvValid && !this.pciProxyMockEnabled;
  }

  get isItemTF() {
    return this.item && this.item.supplier && this.item.supplier === 'TravelFusion' ? true : false;
  }

  get addedCartInBasket() {
    return BasketStore.addedCartInBasket;
  }

  get paymentForTripId() {
    return BasketStore.paymentForTripId;
  }

  get isOpened() {
    return this.cardOpened || this.methodOpened;
  }

  get selectedPaymentMethodObject() {
    return this.paymentMethods.supportedPaymentMethods.find(item => {
      return item.paymentType === this.selectedPaymentMethod.paymentType;
    });
  }

  get seatSelection() {
    return BasketStore.seatSelection;
  }

  get tripSeatSelection() {
    return BasketStore.tripSeatSelection;
  }

  get itemSeatSelection() {
    return this.tripSeatSelection.find(selection => {
      return this.item.id === selection.id;
    });
  }

  get shouldCentralPaymentBeUnsupported() {
    return this.selectedPaymentMethod && this.selectedPaymentMethod.paymentType === 'CentralPayment' ? !!(this.itemSeatSelection && this.itemSeatSelection.seats.length) : false;
  }

  get isAnySeatSelected() {
    return this.itemSeatSelection && this.itemSeatSelection.seats ? !!(this.itemSeatSelection.seats.length) : false;
  }

  get isSelectedPaymentMethodUnsupportedBySeats() {
    if (!this.selectedPaymentMethod) {
      return false;
    } else {
      return this.item && this.item.supplier === 'Sabre' &&
        this.selectedPaymentMethod.paymentType === 'CentralPayment' &&
        this.selectedPaymentMethod.isAcceptableBySeats === false &&
        this.itemSeatSelection &&
        this.itemSeatSelection.seats.length > 0  ? true : false;
    }
  }

  get isSelectedPaymentUnsupported() {
    if (!this.selectedPaymentMethod) {
      return false;
    } else {
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard') {
        return this.selectedPaymentCard ? this.selectedPaymentCard.unsupported : false;
      } else if (this.selectedPaymentMethod.paymentType === 'LodgeCard') {
        return this.selectedLodgeCard ? this.selectedLodgeCard.unsupported : false;
      } else {
        return false;
      }
    }
  }

  get anyPaymentCardUnsupportedWithSeats() {
    const methodObject = this.paymentMethods.supportedPaymentMethods.find(item => {
      return item.paymentType === this.selectedPaymentMethod.paymentType;
    });
    if (methodObject) {
      if (!methodObject.validPaymentCards || !methodObject.validPaymentCards.length) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  get getPlaceholderPaymentCard() {
    if (this.paymentCards && this.paymentCards.length === 0) {
      return translate('basket.empty-list');
    }
    return translate('basket.select-card');
  }

  get addCardVisible() {
    return accountStore.HasPermission('WriteProfilePaymentCards') || accountStore.HasPermission('CanAddPaymentCardToTraveller');
  }

  get hasUnsupportedPaymentCard() {
    return this.selectedPaymentMethod &&
      this.selectedPaymentMethod.paymentType === 'PaymentCard' &&
      this.selectedPaymentCard &&
      !this.selectedPaymentCard.isSupportedByProvider;
  }

  get cardType() {
    if (
      !this.selectedPaymentMethod ||
      this.selectedPaymentMethod.paymentType !== 'PaymentCard' ||
      !this.selectedPaymentCard
    ) {
      return '';
    }
    return this.selectedPaymentCard.type;
  }

  get errorMessageTF() {
    if (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentType === 'TfPay' && this.isItemTF) {
      return true;
    }
    
    return false;
  }



  getPriceText(priceObject) {
    const price = formatPrice(priceObject.amount);
    const symbol = priceObject.currency.symbol ? priceObject.currency.symbol : priceObject.currency.code;

    return [
      symbol,
      price,
    ].join(' ');
  }

  closeCardPopup() {
    this.showCardPopup = false;
    BasketStore.setAddedCartInBasket(true);
  }

  updatePaymentMethod(selectedMethod) {
    this.isFirstEnter = false;
    this.secureFieldsReady = false;
    this.selectedPaymentMethod = selectedMethod;

    if (this.selectedPaymentMethod.paymentType !== 'PaymentCard' || this.selectedPaymentMethod.paymentType !== 'LodgeCard') {
      this.selectedPaymentCard = null;
    }

    if (this.shouldValidateUnsupportedPayments) {
      if (this.isAnySeatSelected && !this.selectedPaymentMethod.isAcceptableBySeats) {
        BasketStore.setBasketPaymentUnsupported(true);
      } else {
        BasketStore.setBasketPaymentUnsupported(false);
      }
    } else {
      BasketStore.setBasketPaymentUnsupported(false);
    }

    this.updateBasketItem();
  }

  addCard() {
    this.selectedCard = null;
    this.showCardPopup = true;
  }

  isPaymentUnsupportedForSeats(card) {
    if (this.selectedPaymentMethodObject.validPaymentCards && this.selectedPaymentMethodObject.validPaymentCards.length) {
      return !this.selectedPaymentMethodObject.validPaymentCards.some(type => {
        return card.type === type;
      });
    } else {
      return false;
    }
  }

  setItemPayment() {
    if (this.selectedPaymentCard) {
      this.item.selectedPaymentCard = {
        paymentMethod: this.selectedPaymentMethod.paymentType,
        paymentCardId: this.selectedPaymentCard.paymentCardId,
        userProfileId: this.selectedPaymentCard.profileId,
        maskedNumber: this.selectedPaymentCard.maskedNumber,
        holderFirstName: this.selectedPaymentCard.holderFirstName,
        holderLastName: this.selectedPaymentCard.holderLastName,
        cardFee: this.selectedPaymentCard.cardFee,
        type: this.selectedPaymentCard.type && this.selectedPaymentCard.type.value ? this.selectedPaymentCard.type.value : this.selectedPaymentCard.type,
        updateCardTypeInProfile: true,
        hasErrors: !this.selectedPaymentCard.type || this.selectedPaymentCard.type === PaymentCardType.Unknown,
        transactionId: this.selectedPaymentCard.transactionId
      };
    } else {
      this.item.selectedPaymentCard = {
        paymentMethod: this.selectedPaymentMethod.paymentType,
      };
    }

    this.$emit('item-paymentcard-changed', this.item);
  }

  updateBasketItem() {
    if (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentType === 'PaymentCard') {
      this.setItemPayment();
    } else if (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentType === 'LodgeCard') {
      this.item.selectedPaymentCard = {
        paymentMethod: this.selectedPaymentMethod.paymentType,
      };
      if (this.selectedLodgeCard) {
        this.item.selectedPaymentCard.paymentCardId = this.selectedLodgeCard.paymentCardId;
      }
      this.$emit('item-paymentcard-changed', this.item);
    } else if (this.selectedPaymentMethod && this.selectedPaymentMethod.paymentType !== 'PaymentCard' && this.selectedPaymentMethod.paymentType !== 'LodgeCard') {
      this.item.selectedPaymentCard = {
        paymentMethod: this.selectedPaymentMethod.paymentType,
      };
      this.$emit('item-paymentcard-changed', this.item);
    }
  }

  onReady() {
    this.secureFieldsReady = true;
  }

  onCVVChange(data) {
    if (data.fields.cvv) {
      this.cvvValid = data.fields.cvv.valid;
      this.cvvLength = data.fields.cvv.length;
    }
  }

  onSuccess(data) {
    if (data.transactionId) {
      this.selectedPaymentCard!.transactionId = data.transactionId;
      this.updateBasketItem();
      const selectedPaymentMethods = {
        tripItemId: this.paymentMethods.tripItemId,
        hasErrors: false,
        paymentMethod: this.selectedPaymentMethod.paymentType,
        cardId: this.selectedPaymentCard!.paymentCardId,
        cardTypeOverride: this.selectedPaymentCard!.type,
        saveTypeOverrideIntoCardDefinition: true,
        needSubmit: true,
        transactionId: this.selectedPaymentCard!.transactionId
      };
      BasketStore.setSelectedPaymentMethods(selectedPaymentMethods);
      const selectedCreditCardPaymentMethod = {
        tripItemId: this.paymentMethods.tripItemId,
        transactionId: this.selectedPaymentCard!.transactionId,
        isNeeedToSubmit: false,
        isValid: true,
      };
      BasketStore.setSelectedCreditCardPaymentMethods(selectedCreditCardPaymentMethod);
    }
  }

  onValidate(data) {
    if (data.fields.cvv) {
      this.cvvValid = data.fields.cvv.valid;
      this.cvvLength = data.fields.cvv.length;
      if (!this.cvvValid) {
        const selectedPaymentMethods = {
          tripItemId: this.paymentMethods.tripItemId,
          hasErrors: true,
          paymentMethod: this.selectedPaymentMethod.paymentType,
          cardId: this.selectedPaymentCard!.paymentCardId,
          cardTypeOverride: this.selectedPaymentCard!.type,
          saveTypeOverrideIntoCardDefinition: true,
          transactionId: null,
          needSubmit: true,
        };
        BasketStore.setSelectedPaymentMethods(selectedPaymentMethods);
        BasketStore.setValidationErrorAfterSubmit();
      }
    }
  }

  onError(data) {
    if (data) {
      this.paymentErrors = translate(messages.unknown); 
      const selectedPaymentMethods = {
        tripItemId: this.paymentMethods.tripItemId,
        hasErrors: true,
        paymentMethod: this.selectedPaymentMethod.paymentType,
        cardId: null,
        cardTypeOverride: null,
        saveTypeOverrideIntoCardDefinition: null,
        transactionId: null,
        needSubmit: true,
      };

      const selectedCreditCardPaymentMethod = {
        tripItemId: this.paymentMethods.tripItemId,
        transactionId: null,
        isNeeedToSubmit: true,
        isValid: false,
      };
      BasketStore.setSelectedPaymentMethods(selectedPaymentMethods);
      BasketStore.setSelectedCreditCardPaymentMethods(selectedCreditCardPaymentMethod);
      BasketStore.setValidationErrorAfterSubmit();
    }
  }

  initSecureFields() {
    this.paymentErrors = null;
    this.secureFieldsReady = false;

    if (this.isPaymentCardMethod) {
      this.secureFields = getSecureFields();

      let styles = {
        '*': 'background: white; border-radius: 5px; border: 1px solid #999999;min-height: 70px; margin:5px 0; text-transform: inherit; height: 70px;color: #4D4D4D; padding-left: 13px; font-family: Orkney, "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, sans-serif; font-size: 14px; width: 99%; outline: 0;',
        '*::placeholder': 'color: #D8D8D8;  font-size: 14px;',
        '*:-ms-input-placeholder': 'color: #D8D8D8',
        '@font-face': {
          '*': {
              fontFamily: 'Orkney',
              fontStyle: 'normal',
              fontWeight: 400,
              src: 'url(\'Orkney Regular.woff2\') format(\'woff2\'), url(\'Orkney Regular.eot\') format(\'embedded-opentype\'), url(\'Orkney Regular.woff\') format(\'woff2\'),url(\'Orkney Regular.otf\') format(\'opentype\'),url(\'Orkney Regular.ttf\') format(\'truetype\')',
          }        
        },
        '@media (max-width: 799.98px)': { 
          '*': {
            fontSize: '16px',
          }
        }
      };

      this.secureFields.on('ready', this.onReady);
      this.secureFields.on('change', this.onCVVChange);
      this.secureFields.on('validate', this.onValidate);
      this.secureFields.on('success', this.onSuccess);
      this.secureFields.on('error', this.onError);

      this.$nextTick(() => {
        this.secureFields.initTokenize(this.pciProxyMerchantId, {
          cvv: {
            placeholderElementId: 'cvvPlaceholder' + this.index,
            placeholder: translate('basket.your-cvv'),
            inputType: 'tel',
          }
        }, 
        {            
          styles,
        });
      });
    }
  }

  onValidationMessages(value) {
    this.displayValidationMessages = value;
  }

  async paymentValidation() {
    this.shouldValidateUnsupportedPayments = true;
    if (this.isSelectedPaymentMethodUnsupportedBySeats || this.isSelectedPaymentUnsupported) {
      BasketStore.setBasketPaymentUnsupported(true);
    } else {
      BasketStore.setBasketPaymentUnsupported(false);
    }
    let value = {
      tripItemId: this.paymentMethods.tripItemId,
      hasErrors: false,
      paymentMethod: null,
      cardId: '',
      cardTypeOverride: null,
      saveTypeOverrideIntoCardDefinition: true,
      transactionId: null,
      needSubmit: false,
    };

    if (!this.selectedPaymentMethod) {
      value.hasErrors = true;
    } else if (this.selectedPaymentMethod.isNotAcceptable) {
      value.hasErrors = true;
    } else if (this.item.supplier === 'Sabre' && this.isSelectedPaymentMethodUnsupportedBySeats) {
      value.hasErrors = true;
    } else {
      if (this.hasUnsupportedPaymentCard) {
        value.hasErrors = true;
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
      } else if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && !this.selectedPaymentCard) {
        value.tripItemId = this.paymentMethods.tripItemId;
        value.hasErrors = true;
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
      } else if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.selectedPaymentCard && !this.pciProxyMockEnabled && !this.secureFieldsReady) {
        value.tripItemId = this.paymentMethods.tripItemId;
        value.hasErrors = true;
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.cardTypeOverride = this.selectedPaymentCard!.type;
      } else if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.selectedPaymentCard && !this.pciProxyMockEnabled) {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.cardId = this.selectedPaymentCard!.paymentCardId;
        value.cardTypeOverride = this.selectedPaymentCard!.type;
        value.needSubmit = true;
      } else if (this.selectedPaymentMethod.paymentType === 'LodgeCard') {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
        value.hasErrors = this.selectedLodgeCard ? false : true;
        value.cardId = this.selectedLodgeCard ? this.selectedLodgeCard.paymentCardId : '';
      } else if (this.selectedPaymentMethod.paymentType !== 'PaymentCard') {
        value.paymentMethod = this.selectedPaymentMethod.paymentType;
      }
    }
    BasketStore.setSelectedPaymentMethods(value);
    BasketStore.setPaymentFormResponded(true);
  }

  async paymentSubmit(tripItemId) {
    if (this.paymentMethods.tripItemId === tripItemId) {
      this.secureFields.submit();
    }
  }

  onOpenMethod() {
    this.methodOpened = true;
  }

  onCloseMethod() {
    this.methodOpened = false;
  }

  onOpenCard() {
    this.cardOpened = true;
  }

  onCloseCard() {
    this.cardOpened = false;
  }

  @Watch('selectedPaymentMethod')
  onSelectedPaymentMethodChange(value) {
    if (!this.isFirstEnter) {
      this.$emit('dropdown-change', value);
    }
  }

  updateAddedCartInBasket() {
    if (this.addedCartInBasket && this.paymentMethods && this.paymentMethods.availablePaymentCards &&
        this.paymentForTripId && this.paymentForTripId.length) {

      let selectedTrip = this.paymentForTripId.find(trip => trip.tripItemId === this.paymentMethods.tripItemId);
      if (selectedTrip) {
        const candidate = this.paymentMethods.availablePaymentCards
          .find(card => card.paymentCardId === selectedTrip.paymentCardId);

        if (!candidate) {
          return;
        }
        this.selectedPaymentCard = candidate;
        const selectedPaymentMethods = {
          paymentType: 'PaymentCard',
          ...this.selectedPaymentCard,
        };
        this.updatePaymentMethod(selectedPaymentMethods);
        this.onPaymentCardSelect(selectedPaymentMethods);
      }
    }
  }
   
  mounted() {
    this.isFirstEnter = true;
    BasketStore.setBasketPaymentUnsupported(false);

    if (this.paymentMethods && this.paymentMethods.selectedPaymentMethod) {
      this.selectedPaymentMethod = this.paymentMethods.supportedPaymentMethods
        .find(payment => payment.paymentType === this.paymentMethods.selectedPaymentMethod);
    } else {
      const defaultPaymentMethod = this.paymentMethods.supportedPaymentMethods.find(payment => payment.isDefault && !payment.isNotAcceptable);
      if (defaultPaymentMethod) {
        this.selectedPaymentMethod = defaultPaymentMethod;
      }
    }

    if (this.paymentMethods && this.selectedPaymentMethod && this.paymentMethods.selectedPaymentCardId) {
      if (this.selectedPaymentMethod.paymentType === 'PaymentCard' && this.paymentMethods.availablePaymentCards) {
        this.selectedPaymentCard = this.paymentMethods.availablePaymentCards
          .find(card => card.paymentCardId === this.paymentMethods.selectedPaymentCardId) || null;

        this.onPaymentCardSelect(this.selectedPaymentCard);
      } else if (this.selectedPaymentMethod.paymentType === 'LodgeCard' && this.paymentMethods.availableLodgeCards) {
        this.selectedPaymentCard = this.paymentMethods.availableLodgeCards
          .find(card => card.paymentCardId === this.paymentMethods.selectedPaymentCardId) || null;

        this.onPaymentCardSelect(this.selectedPaymentCard);
      }
    }

    this.updateAddedCartInBasket();
    this.updateBasketItem();

    EventBus.$on('paymentValidation', this.paymentValidation);
    EventBus.$on('paymentValidationErrors', this.onValidationMessages);
    EventBus.$on('paymentSubmit', this.paymentSubmit);
  }

  beforeDestroy() {
    EventBus.$off('paymentValidation', this.paymentValidation);
    EventBus.$off('paymentValidationErrors', this.onValidationMessages);
    EventBus.$off('paymentSubmit', this.paymentSubmit);
  }
}

