



































import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketItem from './BasketItem.vue';
import SncfBasketAncillaries from '../basket/rail/SncfBasketAncillaries.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import BasketStore from './basket.store';

@Component({
   components: {
    BasketItem,
    SncfBasketAncillaries,
    BasketExpirationCountdown,
  }
})
export default class BasketRailSncfAncillariesSelectionView extends Vue {
  @Prop() basketExpired!: boolean;

  get loading() {
    return BasketStore.loadingAncillaries;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    if (this.bookingStep < 1) {
      return '';
    }
    return this.wizardSteps[this.bookingStep - 1].tripItemId;
  }

  get basketRailSncfAncillaries() {
    return BasketStore.basketRailSncfAncillaries;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }
 
  get basket() {
    return BasketStore.basket;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'RAIL_SNCF_ANCILLARIES_SELECTION') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped[0];
  }

  async created() {
    BasketStore.setBookingStepLoading(true);
    if (this.item) {
      let recommendationId = this.item.providerReferenceId;
      BasketStore.resetPriceChangesForStep({step: this.bookingStep, tripItemId: this.itemId});
      await BasketStore.getRailAncillariesOffers({recommendationId: recommendationId, railSupplier: this.item.supplier});
      BasketStore.setBookingStepLoading(false);
    }
  }

  beforeDestroy() {
    BasketStore.resetSelectedRailAncillaries();
  }
}

