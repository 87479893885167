import FaresMappingConfigurations from '@/modules/settings/fares-mapping/FaresMappingConfigurations.vue';

export default {
  path: '',
  name: 'fares-mapping-configurations',
  component: FaresMappingConfigurations,
  displayName: 'settings-sidebar.fares-mapping-configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditFareMapping',
    depthCategory: 'fares-mapping-configurations',
    parentName: 'fares-mapping-configurations',
    depth: 1,
  }
};