import AirResults from '@/modules/search/air/AirResults.vue';
import AirExchangeModifyView from '@/modules/search/air/AirExchangeModifyView.vue';
import AirSubintro from '@/modules/search/air/AirSubintro.vue';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import AirResultsFilters from '@/modules/search/air/AirResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/air-modification/:searchId/basket/:basketId',
  name: 'airModification',
  components: {
    default: AirResults,
    intro: AirExchangeModifyView,
    sidebar: AirResultsFilters,
    subintro: AirSubintro,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'ExchangeFlightSearch',
    preserveSidebar: {
      airDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airDetails: AnimationName.DOWN,
      },
    }
  }
};
