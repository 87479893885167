
let reloadCount = 0;

export default () => {
  window.addEventListener('pageshow', () => {
    if (0 < reloadCount) {
      location.reload();
    }
    reloadCount++;
  });
};
