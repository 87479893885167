










import { Vue, Component, Watch } from 'vue-property-decorator';
import SearchTravellersList from '@/modules/search/SearchTravellersList.vue';
import { router } from '@/router';

@Component({
  components: {
    SearchTravellersList,
  }
})
export default class SearchTravellersIntro extends Vue {
  
}
