











































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TripApi } from '@/api/trip/trip.api';
import { OffersApi } from '@/api/air-engine/offers.api';
import { router } from '@/router';

@Component({
  components: {
  },
})
export default class NotificationBritishAirwaysPopup extends Vue {
  @Prop() itemId!: string;
  @Prop() title!: string;
  @Prop({ default: false }) popupVisible!: boolean;
  @Prop() notification!: any;

  inProgress: boolean = false;
  messageLoaded: boolean = false;
  popupErrors: any[] = [];
  messageData: any = null;
  item: any = null;
  popupTitle: string = '';
  imagesConst = '/assets/img/loader/1.gif';
  isErrorAfterConfirmSchedule: boolean = false;
  isTripItemLoading: boolean = false;
  tripItemData: any = null;
  tripItemLoadingErrors: any[] = [];

  get notificationReferences() {
    if (!this.messageData) {
      return [];
    }

    return this.messageData.providerReference;
  }

  async loadTripItem() {
    this.isTripItemLoading = true;
    this.tripItemLoadingErrors = [];
    try {
      const response = await OffersApi.getOffer(this.messageData.tripItem.providerReferenceId);

      this.tripItemData = {
        ...response.data,
        pricePlusMarkup: this.item.price.total,
        travelPolicy: this.item.travelPolicy,
      };
    } catch (error) {
      this.tripItemLoadingErrors = this.$handleErrors(error);
    } finally {
      this.isTripItemLoading = false;
    }
  }

  async showMessage() {
    this.messageLoaded = false;
    this.messageData = null;
    this.popupErrors = [];
    this.inProgress = true;

    try {
      const result = await TripApi.getNotificationMessage(this.itemId);
      if (result && result.data) {
        this.messageData = result.data;
        if (this.messageData && this.messageData.message) {
          this.messageData.message = result.data.message.split('|');
        }

        if (this.messageData.tripItem && this.messageData.tripItem.providerReferenceId) {
          this.item = this.messageData.tripItem;
          this.loadTripItem();
        }
      }
      this.messageLoaded = true;
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
    } finally {
      this.inProgress = false;
    }
  }

  hidePopup() {
    this.$emit('close');
  }

  created() {
    this.showMessage();
  }

  async acceptChanges() {
    this.inProgress = true;
    this.popupErrors = [];
    try {
      const params = {
        'markAsRead': true,
        'acceptScheduleChange': true,
      };

      const initResponse = await TripApi.notificationMark(this.messageData.id, params);

      if (initResponse && initResponse.status === 204) {
        this.$emit('updateCurrentRow', true);
      }

      this.hidePopup();
    } catch (error) {
      this.popupErrors = this.$handleErrors(error, true);
      this.isErrorAfterConfirmSchedule = true;
    } finally {
      this.inProgress = false;
    }
  }

cancelBooking() {
  router.push({
      name: 'basket',
      params: { 
        id: this.messageData.tripId,
        followUpActionName: 'OpenRefundPopup',
        followUpActionTripItemId: this.messageData.tripItemId
      }
  });
}

changeDateOrTime() {
  router.push({
      name: 'basket',
      params: { 
        id: this.messageData.tripId,
        followUpActionName: 'OpenModifyModalExchange',
        followUpActionTripItemId: this.messageData.tripItemId
      }
  });
}
}

