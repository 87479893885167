import ExpensePerDiemsSettings from '@/modules/settings/expense-configuration/ExpensePerDiemsSettings.vue';

export default {
  path: ':configurationId?/per-diems/:itemId?/:isCopy?',
  name: 'per-diems',
  component: ExpensePerDiemsSettings,
  displayName: 'Per Diems settings',
  meta: {
    breadCrumb: 'Per Diems settings',
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};