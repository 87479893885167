












































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import SearchConst from '@/const/search.const';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { HomeApi } from '@/api/home/home.api';

@Component({})
export default class RailTrenitaliaLegSelectionRow extends Vue {
  @Prop() leg!: any;
  @Prop() legs!: any;
  @Prop() index!: number;
  @Prop() minDate!: string;
  @Prop() maxDate!: string;
  @Prop() disabled!: boolean;
  @Prop({ default: false }) disabledLocation!: boolean;
  @Prop({ default: false }) disabledDate!: boolean;
  @Prop() showTooltip!: boolean;
  @Prop() showTimePickers!: boolean;

  fromLocations: any[] = [];
  toLocations: any[] = [];
  copyLegs: any[] = [];
  diffDays: number = 1;



  get valueTimeDeparture() {
    return {
      timeRange: [this.leg.departureTimeSpan.from, this.leg.departureTimeSpan.to],
    };
  }

  set valueTimeDeparture(value) {
    this.leg.departureTimeSpan.from = value.timeRange[0];
    this.leg.departureTimeSpan.to = value.timeRange[1];
  }
  
  get valueTimeOptions() {
    return SearchConst.railSearchTimeWindows;
  }

  get datePickerTimeLabels() {
    return {
      start: translate('search-train.departure'),
      end: translate('search-train.return'),
    };
  }

  get fromLocationsOptions() {
    return this.fromLocations.map(location => ({
      ...location,
      uniqId: location.codes ? JSON.stringify(location.codes) : ''
    }));
  }

  get toLocationsOptions() {
    return this.toLocations.map(location => ({
      ...location,
      uniqId: location.codes ? JSON.stringify(location.codes) : ''
    }));
  }

  get legDate() {
    return moment(this.leg.date).toDate();
  }

  set legDate(value) {
    this.leg.date = moment(value).format('YYYY-MM-DD');
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }



  prepareFrom() {
    this.fromLocations = [];
    if (this.leg.from) {
      this.fromLocations.push(this.leg.from);
    }
  }

  prepareTo() {
    this.toLocations = [];
    if (this.leg.to) {
      this.toLocations.push(this.leg.to);
    }
  }

  focusOnFirstElement() {
    ((this.$refs.checkbox as Vue).$el as HTMLElement).focus();
  }

  focusOn(elementId) {
    const inputValue = ((this.$refs[elementId] as Vue).$el as HTMLInputElement);
    inputValue.getElementsByTagName('input')[0].focus();
  }
  
  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'leg.isFromLoading',
  })
  async findFromLocation(query) {
    if (query && query.length > 2) {
      this.leg.isFromLoading = true;
      const response = await HomeApi.findRailLocation(query, this.languageCode);

      this.fromLocations = [];
      if (response && response.data) {
        this.fromLocations = response.data;
      }

      this.leg.isFromLoading = false;
    } else {
      this.leg.isFromLoading = false;
      this.fromLocations = [];
      if (this.leg.from) {
        this.fromLocations.push(this.leg.from);
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'leg.isToLoading',
  })
  async findToLocation(query) {
    if (query && query.length > 2) {
      this.leg.isToLoading = true;
      const response = await HomeApi.findRailLocation(query, this.languageCode);

      this.toLocations = [];
      if (response && response.data) {
        this.toLocations = response.data;
      }

      this.leg.isToLoading = false;
    } else {
      this.leg.isToLoading = false;
      this.toLocations = [];
      if (this.leg.to) {
        this.toLocations.push(this.leg.to);
      }
    }
  }

  isForExchangeChange(value) {
    if (!value) {
      this.$emit('deselect-leg');
    }
  }

  handleEnterPress() {
    this.$emit('focus-on-next');
  }

  onLegDateChange() {
    if (1 === this.legs.length) {
      return;
    }
    const one_day = 1000 * 60 * 60 * 24;
    this.diffDays = Math.ceil((new Date(this.legs[1].date).getTime() - new Date(this.copyLegs[0].date).getTime() ) / one_day);

    this.copyLegs = JSON.parse(JSON.stringify(this.legs));
  }

  @Watch('legDate')
  onLegsUpdate(value) {
    if (
      this.legs.length > 1 &&
      this.legs[this.index].date &&
      this.index === 0 &&
      moment(this.legs[this.index + 1].date).isSameOrBefore(this.legs[this.index].date, 'day')
    ) {
      this.legs[this.index + 1].date = moment(value).add(this.diffDays, 'days').format('YYYY-MM-DD');
    }

    this.copyLegs = JSON.parse(JSON.stringify(this.legs));
  }

  mounted() {
    this.prepareFrom();
    this.prepareTo();
    this.copyLegs = JSON.parse(JSON.stringify(this.legs));
  }
}

