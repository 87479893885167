





















































































import { Vue, Component, Watch, Emit } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { ExpenseConfigurationsApi } from '@/api/profile/expense-configuration.api';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Permission } from '@/const/permission.enum';
import ExpenseCategory from './ExpenseCategory.vue';
import DefaultCategoryAssigments from './DefaultCategoryAssigments.vue';

@Component({
  components: {
    ExpenseCategory,
    DefaultCategoryAssigments,
  }
})
export default class ExpenseCategories extends Vue {

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return router.currentRoute.params.isCopy;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get activeTab() {
    return ExpensePolicyStore.activeCategoryTab;
  }

  setCategoryTab() {
    ExpensePolicyStore.setCategoryTab();
  }

  setAssignCategoryTab() {
    ExpensePolicyStore.setAssignCategoryTab();
  }

  returnToConfigurations() {
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'expense-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  addExpenseCategory() {
    router.push({
      name: 'expense-category',
      params: {
        id: this.currentCompany!.rootCompanyId,
        itemId: this.$route.params.itemId,
        configurationId: this.$route.params.configurationId
      }
    });
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (!this.configuration.name) {
        ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
      ExpensePolicyStore.getExpensePolicyDetails(this.policyId);
      ExpensePolicyStore.getExpenseCategories(this.policyId);
    }
  }

}
