import Basket from '@/modules/basket/Basket.vue';
import BasketAside from '@/modules/basket/BasketAside.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import BasketTravellersList from '@/modules/basket/BasketTravellersList.vue';
import BasketMessages from '@/modules/basket/BasketMessages.vue';

export default {
  path: '/basket-travellers/:id',
  name: 'basketTravellers',
  component: Basket,
  components: {
    default: Basket,
    intro: BasketTravellersList,
    aside: BasketAside,
    messages: BasketMessages,
  },
  meta: {
    introClass: 'intro-basket',
    permission: 'ReadTrip',
    startWithHiddenAside: true,
    waitForDicts: true,
    preserveAsideFrom: [
      'basket',
      'basketAddSegment',
    ],
    transitions: {
      intro: {
        basketAddSegment: AnimationName.DOWN,
      },
    },
  },
};
