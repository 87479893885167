import { translate } from '@/i18n';
import ExpensePolicy from '@/modules/settings/expense-configuration/ExpensePolicy.vue';
import ExpensePolicyConfigurations from './expense-configurations.route';
import ExpensePolicyConfiguration from './expense-configuration.route';
import ExpenseDefinition from './expense-definition.route';
import ExpenseWorkflowSettings from './expense-workflow.route';
import ExpenseCategories from './expense-categories.route';
import ExpenseCategoryDetails from './expense-category-details.route';
import ManageExpenseModel from './manage-expense.route';
import BasicExtension from './basic-extension.route';
import CustomFieldsExpense from './custom-fields-expense.route';
import ExpensePaymentInstruments from './expense-payments.route';
import ExpensePaymentInstrument from './expense-payment.route';
import ExpensePerDiemsSettings from './expense-per-diems.route';
import PerDiemRate from './expense-per-diem-rate.route';
import ReductionReasonRule from './expense-reduction-reason-rule.route';
import LengthOfStayRules from './expense-length-stay-rule.route';
import ExpenseAuditRule from './expense-audit-rule.route';

export default {
  path: 'expense-policies',
  component: ExpensePolicy,
  displayName: 'settings-sidebar.expense-policies',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    icon: 'savings',
    breadCrumb: `Expense poliecies' configurations' list`,
    parentName: 'expense-policies',
  },
  children: [
    ExpensePolicyConfigurations,
    ExpensePolicyConfiguration,
    ExpenseDefinition,
    ExpenseWorkflowSettings,
    ExpenseCategories,
    ExpenseCategoryDetails,
    ManageExpenseModel,
    BasicExtension,
    CustomFieldsExpense,
    ExpensePaymentInstruments,
    ExpensePaymentInstrument,
    ExpensePerDiemsSettings,
    PerDiemRate,
    ReductionReasonRule,
    LengthOfStayRules,
    ExpenseAuditRule,
  ],
};