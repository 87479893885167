import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import { store } from '@/store';

import SettingsStore from '../settings.store';
import {
  RoleModel,
  RoleDetailsModel as SectionPermissionModel,
  NewRoleModel,
  RoleEditModel,
  UpdateRoleModel,
} from '@/api/roles/roles.model';
import { RolesApi } from '@/api/roles/roles.api';
import EventHandler from '@/services/event-handler';
import $handleErrors from '@/core/errors/handle-errors.service';
import { translate } from '@/i18n';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'roles'
})
class RolesStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  permissions: SectionPermissionModel[] | null = null;
  companyRoles: RoleModel[] | null = null;
  roleDetails: SectionPermissionModel[] | null = null;
  editedRole: RoleEditModel | null = null;
  errors: any[] = [];
  showRemoveModal: boolean = false;

  get IsLoading(): boolean {
    return this.loading;
  }

  get IsLoaded(): boolean {
    return this.loaded;
  }

  get Permissions(): SectionPermissionModel[] | null {
    return this.permissions;
  }

  get Roles(): RoleModel[] {
    return this.companyRoles != null
      ? this.companyRoles.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        )
      : [];
  }

  get RoleDetails(): SectionPermissionModel[] | null {
    return this.roleDetails;
  }

  get EditedRole(): RoleEditModel | null {
    return this.editedRole;
  }

  get Errors(): any[] {
    return this.errors;
  }

  get companyId() {
    return SettingsStore.currentCompany!.companyId;
  }

  @Mutation
  startLoading() {
    this.loading = true;
  }

  @Mutation
  finishLoading() {
    this.loading = false;
  }

  @Mutation
  loadingSuccess() {
    this.loaded = true;
  }

  @Mutation
  setPermissions(permissions: SectionPermissionModel[] | null) {
    this.permissions = permissions;
  }

  @Mutation
  setCompanyRoles(roles: RoleModel[]) {
    this.companyRoles = roles;
  }

  @Mutation
  setCurrentRoleDetails(roleDetails: SectionPermissionModel[] | null) {
    this.roleDetails = roleDetails;
  }

  @Mutation
  setCurrentRoleToEdit(roleToEdit: RoleEditModel | null) {
    this.editedRole = roleToEdit;
  }

  @Mutation
  setErrors({ error, isForm }) {
    this.errors = $handleErrors(error, isForm);
  }

  @Mutation
  resetErrors() {
    this.errors = [];
  }

  @Mutation
  setRemoveModalVisibility(payload: boolean) {
    this.showRemoveModal = payload;
  }

  @Action
  public async loadCompanyPermissions() {
    this.startLoading();
    this.resetErrors();
    try {
      this.setCurrentRoleDetails(null);
      this.setCompanyRoles([]);

      if (this.permissions == null) {
        let permissions = await RolesApi.getPermissions();
        this.setPermissions(permissions.data);
      }

      this.loadingSuccess();
    } catch (error) {
      this.setErrors({ error, isForm: true });
      throw(error);
    } finally {
      this.finishLoading();
    }
  }

  @Action
  public async selectRole(roleId: string) {
    this.startLoading();
    this.resetErrors();
    try {
      this.setCurrentRoleDetails(null);

      let roleDetails = await RolesApi.getRolePermissionsById(roleId);
      this.setCurrentRoleDetails(roleDetails.data);

      this.loadingSuccess();
    } catch (error) {
      this.setErrors({ error, isForm: true });
      throw(error);
    } finally {
      this.finishLoading();
    }
  }

  @Action
  public async editRole(roleId) {
    this.startLoading();
    this.resetErrors();
    try {
      this.setCurrentRoleToEdit(null);

      let roleEdit = await RolesApi.getRoleById(roleId);
      if (roleEdit && roleEdit.data) {
        let roleToEdit = roleEdit.data;
        roleToEdit.roleId = roleId;
        this.setCurrentRoleToEdit(roleToEdit);
      }
      this.loadingSuccess();
    } catch (error) {
      this.setErrors({ error, isForm: true });
    } finally {
      this.finishLoading();
    }
  }

  @Action
  public async removeRole(role: RoleModel) {
    this.startLoading();
    this.resetErrors();
    try {
      this.setCurrentRoleDetails(null);

      await RolesApi.removeRole(role.roleId);

      if (this.companyRoles != null) {
        let roleIndex = this.companyRoles.indexOf(role);
        this.companyRoles.splice(roleIndex, 1);
      }

      const obj = {
        type: translate('common.success'),
        title: translate('settings-roles.removed'),
        message: translate('settings-roles.removed-info')
      };

      EventHandler.$emit('show-toast', obj);
      this.loadingSuccess();
    } catch (error) {
      this.setErrors({ error, isForm: true });
      throw(error);
    } finally {
      if (!this.Errors.length) {
        this.setRemoveModalVisibility(false);
      }
      this.finishLoading();
    }
  }

  @Action
  public async updateRole(updateRole: UpdateRoleModel) {
    this.startLoading();
    this.resetErrors();
    try {
      if (this.editedRole) {
        await RolesApi.updateRole(this.editedRole.roleId, updateRole);

        this.setCurrentRoleToEdit(null);
      }

      this.loadingSuccess();
    } catch (error) {
      this.setErrors({ error, isForm: true });
      throw(error);
    } finally {
      this.finishLoading();
    }
  }

  @Action
  public async addNewRole(newRole: NewRoleModel) {
    this.startLoading();
    this.resetErrors();
    try {
      if (SettingsStore.IsLoaded && SettingsStore.currentCompany) {
        newRole.companyId = SettingsStore.currentCompany.companyId;
        let role = await RolesApi.addNewRole(newRole);
        if (role.data) {
          this.companyRoles!.push(role.data);

          this.setPermissions(null);
          this.selectRole(role.data.roleId);
        }
      }
    } catch (error) {
      this.setErrors({ error, isForm: true });
      throw(error);
    } finally {
      this.finishLoading();
    }
  }
}

export default getModule(RolesStore);
