import ApprovalWorkflow from '@/modules/profile/approval-workflow/ApprovalWorkflow.vue';

export default {
  path: 'approval-workflow',
  name: 'approval-workflow',
  component: ApprovalWorkflow,
  displayName: 'profile-approval-workflow.approval-workflow-title',
  meta: {
    permission: ['CanViewApprovalCustomFieldsInProfile', 'CanViewDesignatedTravellersForApprovalInProfile', 'CanViewDesignatedApproversInProfile'],
    icon: 'fact_check',
  }
};
