import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  PreferencesModel,
} from './preferences.model';

class PreferencesApiClass {
  public getPreferences(profileId) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/preferences',
      method: RequestMethod.GET
    }, {
      profileId,
    }, {});
  }

  public createPreferences(profileId, params: PreferencesModel) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/preferences',
      method: RequestMethod.POST
    }, {
      profileId,
    }, params);
  }

  public updatePreferences(profileId, params: PreferencesModel) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/preferences',
      method: RequestMethod.PUT
    }, { profileId }, params);
  }
}

export const PreferencesApi: PreferencesApiClass = new PreferencesApiClass();
