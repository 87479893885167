








































































































































import { Component } from 'vue-property-decorator';
import EventHandler from '@/services/event-handler';
import { router } from '@/router';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { ExpenseApi } from '@/api/expense/expense.api';
import { MyMissionsTableParams } from './mymission-table.params';
import accountStore from '@/store/account.store';
import ExpenseStore from '../expense.store';
import { translate } from '@/i18n';
import { Permission } from '@/const/permission.enum';

import { MissionResult, MissionState } from '@/api/expense/expense.model';

@Component({})
export default class MyMissionsTable extends BaseTable<MissionResult> {
  useQueryParams: boolean = true;
  params: MyMissionsTableParams = new MyMissionsTableParams({});
  fields = {
    name: {
      label: translate('expense-list.name'),
      class: 'mission-table__column--name',
    },
    state: {
      label: translate('expense-list.state'),
      class: 'mission-table__column--state',
    },
    missionStart: {
      label: translate('my-missions.mission-start'),
      class: 'mission-table__column--date',
    },
    missionEnd: {
      label: translate('my-missions.mission-end'),
      class: 'mission-table__column--date',
    },
    missionBudget: {
      label: translate('my-missions.mission-budget'),
      class: 'mission-table__column--total-price',
    },
    created: {
      label: translate('expense-list.created-date'),
      class: 'mission-table__column--created-date',
    },
    actionsColumn: {
      label: '',
      class: 'mission-table__column--actions',
    },
  };
  menuActive: boolean = false;
  mission: MissionResult | null = null;
  deleteMissionsPopup: boolean = false;
  missionRemovalErrorMessage: string | null = null;
  missionToRemove: MissionResult | null = null;
  formPending: boolean = false;

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get missionsList() {
    return ExpenseStore.missionsList;
  }

  get errMessages() {
    return ExpenseStore.errMessages;
  }

  get companyId() {
    return accountStore.CurrentUser!.profile.companyId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get showConfigurationError() {
    return ExpenseStore.showConfigurationError;
  }

  async getItems(params: MyMissionsTableParams): Promise<GetItemsResult<MissionResult>> {
    ExpenseStore.clearErrMessages();
    await ExpenseStore.getDefaultExpensePolicy(Permission.ReadMissionDefinition);
    let searchParams = {
      filters: {
        profileId: this.currentProfileId,
        policyId: this.policyId,
        approverId: params.approverId,
        companyId: params.companyId,
        businessUnitId: params.businessUnitId
      },
      paging: {
        offset: params.start,
        size: params.size,
      }
    };
    await ExpenseStore.getMissionsList(searchParams);
    return {
      page: {
        pageSize: params.size,
        moreResultsAvailable: false,
        pageNo: params.start,
      },
      results: this.missionsList,
    };
  }

  stateClasses(state) {
    return {
      'counter--empty': MissionState.Draft === state,
      'counter--yellow': MissionState.MissionRequestSubmitted === state,
      'counter--orange': MissionState.MissionRequestInApproval === state,
      'counter--strong-grey': MissionState.MissionCancelled === state || MissionState.ExpenseReportCancelled === state,
      'counter--light-green': MissionState.MissionApproved === state,
      'counter--red-grey': MissionState.AmendmentNeeded === state || MissionState.ReportAmendmentNeeded === state,
      'counter--strong-red': MissionState.MissionRequestRejected === state || MissionState.ExpenseReportRejected === state,
      'counter--green': MissionState.ExpenseReportApproved === state,
      'counter--orange-green': MissionState.ExpenseReportInApproval === state,
      'counter--yellow-green': MissionState.ExpenseReportSubmitted === state,
    };
  }

  toggleMissionMenu(item) {
    if (this.mission !== item) {
      this.mission = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  modifyMission(item) {
    router.push({
      name: 'modify-mission',
      params: {
        missionId: item.id,
      },
    });
  }

  showAssociatedExpenses(item) {
    router.push({
      name: 'expense-list-filtered',
      params: {
        missionId: item.id,
      },
    });
  }

  addExpenseToMission(item) {
    router.push({
      name: 'add-expense',
      params: {
        missionId: item.id,
      },
    });
  }

 initRemoveMission(mission) {
    this.missionRemovalErrorMessage = null;
    this.menuActive = false;
    this.deleteMissionsPopup = true;
    this.missionToRemove = mission;
  }

  async removeMission() {
    if (this.missionToRemove) {
      try {
        this.formPending = true;
        await ExpenseApi.removeMissionItem(this.missionToRemove.id);
        this.deleteMissionsPopup = false;
         let obj = {
          type: translate('common.success'),
          title: translate('my-missions.remove-mission-title'),
          message: translate('my-missions.remove-mission-message')
        };
        EventHandler.$emit('show-toast', obj);
        this.counted = 0;
        this.reload();
      } catch (error) {
        if (!error.response.data.error.details) {
          this.missionRemovalErrorMessage = error.response.data.error.message;
        }
      } finally {
        this.formPending = false;
      }
    }
  }

  refreshList() {
    this.resetList(); 
    this.reload();
  }
}
