import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import _ from 'lodash';
import 'moment/locale/fr';
import 'moment/locale/it';
import moment from 'moment';

import settings from '@/settings';
import buildData from '@/version';
import { store } from '@/store';
import appConst from '@/const/app.const';
import { SessionProfileModel } from '@/api/profile/profile.model';
import { ProfileApi } from '@/api/profile/profile.api';
import { PermissionApi } from '@/api/permissions/permissions.api';
import { AvatarCollectionModel } from '@/api/profile/profile.model';
import { http, RequestMethod } from '@/services/http';
import { ThemesApi } from '@/api/profile/themes.api';
import { loadLanguageAsync } from '@/i18n';
import { UserModel } from '@/api/account/users.model';
import SearchStore from '@/modules/search/search.store';

const LOGO_BASE64_STRING_PREFIX = 'data:image/' + settings.companyLogo.returnFormat + ';base64,';

const BASE64_STRING_PREFIX = 'data:image/' + settings.avatar.returnFormat + ';base64,';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'account'
})
class AccountStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  missingPermissionsError: boolean = false;
  current: SessionProfileModel | null = null;
  profilePermissions: string[] = [];
  preloadError: boolean = false;
  avatarCollection: AvatarCollectionModel[] = [];
  environmentReloading: boolean = false;
  environmentError: any | null = null;
  companyLogo: string = '';
  initialCompanyLogo: string = '';
  currentLanguage: string = '';
  settingsLoaded: boolean = false;
  user: UserModel | null = null;
  createProfileResponse: any | null = null;
  userBasicData: any | null = null;
  twoFactorError: boolean = false;
  ipVerificationError: boolean = false;
  chooseTravellerMode: boolean = false;

  get CurrentUser(): SessionProfileModel | null {
    return this.current;
  }

  get HasPermission(): (_: string) => boolean {
    return (permissionCode: string) => {
      if (permissionCode) {
        return this.profilePermissions.indexOf(permissionCode) > -1;
      }

      return true;
    };
  }

  get IsLoading(): boolean {
    return this.loading;
  }

  get IsLoaded(): boolean {
    return this.loaded;
  }

  get footerText(): string {
    if (
      !this.CurrentUser ||
      !this.CurrentUser.profile ||
      !this.CurrentUser.profile.theme
    ) {
      return '';
    }

    return this.CurrentUser.profile.theme.footer;
  }

  get themeName(): string {
    if (!this.CurrentUser || !this.CurrentUser.profile.theme) {
      return '';
    }

    return this.CurrentUser.profile.theme.themeName;
  }

  get isExpenseModuleEnabled() {
    if (!this.current || !this.current.modules) {
      return false;
    }

    const expenseModule = this.current.modules.find(module => module.name === 'Expense');

    if (!expenseModule) {
      return false;
    }

    return expenseModule.enabled;
  }

  @Mutation
  setSettingsLoaded(payload) {
    this.settingsLoaded = payload;
  }

  @Mutation
  setCurrentLanguage(lang) {
    this.currentLanguage = lang;
  }

  @Mutation
  startLoading() {
    this.loading = true;
  }

  @Mutation
  finishLoading() {
    this.loading = false;
  }

  @Mutation
  loadingSuccess() {
    this.loaded = true;
  }

  @Mutation
  setProfileAvatar(data: AvatarCollectionModel) {
    if (data.avatar === BASE64_STRING_PREFIX + 'null') {
      data.avatar = '';
    }
    let foundAvatarIndex = this.avatarCollection.map((e) => e.id).indexOf(data.id);
    if (foundAvatarIndex > -1) {
      this.avatarCollection.splice(foundAvatarIndex, 1, data);
    } else if (foundAvatarIndex === -1 && this.avatarCollection.length >= settings.avatar.maxAvatarCount) {
      let minTimestamp = Math.min(...this.avatarCollection.slice(1).map(e => e.usageTimestamp));
      let oldestAvatarIndex = this.avatarCollection.map((e) => e.usageTimestamp).indexOf(minTimestamp);
      this.avatarCollection.splice(oldestAvatarIndex, 1, data);
    } else if (foundAvatarIndex === -1 && this.avatarCollection.length < settings.avatar.maxAvatarCount) {
      this.avatarCollection.push(data);
    }
  } 

  @Mutation
  public setCurrent(current: SessionProfileModel | null) {
    this.current = current;
  }

  @Mutation
  public setProfilePermissions(permissions: string[]) {
    this.profilePermissions = permissions;
  }

  @Mutation
  public missingPermissions() {
    this.missingPermissionsError = true;
  }

  @Mutation
  setPreloadError(payload: boolean) {
    this.preloadError = payload;
  }

  @Mutation
  setTwoFactorError(value: boolean) {
    this.twoFactorError = value;
  }

  @Mutation
  setIPVerificationError(value: boolean) {
    this.ipVerificationError = value;
  }

  @Mutation
  setEnvironmentReloading(value: boolean) {
    this.environmentReloading = value;
  }

  @Mutation
  setEnvironmentError(value: any | null) {
    this.environmentError = value;
  }

  @Mutation
  setCompanyLogo(value?: string) {
    if (!!value) {
      this.companyLogo = value;
    } else {
      this.companyLogo = '';
    }
  }

  @Mutation
  setInitialCompanyLogo(value?: string) {
    if (!!value) {
      this.initialCompanyLogo = value;
    } else {
      this.initialCompanyLogo = '';
    }
  }

  @Mutation
  setUser(value: UserModel | null) {
    this.user = value;
  }

  @Mutation
  setCreateProfileResponse(value) {
    this.createProfileResponse = value;
  }

  @Mutation
  setUserBasicData(value) {
    this.userBasicData = value;
  }

  @Mutation
  setChooseTravellerMode(value) {
    this.chooseTravellerMode = value;
  }


  @Action
  public async getAvatar(profileId: string) {
    try {
      let avatarsResponse = await ProfileApi.getAvatar(profileId);
      if (avatarsResponse.status === 200 && avatarsResponse.data) {
        this.setProfileAvatar({
          id: profileId,
          avatar: BASE64_STRING_PREFIX + avatarsResponse.data, 
          usageTimestamp: new Date().getTime(),
        });
        return BASE64_STRING_PREFIX + avatarsResponse.data;
      }
    } catch (error) {
      return false;
    }
  }

  @Action
  public async init() {
    this.startLoading();
    try {
      const result = await ProfileApi.current();

      if (result && result.data) {
        const lang = result.data.profile.displayLanguage;
        this.setCurrentLanguage(lang);
        loadLanguageAsync(lang);
        moment.locale(lang);
      }

      const profile = result.data.profile;

      this.applyThemeToDocument(profile);
      await this.fetchAndApplyPermissions(profile.id);

      this.setCurrent(result.data);
    } catch (error) {
      const errorStatus = error.response && error.response.status;

      if (errorStatus === 401) {
        return;
      }
      if (
        errorStatus === 403 &&
        error.response.data.error &&
        error.response.data.error.details &&
        error.response.data.error.details.length
      ) {
        if (
          error.response.data.error.details[0].code === 'MFA_REQUIRED'
          ) {
            this.setTwoFactorError(true);
        }
        else if (
          error.response.data.error.details[0].code === 'IP_VERIFICATION_FAILED'
          ) {          
            this.setIPVerificationError(true);
        }
      }

      this.setPreloadError(true);
      this.setEnvironmentError(error);
    } finally {
      document.getElementById('preLoader')!.style.display = 'none';
      this.finishLoading();
    }
    if (this.current) {
      try {
        await this.fetchAndApplyCompanyLogo(this.current.profile.companyId);
        await this.fetchAndApplyUserAvatar(this.current.profile.id);
      } catch (error) {
        this.setEnvironmentError(error);
      }
    }
  }

  @Action
  applyThemeToDocument(profileData) {
    if (profileData.theme && profileData.theme.themeVersion) {
      const body  = document.getElementsByTagName('body')[0];
      const link  = document.createElement('link');
      link.id   = 'css' + profileData.theme.themeVersion;
      link.rel  = 'stylesheet';
      link.type = 'text/css';
      link.href = settings.apiWeb + 
        '/themes/' + profileData.theme.themeVersion + 
        '?version=' + buildData.version;
      link.media = 'all';
      body.appendChild(link);

      document.title = profileData.theme.themeName || appConst.defaultAppName;
    }
  }

  @Action
  async fetchAndApplyCompanyLogo(companyId) {
    let logosResponse = await this.getCompanyLogo(companyId);
    if (logosResponse && logosResponse.length) {
      this.setCompanyLogo(LOGO_BASE64_STRING_PREFIX + logosResponse[0].image);
      this.setInitialCompanyLogo(LOGO_BASE64_STRING_PREFIX + logosResponse[0].image);
    }
  }

  @Action
  async fetchAndApplyUserAvatar(profileId) {
    let avatarsResponse = await ProfileApi.getAvatar(profileId);
    if (avatarsResponse.status === 200 && avatarsResponse.data) {
      this.avatarCollection.push({
        id: profileId,
        avatar: BASE64_STRING_PREFIX + avatarsResponse.data,
        usageTimestamp: new Date().getTime(),
      });
    }
  }

  @Action
  async fetchAndApplyPermissions(profileId) {
    const permissionResult = await PermissionApi.profilePermissions(profileId);
    let profilePermissionCodes = permissionResult.data.map(
      x => x.permissionCode
    );
    if (profilePermissionCodes.length > 0) {
      this.setProfilePermissions(profilePermissionCodes);
      SearchStore.init();
      this.loadingSuccess();
    } else {
      this.missingPermissions();
    }
  }

  @Action
  public async reloadEnvironment() {
    this.setEnvironmentReloading(true);
    this.setEnvironmentError(null);
    try {
      const result = await ProfileApi.current();
      const profile = result.data.profile;

      if (profile.theme !== null && profile.theme.themeVersion) {
        const path = settings.apiWeb + 
          '/themes/' + profile.theme.themeVersion + 
          '?version=' + buildData.version;
        
        await http.execute({
          path,
          method: RequestMethod.GET,
        }, {}, {});
      }
    } catch (error) {
      this.setEnvironmentError(error);
    } finally {
      this.setEnvironmentReloading(false);
    }
  }

  @Action
  public async getCompanyLogo(companyId: string) {
    try {
      let response = await ThemesApi.getCompanyLogo(companyId);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return false;
    }
  }
}

export default getModule(AccountStore);
