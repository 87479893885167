import CustomFieldConfiguration from '@/modules/settings/custom-fields-configuration/CustomFieldConfiguration.vue';

export default {
  path: ':configurationId?/field-configuration/:isCopy?',
  name: 'field-configuration',
  component: CustomFieldConfiguration,
  displayName: 'Fields configuration',
  meta: {
    breadCrumb: 'Fields configuration',
    parentName: 'custom-fields',
    depthCategory: 'custom-fields',
    introClass: 'intro-settings',
    depth: 2,
  }
};