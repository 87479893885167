










import { Vue, Component, Watch } from 'vue-property-decorator';
import SearchTravellersList from './SearchTravellersList.vue';
import { router } from '@/router';
import SearchStore from './search.store';

@Component({
  components: {
    SearchTravellersList,
  }
})
export default class SearchTravellersIntro extends Vue {
  

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  @Watch('$route.params', { deep: true, immediate: true })
  onChildChanged(val) {
    if (!(val && val.searchId)) {
      if (this.travellersSearchList && this.travellersSearchList.travellers.length === 0) {
        router.push('/');
      }
    }
  }
}
