





































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import HotelSearchStore from '@/modules/search/hotel/hotel-search.store';
import HotelResultsRow from '@/modules/search/hotel/HotelResultsRow.vue';
import BasketStore from './basket.store';

@Component({
  components: {
    HotelResultsRow,
  }
})
export default class BasketEditHotelAgencyMarkupPopup extends Vue {
  @Prop() show!: boolean;

  showing: boolean = false;
  loading: boolean = false;
  isFeeValueChanging: boolean = false;
  agencyMarkupValue: any = 0;
  initialAgencyMarkupValue: number = 0;
  errors: any[] = [];



  get result() {
    return BasketStore.editedFeeItem;
  }

  get offer() {
    return BasketStore.editedFeeOffer;
  }

  get feeCurrency() {
    if (this.offer && this.offer.price && this.offer.price.agencyMarkup && this.offer.price.agencyMarkup.currency) {
      return this.offer.price.agencyMarkup.currency.symbol ? this.offer.price.agencyMarkup.currency.symbol : this.offer.price.agencyMarkup.currency.code; 
    }
    return '\u20AC';
  }

  get basePrice() {
    return this.offer.totalPrice.amount - this.initialAgencyMarkupValue;
  }

  get totalPrice() {
    return {
      amount: this.agencyMarkupValue + this.basePrice,
      currency: {
        ...this.offer.price.agencyMarkup.currency,
      },
    };
  }

  get basketId() {
    return this.$route.params.id;
  }



  @Emit('update:show')
  closePopup() {
    this.showing = false;
    HotelSearchStore.setEditedOffer({ offer: null, result: null });
    BasketStore.setEditedFeeItem(null);
    BasketStore.setEditedFeeOffer(null);
    return false;
  }

  async save() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      let agencyMarkup = {
        amount: this.agencyMarkupValue,
        currency: this.offer.price.agencyMarkup.currency,
      };
      const basePrice = this.basePrice;
      const response = await BasketItemApi.updateAgencyMarkupOnItem({
        basketId: this.basketId,
        tripItemId: this.offer.id,
        agencyMarkup,
      });
      if (response && response.data && response.data.items) {
        BasketStore.updatePricesForBasketItemsAndPaymentMethods(response.data.items);
        await BasketStore.reloadWizardSteps();
        EventBus.$emit('offer-fee-edited', {
          offerId: this.offer.id,
          agencyMarkupValue: this.agencyMarkupValue,
          totalPrice: this.agencyMarkupValue + basePrice,
        });
        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          message: translate('settings-fees.prices-updated')
        });
      }

      this.closePopup();
    } catch (errors) {
      this.errors = this.$handleErrors(errors, true);
    } finally {
      this.loading = false;
    }
  }

  @Watch('show')
  onShowChange(value) {
    if (value) {
      this.agencyMarkupValue = this.offer.price.agencyMarkup.amount;
      this.initialAgencyMarkupValue = this.agencyMarkupValue;
    }
    this.showing = value;
  }
}

