





















































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import { TravellerTypeMappings } from '@/const/traveller-types.const';

@Component({
})
export default class TrainPriceDetails extends Vue {
  @Prop() fare!: any;
  @Prop() travellers!: any;



  get travellersMapped() {
    if (!this.travellers || (this.travellers && this.travellers.length === 0)) {
      return [];
    }

    return this.travellers.map(traveller => {
      const fare = this.fare.passengerPrices
        .find(p => p && p.travellerId === traveller.id);

      return {
        ...traveller,
        fare,
      };
    });
  }



  userFullName(user) {
    if (user.isGuest) {
      return translate(TravellerTypeMappings[user.passengerTypeCode]);
    }
    return userFullName(user);
  }
}

