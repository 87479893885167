
























































































import { Vue, Component, Prop } from 'vue-property-decorator';

import SeatPlace from './SeatPlace.vue';
import ExitMarker from './ExitMarker.vue';
import SeatMapConst from '@/const/seat-map.const';

@Component({
  components: {
    SeatPlace,
    ExitMarker,
  },
})
export default class AirSeatMapLegend extends Vue {
  consts: any = SeatMapConst;

  get margin() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileMargin;
    } else {
      return SeatMapConst.margin;
    }
  }

  get rowHeight() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileRowHeight;
    } else {
      return SeatMapConst.rowHeight;
    }
  }

  get colWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileColWidth;
    } else {
      return SeatMapConst.colWidth;
    }
  }

  get extendedColWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileExtendedColWidth;
    } else {
      return SeatMapConst.extendedColWidth;
    }
  }
}
