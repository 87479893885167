import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import { Permission } from '@/const/permission.enum';
import { DelayTicketingConfigurationModel, Provider } from './delay-ticketing-configuration.model';

export class DelayTicketingConfigurationApiClass {
  public createConfiguration(requestMessage: DelayTicketingConfigurationModel): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/delay-ticketing',
      method: RequestMethod.POST,
      permission: Permission.CanEditDelayTicketing,
    }, {}, requestMessage );
  }

  public getConfiguration(configurationId: string): Promise<AxiosResponse<DelayTicketingConfigurationModel>> {
    return http.execute({
      path: settings.apiProfile + '/delay-ticketing/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.CanEditDelayTicketing,
    }, { configurationId }, {});
  }

  public updateConfiguration(configurationId: string, params: DelayTicketingConfigurationModel): Promise<AxiosResponse<DelayTicketingConfigurationModel>> {
    return http.execute({
      path: settings.apiProfile + '/delay-ticketing/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.CanEditDelayTicketing,
    }, { configurationId: configurationId }, { name: params.name, rules: params.rules,  rootCompanyId: params.rootCompanyId });
  }

  public getProviders(): Promise<AxiosResponse<Array<Provider>>> {
    return http.execute({
      path: settings.apiProfile + '/delay-ticketing/providers',
      method: RequestMethod.GET,
      permission: Permission.CanEditDelayTicketing,
    }, {}, {});
  }

}

export const DelayTicketingConfigurationApi: DelayTicketingConfigurationApiClass = new DelayTicketingConfigurationApiClass();