import VirtualCardsConfigurations from '@/modules/settings/virtual-cards/VirtualCardsConfigurations.vue';

export default {
  path: '',
  name: 'virtual-cards-configurations',
  component: VirtualCardsConfigurations,
  displayName: 'Virtual cards Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditVirtualCards',
    depthCategory: 'virtual-cards-configurations',
    parentName: 'virtual-cards-configurations',
    depth: 1,
  }
};