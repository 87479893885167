import Bookings from '@/modules/bookings/Bookings.vue';
import BookingsIntro from '@/modules/bookings/BookingsIntro.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/bookings',
  name: 'bookings',
  components: {
    default: Bookings,
    intro: BookingsIntro,
  },
  meta: {
    introClass: 'intro-trips',
    permission: 'ShowTripList',
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  },
};
