export interface CreatePaymentMethod {
  rootCompanyId?: string;
  name: string;
  paymentMethodConfigurations: Configuration[];
}

export interface Configuration {
  succeeded?: boolean;
  error?: Error;
  supplier: string;
  defaultPaymentMethod: string;
  isCentralPaymentEnabled: boolean;
  isCardPaymentEnabled: boolean;
}

export class PaymentMethodResults {
  configurationId: string = '';
  name?: string = '';
  paymentMethodConfigurations: ConfigurationResult[] = [];
  
  constructor(config?: any) {
    if (config) {
      if (config.configurationId !== undefined) {
        this.configurationId = config.configurationId;
      }
      if (config.name !== undefined) {
        this.name = config.name;
      }
      if (config.paymentMethodConfigurations !== undefined) {
        this.paymentMethodConfigurations = config.paymentMethodConfigurations;
      }
    }
  }
}

export interface ConfigurationResult {
  supplier: string;
  succeeded: boolean;
  error: Error;
}

export interface Error {
  code: string;
  message: string;
  messageEng: string;
  serviceTag: string;
  details: Detail[];
  devDetails: DevDetails;
  isTranslated: boolean;
  translationLanguage: string;
}

export interface DevDetails {
  extendedInfo: any;
  message: string;
  stacktrace: string;
  serverErrorSource: string;
  innerException: string;
}

export interface Detail {
  code: string;
  message: string;
  messageEng: string;
  field: string;
  params: Params;
  resourceId: string;
  isTranslated: boolean;
  translationLanguage: string;
}

export interface Params {
  additionalProp1: any;
  additionalProp2: any;
  additionalProp3: any;
}

export interface PaymentMethodConfiguration {
  rootCompanyId: string;
  name: string;
  paymentMethodConfigurations: Configuration[];
}