










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import wonderSearchConst from '@/const/wondersearch.const';
import { WonderSearchApi } from '@/api/wonder-search/wonder-search.api';
import { WonderModel } from '@/api/wonder-search/wonder-search.model';

@Component({})
export default class UiWonderSearch extends Vue {
  @Prop() startSearchHint!: Object;

  options: any[] = [];
  isLoading: boolean = false;
  wonderSearchParameters: any[] = [];
  asyncTimer: number | null = null;

  get placeHolderMessage() {
    if (!this.wonderSearchParameters || this.wonderSearchParameters.length === 0) {
      return this.$t('navigation-wondersearch.placeholder');
    }
    return '';
  }

  limitText(count) {
    return this.$t('navigation-wondersearch.limit-text', count);
  }

  delayedSearch(query, retrieveParametersFromApi) {
    let self = this;
    this.isLoading = true;

    if (this.asyncTimer) {
      clearTimeout(this.asyncTimer);
      this.asyncTimer = null;
    }
    
    this.asyncTimer = setTimeout(() => {
      self.asyncFind(query, retrieveParametersFromApi);
    }, 200);
  }

  refreshOptions(option, removing) {
    this.options = [];
    this.isLoading = true;
    let findRoutine = this.asyncFind;
    let delayedFindRoutine = this.delayedSearch;

    if (removing) {
      this.$emit(wonderSearchConst.EVENT_SEARCH_READY, null);
      setTimeout(() => {
        delayedFindRoutine('', true);
      }, 200);
    }
    else {
      this.wonderSearchParameters.push(option);
      findRoutine('', true);
    }
  }

  onRemove(option) {
    this.refreshOptions(option, true);
  }

  onSelect(option) {
    this.refreshOptions(option, false);
  }

  clearAll() {
    this.options = [];
    this.wonderSearchParameters = [];
  }

  async asyncFind(query, retrieveParametersFromApi) {
    this.isLoading = true;
    let wonderRequest: WonderModel = {
      currentEntry: query,
      parameters: _.uniqBy(this.wonderSearchParameters, (param: any) => {
        return param.type + param.name + param.value;
      }),
    };

    try {
      const response = await WonderSearchApi.wonderFind(wonderRequest);
      if (response && response.data) {
        this.options = response.data.hints;
        if (retrieveParametersFromApi && response.data.parameters) {
          this.wonderSearchParameters = response.data.parameters;
        }
        if (response.data.hints && response.data.hints.length > 0
          && response.data.hints[0].type === wonderSearchConst.ACTION_TRIGGER) {
          if (response.data.hints[0].value && response.data.hints[0].value.length > 0) {
            this.options = [];
            this.wonderSearchParameters = [];
            this.$emit(wonderSearchConst.EVENT_TRIGGER_ACTION, response.data.hints[0]);
          } else {
            this.$emit(wonderSearchConst.EVENT_SEARCH_READY, response.data.hints[0]);
          }
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  @Watch('startSearchHint')
  onSearchChanged() {
    if (this.startSearchHint) {
      this.wonderSearchParameters.push(this.startSearchHint);
      this.refreshOptions(this.startSearchHint, false);
    }
  }

  created() {
    this.asyncFind('', null);
  }
}
