














































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import {
  BasketRailBenerailOption,
  BasketRailBenerailSeatPreference,
  RailBenerailPreference,
} from '@/api/trip/basket.model';
import { Price } from '@/api/trip/trip.model';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import BasketItem from './BasketItem.vue';

const SEAT = {
  noPreference: null,
  selectAssignment: 'selectAssignment',
  withoutSeatReservation: 'withoutSeatReservation',
};

const NO_PREFERENCES = {
  value: SEAT.noPreference,
  get name() {
    return translate('basket.no-preference');
  },
};

const SELECT_ASSIGNMENT = {
  value: SEAT.selectAssignment,
  get name() {
    return translate('basket.seat-assignment');
  },
};

const WITHOUT_SEAT_RESERVATION = {
  value: SEAT.withoutSeatReservation,
  get name() {
    return translate('basket.without-seat-reservation');
  },
};

@Component({
  components: {
    BasketItem,
  },
})
export default class BasketRailBenerailSeatSelection extends Vue {
  @Prop() item!: any;

  $v;
  SEAT = SEAT;
  selectedTrainIndex: number = 0;

  @Validation()
  validationObject() {
    return {
      basketRailBenerailSeatPreferences: {
        $each: {
          options: {
            required(value: BasketRailBenerailOption[], { seatPreference }: BasketRailBenerailSeatPreference) {
              return seatPreference.value === SEAT.selectAssignment ? value.every(({ preferences }) => {
                return !!preferences && preferences.some((item) => !!item.value);
              }) : true;
            },
            $each: {
              preferences: {
                required: (preferences: RailBenerailPreference[] | null) => {
                  return !!preferences ? preferences.some((item) => !!item.value) : true;
                },
              },
            },
          },
        },
      },
    };
  }

  get travellers() {
    return SearchStore.getTravellersState.travellers;
  }

  get basket() {
    return BasketStore.basket;
  }

  get basketRailBenerailSeatPreferences() {
    return BasketStore.basketRailBenerailSeatPreferences;
  }

  get isBasketRailSeatsPreferencesLoading() {
    return BasketStore.basketRailSeatPreferencesLoading;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get currentBasketRailBenerailSeatPreference() {
    return this.basketRailBenerailSeatPreferences[this.selectedTrainIndex];
  }

  get seatPreferenceOptions() {
    const { options } = this.currentBasketRailBenerailSeatPreference;
    return [
      { ...NO_PREFERENCES },
      options.some(({ categories }) => categories.length > 0) ? { ...SELECT_ASSIGNMENT } : null,
      options.some(({ isOptional }) => isOptional) ? { ...WITHOUT_SEAT_RESERVATION } : null
    ].filter((item) => item);
  }

  isMainPassenger(id: string) {
    const traveller = this.travellers.find((item) => item.id === id);
    return traveller && traveller.isMainTraveller;
  }

  userFullName(user: {}) {
    return userFullName(user);
  }

  trainClick(index: number) {
    this.selectedTrainIndex = index;
    const element = (this.$refs.journey as HTMLElement[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  getPrice(seatPreference: RailBenerailPreference, price: Price) {
    return {
      ...price,
      amount: seatPreference.value === SEAT.withoutSeatReservation ? 0 : price.amount
    };
  }

  seatPreferenceSelect(seatPreference: RailBenerailPreference) {
    const model = this.basketRailBenerailSeatPreferences.map((item, index) => {
      if (index === this.selectedTrainIndex) {
        const options = item.options.map((option) => {
          const isIncluded = !(option.isOptional && seatPreference.value === SEAT.withoutSeatReservation);
          const preferences = seatPreference.value === SEAT.selectAssignment ? option.categories.map(({ preferences }) => preferences[0]) : null;
          return {
            ...option,
            isIncluded,
            preferences,
          };
        });
        return {
          ...item,
          seatPreference,
          options,
        };
      }
      return item;
    });
    BasketStore.setBasketRailBenerailSeatPreferences(model);
  }

  validate() {
    this.$v.$touch();
    BasketStore.setBasketRailBenerailSeatPreferencesError(this.$v.$invalid);
  }

  created() {
    EventBus.$on('validate-benerail-seats', this.validate);
  }

  beforeDestroy() {
    EventBus.$off('validate-benerail-seats', this.validate);
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    this.$v.$reset();
    const data = await BasketStore.getBasketRailBenerailSeatPreferences({
      id: this.item.providerReferenceId,
      supplier: this.item.supplier,
    });
    const legs = (data && data.legs) || [];
    const model = legs.reduce((prev, next) => {
      const { legNumber } = next;
      const trains = next.trains.map((train) => {
        const options = train.options.map((option) => {
          const categories = option.categories.map((category) => ({
            ...category,
            preferences: [{ ...NO_PREFERENCES }, ...category.preferences],
          }));
          return {
            ...option,
            categories,
            isIncluded: true,
            preferences: null,
          };
        });
        return {
          ...train,
          legNumber,
          options,
          seatPreference: { ...NO_PREFERENCES },
        };
      });
      return prev.concat(trains);
    }, [] as BasketRailBenerailSeatPreference[]);
    BasketStore.setBasketRailBenerailSeatPreferences(model);
  }

  @Watch('basketRailBenerailSeatPreferences')
  onSeatingOptionsChanged() {
    BasketStore.setPriceChangesForStep({
      tripItemId: BasketStore.bookingStepDef.tripItemId,
      step: BasketStore.bookingStep,
      type: 'seats',
      prices: BasketStore.basketRailBenerailSeatPreferences
        .reduce((prev, { seatPreference, options }) => {
          const prices = options.map(({ price }) => this.getPrice(seatPreference, price));
          return [...prev, ...prices];
        }, [] as Price[]),
    });
  }
}
