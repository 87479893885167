






















import { Vue, Component } from 'vue-property-decorator';
import settings from '@/settings';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AccountStore from '@/store/account.store';

@Component({})
export default class PKPSearch extends Vue {

  get user() {
    return AccountStore.current;
  }

  get pkpAgencyIds() {
    return settings.hasOwnProperty('pkpAgencyIds') ? JSON.parse(settings.pkpAgencyIds) : false;
  }

  get pkpUrl() {
    return settings.hasOwnProperty('pkpUrl') ? sanitizeUrl(settings.pkpUrl) : false;
  }

  get pkpDescription() {
    return settings.hasOwnProperty('pkpDescription') ? settings.pkpDescription : false;
  }
  
  get showPkpContent() {
    if (this.pkpAgencyIds && this.pkpAgencyIds.length > 0 && this.user!.profile.agencyId) {
      return this.pkpAgencyIds.some((pkpId) => pkpId === this.user!.profile.agencyId);
    } else {
      return false;
    }
  }
  
}

