



















































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import BasketCancellationStore from './basket-cancellation.store';

@Component({})
export default class BasketAirSabreRefundView extends Vue {
  @Prop() basketItemId!: string;
  @Prop() conditions!: any;

  selectedTicket: any = null;
  selectedTraveller: any = null;

  selectedOption: any = '';
  ticketRefundType = [
    {
      name: translate('refund.full-refund'),
      value: 'FullRefund',
      info: translate('refund.full-refund-info')
    },
    {
      name: translate('refund.partial-refund'),
      value: 'PartialRefund',
      info: translate('refund.partial-refund-info')
    },
    {
      name: translate('refund.full-tax-refund'),
      value: 'FullTaxRefund',
      info: translate('refund.full-tax-refund-info')
    },
    {
      name: translate('refund.partial-tax-refund'),
      value: 'PartialTaxRefund',
      info: translate('refund.partial-tax-refund-info')
    }
  ];

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };

  get buttonDisabled() {
    return !this.selectedTicket || !this.selectedTraveller;
  }

  get loadingCoupons() {
    return BasketCancellationStore.loadingCoupons;
  }

  get couponsLoaded() {
    return BasketCancellationStore.couponsLoaded;
  }

  get processing() {
    return BasketCancellationStore.processing;
  }

  get loadingDetails() {
    return BasketCancellationStore.loadingDetails;
  }

  get loading() {
    return this.processing || this.loadingCoupons || this.loadingDetails;
  }

  get sabreRefundProcessScreenLoaded() {
    return BasketCancellationStore.sabreRefundProcessScreenLoaded;
  }

  get couponsStatus() {
    return BasketCancellationStore.couponsStatus;
  }

  get sabreRefundProcessScreen() {
    return BasketCancellationStore.sabreRefundProcessScreen;
  }

  get processWarning() {
    return BasketCancellationStore.processWarning;
  }

  get forceCancelRefund() {
    return BasketCancellationStore.forceCancelRefund;
  }

  get refundError() {
    return BasketCancellationStore.refundError;
  }

  userFullName(user) {
    return userFullName(user);
  }

  get filteredTicketRefundType() {
    if (this.conditions.fullRefundOnly) {
      return [this.ticketRefundType[0]];
    }

    return this.ticketRefundType;
  }


  shouldDisableCheckbox(status) {
    let state = true;

    status.coupons.forEach(item => {
      if (item.status !== 'Refunded') {
        state = false;
      }
    });

    return state;
  }

  getStyle(value) {
    if (value > 0) {
      return 'width: ' + 10 * value + 'px;';
    }
    return 'width: ' + 10 + 'px;';
  }

  issueDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  back() {
    BasketCancellationStore.setIsSabreRefundChoose(false);
  }

  async beginProcess() {
    let request = {
      itemId: this.basketItemId,
      params: {
        profileId: this.selectedTraveller,
        type: this.selectedTicket
      }
    };

    await BasketCancellationStore.initSabreRefundProcess(request);
  }

  forceCancelRefundAction() {
    BasketCancellationStore.forceCancelRefundAction();
  }

  async updateProcess(button) {
    let inputs: any = [];
    this.sabreRefundProcessScreen.lines.forEach(line => {
      line.fields.forEach(field => {
        if (field.type === 'Input') {
          inputs.push(field);
        }
      });
    });
    let request = {
      itemId: this.basketItemId,
      params: {
        inputs: inputs,
        selectedButton: button
      }
    };
    await BasketCancellationStore.updateSabreCurrentRefundProcess(request);
  }

}
