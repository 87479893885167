































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VclCode } from 'vue-content-loading';

import { router } from '@/router';
import accountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import TrainSearchStore from './train-search.store';
import { serviceClassEnum } from '@/api/home/home.model';
import TrainResultsRow from './TrainResultsRow.vue';
import TrainResultsShortRow from './TrainResultsShortRow.vue';
import EditTrainFeePopup from './EditTrainFeePopup.vue';
import EditTrainAgencyMarkupPopup from './EditTrainAgencyMarkupPopup.vue';
import EarlierLaterConnections from './EarlierLaterConnections.vue';

@Component({
  components: {
    VclCode,
    TrainResultsRow,
    TrainResultsShortRow,
    EditTrainFeePopup,
    EditTrainAgencyMarkupPopup,
    EarlierLaterConnections,
  }
})
export default class TrainResults extends Vue {
  forcing: boolean = false;

  get recommendationsRailCount() {
    return TrainSearchStore.recommendationsRailCount;
  }

  get legNumber() {
    return this.$route.params.leg;
  }

  get totalOffers() {
    return TrainSearchStore.totalOffers;
  }

  get isRoundTrip() {
    return SearchStore.trainSearchParameters.searchMode === 'RoundTrip';
  }

  get filters() {
    return TrainSearchStore.filters;
  }

  get loading() {
    return TrainSearchStore.loading;
  }

  get offers() {
    return TrainSearchStore.offers;
  }

  get showPlaceholder() {
    return !this.filters ||
      (this.loading && this.filters.length === 0) ||
      (this.loading && this.offers.length === 0);
  }

  get disableOfferSelection() {
    return TrainSearchStore.selectingOffer;
  }

  get showError() {
    return TrainSearchStore.showError ||
      TrainSearchStore.moreActionsErrors.length > 0;
  }

  get errMessages() {
    return [
      ...TrainSearchStore.errMessages,
      ...TrainSearchStore.moreActionsErrors,
    ];
  }

  get showModal() {
    return TrainSearchStore.showModal;
  }

  get currentBasketId() {
    return BasketStore.basketId;
  }

  get basketId() {
    return TrainSearchStore.basketId;
  }

  get prevSelectedOffer() {
    return TrainSearchStore.prevSelectedOffer;
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get popupButtonsClasses() {
    return {
      'disabled': this.forcing,
    };
  }

  get popupForceButtonClasses() {
    return {
      'disabled': this.forcing,
      'disabled-active': this.forcing,
    };
  }

  get currentUser() {
    return accountStore.CurrentUser;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }

  AddOfferToCartForce() {
    if (this.forcing) {
      return;
    }
    this.forcing = true;
    this.AddOfferToCart(true);
    window.scrollTo(0, 0);
  }

  AddOfferToCart(force = false) {
    let request = {
      forceAdd: force,
      bookerId: this.currentUser!.profile.id,
      type: 'Rail',
      searchId: this.$route.params.searchId
    };

    TrainSearchStore.addOfferToCart(request);
  }

  GoToBasketWithoutChanges() {
    TrainSearchStore.stopSearch();
    router.push({
      name: 'basket',
      params: { id: this.basketId! }
    });
  }

  goToBasketWithoutModyfication() {
    TrainSearchStore.stopSearch();
    router.push({
      name: 'basket',
      params: { id: this.currentBasketId }
    });
  }

  async BackToResults() {
    let searchId = this.$route.params.searchId;
    let legNumber = this.$route.params.leg;
    TrainSearchStore.stopSearch();
    TrainSearchStore.setSelectedOfferId('');
    await TrainSearchStore.deleteSelectedLegAndGetSession({searchId, legNumber});
    TrainSearchStore.setShowModal(false);
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.uiError) {
        return;
      }
      ((this.$refs.uiError as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  @Watch('errMessages', {deep: true})
  onErrorShow() {
    this.scrollToError();
  }

  @Watch('$route.params.searchId', { immediate: true })
  onSearchIdChange() {
    TrainSearchStore.setFiltersChanging(false);
    TrainSearchStore.setTotalOffers(0);
    TrainSearchStore.setRecommendationsRailCount(0);
    if (!this.modifyMode) {
      return;
    }
    const basketId = this.$route.params.basketId;
    const tripItemId = this.$route.params.tripItemId;
    TrainSearchStore.setExchangeLoaded(false);
  }


  @Watch('$route.params', { immediate: true, deep: true })
  onLegChange(to, from) {
    if (to && from && to.searchId === from.searchId && to.leg === from.leg) {
      return;
    }
    if (from === undefined || to.leg !== from.leg) {
      TrainSearchStore.setTotalOffers(0);
      TrainSearchStore.setRecommendationsRailCount(0);
    }
    TrainSearchStore.setFiltersChanging(false);
    TrainSearchStore.setOffers([]);
    let legNumber = this.$route.params.leg ? this.$route.params.leg : 1;
    let searchId = this.$route.params.searchId;

    if (!searchId || searchId.length === 0 || ('-' === searchId)) {
      return;
    }

    TrainSearchStore.getOffers({searchId, legNumber});
    SearchStore.selectNavigated(serviceClassEnum.Train);
    SearchStore.select(serviceClassEnum.Train);
  }

  beforeDestroy() {
    TrainSearchStore.clearErrMessages();
    TrainSearchStore.stopSearch();
  }
}
