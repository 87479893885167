import SecurityConfiguration from '@/modules/settings/security/SecurityConfiguration.vue';

export default {
  path: ':configurationId?/security-configuration',
  name: 'security-configuration',
  component: SecurityConfiguration,
  displayName: 'Security Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditSecurityConfiguration',
    parentName: 'security-configurations',
    depthCategory: 'security-configurations',
    depth: 2,
  }
};