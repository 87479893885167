



















































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import PnrRemarksStore from '@/modules/settings/pnr-remarks/pnr-remarks.store';
import SettingsStore from '@/modules/settings/settings.store';
import EventHandler from '@/services/event-handler';
import handleErrors from '@/core/errors/handle-errors.service';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class PnrRemarksConfigurations extends BaseTable<ConfigurationRow> {
  useQueryParams: boolean = true;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      sortable: false,
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  loading: boolean = false;
  items: ConfigurationRow[] = [];
  company = this.currentCompany;
  menuActive: boolean = false;
  configuration: ConfigurationRow | null = null;
  removePopup: boolean = false;
  serverErrors: any[] = [];
  errorsOnPopup: any[] = [];
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isRootCompany() {
    return this.currentCompany!.isRoot;
  }

  get count() {
    return this.items.length;
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.companyId + '/pnr-remarks/' + item.id + '/pnr-remarks-configuration');
  }

  rowClicked(item, preventRedirect = false) {
    if (item) {
      this.resetData();
      this.configuration = item;
      PnrRemarksStore.setCurrentConfigurationName(item.name);

      if (!preventRedirect) {
        if (this.$hasAccess('WritePnrRemarks')) {
          router.push({
            name: 'pnr-remarks-configuration',
            params: {
              configurationId: item.id
            }
          });
        } else {
          router.push({
            name: 'pnr-remarks-configuration',
            params: {
              configurationId: item.id
            },
            query: {
              readOnly: 'true'
            }
          });
        }
      }
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  resetData() {
    PnrRemarksStore.clearData();
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.configuration!.id, Permission.WritePnrRemarks);
      this.reload();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-approval.configuration-removed'),
        message: translate('settings-approval.info-removed')
      });
    }
    catch (error) {
      this.errorsOnPopup = handleErrors(error, true);
      this.loading = false;
    }
    finally {
      if (!this.errorsOnPopup.length) {
        this.removePopup = false;
      }
    }
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    this.serverErrors = [];
    try {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      const result = await ProfileConfigurationsApi.getByRootCompanyId(
        this.company!.rootCompanyId,
        companyConfigurationType.pnrRemarksConfiguation,
        this.params.size,
        this.params.page,
        Permission.ReadPnrRemarks,
        request.searchName
      );

      if (result && result.data) {
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      }
    } catch (error) {
      this.serverErrors = handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  togglePnrRemarksConfigurationMenu(item) {
    if (!this.configuration || this.configuration.id !== item.id) {
      this.configuration = item;
      this.menuActive = true;
    } else {
      this.configuration = item;
      this.menuActive = !this.menuActive;
    }
  }
}

