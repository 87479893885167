import TrainResults from '@/modules/search/train/TrainResults.vue';
import TrainSubintro from '@/modules/search/train/TrainSubintro.vue';
import TrainResultsFilters from '@/modules/search/train/TrainResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';

export default {
  path: '/trainResultsTravellers/:searchId/:leg',
  name: 'trainTravellers',
  components: {
    default: TrainResults,
    intro: SearchTravellersIntro,
    subintro: TrainSubintro,
    sidebar: TrainResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-train-results',
    permission: 'SelectTravellersForSearch',
    transitions: {
      intro: {
        search: AnimationName.UP,
        home: AnimationName.LEFT,
        train: AnimationName.UP,
      },
    }
  }
};