




import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiIcon extends Vue {
  @Prop() icon!: string;
}
