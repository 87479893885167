import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import { http,
  RequestMethod 
} from '@/services/http';
import settings from '@/settings';
import {
  InitCarSearchMessage,
  OffersQuery,
  FareConditionsMessage,
  AgencyMarkup
} from './car-search.model';

class CarApiClass {
  public initCarSearch(params: InitCarSearchMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/search',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public initCarSearchForMission(params: InitCarSearchMessage, missionId): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/search?missionId={missionId}',
      method: RequestMethod.POST,
    }, { missionId }, params);
  }
  
  public addSegment(params: InitCarSearchMessage, basketId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/search?basketId={basketId}',
      method: RequestMethod.POST,
    }, { basketId }, params);
  }

  public addSegmentForMission(params: InitCarSearchMessage, basketId: string, missionId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/search?basketId={basketId}&missionId={missionId}',
      method: RequestMethod.POST,
    }, { basketId, missionId }, params);
  }

  public getCarSearchSession({ searchId }): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/search/{searchId}/session',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public getCarSearchState(searchId, stateId, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + '/search/{searchId}/state?stateId={stateId}',
      method: RequestMethod.GET,
    }, { searchId, stateId }, {}, false, cancelTokenSource);
  }

  public getCarOffers(query: OffersQuery, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiCarEngine + `/search/{searchId}/result?${query.sortQuery}`,
      method: RequestMethod.GET,
    }, query, {}, false, cancelTokenSource);
  }

  public updateFilterOffers(searchId, params, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiCarEngine + '/search/{searchId}/filter',
      method: RequestMethod.PUT,
    }, searchId, params, false, cancelTokenSource);
  }

  public getFareConditions(message: FareConditionsMessage, offerId: string) {
    return http.execute({
      path: settings.apiCarEngine + `/offers/{offerId}/get-conditions`,
      method: RequestMethod.POST,
    }, { offerId }, message);
  }

  public setAgencyMarkupFilters(searchId: string, request: any): Promise<AxiosResponse<AgencyMarkup>> {
    return http.execute({
      path: settings.apiCarEngine + '/search/{searchId}/offers/agency-markup',
      method: RequestMethod.PUT,
    }, { searchId }, request, false);
  }
}

export const CarApi: CarApiClass = new CarApiClass();