

































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class ExportConfirmationPopup extends Vue {
  @Prop() show!: boolean;
  @Prop({ default: false }) loading!: boolean;

  @Emit('update:show')
  hidePopup() {
    return false;
  }

  @Emit('confirmed')
  confirm() {
    return true;
  }
}

