

















import { Vue, Component, Prop } from 'vue-property-decorator';

const eventName = 'change';

@Component
export default class UiTextFit extends Vue {
  @Prop({ default: 30 }) minHeight!: number;
  isLoading: boolean = true;
  height: number = 30;
  width: number = 10;
  scale: number = 1;
  interval: any = -1;

  get styles() {
    if (this.isLoading) {
      return {};
    }
    return {
      'height': this.height + 'px',
      'min-height': this.minHeight + 'px',
    };
  }

  get classes() {
    return {
      'ui-text-fit--ready': !this.isLoading,
    };
  }

  get innerStyles() {
    if (this.isLoading) {
      return {};
    }

    return {
      width: this.width + 'px',
      transform: 'scale(' + this.scale + ')',
    };
  }

  getHeight() {
    const inner = this.$refs.inner as HTMLElement;
    const bounds = inner.getBoundingClientRect();

    this.height = Math.ceil(bounds.height);
  }
  
  mounted() {
    const inner = this.$refs.inner as HTMLElement;
    const outer = this.$refs.outer as HTMLElement;
    const bounds = inner.getBoundingClientRect();
    const obounds = outer.getBoundingClientRect();

    this.height = bounds.height;

    if (obounds.width < bounds.width) {
      this.scale = Math.floor(100 * obounds.width / bounds.width) / 100;
      this.width = bounds.width;
    }

    this.isLoading = false;

    this.interval = setInterval(this.getHeight, 50);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }
}

