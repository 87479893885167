































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
  },
})
export default class BasketStepsBreadcrumbItem extends Vue {
  @Prop() step: any;
  @Prop() group: any;
  @Prop() groups: any;

  get isPostTicketing() {
    return BasketStore.postTicketingExtrasBooking;
  }

  get isGhostStep() {
    return this.isPostTicketing && this.step.code === 'TRAVELLERS_CHECK_COMBINED';
  }

  get stepFilled() {
    if (this.isGhostStep) {
      return true;
    }
    return this.bookingStep - 1 > this.step.index;
  }

  get currentStep() {
    if (this.isGhostStep) {
      return false;
    }
    return this.bookingStep - 1 === this.step.index;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get isNextStep() {
    const num = this.bookingStep;
    let stepsBetween = 0;
    let foundCurrentStep = false;

    this.groups.forEach(group => {
      group.steps.forEach(step => {
        if (!foundCurrentStep && step.index === num - 1) {
          foundCurrentStep = true;
        } else if (foundCurrentStep && step.index !== this.step.index) {
          stepsBetween++;
        } else if (foundCurrentStep && step.index === this.step.index) {
          foundCurrentStep = false;
        }
      });
    });

    return 0 === stepsBetween;
  }

  get isFirstStep() {
    const firstGroup = this.groups[0];

    if (firstGroup === this.group) {
      return true;
    }
    
    return false;
  }

  get isLastStep() {
    const lastGroup = this.groups[this.groups.length - 1];

    if (lastGroup !== this.group) {
      return false;
    }
    const lastStep = lastGroup.steps[lastGroup.steps.length - 1];

    return this.bookingStep === lastStep.index + 1;
  }

  get isActive() {
    if (this.currentStep) {
      return false;
    }

    return this.isNextStep || 
      (this.bookingStep >= this.step.index && 
      this.bookingStep - 1 !== this.step.index);
  }

  clickItem() {
    if (!this.isActive) {
      return;
    }

    if (this.bookingStep <= this.step.index) {
      BasketStore.continue();
      return;
    }

    BasketStore.goBackToStep(this.step.index);
  }
}
