import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

class DisplayPreferencesApiClass {

  public getConfiguration(configurationId) {
    return http.execute({
      path: settings.apiProfile + '/display-preferences/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public createConfiguration(configuration) {
    return http.execute({
      path: settings.apiProfile + '/display-preferences',
      method: RequestMethod.POST
    }, {}, configuration);
  }

  public updateConfiguration(configurationId, configuration) {
    return http.execute({
      path: settings.apiProfile + '/display-preferences/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, configuration);
  }
}

export const DisplayPreferencesApi: DisplayPreferencesApiClass = new DisplayPreferencesApiClass();
