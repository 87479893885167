import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  CompareModel,
  CompareOfferDetails,
} from './compare-offers.model';

class CompareOffersApiClass {
  public compareOffers(params): Promise<AxiosResponse<CompareModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/compare/send',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public offerDetails(params): Promise<AxiosResponse<CompareOfferDetails[]>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/offer-details',
      method: RequestMethod.POST,
    }, {}, params, true);
  }
}

export const CompareOffersApi: CompareOffersApiClass = new CompareOffersApiClass();