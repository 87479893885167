var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"uiSelectWrapper"},[_c('multiselect',_vm._g(_vm._b({staticClass:"xs-hidden-mobile",attrs:{"value":_vm.value,"searchable":false,"options":_vm.options,"multiple":false,"seleniumId":_vm.seleniumId,"label":_vm.label,"track-by":_vm.trackBy,"disabled":_vm.disabled,"allow-empty":_vm.allowEmpty,"custom-label":_vm.customLabel,"id":_vm.currentId,"show-labels":_vm.showLabels,"placeholder":_vm.placeholder},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'multiselect',_vm.$attrs,false),_vm.listeners),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2),_c('div',{staticClass:"desktop-hidden xs-visible-mobile"},[_c('div',{staticClass:"ui-select__mobile",class:{'ui-select__mobile--disabled': _vm.disabled}},[_c('div',{ref:"mobileField",staticClass:"ui-select__mobile-field",attrs:{"tabindex":"0","seleniumId":'Mobile' + _vm.seleniumId,"id":'Mobile' + _vm.currentId},on:{"!click":function($event){return _vm.mobileFieldClick()}}},[(_vm.isSimple)?[((_vm.$scopedSlots.singleLabel || _vm.$slots.singleLabel))?[(_vm.value !== null)?_vm._t("singleLabel",null,null,{
                option: _vm.value,
              }):_c('span',[_vm._v(" ")])]:_c('span',[_vm._v(_vm._s(_vm.value)),(0 === ('' + _vm.value).length)?[_vm._v(" ")]:_vm._e()],2)]:[((_vm.$scopedSlots.singleLabel || _vm.$slots.singleLabel))?[(_vm.value !== null)?_vm._t("singleLabel",null,null,{
                option: _vm.value,
              }):_c('span',[_vm._v(" ")])]:_c('span',[_vm._v(_vm._s(_vm.getValueText(_vm.value))),(0 === ('' + _vm.getValueText(_vm.value)).length)?[_vm._v(" ")]:_vm._e()],2)]],2),(_vm.isActive)?_c('portal',{attrs:{"to":"mobile-dropdown"}},[_c('div',{staticClass:"ui-select__mobile-view",class:_vm.mobileViewClasses},[('' !== _vm.popupTitle)?_c('div',{staticClass:"ui-select__mobile-popup-title"},[_vm._v("\n            "+_vm._s(_vm.popupTitle)+"\n          ")]):_vm._e(),_c('div',{staticClass:"ui-select__mobile-field-label"},[(_vm.$slots['field-label'])?_vm._t("field-label"):_vm._e(),(!_vm.$slots['field-label'])?[(!_vm.foundLabel)?[_vm._v(" ")]:_vm._e(),_vm._v("\n              "+_vm._s(_vm.foundLabel)+"\n            ")]:_vm._e()],2),_c('div',{ref:"mobileField",staticClass:"ui-select__mobile-field",attrs:{"tabindex":"0","seleniumId":'Mobile' + _vm.seleniumId,"id":'Mobile' + _vm.currentId},on:{"!click":function($event){return _vm.confirm()}}},[(_vm.isSimple)?[((_vm.$scopedSlots.singleLabel || _vm.$slots.singleLabel))?[(_vm.value !== null)?_vm._t("singleLabel",null,null,{
                    option: _vm.value,
                  }):_c('span',[_vm._v(" ")])]:_c('span',[_vm._v(_vm._s(_vm.value)),(0 === ('' + _vm.value).length)?[_vm._v(" ")]:_vm._e()],2)]:[((_vm.$scopedSlots.singleLabel || _vm.$slots.singleLabel))?[(_vm.value !== null)?_vm._t("singleLabel",null,null,{
                    option: _vm.value,
                  }):_c('span',[_vm._v(" ")])]:_c('span',[_vm._v(_vm._s(_vm.getValueText(_vm.value))),(0 === ('' + _vm.getValueText(_vm.value)).length)?[_vm._v(" ")]:_vm._e()],2)]],2),_c('div',{staticClass:"ui-select__mobile-options",on:{"!click":function($event){return _vm.mobileOptionsClick($event)}}},_vm._l((_vm.options),function(item,index){return _c('div',{key:'select_mobile_option' + index,staticClass:"ui-select__mobile-option option-item",class:_vm.classesForIndex(item, index),attrs:{"data":index},on:{"touchstart":function($event){return _vm.onTouchStart(index)},"touchend":function($event){return _vm.onTouchEnd(index)}}},[(_vm.$scopedSlots.option)?_vm._t("option",null,null,{
                  option: item,
                }):_vm._e(),(!_vm.$scopedSlots.option)?[_vm._v(_vm._s(_vm.getOptionText(item)))]:_vm._e(),(0 === ('' + item).length)?[_vm._v("\n                 \n              ")]:_vm._e()],2)}),0)])]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }