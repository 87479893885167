import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  SystemMarkupConfig,
  SystemMarkupConfigurationResult
} from './system-markup-configuration.model';
import { Permission } from '@/const/permission.enum';

class AirSystemMarkupConfigurationApiClass {
  public create(params: SystemMarkupConfig): Promise<AxiosResponse<SystemMarkupConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/system-markup-configurations',
      method: RequestMethod.POST,
      permission: Permission.CanEditSystemMarkupConfiguration,
    }, {}, params);
  }

  public getById(configurationId: string): Promise<AxiosResponse<SystemMarkupConfig>> {
    return http.execute({
      path: settings.apiAirEngine + '/system-markup-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.CanEditSystemMarkupConfiguration,
    }, { configurationId }, {});
  }

  public update(configurationId: string, params: SystemMarkupConfig): Promise<AxiosResponse<SystemMarkupConfigurationResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/system-markup-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.CanEditSystemMarkupConfiguration,
    }, { configurationId }, params);
  }
}

export const AirSystemMarkupConfigurationApi: AirSystemMarkupConfigurationApiClass = new AirSystemMarkupConfigurationApiClass();