import RailProviderConfigurations from '@/modules/settings/configurations/RailProviderConfigurations.vue';

export default {
  path: '',
  name: 'rail-provider-configurations',
  component: RailProviderConfigurations,
  displayName: 'Rail providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadRailProviders',
    depthCategory: 'rail-providers',
    depth: 1,
    parentName: 'rail-provider-configurations',
  },
};