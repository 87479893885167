































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import UiAvatar from '@/controls/UiAvatar.vue';

@Component({
  components: {
    UiAvatar
  },
})
export default class Home extends Vue {}

