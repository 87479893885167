










































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import ProjectsStore from './projects.store';
import  { ProjectsApi } from '@/api/profile/projects.api';
import { ProjectModel } from '@/api/profile/projects.model';
import { Permission } from '@/const/permission.enum';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class ProjectDefinitions extends BaseTable<ProjectModel[]> {
  @Prop({}) readonly!: boolean;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: ProjectModel | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    name: {
      sortable: false,
      label: translate('settings-projects.name'),
    },
    description: {
      sortable: false,
      label: translate('settings-projects.description'),
    },
    code: {
      sortable: false,
      label: translate('settings-projects.code'),
    },
    active: {
      sortable: false,
      label: translate('settings-projects.active'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  errors: any[] = [];
  results: any[] = [];

  get currentCompany() {
    return ProjectsStore.companyId;
  }

  get projectsList() {
    return ProjectsStore.projects;
  }

  get configuration() {
    return ProjectsStore.project;
  }

  get configurationId() {
    if (this.$route.params.configurationId) {
      return this.$route.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.projectsList.length || 0;
  }

  toggleMenu(item) {
    if (this.selected !== item) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    router.push({
      name: 'definition',
      params: {
        configurationId: this.$route.params.configurationId,
        itemId: item.id
      }
    });
  }

  resetData() {
    ProjectsStore.setProjects([]);
  }

  async submitForm() {
    this.resetData();
    this.reload();
  }

  async getItems() {
    try {
      this.loading = true;
      await ProjectsStore.getProjectsList(this.$route.params.configurationId);
      return {
        page: {
          pageSize: this.params.size,
          pageNo: this.params.start,
          moreResultsAvailable: false,
        },
        results: ProjectsStore.projects,
      };
    } catch (error) {
      this.errors = $handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  async removeField() {
    try {
      await ProjectsApi.deleteProject(this.selected!.id, Permission.WriteProjects);
      this.removePopup = false;
      const obj = {
        type: translate('common.success'),
        title: translate('settings-projects.configuration-removed'),
        message: translate('settings-projects.project-removed-message')
      };
      EventHandler.$emit('show-toast', obj);
      await this.submitForm();
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }
}
