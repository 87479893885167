




















import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import EventBus from '@/services/event-handler';
import SearchConst from '@/const/search.const';
import moment from 'moment';
import { translate } from '@/i18n';

const updateAirSearchParameters: string = 'update:airSearchParameters';

@Component({})
export default class SearchDatePicker extends Vue {
  @Prop() airSearchParameters!: any;
  @Prop() leg!: any;
  @Prop() searchModes!: any;

  valueTimeDeparture = {
    value: -1,
    label: translate('common.any-hour'),
    timeRange: [0, 1439]
  };

  get valueTimeOptions() {
    return SearchConst.airSearchTimeWindows;
  }

  get datePickerTimeLabels() {
    return {
      start: translate('search-air.departure-at'),
      end: translate('search-air.return-at'),
    };
  }

  get searchDate() {
    return moment(this.leg.departureDate).toDate();
  }

  set searchDate(data: any) {
    if (data) {
      this.leg.departureDate = data;
      this.$emit('check-order');
      this[updateAirSearchParameters](this.leg);
    }
  }

  handleEnterPress() {
    EventBus.$emit('focus-on-search');
  }

  refreshTimeWindows() {
    if (this.leg.timeWindows) {
      let window = this.leg.timeWindows.departureWindow ? this.leg.timeWindows.departureWindow : this.leg.timeWindows.timeRange;
      this.valueTimeDeparture = this.findTimeRange(window) as any;
    }
  }

  findTimeRange(range) {
    const windowFound = SearchConst.airSearchTimeWindows.find((window) => {
      return window.timeRange[0] === range[0] && window.timeRange[1] === range[1];
    });
    return windowFound ? windowFound : SearchConst.airSearchTimeWindows[0];
  }

  clickdate(legNumber) {
    if (this.$refs['airDeparture' + legNumber]) {
      const buttonElement = (this.$refs['airDeparture' + legNumber] as Vue).$el as HTMLInputElement;
      if (buttonElement) {
        buttonElement.getElementsByTagName('input')[0].focus();
      }
    }
  }

  @Watch('leg.departureDate')
  onChangedDeparture(val) {
    if (val) {
      this.leg.departureDate = moment(this.leg.departureDate).format('YYYY-MM-DD');
    }
    if (new Date(val).getTime() < new Date().getTime()) {
      this.leg.departureDate = moment().format('YYYY-MM-DD');
    }
    this[updateAirSearchParameters](this.leg);
  }

  @Watch('valueTimeDeparture', { deep: true })
  onChangedDepartureTimeWindow(val) {
    if (val) {
      this.leg.timeWindows = {
        departureWindow: val.timeRange,
        arrivalWindow: null
      };
      this[updateAirSearchParameters](this.leg);
    }
    this.$emit('check-order');
  }

  @Watch('airSearchParameters.searchMode', { immediate: true, deep: true })
  onChangedSearchMode() {
    this[updateAirSearchParameters](this.leg);
    this.refreshTimeWindows();
  }

  @Emit()
  [updateAirSearchParameters](value) {
    return value;
  }

  beforeDestroy() {
    EventBus.$off('focus-on-date', this.clickdate);
  }

  created() {
    EventBus.$on('focus-on-date', this.clickdate);
  }
}

