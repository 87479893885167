import {
  getModule,
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'displayPreferences'
})
class DisplayPreferencesStore extends VuexModule {
  currentSelectedItem: string = 'Flight';
  currentConfigName: string = '';
  loading: boolean = false;
  errors: any[] = [];
  isFromManage: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get getCurrentSelectedItem() {
    return this.currentSelectedItem;
  }

  get getErrors() {
    return this.errors;
  }

  @Mutation
  setCurrentConfigName(payload) {
    this.currentConfigName = payload;
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setIsFromManage(payload) {
    this.isFromManage = payload;
  }

  @Mutation
  clearErrors() {
    this.errors = [];
  }

  @Mutation
  setErrors(payload) {
    this.errors = $handleErrors(payload, true);
  }

  @Mutation
  setCurrentSelectedItem(payload) {
    this.currentSelectedItem = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }
}

export default getModule(DisplayPreferencesStore);