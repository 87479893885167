import Plugins from '@/modules/settings/plugins/Plugins.vue';
import PluginsList from './plugins-configurations.route';
import PluginsConfig from './plugins-configuration.route';

export default {
  path: 'plugins-configurations',
  component: Plugins,
  displayName: 'settings-sidebar.plugins',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditPlugins',
    icon: 'extension',
    breadCrumb: 'Plugins configuration',
    parentName: 'plugins-configurations',
  },
  children: [
    PluginsList,
    PluginsConfig
  ],
};