















































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { numeric, maxLength } from 'vuelidate/lib/validators';
import _ from 'lodash';

import { userFullName } from '@/core/user-full-name';
import BasketStore from './basket.store';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import BasketItem from './BasketItem.vue';
import consts from '@/const/rail-seat-map.const';
import priceConst from '@/const/price.const';
import AccountStore from '@/store/account.store';
import { BasketItemApi } from '@/api/trip/basket-item.api';

@Component({
  components: {
    BasketItem,
  },
  filters: {
    capitalize(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
})
export default class BasketRailSncfSeatSelection extends Vue {
  @Prop() item!: any;
  $v;

  selectedJourneyId: string = '';
  travellerPicked: any = null;
  isProviderError: boolean = false;
  emptyOption: any = {
    value: null,
    label: translate('basket.no-preference'),
  };
  emptySeatsErrors: any = [{
    message: translate('basket.no-seats-selected')
  }];
  seatPreferenceOptions: any[] = [
    {
      value: null,
      label: translate('basket.no-preference'),
      isEnabled: (preferencesAvailable, selectedJourneyObject) => true,
    },
    {
      value: 'SeatMap',
      label: translate('basket.choose-seat-map'),
      isEnabled: (preferencesAvailable, selectedJourneyObject) => {
        return selectedJourneyObject && selectedJourneyObject.train && selectedJourneyObject.train.seatMapAvailable;
      },
    },
    {
      value: 'Assignment',
      label: translate('basket.seat-assignment'),
      isEnabled: (preferencesAvailable, selectedJourneyObject) => preferencesAvailable,
    },
    {
      value: 'Specified',
      label: translate('basket.seat-next-to-specified'),
      isEnabled: (preferencesAvailable, selectedJourneyObject) => preferencesAvailable,
    },
  ];
  showingSeatMap: boolean = false;
  seatMapVisible: boolean = false;
  seatMapLoading: boolean = true;
  seatMapErrors: any[] = [];
  selectedSeats: any = [];
  data: any = null;



  @Validation()
  validationObject() {
    return {
      railPreferences: {
        $each: {
          coachNumber: {
            required: (value, parent) => {
              return parent.selectedPreferenceType && parent.selectedPreferenceType.value === 'Specified' ? value.length : true;
            },
            numeric,
            maxLength: maxLength(5),
          },
          seatNumber: {
            required: (value, parent) => {
              return parent.selectedPreferenceType && parent.selectedPreferenceType.value === 'Specified' ? value.length : true;
            },
            numeric,
            maxLength: maxLength(5),
          },
          seatSelections: {
            required: (value, parent) => {
              return parent.selectedPreferenceType && parent.selectedPreferenceType.value === 'SeatMap' ? value.length === this.travellers.length : true;
            },
          },
          selectedPreferences: {
            required: (value, parent) => {
              return this.isAssingmentValid(value, parent) ;
            }
          }
        }
      },
    };
  }



  get seatPreferenceOptionsForJourney() {
    return this.seatPreferenceOptions.filter(item => item.isEnabled(this.preferencesAvailable, this.selectedJourneyObject));
  }

  get primaryColor() {
    return AccountStore.current!.profile.theme.primaryColor || consts.sncf.primaryColor;
  }

  get secondaryColor() {
    return consts.sncf.secondaryColor;
  }

  get ternaryColor() {
    return consts.sncf.ternaryColor;
  }

  get quaternaryColor() {
    return consts.sncf.quaternaryColor;
  }

  get currentSelectedSeats() {
    return this.selectedSeats.filter(item => item.segmentId === this.selectedJourneyId);
  }

  get isBasketRailSeatsLoading() {
    return BasketStore.basketRailSeatPreferencesLoading;
  }

  get basket() {
    return BasketStore.basket;
  }

  get travellers() {
    return this.basket!.travellers;
  }

  get railPreferences() {
    return BasketStore.basketRailSncfSeatPreferences;
  }

  get currentWizardStep() {
    return BasketStore.wizardSteps[BasketStore.bookingStep - 1];
  }

  get errors() {
    return BasketStore.errors;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get trains() {
    return this.railPreferences.map(e => e.train).sort((a, b) => {
      return (new Date(a.departure) as any) - (new Date(b.departure) as any);
    });
  }

  get selectedJourneyObject() {
    return this.railPreferences.find(e => {
      return e.segmentId === this.selectedJourneyId;
    });
  }

  get currentModel() {
    return this.railPreferences.find(e => {
      return e.segmentId === this.selectedJourneyId;
    });
  }

  get preferencesAvailable() {
    return this.currentModel && this.currentModel.assignmentPreferences && this.currentModel.assignmentPreferences.length;
  }

  get selectionAvailable() {
    return this.seatPreferenceOptionsForJourney.length > 0;
  }

  get selectedPreferenceType() {
    return this.currentModel && this.currentModel.selectedPreferenceType ? this.currentModel.selectedPreferenceType.value : '';
  }

  get preferencesError() {
    return BasketStore.basketRailSncfSeatPreferencesError;
  }

  get formError() {
    return this.$v.$invalid;
  }

  get validationIndex() {
    return this.railPreferences.findIndex(pref => {
      return pref.segmentId === this.currentModel.segmentId;
    });
  }

  get hasAnySeatsErrors() {
    if (!this.$v.$dirty) {
      return false;
    }

    return this.railPreferences.filter(preferences => {
      return preferences.selectedPreferenceType.value === 'SeatMap' && preferences.hasError;
    }).length > 0;
  }

  knownName(item) {
    if (!item.name) {
      return 'other';
    }

    return item.name;
  }

  userFullName(user) {
    return userFullName(user);
  }

  selectJourney(journey, index) {
    this.selectedJourneyId = journey.segmentId;

      const element = (this.$refs.journey as any[])[index];
      EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  travellerNumber(profileId) {
    return this.travellers.findIndex(t => t.id === profileId) + 1;
  }

  validate() {
    this.$v.$touch();
    this.updateSeatsInRailPreferences();
  }

  updateSeatsInRailPreferencesHasError() {
    this.railPreferences
      .filter(preferences => preferences.selectedPreferenceType.value === 'SeatMap')
      .forEach(preferences => {
        if (preferences.segmentId === this.selectedJourneyId) {
          preferences.seatSelections = this.selectedSeats.filter(seat => seat.segmentId === preferences.segmentId);
        }
        preferences.hasError = preferences.seatSelections.length < this.travellers.length;
      });
  }

  updateSeatsInRailPreferences() {
    this.updateSeatsInRailPreferencesHasError();
    this.railPreferences.forEach(preferences => {
      if (preferences.selectedPreferenceType.value === 'SeatMap') {
        preferences.seatSelections = this.selectedSeats.filter(seat => seat.segmentId === preferences.segmentId);
        preferences.hasError = this.$v.$dirty && preferences.seatSelections.length < this.travellers.length;
      } else {
        preferences.hasError = false;
      }
    });
    if (this.$v.$dirty) {
      BasketStore.setBasketRailSncfSeatPreferencesError(this.$v.$invalid || this.hasAnySeatsErrors);
    }
  }

  isAssingmentValid(model, parent) {
    if (!parent.selectedPreferenceType || parent.selectedPreferenceType.value !== 'Assignment') {
      return true;
    } else {
      if (model && model.length) {
        return model.some(item => {
          return item.value ? !!(item.value.code) : false;
        });
      } else {
        return true;
      }
    }
  }

  seatSelected(event) {
    const currentSeats = this.travellers.map((traveller) => {
      const seatRequirement = BasketStore.railTravellersSeatRequirements.find(({id, isVirtual}) => id === (isVirtual ? traveller.virtualTravellerId : traveller.id));
      return {
        profileId: traveller.id,
        virtualTravellerId: traveller.virtualTravellerId,
        segmentId: this.selectedJourneyId,
        needsSeat: seatRequirement ? seatRequirement.needsSeat : true,
        coachNumber: undefined,
        seatNumber: undefined,
        price: undefined
      };
    });

    currentSeats.filter((seat) => seat.needsSeat === true).forEach((seat, index) => {
      let price: any = undefined;
      const placing = event.detail.placings.length > index ? event.detail.placings[index] : undefined;
      if (placing && placing.extraPrice) {
        price = {
        ...priceConst.priceModel,
        currency: { ...priceConst.priceModel.currency },
      };
        price.amount = placing.extraPrice;
      }
      seat.coachNumber = event.detail.coach;
      seat.seatNumber = placing ? placing.seatNumber : undefined;
      seat.price = price;
    });

    this.selectedSeats = this.selectedSeats
      .filter(seat => {
        return seat.segmentId !== this.selectedJourneyId;
      });
    this.selectedSeats = this.selectedSeats
      .concat.apply(this.selectedSeats, currentSeats);

    this.selectedSeats.forEach(seat => {
      BasketStore.setSelectedRailSeat({
        segmentId: seat.segmentId,
        coachNumber: seat.coachNumber,
        seatNumber: seat.seatNumber,
        profileId: seat.profileId,
        price: seat.price,
      });
    });

    this.hideSeatMapPopup();
    this.updateSeatsInRailPreferences();
  }

  showSeatMap(data) {
    this.seatMapVisible = true;
    setTimeout(() => {
      const element = this.$refs.seatmap as any;
      element.primaryColor = this.primaryColor;
      element.secondaryColor = this.secondaryColor;
      element.ternaryColor = this.ternaryColor;
      element.quaternaryColor = this.quaternaryColor;
      element.numberOfPassengers = BasketStore.railTravellersSeatRequirements.filter((req) => req.needsSeat === true).length;
      element.data = data;
    });
  }

  passangerSeat(profileId) {
    return this.selectedSeats.find(seat => {
      return seat.profileId === profileId && seat.segmentId === this.selectedJourneyId;
    });
  }

  passangerPrice(profileId) {
    const seat = this.passangerSeat(profileId);

    if (!seat || !seat.price) {
      return {
        ...priceConst.priceModel,
        currency: { ...priceConst.priceModel.currency },
      };
    }

    return seat.price;
  }

  async showSeatMapPopup() {
    this.isProviderError = false;
    this.seatMapErrors = [];
    this.seatMapLoading = true;
    this.showingSeatMap = true;

    const offerId = BasketStore.bookingStepTripItem!.providerReferenceId || '';
    const segmentId = this.selectedJourneyObject.segmentId;

    try {
      await BasketStore.getRailTravellersSeatRequirements({offerId: offerId, railSupplier: this.item.supplier});
      const response = await BasketItemApi.getRailSncfSeatMap(offerId, segmentId, this.item.supplier);

      this.showSeatMap(response.data);
    } catch (error) {
      if (
        error.response && error.response.data &&
        error.response.data.error &&
        error.response.data.error.code === 'PROVIDER_EXECUTION_FAILURE' &&
        error.response.data.error.details.length &&
        error.response.data.error.details[0].params
      ) {
        this.isProviderError = true;
        this.seatMapErrors = error.response.data.error.details
          .filter(detail => !!detail.params)
          .map(detail => {
            return {
              message: [
                detail.params.MESSAGE,
                ' (',
                detail.code,
                ')'
              ].join(''),
            };
          });
      } else {
        this.seatMapErrors = this.$handleErrors(error, true);
      }
    } finally {
      this.seatMapLoading = false;
    }
  }

  hideSeatMapPopup() {
    this.showingSeatMap = false;
    this.seatMapVisible = false;
    this.seatMapLoading = true;
  }

  @Watch('selectedPreferenceType')
  onPreferenceTypeChange(value) {
    if (value !== 'SeatMap') {
      this.selectedSeats
        .filter(seat => {
          return seat.segmentId === this.selectedJourneyId;
        })
        .forEach(seat => {
          BasketStore.deselectSelectedRailSeat({
            segmentId: seat.segmentId,
            coachNumber: seat.coachNumber,
            seatNumber: seat.seatNumber,
            profileId: seat.profileId,
            price: seat.price,
          });
        });

      this.railPreferences
        .filter(preferences => preferences.segmentId === this.selectedJourneyId)
        .forEach(preferences => {
          (preferences.seatSelections || []).splice(0, preferences.seatSelections);
        });

      this.selectedSeats = this.selectedSeats
        .filter(seat => {
          return seat.segmentId !== this.selectedJourneyId;
        });

      if (this.preferencesError) {
        this.validate();
      }
    }
  }

  @Watch('formError')
  async onFormChangeError(value) {
    BasketStore.setBasketRailSncfSeatPreferencesError(this.$v.invalid || this.hasAnySeatsErrors);
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.resetPriceChangesForStep({ tripItemId: this.itemId, step: this.bookingStep });
    this.travellerPicked = this.travellers[0];
    BasketStore.setBasketRailSncfSeatPreferencesError(false);
    await BasketStore.getBasketRailSncfSeatPreferences({recommendationId: this.item.providerReferenceId, railSupplier: this.item.supplier});
    if (this.trains && this.trains.length) {
      this.selectedJourneyId = this.trains[0].segmentId;
    }
  }



  async created() {
    EventBus.$on('validate-sncf-seats', this.validate);
  }

  beforeDestroy() {
    BasketStore.resetSelectedRailSeats();
    EventBus.$off('validate-sncf-seats', this.validate);
  }
}
