
















































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class Inputs extends Vue {
  singleValue = null;
  multiValues = [];
  valueSlider: number = 10;
  rangeValueSlider: any[] = [25, 75];
  text: string = '';
  color1: string = '#000000';
  options = [
    {
      label: 'option 1',
      value: 1,
    },
    {
      label: 'option 2',
      value: 2,
    },
    {
      label: 'option 3',
      value: 3,
    },
    {
      label: 'option 4',
      value: 4,
    },
    {
      label: 'option 5',
      value: 5,
    },
  ];
  radioGroupPicked = null;
  radioGroupPicked2 = null;
  radiobuttons = [
    {
      label: 'radio 1',
      value: {
        id: 'someId1',
        value: 'some value 1',
      },
    },
    {
      label: 'radio 2',
      value: {
        id: 'someId2',
        value: 'some value 2',
      },
    },
    {
      label: 'radio 3',
      value: {
        id: 'someId3',
        value: 'some value 3',
      },
    },
    {
      label: 'radio 4',
      value: {
        id: 'someId4',
        value: 'some value 4',
      },
    },
    {
      label: 'radio 5',
      value: {
        id: 'someId5',
        value: 'some value 5',
      },
    },
  ];
  radiobuttons2 = [
    {
      label: 'radio 6',
      value: {
        id: 'someId6',
        value: 'some value 6',
      },
    },
    {
      label: 'radio 7',
      value: {
        id: 'someId7',
        value: 'some value 7',
      },
    },
    {
      label: 'radio 8',
      value: {
        id: 'someId8',
        value: 'some value 8',
      },
    },
    {
      label: 'radio 9',
      value: {
        id: 'someId9',
        value: 'some value 9',
      },
    },
    {
      label: 'radio 10',
      value: {
        id: 'someId10',
        value: 'some value 10',
      },
    },
  ];

  checkboxValue: boolean = false;
  checkboxes1Value: any[] = [];
  checkboxes2Value = [];

  checkboxes1 = [
    {
      id: 'checkbox2',
      label: 'Checkbox 2',
      value: {
        data: true,
      },
    },
    {
      id: 'checkbox3',
      label: 'Checkbox 3',
      value: {
        data: true,
      },
    },
  ];

  checkboxes2 = [
    'Checkbox 4',
    'Checkbox 5',
    'Checkbox 6',
    'Checkbox 7',
    'Checkbox 8',
    'Checkbox 9',
  ];

  get checkedBoxes1() {
    return this.checkboxes1Value
      .map(value => value.label + ' (' + value.id + ')')
      .join(', ');
  }

}
