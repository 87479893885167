





















import { Vue, Component } from 'vue-property-decorator';

import TrainSearchStore from './train-search.store';

@Component({})
export default class EarlierLaterConnections extends Vue {

  get currentLeg() {
    const value = this.$route.params.leg;
    return isNaN(Number(value)) ? 1 : Number(value);
  }

  get availableActions() {
    return TrainSearchStore.availableMoreActions;
  }

  get earlierAvailable() {
    return this.availableActions[this.currentLeg] &&
      this.availableActions[this.currentLeg].indexOf('Previous') > -1;
  }

  get laterAvailable() {
    return this.availableActions[this.currentLeg] &&
      this.availableActions[this.currentLeg].indexOf('Next') > -1;
  }

  get bothAvailable() {
    return this.earlierAvailable && this.laterAvailable;
  }

  get noneAvailable() {
    return this.earlierAvailable || this.laterAvailable;
  }

  get loading() {
    return TrainSearchStore.loading;
  }

  get classes() {
    return {
      'link-disabled': this.loading,
    };
  }



  loadEarlierConnections() {
    if (this.loading) {
      return;
    }

    TrainSearchStore.loadMoreNow({
      searchId: this.$route.params.searchId,
      legNumber: this.currentLeg,
      action: 'Previous',
    });
  }

  loadLaterConnections() {
    if (this.loading) {
      return;
    }

    TrainSearchStore.loadMoreNow({
      searchId: this.$route.params.searchId,
      legNumber: this.currentLeg,
      action: 'Next',
    });
  }
}
