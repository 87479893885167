































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import PaymentMethodsStore from './payment-methods-accommodation.store';
import { AirNegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class PaymentAccommodationConfiguration extends Vue {
  $v;
  validated: boolean = false;

  get providerOptions() {
    return [
      'Sabre',
      'Expedia',
      'Teldar',
      'HCorpo',
      'WonderHotel',
    ];
  }

  Form: any = {
    supplier: null,
    isCentralPaymentEnabled: false,
    isUserCardPaymentEnabled: false,
    isLodgeCardPaymentEnabled: false,
    defaultPaymentMethod: null,
    enablePaymentGateway: false,
    paymentGatewayOption: 'TotalBookingAmount',
    hidePaymentMethodIfItIsNotNeeded: false,
  };

  @Validation()
  validationObject() {
    return {
      Form: {
        supplier: {
          required,
        },
        isCentralPaymentEnabled: {
        },
        isUserCardPaymentEnabled: {
        },
        defaultPaymentMethod: {
        },
      }
    };
  }

  get readOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get filterProviderList() {
    if (this.currentPaymentList && this.currentPaymentList.length && this.isNew) {
      return this.providerOptions.filter(e => !this.currentPaymentList.some(a => a.supplier === e && a.succeeded));
    }

    return this.providerOptions;
  }

  get filteredTranslatedProviderList() {
    return this.filterProviderList.map(supplier => {
      return {
        value: supplier,
        label: SupplierName(supplier),
      };
    });
  }

  get numberOfPaymentMethodsEnabled() {
    if (!this.Form.supplier) {
      return 0;
    }

    const paymentMethodsEnabled = [
      this.Form.isCentralPaymentEnabled,
      this.Form.isUserCardPaymentEnabled,
      this.Form.isLodgeCardPaymentEnabled,
      this.Form.isVirtualCardPaymentEnabled
    ];

    const paymentMethodsNumberByProvider = {
      Sabre: [1, 2, 3],
      Expedia: [0, 1, 2],
      Teldar: [0],
      HCorpo: [0],
      WonderHotel: [0],
    };

    return paymentMethodsEnabled.filter((item, index) => {
      return paymentMethodsNumberByProvider[this.Form.supplier].includes(index) && item;
    }).length;
  }

  get isHidePaymentMethodDisabled() {
    return (this.numberOfPaymentMethodsEnabled !== 1) ||
      this.Form.isUserCardPaymentEnabled;
  }

  get selectedSupplier() {
    return this.filteredTranslatedProviderList.find(item => item.value === this.Form.supplier) || null;
  }

  set selectedSupplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      return;
    }

    this.Form.supplier = value.value;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return PaymentMethodsStore.configurationName;
  }

  get selectedPayment() {
    return PaymentMethodsStore.selectedPayment;
  }

  get providers() {
    return AirNegoFareConfigurationSupplierEnum;
  }

  get currentPaymentList() {
    return PaymentMethodsStore.paymentList;
  }

  get isLodgeCardPaymentAvailable() {
    return -1 < [
      'Expedia',
      'Sabre',
    ].indexOf(this.Form.supplier);
  }

  get isCentralPaymentAvailable() {
    return -1 < [
      'Expedia',
      'Teldar',
      'HCorpo',
      'WonderHotel',
    ].indexOf(this.Form.supplier);
  }

  get isUserCardPaymentAvailable() {
    return -1 < [
      'Expedia',
      'Sabre',
    ].indexOf(this.Form.supplier);
  }

  get isVirtualCardPaymentAvailable() {
    return -1 < [
      'Sabre',
    ].indexOf(this.Form.supplier);
  }

  get isNew() {
    if (this.selectedPayment) {
      return Object.entries(this.selectedPayment as any).length === 0;
    }
    return false;
  }

  goToConfiguration() {
    PaymentMethodsStore.setIsFromManage(true);
    router.push({
      name: 'payment-methods-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      this.Form = {
        supplier: data.supplier,
        isCentralPaymentEnabled: data.isCentralPaymentEnabled,
        isUserCardPaymentEnabled: data.isUserCardPaymentEnabled,
        isLodgeCardPaymentEnabled: data.isLodgeCardPaymentEnabled,
        isVirtualCardPaymentEnabled: data.isVirtualCardPaymentEnabled,
        defaultPaymentMethod: data.defaultPaymentMethod,
        enablePaymentGateway: !!data.paymentGatewayOption ? true : false,
        paymentGatewayOption: data.paymentGatewayOption ? data.paymentGatewayOption : 'TotalBookingAmount',
        hidePaymentMethodIfItIsNotNeeded: data.hidePaymentMethodIfItIsNotNeeded,
      } as any;
    } else {
      this.Form = {
        supplier: null,
        isCentralPaymentEnabled: false,
        isUserCardPaymentEnabled: false,
        isLodgeCardPaymentEnabled: false,
        isVirtualCardPaymentEnabled: false,
        defaultPaymentMethod: null,
        enablePaymentGateway: false,
        paymentGatewayOption: 'TotalBookingAmount',
        hidePaymentMethodIfItIsNotNeeded: false,
      } as any;
    }
  }

  createPayment() {
    let item = {
      supplier: this.Form.supplier,
      isCentralPaymentEnabled: this.Form.isCentralPaymentEnabled,
      isUserCardPaymentEnabled: this.Form.isUserCardPaymentEnabled,
      isLodgeCardPaymentEnabled: this.Form.isLodgeCardPaymentEnabled,
      isVirtualCardPaymentEnabled: this.Form.isVirtualCardPaymentEnabled,
      paymentGatewayOption: this.Form.enablePaymentGateway ? this.Form.paymentGatewayOption : null,
      hidePaymentMethodIfItIsNotNeeded: this.Form.hidePaymentMethodIfItIsNotNeeded,
      defaultPaymentMethod: 
        this.Form.isCentralPaymentEnabled === false &&
        this.Form.isUserCardPaymentEnabled === false &&
        this.Form.isLodgeCardPaymentEnabled === false &&
        this.Form.isVirtualCardPaymentEnabled === false
        ? 'None'
        : this.Form.defaultPaymentMethod,
      status: PaymentMethodsStore.draftNewStatus,
      succeeded: true,
      id: _.uniqueId()
    };
    if (
      Object.entries(this.selectedPayment as any).length === 0 || 
      !this.currentPaymentList.find(entry => {
        return entry.supplier === item.supplier;
      })
    ) {
      PaymentMethodsStore.addSelectedPayment(item);
    } else {
      PaymentMethodsStore.editSelectedPayment(item);
    }
  }

  confirmAndGoBack() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.createPayment();
      this.goToConfiguration();
    }
  }

  @Watch('selectedPayment', { deep: true, immediate: true })
  onCodeChange(payment) {
    if (!payment) {
      router.go(-1);
    }
  }

  @Watch('isHidePaymentMethodDisabled', { immediate: true })
  onIsHidePaymentMethodDisabledChanged() {
    if (this.isHidePaymentMethodDisabled) {
      this.Form.hidePaymentMethodIfItIsNotNeeded = false;
    }
  }

  paymentMethodChanged(value, paymentType) {
    if (value && this.Form.defaultPaymentMethod) {
      return;
    }
    if (value && !this.Form.defaultPaymentMethod) {
      this.Form.defaultPaymentMethod = paymentType;
      return;
    }
    if (!value && this.Form.defaultPaymentMethod === paymentType) {
      const paymentOptions = [
      {
        condition: () => this.Form.isCentralPaymentEnabled,
        result: 'CentralPayment',
      },
      {
        condition: () => this.Form.isUserCardPaymentEnabled,
        result: 'PaymentCard',
      },
      {
        condition: () => this.Form.isLodgeCardPaymentEnabled,
        result: 'LodgeCard',
      },
      {
        condition: () => this.Form.isVirtualCardPaymentEnabled,
        result: 'VirtualCard',
      }];

      const defaultPaymentOption = paymentOptions.find(option => option.condition());
      this.Form.defaultPaymentMethod = (defaultPaymentOption) ? defaultPaymentOption.result : null;
    }
  }

  created() {
    if (this.selectedPayment) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedPayment)));
    } else {
      this.mapFormData();
    }
  }
}
