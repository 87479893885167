export class VirtualCardsConfiguration {
  rootCompanyId?: string;
  configurationId?: string;
  configurationName: string = '';
  airPlusSecretKey: string = '';

  constructor(data?: any) {
    if (data) {
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.configurationName !== undefined) {
        this.configurationName = data.configurationName;
      }
      if (data.airPlusSecretKey !== undefined) {
        this.airPlusSecretKey = data.airPlusSecretKey;
      }
    }
  }
}