import Community from '@/modules/settings/community/Community.vue';
import communityProfiles from './community/community-profiles.route';
import communityAddProfile from './community/community-add-profile.route';
import { translate } from '@/i18n';

export default {
  path: 'community',
  component: Community,
  displayName: 'settings-sidebar.community',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCommunity',
    icon: 'groups',
    parentName: 'community',
  },
  children: [
    communityProfiles,
    communityAddProfile,
  ],
};
