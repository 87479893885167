import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  FlightRuleCreateConfigurationMessage,
  FlightRuleUpdateConfigurationMessage,
  TravelPolicySimplyConfigutationIdModel,
  FlightRuleConfigurationFromDbModel,
  CarRuleConfigurationCreateModel,
  CarRuleConfigurationUpdateModel,
  AccomodationRuleConfigurationCreateModel,
  AccomodationRuleConfigurationUpdateModel,
  RentalCompany,
  RailRuleConfigurationCreateModel,
  RailRuleConfigurationUpdateModel
} from './travel-policy.model';
import { LanguageCode } from '../dictionary/dictionary.model';

class TravelPolicyApiClass {
  public getFlightRules(configurationId: string): Promise<AxiosResponse<FlightRuleConfigurationFromDbModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/air/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public addFlightRules(params: FlightRuleCreateConfigurationMessage): Promise<AxiosResponse<TravelPolicySimplyConfigutationIdModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/air',
      method: RequestMethod.POST
    }, {}, params);
  }

  public editFlightRules(configurationId: string, params: FlightRuleUpdateConfigurationMessage): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/air/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }

  public getCarRules(configurationId: string): Promise<AxiosResponse<CarRuleConfigurationUpdateModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/car/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public addCarRules(params: CarRuleConfigurationCreateModel): Promise<AxiosResponse<CarRuleConfigurationCreateModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/car',
      method: RequestMethod.POST
    }, {}, params);
  }

  public editCarRules(configurationId: string, params: CarRuleConfigurationUpdateModel): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/car/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }

  public getAccomodationRules(configurationId: string): Promise<AxiosResponse<AccomodationRuleConfigurationCreateModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/accomodation/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public addAccomodationRules(params: AccomodationRuleConfigurationCreateModel): Promise<AxiosResponse<AccomodationRuleConfigurationCreateModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/accomodation',
      method: RequestMethod.POST
    }, {}, params);
  }

  public editAccomodationRules(configurationId: string, params: AccomodationRuleConfigurationUpdateModel): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/accomodation/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }

  public getRailRules(configurationId: string, languageCode?: LanguageCode): Promise<AxiosResponse<RailRuleConfigurationCreateModel>> {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/rail/{configurationId}?languageCode={languageCode}',
      method: RequestMethod.GET,
    }, { configurationId, languageCode }, {});
  }

  public addRailRules(params: RailRuleConfigurationCreateModel): Promise<AxiosResponse<RailRuleConfigurationCreateModel>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/rail/',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public editRailRules(configurationId: string, params: RailRuleConfigurationUpdateModel): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiTravelPolicy + '/api/travelpolicies/rail/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, params);
  }

  public getRentalCompanys(): Promise<AxiosResponse<Array<RentalCompany>>> {
    return http.execute({
      path: settings.apiTravelPolicy + '/car/rental-companies',
      method: RequestMethod.GET
    }, {}, {});
  }
}

export const TravelPolicyApi: TravelPolicyApiClass = new TravelPolicyApiClass();