import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { router } from '@/router';
import AccountStore from '@/store/account.store';
import { RootCompanyModel } from '@/api/profile/company.model';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { ProfileApi } from '@/api/profile/profile.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import { Permission } from '@/const/permission.enum';
import consts from './settings.const';

const CACHE_DELAY = 3000;

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'settings'
})
class SettingsStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentCompany: RootCompanyModel | null = null;
  searchedCompanies = [];
  airProvidersErrors: string[] = [];
  carProvidersErrors: string[] = [];
  accommodationProvidersErrors: string[] = [];
  railProvidersErrors: string[] = [];
  serverErrors: any[] = [];
  isAgency: boolean = false;
  agencyAssigned: any = null;
  breadcrumbstCompany: any[] = [];
  cachedData: any[] = [];
  lastCompanyId: string | null = null;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get companies() {
    return this.searchedCompanies;
  }

  get IsLoading(): boolean {
    return this.loading;
  }

  get IsLoaded(): boolean {
    return this.loaded;
  }

  get isRootCompany(): boolean {
    return !!this.currentCompany && this.currentCompany.isRoot;
  }

  get canReadAgencyAssignment() {
    return AccountStore.HasPermission('ReadAgencyAssignment');
  }

  get menuGroups() {
    if (!AccountStore.IsLoaded) {
      return [];
    }
    let isAgency = this.isAgency;
    let isRootCompany = this.isRootCompany;
    if (!this.currentCompany) {
      isAgency = AccountStore.current!.profile.isAgency;
      isRootCompany = AccountStore.current!.profile.rootCompanyId === AccountStore.current!.profile.companyId;
    }
    const linkToDisplay = (route) => {
      return route.path === '' || route.path === 'company-structure' || route.path === 'community' || ((route.path === 'customers') && isAgency);
    };
    return consts.menuGroups
      .filter(group => 'expense' !== group.name || AccountStore.isExpenseModuleEnabled)
      .map(group => {
        const items = (group.items as any[])
          .filter(route => (!route.meta || !route.meta.permission) ? true : AccountStore.HasPermission(route.meta.permission))
          .filter(route => ((route.path === 'customers') && !isAgency) ? false : (!linkToDisplay(route) && !isRootCompany) ? false : true);
        return {
          ...group,
          items,
        };
      })
      .filter(group => !!group.items.length);
  }

  @Mutation
  startLoading() {
    this.loading = true;
  }

  @Mutation
  finishLoading() {
    this.loading = false;
  }

  @Mutation
  loadingSuccess() {
    this.loaded = true;
  }

  @Mutation
  setAgencyAssigned(value) {
    this.agencyAssigned = value;
  }

  @Mutation
  setServerErrors(error) {
    this.serverErrors = $handleErrors(error, true);
  }

  @Mutation
  public setCurrentCompany(currentCompany) {
    this.currentCompany = currentCompany;
  }

  @Mutation
  setIsAgency(value) {
    this.isAgency = value;
  }

  @Mutation
  setBreadcrumbstCompanyInfo(value) {
    this.breadcrumbstCompany = value;
  }

  @Mutation
  setLastCompanyId(id) {
    this.lastCompanyId = id;
  }

  @Mutation
  updateCachedData({ id, data }) {
    const item = this.cachedData.find(e => e.id === id);
    const timestamp = (new Date()).getTime();

    if (item) {
      item.data = data;
      item.timestamp = timestamp;
      return;
    }

    this.cachedData.push({
      id,
      data,
      timestamp,
    });
  }

  @Mutation
  clearOldCachedRequests() {
    const timestamp = (new Date()).getTime();
    const itemsToDelete: number[] = [];
    this.cachedData.forEach((item, index) => {
      if (item.timestamp + CACHE_DELAY < timestamp) {
        itemsToDelete.push(index);
      }
    });

    itemsToDelete.forEach((item, index) => {
      this.cachedData.splice(item - index, 1);
    });
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async getCompanyInitData(id) {
    const timestamp = (new Date()).getTime();
    const item = this.cachedData.find(e => e.id === id && e.timestamp + CACHE_DELAY > timestamp);
    this.setCustomError(false);

    if (!item) {
      let company: any | null = null;
      let agency: any | null = null;
      let isAgency: boolean | null = null;
      let breadcrumbs: any[] = [];
      let loaded = false;
      let loading = true;


      this.updateCachedData({
        id,
        data: {
          loading,
          loaded,
        },
      });
      try {
        const companyResult = await ProfileCompanyApi.getRootByCompanyId(id);

        if (companyResult && companyResult.data) {
          company = companyResult.data;

          if (this.canReadAgencyAssignment) {
            const selectedAgency = await ProfileApi.getAssignedAgency(company.companyId);
            if (selectedAgency && selectedAgency.data && selectedAgency.data !== '') {
              agency = selectedAgency.data;
            }
          }
        }

        let companyInfo = await ProfileCompanyApi.getById(id, Permission.AccessSettings);
        isAgency = companyInfo.data.isAgency || null;

        if (companyInfo.data) {
          let breadcrumbsCompany = await ProfileApi.getCompanyBreadcrumbs(companyInfo.data.id);
          breadcrumbs = breadcrumbsCompany.data;
        }

        loading = false;
        loaded = true;

        this.updateCachedData({
          id,
          data: {
            loading,
            loaded,
            company,
            agency,
            isAgency,
            breadcrumbs,
          },
        });

        return {
          id,
          data: {
            loading,
            loaded,
            company,
            agency,
            isAgency,
            breadcrumbs,
          },
        };
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          this.setCustomError(true);
        } else {
          this.setServerErrors(error);
        }
      }
    }

    if (item && item.data && item.data.loaded) {
      return {
        id,
        data: {
          ...item.data,
        },
      };
    }

    const result = await new Promise(resolve => {
      const unwatch = store.watch(() => {
        const item = this.cachedData.find(e => e.id === id);
        if (!item) {
          return null;
        }
        return item.data && item.data.loaded;
      }, (value) => {
        if (value === true || value === null) {
          const item = this.cachedData.find(e => e.id === id);
          unwatch();
          resolve(item);
        }
      });
    });

    return result;
  }

  @Action
  public async init(companyId?) {
    this.clearOldCachedRequests();
    this.startLoading();
    this.setCustomError(false);
    try {
      if (this.currentCompany == null || (companyId && companyId !== this.currentCompany.companyId)) {
        let currentUser = AccountStore.CurrentUser;

        if (currentUser != null) {
          const companyIdForRequest = companyId ? companyId : currentUser.profile.companyId;

          this.setLastCompanyId(companyIdForRequest);
          const result: any = await this.getCompanyInitData(companyIdForRequest);

          if (this.lastCompanyId === companyIdForRequest) {
            this.setCurrentCompany(result.data.company);
            this.setAgencyAssigned(result.data.agency);
            this.setIsAgency(result.data.isAgency);
            this.setBreadcrumbstCompanyInfo(result.data.breadcrumbs);
            this.loadingSuccess();
          }
        }
      }

    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setServerErrors(error);
      }
    } finally {
      this.finishLoading();
    }
  }

  @Mutation
  public setCompanies(value) {
    this.searchedCompanies = value;
  }

  @Action
  public async loadCompanies(phrase: string) {
    if (phrase && phrase.length > 1) {      
      let response = await ProfileCompanyApi.autocompleteCompanies(phrase);

      if (response && response.data) {
        this.setCompanies(response.data);
      }
    } else {
      this.setCompanies([]);
    }
  }

  @Action
  public async switchCompany(companyId) {
    try {
      let company = await ProfileCompanyApi.getRootByCompanyId(companyId);
      let companyInfo = await ProfileCompanyApi.getById(companyId, Permission.AccessSettings);
      this.setIsAgency(companyInfo.data.isAgency);

      if (this.canReadAgencyAssignment) {
        this.setAgencyAssigned(null);
        const selectedAgency = await ProfileApi.getAssignedAgency(company.data.companyId);
        if (selectedAgency && selectedAgency.data && selectedAgency.data !== '') {
          this.setAgencyAssigned(selectedAgency.data);
        }
      }

      if (
        router.currentRoute.params.id !== companyId ||
        router.currentRoute.name !== 'company-info'
      ) {
        router.push({
          name: 'company-info',
          params: {
            id: companyId,
          }
        });
      }

      this.loadingSuccess();
    } catch (error) {
      this.serverErrors = $handleErrors(error);
    } finally {
      this.finishLoading();
    }
  }

  @Mutation
  someErrorsInAirProvider(settingsId) {
    if (-1 === this.airProvidersErrors.indexOf(settingsId)) {
      this.airProvidersErrors.push(settingsId);
    }
  }

  @Mutation
  resetAirProvidersErrors(settingsId) {
    const index = this.airProvidersErrors.indexOf(settingsId);
    if (-1 < index) {
      this.airProvidersErrors.splice(index, 1);
    }
  }

  @Mutation
  someErrorsInCarProvider(settingsId) {
    if (-1 === this.carProvidersErrors.indexOf(settingsId)) {
      this.carProvidersErrors.push(settingsId);
    }
  }

  @Mutation
  resetCarProvidersErrors(settingsId) {
    const index = this.carProvidersErrors.indexOf(settingsId);
    if (-1 < index) {
      this.carProvidersErrors.splice(index, 1);
    }
  }

  @Mutation
  someErrorsInAccommodationProvider(settingsId) {
    if (-1 === this.accommodationProvidersErrors.indexOf(settingsId)) {
      this.accommodationProvidersErrors.push(settingsId);
    }
  }

  @Mutation
  resetAccommodationProvidersErrors(settingsId) {
    const index = this.accommodationProvidersErrors.indexOf(settingsId);
    if (-1 < index) {
      this.accommodationProvidersErrors.splice(index, 1);
    }
  }

  @Mutation
  someErrorsInRailProvider(settingsId) {
    if (-1 === this.railProvidersErrors.indexOf(settingsId)) {
      this.railProvidersErrors.push(settingsId);
    }
  }

  @Mutation
  resetRailProvidersError(settingsId) {
    const index = this.railProvidersErrors.indexOf(settingsId);
    if (-1 < index) {
      this.railProvidersErrors.splice(index, 1);
    }
  }
}

export default getModule(SettingsStore);
