import animation from './animation.guard';
import dictionaries from './dictionaries.guard';
import permission from './permission.guard';
import settings from './settings.guard';
import globalSettings from './global-settings.guard';
import profile from './profile.guard';
import approvals from './approvals.guard';
import scrollTop from './scroll-top.guard';
import exchange from './exchange.guard';
import basketPayment from './basket-payment.guard';
import resetTravellers from './travellers.guard';
import basketMetadataGuard from './basket-metadata.guard';
import agency from './agency.guard';

export default [
  permission, // this should be on top of guards list
  basketMetadataGuard,
  agency,
  dictionaries,
  settings,
  globalSettings,
  profile,
  approvals,
  exchange,
  basketPayment,
  scrollTop,
  resetTravellers,
  animation,
];
