import RailProviders from '@/modules/settings/configurations/RailProviders.vue';
import railProvidersList from './rail-providers/rail-providers-list.route';
import newRailProvider from './rail-providers/new-rail-provider.route';
import editRailProvider from './rail-providers/edit-rail-provider.route';
import { translate } from '@/i18n';

export default {
  path: 'rail-provider-configurations',
  component: RailProviders,
  displayName: 'settings-sidebar.rail-providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadRailProviders',
    icon: 'train',
    parentName: 'rail-provider-configurations',
  },
  children: [
    railProvidersList,
    newRailProvider,
    editRailProvider,
  ],
};
