















































































































































































import { Prop, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import {
  FlightRuleConfigurationUpdateModel,
  FlightRuleCreateModel,
} from '@/api/travel-policy/travel-policy.model';
import SettingsStore from '@/modules/settings/settings.store';
import DisplayPreferencesFlightStore from '@/modules/settings/display-preferences/display-preferences-flight.store';
import DisplayPreferencesStore from '@/modules/settings/display-preferences/display-preferences.store';
import EventBus from '@/services/event-handler';
import _ from 'lodash';
import { translate } from '@/i18n';

@Component({})

export default class DisplayPreferencesFlight extends BaseTable<any> {
  @Prop({ default: false }) formPending!: boolean;
  @Prop({ default: false }) readOnly?;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  fields = {
    operatingAirlines: {
      sortable: false,
      label: translate('settings-display-preferences.airlines'),
    },
    mixed: {
      sortable: false,
      label: translate('settings-display-preferences.mixed'),
    },
    routings: {
      sortable: false,
      label: translate('settings-display-preferences.routings'),
    },
    countries: {
      sortable: false,
      label: translate('settings-display-preferences.countries'),
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    seeAll: {
      sortable: false,
      label: '',
    },
    actions: {
      sortable: false,
      label: '',
    },
  };
  $v;
  menuActive: boolean = false;
  rule: FlightRuleConfigurationUpdateModel | null = null;
  modifyPopup: boolean = false;
  removePopup: boolean = false;
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  itemList: FlightRuleCreateModel[] = [];

  get withCompliantTravelPolicy() {
    return this.configuration.withCompliantTravelPolicy;
  }

  set withCompliantTravelPolicy(value) {
    this.configuration.withCompliantTravelPolicy = value;
  }

  get hideDuplicatedGdsFares() {
    return this.configuration.hideDuplicatedGdsFares;
  }

  set hideDuplicatedGdsFares(value) {
    this.configuration.hideDuplicatedGdsFares = value;
  }

  get ruleList() {
    return DisplayPreferencesFlightStore.getFlightRules;
  }

  get configuration() {
    return DisplayPreferencesFlightStore.getFlightDisplayPreferences;
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isReadOnly() {
    return !!this.$route.query.readOnly;
  }

  get count() {
    if (!this.results) {
      return 0;
    }

    return this.results.length;
  }

  get loadingList() {
    return DisplayPreferencesStore.loading;
  }

  modifyRule(item) {
    DisplayPreferencesFlightStore.selectedFlightRule(item);
    router.push({
      name: 'display-preferences-flight-rule',
      params: {
        configurationId: this.currentConfigurationId,
      },
    });
  }

  removeRule(selected) {
    if (selected) {
      let filterList = _.filter(this.ruleList, (item) => {
        return item.id !== selected.id;
      });
      DisplayPreferencesFlightStore.setFlightRules(filterList);
      this.reload();
    }
  }

  async getItems() {
    await new Promise(resolve => setTimeout(resolve));
    return {
      page: {
        pageSize: this.params.size,
        pageNo: this.params.start,
        moreResultsAvailable: false,
      },
      results: this.ruleList,
    };
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.rulePriority < b.rulePriority) {
        result = -1;
      } else if (a.rulePriority > b.rulePriority) {
        result = 1;
      }
      return result;
    });
  }

  toggleRuleMenu(item) {
    this.rule = item;
    this.menuActive = !this.menuActive;
  }

  addRule() {
    DisplayPreferencesFlightStore.selectedFlightRule({});
    if (this.currentConfigurationId) {
      router.push({
        name: 'display-preferences-flight-rule',
        params: {
          configurationId: this.currentConfigurationId
        },
      });
    } else {
      router.push({
        name: 'display-preferences-flight-rule',
      });
    }
  }

  onReceive() {
    this.reload();
  }

  created() {
    EventBus.$on('refresh-data', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('refresh-data', this.onReceive);
  }

  @Watch('ruleList')
  onRuleListChange() {
    this.reload();
  }
}
