















import { Vue, Component } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import BasketTravellerMissingDocumentForm from './BasketTravellerMissingDocumentForm.vue';

@Component({
  components: {
    BasketTravellerMissingDocumentForm,
  },
})
export default class BasketTravellersMissingDocument extends Vue {

  get travellers() {
    return SearchStore.travellersSearch.travellers;
  }

  get loading() {
    return BasketStore.loadingBasketTravellersDocuments;
  }



  created() {
    BasketStore.resetTravellersMissingDocumentCollection();
    BasketStore.loadTravellersDocuments();
  }
}
