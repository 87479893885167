import CarResults from '@/modules/search/car/CarResults.vue';
import CarSubintro from '@/modules/search/car/CarSubintro.vue';
import CarSearchShortParams from '@/modules/search/car/CarSearchShortParams.vue';
import CarResultsFilters from '@/modules/search/car/CarResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/car-results/:searchId',
  name: 'car',
  components: {
    default: CarResults,
    intro: CarSearchShortParams,
    subintro: CarSubintro,
    sidebar: CarResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-car-results',
    permission: 'SearchOffers',
    preserveSidebar: {
      airDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        carDetails: AnimationName.DOWN,
        carTravellers: AnimationName.DOWN,
      },
    }
  }
};
