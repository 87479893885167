import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

class AirBookingsApiClass {

  public getSabreHistoryCompare(recommendationId: string, notificationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/sabre/{recommendationId}/history/{notificationId}/compare',
      method: RequestMethod.GET
    }, {
      recommendationId,
      notificationId,
    }, {});
  }

  public getTravellersDetails(supplier, recommendationId): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/{supplier}/{recommendationId}/passengers',
      method: RequestMethod.GET
    }, {
      supplier,
      recommendationId
    }, {});
  }

  public getSabreHistory(recommendationId): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/sabre/{recommendationId}/history',
      method: RequestMethod.GET
    }, {
      recommendationId
    }, {});
  }

}

export const AirBookingsApi: AirBookingsApiClass = new AirBookingsApiClass();