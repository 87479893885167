














































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import BasketStore from './basket.store';
import AccountStore from '@/store/account.store';
import BasketApproval from './BasketApproval.vue';
import settings from '@/settings';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import { OffersApi } from '@/api/air-engine/offers.api';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { Journey, Proposal } from '@/api/train-engine/train-search.model';
import HotelResultsRow from '@/modules/search/hotel/HotelResultsRow.vue';
import BasketAirOffer from './BasketAirOffer.vue';
import TrainResultsBasketRow from '@/modules/search/train/TrainResultsBasketRow.vue';
import CarResultsRow from '@/modules/search/car/CarResultsRow.vue';

@Component({
  components: {
    BasketAirOffer,
    TrainResultsBasketRow,
    HotelResultsRow,
    CarResultsRow,
    BasketApproval,
    BasketExpirationCountdown,
  },
})
export default class BasketSelectApproverView extends Vue {
  @Prop() basketExpired!: boolean;

  itemDetails: any = null;
  accomodationItem: any = null;
  loading: boolean = true;
  loadDetailsErrors: any[] = [];
  loaded = false;

  get displayApproverEmpty() {
    return BasketStore.displayApproverEmpty;
  }

  get travelPolicyComplianceElement() {
    if (!this.travelPolicyCompliance) {
      return null;
    }
    return this.travelPolicyCompliance.recalculateTripItemResults
      .find(item => item.tripItemId === this.item.id);
  }

  get itemDetailsMapped() {
    return {
      ...this.itemDetails,
      travelPolicy: this.travelPolicyComplianceElement ? this.travelPolicyComplianceElement.travelPolicyResultChange.currentTravelPolicyResult : this.item.travelPolicy,
    };
  }

  get travelPolicyCompliance() {
    return BasketStore.travelPolicyCompliance;
  }

  get bookingInProgress() {
    return BasketStore.status && BasketStore.status.isInProgress;
  }

  set displayApproverEmpty(value) {
    BasketStore.setDisplayApproverEmpty(value);
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }
  
  get option() {
    return BasketStore.finalBookOption;
  }

  set option(value) {
    BasketStore.setFinalBookOption(value);
  }

  get approvalWorkflowForBasket() {
    return BasketStore.approvalWorkflowForBasket;
  }

  get isAutoApproval() {
    if (!this.approvalWorkflowForBasket) {
      return false;
    }

    return !!this.approvalWorkflowForBasket.workflowLevels
      .find(level => level.approvalWorkflowResult === 'AutoApprove');
  }

  get canShowApprovalOptions() {
    if ('hold' === this.option && this.approvalVisible) {
      return true;
    }
    if ('approval' === this.option) {
      return true;
    }

    return false;
  }

  get showQuickBook() {
    return BasketStore.showQuickBook;
  }

  get showApprovalOptions() {
    return BasketStore.showApprovalOptions;
  }

  get holdDeadline() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.holdDeadline : null;
  }

  get holdVisible() {
    if (settings.enableBookingHoldAction !== 'true') {
      return false;
    }
    return BasketStore.preBookingData ? BasketStore.preBookingData.isHoldEnabled : null;
  }

  get holdDisabled() {
    return BasketStore.preBookingData ? !BasketStore.preBookingData.isHoldPossible : true;
  }

  get approvalDeadline() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.approvalDeadline : null;
  }

  get canDeadlinesChange() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.canDeadlinesChange : false;
  }

  get approvalVisible() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.isApprovalEnabled : null;
  }

  get approvalDisabled() {
    return BasketStore.preBookingData ? !BasketStore.preBookingData.isApprovalPossible : true;
  }

  get basket() {
    return BasketStore.basket;
  }

  get user() {
    return AccountStore.current;
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ', ' + this.currentTimeFormat + ' (\\G\\M\\T Z)';
  }

  get priceChangesForItem() {
    return BasketStore.priceChanges
      .filter(item => item.tripItemId === this.item.id)
      .reduce((prev, cur) => {
        const sum = cur.prices.reduce((p, c) => {
          return p + c.amount;
        }, 0);

        return prev + sum;
      }, 0);
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'SELECT_APPROVER') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped as any;
  }

  get itemMapped() {
    return {
      ...this.item,
      travelPolicy: this.travelPolicyComplianceElement ? this.travelPolicyComplianceElement.travelPolicyResultChange.currentTravelPolicyResult : this.item.travelPolicy,
    };
  }

  get basketItemStatusName() {
    return this.item.status;
  }



  dateTime(date) {
    const mmt = moment.parseZone(date);
    return mmt.format(this.dateTimeFormat);
  }

  async getAirDetails() {
    try {
      const response = await OffersApi.getOffer(this.item.providerReferenceId);

      this.itemDetails = {
        ...response.data,
        pricePlusMarkup: this.travelPolicyComplianceElement ? this.travelPolicyComplianceElement.totalPrice : this.item.price.total,
        travelPolicy: this.itemMapped.travelPolicy,
      };
    } catch (error) {
      this.loadDetailsErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async getBasketItem() {
    try {
      const response = await BasketItemApi.getBasketItem(this.item.id);
      let details = response.data;

      if (details.rail && details.rail.recommendations) {
        let journeys: Journey[] = [];
        let proposals: Proposal[] = [];
        let price = 0;

        details.rail.recommendations.forEach(recommendation => {
          recommendation.journeys.forEach(journey => {
            journeys.push(journey);
          });

          recommendation.proposals.forEach(proposal => {
            proposals.push(proposal);
          });
        });

        proposals.forEach(proposal => {
          proposal.fares.forEach(fare => {
            fare.isSelected = true;
            price += (fare as any).price.amount;
          });
        });

        details.rail = {
          ...details.rail,
          id: details.rail.id,
          orderId: details.rail.orderId,
          offerProvider: details.rail.recommendations[0].offerProvider,
          supplier: details.rail.recommendations[0].supplier,
          recommendationId: details.recommendationId,
          journeys: journeys,
          proposals: proposals,
          price: {
            amount: price,
            currency: {
              code: 'EUR',
              symbol: '€'
            }
          },
          travelPolicy: details.travelPolicy,
        };
        this.itemDetails = details.rail;
      } else if (details.accommodation) {

        this.itemDetails = {
          ...details.accommodation,
          agencyFee: details.agencyFee,
          supplier: details.supplier,
        };

        this.accomodationItem = {
          ...this.itemMapped,
          agencyMarkup: details.accommodation.selectedOffer.agencyMarkup,
          totalPrice: this.item.price.total,
        };
      } else if (details.car) {
        this.itemDetails = {
          ...details.car.recommendation,
          totalPrice: this.item.price.total,
          supplier: details.supplier,
        };
      }
            
    } catch (error) {
      this.loadDetailsErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  async loadData() {
    if (this.item.type === 'Air') {
      await this.getAirDetails(); 
    } else {
      await this.getBasketItem();
    }
  }

  created() {
    this.loadData();
  }
}
