











































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import LayoutStore from '@/modules/layout/layout.store';
import { DocumentsApi } from '@/api/profile/documents.api';
import { DocumentTypeList } from '@/const/document-type-list.const';
import { DocumentType, ProfileDocument } from '@/api/profile/documents.model';
import DocumentEditPopup from '@/modules/profile/documents/DocumentEditPopup.vue';
import DictionaryStore from '@/store/dictionary.store';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import { BasketApi } from '@/api/trip/basket.api';
import { TravellerDocumentModel } from '@/api/trip/basket.model';

@Component({
  components: {
    DocumentEditPopup,
  }
})
export default class BasketTravellerMissingDocumentDocuments extends Vue {
  @Prop() traveller!: any;
  @Prop({ default: () => ({
    documents: [],
    willAnyDocumentExpireBeforeTripEnd: false,
  }) }) documentsObject!: any;

  documentRemovalErrorMessage: string | null = null;
  showDocumentEditPopup: boolean = false;
  deleteDocumentPopup: Boolean = false;
  documentToRemove: ProfileDocument | null = null;
  formPending: boolean = false;
  errMessages: any[] = [];
  showDocuments: boolean = true;
  willAnyDocumentExpireBeforeTripEnd: boolean = false;
  documentsAdded: any[] = [];
  allowedDocumentTypes = [
    'Passport',
    'NationalId',
  ];



  get documentTypes() {
    return DocumentTypeList
      .map(item => {
        return {
          ...item,
          name: translate('profile-documents-by-type.' + item.value),
        };
      });
  }

  get documents() {
    return [
      ...this.documentsObject.documents,
      ...this.documentsAdded
    ];
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get availableDocumentTypes() {
    return this.documentTypes.filter(type => {
      return this.documents.find((doc: any) => doc.type === type.value);
    });
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get DocumentType() {
    return DocumentType;
  }



  onDocumentChange(shouldReload, document) {
    if (shouldReload) {
      this.documentsAdded.push(document);
    }
    this.showDocumentEditPopup = false;
  }

  getCountryName(code: string) {
    let country = this.allCountries!.find((country: any) => { return country.code === code; });
    if (country) {
      return country.countryName;
    }
    else {
      return code;
    }
  }

  documentsOfGivenType(type) {
    return this.documents.filter((doc: any) => doc.type === type.value);
  }

  isDocumentInDraft(document) {
    return this.documentsAdded.includes(document);
  }

  removeDocument(document) {
    if (!this.isDocumentInDraft(document)) {
      return;
    }

    const index = this.documentsAdded.indexOf(document);

    this.documentsAdded.splice(index, 1);
  }

  showWarningDate(date) {
    let dayOne = moment(date);
    let dayTwo = moment();

    if (date && dayOne.diff(dayTwo, 'days') < 0) {
      return true;
    }

    return false;
  }

  showWarningIssueDate(issueDate, expireDate) {
    if (issueDate && expireDate) {
      let dayOne = moment(issueDate);
      let dayTwo = moment(expireDate);
      if (dayOne.diff(dayTwo, 'days') > 0) {
        return true;
      }

      return false;
    }
    return false;
  }

  formatDocumentRemoveDisplayName(document) {
    if (document) {
      const docType = this.documentTypes.find(type => {
        return type.value === document.type;
      });
      if (docType && docType.name) {
        return document.number + ' ' + docType.name;
      } else {
        return document.number;
      }
    }
    return '';
  }

  showAddNewDocumentPopup() {
    this.showDocumentEditPopup = true;
  }

  onFillMissingDocument() {
    BasketStore.setTravellersMissingDocumentCollection({
      profileId: this.traveller.id,
      documents: this.documentsAdded,
    });
  }



  created() {
    EventBus.$on('basket-travellers-fill-missing-documents', this.onFillMissingDocument);
  }

  beforeDestroy() {
    EventBus.$off('basket-travellers-fill-missing-documents', this.onFillMissingDocument);
  }
}

