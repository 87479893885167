import AccommodationProviderConfigurationDetail from '@/modules/settings/configurations/AccommodationProviderConfigurationDetail.vue';

export default {
  path: ':configurationId',
  name: 'accommodation-provider-configuration',
  component: AccommodationProviderConfigurationDetail,
  displayName: 'Accommodation provider',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadAccommodationProviders',
    depthCategory: 'accommodation-providers',
    depth: 2,
    parentName: 'accommodation-provider-configurations',
  },
};