






































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VclCode, VueContentLoading } from 'vue-content-loading';
import _ from 'lodash';

import { router } from '@/router';
import FlightTimelineDetails from './FlightTimelineDetails.vue';
import AirSearchStore from './air-search.store';
import AirFareConditions from './AirFareConditions.vue';
import AirResultsRow from './AirResultsRow.vue';
import FlightProposal from './FlightProposal.vue';
import FlightTimelineDetailsView from './FlightTimelineDetailsView.vue';
import accountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import AirTemplateOffers from './AirTemplateOffers.vue';
import EventBus from '@/services/event-handler';
import AirMissedSavingsPopup from './AirMissedSavingsPopup.vue';

@Component({
   components: {
    FlightTimelineDetails,
    AirFareConditions,
    AirResultsRow,
    FlightTimelineDetailsView,
    FlightProposal,
    VclCode,
    VueContentLoading,
    AirTemplateOffers,
    AirMissedSavingsPopup,
  }
})
export default class AirResultDetailsView extends Vue {
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: true }) searchMode!: boolean;
  searchId: string | null = null;
  canShow = this.searchMode;
  showFullConditionsPopup: boolean = false;
  forcing: boolean = false;
  selectingOffer: boolean = false;
  selectingOfferForce: boolean = false;
  offerDetails: any = null;
  selectedClass: any = null;
  selectedProposal: any = null;
  proposalsNumber: number = 0;
  proposalVisible: number = 3;
  showLessButton: boolean = false;
  showTemplateModal: boolean = false;

  get basketId() {
    return AirSearchStore.basketId;
  }

  get prevSelectedOffer() {
    return AirSearchStore.prevSelectedOffer;
  }

  get showModal() {
    return AirSearchStore.showModal;
  }

  get showErrorBE() {
    return AirSearchStore.showErrorBE;
  }
  
  get errorMessageBe() {
    return AirSearchStore.errorMessageBe;
  }

  get currentUser() {
    return accountStore.CurrentUser;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get offers() {
    return AirSearchStore.offers;
  }

  get recomendationId() {
    return this.$route.params.recomendationId;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get offer() {
    let offer = this.offers.find(x => x.id === this.recomendationId);
    if (offer) {
      let selectedProposal = this.offersProposalsList.find(proposal => proposal.id === offer!.id);
      return {
        ...offer as any,
        offerProposals: selectedProposal as any,
        travelPolicy: selectedProposal ? selectedProposal.travelPolicy : offer.travelPolicy,
        minPrice: selectedProposal ? selectedProposal.minPrice : offer.minPrice,
      };
    }
    return null;
  }

  get popupButtonsClasses() {
    return {
      'disabled': this.forcing,
    };
  }

   get popupForceButtonClasses() {
    return {
      'disabled': this.forcing,
      'disabled-active': this.forcing,
    };
  }

  get isDisabled() {
    return AirSearchStore.isSelected;
  }

  get loadingProposals() {
    if (this.offer) {
      return this.offer!.loadingProposals;
    }
    return false;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get proposalsMap() {
   if (this.offer && this.offer.offerProposals && this.offer.offerProposals.selectedProposals && this.offer.offerProposals.selectedProposals.length) {
      return this.offer.offerProposals.selectedProposals.filter(prop => prop.cabinClasses.includes(this.selectedClass));
    } else if (this.offer && this.offer.offerProposals && this.offer.offerProposals.proposals) {
      return this.offer.offerProposals.proposals.filter(prop => prop.cabinClasses.includes(this.selectedClass));
    }
    return [];
}

  get offersProposalsList() {
    return AirSearchStore.offersProposalsList;
  }

  get currentOfferId () {
    return AirSearchStore.currentOfferId;
  }

  get currentProposalId() {
    return AirSearchStore.currentProposalId;
  }

  uniqueClasses() {
    if (this.offer && this.offer.offerProposals) {
      let uniqueArr = _.flatMap(this.offer.offerProposals.proposals, 'cabinClasses');
      return _.uniqBy(uniqueArr);
    }

    return [];
  }

  uniqueSelectedProposalClasses() {
    if (this.offer && this.offer.offerProposals) {
      let uniqueArr = _.flatMap(this.offer.offerProposals.selectedProposals, 'cabinClasses');
      return _.uniqBy(uniqueArr);
    }

    return [];
  }

  selectClass(fareClass) {
    this.selectedClass = fareClass;
  }

  selectClassProposal() {
    if (this.offer && this.offer.offerProposals && this.offer.offerProposals.proposals) {
      let classes = this.uniqueClasses();
      this.selectClass(classes[0]);
    }
  }

  selectedProposalExist() {
    return this.offer && this.offer.offerProposals && this.offer.offerProposals.selectedProposals;
  }

  async loadProposals() {
    let searchId = this.$route.params.searchId;
    let flightNumberHash: any = [];
    if (this.offer && this.offer.legs) {
      this.offer.legs.forEach(leg => {
        flightNumberHash.push(leg.flightNumberHash);
      });
    }

    if (this.selectedProposalExist()) {
      let classes = this.uniqueSelectedProposalClasses();
      this.selectClass(classes[0]);
      AirSearchStore.updateLoadingProposalOffer({offer: this.offer, value: false});
      return;
    }

    await AirSearchStore.loadProposals({searchId, id: this.offer!.id, flightNumberHash});
    this.selectClassProposal();
  }

  showResults() {
    router.push({ name: 'air' });
  }

  goToBasketWithoutChanges() {
    router.push({
      name: 'basket',
      params: { id: this.basketId }
    });
  }

  backToDetails() {
    this.selectingOffer = false;
    AirSearchStore.setShowModal(false);
  }

  async initView() {
    this.searchId = this.$route.params.searchId;
    await AirSearchStore.getSearchSession(this.searchId);
    if (this.searchId && this.offer) {
      await this.loadProposals();
    } else {
      this.showResults();
    }
  }

  AddOfferToCartForce() {
    if (this.forcing) {
      return;
    }
    this.forcing = true;
    this.selectingOfferForce = true;
    this.AddOfferToCart(true);
  }

  async AddOfferToCart(force = false) {
    let basketItemsRequest = {
      forceAdd: force,
      recommendationId: this.currentProposalId,
    };
    this.selectingOffer = true;
    AirSearchStore.select({ offerId: this.offer!.id, proposalId: this.currentProposalId ? this.currentProposalId : this.offer!.proposal.id });

    AirSearchStore.CheckIfCanAddOfferToCart(basketItemsRequest);
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  onReceive() {
    this.showTemplateModal = true;
  }

  onSelectingAborted() {
    this.selectingOffer = false;
  }

  selectedOfferProposal(data) {
    AirSearchStore.setLoadingRestProposals(true);
    AirSearchStore.select({ offerId: data.offer.id, proposalId: data.proposal.id });
    this.AddOfferToCart();
  }

  created() {
    EventBus.$on('prepare-mobile-offer', this.onReceive);
    EventBus.$on('mobile-selected-offer-proposal', this.selectedOfferProposal);
    EventBus.$on('reload-proposals',  this.loadProposals);
    EventBus.$on('selecting-aborted', this.onSelectingAborted);
  }

  beforeDestroy() {
    EventBus.$off('prepare-mobile-offer', this.onReceive);
    EventBus.$off('mobile-selected-offer-proposal', this.selectedOfferProposal);
    EventBus.$off('reload-proposals',  this.loadProposals);
    EventBus.$off('selecting-aborted', this.onSelectingAborted);
  }
}
