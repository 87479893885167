







import { Vue, Component, Prop } from 'vue-property-decorator';
import formatPrice from '@/filters/format-price.filter';

@Component({})
export default class UiPrice extends Vue {
  @Prop() price!: Object;

  formatPrice(price) {
    return formatPrice(price);
  }
}

