























































































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { router } from '@/router';
import {
  OfflineRequestStatus,
  OfflineRequestType,
  CreateOfflineRequestMessage,
  UpdateOfflineRequestMessage,
} from '@/api/trip/offline-request.model';
import accountStore from '@/store/account.store';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { OfflineRequestApi } from '@/api/trip/offline-request.api';
import { OfflineRequest } from '@/api/trip/offline-request.model';
import { translate } from '@/i18n';

@Component({})
export default class OfflineRequestsPopup extends Vue {
  @Prop({ default: false }) requestDetailsPopupVisible!: boolean;
  @Prop({ default: false }) isEditing!: boolean;
  @Prop({ default: '' }) offlineRequestId!: string;

  offlineRequestDetails: any | null = null;
  serverErrors: any[] = [];
  wasSubmitted: boolean = false;
  formPending: boolean = false;
  statuses = [
    {
      label: translate('offline.new'),
      value: OfflineRequestStatus.New,
    },
    {
      label: translate('offline.in-progress'),
      value: OfflineRequestStatus.InProgress,
    },
    {
      label: translate('offline.closed'),
      value: OfflineRequestStatus.Closed,
    },
  ];
  types = [
    {
      label: translate('offline.new-booking'),
      value: OfflineRequestType.NewBooking,
    },
    {
      label: translate('offline.modification'),
      value: OfflineRequestType.Modification,
    },
    {
      label: translate('offline.cancellation'),
      value: OfflineRequestType.Cancellation,
    },
    {
      label: translate('offline.additional-service'),
      value: OfflineRequestType.AdditionalService,
    },
  ];

  get popupTitle() {
    if (this.isEditing) {
      return translate('offline.request-details');
    }
    return translate('offline.new-request');
  }

  get currentAccountName() {
    return this.firstName + ' ' + this.lastName;
  }

  get currentProfileId() {
    return accountStore.CurrentUser!.profile.id;
  }

  get firstName() {
    return accountStore.CurrentUser!.profile.firstName;
  }

  get lastName() {
    return accountStore.CurrentUser!.profile.lastName;
  }

  get companyId() {
    return accountStore.CurrentUser!.profile.companyId;
  }

  get isCurrentUserAnAgentForOfflineRequest() {
    return this.offlineRequestDetails != null
      && typeof this.currentProfileId !== 'undefined'
      && this.offlineRequestDetails.associatedAgentId === this.currentProfileId;
  }

  async created() {
    if (this.isEditing) {
      const result = await OfflineRequestApi.get(this.offlineRequestId);
      if (this.currentProfileId === result.data.associatedAgentId) {
        result.data.associatedAgentName = this.currentAccountName;
      }
      this.offlineRequestDetails = result.data;
      this.offlineRequestDetails.requestStatus = this.statuses
        .find(item => item.value === result.data.requestStatus);
      this.offlineRequestDetails.requestType = this.types
        .find(item => item.value === result.data.requestType);
    } else {
      this.offlineRequestDetails = {
        requestorName: this.currentAccountName,
        requestorId: this.currentProfileId,
        requestType: this.types[0],
        requestStatus: this.statuses[0],
        requestId: '{will be generated after save}',
        requestorCompanyName: '',
        requestDate: new Date(),
        title: '',
        description: '',
      };

      const result = await ProfileCompanyApi.getById(this.companyId, '');
      this.offlineRequestDetails.requestorCompanyName = result.data.name;
    }
  }

  notifyParent() {
    this.$emit('request-changed');
  }

  async saveDetails(redirectToHomeAfterUpdate) {
    this.serverErrors = [];

    if (this.isEditing) {
      let updateRequest: UpdateOfflineRequestMessage = {
        finishComment: this.offlineRequestDetails.finishComment,
        requestStatus: this.offlineRequestDetails.requestStatus.value
      };

      try {
        this.formPending = true;
        await OfflineRequestApi.update(this.offlineRequestId, updateRequest);
        this.notifyParent();
        if (redirectToHomeAfterUpdate) {
          router.push({
            name: 'home',
            query: {
              offlineRequestId: this.offlineRequestDetails.id,
            },
          });
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    } else {
      this.wasSubmitted = true;
      if (this.offlineRequestDetails.description.length === 0 
        || this.offlineRequestDetails.title.length === 0) {
        return;
      }
      let createRequest: CreateOfflineRequestMessage = {
        requestType: this.offlineRequestDetails.requestType.value,
        description: this.offlineRequestDetails.description,
        title: this.offlineRequestDetails.title,
        requestorId: this.offlineRequestDetails.requestorId,
      };

      try {
        this.formPending = true;
        await OfflineRequestApi.create(createRequest);
        this.notifyParent();
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      } finally {
        this.formPending = false;
      }
    }
  }

  @Emit()
  close() {}

  async searchAndBook() {
    if (this.offlineRequestDetails.requestStatus.value !== OfflineRequestStatus.InProgress) {
      this.offlineRequestDetails.requestStatus = this.statuses[1];
    }
    this.saveDetails(true);
  }
}
