











































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { LegFlight, Proposal } from '@/api/air-engine/air-search.model';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import { LegDetail } from '@/api/air-engine/offers.model';
import { VueContentLoading } from 'vue-content-loading';
import SearchStore from '@/modules/search/search.store';
import AirSearchStore from './air-search.store';
import { technicalStopTooltip } from './technical-tooltip.service';
import EventBus from '@/services/event-handler';
import { sanitizeUrl } from '@braintree/sanitize-url';
import AirBritishAirwaysShortOfferDetails from './AirBritishAirwaysShortOfferDetails.vue';

@Component({
  components: {
    VueContentLoading,
    AirBritishAirwaysShortOfferDetails
  },
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class FlightTimelineDetailsMobile extends Vue {
  @Prop() leg!: LegFlight;
  @Prop() proposal!: Proposal;
  @Prop() offerDetails!: LegDetail;
  @Prop({default: false}) isInBasket!: boolean;

  infoHeights: any[] = [];
  traveller: any;
  
  get travellers() {
    return SearchStore.getTravellersState;
  }

  get newDisplay() {
    return AirSearchStore.newFlightDisplay;
  }
  
  get modifyMode() {
    return AirSearchStore.modifyMode;
  }
  
  get legFlights() {
    return this.leg.flights.map(this.legFlightWithExtendedTravellers);
  }

  get isSabreSupplier() {
    return this.proposal.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.proposal.supplier === 'Amadeus';
  }


  

  travellerUrl(traveller) {
    return sanitizeUrl('/profile/' + traveller.id);
  }

  userFullName(user) {
    return userFullName(user);
  }

  iconBasedOnLocation(location, defaultValue) {
    if (location && !location.isAirport && location.isRailwayStation) {
      return 'train';
    }
    return defaultValue;
  }

  flagClasses(flight) {
    return {
      'flag__public': 'Public' === flight.fareInfo.fareType,
      'flag__nego': 'Nego' === flight.fareInfo.fareType,
      'flag__private': 'Private' === flight.fareInfo.fareType,
      'adjust__oldDisplay': !this.newDisplay,
    };
  }

  applyMinHeight(id) {
    if (this.proposal.id !== id) {
      return;
    }

    this.$nextTick(() => {
      const fareDetails = this.$refs.fareDetails as HTMLElement[];
      const fareName = this.$refs.fareName as HTMLElement[];

      if (!fareDetails || !fareDetails.length) {
        return;
      }

      this.infoHeights = [];

      fareDetails.forEach((fareDetailsItem, index) => {
        const fareDetailsRect = fareDetailsItem.getBoundingClientRect();
        const fareNameRect = fareName[index].getBoundingClientRect();

        this.infoHeights.push(fareNameRect.height + fareDetailsRect.height);
      });
    });
  }

  flightDetails(flight) {
    if (!this.offerDetails || this.offerDetails.error) {
      return null;
    }
    if (flight.type === 'Stop') {
      return null;
    }
    return this.offerDetails.segments.find((segment) => segment.carrierCode === flight.flightInfo.carrier.code && segment.flightNumber === flight.flightInfo.number);
  }

  technicalStopTooltip(stop) {
    return technicalStopTooltip(stop);
  }

  legFlightWithExtendedTravellers(flight) {
    if (flight.selectedSeats && flight.selectedAncillaries) {
      if (flight.selectedSeats.length || flight.selectedAncillaries.length) {
        return {
          ...flight,
          travellers: this.travellers.travellers.map(traveller => {
            return this.extendTravellerWithSeatAndAncillaries(traveller, flight);
          }),
        };
      }

      return {
        ...flight,
      };
    }

    return {
      ...flight,
    };
  }

  extendTravellerWithSeatAndAncillaries(traveller, flight)  {
    return {
      ...traveller,
      seat: flight.selectedSeats.length ? flight.selectedSeats.find(seat => traveller.id === seat.profileId) : null,
      ancillaries: flight.selectedAncillaries.length ? flight.selectedAncillaries.filter(ancillary => traveller.id === ancillary.profileId && flight.id === ancillary.flightId) : null,
    };
  }



  mounted() {
    EventBus.$on('offer-details-reloaded', this.applyMinHeight);
  }

  beforeDestroy() {
    EventBus.$off('offer-details-reloaded', this.applyMinHeight);
  }
}
