import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { AxiosResponse } from 'axios';
import { PaymentCardModel, UpdatePaymentCardMessage } from './payment-cards.model';

class PaymentCardsApiClass {

  public getPaymentCards(profileId) {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/payment-cards',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public createPaymentCard(profileId, params: PaymentCardModel): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles/{profileId}/payment-cards',
      method: RequestMethod.POST
    }, { profileId }, params);
  }

  public updatePaymentCard(profileId, cardId, params: UpdatePaymentCardMessage) {
    return http.execute({
      path: settings.apiProfile + '/api/v2/payment-cards/{cardId}',
      method: RequestMethod.PUT
    }, { profileId, cardId }, params);
  }

  public removeCard(profileId, cardId) {
    return http.execute({
      path: settings.apiProfile + '/api/v2/payment-cards/{cardId}',
      method: RequestMethod.DELETE
    }, { profileId, cardId }, {});
  }
}

export const PaymentCardsApi: PaymentCardsApiClass = new PaymentCardsApiClass();