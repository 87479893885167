import {
  SlotTransition,
  AnimationName,
  AnimationMode,
} from './layout.model';

// should animate view components during first load
// when true, this will trigger animation state to
// change from UNKNOWN into 
export const SHOULD_ANIMATE_ON_START: boolean = false;

export const DEFAULT_PADDING = 20;

export const CHECK_INTERVAL = 30;

export const MOBILE_NAVIGATION_HEIGHT = 56;
export const MOBILE_SUBNAVIGATION_HEIGHT = 50;

export const DEFAULT_DURATION = 500;
export const DEFAULT_DURATION_HALF = DEFAULT_DURATION / 2;

export const COMMENT_NAME = '#comment';

export const CSS_TRANSITION_END_EVENTS = [
  'webkitTransitionEnd',
  'otransitionend',
  'oTransitionEnd',
  'msTransitionEnd',
  'transitionend',
];

export const DEFAULT_INTRO_TRANSITION: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_INTRO_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_INTRO_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_MESSAGES_TRANSITION: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_MESSAGES_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_MESSAGES_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SUBINTRO_TRANSITION: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SUBINTRO_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SUBINTRO_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SUBMENU_TRANSITION: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SUBMENU_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE_DOWN,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SUBMENU_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE_UP,
  duration: DEFAULT_DURATION,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_HEADER_TRANSITION: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_HEADER_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_HEADER_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.DEFAULT,
};

export const DEFAULT_SIDEBAR_TRANSITION: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_SIDEBAR_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_SIDEBAR_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_ASIDE_TRANSITION: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_ASIDE_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_ASIDE_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_BACKGROUND_TRANSITION: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_BACKGROUND_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_BACKGROUND_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_TRANSITION: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_TRANSITION_SHOW: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_TRANSITION_HIDE: SlotTransition = {
  name: AnimationName.FADE,
  duration: DEFAULT_DURATION_HALF,
  mode: AnimationMode.OUT_IN,
};

export const DEFAULT_TRANSITIONS = {
  INTRO: DEFAULT_INTRO_TRANSITION,
  HEADER: DEFAULT_HEADER_TRANSITION,
  SIDEBAR: DEFAULT_SIDEBAR_TRANSITION,
  ASIDE: DEFAULT_ASIDE_TRANSITION,
  DEFAULT: DEFAULT_TRANSITION,
  SUBINTRO: DEFAULT_SUBINTRO_TRANSITION,
  SUBMENU: DEFAULT_SUBMENU_TRANSITION,
  BACKGROUND: DEFAULT_BACKGROUND_TRANSITION,
  MESSAGES: DEFAULT_MESSAGES_TRANSITION,
};

export const DEFAULT_TRANSITIONS_SHOW = {
  INTRO: DEFAULT_INTRO_TRANSITION_SHOW,
  HEADER: DEFAULT_HEADER_TRANSITION_SHOW,
  SIDEBAR: DEFAULT_SIDEBAR_TRANSITION_SHOW,
  ASIDE: DEFAULT_ASIDE_TRANSITION_SHOW,
  DEFAULT: DEFAULT_TRANSITION_SHOW,
  SUBINTRO: DEFAULT_SUBINTRO_TRANSITION_SHOW,
  SUBMENU: DEFAULT_SUBMENU_TRANSITION_SHOW,
  BACKGROUND: DEFAULT_BACKGROUND_TRANSITION_SHOW,
  MESSAGES: DEFAULT_MESSAGES_TRANSITION_SHOW,
};

export const DEFAULT_TRANSITIONS_HIDE = {
  INTRO: DEFAULT_INTRO_TRANSITION_HIDE,
  HEADER: DEFAULT_HEADER_TRANSITION_HIDE,
  SIDEBAR: DEFAULT_SIDEBAR_TRANSITION_HIDE,
  ASIDE: DEFAULT_ASIDE_TRANSITION_HIDE,
  DEFAULT: DEFAULT_TRANSITION_HIDE,
  SUBINTRO: DEFAULT_SUBINTRO_TRANSITION_HIDE,
  SUBMENU: DEFAULT_SUBMENU_TRANSITION_HIDE,
  BACKGROUND: DEFAULT_BACKGROUND_TRANSITION_HIDE,
  MESSAGES: DEFAULT_MESSAGES_TRANSITION_HIDE,
};

export const TRANSITION_MODES = {
  [AnimationName.NONE]: AnimationMode.DEFAULT,
  [AnimationName.FADE_UP]: AnimationMode.DEFAULT,
  [AnimationName.FADE_DOWN]: AnimationMode.DEFAULT,
  [AnimationName.UP]: AnimationMode.DEFAULT,
  [AnimationName.DOWN]: AnimationMode.DEFAULT,
  [AnimationName.LEFT]: AnimationMode.DEFAULT,
  [AnimationName.RIGHT]: AnimationMode.DEFAULT,
  [AnimationName.FADE]: AnimationMode.OUT_IN,
};

export const TRANSITION_DURATIONS = {
  [AnimationName.NONE]: 0,
  [AnimationName.FADE_UP]: DEFAULT_DURATION,
  [AnimationName.FADE_DOWN]: DEFAULT_DURATION,
  [AnimationName.UP]: DEFAULT_DURATION,
  [AnimationName.DOWN]: DEFAULT_DURATION,
  [AnimationName.LEFT]: DEFAULT_DURATION,
  [AnimationName.RIGHT]: DEFAULT_DURATION,
  [AnimationName.FADE]: DEFAULT_DURATION_HALF,
};

export const DEFAULT_METAKEY = '$default';
