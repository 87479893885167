



























import { Vue, Component, Watch } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import TrainSearchStore from './train-search.store';

@Component({})
export default class TrainExchangeInfoSubmenu extends Vue {
  get basketId() {
    return this.$route.params.basketId;
  }

  get canShowEditLink() {
    return null !== TrainSearchStore.exchangedOffer;
  }

  get isExchangeLoaded() {
    return TrainSearchStore.exchangeLoaded;
  }

  editRequest() {
    EventBus.$emit('show-train-exchange-popup');
  }
}
