



































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import priceModel from '@/const/price.const';
import BasketStore from './basket.store';

const eventName = 'update:show';
const zeroDefault = priceModel.priceModel;

@Component({})
export default class BasketDiscountAppliedPopup extends Vue {
  @Prop() show!: boolean;

  get priceBefore() {
    if (!BasketStore.currentDiscountResponse) {
      return zeroDefault;
    }
    return BasketStore.currentDiscountResponse.priceBeforeDiscount;
  }

  get priceAfter() {
    if (!BasketStore.currentDiscountResponse) {
      return zeroDefault;
    }
    return BasketStore.currentDiscountResponse.priceAfterDiscount;
  }

  get totalDiscount() {
    if (!BasketStore.currentDiscountResponse) {
      return zeroDefault;
    }
    return BasketStore.currentDiscountResponse.discount;
  }

  @Emit(eventName)
  hidePopup() {
    setTimeout(() => {
      BasketStore.updateCurrentDiscountResponse(null);
    });
    return false;
  }
}

