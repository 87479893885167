var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentCategories.length),expression:"currentCategories.length"}],staticClass:"categories-filter"},[_c('label',{staticClass:"title-section-filter"},[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('span',{staticClass:"icon icon-chev-up"},[(_vm.visible)?_c('UiIcon',{staticClass:"icon",attrs:{"icon":"keyboard_arrow_up"},nativeOn:{"click":function($event){_vm.visible = !_vm.visible}}}):_c('UiIcon',{staticClass:"icon",attrs:{"icon":"keyboard_arrow_down"},nativeOn:{"click":function($event){_vm.visible = !_vm.visible}}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"row mt-1"},[_c('div',{staticClass:"col"},_vm._l((_vm.currentCategories),function(category){return _c('div',{key:category.code,staticClass:"d-flex justify-content-between"},[(category.isMixedModeModifier)?_c('UiLabel',{staticClass:"categories-filter__switch-label",attrs:{"inline":true,"label-position":"after"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"categories-filter__switch-label-text"},[_vm._v("\n              "+_vm._s(_vm.$t('search.mixed'))+"\n              "),(_vm.$te('search-filters-mixed-tooltips.' + _vm.code))?_c('UiIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.$t('search-filters-mixed-tooltips.' + _vm.code),
                }),expression:"{\n                  content: $t('search-filters-mixed-tooltips.' + code),\n                }"}],staticClass:"label-info-icon categories-filter__switch-label-icon",attrs:{"icon":"info"}}):_vm._e()],1)]),_c('UiSwitch',{staticClass:"switch-button-xs",attrs:{"seleniumId":'CategoriesFilterMixed_' + _vm.label,"value":category.code},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}})],2):_c('label',{staticClass:"custom-control custom-checkbox label-left-side"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","seleniumId":'CategoriesFilter_' + _vm.label},domProps:{"value":category.code,"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,category.code)>-1:(_vm.checked)},on:{"change":function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=category.code,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}}}}),_c('span',{staticClass:"categories-filter__label custom-control-label",class:_vm.categoryClass(category),attrs:{"seleniumId":'CategoriesFilter_string_' + _vm.label}},[(!category.stars)?[(-1 < [
                  'SUPPLIER',
                  'PROVIDER' ].indexOf(_vm.code))?[_vm._v(_vm._s(_vm.getSupplierName(category.name)))]:('ECO_FRIENDLY' === category.code)?[_vm._v("\n                  "+_vm._s(_vm.$t('search-hotel.eco-friendly'))+"\n                ")]:[_c('span',{staticClass:"categories-filter__label--text"},[_vm._v(_vm._s(_vm.categoryTranslation(category.name)))])],(_vm.code === 'AIRLINE')?_c('span',[_vm._v(" - "+_vm._s(category.code)+" ")]):_vm._e()]:_vm._e(),(category.stars)?_vm._l((category.stars),function(i){return _c('UiIcon',{key:i,staticClass:"categories-filter__star",attrs:{"icon":"star"}})}):_vm._e(),_c('span',{staticClass:"categories-filter__matches"},[_vm._v("("+_vm._s(category.matches)+")")]),(_vm.showImagesForValues &&
                  -1 < [
                    'SUPPLIER',
                    'PROVIDER' ].indexOf(_vm.code))?[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageVisible(category) && !_vm.imageProcessing(category)),expression:"imageVisible(category) && !imageProcessing(category)"}],staticClass:"categories-filter__category-image",class:{
                    'categories-filter__category-image--amadeus': 'amadeus' === category.code.toLowerCase(),
                    'categories-filter__category-image--benerail': 'benerail' === category.code.toLowerCase(),
                    'categories-filter__category-image--travelfusion': 'travelfusion' === category.code.toLowerCase(),
                    'categories-filter__category-image--ouigo': 'ouigo' === category.code.toLowerCase(),
                    'categories-filter__category-image--americanairlines': 'americanairlines' === category.code.toLowerCase(),
                    'categories-filter__category-image--wonderhotel': 'wonderhotel' === category.code.toLowerCase(),
                  },attrs:{"src":_vm.getSupplierImagePath(category)},on:{"load":function($event){return _vm.imageLoaded(category)},"error":function($event){return _vm.imageError(category)}}})]:_vm._e()],2)]),_c('label',{staticClass:"custom-control custom-checkbox label-right-side"},[(category.minPrice)?[(category.minPrice.currency.symbol)?_c('span',[_vm._v(_vm._s(category.minPrice.currency.symbol))]):_c('span',[_vm._v("€")]),_vm._v("\n            "+_vm._s(_vm._f("priceFix")(category.minPrice.amount))+"\n          ")]:[_vm._v("\n             \n          ")]],2)],1)}),0)]),(_vm.visible)?_c('div',{staticClass:"d-flex justify-content-between mb-4 show-more pl-2"},[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExpand),expression:"showExpand"}],attrs:{"seleniumId":'CategoriesFilter_showMore_' + _vm.label}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.expanded),expression:"!expanded"}],staticClass:"cursor-pointer",on:{"click":function($event){_vm.expanded = true}}},[_vm._v("+ "+_vm._s(_vm.$t('search-filter.show-more')))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"cursor-pointer",on:{"click":function($event){_vm.expanded = false}}},[_vm._v("- "+_vm._s(_vm.$t('search-filter.show-less')))])])]),_c('div',{staticClass:"cursor-pointer",attrs:{"seleniumId":'CategoriesFilter_clear_' + _vm.label},on:{"click":function($event){return _vm.clearSelection()}}},[_vm._v(_vm._s(_vm.$t('search-filter.clear-selection')))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }