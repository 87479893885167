





















































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VueContentLoading from 'vue-content-loading';
import moment from 'moment';
import _ from 'lodash';
import settings from '@/settings';
import L, { Map, Marker, TileLayer, LatLngLiteral, LayerGroup, LatLngExpression, Polyline } from 'leaflet';
import CarSearchStore from './car-search.store';
import { CarApi } from '@/api/car-engine/car-search.api';
import { translate } from '@/i18n';
import { DEFAULT_DURATION } from '@/modules/layout/layout.const';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';

const ERROR_EXCEPTION_CODE = 'NO_RULE_FROM_VENDOR';

enum MarkerType {
  Pickup = 'pickup',
  Return = 'return',
  PickupReturn = 'pickupreturn',
}

@Component({
  components: {
    VueContentLoading,
  }
})
export default class CarFareConditions extends Vue {
  @Prop() offer!: any;
  loading: boolean = false;
  conditionsAvailable: boolean = false;
  fareConditions: any | null = null;
  map?: Map;
  tileLayer?: TileLayer;
  markerLayer: LayerGroup = new LayerGroup();
  markers: Marker[] = [];
  line?: Polyline;
  serverErrors: any[] = [];
  mapVisible: boolean = false;

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get pickupTimeString() {
    if (!this.fareConditions.pickupDateTime) {
      return false;
    } else {
      return moment.parseZone(this.fareConditions.pickupDateTime).utc(false).format(this.currentTimeFormat);
    }
  }

  get returnTimeString() {
    if (!this.fareConditions.returnDateTime) {
      return false;
    } else {
      return moment.parseZone(this.fareConditions.returnDateTime).utc(false).format(this.currentTimeFormat);
    }
  }

  get differentAddresses() {
    if (this.fareConditions && this.fareConditions.pickupLocation && this.fareConditions.returnLocation) {
      return !_.isEqual(this.fareConditions.pickupLocation.fullAddress, this.fareConditions.returnLocation.fullAddress);
    }
    if (!this.fareConditions.returnLocation) {
      return false;
    }
    return true;
  }

  get differentGeoLocations() {
    if (this.fareConditions && this.fareConditions.pickupLocation && this.fareConditions.returnLocation) {
      return !_.isEqual(this.fareConditions.pickupLocation.geoLocation, this.fareConditions.returnLocation.geoLocation);
    }
    return false;
  }

  initTiles() {
    this.tileLayer = L.tileLayer(settings.hotelMapTileServerUrl, {
      attribution: settings.hotelMapAttribution
    }).addTo(this.map as Map);
  }

  addMarker(latLng: LatLngLiteral, markerType: string) {
    let marker = L.marker([
      latLng.lat,
      latLng.lng
    ], {
      icon: this.generateLocationMarker(markerType)
    });
    this.markers.push(marker);
    marker.addTo(this.markerLayer);
    marker.addTo(this.map as Map);
  }

  generateLocationMarker(markerType: string) {
    return L.divIcon({
      className: 'fare-map__marker',
      html: `<div class="fare-map__marker-location-tag">
              <div>${ markerType === MarkerType.Return ? translate('common.capital-return') :
              markerType === MarkerType.Pickup ? translate('common.capital-pickup') : translate('search-car.pickup-return') }</div>
            </div>
            <div class="fare-map__marker-tip-container">
              <div class="fare-map__marker-tip"></div>
            </div>`
    });
  }

  clearMarkers() {
    this.markerLayer.clearLayers();
    this.markers = [];
  }

  centerMap() {
    let markerGroup = L.featureGroup(this.markers);
    if (this.map) {
      setTimeout(() => {
        this.map!.fitBounds(markerGroup.getBounds());
      }, DEFAULT_DURATION);
      return;
    } else {
      this.populateMap();
      this.centerMap();
      return;
    }
  }

  async populateMap() {
    if (
      this.fareConditions &&
      this.fareConditions.pickupLocation &&
      this.fareConditions.pickupLocation.geoLocation &&
      !Number.isNaN(this.fareConditions.pickupLocation.geoLocation.lat) &&
      !Number.isNaN(this.fareConditions.pickupLocation.geoLocation.lon)
    ) {
      this.mapVisible = true;
    } else {
      return;
    }

    if (!this.map) {
      await this.$nextTick(() => {
        this.map = L.map('fare-map');
        this.map.setView({
          lat: this.fareConditions.pickupLocation.geoLocation.lat,
          lng: this.fareConditions.pickupLocation.geoLocation.lon,
        }, 16);
        this.initTiles();
        if (this.differentGeoLocations) {
          this.addMarker({
            lat: this.fareConditions.pickupLocation.geoLocation.lat,
            lng: this.fareConditions.pickupLocation.geoLocation.lon,
          }, MarkerType.Pickup);
          this.addMarker({
            lat: this.fareConditions.returnLocation.geoLocation.lat,
            lng: this.fareConditions.returnLocation.geoLocation.lon,
          }, MarkerType.Return);
          this.line = L.polyline([
            { lat: this.fareConditions.pickupLocation.geoLocation.lat, lng: this.fareConditions.pickupLocation.geoLocation.lon },
            { lat: this.fareConditions.returnLocation.geoLocation.lat, lng: this.fareConditions.returnLocation.geoLocation.lon },
          ], { className: 'fare-map__line' }).addTo(this.map);
          this.centerMap();
        } else {
          this.addMarker({
            lat: this.fareConditions.pickupLocation.geoLocation.lat,
            lng: this.fareConditions.pickupLocation.geoLocation.lon,
          }, MarkerType.PickupReturn);
          this.centerMap();
        }
      });      
    } else {
      this.clearMarkers();
      if (this.differentGeoLocations) {
        this.addMarker({
          lat: this.fareConditions.pickupLocation.geoLocation.lat,
          lng: this.fareConditions.pickupLocation.geoLocation.lon,
        }, MarkerType.Pickup);
        this.addMarker({
          lat: this.fareConditions.returnLocation.geoLocation.lat,
          lng: this.fareConditions.returnLocation.geoLocation.lon,
        }, MarkerType.Return);
        this.line = L.polyline([
          { lat: this.fareConditions.pickupLocation.geoLocation.lat, lng: this.fareConditions.pickupLocation.geoLocation.lon },
          { lat: this.fareConditions.returnLocation.geoLocation.lat, lng: this.fareConditions.returnLocation.geoLocation.lon },
        ], { className: 'fare-map__line' });
        this.centerMap();
      } else {
        this.addMarker({
          lat: this.fareConditions.pickupLocation.geoLocation.lat,
          lng: this.fareConditions.pickupLocation.geoLocation.lon,
        }, MarkerType.PickupReturn);
        this.map.setView({
          lat: this.fareConditions.pickupLocation.geoLocation.lat,
          lng: this.fareConditions.pickupLocation.geoLocation.lon,
        }, 16);
      }
    }
  }

  async created() {
    this.loading = true;
    let conditions;
    try {
      conditions = await CarApi.getFareConditions({
        supplier: this.offer.supplier,
        supplierSearchId: this.offer.supplierSearchId,
        searchId: this.$route.params.searchId ? this.$route.params.searchId : null,
        extras: this.offer.rate.extraCharges.map(extra => {
          return extra.code;
        }),
      }, this.offer.offerId);
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.code !== ERROR_EXCEPTION_CODE) {
        this.serverErrors = this.$handleErrors(error, true);
      }
      this.conditionsAvailable = false;
    } finally {
      if (conditions && conditions.data) {
        this.fareConditions = conditions.data;
        this.conditionsAvailable = true;
      }
      this.loading = false;
    }
    await this.populateMap();
  }
}
