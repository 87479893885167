








































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import AssignTravellerToRoomSlot from './AssignTravellerToRoomSlot.vue';

@Component({
  components: {
    AssignTravellerToRoomSlot,
  },
})
export default class AssignTravellersToRoom extends Vue {
  @Prop() roomIndex!: number;
  @Prop() room!: any;
  @Prop() travellers!: any[];
  @Prop() availableTravellers!: any[];



  get maxPax() {
    return this.room.maxPax;
  }

  get slots() {
    return this.room.slots;
  }



  change(param) {
    this.$emit('change', {
      ...param,
      roomIndex: this.roomIndex,
    });
  }

  add() {
    this.$emit('add-slot', {
      roomIndex: this.roomIndex,
    });
  }

  remove(index) {
    this.$emit('remove-slot', {
      roomIndex: this.roomIndex,
      index,
    });
  }
}

