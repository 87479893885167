import AirResults from '@/modules/search/air/AirResults.vue';
import Search from '@/modules/search/Search.vue';
import AirResultsFilters from '@/modules/search/air/AirResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import AirSubintro from '@/modules/search/air/AirSubintro.vue';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/air-results-details/:searchId',
  name: 'airDetails',
  components: {
    default: AirResults,
    intro: Search,
    subintro: AirSubintro,
    sidebar: AirResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-search',
    permission: 'SearchOffers',
    waitForDicts: true,
    preserveSidebar: {
      air: true,
    },
    transitions: {
      intro: {
        search: AnimationName.UP,
        home: AnimationName.LEFT,
        air: AnimationName.UP,
      },
    }
  }
};
