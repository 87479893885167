import ApprovalTasks from '@/modules/expense/approval-tasks/ApprovalTasks.vue';

export default {
  path: 'approval-tasks',
  name: 'approval-tasks',
  component: ApprovalTasks,
  displayName: 'Approval Tasks',
  meta: {
    permission: 'Client'
  }
};