






























































































import { Vue, Component, Prop } from 'vue-property-decorator';

const IMAGE_RELOAD_ATTEMPT_DELAY = 1000;
const IMAGE_SWITCH_ANIMATION_TIME = 300;

@Component({})
export default class UiGallery extends Vue {
  @Prop({ default: [] }) photos?: string[];

  activePhotoIndex: number = 0;
  imageErrorCounter: number = 0;
  imageLoading: boolean = true;
  imageVisible: boolean = true;
  imageProcessed: boolean = false;
  imageAnimating: boolean = true;
  openPhotoPreview: boolean = false;

  get activePhoto() {
    if (this.imageLoading) {
      return this.photos ? this.photos[this.activePhotoIndex] : '';
    } else {
      return '';
    }
  }

  get photoUrls() {
    return this.photos;
  }

  get photosCount() {
    return this.photos ?
    this.photos.length : 0;
  }

  previousPhotoPreview() {
    const event: Event = new Event('swipe');
    event.stopPropagation();
    this.previousPhoto(true);
  }

  previousPhoto(inPreview: boolean = false) {
    if (this.photos) {
      this.imageAnimating = true;
      let mainPhoto = inPreview === true ?
      this.$refs.previewMainPhoto as HTMLElement :
      this.$refs.mainPhoto as HTMLElement;

      if (mainPhoto.classList.contains('anim-left')) {
        mainPhoto.classList.replace('anim-left', 'anim-right');
      }
      mainPhoto.classList.add('hide-right');
      setTimeout(() => {
        this.activePhotoIndex =
          this.activePhotoIndex === 0 ?
          this.photosCount - 1 : this.activePhotoIndex - 1;
        mainPhoto.classList.remove('hide-right');
        this.scrollIntoPhoto(this.activePhotoIndex, inPreview);
      }, IMAGE_SWITCH_ANIMATION_TIME);
      setTimeout(() => {
        this.imageAnimating = false;
      }, IMAGE_SWITCH_ANIMATION_TIME * 1.5);
    }
  }

  nextPhotoPreview() {
    const event: Event = new Event('swipe');
    event.stopPropagation();
    this.nextPhoto(true);
  }

  nextPhoto(inPreview: boolean = false) {
    if (this.photos) {
      this.imageAnimating = true;
      let mainPhoto = inPreview === true ?
      this.$refs.previewMainPhoto as HTMLElement :
      this.$refs.mainPhoto as HTMLElement;

      if (mainPhoto.classList.contains('anim-right')) {
        mainPhoto.classList.replace('anim-right', 'anim-left');
      }
      mainPhoto.classList.add('hide-left');
      setTimeout(() => {
        this.activePhotoIndex =
          (this.activePhotoIndex === this.photosCount - 1) ?
          0 : this.activePhotoIndex + 1;
        mainPhoto.classList.remove('hide-left');
        this.scrollIntoPhoto(this.activePhotoIndex, inPreview);
      }, IMAGE_SWITCH_ANIMATION_TIME);      
      setTimeout(() => {
        this.imageAnimating = false;
      }, IMAGE_SWITCH_ANIMATION_TIME * 1.5);
    }
  }

  goToPhoto(photoIndex, inPreview: boolean = false) {
    this.imageAnimating = true;
    let mainPhoto = inPreview === true ?
    this.$refs.previewMainPhoto as HTMLElement :
    this.$refs.mainPhoto as HTMLElement;
    if (photoIndex < this.activePhotoIndex) {
      if (mainPhoto.classList.contains('anim-left')) {
        mainPhoto.classList.replace('anim-left', 'anim-right');
      }
      mainPhoto.classList.add('hide-right');
      setTimeout(() => {
        this.activePhotoIndex = photoIndex;
        mainPhoto.classList.remove('hide-right');
        this.scrollIntoPhoto(this.activePhotoIndex, inPreview);
      }, IMAGE_SWITCH_ANIMATION_TIME);
      setTimeout(() => {
        this.imageAnimating = false;
      }, IMAGE_SWITCH_ANIMATION_TIME * 2);
    } else if (photoIndex > this.activePhotoIndex) {
      if (mainPhoto.classList.contains('anim-right')) {
        mainPhoto.classList.replace('anim-right', 'anim-left');
      }
      mainPhoto.classList.add('hide-left');
      setTimeout(() => {
        this.activePhotoIndex = photoIndex;
        mainPhoto.classList.remove('hide-left');
        this.scrollIntoPhoto(this.activePhotoIndex, inPreview);
      }, IMAGE_SWITCH_ANIMATION_TIME);
      setTimeout(() => {
        this.imageAnimating = false;
      }, IMAGE_SWITCH_ANIMATION_TIME * 2);
    } else {
      this.imageAnimating = false;
      return;
    }
  }

  scrollIntoPhoto(index: number, inPreview: boolean = false) {
    let photo = inPreview ? 
    this.$el.querySelector('.gallery__preview-minature:nth-child(' + (this.activePhotoIndex + 1).toString() + ')') :
    this.$el.querySelector('.gallery__minature:nth-child(' + (this.activePhotoIndex + 1).toString() + ')');
    if (photo) {
      photo.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
        block: 'nearest',
      });
    }
  }

  imageError(e) {
    this.imageProcessed = false;
    if ('' === this.activePhoto) {
      this.imageProcessed = true;
      return;
    }
    this.imageErrorCounter++;
    if (this.imageErrorCounter < 2) {
      this.imageLoading = false;
      setTimeout(() => {
        this.imageLoading = true;
        this.imageProcessed = true;
      }, IMAGE_RELOAD_ATTEMPT_DELAY);
    } else {
      this.imageVisible = false;
      this.imageProcessed = true;
    }
  }

  mounted() {
    setTimeout( () => {
      this.imageAnimating = false;
    }, IMAGE_SWITCH_ANIMATION_TIME);
  }
}
