import { store } from '@/store';
import accountStore from '@/store/account.store';
import hasAccessFn from '@/core/permissions/has-access.service';

function checkPermission(to: any, next: any, from?: any) {
  let permission = to.meta.permission;
  let hasAccess = hasAccessFn(permission, false);
  if (hasAccess) {
    next();
  } else {
    if (from && from.matched.length > 0) {
      next(false);
    } else {
      next({ name: 'home', replace: true });
    }
  }
}

export default (to, from, next) => {
  if (accountStore.CurrentUser) {
    checkPermission(to, next, from);
  } else {
    let unwatch = store.watch(
      () => accountStore.CurrentUser,
      value => {
        if (!value) {
          return;
        }

        checkPermission(to, next, from);
        unwatch();
      }
    );
  }
};
