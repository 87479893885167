import PaymentAirConfiguration from '@/modules/settings/payment-methods/PaymentAirConfiguration.vue';

export default {
  path: ':configurationId?/air-configuration',
  name: 'air-configuration',
  component: PaymentAirConfiguration,
  displayName: 'Air configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPaymentMethodConfiguration',
    depthCategory: 'payment-methods',
    depth: 3,
    parentName: 'payment-methods-configurations',
  },
};