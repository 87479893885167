






























































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import SearchConst from '@/const/search.const';
import EventBus from '@/services/event-handler';
import SearchStore from '../search.store';
import { HomeApi } from '@/api/home/home.api';
import { translate } from '@/i18n';
import { RailLocationModel, searchModes, serviceClassEnum } from '@/api/home/home.model';
import HeightTransition from '@/modules/layout/HeightTransition.vue';

@Component({
  components: {
    HeightTransition,
  },
})
export default class TrainSearch extends Vue {
  fromLocations: RailLocationModel[] = [];
  toLocations: RailLocationModel[] = [];
  isFromLoading: boolean = false;
  isToLoading: boolean = false;
  shouldFocus: boolean = false;
  searchModes = searchModes;
  isSwapLocationsActive: boolean = false;
  searchModeOptions = [
    {
      value: searchModes.oneWay,
      label: translate('common.one-way'),
    },
    {
      value: searchModes.roundTrip,
      label: translate('common.roundtrip')
    }
  ];
  returnAvailable: boolean = true;
  shouldShowAdvancedCriteria: boolean = false;
  valueTimeDeparture = {
    label: '10:00',
    value: 600,
    timeRange: [600, 720],
  };
  valueTimeReturn = {
    label: '16:00',
    value: 960,
    timeRange: [960, 1080]
  };



  get trainSearchParameters() {
    return SearchStore.getTrainDefaultState;
  }

  get departureTimeSpan() {
    return this.trainSearchParameters.departureTimeSpan;
  }

  set departureTimeSpan(data: any) {
    if (data) {
      this.trainSearchParameters.departureTimeSpan.value[0] = data[0];
      this.trainSearchParameters.departureTimeSpan.value[1] = data[1];
    }
  }

  get returnTimeSpan() {
    return this.trainSearchParameters.returnTimeSpan;
  }

  set returnTimeSpan(data: any) {
    if (data) {
      this.trainSearchParameters.returnTimeSpan.value[0] = data[0];
      this.trainSearchParameters.returnTimeSpan.value[1] = data[1];
    }
  }

  get mobileSearchMode() {
    return this.searchModeOptions
      .find((option) => option.value === this.trainSearchParameters.searchMode);
  }

  set mobileSearchMode(value) {
    if (!value) {
      return;
    }
    this.trainSearchParameters.searchMode = value.value;
  }

  get searchDate() {
    if (this.returnAvailable) {
      return {
        start: moment(this.trainSearchParameters.departureDate).toDate(),
        end:  moment(this.trainSearchParameters.returnDate).toDate(),
      };
    } else {
      return moment(this.trainSearchParameters.departureDate).toDate();
    }
  }

  set searchDate(data: any) {
    if (this.returnAvailable && data && data.start) {
      this.trainSearchParameters.departureDate = data.start;
      this.trainSearchParameters.returnDate = data.end;
    } else if (!this.returnAvailable && data) {
      this.trainSearchParameters.departureDate = data;
    }
  }

  get classesDate() {
    return this.returnAvailable ? 'date-picker-section__search-roundtrip' : 'date-picker-section__search-oneway';
  }

  get classesLocation() {
    return this.returnAvailable ? 'location-select__roundtrip' : 'location-select__one-way';
  }

  get modeType() {
    return this.returnAvailable ? 'range' : 'single';
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get minReturnDate() {
    return this.trainSearchParameters.departureDate
      ? moment(this.trainSearchParameters.departureDate, 'YYYY-MM-DD')
      : moment();
  }

  get toLocationsOptions() {
    return this.toLocations.map(location => ({
      ...location,
      uniqId: location.codes ? JSON.stringify(location.codes) : ''
    }));
  }

  get fromLocationsOptions() {
    return this.fromLocations.map(location => ({
      ...location,
      uniqId: location.codes ? JSON.stringify(location.codes) : ''
    }));
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get valueTimeOptions() {
    return SearchConst.railSearchTimeValues;
  }

  get datePickerTimeLabels() {
    return {
      start: translate('search-train.departure'),
      end: translate('search-train.return'),
    };
  }


  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isFromLoading',
  })
  async findFromLocation(query) {
    if (query && query.length > 2) {
      this.isFromLoading = true;
      const response = await HomeApi.findRailLocation(query, this.languageCode);

      this.fromLocations = [];
      if (response && response.data) {
        this.fromLocations = response.data;
      }

      this.isFromLoading = false;
    } else {
      this.isFromLoading = false;
      this.fromLocations = [];
      if (this.trainSearchParameters.from) {
        this.fromLocations.push(this.trainSearchParameters.from);
      }
    }
  }

  onDateInvalid(value) {
    EventBus.$emit('date-invalid', {service: serviceClassEnum.Train, value});
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isToLoading',
  })
  async findToLocation(query) {
    if (query && query.length > 2) {
      this.isToLoading = true;
      const response = await HomeApi.findRailLocation(query, this.languageCode);

      this.toLocations = [];
      if (response && response.data) {
        this.toLocations = response.data;
      }

      this.isToLoading = false;
    } else {
      this.isToLoading = false;
      this.toLocations = [];
      if (this.trainSearchParameters.to) {
        this.toLocations.push(this.trainSearchParameters.to);
      }
    }
  }

  showAdvancedCriteria() {
    this.shouldShowAdvancedCriteria = true;
  }

  hideAdvancedCriteria() {
    this.shouldShowAdvancedCriteria = false;
  }

  swapLocations() {
    this.isSwapLocationsActive = true;
    let from = this.trainSearchParameters.from;
    this.trainSearchParameters.from = this.trainSearchParameters.to;
    this.trainSearchParameters.to = from;

    this.prepareTo();
    this.prepareFrom();
    setTimeout(() => {
      this.isSwapLocationsActive = false;
    }, 450);
  }

  checkMode() {
    this.returnAvailable = this.trainSearchParameters.searchMode === 'RoundTrip';
  }

  focusOn(elementId) {
    if (window.innerWidth < 800) {
      return;
    }
    const inputValue = ((this.$refs[elementId] as Vue).$el as HTMLInputElement);
    inputValue.getElementsByTagName('input')[0].focus();
  }

  onReceive(data) {
    if (data) {
      const element: Vue = this.$refs.fromSelect as Vue;
      const from: Multiselect = element.$refs.multiselect as Multiselect;
      const searchInput: HTMLInputElement = from.$refs.search as HTMLInputElement;

      if (!searchInput) {
        return;
      }
      return searchInput && searchInput.focus();
    }
  }

  @AnimatedIn()
  animatedIn() {
    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.dateEnter();
    } else if (this.shouldFocus) {
      this.onReceive(true);
    }
  }

  onFocus() {
    this.shouldFocus = true;
  }

  dateEnter() {
    this.$emit('focusSearch');
  }

  prepareFrom() {
    this.fromLocations = [];
    if (this.trainSearchParameters.from) {
      this.fromLocations.push(this.trainSearchParameters.from);
    }
  }

  prepareTo() {
    this.toLocations = [];
    if (this.trainSearchParameters.to) {
      this.toLocations.push(this.trainSearchParameters.to);
    }
  }

  handleEnterPress() {
    EventBus.$emit('focus-on-search');
  }

  findTimeRange(range) {
    const windowFound = !range ? false : this.valueTimeOptions.find((value) => {
      return value.timeRange[0] === range[0] && value.timeRange[1] === range[1];
    });
    return windowFound ? windowFound : this.valueTimeOptions[this.valueTimeOptions.length - 1];
  }

  refreshTimeWindows() {
    if (this.trainSearchParameters.departureTimeSpan) {
      let value = this.trainSearchParameters.departureTimeSpan.value;
      this.valueTimeDeparture = this.findTimeRange(value) as any;
    }
    if (this.trainSearchParameters.returnTimeSpan) {
      let value = this.trainSearchParameters.returnTimeSpan.value;
      this.valueTimeReturn = this.findTimeRange(value) as any;
    }
  }

  @Watch('trainSearchParameters.searchMode', { immediate: true, deep: true })
  onChangedSearchMode(val) {
    if (this.trainSearchParameters.searchMode === this.searchModes.oneWay) {
      this.returnAvailable = false;
      this.trainSearchParameters.returnDate = '';
    } else {
      this.returnAvailable = true;
      if (this.trainSearchParameters.returnDate) {
        if (moment(this.trainSearchParameters.returnDate).isAfter(
          moment(this.trainSearchParameters.departureDate).add(1, 'days')
        )) {
          this.refreshTimeWindows();
          return;
        }
        this.trainSearchParameters.returnDate = moment(this.trainSearchParameters.returnDate).format('YYYY-MM-DD');
      } else {
        this.trainSearchParameters.returnDate = moment(this.trainSearchParameters.departureDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    this.refreshTimeWindows();
  }

  @Watch('trainSearchParameters.departureDate')
  onChangedDeparture(val) {
    if (val) {
      this.trainSearchParameters.departureDate = moment(this.trainSearchParameters.departureDate).format('YYYY-MM-DD');
    }
    if (moment(this.trainSearchParameters.departureDate).isAfter(this.trainSearchParameters.returnDate)) {
        this.trainSearchParameters.returnDate = moment(this.trainSearchParameters.departureDate).add(1, 'days').format('YYYY-MM-DD');
    }
    if (new Date(val).getTime() < new Date().getTime()) {
      this.trainSearchParameters.departureDate = moment().format('YYYY-MM-DD');
    }
  }

  @Watch('trainSearchParameters.returnDate')
  onChangedReturn(val) {
    if (this.trainSearchParameters.searchMode === this.searchModes.oneWay) {
      this.trainSearchParameters.returnDate = '';
    } else {
      if (this.trainSearchParameters.returnDate) {
        this.trainSearchParameters.returnDate = moment(this.trainSearchParameters.returnDate).format('YYYY-MM-DD');
      }
    }
  }

  @Watch('valueTimeDeparture', { deep: true })
  onChangedDepartureTimeWindow(val) {
    if (val) {
      this.trainSearchParameters.departureTimeSpan = val.timeRange;
    }
  }

  @Watch('valueTimeReturn', { deep: true })
  onChangedReturnTimeWindow(val) {
    if (this.trainSearchParameters.searchMode === this.searchModes.oneWay) {
      this.trainSearchParameters.departureTimeSpan = null;
    } else {
      if (this.trainSearchParameters.returnDate) {
        this.trainSearchParameters.returnTimeSpan = val.timeRange;
      }
    }
  }

  created() {
    EventBus.$on('focus-from', this.onFocus);
  }

  beforeDestroy() {
    EventBus.$off('focus-from', this.onFocus);
  }
}
