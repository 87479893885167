import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  PaymentInstrumentsResult,
} from './expense-payments.model';

class ExpensePaymentsApiClass {
  public getPaymentInstruments(policyId: string): Promise<AxiosResponse<PaymentInstrumentsResult[]>> {
    return http.execute({
      path: settings.apiExpenseCore + '/paymentInstruments?policyId={policyId}',
      method: RequestMethod.GET,
    }, { policyId }, {});
  }

  public getPaymentInstrument(paymentInstrumentId: string): Promise<AxiosResponse<PaymentInstrumentsResult>> {
    return http.execute({
      path: settings.apiExpenseCore + '/paymentInstruments/{paymentInstrumentId}',
      method: RequestMethod.GET,
    }, { paymentInstrumentId }, {});
  }

  public createPaymentInstrument(params) {
    return http.execute({
      path: settings.apiExpenseCore + '/paymentInstruments',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updatePaymentInstrument(paymentInstrumentId: string, params) {
    return http.execute({
      path: settings.apiExpenseCore + '/paymentInstruments/{paymentInstrumentId}',
      method: RequestMethod.PUT,
    }, { paymentInstrumentId }, params );
  }

  public removePaymentInstrument(paymentInstrumentId: string) {
    return http.execute({
      path: settings.apiExpenseCore + '/paymentInstruments/{paymentInstrumentId}',
      method: RequestMethod.DELETE,
    }, { paymentInstrumentId }, {} );
  }

}

export const ExpensePaymentsApi: ExpensePaymentsApiClass = new ExpensePaymentsApiClass();
