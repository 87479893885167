import Billing from '@/modules/settings/billing/Billing.vue';
import BillingConfigurations from './billing-configurations.route';
import BillingConfiguration from './billing-configuration.route';
import { translate } from '@/i18n';

export default {
  path: 'billing',
  component: Billing,
  displayName: 'settings-sidebar.back-office-configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadBackOfficeConfiguration',
    icon: 'feed',
    breadCrumb: 'Billing configuration',
    parentName: 'billing-configurations',
  },
  children: [
    BillingConfigurations,
    BillingConfiguration,
  ],
};