


































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import AccountStore from '@/store/account.store';
import SearchConst from '@/const/search.const';
import FlightTimeline from './FlightTimeline.vue';
import FlightTimelineDetails from './FlightTimelineDetails.vue';
import FlightTimelineDetailsView from './FlightTimelineDetailsView.vue';
import FlightProposalsDetails from './FlightProposalsDetails.vue';
import { FlightDetails } from '@/api/air-engine/air-search.model';
import AirFareConditions from './AirFareConditions.vue';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from '@/modules/basket/basket.store';
import { router } from '@/router';
import AirExchangeModifyPopup from './AirExchangeModifyPopup.vue';
import AirExchangeOfferDetails from './AirExchangeOfferDetails.vue';
import AirResultGroupFlightError from './AirResultGroupFlightError.vue';
import { translate } from '@/i18n';
import formatPrice from '@/filters/format-price.filter';
import AirSeatMap from './AirSeatMap.vue';
import { AirSeats } from './air-seats.model';
import SearchStore from '../search.store';

@Component({
  components: {
    FlightTimeline,
    FlightTimelineDetails,
    AirFareConditions,
    AirSeatMap,
    AirExchangeModifyPopup,
    AirExchangeOfferDetails,
    AirResultGroupFlightError,
    FlightTimelineDetailsView,
    FlightProposalsDetails,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
    momentDateTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm');
    },
  }
})
export default class AirResultsRowView extends Vue {
  @Prop() offer!: FlightDetails;
  @Prop() item?: any;
  @Prop({ default: false }) enableFilterByFlightNumber!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;
  @Prop({ default: false }) shouldHide!: boolean;
  @Prop({ default: true }) searchMode!: boolean;
  @Prop({ default: false }) modifyMode!: boolean;
  @Prop({ default: false }) bookingInProgress!: boolean;
  @Prop({ default: false }) disableAgencyMarkup!: boolean;
  @Prop({ default: true }) showPrice!: boolean;
  @Prop({ default: false }) disablePriceChanged!: boolean;

  showDetails: boolean = false;
  seatsViewPopup: boolean = false;
  errors: any[] = [];
  flightDetailsData: any = null;
  selectedSeats: AirSeats | null = null;
  canShow = this.searchMode;
  showEditSegmentPopup: boolean = false;
  animating: boolean = false;
  showExchangeDetailsPopup: boolean = false;
  disabledInfo: string = translate('search-air.max-selected');
  currentTemplateOffers: any[] = [];
  currentSsid: string = '';
  loaderImagePath: string = '/assets/img/loader/1.gif';
  imagesErrorInfoConst = '/assets/img/ic_error.png';

  get timelinesCollapsed() {
    return false;
  }

  get canUserBookTrip() {
    return AccountStore.HasPermission('BookTrip');
  }

  get searchModeType() {
    return this.airSearchParameters.searchMode;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get templateOffers(): any[] {
    return AirSearchStore.templateOffers;
  }

  get isThisOfferSelected() {
    return AirSearchStore.isSelected && this.offer.id === AirSearchStore.currentOfferId;
  }

  get sameAsExchangedOffer() {
    return AirSearchStore.getExchangedOffer ? AirSearchStore.getExchangedOffer.id === this.offer.id : false;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get currentTravellers() {
    return AirSearchStore.getExchangeTravellers;
  }

  get modificationBasketId() {
    return AirSearchStore.modificationBasketId;
  }

  get sameMonth() {
    if (this.offer) {
      return moment(this.startDate).isSame(this.endDate, 'month');
    }
  }

  get startDate() {
    if (this.offer) {
      return this.offer.legs[0].flights[0].departure;
    }
  }

  get endDate() {
    if (this.offer) {
      const lastLeg: any = _.last(this.offer.legs);
      const lastFlight: any = _.last(lastLeg.flights);
      return lastFlight.arrival;
    }
  }

  get canCompareOffers() {
    return AccountStore.HasPermission('CanSendOfferProposal');
  }

  get user() {
    return AccountStore.current;
  }

  get fareUnavailableCode() {
    return AirSearchStore.fareUnavailableErrorParams;
  }

  get offerProposals() {
    return this.offer.offerProposals;
  }

  get searchCompleted() {
    return AirSearchStore.searchCompleted;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  isNdc(supplier) {
    return -1 < SearchConst.ndcProviders.indexOf(supplier);
  }

  priceFormat(price) {
    return formatPrice(price);
  }

  carriers(leg) {
    return leg.flights
      .filter(flight => flight.flightInfo)
      .map(flight => flight.flightInfo.carrier)
      .filter((value, index, self) => self.findIndex((iv) => iv.name === value.name && iv.code === value.code) === index);
  }

  toggleDetails() {
    AirSearchStore.updateLoadingProposalOffer({offer: this.offer, value: true});
    this.showDetails = !this.showDetails;
    if (this.showDetails) {
      AirSearchStore.setStartSearchingMoreProposals(true);
      AirSearchStore.setOpenOffers(this.offer);
    } else {
      AirSearchStore.setStartSearchingMoreProposals(false);
      AirSearchStore.removeOffers(this.offer);
    }
  }

  flightSelected(e) {
    this.showDetails = false;
    this.$emit('flightSelected', e);
  }

  refreshFlightNumberHashState(hashes) {
    this.offer.legs.forEach(leg => {
      const selected = hashes.includes(leg.flightNumberHash);
      const timelineKey = `leg${leg.legNumber}`;
      const timelineRefs = this.$refs.flightTimelines as FlightTimeline[];
      const timeline = timelineRefs.find((x: any) => x.$vnode.key === timelineKey);

      if (timeline) {
        timeline.setFlightSelectedState(selected);
      }
    });
  }

  shouldFadeLeg(legIndex) {
    if (-1 === [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) {
      return false;
    }
    if (legIndex >= AirSearchStore.exchangeLegsSelectedForEdit.length) {
      return false;
    }
    return !AirSearchStore.exchangeLegsSelectedForEdit[legIndex];
  }

  showExchangeDetails() {
    this.showExchangeDetailsPopup = true;
  }

  showMobileOfferDetails(offer) {
    let name = this.isNewFlightDisplay ? 'airResultDetailsView' : 'airResultDetails';
    router.push({
      name: name,
      params: {
        searchId: this.$route.params.searchId,
        recomendationId: offer.id,
      }
    });
  }

  formatPrice(price) {
    if (!price) {
      return '';
    }
    return price.currency.symbol + ' ' + (Math.round(100 * price.amount) / 100).toFixed(2);
  }

  toggleAnimationFinished() {
    this.animating = !this.showDetails;
  }

  mounted() {
    this.currentSsid = this.$route.params.searchId;
  }
}
