














import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';

@Component
export default class UiForm extends Vue {
  @Prop({ default: false}) disableEnterSubmit!: boolean;

  elements: any[] = [];

  mounted() {
    if (!this.disableEnterSubmit) {
      return;
    }
    this.removeInputsDefault();
  }

  isKeyboardEvent(event: Event): event is KeyboardEvent {
    return 'code' in event;
  }

  onKeyDown(event: Event) {
    if (this.isKeyboardEvent(event)) {
      if (event.code === 'Enter') {
        event.preventDefault();
      }
    }
  }

  removeInputsDefault() {
    this.$el.querySelectorAll('.content input').forEach((element) => {
      this.elements.push(element);
      element.addEventListener('keydown', this.onKeyDown);
    });
  }

  beforeDestroy() {
    this.elements.forEach(element => {
      element.removeEventListener('keydown', this.onKeyDown);
    });

    this.elements = [];
  }
}
