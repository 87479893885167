import PersonalInformation from '@/modules/profile/personal/PersonalInformation.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '',
  name: 'personal',
  component: PersonalInformation,
  displayName: 'profile-personal.general-data',
  meta: {
    permission: 'ReadProfilePersonal',
    icon: 'info',
    transitions: {
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  }
};
