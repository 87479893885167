












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const hasParent = (element, parent) => {
  if (element === parent) {
    return true;
  }
  return element.parentNode && hasParent(element.parentNode, parent);
};

@Component({})
export default class UiDropdown extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: true }) hideOnOutClick!: boolean;

  showing: boolean = false;

  windowMouseUp($event) {
    if (!hasParent($event.target, this.$refs.dropdown)) {
      this.showing = false;
      this.$emit('update:show', false);
    }
  }

  @Watch('show', { immediate: true })
  onShowChange(value) {
    this.showing = value;
    if (!this.hideOnOutClick) {
      return;
    }
    if (value) {
      window.addEventListener('mouseup', this.windowMouseUp, true);
    } else if (!value) {
      window.removeEventListener('mouseup', this.windowMouseUp);
    }
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.windowMouseUp);
  }
}

