import {
  translate,
} from '@/i18n';

export const AirlinesString = (airlines) => {
  if (airlines.length > 2) {
    return `${airlines.length} ${translate('settings-travel-policy.lowercase-airlines')}`;
  }
  let result: any[] = [];
  airlines.forEach((item: any) => {
    result.push(item.businessName ? item.businessName : item);
  });

  return result.join(', ');
};