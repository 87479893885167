import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import SettingsStore from '@/modules/settings/settings.store';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { Permission } from '@/const/permission.enum';
import { ConfigurationRow, companyConfigurationType } from '@/api/profile/configurations.model';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import { ExpenseApi } from '@/api/expense/expense.api';
import { ExpenseExtensionsApi } from '@/api/expense/expense-extensions.api';
import  { ExpenseConfigurationsApi } from '@/api/profile/expense-configuration.api';
import { ExpenseWorkflowApi } from '@/api/expense/expense-workflow.api';
import { WorkflowSettingModel } from '@/api/expense/expense-workflow.model';
import  { ExpenseCategoriesApi } from '@/api/expense/expense-categories.api';
import { CustomFieldsExpenseApi } from '@/api/expense/custom-fields-expense.api';
import { ExpensePaymentsApi } from '@/api/expense/expense-payments.api';
import { ExpenseAuditRulesApi } from '@/api/expense/expense-audit-rules.api';
import { AuditRuleModel } from '@/api/expense/expense-audit-rules.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'expensePolicy'
})
class ExpensePolicyStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  configurations: any[] = [];
  configuration: any = {};
  expenseDetails: any = {};
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  activeWorkflowTab: string = 'MissionPreapproval';
  expenseWorkflowSetting: WorkflowSettingModel = new WorkflowSettingModel();
  expenseCategories: any[] = [];
  expenseCategory: any = {};
  activeTab: string = 'basic';
  basicExtensions: any[] = [];
  basicExtension: any = {};
  customFieldsList: any[] = [];
  customField: any = {};
  customFieldDefinition: any[] = [];
  paymentInstruments: any[] = [];
  paymentInstrument: any = {};
  auditRules: AuditRuleModel[] = [];
  auditRule: AuditRuleModel = new AuditRuleModel();
  activeCategoryTab: string = 'expenseCategory';
  categoriesForTripType: any[] = [];
  auditRuleExpressionCreator: any = {};
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get companyId() {
    return SettingsStore.currentCompany!.rootCompanyId;
  }

  @Mutation
  setBasicTab() {
    this.activeTab = 'basic';
  }

  @Mutation
  setCustomTab() {
    this.activeTab = 'custom';
  }

  @Mutation
  setCategoryTab() {
    this.activeCategoryTab = 'expenseCategory';
  }

  @Mutation
  setAssignCategoryTab() {
    this.activeCategoryTab = 'assignCategory';
  }

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setName(value) {
    this.configuration.name = value;
  }

  @Mutation
  setConfigurations(value) {
    this.configurations = value;
  }

  @Mutation
  setConfiguration(value) {
    this.configuration = value;
  }

  @Mutation
  setPaymentInstruments(value) {
    this.paymentInstruments = value;
  }

  @Mutation
  setPaymentInstrument(value) {
    this.paymentInstrument = value;
  }

  @Mutation
  setConfigurationId(value) {
    this.configuration.id = value;
  }

  @Mutation
  setExpenseDetails(value) {
    this.expenseDetails = value;
  }

  @Mutation
  setExpenseCategories(value) {
    this.expenseCategories = value;
  }

  @Mutation
  setExpenseCategory(value) {
    this.expenseCategory = value;
  }
  
  @Mutation
  setBasicExtensions(payload) {
    this.basicExtensions = payload;
  }

  @Mutation
  setBasicExtension(payload) {
    this.basicExtension = payload;
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setMissionPreapprovalTab() {
    this.activeWorkflowTab = 'MissionPreapproval';
  }

  @Mutation
  setReportApprovalTab() {
    this.activeWorkflowTab = 'ExpenseReportValidation';
  }

  @Mutation
  setSingleExpenseValidationTab() {
    this.activeWorkflowTab = 'SingleExpenseValidation';
  }

  @Mutation
  setExpenseWorkflowSetting(payload) {
    this.expenseWorkflowSetting = payload;
  }

  @Mutation
  addNewApprover(payload) {
    this.expenseWorkflowSetting.assignedManualApprovers.push(payload);
  }

  @Mutation
  setCustomFieldsList(payload) {
    this.customFieldsList = payload;
  }

  @Mutation
  setCustomField(payload) {
    this.customField = payload;
  }

  @Mutation
  setCustomFieldDefinition(payload) {
    this.customFieldDefinition = payload;
  }

  @Mutation
  setAuditRules(payload) {
    this.auditRules = payload;
  }

  @Mutation
  setAuditRule(payload) {
    this.auditRule = payload;
  }

  @Mutation
  setCategoriesForTripType(payload) {
    this.categoriesForTripType = payload;
  }

  @Mutation
  setAuditRuleExpressionCreator(payload) {
    this.auditRuleExpressionCreator = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async getExpenseConfiguration(data) {
    this.setLoading(true);
    if (data.configurationId) {
      const result = await ProfileConfigurationsApi.getByRootCompanyId(data.companyId, companyConfigurationType.expensePoliciesConfiguration, 0, 1, Permission.ReadExpensePolicyDefinition);
      if (result && result.data) {
        const configuration = result.data.results.find((e) => {
          return e.id === data.configurationId;
        });
        if (configuration) {
          this.setConfiguration(configuration);
        }
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async getExpensePoliciesConfiguration(configurationId) {
    try {
      this.setLoading(true);
      const result = await ExpenseApi.getExpensePolicies(configurationId);
      if (result && result.data) {
        this.setConfigurations(result.data);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getExpensePolicyDetails(expensePolicyId) {
    try {
      this.setLoading(true);
      const result = await ExpenseApi.getExpensePolicyDetails(expensePolicyId);
      if (result && result.data) {
        this.setExpenseDetails(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createExpenseConfiguration(data) {
    try {
      this.setLoading(true);
      const result = await ExpenseConfigurationsApi.createExpensePolicyConfiguration({rootCompanyId: data.rootCompanyId, name: data.name}, data.permission);
      if (result && result.data) {
        this.setConfigurationId(result.data.id);
      }
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.policy-saved-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateExpenseConfiguration(data) {
    try {
      this.setLoading(true);
      await ExpenseConfigurationsApi.updateExpensePolicyConfiguration(data.configurationId, data.permission, { name: data.name } );
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.policy-updated-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createExpenseDefinition(params) {
    try {
      this.setLoading(true);
      await ExpenseApi.createExpensePolicy(params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.definition-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateExpenseDefinition(data) {
    try {
      this.setLoading(true);
      await ExpenseApi.updateExpensePolicy(data.expensePolicyId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.definition-updated-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateDefaultExpensePolicy(params) {
    try {
      this.setLoading(true);
      await ExpenseApi.updateDefaultExpensePolicy(this.configuration.id, params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.default-updated-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getExpenseWorkflow(data) {
    try {
      this.setLoading(true);
      const result = await ExpenseWorkflowApi.getWorkflowSettings(data.policyId, data.stage);
      if (result && result.data) {
        this.setExpenseWorkflowSetting(result.data[0]);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
  
  @Action
  async getExpenseCategories(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpenseCategoriesApi.getExpenseCategories(policyId);
      if (result && result.data) {
        this.setExpenseCategories(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getBasicExtensionsList(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpenseExtensionsApi.getExpenseExtensionsList(policyId);
      if (result && result.data) {
        this.setBasicExtensions(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getPaymentsConfiguration(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpensePaymentsApi.getPaymentInstruments(policyId);
      if (result && result.data) {
        this.setPaymentInstruments(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateExpenseWorkflow(data) {
    try {
      this.setLoading(true);
      await ExpenseWorkflowApi.updateWorkflowSettings(data.id, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getExpenseCategory(categoryId) {
    try {
      this.setLoading(true);
      const result = await ExpenseCategoriesApi.getExpenseCategory(categoryId);
      if (result && result.data) {
        this.setExpenseCategory(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getBasicExtension(expenseExtensionId) {
    try {
      this.setLoading(true);
      const result = await ExpenseExtensionsApi.getExpenseExtension(expenseExtensionId);
      if (result && result.data) {
        this.setBasicExtension(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getPaymentInstrument(paymentInstrumentId) {
    try {
      this.setLoading(true);
      const result = await ExpensePaymentsApi.getPaymentInstrument(paymentInstrumentId);
      if (result && result.data) {
        this.setPaymentInstrument(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createExpenseCategory(data) {
    try {
      this.setLoading(true);
      await ExpenseCategoriesApi.createExpenseCategory(data);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.configuration-saved-category'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createBasicExtension(params) {
    try {
      this.setLoading(true);
      await ExpenseExtensionsApi.addExpenseExtensions(params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-saved-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateBasicExtension(data) {
    try {
      this.setLoading(true);
      await ExpenseExtensionsApi.updateExpenseExtensions(data.expenseExtensionId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createPaymentInstrument(data) {
    try {
      this.setLoading(true);
      await ExpensePaymentsApi.createPaymentInstrument(data);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.configuration-saved-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateExpenseCategory(data) {
    try {
      this.setLoading(true);
      await ExpenseCategoriesApi.updateExpenseCategory(data.categoryId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated-category'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getCustomFieldsList(policyId) {
    try {
      this.setLoading(true);
      const result = await CustomFieldsExpenseApi.getCustomFieldsList(policyId);
      if (result && result.data) {
        this.setCustomFieldsList(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getCustomField(expenseExtensionId) {
    try {
      this.setLoading(true);
      const result = await CustomFieldsExpenseApi.getCustomField(expenseExtensionId);
      if (result && result.data) {
        this.setCustomField(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createCustomField(data) {
    try {
      this.setLoading(true);
      let request = await ExpenseExtensionsApi.addExpenseExtensions(data.createRequest);
      if (request && request.data) {
        await CustomFieldsExpenseApi.addCustomFields(request.data.expenseExtensionId, data.request);
      }
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.configuration-saved-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateCustomField(data) {
    try {
      this.setLoading(true);
      await CustomFieldsExpenseApi.updateCustomFields(data.expenseExtensionId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updatePaymentInstrument(data) {
    try {
      this.setLoading(true);
      await ExpensePaymentsApi.updatePaymentInstrument(data.paymentInstrumentId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated-success'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getCustomFieldDefinition() {
    try {
      this.setLoading(true);
      const result = await CustomFieldsApi.getActiveCustomFields(this.companyId);
      if (result && result.data) {
        this.setCustomFieldDefinition(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getAuditRules(data) {
    try {
      this.setLoading(true);
      const result = await ExpenseAuditRulesApi.getAuditRules(data.policyId, data.stage, data.scope);
      if (result && result.data) {
        this.setAuditRules(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  async getCategoriesForTripType(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpenseApi.getCategoriesForTripType(policyId);
      if (result && result.data) {
        this.setCategoriesForTripType(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getAuditRule(auditRuleId) {
    try {
      this.setLoading(true);
      const result = await ExpenseAuditRulesApi.getAuditRule(auditRuleId);
      if (result && result.data) {
        this.setAuditRule(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getAuditRuleExpressionCreator(auditRuleId) {
    try {
      this.setLoading(true);
      const result = await ExpenseAuditRulesApi.getAuditRuleExpressionCreator(auditRuleId);
      if (result && result.data) {
        this.setAuditRuleExpressionCreator(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createAuditRule(params) {
    try {
      this.setLoading(true);
      await ExpenseAuditRulesApi.createAuditRule(params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.configuration-saved-audit-rule'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateAuditRule(data) {
    try {
      this.setLoading(true);
      await ExpenseAuditRulesApi.updateAuditRule(data.auditRuleId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated-audit-rule'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateCategoriesForTripType(data) {
    try {
      this.setLoading(true);
      await ExpenseApi.updateCategoriesForTripType(data.policyId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.configuration-updated'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

}

export default getModule(ExpensePolicyStore);
