



















































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';
import DebounceConst from '@/const/debounce.const';
import { Debounce } from '@/core/decorators/debounce.decorator';
import { ProfileApi } from '@/api/profile/profile.api';
import ProfileStore from '@/modules/profile/profile.store';
import { ApprovalWorkflowApi } from '@/api/approval-workflow/approval-workflow.api';
import { DesignatedAccessType } from '@/api/approval-workflow/approval-workflow.model';

@Component({})
export default class ProfileDesignatedTravellers extends Vue {
  loading: boolean = true;
  errors: any[] = [];
  popupErrors: any[] = [];
  editMode: boolean = false;
  isVisible: boolean = false;
  accessType: DesignatedAccessType = DesignatedAccessType.NoAccess;
  items: any[] = [];
  candidate: any = null;
  candidatesLoading: boolean = false;
  showRemovePopup: boolean = false;
  candidates: any[] = [];
  phraseCandidate: string = '';
  phrase: string = '';
  selectedIds: string[] = [];
  currentPage: number = 1;
  params = {
    sort: '',
    size: Number.MAX_SAFE_INTEGER,
    page: 1,
    usePageParam: true,
  };
  fields = {
    profileName: {
      sortable: false,
      label: translate('settings-community.profile-name'),
    },
    actions: {
      sortable: false,
      label: '',
      class: 'designated__checkbox',
    }
  };


  get canShowSection() {
    return this.isVisible &&
      this.$hasAccess('CanViewDesignatedTravellersForApprovalInProfile');
  }

  get canEdit() {
    return this.accessType === DesignatedAccessType.CanEdit;
  }

  get itemsFiltered() {
    return this.items
      .filter(x => {
        const fullName = userFullName(x).toLowerCase();
        return fullName.includes(this.phrase.toLowerCase());
      })
      .map(item => {
        return {
          ...item,
          selected: this.selectedIds.includes(item.profileId),
        };
      });
  }

  get candidatesFiltered() {
    return this.candidates.filter(c => !this.items.find(i => i.profileId === c.profileId));
  }



  search() {
    this.phrase = this.phraseCandidate;
  }

  toggleSelected(id) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter(x => x !== id);
      return;
    }
    this.selectedIds.push(id);
  }

  selectAll() {
    this.selectedIds = this.items.map(x => x.profileId);
  }

  deselectAll() {
    this.selectedIds.splice(0, this.selectedIds.length);
  }

  remove() {
    if (!this.selectedIds.length) {
      return;
    }
    this.showRemovePopup = true;
  }

  async removeNow() {
    if (!this.selectedIds.length) {
      return;
    }
    try {
      await ApprovalWorkflowApi.removeDeisignatedTravellers(this.$route.params.id, this.selectedIds);

      this.items = this.items.filter(x => !this.selectedIds.includes(x.profileId));
      this.showRemovePopup = false;
    } catch (error) {
      this.popupErrors = this.$handleErrors(error);
    }
  }

  async addToTheList() {
    if (!this.candidate) {
      return;
    }
    try {
      await ApprovalWorkflowApi.addDeisignatedTraveller(this.$route.params.id, this.candidate.profileId);

      this.items.push(this.candidate);
      this.candidate = null;
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

  userFullName(user) {
    return userFullName(user);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'candidatesLoading',
  })
  async loadCandidates(query) {
    try {
      this.candidatesLoading = true;
      const result = await ProfileApi.getDesignatedTravellers(this.$route.params.id, query);
      if (result && result.data) {
        this.candidates = result.data
          .filter(c => !this.items.find(i => i.profileId === c.profileId));
      } else {
        this.candidates = [];
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.candidatesLoading = false;
    }
  }

  handleEnterPress() {
    setTimeout(() => {
      if (window.innerWidth >= 800) {
        const btn = ((this.$refs.addToTheList as Vue).$el as HTMLInputElement);
        btn.focus();
      }
    }, 50);
  }

  async reload() {
    ProfileStore.setHasAccessToApprovalWorkflow(false);
    try {
      const { data } = await ApprovalWorkflowApi.getDeisignatedTravellers(this.$route.params.id);
      this.isVisible = data.accessLevel !== DesignatedAccessType.NoAccess;
      this.accessType = data.accessLevel;
      this.items = data.assignedTravellers;
      ProfileStore.setHasAccessToApprovalWorkflow(this.isVisible);
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
    this.loading = false;
  }

  @Watch('$route', { immediate: true , deep: true})
  onchangeRoute() {
    this.reload();
  }
}
