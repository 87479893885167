import { render, staticRenderFns } from "./TravelPolicyFlightManageRule.vue?vue&type=template&id=4b0bd424"
import script from "./TravelPolicyFlightManageRule.vue?vue&type=script&lang=ts"
export * from "./TravelPolicyFlightManageRule.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports