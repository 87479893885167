import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { TrainOrdersTickets } from './train-orders.model';

class TrainOrdersApiClass {
  public getTickets(orderId: string, railSupplier: string): Promise<AxiosResponse<TrainOrdersTickets[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/orders/{orderId}/references?railSupplier={railSupplier}',
      method: RequestMethod.GET
    }, {
      orderId, railSupplier
    }, {});
  }

  public getTicketsExist(orderId: string, railSupplier: string) {
    return http.execute({
      path: settings.apiRailEngine + '/api/orders/{orderId}/tickets?railSupplier={railSupplier}',
      method: RequestMethod.HEAD
    }, {
      orderId, railSupplier
    }, {});
  }

  public getProviderBookingDetails(providerReferenceId: string, railSupplier: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/booking/{railSupplier}/{providerReferenceId}/status',
      method: RequestMethod.GET
    }, {
      providerReferenceId, railSupplier
    }, {});
  }

  public getProviderRawBookingDetails(providerReferenceId: string, railSupplier: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/booking/{railSupplier}/{providerReferenceId}/raw',
      method: RequestMethod.GET
    }, {
      providerReferenceId, railSupplier
    }, {});
  }
}
 
 export const TrainOrdersApi: TrainOrdersApiClass = new TrainOrdersApiClass();