export const RequestTypeList = [
  {
    value: 'Unknown',
    name: 'Unknown',
  },
  {
    value: 'NewBooking',
    name: 'common.new-booking',
  },
  {
    value: 'Modification',
    name: 'common.modification',
  },
  {
    value: 'Cancellation',
    name: 'common.cancellation',
  },
  {
    value: 'AdditionalService',
    name: 'common.additional-service',
  },
];
