










































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Emit, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, helpers, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';

import { router } from '@/router';
import AccountStore from '@/store/account.store';
import EventHandler from '@/services/event-handler';
import { SearchMission, MissionState } from '@/api/expense/expense.model';
import formatDateMoment from '@/filters/format-data-moment.filter';
import { ExpenseApi } from '@/api/expense/expense.api';
import { translate } from '@/i18n';
import ExpenseStore from '../expense.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { CityCountryModel } from '@/api/dictionary/dictionary.model';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import SearchStore from '@/modules/search/search.store';
import { AirSearchStateParams } from '@/modules/search/air/air-search.params';
import { HotelSearchStateParams } from '@/modules/search/hotel/hotel-search.params';
import { TrainSearchStateParams } from '@/modules/search/train/train-search.params';
import { CarSearchStateParams } from '@/modules/search/car/car-search.params';
import TripsIncludedMissionTable from './TripsIncludedMissionTable.vue';
import ReportedExpenseTable from './ReportedExpenseTable.vue';
import { Permission } from '@/const/permission.enum';
import _ from 'lodash';

const priceRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^(\d{1,10})(\.\d{1,2})?$/.test(value);
};

const validationResultIcon = {
  Risk: 'notification_important',
  Violation: 'block',
  Warning: 'warning',
  Information: 'info',
};

@Component({
  components: {
    TripsIncludedMissionTable,
    ReportedExpenseTable,
  },
})
export default class AddMission extends Vue {
  ExpenseForm: SearchMission = new SearchMission({});
  cleanSearchForm: any = null;

  activeTab: string = 'mission-data';
  missionTabs = [
    {
      label: translate('add-mission.mission-data'),
      value: 'mission-data',
    },
    {
      label: translate('expense-add.comments'),
      value: 'comments',
    },
    {
      label: translate('expense-add.attachments'),
      value: 'attachments',
    },
    {
      label: translate('expense-add.history'),
      value: 'history',
    }
  ];
  activeRelatedTab: string = 'trips';
  relatedTabs = [
    {
      label: translate('add-mission.trips'),
      value: 'trips',
    },
    {
      label: translate('add-mission.reported-expenses'),
      value: 'reported-expenses',
    },
  ];
  $v;
  formPending: boolean = false;
  serverErrors: any[] = [];
  editMode: boolean = false;
  missionId: string | null = null;
  deleteMissionPopup: boolean = false;
  missionRemovalErrorMessage: string | null = null;
  showAssociatedExpensesPopup: boolean = false;
  showAssociatedExpensesErrorMessage: any[] | null = null;
  addExpenseMode: boolean = false;
  locationOptions: CityCountryModel[] = [];
  isLoading: boolean = false;
  loading: boolean = false;
  showMissionTimePopup: boolean = false;
  showMissionStartTimePopup: boolean = false;
  showMissionEndTimePopup: boolean = false;
  missionStartTimePopup: any = null;
  missionEndTimePopup: any = null;
  showCancellationMissionPopup: boolean = false;
  showCancellationExpensePopup: boolean = false;
  showDestinationError: boolean = false;
  destinationLocationError: any = null;
  showExpensesNotCompletedPopup: boolean = false;



  get tabNames() {
    return this.missionTabs;
  }

  get bottomTabs() {
    return this.relatedTabs;
  }

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get currencyOptions() {
    return ExpenseStore.currencyOptions;
  }

  get chackEndDate() {
    if (this.ExpenseForm!.missionStart) {
      return new Date(this.ExpenseForm!.missionStart);
    }
  }

  get chackStartDate() {
    if (this.ExpenseForm!.missionEnd) {
      return new Date(this.ExpenseForm!.missionEnd);
    }
  }

  get missionReporter() {
    return ExpenseStore.missionReporter;
  }

  get companyId() {
    return ExpenseStore.companyId;
  }

  get rootCompanyId() {
    return ExpenseStore.rootCompanyId;
  }

  get showConfigurationError() {
    return ExpenseStore.showConfigurationError;
  }

  get displayLocationOptions() {
    return this.locationOptions.map(location => ({ ...location, uniqId: location.cityId + location.countryCode }));
  }

  get showMissionToApprovalPopPup() {
    return ExpenseStore.showMissionToApprovalPopPup;
  }

  get disabledForm() {
    return this.ExpenseForm.state !== MissionState.Draft && this.ExpenseForm.state !== MissionState.AmendmentNeeded && this.ExpenseForm.state !== MissionState.ReportAmendmentNeeded;
  }

  get errMessages() {
    return ExpenseStore.errMessages;
  }

  get showSaveButton() {
    return this.ExpenseForm.state === MissionState.Draft;
  }

  get showSubmitExpenseReport() {
    return this.ExpenseForm.state === MissionState.MissionApproved;
  }

  get showCancelButton() {
    return this.ExpenseForm.state === MissionState.MissionRequestSubmitted || this.ExpenseForm.state === MissionState.MissionApproved || 
      this.ExpenseForm.state === MissionState.MissionRequestRejected || this.ExpenseForm.state === MissionState.AmendmentNeeded;
  }

  get showCancelExpenseButton() {
    return this.ExpenseForm.state === MissionState.ExpenseReportSubmitted || this.ExpenseForm.state === MissionState.ExpenseReportRejected ||  this.ExpenseForm.state === MissionState.ReportAmendmentNeeded;
  }

  get showApprovalAgainButton() {
    return this.ExpenseForm.state === MissionState.AmendmentNeeded;
  }

  get showSubmitExpenseReportAgainButton() {
    return this.ExpenseForm.state === MissionState.ReportAmendmentNeeded;
  }

  get hideIfMissionCancelledState() {
    return this.ExpenseForm.state === MissionState.MissionCancelled;
  }

  get hideBookButton() {
    return this.ExpenseForm.state === MissionState.ExpenseReportSubmitted || this.ExpenseForm.state === MissionState.ExpenseReportRejected ||  
    this.ExpenseForm.state === MissionState.ExpenseReportApproved || this.ExpenseForm.state === MissionState.ExpenseReportInApproval || this.ExpenseForm.state === MissionState.ExpenseReportCancelled;
  }

  get showAddExpenseButton() {
    return this.ExpenseForm.state === MissionState.ReportAmendmentNeeded || this.ExpenseForm.state === MissionState.MissionApproved;
  }

  get validationResultsList() {
    return ExpenseStore.validationResults;
  }

  get submitExpenseReportResult() {
    return ExpenseStore.submitExpenseReportResult;
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }



  backFromExpensesNotCompletedPopup() {
    this.showExpensesNotCompletedPopup = false;
  }

  destinationMinDate(index) {
    if (index > 0 && this.ExpenseForm.destinations && this.ExpenseForm.destinations.length > 0) {
      return this.ExpenseForm.destinations[index - 1].endDate;
    }
  }

  @Validation()
  validationObject() {
    return {
      ExpenseForm: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        description: {
          maxLength: maxLength(1024),
          required: requiredIf(() => {
            return this.ExpenseForm.state !== MissionState.Draft;
          }),
        },
        missionBudget: {
          amount: {
            priceRegex,
            required,
          },
          currency: {
            required,
          },
        },
        missionStart: {
          required: requiredIf(() => {
            if (this.ExpenseForm && !this.ExpenseForm.missionStart) {
              return this.ExpenseForm!.missionEnd;
            }
          }),
        },
        missionEnd: {
          required: requiredIf(() => {
            if (this.ExpenseForm && !this.ExpenseForm.missionEnd) {
              return this.ExpenseForm!.missionStart;
            }
          }),
        },
        destinations: {
          $each: {
            location: {
              required
            },
            startDate: {
              required
            },
            endDate: {
              required
            }
          }
        }
      },
    };
  }

  close() {
    router.push({
      name: 'my-missions',
    });
  }

  modifyExpense(item) {
    router.push({
      name: 'modify-expense',
      params: {
        expenseId: item.id,
      },
    });
  }

  validationResultClasses(validationResult) {
    return {
      'error': validationResult.severity === 'Violation',
      'warning': validationResult.severity === 'Warning',
      'info-level': validationResult.severity === 'Information',
      'risk': validationResult.severity === 'Risk',
    };
  }

  validationResultIcons(validationResult) {
    return validationResultIcon[validationResult.severity];
  }

  stateClasses(state) {
    return {
      'counter--empty': MissionState.Draft === state,
      'counter--yellow': MissionState.MissionRequestSubmitted === state,
      'counter--orange': MissionState.MissionRequestInApproval === state,
      'counter--strong-grey': MissionState.MissionCancelled === state || MissionState.ExpenseReportCancelled === state,
      'counter--light-green': MissionState.MissionApproved === state,
      'counter--red-grey': MissionState.AmendmentNeeded === state || MissionState.ReportAmendmentNeeded === state,
      'counter--strong-red': MissionState.MissionRequestRejected === state || MissionState.ExpenseReportRejected === state,
      'counter--green': MissionState.ExpenseReportApproved === state,
      'counter--orange-green': MissionState.ExpenseReportInApproval === state,
      'counter--yellow-green': MissionState.ExpenseReportSubmitted === state,
    };
  }

  async initView() {
    this.loading = true;
    await ExpenseStore.getCurrencies();
    await ExpenseStore.getDefaultExpensePolicy(Permission.WriteMissionDefinition);
    this.missionId = this.$route.params.missionId;

    if (this.ExpenseForm.destinations.length === 0) {
      let startDate = null;

      if (this.ExpenseForm.missionStart) {
        startDate = this.ExpenseForm.missionStart;
      }

      this.ExpenseForm.destinations.push(
        {
          location: null,
          startDate: startDate,
          endDate: null,
        });
    }
    
    if (this.missionId) {
      this.editMode = true;
      const response = await ExpenseApi.getMissionItem(this.missionId);
      this.ExpenseForm = new SearchMission(response.data);

      if (this.ExpenseForm.state !== MissionState.Draft && this.ExpenseForm.state !== MissionState.MissionCancelled) {
        const params = {
          missionId: this.missionId,
          expenseId: undefined,
        };
        await ExpenseStore.getValidationsResults(params);
      }

      if (this.ExpenseForm.reporterId) {
        await ExpenseStore.getUser(this.ExpenseForm.reporterId);
      }
      if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length) {
       
        for (let i = 0; i < this.ExpenseForm.destinations.length; i++) { 
          this.ExpenseForm.destinations[i].startDate = new Date(this.ExpenseForm.destinations[i].startDate);
          this.ExpenseForm.destinations[i].endDate = new Date(this.ExpenseForm.destinations[i].endDate);
        }
      }
      this.cleanSearchForm = _.cloneDeep(this.ExpenseForm);
    }
    this.loading = false;
  }

  saveMission() {
    this.$v.ExpenseForm.$touch();
    if (this.$v.ExpenseForm.$pending || this.$v.ExpenseForm.$error) {
      return;
    }
    
    if (this.ExpenseForm.destinations.length === 0) {
      return;
    }

    this.showDestinationError = false;
    this.destinationLocationError = null;

    if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length > 1) {
      for (let i = 1; i < this.ExpenseForm.destinations.length; i++) { 
        const currentDestination = this.ExpenseForm.destinations[i];
        const previousDestination = this.ExpenseForm.destinations[i - 1];
        const previousDestinationStartDate = moment(previousDestination.startDate).format('YYYY-MM-DD');
        const previousDestinationEndDate = moment(previousDestination.endDate).format('YYYY-MM-DD');
        const currentDestinationStartDate = moment(currentDestination.startDate).format('YYYY-MM-DD');
        const currentDestinationEndDate = moment(currentDestination.endDate).format('YYYY-MM-DD');

        if ((moment(currentDestinationStartDate).isBefore(previousDestinationEndDate) && moment(currentDestinationEndDate).isSameOrAfter(previousDestinationEndDate))
        || (moment(currentDestinationEndDate).isSameOrBefore(previousDestinationEndDate) && moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationStartDate).isBefore(currentDestinationEndDate))
        || (moment(currentDestinationStartDate).isSameOrAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isBefore(previousDestinationEndDate))
        || (moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isSameOrBefore(previousDestinationEndDate) && moment(currentDestinationStartDate).isBefore(currentDestinationEndDate))
        || (moment(previousDestinationEndDate).isSame(previousDestinationStartDate) && moment(currentDestinationStartDate).isBefore(previousDestinationStartDate) && moment(currentDestinationEndDate).isAfter(previousDestinationEndDate))
        || (moment(currentDestinationEndDate).isSame(currentDestinationStartDate) && moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isBefore(previousDestinationEndDate))) {
          this.showDestinationError = true;
          this.destinationLocationError = this.ExpenseForm.destinations[i].location;
          return;
        }
      }
    }

    let showPopup = false;
    let locationStartDate = moment(this.ExpenseForm.destinations[0].startDate).format('YYYY-MM-DD');
    let locationEndDate = moment(this.ExpenseForm.destinations[this.ExpenseForm.destinations.length - 1].endDate).format('YYYY-MM-DD');
    let missionStartDate = moment(this.ExpenseForm.missionStart).format('YYYY-MM-DD');
    let missionEndDate = moment(this.ExpenseForm.missionEnd).format('YYYY-MM-DD');
      
    if (this.ExpenseForm.missionEnd === null 
    || moment(locationEndDate).isAfter(missionEndDate)) {
      showPopup = true;
      this.showMissionEndTimePopup = true;
      this.missionEndTimePopup = formatDateMoment(this.ExpenseForm.destinations[this.ExpenseForm.destinations.length - 1].endDate);
    }

    if (this.ExpenseForm.missionStart === null 
    || moment(locationStartDate).isBefore(missionStartDate)) {
      showPopup = true;
      this.showMissionStartTimePopup = true;
      this.missionStartTimePopup = formatDateMoment(this.ExpenseForm.destinations[0].startDate);
    }

    if (showPopup) {
      this.showMissionTimePopup = true;
    } else if (this.ExpenseForm.state === MissionState.MissionApproved || this.ExpenseForm.state === MissionState.ReportAmendmentNeeded) {
      this.submitExpenseReport();
    } else {
      this.checkIfShowMissionToApprovalPopPup();
    }
  }

  async submitExpenseReport() {
    this.formPending = true;
    if (this.ExpenseForm.state !== MissionState.MissionApproved) {
      await this.submit();
    }
    await ExpenseStore.submitExpenseReport(this.$route.params.missionId);
    this.formPending = false;
    if (this.submitExpenseReportResult && this.submitExpenseReportResult.missionId === this.$route.params.missionId && this.submitExpenseReportResult.expensesNotCompleted.length > 0) {
      this.showExpensesNotCompletedPopup = true;
      return;
    } else if (this.submitExpenseReportResult && this.submitExpenseReportResult.missionId === this.$route.params.missionId && this.submitExpenseReportResult.expensesNotCompleted.length === 0) {
      if (this.showSubmitExpenseReportAgainButton) {
        await this.updateValidationResults(this.validationResultsList);
      }
      this.close();
    }
  }

  changeDates() {
    if (this.showMissionStartTimePopup) {
      this.ExpenseForm.missionStart = this.ExpenseForm.destinations[0].startDate;
    }
    if (this.showMissionEndTimePopup) {
      this.ExpenseForm.missionEnd = this.ExpenseForm.destinations[this.ExpenseForm.destinations.length - 1].endDate;
    }
    this.showMissionTimePopup = false;
    this.checkIfShowMissionToApprovalPopPup();
  }

  async submit() {
    this.formPending = true;
    this.missionId = this.$route.params.missionId;
    if (this.ExpenseForm.missionStart !== null) {
      this.ExpenseForm.missionStart = formatDateMoment(this.ExpenseForm.missionStart);
    }
    if (this.ExpenseForm.missionEnd !== null) {
      this.ExpenseForm.missionEnd = formatDateMoment(this.ExpenseForm.missionEnd);
    }
     if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length) {
       for (let i = 0; i < this.ExpenseForm.destinations.length; i++) { 
         this.ExpenseForm.destinations[i].startDate = formatDateMoment(this.ExpenseForm.destinations[i].startDate);
         this.ExpenseForm.destinations[i].endDate = formatDateMoment(this.ExpenseForm.destinations[i].endDate);
       }
   }
    if (this.missionId) {
      try {
        const response = await ExpenseApi.updateMissionItem(this.missionId, this.ExpenseForm);
        let obj = {
          type: translate('common.success'),
          title: translate('add-mission.update-title'),
          message: translate('add-mission.update-message')
        };
        EventHandler.$emit('show-toast', obj);
        
        if (this.ExpenseForm.state !== MissionState.ReportAmendmentNeeded && this.ExpenseForm.state !== MissionState.MissionApproved) {
          router.push({
            name: 'my-missions'
          });
        }
        
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
        if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length) {
          for (let i = 0; i < this.ExpenseForm.destinations.length; i++) { 
            this.ExpenseForm.destinations[i].startDate = new Date(this.ExpenseForm.destinations[i].startDate);
            this.ExpenseForm.destinations[i].endDate = new Date(this.ExpenseForm.destinations[i].endDate);
          }
        }
        this.ExpenseForm.missionStart = new Date(this.ExpenseForm.missionStart);
        this.ExpenseForm.missionEnd = new Date(this.ExpenseForm.missionEnd);
      } finally {
        this.formPending = false;
      }
    } else {
      this.ExpenseForm.reporterId = this.currentProfileId;
      this.ExpenseForm.policyId = this.policyId;
      this.ExpenseForm.companyId = this.rootCompanyId;
      this.ExpenseForm.businessUnitId = this.companyId;

      try {
        const response = await ExpenseApi.addMission(this.ExpenseForm);

        if (response) {
          let obj = {
            type: translate('common.success'),
            title: translate('add-mission.add-title'),
            message: translate('add-mission.add-mission-message')
          };
          EventHandler.$emit('show-toast', obj);

          router.push({
            name: 'my-missions'
          });
        }
     
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
        if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length) {
          for (let i = 0; i < this.ExpenseForm.destinations.length; i++) { 
            this.ExpenseForm.destinations[i].startDate = new Date(this.ExpenseForm.destinations[i].startDate);
            this.ExpenseForm.destinations[i].endDate = new Date(this.ExpenseForm.destinations[i].endDate);
          }
        }
        this.ExpenseForm.missionStart = new Date(this.ExpenseForm.missionStart);
        this.ExpenseForm.missionEnd = new Date(this.ExpenseForm.missionEnd);
      } finally {
        this.formPending = false;
      }
    }
  }

  async updateValidationResults(value) {
    try {
      if (value && value.length) {
        let a: any[] | any = value;
        let params = { 
          resolutionExplanation: 'Resolved',
        };
        if (!(a instanceof Array)) {
          a = [a];
        }
        await Promise.all(
          a.map(validationResult => ExpenseApi.updateValidationResults(validationResult.id, params))
        );
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
    }
  }

  initRemoveMission() {
    this.missionRemovalErrorMessage = null;
    this.deleteMissionPopup = true;
  }

  checkIfShowMissionToApprovalPopPup() {
    if ((this.ExpenseForm.state === MissionState.Draft || this.ExpenseForm.state === MissionState.AmendmentNeeded) && this.ExpenseForm.missionBudget.amount !== '' && this.ExpenseForm.description && this.ExpenseForm.destinations.length > 0) {
      ExpenseStore.setShowMissionToApprovalPopPup(true);
    } else {
      this.submit();
    }
  }

  closeMissionToApprovalPopPup() {
    ExpenseStore.setShowMissionToApprovalPopPup(false);
  }

  onlySaveMission() {
    this.submit();
    ExpenseStore.setShowMissionToApprovalPopPup(false);
  }

  async sendMissionToApproval() {
    if (this.showApprovalAgainButton) {
      await this.updateValidationResults(this.validationResultsList);
    }
    this.ExpenseForm.state = MissionState.MissionRequestSubmitted;
    this.submit();
    ExpenseStore.setShowMissionToApprovalPopPup(false);
    this.close();
  }

   async sendExpenseReportToApproval() {
    this.ExpenseForm.state = MissionState.ExpenseReportSubmitted;
    this.submit();
    ExpenseStore.setShowMissionToApprovalPopPup(false);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })

  async findCityCountry(query: string) {
    if (query && query.length > 2) {
      try {
        const result = await DictionaryApi.findCityCountry(query, LanguageCode.EN);
        this.locationOptions = result.data.filter(item => item.type === 'City');
      } catch (error) {
        this.serverErrors = this.$handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.isLoading = false;
      this.locationOptions = [];
    }
  }

  cancelMission() {
    this.showCancellationMissionPopup = true;
  }

  backToMission() {
    this.showCancellationMissionPopup = false;
  }

  sendCancellationMission() {
    this.ExpenseForm.state = MissionState.MissionCancelled;
    this.submit();
    this.showCancellationMissionPopup = false;
  }

  cancelExpenseButton() {
    this.showCancellationExpensePopup = true;
  }

  sendCancellationExpenseReport() {
    this.ExpenseForm.state = MissionState.ExpenseReportCancelled;
    this.submit();
    this.showCancellationExpensePopup = false;
  }

  backToMissionFromCancellationExpense() {
    this.showCancellationExpensePopup = false;
  }

  async removeMission() {
    this.missionId = this.$route.params.missionId;
    this.formPending = true;
    
    if (this.missionId) {
      try {
        const response = await ExpenseApi.removeMissionItem(this.missionId);
        this.deleteMissionPopup = false;
        if (response) {
          let obj = {
            type: translate('common.success'),
            title: translate('my-missions.remove-mission-title'),
            message: translate('my-missions.remove-mission-message')
          };
          EventHandler.$emit('show-toast', obj);
          router.push({
            name: 'my-missions'
          });
        }
      } catch (error) {
        if (!error.response.data.error.details) {
          this.missionRemovalErrorMessage = error.response.data.error.message;
        }
        if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length) {
          for (let i = 0; i < this.ExpenseForm.destinations.length; i++) { 
            this.ExpenseForm.destinations[i].startDate = new Date(this.ExpenseForm.destinations[i].startDate);
            this.ExpenseForm.destinations[i].endDate = new Date(this.ExpenseForm.destinations[i].endDate);
          }
        }
        this.ExpenseForm.missionStart = new Date(this.ExpenseForm.missionStart);
        this.ExpenseForm.missionEnd = new Date(this.ExpenseForm.missionEnd);
      }  finally {
        this.formPending = false;
      }
    }
  }

  initShowAssociatedExpenses() {
    if (this.$v.ExpenseForm.$invalid) {
       this.$v.ExpenseForm.$touch();
    }

    if (this.$v.ExpenseForm.$pending || this.$v.ExpenseForm.$error) {
      return;
    }

    if (this.ExpenseForm.destinations.length === 0) {
      return;
    }

    this.showDestinationError = false;
    this.destinationLocationError = null;

    if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length > 1) {
      for (let i = 1; i < this.ExpenseForm.destinations.length; i++) { 
        const currentDestination = this.ExpenseForm.destinations[i];
        const previousDestination = this.ExpenseForm.destinations[i - 1];
        const previousDestinationStartDate = moment(previousDestination.startDate).format('YYYY-MM-DD');
        const previousDestinationEndDate = moment(previousDestination.endDate).format('YYYY-MM-DD');
        const currentDestinationStartDate = moment(currentDestination.startDate).format('YYYY-MM-DD');
        const currentDestinationEndDate = moment(currentDestination.endDate).format('YYYY-MM-DD');

        if ((moment(currentDestinationStartDate).isBefore(previousDestinationEndDate) && moment(currentDestinationEndDate).isSameOrAfter(previousDestinationEndDate))
        || (moment(currentDestinationEndDate).isSameOrBefore(previousDestinationEndDate) && moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationStartDate).isBefore(currentDestinationEndDate))
        || (moment(currentDestinationStartDate).isSameOrAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isBefore(previousDestinationEndDate))
        || (moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isSameOrBefore(previousDestinationEndDate) && moment(currentDestinationStartDate).isBefore(currentDestinationEndDate))
        || (moment(previousDestinationEndDate).isSame(previousDestinationStartDate) && moment(currentDestinationStartDate).isBefore(previousDestinationStartDate) && moment(currentDestinationEndDate).isAfter(previousDestinationEndDate))
        || (moment(currentDestinationEndDate).isSame(currentDestinationStartDate) && moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isBefore(previousDestinationEndDate))) {
          this.showDestinationError = true;
          this.destinationLocationError = this.ExpenseForm.destinations[i].location;
          return;
        }
      }
    }

    if (this.$v.ExpenseForm.$anyDirty || this.$v.ExpenseForm.$dirty || this.cleanSearchForm.destinations.length !== this.ExpenseForm.destinations.length) {
      this.showAssociatedExpensesErrorMessage = null;
      this.showAssociatedExpensesPopup = true;
    } else {
      this.goToAssociatedExpenses();
    }
  }

  initAddExpense() {
    if (this.$v.ExpenseForm.$invalid) {
       this.$v.ExpenseForm.$touch();
    }

    if (this.$v.ExpenseForm.$pending || this.$v.ExpenseForm.$error) {
      return;
    }

    if (this.ExpenseForm.destinations.length === 0) {
      return;
    }

    this.showDestinationError = false;
    this.destinationLocationError = null;

    if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length > 1) {
      for (let i = 1; i < this.ExpenseForm.destinations.length; i++) { 
        const currentDestination = this.ExpenseForm.destinations[i];
        const previousDestination = this.ExpenseForm.destinations[i - 1];
        const previousDestinationStartDate = moment(previousDestination.startDate).format('YYYY-MM-DD');
        const previousDestinationEndDate = moment(previousDestination.endDate).format('YYYY-MM-DD');
        const currentDestinationStartDate = moment(currentDestination.startDate).format('YYYY-MM-DD');
        const currentDestinationEndDate = moment(currentDestination.endDate).format('YYYY-MM-DD');

        if ((moment(currentDestinationStartDate).isBefore(previousDestinationEndDate) && moment(currentDestinationEndDate).isSameOrAfter(previousDestinationEndDate))
        || (moment(currentDestinationEndDate).isSameOrBefore(previousDestinationEndDate) && moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationStartDate).isBefore(currentDestinationEndDate))
        || (moment(currentDestinationStartDate).isSameOrAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isBefore(previousDestinationEndDate))
        || (moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isSameOrBefore(previousDestinationEndDate) && moment(currentDestinationStartDate).isBefore(currentDestinationEndDate))
        || (moment(previousDestinationEndDate).isSame(previousDestinationStartDate) && moment(currentDestinationStartDate).isBefore(previousDestinationStartDate) && moment(currentDestinationEndDate).isAfter(previousDestinationEndDate))
        || (moment(currentDestinationEndDate).isSame(currentDestinationStartDate) && moment(currentDestinationStartDate).isAfter(previousDestinationStartDate) && moment(currentDestinationEndDate).isBefore(previousDestinationEndDate))) {
          this.showDestinationError = true;
          this.destinationLocationError = this.ExpenseForm.destinations[i].location;
          return;
        }
      }
    }

    this.addExpenseMode = true;
    if (this.$v.ExpenseForm.$anyDirty || this.$v.ExpenseForm.$dirty || this.cleanSearchForm.destinations.length !== this.ExpenseForm.destinations.length) {
      this.showAssociatedExpensesErrorMessage = null;
      this.showAssociatedExpensesPopup = true;
    } else {
      this.addExpense();
    }
  }

  goToAssociatedExpenses() {
    this.missionId = this.$route.params.missionId;
    router.push({
      name: 'expense-list-filtered',
      params: {
        missionId: this.missionId,
      },
    });
    this.showAssociatedExpensesPopup = false;
  }

  async saveAndGoToAssociatedExpenses() {
    this.showAssociatedExpensesErrorMessage = null;
    this.formPending = true;
    this.missionId = this.$route.params.missionId;

    if (this.ExpenseForm.missionStart !== null) {
      this.ExpenseForm.missionStart = formatDateMoment(this.ExpenseForm.missionStart);
    }
    if (this.ExpenseForm.missionEnd !== null) {
      this.ExpenseForm.missionEnd = formatDateMoment(this.ExpenseForm.missionEnd);
    }

    try {
      const response = await ExpenseApi.updateMissionItem(this.missionId, this.ExpenseForm);

      let obj = {
        type: translate('common.success'),
        title: translate('add-mission.update-title'),
        message: translate('add-mission.update-message')
      };
      EventHandler.$emit('show-toast', obj);
      if (this.addExpenseMode) {
        this.addExpense();
      } else {
        this.goToAssociatedExpenses();
      }   
    } catch (error) {
      this.showAssociatedExpensesErrorMessage = this.$handleErrors(error, true);
      if (this.ExpenseForm.destinations && this.ExpenseForm.destinations.length) {
        for (let i = 0; i < this.ExpenseForm.destinations.length; i++) { 
          this.ExpenseForm.destinations[i].startDate = new Date(this.ExpenseForm.destinations[i].startDate);
          this.ExpenseForm.destinations[i].endDate = new Date(this.ExpenseForm.destinations[i].endDate);
        }
      }
      this.ExpenseForm.missionStart = new Date(this.ExpenseForm.missionStart);
      this.ExpenseForm.missionEnd = new Date(this.ExpenseForm.missionEnd);
    } finally {
      this.formPending = false;
    }
  }

  onSelectLocation(index) {
    const selection = this.$refs['missionLocation' + index] as Vue;
    const selectionEl = selection[0].$el.getElementsByTagName('input')[0] as HTMLElement;

    if (selectionEl) {
      setTimeout(() => {
        selectionEl.blur();
      });
    }
  }

  addExpense() {
    this.missionId = this.$route.params.missionId;
    router.push({
      name: 'add-expense',
      params: {
        missionId: this.missionId,
      },
    });
    this.showAssociatedExpensesPopup = false;
  }

  addLocation() {
    if (this.$v.ExpenseForm.destinations.$invalid) {
      this.$v.ExpenseForm.destinations.$touch();
      return;
    }
    let startDate = null;
    let endDate = null;

    if (this.ExpenseForm.destinations.length > 0) {
      startDate = this.ExpenseForm.destinations[this.ExpenseForm.destinations.length - 1].endDate;
    } else if (this.ExpenseForm.destinations.length === 0 && this.ExpenseForm.missionStart) {
      startDate = this.ExpenseForm.missionStart;
    }

    this.ExpenseForm.destinations.push(
      {
        location: null,
        iataCode: '',
        countryCode: '',
        cityId: '',
        startDate: startDate,
        endDate: endDate,
        type: ''
      }
    );
  }

  removeLocation(index) {
    this.$v.ExpenseForm.destinations.$each[index].$reset();
    this.ExpenseForm.destinations.splice(index, 1);
  }

  async book(type, destination, index) {
    
    this.$v.ExpenseForm.$touch();
    if (this.$v.ExpenseForm.$pending || this.$v.ExpenseForm.$error) {
      return;
    }

    if (this.ExpenseForm.state === MissionState.Draft || this.ExpenseForm.state === MissionState.MissionRequestInApproval || this.ExpenseForm.state === MissionState.AmendmentNeeded) {
       await this.submit();
    }

    let userObj = {
        businessUnitId: this.missionReporter.companyId,
        businessUnitName: this.missionReporter.companyName,
        companyId: this.missionReporter.rootCompanyId,
        companyName: this.missionReporter.rootCompanyName,
        dateOfBirth: this.missionReporter.dateOfBirth ? moment(this.missionReporter.dateOfBirth).format('YYYY-MM-DD') : null,
        firstName: this.missionReporter.firstName,
        middleName: this.missionReporter.middleName || '',
        id: this.missionReporter.id,
        isMainTraveller: true,
        lastName: this.missionReporter.lastName
    };

    let departurePrev = null;
    if (this.ExpenseForm.destinations.length > 1 && index > 0) {
      departurePrev = this.ExpenseForm.destinations[index - 1].location;
    }
    SearchStore.updateTravellersDefaultState({travellers: [userObj]});
    SearchStore.selectNavigated(type);
    SearchStore.selectBasket('');
    let next: any = {
      name: 'search',
    };
    
    if (type === 'Flight') {
      SearchStore.updateAirDefaultState(new AirSearchStateParams({
        searchMode: this.ExpenseForm.destinations && this.ExpenseForm.destinations.length > 1 ? 'OneWay' : 'RoundTrip',
        from: departurePrev,
        to: destination.location,
        departureDate: formatDateMoment(destination.startDate),
        returnDate: formatDateMoment(destination.endDate),
      }));
    } else if (type === 'Train') {
      const locationTrain = {
        ...destination.location,
        codes: {
          Ntv: '',
        }
      };

      SearchStore.updateTrainDefaultState(new TrainSearchStateParams({
        searchMode: this.ExpenseForm.destinations && this.ExpenseForm.destinations.length > 1 ? 'OneWay' : 'RoundTrip',
        from: departurePrev,
        to: locationTrain,
        departureDate: formatDateMoment(destination.startDate),
        returnDate: formatDateMoment(destination.endDate),
      }));
    } else if (type === 'Hotel') {
      const locationHotel = {
        ...destination.location,
        identifier: destination.location.cityCode,
        displayText: destination.location.cityName + ', ' + destination.location.countryCode,
      };

      SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
        to: locationHotel,
        checkInDate: formatDateMoment(destination.startDate),
        checkOutDate: formatDateMoment(destination.endDate),
      }));
    } else if (type === 'Car') {
      let locationCar;
      const response = await DictionaryApi.findHotelToLocation(destination.location.cityName, this.languageCode);
      if (response && response.data) {
        locationCar = response.data[0];
      } else {
        locationCar = {
          ...destination.location,
          identifier: destination.location.cityCode,
          displayText: destination.location.cityName + ', ' + destination.location.countryCode,
          location: {
            lat: 0,
            lon: 0,
          }
        };
      }
      SearchStore.updateCarDefaultState(new CarSearchStateParams({
        pickupLocation: locationCar,
        returnLocation: locationCar,
        pickupDate: formatDateMoment(destination.startDate),
        returnDate: formatDateMoment(destination.endDate),
      }));
    }

    if (this.missionId) {
      next.query = {
        missionId: this.missionId,
      };
    }
    this.$router.push(next);
  }

  @Watch('$route.params.missionId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  @Watch('ExpenseForm.missionEnd')
  onChangedMissionEnd(val) {
    if (val && this.ExpenseForm && this.ExpenseForm.missionStart && new Date(val).getTime() < new Date(this.ExpenseForm.missionStart).getTime()) {
      this.ExpenseForm.missionEnd = new Date(this.ExpenseForm.missionStart);
    }
  }

  @Watch('ExpenseForm.missionStart')
  onChangedMissionStart(val) {
    if (val && this.ExpenseForm && this.ExpenseForm.missionEnd && new Date(val).getTime() > new Date(this.ExpenseForm.missionEnd).getTime()) {
      this.ExpenseForm.missionStart = new Date(this.ExpenseForm.missionEnd);
    }
  }

  beforeDestroy() {
    ExpenseStore.setValidationResults(null);
  }

}
