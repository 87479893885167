import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { OffersModel, IDetailsModel, IOfferDetails } from './offers.model';
import { IOfferChange, IRawBasketAirOffer } from '@/api/air-engine/air-search.model';

class OffersApiClass {
  public getShortOfferDetails(supplier: string, offerId: string, providerSearchId: string, searchId: string): Promise<AxiosResponse<OffersModel>> {
    let url = '/api/air/offers/{offerId}/short-details?supplier={supplier}';
    if (providerSearchId) {
      url += '&providerSearchId={providerSearchId}';
    }
    if (searchId) {
      url += '&searchId={searchId}';
    }

    return http.execute({
      path: settings.apiAirEngine + url,
      method: RequestMethod.GET,
    }, {
      offerId,
      supplier,
      providerSearchId,
      searchId,
    }, {});
  }

  public getBasketOfferConditions(offerId): Promise<AxiosResponse<IOfferDetails>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{offerId}/details',
      method: RequestMethod.GET,
    }, { offerId }, {});
  }

  public getBasketOfferConditionsV2(offerId): Promise<AxiosResponse<IDetailsModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{offerId}/details/v2',
      method: RequestMethod.GET,
    }, { offerId }, {});
  }

  public getOfferDetails(supplier: string, offerId: string, providerSearchId: string, searchId: string): Promise<AxiosResponse<IOfferDetails>> {
    let url = '/api/air/offers/{offerId}/details?supplier={supplier}&providerSearchId={providerSearchId}';
    if (searchId) {
      url += '&searchId={searchId}';
    }
    return http.execute({
      path: settings.apiAirEngine + url,
      method: RequestMethod.GET,
    }, {
      offerId,
      supplier,
      providerSearchId,
      searchId,
    }, {});
  }

  public getOfferDetailsV2(supplier: string, offerId: string, providerSearchId: string, searchId: string): Promise<AxiosResponse<IDetailsModel>> {
    let url = '/api/air/offers/{offerId}/details/v2?supplier={supplier}&providerSearchId={providerSearchId}';
    if (searchId) {
      url += '&searchId={searchId}';
    }
    return http.execute({
      path: settings.apiAirEngine + url,
      method: RequestMethod.GET,
    }, {
      offerId,
      supplier,
      providerSearchId,
      searchId,
    }, {});
  }

  public saveDocumentsAsFoid(recommendationId, documents: any[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{recommendationId}/foid',
      method: RequestMethod.PUT,
    }, { recommendationId }, documents, true);
  }

  public retrievesExchangeOfferPrice(recommendationId: string, params: any): any {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{recommendationId}/exchange-details',
      method: RequestMethod.POST,
    }, { recommendationId }, params);
  }

  public exchangeCandidate(recommendationId: string, sessionId: string): any {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{recommendationId}/exchange-candidate/{sessionId}',
      method: RequestMethod.POST,
    }, { recommendationId, sessionId }, {});
  }

  public getOffer(recommendationId): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{recommendationId}',
      method: RequestMethod.GET,
    }, { recommendationId }, {});
  }

  public getLufthansaBookingDetails(recommendationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/lufthansa/{recommendationId}/status',
      method: RequestMethod.GET,
    }, { recommendationId }, {}, true);
  }

  public getAirFranceKlmBookingDetails(recommendationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/airfranceklm/{recommendationId}/status',
      method: RequestMethod.GET,
    }, { recommendationId }, {}, true);
  }

  public getProviderBookingDetails(recommendationId: string, provider: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/{provider}/{recommendationId}/status',
      method: RequestMethod.GET,
    }, { recommendationId, provider }, {}, true);
  }

  public getProviderRawBookingDetails(recommendationId: string, provider: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/{provider}/{recommendationId}/status/raw',
      method: RequestMethod.GET,
    }, { recommendationId, provider }, {}, true);
  }

  public deleteLufthansaOrderItems(recommendationId: string, orderItemIds: string[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/booking/lufthansa/{recommendationId}/delete-order-items',
      method: RequestMethod.DELETE,
    }, { recommendationId }, orderItemIds, true);
  }

  public getLufthansaOfferBooking(offerId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/lufthansa/offers/{offerId}/booking',
      method: RequestMethod.GET,
    }, { offerId }, {}, true);
  }

  public getSabreOfferBooking(offerId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/sabre/offers/{offerId}/booking',
      method: RequestMethod.GET,
    }, { offerId }, {}, true);
  }

  public getAmadeusOfferBooking(offerId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/amadeus/offers/{offerId}/booking',
      method: RequestMethod.GET,
    }, { offerId }, {}, true);
  }

  public getApisValidationRules(offers: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/apis-validation/rules?' + offers,
      method: RequestMethod.GET,
    }, {}, {}, true);
  }

  public getOffersLatestCompare(supplier: string, offerId: string): Promise<AxiosResponse<{
    currentOffer: IRawBasketAirOffer,
    previousOffer: IRawBasketAirOffer,
    offerChanges: IOfferChange[],
  }>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{supplier}/offers/{offerId}/latest-compare',
      method: RequestMethod.GET,
    }, { supplier, offerId }, {});
  }
}

export const OffersApi: OffersApiClass = new OffersApiClass();
