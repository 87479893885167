















































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { AirLocationModel} from '@/api/home/home.model';

@Component
export default class UiAirAutocompleteTemplate extends Vue {
  @Prop() props!: any;
  @Prop() options!: AirLocationModel[];


  get showAsCity() {
    if (this.options && this.props) {
      let index = this.options.indexOf(this.props.option);

      if (0 < index) {
        return this.options[index - 1].type !== 'CityAirport';
      }

      return false;
    }

    return false;
  }

  get elementIcon() {
    if (this.props.option.isAirport) {
      return 'local_airport';
    }

    return 'train';
  }
}

