











import { Vue, Component, Watch } from 'vue-property-decorator';
import AccountStore from '@/store/account.store';
import LayoutStore from '@/modules/layout/layout.store';

@Component({})
export default class AppFooter extends Vue {
  get footerText() {
    return AccountStore.footerText;
  }

  get isLayoutFullscreen() {
    return LayoutStore.isLayoutFullscreen;
  }
}

