















































































import { Vue, Component, Watch } from 'vue-property-decorator';

import consts from '@/const/table.const';
import Data from '../data.json';

@Component({})
export default class Table1 extends Vue {
  perPageOptions = consts.perPageOptions;
  perPage = consts.defaultPerPage;
  currentPage = 1;
  items = []; // Data;
  fields = {
    name: {
      sortable: true,
    },
    age: {
      sortable: true,
    },
    gender: {
      sortable: true,
    },
    eyeColor: {
      sortable: true,
    },
    company: {
      sortable: true,
    },
    email: {
      sortable: true,
    },
    phone: {
      sortable: true,
    },
    details: {
      label: '',
    },
  };
  
  get count() {
    return this.items.length;
  }

  loadData() {
    this.items = Data;
  }
}

