import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  TrainCancellationsMessage,
  ResponseTrainCancellations,
} from './train-cancellations.model';

class TrainCancellationsApiClass {
  public initTrainCancellations(params: TrainCancellationsMessage): Promise<AxiosResponse<ResponseTrainCancellations>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/cancellations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public confirmTrainCancellations(cancellationId: string) {
    return http.execute({
      path: settings.apiRailEngine + '/api/cancellations/{cancellationId}/confirm',
      method: RequestMethod.POST,
    }, { cancellationId }, {});
  }

  public abortTrainCancellations(cancellationId: string) {
    return http.execute({
      path: settings.apiRailEngine + '/api/cancellations/{cancellationId}/abort',
      method: RequestMethod.POST,
    }, { cancellationId }, {});
  }

  public getCancellationOptions(orderId: string, railSupplier: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/orders/{orderId}/cancellation-options?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { orderId, railSupplier }, {});
  }
}

export const TrainCancellationsApi: TrainCancellationsApiClass = new TrainCancellationsApiClass();