import ThemeConfiguration from '@/modules/settings/theme/ThemeConfiguration.vue';

export default {
  path: ':configurationId?/theme-configuration',
  name: 'theme-configuration',
  component: ThemeConfiguration,
  displayName: 'Company theme',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyTheme',
    parentName: 'theme-configurations',
    depthCategory: 'theme-configurations',
    depth: 2,
  },
};
