import SearchTravellersIntro from '@/modules/search/SearchTravellersIntro.vue';
import Home from '@/modules/home/Home.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/search-travellers-list',
  name: 'searchTravellersList',
  components: {
    default: Home,
    intro: SearchTravellersIntro,
  },
  meta: {
    introClass: 'intro-choose-travellers',
    permission: 'SelectTravellersForSearch',
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      },
    }
  }
};
