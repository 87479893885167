import ExpenseList from '@/modules/expense/my-expenses/ExpenseList.vue';

export default {
  path: '',
  name: 'expense-list',
  component: ExpenseList,
  displayName: 'My expenses',
  meta: {
    permission: 'Client'
  }
};