import { render, staticRenderFns } from "./HeaderTransition.vue?vue&type=template&id=3f71c740"
import script from "./HeaderTransition.vue?vue&type=script&lang=ts"
export * from "./HeaderTransition.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports