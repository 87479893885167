


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';

@Component({
})
export default class BasketItemBusinessErrors extends Vue {
  @Prop() errors!: any[];
  
  isOpened: boolean[] = [];

  get canShow() {
    return AccountStore.HasPermission('ShowAdvancedOfferInfo');
  }

  get errorTooltip() {
    return translate('basket.error-tooltip');
  }

  showErrorInfo(index) {
    this.isOpened[index] = !this.isOpened[index];
    this.$forceUpdate();
  }

  mainLine(error) {
    return error.code + ' ' + error.message;
  }

  detailsLine(line) {
    let result = line.params.StatusText;
    if (line.params.DescText) {
      result += ' (' + line.params.DescText + ')';
    }
    if (result === undefined || result === null || result.trim() === '') {
      result = line.message;
    }

    return result;
  }

  @Watch('errors')
  onErrorsChange() {
    this.isOpened = [];
    this.errors.forEach(() => {
      this.isOpened.push(false);
    });
  }
}

