






import { Vue, Component } from 'vue-property-decorator';

import TripsTable from './TripsTable.vue';

@Component({
  components: {
    TripsTable,
  },
})
export default class Trips extends Vue {}

