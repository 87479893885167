import Roles from '@/modules/profile/roles/Roles.vue';
import { translate } from '@/i18n';

export default {
  path: 'roles',
  name: 'roles',
  component: Roles,
  displayName: 'profile-roles.roles',
  meta: {
    permission: 'ReadProfilePermissions',
    icon: 'admin_panel_settings',
  }
};
