











import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';

@Component({})
export default class TrainCabinWindow extends Vue {
  @Prop({ default: null }) item!: any | null;

  get x() {
    return consts.margin + (this.item.x + .5) * consts.colWidth - consts.thickness;
  }

  get y() {
    return consts.margin + (this.item.y + .5) * consts.rowHeight;
  }

  get width() {
    return (this.item.width - 1) * consts.colWidth + 2 * consts.thickness;
  }

  get height() {
    return this.item.height * consts.rowHeight;
  }
}
