import NegoFares from '@/modules/settings/nego-fare/NegoFares.vue';
import NegoFareList from './nego-fare/nego-fare-list.route';
import NewNegoFare from './nego-fare/new-nego-fare.route';
import EditNegoFare from './nego-fare/edit-nego-fare.route';
import NegoFareAirCode from './nego-fare/nego-fare-air-code.route';
import NegoFareRailCode from './nego-fare/nego-fare-rail-code.route';
import NegoFareAccommodationCode from './nego-fare/nego-fare-accommodation-code.route';

export default {
  path: 'nego-fare-configurations',
  component: NegoFares,
  displayName: 'settings-sidebar.nego-fare',
  meta: {
    permission: 'ReadNegoFare',
    parentName: 'nego-fare-configurations',
    icon: 'local_offer',
    introClass: 'intro-settings',
  },
  children: [
    NegoFareList,
    NewNegoFare,
    EditNegoFare,
    NegoFareAirCode,
    NegoFareRailCode,
    NegoFareAccommodationCode,
  ],
};
