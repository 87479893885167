































import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';

@Component({})
export default class SncfAncillaryRail extends Vue {
  @Prop() offer!: any;
  @Prop() segmentId!: any;
  @Prop() profileId!: any;

  get isSelected() {
    return !!BasketStore.selectedRailAncillaries.find(offer => {
      return offer.profileId === this.profileId &&
        offer.segmentId === this.segmentId &&
        offer.product.code === this.offer.product.code;
    });
  }
}

