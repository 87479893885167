







































import { Vue, Component, Prop } from 'vue-property-decorator';
import BasketItem from '@/modules/basket/BasketItem.vue';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    BasketItem,
  },
})
export default class BasketProcessingItem extends Vue {
  @Prop() item!: any;
  @Prop({ default: false }) showLoader!: boolean;

  get basket() {
    return BasketStore.basket;
  }

  get processing() {
    return BasketStore.processing;
  }
}
