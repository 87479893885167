export interface IRestrictChoice {
  key: string;
  value: string;
}

export interface ILoyaltyCard {
  cardLabel?: string;
  cardNumberWithOnlyDigits: boolean;
  carrierCode: string | null;
  carrierName: string | null;
  carriers?: string[];
  id: string;
  isExpirationRequired: boolean | null;
  isIssueDateRequired: boolean | null;
  isNumberRequired: boolean;
  isRoutingRequired: boolean | null;
  isValidFromDateRequired?: boolean | null;
  loyaltyType: string | null;
  programCode?: string;
  programName: string | null;
  serviceType: string;
  subscriber: string;
}

export interface IValidRoutes {
  type: string | null;
  name?: string | null;
}

export interface IProfileLoyaltiesCard {
  isValidForTrip: boolean;
  cardNumber: string | null;
  expireDate: string | null;
  id: string;
  issueDate: string | null;
  loyalty: ILoyaltyCard;
  ownerId: string;
  validFromDate: string | null;
  validRoutes: IValidRoutes | null;
}

export enum LoyaltyType {
  Unknown = '',
  Discount = 'Discount',
  Loyalty = 'Loyalty',
}

export class LoyaltyForm {
  loyaltyId: string | null;
  cardNumber: string | null;
  expireDate: string | null;
  issueDate: string | null;
  validFromDate: string | null;
  validRoutes: IValidRoutes | null;

  constructor(payload?: Partial<LoyaltyForm>) {
    this.loyaltyId = (payload && payload.loyaltyId) || null;
    this.cardNumber = (payload && payload.cardNumber) || null;
    this.expireDate = (payload && payload.expireDate) || null;
    this.issueDate = (payload && payload.issueDate) || null;
    this.validFromDate = (payload && payload.validFromDate) || null;
    this.validRoutes = (payload && payload.validRoutes) || null;
  }
}

export interface IUpdateLoyaltyForm {
  cardNumber: string | null;
  expireDate: string | null;
  issueDate: string | null;
  validFromDate: string | null;
  validRoutes: IValidRoutes | null;
}
