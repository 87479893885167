






































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

import { Journey, Recommendation, Train } from '@/api/train-engine/train-search.model';
import EventBus from '@/services/event-handler';
import moment from 'moment';
import _ from 'lodash';

@Component({
  filters: {
    moment(date) {
      return moment(date).format('Do MMMM HH:mm');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
  }
})

export default class TrainTimelineInfoHeader extends Vue {
  @Prop() journeys!: Journey;
  @Prop() offer!: Recommendation;
  @Prop() selectedKey!: string;
  
  get trainLegList() {
    return this.journeys[0].trains.filter(train => {
      return train.type === 'Train';
    }) as Train[];
  }

  nextLeg(data, canClick) {
    if (canClick) {
      this.$emit('next-leg', data);
    }
  }

  selectProp() {
    if (this.trainLegList) {
      let obj = _.uniqBy(this.trainLegList, 'segmentKey');
      obj.map(train => {
        train.selectedProposal = null;
        this.offer.proposals.map(proposal => {
          if (train.segmentKey === proposal.segmentKey) {
            proposal.fares.map(fare => {
              if (fare.isSelected) {
                train.selectedProposal = fare;
              }
            });
          }
        });
      });
      EventBus.$emit('price-proposal-update');
      this.$forceUpdate();
    }
  }

  @Watch('offer.proposals', { deep: true, immediate: true })
  onPropChange() {
    this.selectProp();
  }
}

