
























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { AirProviderConfiguration } from '@/api/air-engine/air-provider-configuration.model';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class ProviderConfigurationDetail extends Vue {
  @Prop() providerConfigurations!: AirProviderConfiguration;
  @Prop() v!: Object;
  @Prop() providerIndex!: number;
  @Prop({ default: true }) inViewMode!: boolean;
  @Prop({}) service!: string;
  @Prop({}) configKeysNotRequired!: string[];
  @Prop({ default: false }) hasSaveError!: boolean;
  serverErrors = [];

  get supplier() {
    return SupplierName(this.providerConfigurations.supplier);
  }

  get configurationSettings() {
    return this.providerConfigurations.configurationSettings
      .map((item, index, array) => {
        let masterIndex = -1;

        if (item.isIndented) {
          for (let i = index - 1; i >= 0; i--) {
            if (!array[i].isIndented) {
              masterIndex = i;
              break;
            }
          }
        }
        return {
          ...item,
          isMaster: !item.isIndented && array.length > index + 1 && array[index + 1].isIndented,
          masterIndex,
        };
      }, []);
  }

  isIndentedAndMasterOff(setting) {
    if (
      setting.isIndented &&
      this.configurationSettings[setting.masterIndex].settingValue === false
    ) {
      return true;
    }

    return false;
  }

  onSwitchChange(setting, index, $event) {
    if (!setting.isMaster || $event) {
      this.$forceUpdate();
      return;
    }

    const valueByType = {
      Boolean: false,
      String: '',
    };

    this.configurationSettings.forEach((item, idx) => {
      if (item.masterIndex !== index) {
        return;
      }

      if (undefined !== valueByType[item.settingType || '']) {
        this.providerConfigurations.configurationSettings[idx].settingValue = valueByType[item.settingType || ''];
      }
    });
    this.$forceUpdate();
  }

  isKeyNotRequired(key) {
    return this.configKeysNotRequired ?
      this.configKeysNotRequired.findIndex(e => e === key) > -1 :
      false;
  }
}
