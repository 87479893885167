















import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import TrainSearchParamsOnResults from './TrainSearchParamsOnResults.vue';

@Component({
  components: {
    TrainSearchParamsOnResults
  }
})
export default class TrainSearchShortParams extends Vue {

  get trainSearchParameters() {
    return SearchStore.getTrainDefaultState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  showCriteria() {
    if (this.$route.query.leg) {
      router.push({
        name: 'trainDetails',
        params: router.currentRoute.params,
        query: { leg:  this.$route.query.leg },
      });
    } else {
      if (router.currentRoute.params && router.currentRoute.params.searchId !== '') {
        router.push({
          name: 'trainDetails',
          params: router.currentRoute.params,
        });
      } else {
        router.push({
          name: 'trainDetails'
        });
      }
    }
  }

  showTravellers() {
    router.push({
      name: 'trainTravellers',
      params: router.currentRoute.params,
      query: { leg: this.$route.query.leg },
    });
  }
}

