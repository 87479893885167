import DisplayPreferencesFlightManageRule from '@/modules/settings/display-preferences/configurations/configuration/DisplayPreferencesFlightManageRule.vue';

export default {
  path: ':configurationId?/flight-rule',
  name: 'display-preferences-flight-rule',
  component: DisplayPreferencesFlightManageRule,
  displayName: 'Flight Rule',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditDisplayPreferences',
    parentName: 'display-preferences-configurations',
    depthCategory: 'display-preferences',
    waitForDicts: true,
    depth: 3,
  }
};