

















import { Vue, Component, Prop } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import BasketBookingError from './BasketBookingError.vue';

@Component({
  components: {
    BasketBookingError,
  },
})
export default class BasketErrorGroups extends Vue {
  @Prop({ default: () => [] }) propertyErrors!: any[];

  get showSeeProviderWarnings() {
    return AccountStore.HasPermission('CanSeeProviderWarnings');
  }

  get errors() {
    return this.propertyErrors
      .filter(item => {
        return item.type !== 'Warning' || this.showSeeProviderWarnings;
      });
  }
}
