













import { Vue, Component, Prop } from 'vue-property-decorator';

import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import EventBus from '@/services/event-handler';

@Component({})
export default class TextBoxFilter extends Vue {
  @Prop() value!: string;
  @Prop() code!: string;
  @Prop() label!: string;
  @Prop() placeholder!: string;

  val: string = '';

  get valueI() {
    return this.val;
  }

  set valueI(value) {
    this.val = value;
    this.onChange();
  }
  
  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  onChange() {
    this.$emit('change', {
      value: this.val,
      code: this.code,
    });
  }

  mounted() {
    this.val = this.value;
  }

  clearFilter() {
    this.valueI = '';
  }

  created() {
    EventBus.$on('clear-filters', this.clearFilter);
  }

  beforeDestroy() {
    EventBus.$off('clear-filters', this.clearFilter);
  }
}

