





































































import { Vue, Component } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import HotelSearchStore from './hotel-search.store';
import HotelResultsRow from './HotelResultsRow.vue';

@Component({
  components: {
    HotelResultsRow,
  }
})
export default class EditHotelAgencyMarkupPopup extends Vue {
  showing: boolean = false;
  loading: boolean = false;
  isFeeValueChanging: boolean = false;
  markupValue: any = 0;
  initialMarkupValue: number = 0;
  errors: any[] = [];



  get offer() {
    return HotelSearchStore.editedFeeOffer;
  }

  get result() {
    return HotelSearchStore.editedFeeResult;
  }

  get markupCurrency() {
    if (this.offer && this.offer.agencyMarkup && this.offer.agencyMarkup.currency) {
      return this.offer.agencyMarkup.currency.symbol ? this.offer.agencyMarkup.currency.symbol : this.offer.agencyMarkup.currency.code; 
    }
    return '\u20AC';
  }

  get basePrice() {
    return this.offer.totalPrice.amount - this.initialMarkupValue;
  }

  get totalPrice() {
    return {
      amount: this.markupValue + this.basePrice,
      currency: {
        ...this.offer.agencyMarkup.currency,
      },
    };
  }



  onEvent() {
    if (this.offer && this.result) {
      this.markupValue = this.offer.agencyMarkup.amount;
      this.initialMarkupValue = this.markupValue;
      this.showing = true;
    }
  }

  closePopup() {
    this.showing = false;
    HotelSearchStore.setEditedOffer({ offer: null, result: null });
  }

  async save() {
    if (this.loading || this.isFeeValueChanging) {
      return;
    }
    this.loading = true;
    const response = await HotelSearchStore.updateAgencyMarkupInOffer({
      agencyMarkupValue: this.markupValue,
      totalPrice: this.totalPrice.amount,
    });

    if (response.errors && response.errors.length) {
      this.errors = response.errors;
    } else {
      this.closePopup();
    }

    this.loading = false;
  }



  mounted() {
    EventBus.$on('show-hotel-edit-agency-markup-popup', this.onEvent);
  }

  beforeDestroy() {
    EventBus.$off('show-hotel-edit-agency-markup-popup', this.onEvent);
  }
}

