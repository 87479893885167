


















import { Vue, Prop, Component } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import BasketProvideTravellerAddressAtDestinationForm from './BasketProvideTravellerAddressAtDestinationForm.vue';

@Component({
  components: {
    BasketProvideTravellerAddressAtDestinationForm,
  },
})
export default class BasketProvideTravellersAddressAtDestination extends Vue {
  @Prop({}) travellersWithoutRequiredAddressAtDestination!: any;

  get travellers() {
    return SearchStore.travellersSearch.travellers.filter(traveller => this.travellersWithoutRequiredAddressAtDestination.travellersIds.includes(traveller.id));
  }

  async mounted() {
    await BasketStore.updateBasket();
  }
}
