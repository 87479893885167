import Billing from '@/modules/settings/billing-custom-fields/BillingCustomFields.vue';
import BillingCustomFieldsConfigurations from './billing-custom-fields-configurations.route';
import BillingCustomFieldsConfiguration from './billing-custom-fields-configuration.route';

export default {
  path: 'billing-custom-fields',
  component: Billing,
  displayName: 'settings-sidebar.back-office-custom-fields-configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditBackOfficeCustomFields',
    icon: 'feed',
    breadCrumb: 'Billing configuration',
    parentName: 'billing-custom-fields-configurations',
  },
  children: [
    BillingCustomFieldsConfigurations,
    BillingCustomFieldsConfiguration,
  ],
};