import CustomFieldDefinition from '@/modules/settings/custom-fields-configuration/CustomFieldDefinition.vue';

export default {
  path: ':configurationId?/field-definition',
  name: 'field-definition',
  component: CustomFieldDefinition,
  displayName: 'Field definition',
  meta: {
    permission: 'ReadCustomFieldDefinitions',
    parentName: 'custom-fields',
    depthCategory: 'custom-fields',
    introClass: 'intro-settings',
    depth: 3,
  }
};