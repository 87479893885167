import FeesConfiguration from '@/modules/settings/agency-fees/FeesConfiguration.vue';

export default {
  path: ':configurationId/fees-configuration',
  name: 'fees-configuration',
  component: FeesConfiguration,
  displayName: `Fees' configuration`,
  meta: {
    breadCrumb: 'Fees configuration',
    introClass: 'intro-settings',
    permission: 'CanEditFeesConfiguration',
    parentName: 'fees',
    depthCategory: 'fees',
    depth: 2,
  }
};