import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { Theme, CompanyTheme } from './themes.model';

class ThemesApiClass {
  public getTheme(rootCompanyId: string): Promise<AxiosResponse<Theme>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{rootCompanyId}/theme',
      method: RequestMethod.GET,
    }, { rootCompanyId }, {});
  }

  public saveTheme(rootCompanyId: string, params: Theme): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{rootCompanyId}/theme',
      method: RequestMethod.PUT,
    }, { rootCompanyId }, params);
  }

  public resetTheme(configurationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/theme-configurations/{configurationId}',
      method: RequestMethod.DELETE,
    }, { configurationId }, {});
  }

  public getCompanyLogo(companyId: string, configurationId?: string): Promise<AxiosResponse<any>> {
    let url = '';
    if (configurationId) {
      url = '/api/companies/{companyId}/logos?configurationId={configurationId}';
    } else {
      url = '/api/companies/{companyId}/logos';
    }
    return http.execute({
      path: settings.apiProfile + url,
      method: RequestMethod.GET,
    }, { companyId, configurationId }, {});
  }

  public saveCompanyLogo(companyId: string, params: any): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/logos',
      method: RequestMethod.PUT,
    }, { companyId }, params, true);
  }

  public deleteCompanyLogo(companyId: string, configurationId: string): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/logos?configurationId={configurationId}',
      method: RequestMethod.DELETE,
    }, { companyId, configurationId }, {});
  }

  public createCompanyTheme(params: CompanyTheme) {
    return http.execute({
      path: settings.apiProfile + '/api/theme-configurations',
      method: RequestMethod.POST
    }, {}, params);
  }

  public getCompanyTheme(configurationId: string): Promise<AxiosResponse<Theme>> {
    return http.execute({
      path: settings.apiProfile + '/api/theme-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateCompanyTheme(configurationId: string, params: CompanyTheme): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/theme-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, params, true);
  }

  public deleteCompanyTheme(configurationId: string): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + 'api/configurations/{configurationId}',
      method: RequestMethod.DELETE,
    }, { configurationId }, {});
  }
}

export const ThemesApi: ThemesApiClass = new ThemesApiClass();
