

















































import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

const eventName = 'paginationChange';

@Component({})
export default class UiPagination extends Vue {
  @Model(eventName) modelValue!: number;
  @Prop() perPage!: number;
  @Prop() moreResultsAvailable!: number;
  @Prop() loading!: boolean;

  get pageString() {
    let result: any[] = [];
    if (this.modelValue > 2) {
      result.push('...');
    }
    if (this.modelValue > 2) {
      result.push(this.modelValue - 2);
    }
    if (this.modelValue > 1) {
      result.push(this.modelValue - 1);
    }
    result.push(this.modelValue);
    if (this.moreResultsAvailable) {
      result.push('...');
    }

    return result;
  }

  increase() {
    this.paginationChange(this.modelValue + 1);
  }

  decrease() {
    if (this.modelValue > 0) {
      this.paginationChange(this.modelValue - 1);
    }
  }

  goToFirst() {
    this.paginationChange(1);
  }

  goToPage(pageNumber) {
    if (pageNumber === '...') {
      return;
    } else {
      this.paginationChange(pageNumber);
    }
  }

  @Emit(eventName)
  paginationChange(value) {
    return value;
  }
}
