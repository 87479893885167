
































import { Vue, Component, Prop } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';

@Component({})
export default class SliderFilter extends Vue {
  @Prop() code!: string;
  @Prop({ default: .1 }) sliderStep!: number;
  @Prop() label!: string;
  @Prop({ default: 0 }) initMinLimit!: number;
  @Prop() initMaxLimit!: number;
  @Prop({ default: 0 }) initValue!: number;
  @Prop() unit!: string;
  @Prop({ default: true }) unitAsPrefix!: boolean;
  @Prop({ default: 1000 }) inputDelay!: number;

  minLimit = Math.floor(this.initMinLimit);
  maxLimit = Math.ceil(this.initMaxLimit);
  minInputCursorPosition = 1;
  maxInputCursorPosition = 1;
  timeout: number | null = null;
  visible = true;
  valueS = this.initValue;

  get value() {
    return this.formatValue(this.valueS);
  }

  set value(val) {
    this.unformatValue(val, this.minLimit, this.maxLimit, true, (v) => {
       this.valueS = v;
    }, () => {
      const input = this.$refs.minInput as HTMLInputElement;
      input.selectionEnd = this.minInputCursorPosition;
    });
  }

  emitChange() {
    this.$emit('change', {
      code: this.code,
      data: this.value,
    });
  }

  regExEscape(str) {
    if (str !== null) {
      return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    } else {
      return null;
    }
  }

  valuesChanged() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.emitChange.bind(this), this.inputDelay);
  }

  handleMinInput(e) {
    this.minInputCursorPosition = e.target.selectionStart;
  }

  handleMaxInput(e) {
    this.maxInputCursorPosition = e.target.selectionStart;
  }

  touchMinValue(e) {
    const value = e.target.value;
    this.unformatValue(
      value,
      this.minLimit,
      Math.min(this.valueS, this.maxLimit),
      true,
      v => {
        this.value = v;
        e.target.value = this.formatValue(v);
      },
      () => {
        const input = this.$refs.minInput as HTMLInputElement;
        input.selectionEnd = this.minInputCursorPosition;
      }
    );
  }

  touchMaxValue(e) {
    const value = e.target.value;
    this.unformatValue(
      value,
      Math.max(this.minLimit, this.valueS),
      this.maxLimit,
      true,
      v => {
        this.value = v;
        e.target.value = this.formatValue(v);
      },
      () => {
        const input = this.$refs.maxInput as HTMLInputElement;
        input.selectionEnd = this.maxInputCursorPosition;
      }
    );
  }

  formatValue(value) {
    if (this.unit !== undefined && this.unit !== null) {
      if (this.unitAsPrefix === true) {
        return this.unit + ' ' + value;
      } else {
        return value + ' ' + this.unit;
      }
    } else {
      return value;
    }
  }

  unformatValue(input, min, max, fixMinMax, setter, cursorPositionSetter) {
    let regexPattern: string | null = null;

    if (this.unit !== undefined && this.unit !== null) {
      if (this.unitAsPrefix === true) {
        regexPattern = `^(?:${this.regExEscape(this.unit)})?\\s*((?:[-+]?)\\d+(?:(?:\.|,)\\d{1,2})?)$`;
      } else {
        regexPattern = `^((?:[-+]?)\\d+(?:(?:\\.|,)\d{1,2})?)(?:\\s*(?:${this.regExEscape(this.unit)}))?$`;
      }
    } else {
      regexPattern = '^((?:[-+]?)\\d+(?:(?:\\.|,)\\d{1,2})?)$';
    }

    const regex = new RegExp(regexPattern);
    
    const parseResult = regex.exec(input);
    if (parseResult !== null) {
      const rawValue = parseResult[1].replace(',', '.');
      let value = parseFloat(rawValue);

      if (fixMinMax === true) {
        if (value < min) {
          value = min;
        } else if (value > max) {
          value = max;
        }
      }

      if (value >= min && value <= max) {
        setter(value);
        this.valuesChanged();
      }
    }

    if (this.unitAsPrefix === undefined || this.unitAsPrefix === false) {
      this.$nextTick(cursorPositionSetter);
    }
  }

  refresh(data) {
    if (data) {
      this.minLimit = Math.floor(data.minLimit);
      this.maxLimit = Math.ceil(data.maxLimit);
    }
  }

  clearFilter() {
    this.valueS = this.initValue;
    this.emitChange();
  }

  refreshWhenNeeded(params) {
    if (this.code !== params.code) {
      return;
    }

    this.refresh(params.data);
  }



  created() {
    EventBus.$on('clear-filters', this.clearFilter);
    EventBus.$on('refresh-filter-component', this.refreshWhenNeeded);
  }

  beforeDestroy() {
    EventBus.$off('clear-filters', this.clearFilter);
    EventBus.$off('refresh-filter-component', this.refreshWhenNeeded);
  }

}
