
export default {
  air: {
    filtersdata: {
      rangeFilters: [
        {
          type: 'range',
          code: 'PRICE',
          label: 'search-filter.price'
        },
        {
          type: 'rangeslider',
          code: 'OUTBOUND_DEPART_TIME',
          translate: true,
          label: 'search-filter.departure-time',
        },
        {
          type: 'rangeslider',
          code: 'INBOUND_DEPART_TIME',
          translate: true,
          label: 'search-filter.return-time',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
        {
          type: 'rangeslider',
          code: 'LEG_1_DEPART_TIME',
          translate: true,
          label: 'search-filter.leg-1',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
        {
          type: 'rangeslider',
          code: 'LEG_2_DEPART_TIME',
          translate: true,
          label: 'search-filter.leg-2',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
        {
          type: 'rangeslider',
          code: 'LEG_3_DEPART_TIME',
          translate: true,
          label: 'search-filter.leg-3',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
        {
          type: 'rangeslider',
          code: 'LEG_4_DEPART_TIME',
          translate: true,
          label: 'search-filter.leg-4',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
        {
          type: 'rangeslider',
          code: 'LEG_5_DEPART_TIME',
          translate: true,
          label: 'search-filter.leg-5',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
        {
          type: 'rangeslider',
          code: 'LEG_6_DEPART_TIME',
          translate: true,
          label: 'search-filter.leg-6',
          isVisible: (context) => {
            return context.inbountDepartureTimeFilterVisible;
          }
        },
      ],
      categoriesFilters: [
        {
          type: 'checkbox',
          code: 'SUPPLIER',
          label: 'search-filter.content-source',
          maxCategoriesInCollapsedMode: 3,
          stickTop: true,
        },
        {
          type: 'checkbox',
          code: 'AIRLINE',
          label: 'search-filter.airlines',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'CABINCLASS',
          label: 'search-filter.cabin-class',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'TRAVEL_POLICY',
          label: 'search-filter.travel-policy',
          maxCategoriesInCollapsedMode: 1,
          itemLabels: {
              'Compliant': 'search-filter.compliant'
          }
        },
        {
          type: 'checkbox',
          code: 'STOPS',
          label: 'search-filter.stops',
          maxCategoriesInCollapsedMode: 3,
          itemLabels: {
            'DirectFlight': 'No stops',
            'OneStop': '1 stop',
            'TwoOrMoreStops': '2+ stops'
          }
        },
        {
          type: 'checkbox',
          code: 'BAGS',
          label: 'search-filter.bags',
          maxCategoriesInCollapsedMode: 3,
          itemLabels: {
            'NoBag': 'No bags',
            'OneBag': '1 bag',
            'TwoOrMoreBagss': '2+ bags'
          }
        },
        {
          type: 'checkbox',
          code: 'BUNDLE_FARES',
          label: 'search-filter.bundle-fares',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'FLEX_ONLY',
          label: 'search-filter.fare-family',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'FARE_TYPE',
          label: 'search-filter.fare-type',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'NEGO_CODE',
          label: 'search-filter.nego-code',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'FARE_LABEL',
          label: 'search-filter.fare-labels',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'checkbox',
          code: 'WITHOUT_AIRPORT_CHANGE',
          label: 'search-filter.additional-filter',
          maxCategoriesInCollapsedMode: 3,
        },
      ],
      hashFilters: [
        {
          code: 'FLIGHT_NUMBERS'
        }
      ],
    },
  },
  hotel: {
    filters: [
      {
        type: 'category',
        code: 'PROVIDER',
        category: 'stringCategories',
        label: 'search-filter.content-source',
        maxCategoriesInCollapsedMode: 3,
        stickTop: true,
      },
      {
        type: 'range',
        code: 'PRICE',
        category: 'numericRange',
        unitAsPrefix: true,
        unit: '\u20AC',
        label: 'search-filter.price'
      },
      {
        type: 'category',
        code: 'TYPE',
        category: 'stringCategories',
        label: 'search-filter.property-type',
        maxCategoriesInCollapsedMode: 3,
        translate: true,
      },
      {
        type: 'category',
        code: 'CATEGORY',
        category: 'numericCategories',
        label: 'search-filter.category',
        maxCategoriesInCollapsedMode: 3,
      },
      {
        type: 'range',
        code: 'DISTANCE',
        unitAsPrefix: false,
        unit: 'm',
        category: 'numericRange',
        label: 'search-filter.distance',
      },
      {
        type: 'textbox',
        code: 'NAME',
        category: 'string',
        label: 'search-filter.property-name',
      },
      {
        type: 'category',
        code: 'MEAL',
        category: 'stringCategories',
        label: 'search-filter.meal-type',
        maxCategoriesInCollapsedMode: 3,
        translate: true,
      },
      {
        type: 'checkbox',
        code: 'REFUNDABLE_ONLY',
        category: 'bool',
        label: 'search-filter.refundable',
        titleLabel: 'search-filter.cancellation-policy',
        maxCategoriesInCollapsedMode: 3,
      },
      {
        type: 'checkbox',
        code: 'TP_COMPLIANT_ONLY',
        category: 'bool',
        label: 'search-filter.compliant',
        titleLabel: 'search-filter.travel-policy',
        maxCategoriesInCollapsedMode: 3,
      },
      {
        type: 'category',
        code: 'AMENITY',
        category: 'stringCategories',
        label: 'search-filter.amenities',
        maxCategoriesInCollapsedMode: 3,
        translate: true, 
      },
      {
        type: 'category',
        code: 'TRAVELLER_EXPERIENCE',
        category: 'stringCategories',
        label: 'search-filter.traveller-experience',
        maxCategoriesInCollapsedMode: 3,
      },
    ],
    offersFilters: [
      {
        type: 'range',
        code: 'PRICE',
        label: 'search-filter.price'
      },
      {
        type: 'category',
        code: 'ROOM_TYPE',
        label: 'search-filter.room-types',
        maxCategoriesInCollapsedMode: 3,
      },
      {
        type: 'category',
        code: 'MEAL_TYPE',
        label: 'search-filter.meal-type',
        maxCategoriesInCollapsedMode: 3,
      },
    ]
  },
  train: {
    filters: {
      categoriesFilters: [
        {
          type: 'category',
          code: 'SUPPLIER',
          label: 'search-filter.content-source',
          maxCategoriesInCollapsedMode: 3,
          stickTop: true,
        },
        {
          type: 'category',
          code: 'TRAVEL_POLICY',
          label: 'search-filter.travel-policy',
          translate: true,
          maxCategoriesInCollapsedMode: 1,
          itemLabels: {
            'Compliant': 'search-filter.compliant'
          }
        },
        {
          type: 'category',
          code: 'CHANGES',
          label: 'search-filter.changes',
          translate: true,
          maxCategoriesInCollapsedMode: 3,
        },
        {
          type: 'category',
          code: 'CABINCLASS',
          label: 'search-filter.cabin-class',
          maxCategoriesInCollapsedMode: 3,
        },
      ],
    }
  },
  car: {
    filters: {
      rangeFilters: [
        {
          code: 'PRICE',
          label: 'search-filter.price'
        },
      ],
      categoriesFilters: [
        {
          code: 'SUPPLIER',
          label: 'search-filter.content-source',
          maxCategoriesInCollapsedMode: 2,
          stickTop: true,
        },
        {
          code: 'VENDOR',
          label: 'search-filter.rental-company',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          code: 'TRAVEL_POLICY',
          label: 'search-filter.travel-policy',
          maxCategoriesInCollapsedMode: 1,
          itemLabels: {
            'Compliant': 'search-filter.compliant'
          }
        },
        {
          code: 'TRANSMISSION',
          label: 'search-filter.transmission',
          maxCategoriesInCollapsedMode: 2,
          itemLabels: {
            'AUTO': 'search-filter.auto',
            'MANUAL': 'search-filter.manual',
          }
        },
        {
          code: 'VEHICLE_CATEGORY',
          label: 'search-filter.vehicle-type',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          code: 'EXTRAS',
          label: 'search-filter.extras-included',
          maxCategoriesInCollapsedMode: 3,
        },
        {
          code: 'FUEL_TYPE',
          label: 'search-filter.electric-cars',
          maxCategoriesInCollapsedMode: 1,
          itemLabels: {
            'Electric': 'search-filter.fully-electric',
          }
        },
      ]
    }
  }
};
