import { translate } from '@/i18n';

export const CarCategory = [
  'Unknown',
  'Mini',
  'Economy',
  'Compact',
  'Intermediate',
  'Standard',
  'Fullsize',
  'Premium',
  'Luxury',
  'Oversize',
  'Special',
];

export const BreakfastOptions = [
  {
    value: 'included',
    name: translate('settings-travel-policy.included')
  },
  {
    value: 'notincluded',
    name: translate('settings-travel-policy.not-included')
  },
];

export const CategoriesOptions = [
  {
    value: '0',
    name: translate('settings-travel-policy.no-category'),
  },
  {
    value: '1',
    name: '1 ' + translate('settings-travel-policy.star'),
  },
  {
    value: '2',
    name: '2 ' + translate('settings-travel-policy.stars'),
  },
  {
    value: '3',
    name: '3 ' + translate('settings-travel-policy.stars'),
  },
  {
    value: '4',
    name: '4 ' + translate('settings-travel-policy.stars'),
  },
  {
    value: '5',
    name: '5 ' + translate('settings-travel-policy.stars'),
  },
];