import CreateRoleForm from '@/modules/settings/roles/CreateRoleForm.vue';

export default {
  path: 'create-role',
  name: 'create-role',
  component: CreateRoleForm,
  displayName: 'Roles & permissions - create role',
  meta: {
    introClass: 'intro-settings',
    permission: 'WriteRoles',
    depthCategory: 'settings-roles',
    depth: 2,
    parentName: 'settings-roles',
  },
};
