export interface PaymentInstrumentsResult {
  id: string;
  policyId: string;
  code: string;
  name: string;
  description: string;
  isActive: boolean;
}

export class PaymentInstrumentModel {
  policyId: string = '';
  id: string = '';
  code: string = '';
  name: string = '';
  description: string = '';
  isActive: boolean = true;

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.code !== undefined) {
      this.code = params.code;
    }
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
  }
}