








import { Component, Vue } from 'vue-property-decorator';
import ProfileApproverCustomFields from '@/modules/profile/approval-workflow/ProfileApproverCustomFields.vue';
import DesignatedTravellers from '@/modules/profile/approval-workflow/DesignatedTravellers.vue';
import DesignatedApprovers from '@/modules/profile/approval-workflow/DesignatedApprovers.vue';

@Component({
  components: {
    ProfileApproverCustomFields,
    DesignatedTravellers,
    DesignatedApprovers,
  },
})
export default class ApprovalWorkflow extends Vue {}
