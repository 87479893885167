
















































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import SearchStore from '@/modules/search/search.store';
import SeatMapMock from './seat-map.json';
import { AirSeats } from './air-seats.model';

@Component({
  filters: {
    flightTimelineFilter(val) {
      const momentDeparture = moment(new Date(val.departure).toISOString());
      let momentArrival = moment(new Date(val.arrival).toISOString());
      let result = momentDeparture.format('ddd, Do MMM YYYY | HH:mm');

      if (momentArrival.diff(momentDeparture, 'days') === 0) {
        result += ' - ' + momentArrival.format('HH:mm');
      } else {
        result += ' - ' + momentArrival.format('HH:mm | ddd, Do MMM YYYY');
      }
      return result;
    },
    durationFilter(val) {
      const minutes = val % 60;
      const hours = (val - minutes) / 60;
      let result = hours + ':';
      if (hours < 10) {
        result = '0' + result;
      }
      if (minutes < 10) {
        result += '0';
      }
      result += minutes;
      return result;
    },
  },
})
export default class AirSeatMap extends Vue {
  @Prop() value!: AirSeats;
  @Prop() offer!: any;

  margin = 4;
  rowHeight = 21;
  colWidth = 21;
  extendedColWidth = 46;

  seatViewDetails: any = null;
  cabinPicked: any = null;
  travellerPicked: any = null;
  flightPicked: any = null;
  showTooltip: boolean = false;
  tooltipInfo: any = {};

  tooltipStyles() {
    if (!this.showTooltip) {
      return {};
    }
    return {
      top: this.tooltipInfo.offsetY + 'px',
      left: this.tooltipInfo.offsetX + 'px',
    };
  }

  get seats() {
    if (!this.seatViewDetails) {
      return null;
    }
    return this.seatViewDetails.SeatAvailabilityRS;
  }

  get seatMap() {
    return this.seatViewDetails ? this.seats.SeatMap : null;
  }

  get cabins() {
    if (!this.seatMap) {
      return [];
    }
    return this.seatMap.Cabin.map((item, index) => {
      return {
        ...item,
        name: 'cabin' + index,
      };
    });
  }

  get seatsOfTheDeck() {
    return this.value.seats.filter(item => {
      const rn = Number(item.row);
      return (item.flightId === this.flightPicked.flightNumberHash) &&
        rn >= Number(this.cabinPicked.CabinLayout.Rows.First) &&
        rn <= Number(this.cabinPicked.CabinLayout.Rows.Last);
    });
  }

  get exitRows() {
    if (!this.cabinPicked.CabinLayout.ExitRowPosition) {
      return [];
    }
    return this.cabinPicked.CabinLayout.ExitRowPosition.RowPosition.map(item => {
      return {
        first: Number(item.First),
        last: Number(item.Last),
        middle: (Number(item.First) + Number(item.Last)) / 2,
      };
    });
  }

  get exitRowsForColumns() {
    return this.exitRows.filter(item => item.first > this.firstRowNumber);
  }

  get disclosures() {
    if (!this.seats) {
      return [];
    }
    return this.seats.DataLists.DisclosureList.Disclosures;
  }

  get hasWings(): boolean {
    return !!this.cabinPicked.CabinLayout.WingPosition;
  }

  get wingStart() {
    if (!this.hasWings) {
      return 0;
    }
    return Number(this.cabinPicked.CabinLayout.WingPosition.FirstRow);
  }

  get wingEnd() {
    if (!this.hasWings) {
      return 0;
    }
    return Number(this.cabinPicked.CabinLayout.WingPosition.LastRow);
  }

  get seatComponents() {
    return this.cabinPicked.Component;
  }

  get reservedSeats() {
    return this.seatComponents.filter(seat => 'G' === seat.Type);
  }

  get lavatories() {
    return this.seatComponents.filter(seat => 'LA' === seat.Type);
  }

  get closets() {
    return this.seatComponents.filter(seat => 'CL' === seat.Type);
  }

  get galley() {
    return this.seatComponents.filter(seat => 'OTH' === seat.Type);
  }

  get others() {
    return this.seatComponents.filter(seat => -1 === ['LA', 'OTH', 'CL', 'G'].indexOf(seat.Type));
  }

  get travellers() {
    if (!SearchStore.getTravellersState) {
      return [];
    }
    return SearchStore.getTravellersState.travellers;
  }

  get flights() {
    return this.offer.legFlights.map(item => {
      return {
        ...item.flights[0],
        flightNumberHash: item.flightNumberHash,
        legNumber: item.legNumber,
      };
    });
  }

  get alaCarte() {
    return this.seats.ALaCarteOffer.ALaCarteOfferItem;
  }

  get serviceDefs() {
    return this.seats.DataLists.ServiceDefinitionList.ServiceDefinition;
  }

  get columns() {
    let columnOffsets: number[] = [];
    const mapped = this.cabinPicked.CabinLayout.Columns.map(item => {
      if (typeof item === 'string') {
        return {
          text: item,
          type: '',
        };
      }
      return {
        text: item.__text,
        type: item._Position,
      };
    });
    mapped.forEach((column, index) => {
      if (index === 0) {
        columnOffsets.push(3.5 * this.colWidth);
      } else if (column.type === 'A' && mapped[index - 1].type === 'A') {
        columnOffsets.push(columnOffsets[index - 1] + this.extendedColWidth);
      } else {
        columnOffsets.push(columnOffsets[index - 1] + this.colWidth);
      }
    });
    return mapped.map((item, index) => {
      return {
        ...item,
        offset: columnOffsets[index],
      };
    });
  }

  get columnsAlleys() {
    return this.columns.filter(column => column.type === 'A').map((item, index) => {
      return {
        ...item,
        offset: 0 === index % 2 ? item.offset + this.colWidth / 1.5 : item.offset - this.colWidth / 1.5,
      };
    });
  }

  get rowOffsets() {
    let rowOffsets: number[] = [];
    for (let i = this.firstRowNumber; i <= this.lastRowNumber; i++) {
      let fnd = false;
      this.exitRows.forEach(item => {
        if (item.first <= i && item.last >= i) {
          fnd = true;
        }
      });

      if (fnd) {
        if (i === this.firstRowNumber) {
          rowOffsets.push(1);
          continue;
        }
        rowOffsets.push(rowOffsets[rowOffsets.length - 1] + 2.5);
        continue;
      }
      if (i === this.firstRowNumber) {
        rowOffsets.push(1);
        continue;
      }
      rowOffsets.push(rowOffsets[rowOffsets.length - 1] + 1);
    }
    return rowOffsets;
  }

  get firstRowNumber() {
    return Number(this.cabinPicked.CabinLayout.Rows.First);
  }

  get lastRowNumber() {
    return Number(this.cabinPicked.CabinLayout.Rows.Last);
  }

  get mapWidth() {
    const colNumber = this.cabinPicked.CabinLayout.Columns.length;
    return (colNumber + 4) * this.colWidth + 3 * (this.extendedColWidth - this.colWidth);
  }

  get mapHeight() {
    const rowNumber = this.lastRowNumber - this.firstRowNumber + 
      2.5 + 
      (0.5 + 1.5 * (this.cabinPicked.CabinLayout.ExitRowPosition.RowPosition.length - 1));
    return rowNumber * this.rowHeight;
  }

  get currentPrice() {
    const basePrice = {
      ...this.offer.proposal.price,
    };

    if (this.value) {
      this.seatViewDetails.SeatAvailabilityRS.SeatMap.Cabin.forEach(cabin => {
        cabin.Row.forEach(row => {
          row.Seat.forEach(s => {
            this.value.seats.forEach(seat => {
              if (seat.row === row.Number && seat.column === s.Column) {
                const offerItem = this.seatViewDetails.SeatAvailabilityRS.ALaCarteOffer.ALaCarteOfferItem
                  .find(i => i._OfferItemID === s.OfferItemRefs);

                basePrice.amount += Number(offerItem.UnitPriceDetail.TotalAmount.SimpleCurrencyPrice.__text);
              }
            });
          });
        });
      });
    }

    return basePrice;
  }



  userFullName(user) {
    return userFullName(user);
  }

  getSeatOffset(seat) {
    const column = this.columns.filter(column => column.text === (seat.Column || seat.Location.RowPosition[0].ColumnPosition))[0];
    return column.offset - this.colWidth / 2 + this.margin / 2;
  }

  isExtraPaidSeat(row, seat) {
    const alaCarteOfferItem = this.alaCarte.find(item => item._OfferItemID === seat.OfferItemRefs);
    return Number(alaCarteOfferItem.UnitPriceDetail.TotalAmount.SimpleCurrencyPrice.__text) > 0;
  }

  findSeat($event) {
    let seatClicked: boolean = false;
    let seatColumn: string = '';
    let seatRow: string = '';

    this.columns.forEach(column => {
      if (
        $event.layerX >= column.offset - this.colWidth / 2 + this.margin / 2 &&
        $event.layerX <= column.offset + this.colWidth / 2 - this.margin / 2
      ) {
        seatColumn = column.text;
      }
    });
    this.rowOffsets.forEach((row, index) => {
      if (
        $event.layerY >= (row + .75) * this.rowHeight - 1 &&
        $event.layerY <= (row + .75) * this.rowHeight - 1 + this.rowHeight - this.margin + 2
      ) {
        seatRow = (this.firstRowNumber + index) + '';
      }
    });
    if (!seatRow || !seatColumn) {
      return;
    }
    // check seat
    const row = this.cabinPicked.Row.find(row => row.Number === seatRow);
    if (!row) {
      return;
    }
    const seat = row.Seat.find(seat => seat.Column === seatColumn);
    if (!seat) {
      return;
    }
    // check if doesn't appear in components
    const el = this.seatComponents.find(component => {
      const p = component.Location.RowPosition;
      return p.Begin === seatRow && p.ColumnPosition === seatColumn;
    });

    if (el) {
      return;
    }

    return {
      row: seatRow,
      column: seatColumn,
      seat,
    };
  }

  travellerNumber(id) {
    const idx = this.travellers.findIndex(item => item.id === id);
    return idx + 1;
  }

  clickSeat(row, column) {
    const travellerId = this.travellerPicked.id;
    const flightId = this.flightPicked.flightNumberHash;
    let newValue: AirSeats = {
      seats: this.value.seats || [],
      id: this.value.id || this.offer.id,
    };
    let travellerIndex = newValue.seats
      .findIndex(seat => travellerId === seat.travellerId && flightId === seat.flightId);
    let foundConflict = newValue.seats
      .findIndex(seat => row === seat.row && column === seat.column && flightId === seat.flightId);
    let shouldAdd: boolean = false;
    const el = {
      travellerId,
      flightId,
      row,
      column,
    };

    if (-1 === travellerIndex && -1 === foundConflict) {
      shouldAdd = true;
    }

    if (shouldAdd) {
      newValue.seats.push(el);
    } else {
      if (-1 === travellerIndex && -1 < foundConflict) {
        newValue.seats.splice(foundConflict, 1, el);
      } else if (-1 < travellerIndex && travellerIndex === foundConflict) {
        newValue.seats.splice(travellerIndex, 1);
      } else if (-1 < travellerIndex && -1 < foundConflict) {
        newValue.seats.splice(travellerIndex, 1);
        foundConflict = newValue.seats
          .findIndex(seat => row === seat.row && column === seat.column);
        newValue.seats.splice(foundConflict, 1, el);
      } else {
        newValue.seats.splice(travellerIndex, 1, el);
      }
    }
    this.Input(newValue);
  }

  passangerSeat(id) {
    if (!this.value) {
      return null;
    }
    return this.value.seats
      .find(
        item =>
          item.flightId === this.flightPicked.flightNumberHash &&
          item.travellerId === id
      );
  }

  seatMapClick($event) {
    const result = this.findSeat($event);
    if (!result) {
      return;
    }
    const {
      row,
      column,
    } = result;
    // should select/deselect for current traveller
    this.clickSeat(row, column);
  }

  seatMapOver($event) {
    const result = this.findSeat($event);
    if (!result) {
      this.showTooltip = false;
      this.tooltipInfo = {};
      return;
    }
    this.tooltipInfo = {};

    this.seatViewDetails.SeatAvailabilityRS.SeatMap.Cabin.forEach(cabin => {
      cabin.Row.forEach(row => {
        row.Seat.forEach(s => {
          
          if (result.row === row.Number && result.column === s.Column) {
            const offerItem = this.seatViewDetails.SeatAvailabilityRS.ALaCarteOffer.ALaCarteOfferItem
              .find(i => i._OfferItemID === s.OfferItemRefs);

            this.tooltipInfo.price = {
              currency: this.offer.proposal.price.currency,
              amount: Number(offerItem.UnitPriceDetail.TotalAmount.SimpleCurrencyPrice.__text),
            };
          }
        });
      });
    });

    this.tooltipInfo.offsetX = $event.layerX - 110;
    this.tooltipInfo.offsetY = $event.layerY + 30;

    this.$forceUpdate();

    this.showTooltip = true;
  }

  @Emit('input')
  Input(value) {
    return value;
  }

  mounted() {
    this.seatViewDetails = SeatMapMock;
    this.cabinPicked = this.cabins[0];
    if (this.travellers.length) {
      this.travellerPicked = this.travellers[0];
    }
    if (this.flights.length) {
      this.flightPicked = this.flights[0];
    }
  }
}
