import { BaseTableParams } from '@/core/base-table-params';

export class NotificationsTableParams extends BaseTableParams {
  tripCode: string = '';
  type: string = '';
  status: string = '';
  notificationDate: any = {
    from: '',
    to: '',
  };
  affectedBooking: string = '';
  companyName: string = '';
  page: number = 1;
  usePageParam: boolean = true;

  public constructor(params: any) {
    super(params);
  }
}
