


























import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import ExpenseStore from '../expense.store';

@Component({
  components: {}
})
export default class ExpenseMessages extends Vue {
  expenseId: string = '';
  
  get missionDetails() {
    return ExpenseStore.selectedMissionForExpense;
  }

  goToMission() {
    if (this.missionDetails) {
        router.push({
        name: 'modify-mission',
        params: {
          missionId: this.missionDetails.id,
        },  
      });
    }
  }

  @Watch('$route.params.expenseId', { immediate: true })
  routeHandler(val) {
    if (val) {
      this.expenseId = val;
    }
  }

  beforeDestroy() {
    ExpenseStore.clearMissionForExpense();
  }

}
