import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { ProfilePermissionModel } from './permissions.model';

class PermissionApiClass {
  public profilePermissions(profileId: string): Promise<AxiosResponse<ProfilePermissionModel[]>> {
    return http.execute({
      path: settings.apiPermissions + '/api/profiles/{profileId}/permissions',
      method: RequestMethod.GET
    }, { profileId }, {});
  }
}

export const PermissionApi: PermissionApiClass = new PermissionApiClass();
