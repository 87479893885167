import AccommodationProviderConfigurations from '@/modules/settings/configurations/AccommodationProviderConfigurations.vue';

export default {
  path: '',
  name: 'accommodation-provider-configurations',
  component: AccommodationProviderConfigurations,
  displayName: 'Accommodation providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadAccommodationProviders',
    depthCategory: 'accommodation-providers',
    depth: 1,
    parentName: 'accommodation-provider-configurations',
  },
};