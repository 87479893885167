import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { LanguagesModel } from './languages.model';

class LanguagesApiClass {
  public getLanguages(): Promise<AxiosResponse<LanguagesModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/languages',
      method: RequestMethod.GET,
    }, {}, {});
  }
}

export const LanguagesApi: LanguagesApiClass = new LanguagesApiClass();