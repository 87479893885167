













































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import CompanyActionMenu from './CompanyActionMenu.vue';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { CompanyModel, CompanyChangeStatusMessage } from '@/api/profile/company.model';
import CompanyData from './CompanyData.vue';
import SettingsStore from '../settings.store';
import { Permission } from '@/const/permission.enum';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';
import axios, { CancelTokenSource } from 'axios';
import { isCancelError } from '@/core/utils';
import { sanitizeUrl } from '@braintree/sanitize-url';

const NoConfigItem = {
  id: null,
  name: translate('settings-company.no-configuration'),
};

@Component({
  components: {
    CompanyActionMenu,
    CompanyData
  }
})
export default class CompanyStructure extends Vue {
  currentPage: number = 1;
  params = {
    size: Number.MAX_SAFE_INTEGER,
    sort: '',
    start: 0,
  };
  perPageOptions = [
    5,
    10,
    15,
    20,
    30,
    40,
    50,
    100,
    Number.MAX_SAFE_INTEGER
  ];
  fields = {
    name: {
      label: translate('settings-company.name'),
      sortable: false,
    },
    code: {
      label: translate('settings-company.code'),
      sortable: false,
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  serverErrorsMessages = {
    CompanyCodeNotUnique: translate('common-error.company-code-not-unique'),
    CompanyHasSubhierarhy: translate('common-error.company-has-subhierarhy'),
    UserProfilesExists: translate('common-error.user-profiles-exists'),
    ParentCompanyIsDisabled: translate('common-error.parent-company-is-disabled'),
    CompanyHasDisabledParent: translate('common-error.company-has-disabled-parent'),
    FIELD_VALUE_TOOLONG: translate('common-error.field-too-long')
  };
  configTypesMap = {
    AirProviderConfiguration: translate('settings-company.air-config'),
    TravelPolicyConfiguration: translate('settings-company.policy-config'),
    ApprovalWorkflowConfiguration: translate('settings-company.approval-config'),
    SabreQueuesConfiguration: translate('settings-company.sabre-config'),
  };
  typesItemsMap: any = {
    AirProviderConfiguration: [],
    TravelPolicyConfiguration: [],
  };
  currentValues: any = {};
  loadingMap: any = {};

  company = this.currentCompany;
  selectedUnit: CompanyModel | null = null;
  menuActive: boolean = false;
  addChildPopup: boolean = false;
  editCompanyPopup: boolean = false;
  deactivateUnitPopup: boolean = false;
  activateUnitPopup: boolean = false;
  deleteInProgress: boolean = false;
  deactivateInProgress: boolean = false;
  deleteUnitPopup: boolean = false;
  canDeleteLoading: boolean = false;
  canDisableLoading: boolean = false;
  canEnableLoading: boolean = false;
  cancelToken: CancelTokenSource | null = null;
  canDeleteError: any[] = [];
  canDisableError: any[] = [];
  canEnableError: any[] = [];
  hasSubUnits: boolean = false;
  formPending: boolean = false;
  loading: boolean = false;
  showManageConfigurations: boolean = false;
  items: any[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  serverErrors: any[] = [];
  popupError: any[] = [];
  query: string = '';
  searchQuery: string = '';
  queryInProgress: boolean = false;

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get count() {
    return this.itemsFiltered.length;
  }

  get exactMatches() {
    if (!this.searchQuery) {
      return [];
    }
    const query = this.searchQuery.toLowerCase();

    return this.items.filter(item => {
      return item.name.toLowerCase().indexOf(query) > -1 || item.code.toLowerCase().indexOf(query) > -1;
    });
  }

  get exactMatchesIds() {
    return this.exactMatches.map(el => el.id);
  }

  get itemsFiltered() {
    if (!this.searchQuery) {
      return this.items;
    }
    const exactMatches = this.exactMatches;

    const ids = exactMatches.reduce((previous, current) => {
      return this.findAllParents(current, previous);
    }, exactMatches.map(el => el.id));

    return this.items.filter(item => {
      const parentIds = this.findAllParents(item, []);
      const hasParent = parentIds.reduce((prev, cur) => {
        return prev || !!exactMatches.find(match => match.id === cur);
      }, false);

      return ids.indexOf(item.id) > -1 || hasParent;
    }).map(item => {
      if (-1 === ids.indexOf(item.id)) {
        return item;
      }
      const matchedName = this.matchedItemName(item.name);
      const matchedCode = this.matchedItemName(item.code);
      return {
        ...item,
        namePartBefore: matchedName[0],
        namePartExact: matchedName[1],
        namePartAfter: matchedName[2],
        codePartBefore: matchedCode[0],
        codePartExact: matchedCode[1],
        codePartAfter: matchedCode[2],
      };
    });
  }

  manageCompanyUrl(companyId, id) {
    return sanitizeUrl('/settings/' + companyId + '/company-structure/' + id + '/manage-configuration');
  }

  findAllParents(item, parents) {
    if (!item.parentId) {
      return parents;
    }

    const parent = this.items.find(el => el.id === item.parentId);

    return this.findAllParents(parent, [...parents, item.parentId]);
  }

  matchedItemName(name) {
    const query = this.searchQuery.toLowerCase();
    const index = name.toLowerCase().indexOf(query);

    if (index === -1) {
      return [name, '', ''];
    }

    const before = name.substr(0, index);
    const exact = name.substr(index, query.length);
    const after = name.substr(index + query.length);

    return [before, exact, after];
  }

  classes(level) {
    if (level > 1) {
      return {
        paddingLeft: ((level - 1) * 30) + 'px',
      };
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'queryInProgress',
  })
  updateSearchQuery() {
    this.searchQuery = this.query;
    this.$nextTick(() => {
      this.queryInProgress = false;
    });
  }

  @Watch('query')
  onQueryChange() {
    this.updateSearchQuery();
  }

  @Watch('showManageConfigurations')
  onShowManageConfigurations() {
    router.push({
      name: 'manage-configuration-assignment',
      params: {
        configurationId: this.selectedUnit!.id,
      }
    });
  }

  @Watch('addChildPopup')
  addChildPopupConfigurations() {
    router.push({
      name: 'company-structure-configuration',
      params: {
        configurationId: this.selectedUnit!.id,
      },
      query: {
        edit: 'false',
      }
    });
  }

  @Watch('editCompanyPopup')
  editCompanyPopupConfigurations() {
    router.push({
      name: 'company-structure-configuration',
      params: {
        configurationId: this.selectedUnit!.id,
      },
      query: {
        edit: 'true',
      }
    });
  }

  @Watch('currentCompany')
  companyChange(value) {
    // on current company change
    let currentRootId = this.company!.rootCompanyId;
    this.company = value;

    if (currentRootId !== value.rootId) {
      this.load();
    }
  }

  @Watch('activateUnitPopup')
  async onActivateUnitPopupShow(value) {
    this.canEnableError = [];
    if (!value) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = null;
      return;
    }
    this.canEnableLoading = true;
    this.cancelToken = axios.CancelToken.source();
    
    try {
      const response = await ProfileCompanyApi.getCanEnableCompanyUnit(
        this.selectedUnit!.id,
        Permission.WriteCompanyStructure,
        this.cancelToken
      );
      this.cancelToken = null;
      if (response.data.affectedCompanies.length > 1) {
        this.hasSubUnits = true;
      }
      this.canEnableLoading = false;
    } catch (error) {
      if (!isCancelError(error)) {
        this.canEnableError = this.$handleErrors(error, true);
      }
    }
  }

  @Watch('deactivateUnitPopup')
  async onDeactivateUnitPopupShow(value) {
    this.canDisableError = [];
    if (!value) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = null;
      return;
    }
    this.canDisableLoading = true;
    this.cancelToken = axios.CancelToken.source();
    
    try {
      const response = await ProfileCompanyApi.getCanDisableCompanyUnit(
        this.selectedUnit!.id,
        Permission.WriteCompanyStructure,
        this.cancelToken
      );
      this.cancelToken = null;
      if (response.data.affectedCompanies.length > 1) {
        this.hasSubUnits = true;
      }
      this.canDisableLoading = false;
    } catch (error) {
      if (!isCancelError(error)) {
        this.canDisableError = this.$handleErrors(error, true);
      }
    }
  }

  @Watch('deleteUnitPopup')
  async onDeleteUnitPopupShow(value) {
    this.canDeleteError = [];
    if (!value) {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }
      this.cancelToken = null;
      return;
    }
    this.canDeleteLoading = true;
    this.cancelToken = axios.CancelToken.source();

    try {
      const response = await ProfileCompanyApi.getCanDeleteCompanyUnit(
        this.selectedUnit!.id,
        Permission.CanDeleteCompany,
        this.cancelToken
      );
      this.cancelToken = null;
      if (response.data.affectedCompanies.length > 1) {
        this.hasSubUnits = true;
      }
      this.canDeleteLoading = false;
    } catch (error) {
      if (!isCancelError(error)) {
        this.canDeleteError = this.$handleErrors(error, true);
      }
    }
  }

  @Watch('$route', { deep: true, immediate: true })
  routeChange() {
    this.load();
  }

  hideActivateUnitPopup() {
    this.activateUnitPopup = false;
    this.canEnableLoading = false;
    this.canEnableError = [];
    this.hasSubUnits = false;
  }

  hideDeactivateUnitPopup() {
    this.deactivateUnitPopup = false;
    this.canDisableLoading = false;
    this.canDisableError = [];
    this.hasSubUnits = false;
  }

  hideDeleteUnitPopup() {
    this.deleteUnitPopup = false;
    this.canDeleteLoading = false;
    this.canDeleteError = [];
    this.hasSubUnits = false;
  }

  goToManageConfigurations(id) {
    router.push({
      name: 'manage-configuration-assignment',
      params: {
        id: id,
        configurationId: id,
      }
    });
  }

  async load() {
    try {
      this.loading = true;
      this.serverErrors = [];
      const result = await ProfileCompanyApi.getCompaniesStructure(this.company!.companyId);

      if (result && result.data) {
        let sortedHierarchy: CompanyModel[] = [];
        this.prepareHierarchy(result.data, sortedHierarchy, 0, null);
        this.items = sortedHierarchy;
        this.loading = false;
      }
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  prepareHierarchy(arrayToSort, sortedArray, currentLevel, parentId) {
    if (arrayToSort && sortedArray) {
      let currentLevelItems = arrayToSort.filter(function (item) {
        if (!parentId) {
          return !item.parentId;
        } else {
          return item.parentId === parentId;
        }
      });
      if (currentLevelItems.length > 0) {
        currentLevelItems = this.sortByName(currentLevelItems);
        currentLevelItems.forEach(element => {
          element.level = currentLevel;
          sortedArray.push(element);
          this.prepareHierarchy(arrayToSort, sortedArray, currentLevel + 1, element.id);
        });
      }
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
  }

  editConfigurations(item, preventRedirect = false) {
    this.selectedUnit = item;
    if (!preventRedirect) {
      router.push({
        name: 'company-info',
        params: {
          id: item.id,
        },
        query: {
          edit: 'true',
        }
      });
    }
  }

  editConfigurationsUrl(item) {
    return sanitizeUrl('/settings/' + item.id + '/?edit=true');
  }

  toggleCompanyUnitMenu(item) {
    this.serverErrors = [];
    this.popupError = [];
    this.selectedUnit = item;
    this.menuActive = !this.menuActive;
  }

  async changeStatus(deactivate: boolean) {
    this.deactivateInProgress = true;
    try {
      let message = new CompanyChangeStatusMessage();
      message.deactivate = deactivate;
      await ProfileCompanyApi.changeCompanyStatus(
        message,
        this.selectedUnit!.id,
        Permission.WriteCompanyInfo
      );

      await this.load();
      this.hideDeactivateUnitPopup();
      this.activateUnitPopup = false;
    } catch (error) {
      if (
        error.response && error.response.data && error.response.data.error &&
        'DISABLE_OWN_PROFILE' === error.response.data.error.code
      ) {
        this.popupError = [{
          message: translate('settings-company.unable-to-disable'), 
        }];
      } else {
        this.popupError = $handleErrors(error, true);
      }
    } finally {
      this.deactivateInProgress = false;
    }
  }

  async deleteCompanyUnit() {
    this.deleteInProgress = true;
    try {
      await ProfileCompanyApi.deleteCompanyUnit(
        this.selectedUnit!.id,
        Permission.CanDeleteCompany
      );

      await this.load();
      this.hideDeleteUnitPopup();
    } catch (error) {
      this.popupError = $handleErrors(error, true);
    } finally {
      this.deleteInProgress = false;
    }
  }

  created() {
    this.selectedUnit = null;
  }
}
