import FeeRuleAir from '@/modules/settings/agency-fees/FeeRuleAir.vue';

export default {
  path: ':configurationId?/air-fee-rule',
  name: 'fee-rule-air',
  component: FeeRuleAir,
  displayName: 'Air fee rule definition',
  meta: {
    breadCrumb: 'Air fee rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditFeesConfiguration',
    parentName: 'fees',
    depthCategory: 'fees',
    depth: 3,
  }
};