import GlobalSettings from '@/modules/global-settings/GlobalSettings.vue';
import GlobalSettingsIntro from '@/modules/global-settings/GlobalSettingsIntro.vue';
import GlobalSettingsSidebar from '@/modules/global-settings/GlobalSettingsSidebar.vue';
import customMessages from './custom-messages.route';

export default {
  path: '/global-settings',
  components: {
    default: GlobalSettings,
    intro: GlobalSettingsIntro,
    sidebar: GlobalSettingsSidebar
  },
  meta: {
    introClass: 'intro-settings',
    permission: 'CanAccessGlobalSettings'
  },
  children: [
    customMessages,
  ],
};
