















































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import ProjectsStore from './projects.store';
import { ProjectsApi } from '@/api/profile/projects.api';
import { ProjectModel } from '@/api/profile/projects.model';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength  } from 'vuelidate/lib/validators';
import { Permission } from '@/const/permission.enum';
import moment from 'moment';

@Component({})
export default class ProjectDefinition extends Vue {
  $v;
  formHasErrors: boolean = false;
  currencyOptions: any[] = [];
  definition = new ProjectModel();

  @Validation()
  validationObject() {
    return {
      definition: {
        name: {
          required,
          maxLength: maxLength(64)
        },
        description: {
          maxLength: maxLength(1024)
        },
        code: {
          required,
          maxLength: maxLength(64)
        },
        validFrom: {
        },
        validTo: {
        },
        active: {
        },
      },
    };
  }

  get configuration() {
    return ProjectsStore.project;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get projectId() {
    return router.currentRoute.params.itemId;
  }

  get loading() {
    return ProjectsStore.loading;
  }

  get errors() {
    return ProjectsStore.errMessages;
  }

  get projectList() {
    return ProjectsStore.projects;
  }

  get expenseDetails() {
    return ProjectsStore.expenseDetails;
  }

  get projectObject() {
    return ProjectsStore.projectObject;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/projects/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'projects-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  @Watch('definition.validFrom')
  ondateChange() {
    if (this.definition.validTo && this.definition.validFrom && moment(this.definition.validTo).isBefore(this.definition.validFrom)) {
      this.definition.validTo = this.definition.validFrom;
    }
  }

  async saveDefinition() {
    ProjectsStore.setErrMessages([]);
    ProjectsStore.setShowError(false);
    let configId = this.configurationId;
    this.$v.definition.$touch();
    if (this.$v.definition.$pending || this.$v.definition.$error) {
      return;
    }

    ProjectsStore.setLoading(true);
    if (!configId) {
      const result = await ProjectsApi.createProjectConfiguration(
        {
          rootCompanyId: this.currentCompany!.rootCompanyId,
          name: this.configuration.name,
        },
        Permission.WriteProjects,
      );
      if (result && result.data) {
        ProjectsStore.setProjectConfigurationId(result.data.id);
      }
    }

    let request = {
      rootCompanyId: this.currentCompany!.rootCompanyId,
      configurationId: this.configurationId,
      name: this.definition.name,
      description: this.definition.description,
      code: this.definition.code,
      validFrom: this.definition.validFrom ? new Date(this.definition.validFrom) : '',
      validTo: this.definition.validTo ? new Date(this.definition.validTo) : '',
      active: this.definition.active,
    };

    const isEdit = !!this.definition.id;
    if (isEdit && this.definition.id) {
      const message = {
        projectId: this.definition.id,
        permission: Permission.WriteProjects,
        params: request,
      };
      await ProjectsStore.updateProjects(message);
    } else {
      await ProjectsStore.createProjects({params: request, permission: Permission.WriteProjects});
    }
  }

  async mapFormData() {
    if (this.projectId) {
      await ProjectsStore.getProjectObject(this.projectId);
      if (this.projectObject) {
        this.definition = new ProjectModel(this.projectObject);
      }
    }
  }

  created() {
    ProjectsStore.clearErrMessages();
    if (!this.configuration.name) {
      if (this.$route.params.configurationId === undefined) {
        router.push({
          name: 'projects-configurations',
        });
      } else {
        ProjectsStore.setProjectConfigurationId(this.$route.params.configurationId);
        ProjectsStore.getProjectConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
    }

    this.mapFormData();
  }
}
