import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { DelayTicketingConfigurationApi } from '@/api/profile/delay-ticketing-configuration.api';
import { DelayTicketingConfiguration, RuleItem, Provider } from '@/api/profile/delay-ticketing-configuration.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'delayTicketing'
})
class DelayTicketingStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  delayTicketing: DelayTicketingConfiguration = new DelayTicketingConfiguration();
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;
  providers: Provider[] = [];

  get canShowCustomError() {
    return this.customError;
  }

  get delayTicketingConfiguration() {
    return this.delayTicketing;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get delayTicketingProviders() {
    return this.providers;
  }

  @Mutation
  setDelayTicketingConfiguration(payload) {

    this.delayTicketing = {
      ...payload,
      rules: payload.rules.length ? payload.rules : [
        new RuleItem(),
      ]
    };
  }

  @Mutation
  setName(value) {
    this.delayTicketing.name = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setCopy(value) {
    this.isCopy = value;
  }

  @Mutation
  setProviders(value) {
    this.providers = value;
  }

  @Mutation
  clearData() {
    this.delayTicketing = new DelayTicketingConfiguration();
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async getDelayTicketingConfiguration(currentSelectedId) {
    this.setLoading(true);
    this.setShowError(false);
    this.setCustomError(false);
    if (currentSelectedId) {
      try {
        const result = await DelayTicketingConfigurationApi.getConfiguration(currentSelectedId);
        if (result && result.data) {
          this.setDelayTicketingConfiguration(result.data);
          if (this.isCopy) {
            this.setName(null);
          }
          this.setLoading(false);
        }
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          this.setCustomError(true);
        } else {
          this.setErrMessages($handleErrors(error, true));
          this.setShowError(true);
        }
      } finally {
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async addDelayTicketingConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result = await DelayTicketingConfigurationApi.createConfiguration(data.params);
      if (result && result.data) {
        this.setLoading(false);
        this.setConfigurationId(result.data.id);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-delay-ticketing.config-saved')
        });

        this.getDelayTicketingConfiguration(result.data.id);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateDelayTicketingConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    if (data.configurationId) {
      try {
        await DelayTicketingConfigurationApi.updateConfiguration(data.configurationId, data.params);
        this.setLoading(false);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-delay-ticketing.config-saved')
        });

      } catch (error) {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      } finally {
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async getDelayTicketingProviders() {
    this.setProviders([]);
    try {
      const result = await DelayTicketingConfigurationApi.getProviders();
      if (result && result.data) {
        this.setProviders(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    }
  }
}

export default getModule(DelayTicketingStore);
