import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  TravelAssignmentModel,
  CreateTravelAssignmentMessage,
  CreateTravelAssignmentResponse,
  TravelAssignmentDetails,
} from './custom-fields.model';

export class CustomFieldTravelAssignmentsApiClass {
  public getTravelAssignments(configurationId: string): Promise<AxiosResponse<TravelAssignmentModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/assignments/items/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public getTravelAssignment(id: string): Promise<AxiosResponse<TravelAssignmentDetails>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/assignments/{travelCustomFieldAssignmentId}',
      method: RequestMethod.GET
    }, {
      travelCustomFieldAssignmentId: id,
    }, {});
  }

  public createTravelAssignment(request: CreateTravelAssignmentMessage): Promise<AxiosResponse<CreateTravelAssignmentResponse>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/assignments',
      method: RequestMethod.POST
    }, {}, { ...request });
  }

  public updateTravelAssignment(
    id: string,
    request: CreateTravelAssignmentMessage
  ): Promise<AxiosResponse<CreateTravelAssignmentResponse>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/assignments/{travelCustomFieldAssignmentId}',
      method: RequestMethod.PUT
    }, {
      travelCustomFieldAssignmentId: id,
    }, { ...request });
  }

  public deleteTravelAssignment(id: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFields/assignments/{travelCustomFieldAssignmentId}',
      method: RequestMethod.DELETE
    }, {
      travelCustomFieldAssignmentId: id,
    }, {});
  }
}

export const CustomFieldTravelAssignmentsApi: CustomFieldTravelAssignmentsApiClass
  = new CustomFieldTravelAssignmentsApiClass();