import TravelPolicyConfigurations from '@/modules/settings/travel-policy/configurations/TravelPolicyConfigurations.vue';

export default {
  path: '',
  name: 'travel-policy-configurations',
  component: TravelPolicyConfigurations,
  displayName: 'Travel Policy Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadTravelPolicy',
    parentName: 'travel-policy-configurations',
    depthCategory: 'travel-policy',
    waitForDicts: true,
    depth: 1,
  }
};
