













import { Vue, Component, Prop } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';
import searchConst from '@/const/search.const';
import { userFullName } from '@/core/user-full-name';

@Component({})
export default class UiAvatar extends Vue {
  @Prop() user!: any;
  @Prop({ default: '' }) variant!: string;
  @Prop({ default: false }) selected!: boolean;
  @Prop() isGuest!: boolean;
  @Prop() guestCode!: string;
  @Prop() tooltipAdditions!: string[];
  userAvatarValue: number = 0;

  get displayImg() {
    let userAvatar: any = '';
    if (this.user) {
      userAvatar = AccountStore.avatarCollection.find((element) => {
        return element.id === this.user.id;
      });
    }
    return userAvatar ? userAvatar.avatar : userAvatar;
  }

  get avatarClasses() {
    return {
      avatar: '' === this.variant,
      'avatar avatar--small': 'small' === this.variant,
      'avatar avatar--extra-small': 'extra-small' === this.variant,
      'avatar selected': this.selected === true,
      'avatar avatar-unavailable': !this.displayImg,
    };
  }

  get initials() {
    return this.user
      ? (this.user.firstName && this.user.lastName
        ? this.user.firstName.charAt(0) + this.user.lastName.charAt(0)
        : (this.user.passengerTypeCode ? this.user.passengerTypeCode : (this.user.travellerType ? this.user.travellerType : '')))
      : '';
  }

  get isMainTraveller() {
    return this.selected;
  }

  get fullName() {
    return userFullName(this.user);
  }

  tooltip() {
    
    if (!this.isGuest) {
      let tooltip;
      if (this.isMainTraveller) {
        tooltip = `<span class="tooltip-bold">${translate('basket.main-traveller')} </span>
          <span>${this.fullName}</span>
          <span class="tooltip-italic">${this.user.businessUnitName ?
          this.user.businessUnitName : ''}</span>`;
      } else {
        tooltip = `<span>${this.fullName}</span>
          <span class="tooltip-italic">${this.user.businessUnitName ?
          this.user.businessUnitName : ''}</span>`;
      }
      if (this.tooltipAdditions) {
        this.tooltipAdditions.forEach(addition => {
          tooltip += `<span>${addition}</span>`;
        });
      }
      return tooltip;
    } else {
      let option = searchConst.guestTravellerOptions.find(option => {
        return option.code === this.guestCode;
      });
      if (option) {
        return `<span>${translate(option.label)}</span>`;
      }
    }
  }

  async mounted() {
    if (this.user) {
      let id = this.user.id || this.user.profileId;
      let userAvatar = AccountStore.avatarCollection.find((element) => {
        return element.id === id;
      });
      if (userAvatar) {
        AccountStore.setProfileAvatar({
          id: userAvatar.id,
          avatar: userAvatar.avatar,
          usageTimestamp: new Date().getTime(),
        });
      } else if (!userAvatar && id) {
        let avatarResponse = await AccountStore.getAvatar(id);
        AccountStore.setProfileAvatar({
          id,
          avatar: avatarResponse || '',
          usageTimestamp: new Date().getTime(),
        });
      }
    }
  }
}

