














































































































































import { Vue, Component } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import PnrRemarksStore from './pnr-remarks.store';
import { CreateConfiguration } from '@/api/pnr-remarks/pnr-remarks.model';
import { router } from '@/router';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import PnrRemarksList from './PnrRemarksList.vue';
import PnrRemarksSsrCodes from './PnrRemarksSsrCodes.vue';
import { SupplierName } from '@/services/supplier-name.service';

@Component({
  components: {
    PnrRemarksList,
    PnrRemarksSsrCodes,
  }
})
export default class PnrRemarksConfiguration extends Vue {
  $v;
  errors: any[] = [];
  configsLoaded: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';  
  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight')
    },
    {
      value: 'Accommodation',
      name: translate('settings-travel-policy.accomodation')
    },
    {
      value: 'Car',
      name: translate('settings-travel-policy.car')
    }
  ];
  providerOptions = {
    Flight: [
      {
        value: 'Sabre',
        label: SupplierName('Sabre'),
      },
      {
        value: 'Amadeus',
        label: SupplierName('Amadeus'),
      },
    ],
    Car: [
      {
        value: 'Sabre',
        label: SupplierName('Sabre'),
      },
      {
        value: 'Amadeus',
        label: SupplierName('Amadeus'),
      },
    ],
    Accommodation: [
      {
        value: 'Sabre',
        label: SupplierName('Sabre'),
      },
    ]
  };

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(64)
      },
      remarks: {
        requiredOfEveryType: () => {
          return this.sufficientRemarks();
        }
      },
    };
  }

  get uniqueIdPrefix() {
    return PnrRemarksStore.uniqueIdPrefix;
  }

  get links() {
    return this.navLinks;
  }

  get loading() {
    return PnrRemarksStore.loading;
  }

  get configurationId() {
    return this.$route.params.configurationId || '';
  }

  get storeConfigurationId() {
    return PnrRemarksStore.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return PnrRemarksStore.currentConfigurationName;
  }

  set currentConfigurationName(value) {
    PnrRemarksStore.setCurrentConfigurationName(value);
  }
  
  get configIsEdit() {
    return !!(this.configurationId);
  }

  get selectedTab() {
    return PnrRemarksStore.selectedTab;
  }

  get airRemarks() {
    return PnrRemarksStore.airRemarks;
  }

  get carRemarks() {
    return PnrRemarksStore.carRemarks;
  }

  get accommodationRemarks() {
    return PnrRemarksStore.accommodationRemarks;
  }

  get hasWritePermission() {
    return this.$hasAccess('WritePnrRemarks');
  }

  get canShowCustomError() {
    return PnrRemarksStore.canShowCustomError;
  }

  get carsErrMessages() {
    return PnrRemarksStore.carsErrMessages;
  }

  get accoErrMessages() {
    return PnrRemarksStore.accoErrMessages;
  }

  get flightErrMessages() {
    return PnrRemarksStore.flightErrMessages;
  }



  sufficientRemarks() {
    const airRemarksExists = this.airRemarks && this.airRemarks.length > 0;
    const remarks = [...this.airRemarks, ...this.carRemarks, ...this.accommodationRemarks];
    
    for (const remark of remarks) {
      const hasPnrRemarks = remark.pnrRemarks && remark.pnrRemarks.length;

      if (
        (hasPnrRemarks && ((!airRemarksExists && remark.pnrRemarks.some(item => !!(item.remark))) ||
          (airRemarksExists && remark.pnrRemarks.some(item => !!(item.remark)) && remark.pnrRemarks.some(item => !!(item.type))))) ||
        (remark.pnrSsrCodes && remark.pnrSsrCodes.length && remark.pnrSsrCodes.some(item => !!(item.type)))
      ) {
        return true;
      }
    }
    return false;
}


  getProviderOptions(tab) {
    return this.providerOptions[tab];
  }

  get isAirNewRemarkDisabled() {
    let disabled = false;
    const airRemarksComponent = this.$refs.airRemarksComponent as any[];

    if (!airRemarksComponent || !airRemarksComponent.length) {
      return disabled;
    }

    airRemarksComponent.forEach((airRemark) => {
      if (airRemark.newRemarkDisabled()) {
        disabled = true;
      }
    });
    return disabled;
  }

  get isAirNewCodeDisabled() {
    let disabled = false;
    const airPnrSsrCodesComponent = this.$refs.airPnrSsrCodesComponent as any[];

    if (!airPnrSsrCodesComponent || !airPnrSsrCodesComponent.length) {
      return disabled;
    }

    airPnrSsrCodesComponent.forEach((airCode) => {
      if (airCode.newCodeDisabled()) {
        disabled = true;
      }
    });
    return disabled;
  }

  get isCarNewRemarkDisabled() {
    let disabled = false;
    const carRemarksComponent = this.$refs.carRemarksComponent as any[];

    if (!carRemarksComponent || !carRemarksComponent.length) {
      return disabled;
    }

    carRemarksComponent.forEach((carRemark) => {
      if (carRemark.newRemarkDisabled()) {
        disabled = true;
      }
    });
    return disabled;
  }

  get isAccommodationNewRemarkDisabled() {
    let disabled = false;
    const accommodationRemarksComponent = this.$refs.accommodationRemarksComponent as any[];

    if (!accommodationRemarksComponent || !accommodationRemarksComponent.length) {
      return disabled;
    }

    accommodationRemarksComponent.forEach((accommodationRemark) => {
      if (accommodationRemark.newRemarkDisabled()) {
        disabled = true;
      }
    });
    return disabled;
  }


  changeSelectedTab(value) {
    switch (this.selectedTab) {
      case 'Flight':
        if (!this.isAirNewRemarkDisabled || !this.isAirNewCodeDisabled) {
          PnrRemarksStore.setSelectedTab(value);
        }
        break;
      case 'Car':
        if (!this.isCarNewRemarkDisabled) {
          PnrRemarksStore.setSelectedTab(value);
        }
        break;
      case 'Accommodation':
        if (!this.isAccommodationNewRemarkDisabled) {
          PnrRemarksStore.setSelectedTab(value);
        }
        break;
    }
  }

  backToConfigurationsList() {
    router.push({
      name: 'pnr-remarks-configurations',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  mapRemarksForRequest(remarksArray: any[]) {
    return remarksArray.map(e => {
      let object = e;
      if (!object.remark) {
        return null;
      } else if (object.id.includes(this.uniqueIdPrefix)) {
        delete object.id; 
      }
      return object;
    }).filter(Boolean);
  }

  resetValidations() {
    let air = this.$refs.airRemarksComponent as any[];
    let car = this.$refs.carRemarksComponent as any[];
    let accommodataion = this.$refs.accommodationRemarksComponent as any[];
    let airCodes = this.$refs.airPnrSsrCodesComponent as any[];

    if (air && air.length) {
      air.forEach((airRemark) => {
        airRemark.$v.$reset();
      });
    }

    if (airCodes && airCodes.length) {
      airCodes.forEach((airCode) => {
        airCode.$v.$reset();
      });
    }

    if (car && car.length) {
      car.forEach((carRemark) => {
        carRemark.$v.$reset();
      });
    }

    if (accommodataion && accommodataion.length) {
      accommodataion.forEach((accommodataionRemark) => {
        accommodataionRemark.$v.$reset();
      });
    }
    this.$v.$reset();
  }
        
  validate() {
    this.$v.$touch();
    let air = this.$refs.airRemarksComponent as any[];
    let car = this.$refs.carRemarksComponent as any[];
    let accommodataion = this.$refs.accommodationRemarksComponent as any[];
    let airCodes = this.$refs.airPnrSsrCodesComponent as any[];

    let airError = false;
    let carError = false;
    let accommodataionError = false;
    let airCodesError = false;

    if (air && air.length) {
      air.forEach((airRemark) => {
        airRemark.$v.$touch();
        if (airRemark.$v.$error) {
          airError = airRemark.$v.$error;
        }
      });
    }

    if (airCodes && airCodes.length) {
      airCodes.forEach((airCode) => {
        airCode.$v.$touch();
        if (airCode.$v.$error) {
          airCodesError = airCode.$v.$error;
        }
      });
    }

    if (car && car.length) {
      car.forEach((carRemark) => {
        carRemark.$v.$touch();
        if (carRemark.$v.$error) {
          carError = carRemark.$v.$error;
        }
      });
    }
    
    if (accommodataion && accommodataion.length) {
      accommodataion.forEach((accommodataionRemark) => {
        accommodataionRemark.$v.$touch();
        if (accommodataionRemark.$v.$error) {
          accommodataionError = accommodataionRemark.$v.$error;
        }
      });
    }

    return !(this.$v.$error || airError || carError || accommodataionError || airCodesError);
  }

  configMap(remark) {
    return JSON.parse(JSON.stringify(remark)).map(e => {
      return {
        supplier: e.supplier,
        pnrRemarks: this.mapRemarksForRequest(e.pnrRemarks),
        pnrSsrCodes: e.pnrSsrCodes
      };
    });
  }

  async validateAndProceed() {
    let valid = this.validate();

    if (valid) {

      let air: CreateConfiguration = {
        rootCompanyId: this.$route.params.id,
        name: this.currentConfigurationName,
        configurations: this.configMap(this.airRemarks),
      };

      let car: CreateConfiguration = {
        name: this.currentConfigurationName,
        configurations: this.configMap(this.carRemarks),
      };

      let accommodation: CreateConfiguration = {
        name: this.currentConfigurationName,
        configurations: this.configMap(this.accommodationRemarks),
      };

      if (this.configurationId) {
        delete air.rootCompanyId;
        await PnrRemarksStore.updateAll({ air, car, accommodation });
      } else {
        await PnrRemarksStore.createAll({ air, car, accommodation });
      }
      this.resetValidations();
    }
  }

  async created() {
    PnrRemarksStore.setErrMessages([]);
    if (this.configurationId) {
      await PnrRemarksStore.getAll(this.configurationId);
    }
    this.configsLoaded = true;
  }
}
