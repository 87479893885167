














import { Vue, Component } from 'vue-property-decorator';
import SearchStore from '@/modules/search/search.store';
import AirSearchStore from './air-search.store';
import AirExchangeModifiedOffer from './AirExchangeModifiedOffer.vue';
import AirSearchExchangeShortParams from './AirSearchExchangeShortParams.vue';

@Component({
  components: {
    AirExchangeModifiedOffer,
    AirSearchExchangeShortParams,
  }
})
export default class AirExchangeModifyView extends Vue {

  get exchangedOffer() {
    return AirSearchStore.getExchangedOffer;
  }

}
