















import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate, translationExists } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component
export default class BasketItemAdditionalMessage extends Vue {
  @Prop() message!: any;

  get linkExists() {
    return this.message.url !== null;
  }

  get link() {
    return sanitizeUrl(this.message.url);
  }

  get messageLines() {
    const prefix = 'basket-additional-messages.' + this.message.code + '_';
    let counter = 1;
    let array: string[] = [];

    while (translationExists(prefix + counter)) {
      array.push(translate(prefix + counter));
      counter++;
    }

    return array;
  }
}

