import ExpenseList from '@/modules/expense/my-expenses/ExpenseList.vue';
import ExpenseSubmenu from '@/modules/expense/ExpenseSubmenu.vue';
import ExpenseIntro from '@/modules/expense/ExpenseIntro.vue';
import ExpenseListMessages from'@/modules/expense/my-expenses/ExpenseListMessages.vue';

export default {
  path: '/for-mission/:missionId',
  name: 'expense-list-filtered',
  components: {
    default: ExpenseList,
    submenu: ExpenseSubmenu,
    messages: ExpenseListMessages,
  },
  meta: {
    permission: 'Client'
  }
};