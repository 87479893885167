







































































import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';

const roundedRectangle = (x, y, width, height, roundness) => {
  return [
    'M ' + x + ' ' + (y + roundness),
    'C ' + x + ' ' + y + ', ' + x + ' ' + y + ', ' + (x + roundness) + ' ' + y,
    'L ' + (x + width - roundness) + ' ' + y,
    'C ' + (x + width) + ' ' + y + ', ' + (x + width) + ' ' + y + ', ' + (x + width) + ' ' + (y + roundness),
    'L ' + (x + width) + ' ' + (y + height - roundness),
    'C ' + (x + width) + ' ' + (y + height) + ', ' + (x + width) + ' ' + (y + height) + ', ' + (x + width - roundness) + ' ' + (y + height),
    'L ' + (x + roundness) + ' ' + (y + height),
    'C ' + x + ' ' + (y + height) + ', ' + x + ' ' + (y + height) + ', ' + x + ' ' + (y + height - roundness),
    'z',
  ].join(' ');
};

@Component({})
export default class TrainSeatIcon extends Vue {
  @Prop({ default: null }) item!: any | null;
  @Prop({ default: null }) currentTraveller!: any | null;

  roundness = 10;
  margin = 3;

  get currentTravellerId() {
    if (!this.currentTraveller) {
      return '';
    }

    return this.currentTraveller.id;
  }

  get seatPath() {
    return roundedRectangle(
      this.margin - this.width / 2,
      this.margin - this.height / 2,
      this.width - 2 * this.margin,
      this.height - 2 * this.margin,
      this.roundness
    );
  }

  get backPath() {
    return roundedRectangle(
      this.margin - this.width / 2,
      this.height - this.margin - 5 - this.height / 2,
      this.width - 2 * this.margin,
      5,
      this.margin
    );
  }

  get leftArmPath() {
    return roundedRectangle(
      this.margin - this.width / 2,
      -this.height / 6,
      3,
      this.height * 2 / 3 - this.margin,
      this.margin
    );
  }

  get rightArmPath() {
    return roundedRectangle(
      this.width / 2 - this.margin - 3,
      -this.height / 6,
      3,
      this.height * 2 / 3 - this.margin,
      this.margin
    );
  }

  get viewBox() {
    return (-this.width / 2) + ' ' + (-this.height / 2) + ' ' + this.width + ' ' + this.height;
  }

  get transf() {
    if (!this.item || !this.item.angle) {
      return '';
    }
    const num = this.item.angle;
    return 'rotate(' + num + ')';
  }

  get x1idx() {
    return this.item.x;
  }

  get x2idx() {
    return this.item.x + this.item.width;
  }

  get y1idx() {
    return this.item.y;
  }

  get y2idx() {
    return this.item.y + this.item.height;
  }

  get x() {
    return consts.margin + this.x1idx * consts.colWidth + consts.thickness - this.width / 2;
  }

  get y() {
    return consts.margin + this.y1idx * consts.rowHeight + consts.thickness - this.height / 2;
  }

  get width() {
    return Math.max(0, (this.x2idx - this.x1idx) * consts.colWidth - 2 * consts.thickness);
  }

  get height() {
    return Math.max(0, (this.y2idx - this.y1idx) * consts.rowHeight - 2 * consts.thickness);
  }

  get chargable() {
    if (!this.item || !this.item.prices) {
      return false;
    }
    if ('Open' !== this.item.seatAvailability) {
      return false;
    }
    const el = this.item.prices
      .filter(price => price.travellerId === this.currentTravellerId)
      .find(price => {
        return price.price && price.price.amount && price.price.amount > 0;
      });

    if (el) {
      return true;
    }
    return false;
  }
}
