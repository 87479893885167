




















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { PreferencesModel } from '@/api/profile/preferences.model';
import CarPreferencesPopup from './CarPreferencesPopup.vue';
import _ from 'lodash';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    CarPreferencesPopup,
  }
})
export default class CarPreferencesSection extends Vue {
  @Prop() preferences!: PreferencesModel;
  openPopup: boolean = false;

  editCar() {
    this.openPopup = !this.openPopup;
  }

  closePopup() {
    this.openPopup = !this.openPopup;
    EventBus.$emit('refresh-preferences');
  }
}
