import { BaseTableParams } from '@/core/base-table-params';
import {  } from '@/api/expense/expense.model';

export class MyMissionsTableParams extends BaseTableParams {

  profileId: string = '';
  policyId: string = '';
  approverId: string = '';
  companyId: string = '';
  businessUnitId: string = '';

  public constructor(params: any) {
    super(params);
  }
}
