import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { CarProviderConfigurations } from './car-provider-configuration.model';

class CarProviderConfigurationSetApiClass {
  public create(params: CarProviderConfigurations): Promise<AxiosResponse<CarProviderConfigurations>> {
    return http.execute({
      path: settings.apiCarEngine + '/configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public getById(configurationId): Promise<AxiosResponse<CarProviderConfigurations>> {
    return http.execute({
      path: settings.apiCarEngine + '/configurations/{configurationId}',
      method: RequestMethod.GET
    }, {
        configurationId
    }, {});
  }

  public getEmpty(): Promise<AxiosResponse<CarProviderConfigurations>> {
    return http.execute({
      path: settings.apiCarEngine + '/configurations/empty',
      method: RequestMethod.GET
    }, {}, {});
  }

  public update(configurationId: string, params: CarProviderConfigurations): Promise<AxiosResponse<CarProviderConfigurations>> {
  return http.execute({
      path: settings.apiCarEngine + '/configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, {configurationId}, params);
  }
}

export const CarProviderConfigurationSetApi: CarProviderConfigurationSetApiClass = new CarProviderConfigurationSetApiClass();