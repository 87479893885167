




import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { PaymentCardMainType, PaymentCardType } from '@/api/profile/payment-cards.model';
import BasketStore from '@/modules/basket/basket.store';

@Component({})
export default class UiCreditCardLogo extends Vue {
  @Prop() creditNumber!: string;

  visaRegExPrefix: RegExp = /^4/;
  masterCardRegExPrefix: RegExp = /^(5[1-5]|2(22[1-9]|2[3-9]|[3-6]|7[0-1]|720))/;
  amexpRegExPrefix: RegExp = /^3[4,7]/;
  discovRegExPrefix: RegExp = /^(6011|65|64[4-9]|622)/;
  dinnersRegExPrefix: RegExp = /^(300|301|302|303|304|305|309|36|38|39)/;
  maestroRegExPrefix: RegExp = /^(5018|5020|5038|5893|6304|6759|6761|6762|6763)/;
  uatpRegExPrefix: RegExp = /^1/;
  selectedCreditCard: string = '';

  get displayImg() {
    return `/assets/img/credit-cards/${this.selectedCreditCard}.png`;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  assignCreditCard() {
    if (this.creditNumber) {
      let cardMainType: PaymentCardMainType = PaymentCardMainType.Unknown;
      let cardNumbersPrefix = this.creditNumber.substring(0, 4);
      let isVisa = this.visaRegExPrefix.test( cardNumbersPrefix ) === true;
      let isMaster = this.masterCardRegExPrefix.test( cardNumbersPrefix ) === true;
      let isAmex = this.amexpRegExPrefix.test( cardNumbersPrefix ) === true;
      let isDisc = this.discovRegExPrefix.test( cardNumbersPrefix ) === true;
      let isDinner = this.dinnersRegExPrefix.test( cardNumbersPrefix ) === true;
      let isMaestro = this.maestroRegExPrefix.test( cardNumbersPrefix ) === true;
      let isUatp = this.uatpRegExPrefix.test( cardNumbersPrefix ) === true;

      if (isVisa) {
        cardMainType = PaymentCardMainType.Visa;
        this.selectedCreditCard = 'visa';
      } else if (isMaster) {
        cardMainType = PaymentCardMainType.MasterCard;
        this.selectedCreditCard = 'maste_card';
      } else if (isAmex) {
        cardMainType = PaymentCardMainType.AmericanExpress;
        this.selectedCreditCard = 'american_express';
      } else if (isDisc) {
        cardMainType = PaymentCardMainType.Discover;
        this.selectedCreditCard = 'discover_card';
      } else if (isDinner) {
        cardMainType = PaymentCardMainType.DinersClub;
        this.selectedCreditCard = 'diners_club_card';
      } else if (isMaestro) {
        cardMainType = PaymentCardMainType.Maestro;
        this.selectedCreditCard = 'maestro';
      } else if (isUatp) {
        cardMainType = PaymentCardMainType.UATP;
        this.selectedCreditCard = 'UATP';
      } else {
        this.selectedCreditCard = 'not_found';
      }

      this.$emit('selectedCard', cardMainType);
    }
  }

  mounted() {
    this.assignCreditCard();
  }

  @Watch('bookingStep')
  onBookingStepChange() {
    this.$nextTick(() => {
      this.assignCreditCard();
    });
  }

  @Watch('creditNumber')
  onUserChanged() {
    this.assignCreditCard();
  }
}
