import AirResults from '@/modules/search/air/AirResults.vue';
import AirSearchShortParams from '@/modules/search/air/AirSearchShortParams.vue';
import AirSubintro from '@/modules/search/air/AirSubintro.vue';
import AirResultsFilters from '@/modules/search/air/AirResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/air-results/:searchId',
  name: 'air',
  components: {
    default: AirResults,
    intro: AirSearchShortParams,
    sidebar: AirResultsFilters,
    subintro: AirSubintro,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SearchOffers',
    preserveSidebar: {
      airDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airDetails: AnimationName.DOWN,
        airTravellers: AnimationName.DOWN,
      },
    }
  }
};
