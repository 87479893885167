export default async (to, from, next) => {
  if (!to.matched.length || ('payment-verification-completed' !== to.params.id && 'payment-verification-completed-v2' !== to.params.id)) {
    next();
    return;
  }

  const defaultRedirect = (redirect) => {
    if (!from.matched.length) {
      redirect.replace = true;
    }
    next(redirect);
  };

  if (1 === to.matched.length) {
    if (to.query.recommendationId) {
      const redirect = {
        path: '/payment-verification-completed?recommendationId=' + to.query.recommendationId + '&paymentMethodId=' + to.query.paymentMethodId,
      };
      defaultRedirect(redirect);
      return;
    } else if (to.query.tripItemId) {
      const redirect = {
        path: '/payment-verification-completed?tripItemId=' + to.query.tripItemId + '&paymentMethodId=' + to.query.paymentMethodId,
      };
      defaultRedirect(redirect);
      return;
    }
  }
};