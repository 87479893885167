





























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import { CompanyModel } from '@/api/profile/company.model';

const toggleMenuEvent = 'update:toggle';
const toggleAddChild = 'update:toggleAddChild';
const toggleEditCompany = 'update:toggleEditCompany';
const toggleDeactivateUnit = 'update:toggleDeactivateUnit';
const toggleActivateUnit = 'update:toggleActivateUnit';
const toggleManageConfigurations = 'update:toggleManageConfigurations';
const toggleDeleteUnit = 'update:toggleDeleteUnit';

@Component
export default class CompanyActionMenu extends Vue {
  @Prop() toggle!: boolean;
  @Prop() company!: CompanyModel | null;

  get toggleMenu() {
    return this.toggle;
  }

  set toggleMenu(value: boolean) {
    this[toggleMenuEvent](value);
  }

  get canDeleteCompanies() {
    return this.$hasAccess('CanDeleteCompany');
  }

  get isDeleteDisabled() {
    if (!this.company) {
      return true;
    }
    return this.company.isRemovable !== true;
  }

  @Emit()
  [toggleMenuEvent](val) {
    return val;
  }

  hide() {
    this.toggleMenu = false;
  }

  @Emit()
  [toggleAddChild](val) {
    return val;
  }

  addChild() {
    this[toggleAddChild](true);
    this.hide();
  }

  @Emit()
  [toggleDeleteUnit](val) {
    return val;
  }

  deleteUnit() {
    this[toggleDeleteUnit](true);
    this.hide();
  }

  @Emit()
  [toggleDeactivateUnit](val) {
    return val;
  }

  deactivateUnit() {
    this[toggleDeactivateUnit](true);
    this.hide();
  }

  @Emit()
  [toggleActivateUnit](val) {
    return val;
  }

  activateUnit() {
    this[toggleActivateUnit](true);
    this.hide();
  }

  @Emit()
  [toggleEditCompany](val) {
    return val;
  }

  editCompany() {
    this[toggleEditCompany](true);
    this.hide();
  }

  @Emit()
  [toggleManageConfigurations](val) {
    return val;
  }

  manageConfiguration() {
    this[toggleManageConfigurations](true);
    this.hide();
  }
}
