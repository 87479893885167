

































































import { Vue, Component } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { ProfileApi } from '@/api/profile/profile.api';
import { userFullName } from '@/core/user-full-name';
import EventHandler from '@/services/event-handler';
import ProfileStore from '@/modules/profile/profile.store';
import { DesignatedAccessType } from '@/api/approval-workflow/approval-workflow.model';

@Component({})
export default class ManagerAssignment extends Vue {
  serverErrors: any[] = [];
  formPending: boolean = false;
  isLoading: boolean = false;
  isEditableFlag: boolean = true;
  manager: any = null;
  options: any[] = [];
  isVisible: boolean = false;



  get isEditable() {
    return this.isEditableFlag && this.$hasAccess('CanAssignManagerToProfile');
  }

  get canShowSection() {
    return this.isVisible &&
      this.$hasAccess('CanViewManagerToProfile');
  }



  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async findManager(phrase: string) {
    const profileId = this.$route.params.id;
    if (!phrase.length) {
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    this.options = [];
    try {
      const response = await ProfileApi.searchProfileManagers(profileId, phrase);
      this.options = response.data;
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
    this.isLoading = false;
  }

  userFullName(value) {
    return userFullName(value);
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  async saveManager() {
    this.formPending = true;
    try {
      await ProfileApi.updateProfileManager(this.$route.params.id, this.manager ? this.manager.id : null);

      EventHandler.$emit('show-toast', {
        type: 'success',
        title: translate('common.success'),
        message: translate('common.data-saved'),
      });
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }



  async created() {
    this.formPending = true;
    ProfileStore.setHasAccessToManagerAssignment(false);
    try {
      const response = await ProfileApi.getProfileManager(this.$route.params.id);

      this.isVisible = response.data.accessLevel !== DesignatedAccessType.NoAccess;
      ProfileStore.setHasAccessToManagerAssignment(this.isVisible);
      const manager = response.data.manager;
      if (manager) {
        this.manager = {
          ...manager,
          id: manager.managerId,
        };
      }
    } catch (error) {
      this.isEditableFlag = false;
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }
}

