

































































































































































































































































import { Component } from 'vue-property-decorator';
import { router } from '@/router';
import settingsStore from '../settings.store';
import rolesStore from './roles.store';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { RolesDataRow } from './roles.model';
import { RoleModel, RoleOriginFilter, RoleUsageScopeFilter } from '@/api/roles/roles.model';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { translate } from '@/i18n';
import { RolesApi } from '@/api/roles/roles.api';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class Roles extends BaseTable<RolesDataRow> {
  useQueryParams: boolean = true;
  roleToDelete: RoleModel | null = null;
  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    page: 1,
    usePageParam: true,
  });
  sortDesc = false;
  fields = {
    name: {
      label: translate('settings-roles.table-name'),
      sortable: false,
    },
    actions: {
      sortable: false,
      label: '',
      class: 'roles-table__actions-column',
    }
  };
  selectedItem: any | null = null;
  menuActive: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  roleUsageScopeFilterOptions: any[] = [
    {
      name: translate('settings-community.all'),
      value: RoleUsageScopeFilter.all,
    },
    {
      name: translate('settings-roles.role-filter-usage-scope-internal'),
      value: RoleUsageScopeFilter.internal,
    },
    {
      name: translate('settings-roles.role-filter-usage-scope-global'),
      value: RoleUsageScopeFilter.global,
    },
  ];
  roleOriginFilterOptions: any[] = [
    {
      name: translate('settings-community.all'),
      value: RoleOriginFilter.all,
    },
    {
      name: translate('settings-roles.role-filter-type-of-definition-defined-by-agency'),
      value: RoleOriginFilter.definedByAgency,
    },
    {
      name: translate('settings-roles.role-filter-type-of-definition-defined-by-customer'),
      value: RoleOriginFilter.definedByCustomer,
    },
  ];
  Form: any = {
    searchRole: '',
    roleUsageScopeFilter: this.roleUsageScopeFilterOptions[0],
    roleOriginFilter: this.roleOriginFilterOptions[0]
  };

  get perPageOptions() {
    return [
      5,
      10,
      15,
      20,
      30,
      40,
      50,
      100
    ];
  }

  get isLoaded() {
    return rolesStore.IsLoaded;
  }

  get isLoading() {
    return rolesStore.IsLoading;
  }

  get roles() {
    return rolesStore.Roles;
  }

  get roleDetails() {
    return rolesStore.RoleDetails;
  }

  get Errors() {
    return rolesStore.errors;
  }

  get showModal() {
    return rolesStore.showRemoveModal;
  }

  set showModal(value) {
    rolesStore.setRemoveModalVisibility(value);
  }

  get isAgency() {
    return !!settingsStore.isAgency;
  }

  get isInRootBusinessUnit() {
    return settingsStore.currentCompany ? settingsStore.currentCompany.isRoot : false;
  }

  get count() {
    return this.roles.length;
  }

  get companyId() {
    return rolesStore.companyId;
  }

  get currentCompany() {
    return settingsStore.currentCompany;
  }

  get createRoleUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/roles/create-role');
  }

  editRoleUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/roles/' + item.roleId);
  }

  editRole(role: RoleModel) {
    router.push({
      name: 'edit-role',
      params: {
        roleId: role.roleId,
      },
    });
  }

  toggleDropdown(role) {
    if (role !== this.selectedItem) {
      this.selectedItem = role;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  async selectRole(roleId: string) {
    await rolesStore.selectRole(roleId);
  }

  showRemoveRole(role: RoleModel) {
    this.roleToDelete = role;
    this.menuActive = false;
    rolesStore.resetErrors();
    rolesStore.setRemoveModalVisibility(true);
  }

  closeRemoveRole() {
    rolesStore.setRemoveModalVisibility(false);
  }

  async removeRole(role: RoleModel) {
    await rolesStore.removeRole(role);
    await this.submitForm();
  }

  isGlobalRole(role: RoleModel) {
    return role.usageScope === 'Global';
  }

  isInternalRole(role: RoleModel) {
    return role.usageScope === 'Internal';
  }

  async markAsVisibleForCustomer(role: RoleModel) {
    this.menuActive = false;
    await RolesApi.markAsVisibleForCustomer(role.roleId);
    role.usageScope  = 'Global';  
    this.$forceUpdate();
    this.refreshDataAfterVisibilityChange();
  }

  async markAsInvisibleForCustomer(role: RoleModel) {
    this.menuActive = false;
    await RolesApi.markAsInvisibleForCustomer(role.roleId);
    role.usageScope = 'Internal';
    this.$forceUpdate();
    this.refreshDataAfterVisibilityChange();
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getRoles() {
    this.submitForm();
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  refreshDataAfterVisibilityChange() {
    if (this.Form.roleUsageScopeFilter.value !== RoleUsageScopeFilter.all) {
      this.resetList(this.currentPage);
      this.reload();
    }
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems(params: any) {
    rolesStore.startLoading();

    try {

      const filter = {
        companyId: this.companyId,
        phrase: this.Form.searchRole,
        roleUsageScope: this.Form.roleUsageScopeFilter.value,
        roleOrigin: this.Form.roleOriginFilter.value,
        pagination: {
          pageNo: this.params.page,
          pageSize: this.params.size 
        }
      };

      const profilesResult = await RolesApi.getCompanyRoles(filter);
      if (profilesResult && profilesResult.data) {
        rolesStore.setCompanyRoles(profilesResult.data.results);
        this.params.size = profilesResult.data.page.pageSize;
        rolesStore.loadingSuccess();
        return {
          page: profilesResult.data.page,
          results: profilesResult.data.results,
        };
      }
    } catch (error) {
      rolesStore.setErrors({ error, isForm: true });
      rolesStore.loadingSuccess();
    } finally {
      rolesStore.finishLoading();
    }
  }

  created() {
    rolesStore.setCompanyRoles([]);
  }
}
