import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { DisplayPreferencesApi } from '@/api/profile/display-preferences.api';
import { FlightRuleCreateModel } from '@/api/travel-policy/travel-policy.model';
import DictionaryStore from '@/store/dictionary.store';
import DisplayPreferencesStore from './display-preferences.store';
import _ from 'lodash';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'displayPreferencesFligth'
})
class DisplayPreferencesFlightStore extends VuexModule {
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow ;
  displayPreferences: any = {
    name: '',
    withCompliantTravelPolicy: false,
    hideDuplicatedGdsFares: false,
    displayPreferences: [],
  };
  currentSelectedRules: FlightRuleCreateModel | null = null;
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';

  get allDictionaryAirlines() {
    return DictionaryStore.allAirLines;
  }

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  get getFlightDisplayPreferences() {
    return this.displayPreferences;
  }

  get getFlightRules() {
    return this.displayPreferences.displayPreferences;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get currentFlightRules() {
    return this.currentSelectedRules;
  }

  @Mutation
  selectedFlightRule(payload) {
    this.currentSelectedRules = payload;
  }

  @Mutation
  public SetCurrentConfiguration(currentConfiguration: ConfigurationRow | null) {
    this.currentConfiguration = currentConfiguration!;
  }

  @Mutation
  clearFlightRules() {
    this.displayPreferences = {
      name: '',
      withCompliantTravelPolicy: false,
      hideDuplicatedGdsFares: false,
      displayPreferences: [],
    };
  }
 
  @Mutation
  setFlightRules(payload) {
    this.displayPreferences.displayPreferences = payload;
  }

  @Mutation
  addFlightRule(payload) {
    if (this.displayPreferences.displayPreferences) {
      this.displayPreferences.displayPreferences.push(payload);
    }
  }

  @Mutation
  editFlightRule(payload) {
    let index = _.findIndex(this.displayPreferences.displayPreferences, fr => fr.id === payload.id);
    if (index >= -1) {
      this.displayPreferences.displayPreferences[index] = payload;
    }
  }

  @Mutation
  updateDisplayPreferences({ name, withCompliantTravelPolicy, hideDuplicatedGdsFares }) {
    this.displayPreferences.name = name;
    this.displayPreferences.withCompliantTravelPolicy = withCompliantTravelPolicy;
    this.displayPreferences.hideDuplicatedGdsFares = hideDuplicatedGdsFares;
  }

  @Action
  async getRules(currentSelectedId) {
    DisplayPreferencesStore.setLoading(true);
    try {
      DisplayPreferencesStore.clearErrors();

      const result = await DisplayPreferencesApi.getConfiguration(currentSelectedId);
      if (result && result.data) {
        this.updateDisplayPreferences(result.data);
        this.setFlightRules(result.data.displayPreferences);
        DisplayPreferencesStore.setCurrentConfigName(result.data.name);
        DisplayPreferencesStore.setLoading(false);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        DisplayPreferencesStore.setCustomError(true);
      } else {
        DisplayPreferencesStore.setErrors(error);
      }
      DisplayPreferencesStore.setLoading(false);
    }
  }

  @Action
  async getFlightRulesConfiguration(currentSelectedId) {
    DisplayPreferencesStore.setLoading(true);
    if (currentSelectedId) {
      if (this.displayPreferences.displayPreferences && this.displayPreferences.displayPreferences.length) {
        DisplayPreferencesStore.setCurrentConfigName(this.displayPreferences.name);
      } else {
        await this.getRules(currentSelectedId);
      }
    }
    DisplayPreferencesStore.setLoading(false);
  }
}

export default getModule(DisplayPreferencesFlightStore);