














































































import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';

@Component({})
export default class TrainCabinLavatory extends Vue {
  @Prop({ default: null }) item!: any | null;

  get x1idx() {
    return this.item.x;
  }

  get x2idx() {
    return this.item.x + this.item.width;
  }

  get y1idx() {
    return this.item.y;
  }

  get y2idx() {
    return this.item.y + this.item.height;
  }

  get x() {
    return consts.margin + this.x1idx * consts.colWidth + consts.thickness;
  }

  get y() {
    return consts.margin + this.y1idx * consts.rowHeight + consts.thickness;
  }

  get width() {
    return Math.max(0, (this.x2idx - this.x1idx) * consts.colWidth - 2 * consts.thickness);
  }

  get height() {
    return Math.max(0, (this.y2idx - this.y1idx) * consts.rowHeight - 2 * consts.thickness);
  }

  get xcenter() {
    return this.x + this.width / 2;
  }

  get ycenter() {
    return this.y + this.height / 2;
  }

  get iconWidth() {
    if (this.x2idx - this.x1idx > 2 && this.y2idx - this.y1idx > 2) {
      return 15;
    }
    if (this.x2idx - this.x1idx > 1 && this.y2idx - this.y1idx > 1) {
      return 11;
    }

    return 7.5;
  }

  get iconHeight() {
    return this.iconWidth * 2;
  }
}
