




















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from '@/modules/layout/layout.store';
import AccountStore from '@/store/account.store';
import expenseConsts from './expense.const';

@Component({
  components: {
  }
})
export default class ExpenseSubmenu extends Vue {

  get submenuStyles() {
    return {
      width: 'calc(100% - ' + layoutStore.scrollbarWidth + 'px)',
    };
  }

  get links() {
    return expenseConsts.menu
      .filter(route => (!route.meta || !route.meta.permission) ? true : AccountStore.HasPermission(route.meta.permission));
  }

  activeRouter(item) {
    const name = item.name ? item.name : item.meta.parentName;

    return this.$route.name === name || 
      (this.$route.meta.parentName &&
      this.$route.meta.parentName === name);
  }
 

}
