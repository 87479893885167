





























































































































































































































import { Vue, Component } from 'vue-property-decorator';

import { Validation } from 'vue-plugin-helper-decorator';
import {required } from 'vuelidate/lib/validators';
import settings from '@/settings';
import buildData from '@/version';
import appConst from '@/const/app.const';
import { Theme } from '@/api/profile/themes.model';
import EventHandler from '@/services/event-handler';
import AccountStore from '@/store/account.store';
import ThemeStore from '@/modules/settings/theme/themeStore.store';
import { http, RequestMethod } from '@/services/http';
import ChangeLogoPopup from './ChangeLogoPopup.vue';
import SettingsStore from '../settings.store';
import { translate } from '@/i18n';

const defaultValues: Theme = {
  themeName: appConst.defaultAppName,
  primaryColor: appConst.primaryColor,
  accentColor: appConst.accentColor,
  themeVersion: '',
  footer: '',
};

@Component({
  components: {
    ChangeLogoPopup,
  }
})
export default class ThemeConfiguration extends Vue {
  $v;
  formChecking: boolean = false;
  formPending: boolean = false;
  reloadPopup: boolean = false;
  themeReady: boolean = false;
  logoReady: boolean = false;
  showResetPopup: boolean = false;
  showLogoPopup: boolean = false;
  showRemoveLogoPopup: boolean = false;
  shouldDisplayInfo: boolean = false;
  Form: Theme = {
    themeName: '',
    primaryColor: '',
    accentColor: '',
    themeVersion: '',
    footer: '',
  };
  serverErrors: any[] = [];
  logoErrors: any[] = [];
  loadingGif: string = '/assets/img/loader/1.gif';
  canLoad: boolean = true;
  notRootErrors: any[] = [
    {
      message: translate('settings-travel-policy.available-page'),
    },
  ];

  @Validation()
  validationObject() {
    return {
      Form: {
        themeName: {
          required,
        },
        primaryColor: {
          required,
        },
        accentColor: {
          required,
        },
        footer: {
          required,
        },
      }
    };
  }

  get inEditMode() {
    return !!(this.$route.params.configurationId);
  }

  get isRootCompany() {
    return SettingsStore.isRootCompany;
  }

  get envReloading() {
    return AccountStore.environmentReloading;
  }

  get tmcLogo() {
    return ThemeStore.companyLogo;
  }

  get themeConfiguration() {
    return ThemeStore.themeConfiguration;
  }

  get envErrors() {
    return !AccountStore.environmentError ? [] : this.$handleErrors(AccountStore.environmentError, true);
  }

  get companyLogo() {
    return AccountStore.companyLogo;
  }

  get companyLogoChanged(): boolean {
    return AccountStore.initialCompanyLogo !== this.tmcLogo;
  }

  get tmcName() {
    return this.Form.themeName;
  }

  get user() {
    return AccountStore.current;
  }

  get classes() {
    return {
      'd-block': this.user!.profile.displayLanguage !== 'it',
      'd-none': this.user!.profile.displayLanguage === 'it',
    };
  }

  get errMessages() {
    return ThemeStore.errMessages;
  }

  get showErrMessages() {
    return ThemeStore.showError;
  }

  get canShowCustomError() {
    return ThemeStore.canShowCustomError;
  }
  
  get loading() {
    return ThemeStore.loading;
  }

  async submit() {
    if (this.formPending) {
      return;
    }

    this.$v.Form.$touch();

    if (this.Form.themeName === '') {
      this.Form.themeName = defaultValues.themeName;
    }
    
    if (!this.$v.Form.$invalid) {
      this.serverErrors = [];
      this.formPending = true;
      
      if (this.inEditMode) {
        let request = {
          ...this.Form,
          configurationName: this.Form.themeName,
          logos: this.prepareCompanyLogoRequest(this.tmcLogo)
        };
        await ThemeStore.updateThemeConfiguration({configurationId: this.$route.params.configurationId, params: request});
        this.canLoad = true;
      } else {
        let request = {
          rootCompanyId: this.$route.params.id,
          ...this.Form,
          configurationName: this.Form.themeName,
          logos: this.prepareCompanyLogoRequest(this.tmcLogo)
        };
        await ThemeStore.addThemeConfiguration(request);
        this.canLoad = false;
      }
      await this.init(true);
      this.shouldDisplayInfo = true;
      this.formPending = false;
    }
  }

  getBase64StringSize(base64string: string) {
    let padding: number, sizeInBytes: number;
    if (base64string.endsWith('==')) {
      padding = 2;
    } else if (base64string.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }
    sizeInBytes = ( 3 * base64string.length / 4 ) - padding;
    return sizeInBytes;
  }

  prepareCompanyLogoRequest(companyLogo: string) {
    let logoSplitted = companyLogo.split(',');
    let base64StringSize: number = settings.companyLogo.base64SizeLimitInB;
    if ( AccountStore.current && logoSplitted[1] ) {
      base64StringSize = this.getBase64StringSize(logoSplitted[1]);
      return ( base64StringSize < settings.companyLogo.base64SizeLimitInB ) ?
        [
          {
            size: {
              type: 'large',
              length: base64StringSize,
              height: settings.companyLogo.height,
              width: settings.companyLogo.width,
            },
            format: settings.companyLogo.returnFormat,
            image: logoSplitted[1],
          }
        ] : null;
    } else {
      return null;
    }
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'theme-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  async removeCompanyLogo() {
    ThemeStore.setCompanyLogo([]);
    this.showRemoveLogoPopup = false;
  }

  async getThemeVersion(theme) {
    if (theme.themeVersion) {
      try {
        const path = settings.apiWeb +
          '/themes/' + theme.themeVersion +
          '?version=' + buildData.version;
  
        await http.execute({
          path,
          method: RequestMethod.GET,
        }, {}, {});
        this.themeReady = true;
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          ThemeStore.setCustomError(true);
        } else {
          ThemeStore.setErrMessages(this.$handleErrors(error, true));
          ThemeStore.setShowError(true);
        }
      }
    }
  }

  async mapFormData(theme, preventLogoLoad) {
    if (theme) {
      Object.keys(theme).forEach(key => {
        if (theme[key] === null) {
          theme[key] = defaultValues[key];
        }
      });
      this.Form = theme;
      try {
        if (preventLogoLoad === false) {
          await ThemeStore.getCompanyLogo({companyId: this.$route.params.id, configurationId: this.$route.params.configurationId});
        }
        await this.getThemeVersion(theme);
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }
    } else {
      this.Form = {
        themeName: '',
        primaryColor: '',
        accentColor: '',
        themeVersion: '',
        footer: '',
      };
    }
  }

  async init(loadLogo) {
    this.logoErrors = [];
    if (this.canLoad) {
      await ThemeStore.getThemeConfiguration(this.$route.params.configurationId);
    }
    if (this.themeConfiguration) {
      this.mapFormData(this.themeConfiguration, loadLogo);
    } else {
      this.mapFormData(null, loadLogo);
    }
  }

  onCloseLogoPopup() {
    this.showLogoPopup = false;
  }

  tmcLogoUploaded(logo) {
    ThemeStore.setCompanyLogoFromPopup(logo);
  }

  async created() {
    EventHandler.$on('close-logo-popup', this.onCloseLogoPopup);
    EventHandler.$on('tmc-logo-uploaded', this.tmcLogoUploaded);
    await this.init(false);
  }

  beforeDestroy() {
    EventHandler.$off('close-logo-popup', this.onCloseLogoPopup);
    EventHandler.$off('tmc-logo-uploaded', this.tmcLogoUploaded);
  }
}

