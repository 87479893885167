import MarkupsConfiguration from '@/modules/settings/agency-markup/MarkupsConfiguration.vue';

export default {
  path: ':configurationId/markups-configuration',
  name: 'markups-configuration',
  component: MarkupsConfiguration,
  displayName: `Markups' configuration`,
  meta: {
    breadCrumb: 'Markups configuration',
    introClass: 'intro-settings',
    permission: 'CanEditAgencyMarkupsConfiguration',
    parentName: 'markups',
    depthCategory: 'markups',
    depth: 2,
  }
};