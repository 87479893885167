

















































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class ClientEmailNotificationsManyEmailsConfiguration extends Vue {
  @Prop() maxCount!: number;
  @Prop() isEnabled!: boolean;
  @Prop() emailAddresses!: object[];
  @Prop() emailInputId!: string;
  @Prop() emailInputSeleniumId!: string;
  @Prop() validationObject!: object;
  
  removeEmail(index) {
    this.$emit('removeEmail', index);
  }

  addEmail() {
    this.$emit('addEmail');
  }
}
