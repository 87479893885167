












































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import { CarSearchStateParams } from './car-search.params';
import { TravellersStateParams } from '../travellers.params';
import layoutStore from '@/modules/layout/layout.store';
import AccountStore from '@/store/account.store';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';
import SearchStore from '@/modules/search/search.store';

@Component({
  components: {
    SearchTripTravellers,
  }
})
export default class CarSearchParamOnResults extends Vue {
  @Prop() travellersSearchList!: TravellersStateParams;
  @Prop() carSearchParameters!: CarSearchStateParams;

  get from() {
    if (
      this.carSearchParameters &&
      this.carSearchParameters.pickupLocation
    ) {
      return this.carSearchParameters.pickupLocation.displayText;
    }

    return '';
  }

  get to() {
    if (
      this.carSearchParameters &&
      this.carSearchParameters.returnLocation
    ) {
      return this.carSearchParameters.returnLocation.displayText;
    } else {
      return this.from;
    }
  }

  get user() {
    return AccountStore.current;
  }

  get dateFormat() {
    if (this.user!.profile.displayLanguage === 'pl') {
      return 'D MMMM';
    }

    return 'Do MMMM';
  }

  get departureDate() {
    if (this.carSearchParameters) {
      return moment(
        this.carSearchParameters.pickupDate,
        'YYYY-MM-DD'
      ).format(this.dateFormat);
    }

    return '';
  }

  get departureDay() {
    if (this.carSearchParameters) {
      return moment(
        this.carSearchParameters.pickupDate,
        'YYYY-MM-DD'
      ).format('dddd');
    }

    return '';
  }

  get returnDate() {
    if (this.carSearchParameters) {
      return moment(
        this.carSearchParameters.returnDate,
        'YYYY-MM-DD'
      ).format(this.dateFormat);
    }

    return '';
  }

  get returnDay() {
    if (this.carSearchParameters) {
      return moment(
        this.carSearchParameters.returnDate,
        'YYYY-MM-DD'
      ).format('dddd');
    }

    return '';
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  toggleSidebar() {
    layoutStore.toggleSidebar();
  }

  @Emit()
  criteria() {}

  @Emit()
  travellers() {}
}
