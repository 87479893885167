export const ServiceTypesConst: any[] = [
  {
    value: 'Flight',
    name: 'settings-approval.flight',
  },
  {
    value: 'Accommodation',
    name: 'settings-approval.accommodation',
  },
  {
    value: 'Car',
    name: 'settings-approval.car',
  },
  {
    value: 'Rail',
    name: 'settings-approval.train',
  },
];

export const TravelPolicyResultTypeConst: any[] = [
  {
    value: 'Compliant',
    name: 'settings-approval.compliant',
  },
  {
    value: 'NonCompliant',
    name: 'settings-approval.non-compliant',
  }
];

export const ApproverSelectionTypeConst: any[] = [
  {
    value: 'BookerSelectsApprover',
    name: 'settings-approval.booker-select',
  }
];