import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  TrainFeesConfiguration,
} from './train-fees-configuration.model';

class TrainFeesConfigurationApiClass {
  public getFeeConfiguration(configurationId: string): Promise<AxiosResponse<TrainFeesConfiguration>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/fees-configuration/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public createFeeConfiguration(request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/fees-configuration/{id}',
      method: RequestMethod.POST,
    }, {
      id: request.configurationId,
    }, request);
  }

  public updateFeeConfiguration(configurationId: string, request): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/fees-configuration/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId }, request);
  }
}

export const TrainFeesConfigurationApi: TrainFeesConfigurationApiClass = new TrainFeesConfigurationApiClass();
