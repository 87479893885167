const trainBenerailIconByTrainCode = {
    DPN: '/assets/img/raillogos/Prignitzer_Eisenbahn.png',
    EC: '/assets/img/raillogos/EC.png',
    ECB: '/assets/img/raillogos/EC.png',
    ECM: '/assets/img/raillogos/EC.png',
    EST: '/assets/img/raillogos/EUROSTAR.png',
    FA: '/assets/img/trenitalia/frecciargento_logo.jpg',
    FB: '/assets/img/trenitalia/frecciabianca_logo.jpg',
    FR: '/assets/img/trenitalia/frecciarossa_1000_logo.jpg',
    IC: '/assets/img/raillogos/IC.png',
    ICD: '/assets/img/raillogos/Intercity_direct.png',
    ICE: '/assets/img/raillogos/ICE.png',
    ICN: '/assets/img/raillogos/INTERCITYNOTTE.svg',
    IR: '/assets/img/raillogos/IR.png',
    IXB: '/assets/img/raillogos/ICE.png',
    NJ: '/assets/img/raillogos/NIGHTJET.png',
    R: '/assets/img/raillogos/REGIONAL_TRAIN_SNCB.png',
    R84: '/assets/img/raillogos/RE.png',
    RB: '/assets/img/raillogos/REGIONALBAHN.png',
    RE: '/assets/img/raillogos/RE.png',
    RER: '/assets/img/raillogos/RER.png',
    RHI: '/assets/img/raillogos/ICE.png',
    RHT: '/assets/img/raillogos/TGV_inOui.png',
    RJ: '/assets/img/raillogos/Railjet.png',
    RJX: '/assets/img/raillogos/Railjet_Express_Hungary.png',
    RRT: '/assets/img/raillogos/TGV_inOui.png',
    S: '/assets/img/raillogos/S-Bahn.png',
    T84: '/assets/img/raillogos/Regionalzug.png',
    TGD: '/assets/img/raillogos/TGV_inOui.png',
    TGJ: '/assets/img/raillogos/TGV_Lyria.png',
    THA: '/assets/img/raillogos/EUROSTAR.png',
};

export const trainLogo = {
    'Frecciarossa': '/assets/img/trenitalia/frecciarossa_1000_logo.jpg',
    'Freccialink': '/assets/img/trenitalia/freccialink_logo.jpg',
    'Frecciargento': '/assets/img/trenitalia/frecciargento_logo.jpg',
    'FrecciaBianca': '/assets/img/trenitalia/frecciabianca_logo.jpg',
    'EUROSTAR': '/assets/img/raillogos/EUROSTAR.png',
    'THALYS': '/assets/img/raillogos/EUROSTAR.png',
    'TGV INOUI': '/assets/img/raillogos/TGV_inOui.png',
    'TGV': '/assets/img/raillogos/TGV_inOui.png',
    'TER': '/assets/img/raillogos/TER.png',
    'OUIGO': '/assets/img/raillogos/Grande_Vitesse.png',
    'OUIGO TRAIN CLASSIQUE': '/assets/img/raillogos/Train_Classique.png',
    'INTERCITES': '/assets/img/raillogos/sncf_intercites.png',

    ...trainBenerailIconByTrainCode,
};
