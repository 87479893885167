
























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import BasketStore from './basket.store';
import EventBus from '@/services/event-handler';
import BasketItem from './BasketItem.vue';
import TrainLoco from './railseatmap/TrainLoco.vue';
import TrainWagon from './railseatmap/TrainWagon.vue';
import consts from '@/const/rail-seat-map.const';
import TrainSeatPassanger from './railseatmap/TrainSeatPassanger.vue';
import TrenitaliaCoachMap from './railseatmap/TrenitaliaCoachMap.vue';
import TrenitaliaSeatMapLegend from './railseatmap/TrenitaliaSeatMapLegend.vue';

@Component({
  components: {
    BasketItem,
    TrainLoco,
    TrainWagon,
    TrenitaliaCoachMap,
    TrainSeatPassanger,
    TrenitaliaSeatMapLegend,
  }
})
export default class BasketTrenitaliaSeatSelection extends Vue {
  @Prop() item!: any;

  selectedJourneyId: string = '';
  selectedCoach: any = null;
  travellerPicked: any = null;
  availableItems = [
    'Table',
    'Luggage',
    'Exit',
    'Window',
    'Bulkhead',
    'Lavatory',
    'LargeSeat',
    'NormalSeat',
  ];
  showTooltip: boolean = false;
  tooltipInfo: any = {};

  get isBasketRailSeatsLoading() {
    return BasketStore.basketRailSeatsMapLoading;
  }

  get seatMap() {
    return BasketStore.basketRailSeatsMap || [];
  }

  get basket() {
    return BasketStore.basket;
  }

  get travellers() {
    return this.basket!.travellers;
  }

  get availableTravellers() {
    const segment = BasketStore.availableTravellersForSeats.find(segment => segment.segmentId === this.selectedJourneyId);

    if (!segment) {
      return [];
    }
    return this.travellers.filter(traveller => {
      return null != segment.travellers.find(travellerId => travellerId === traveller.id);
    });
  }

  get currentWizardStep() {
    return BasketStore.wizardSteps[BasketStore.bookingStep - 1];
  }

  get errors() {
    return BasketStore.errors;
  }

  get currentSeatMap() {
    return this.seatMap.find(seatmap => {
      return seatmap.segmentId === this.selectedJourneyId;
    });
  }

  get mapWidth() {
    let cols = 0;
    if (this.selectedCoach) {
      cols = this.selectedCoach.width;
    }

    return consts.margin * 2 + cols * consts.colWidth;
  }

  get mapHeight() {
    let rows = - 2 * consts.margin / consts.rowHeight;

    if (this.selectedCoach) {
      rows = this.selectedCoach.length;
    }

    return consts.margin * 2 + rows * consts.rowHeight;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }



  userFullName(user) {
    return userFullName(user);
  }

  tooltipStyles() {
    if (!this.showTooltip) {
      return {};
    }
    return {
      top: this.tooltipInfo.offsetY + 'px',
      left: this.tooltipInfo.offsetX + 'px',
    };
  }

  selectJourney(journey, index) {
    this.selectedJourneyId = journey.segmentId;
    BasketStore.setBasketRailJourney(journey);

    const element = (this.$refs.journey as any[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  selectCoach(coach, index) {
    if (!coach.available) {
      return;
    }
    this.selectedCoach = coach;
    BasketStore.setBasketRailCoach(coach);
  }

  selectSeat(seat) {
    const segmentId = this.selectedJourneyId;
    const coachNumber = this.selectedCoach.number;
    const seatNumber = seat.seatNumber;
    const alternateNumber = seat.alternateNumber;
    const profileId = this.travellerPicked.id;

    const seatDef = this.findSeat(segmentId, coachNumber, seatNumber);
    const priceObj = seatDef ? seatDef.prices.find(price => price.travellerId === profileId) : null;
    const price = priceObj ? priceObj.price : null;

    const sameSeat = BasketStore.basketRailSelectedSeats.findIndex(other => {
      return other.segmentId === segmentId &&
        other.coachNumber === coachNumber &&
        other.seatNumber === seatNumber &&
        other.profileId === profileId;
    });

    if (-1 < sameSeat) {
      BasketStore.deselectSelectedRailSeat({
        segmentId,
        coachNumber,
        seatNumber,
        alternateNumber,
        profileId,
        price, 
      });
      return;
    }

    BasketStore.setSelectedRailSeat({
      segmentId,
      coachNumber,
      seatNumber,
      alternateNumber,
      profileId,
      price,
    });
  }

  mouseEnterSeat({ item, e }) {
    if ('Open' === item.seatAvailability) {
      this.tooltipInfo = {
        seatNumber: item.seatNumber,
        price: item.prices && item.prices.length ? item.prices.find(p => {
          return p.travellerId === this.travellerPicked.id;
        }).price : {
          amount: 0,
          currency: {
            code: 'EUR',
          }
        },
        offsetX: e.layerX - 20,
        offsetY: e.layerY + 30,
      };
      this.showTooltip = true;
    }
  }

  mouseLeaveSeat({ item, e }) {
    if (item.seatNumber === this.tooltipInfo.seatNumber) {
      this.showTooltip = false;
    }
  }

  passangerSeat(profileId) {
    return BasketStore.basketRailSelectedSeats.find(seat => {
      return seat.profileId === profileId && seat.segmentId === this.selectedJourneyId;
    });
  }

  findSeat(segmentId, coachNumber, seatNumber) {
    const seatMap = this.seatMap.find(segment => segment.segmentId === segmentId);
    if (!seatMap) {
      return null;
    }

    const coachDef = seatMap.coaches.find(coach => coach.number === coachNumber);
    if (!coachDef) {
      return null;
    }

    const seatDef = coachDef.seatMapItems.find(s => s.seatNumber === seatNumber);
    if (!seatDef || !seatDef.prices) {
      return null;
    }

    return seatDef;
  }

  passangerPrice(profileId) {
    const seat = this.passangerSeat(profileId);

    if (!seat) {
      return null;
    }

    const coachDef = this.currentSeatMap.coaches.find(coach => coach.number === seat.coachNumber);
    if (!coachDef) {
      return null;
    }
    const seatDef = coachDef.seatMapItems.find(s => s.seatNumber === seat.seatNumber);

    if (!seatDef || !seatDef.prices) {
      return null;
    }

    const price = seatDef.prices.find(price => price.travellerId === profileId);

    if (!price) {
      return null;
    }

    if (price.price && price.price.amount === 0) {
      return null;
    }

    return price.price;
  }

  travellerNumber(profileId) {
    return this.availableTravellers.findIndex(t => t.id === profileId) + 1;
  }

  selectFirstCoach() {
    if (!this.travellerPicked) {
      this.selectedCoach = null;
      return;
    }
    const travellerId = this.travellerPicked.id;
    let seatSelected = BasketStore.basketRailSelectedSeats.find(seat => {
      return seat.profileId === travellerId && seat.segmentId === this.selectedJourneyId;
    });

    if (!seatSelected && this.currentSeatMap) {
      const seat = this.currentSeatMap.assignedSeats
        .filter(seat => seat.coachNumber !== null && seat.seatNumber !== null)
        .find(seat => {
          return seat.travellerId === travellerId;
        });
      seatSelected = seat;
    }
    if (!seatSelected) {
      this.selectedCoach = this.currentSeatMap.coaches.find(coach => coach.available);
    } else {
      this.selectedCoach = this.currentSeatMap.coaches.find(coach => {
        return coach.number === seatSelected.coachNumber;
      });
    }
    
    BasketStore.setBasketRailCoach(this.selectedCoach);
  }

  @Watch('selectedJourneyId', { immediate: true })
  onJourneyChange() {
    const travellerId = this.travellerPicked ? this.travellerPicked.id : '';
    const lastTraveller = this.availableTravellers.find(traveller => traveller.id === travellerId);

    if (!lastTraveller) {
      this.travellerPicked = this.availableTravellers[0];
    }

    this.selectFirstCoach();
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.resetPriceChangesForStep({ tripItemId: this.itemId, step: this.bookingStep });
    await BasketStore.loadRailSeatsMap(this.item.id);

    if (this.seatMap && this.seatMap.length) {
      this.selectedJourneyId = this.seatMap[0].segmentId;
      BasketStore.setBasketRailJourney(this.seatMap[0]);
      this.travellerPicked = this.availableTravellers[0];
      this.selectFirstCoach();
    }
  }

  async created() {
    EventBus.$on('select-seat', this.selectSeat);
    EventBus.$on('mouseenter-seat', this.mouseEnterSeat);
    EventBus.$on('mouseleave-seat', this.mouseLeaveSeat);
  }

  beforeDestroy() {
    EventBus.$off('select-seat', this.selectSeat);
    EventBus.$off('mouseenter-seat', this.mouseEnterSeat);
    EventBus.$off('mouseleave-seat', this.mouseLeaveSeat);
    BasketStore.resetSelectedRailSeats();
  }
}
