import ProjectsConfiguration from '@/modules/settings/projects-configuration/ProjectsConfiguration.vue';

export default {
  path: ':configurationId?/configuration/:isCopy?',
  name: 'projects-configuration',
  component: ProjectsConfiguration,
  displayName: `Projects' configuration`,
  meta: {
    breadCrumb: 'Projects configuration',
    introClass: 'intro-settings',
    permission: 'ReadProjects',
    parentName: 'projects',
    depthCategory: 'projects',
    depth: 2,
  }
};