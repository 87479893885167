


















































































































































































import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator';
import _ from 'lodash';

import searchConst from '@/const/search.const';
import SearchStore from '@/modules/search/search.store';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import { TravellerModel } from '@/api/home/home.model';
import { translate } from '@/i18n';

@Component({})
export default class UiTrainTravellersSelect extends Vue {
  @Model('change') modelValue!: any;
  @Prop() options!: any;
  @Prop() value!: [];
  @Prop() disabled!: boolean;
  cleanOptions: any = null;
  dirtyOptions: any = _.cloneDeep(this.options);
  travellersSum: number = 0;
  travellersSumTemp: number = 0;
  isSetTravellersSum: boolean = true;
  showFull: boolean = false;
  isInPopup: boolean = false;
  isActive: boolean = false;
  popupContainer: HTMLElement | null = null;
  blurTimeout: number = -1;
  touchedIndex: number = -1;
  ageOptions: any = {};



  get travellersSearchList() {
    return this.modelValue;
  }

  get initialTravellersList() {
    return SearchStore.getTravellersState;
  }

  get editedTravellersList() {
    return SearchStore.editedTravellersState;
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get wrapperClasses() {
    return {
      'ui-travellers-select--show-full': this.showFull,
      'disabled': this.disabled,
    };
  }

  get hasItemsWithoutAge() {
    return this.editedTravellersList.travellers
      .some(item => {
        const codeDef = searchConst.guestTravellerOptions
          .find(option => option.code === item.passengerTypeCode) || false;
        const shouldHaveAge = codeDef && codeDef.trainAge;

        return shouldHaveAge && item.age === null;
      });
  }



  ageProfiles(option) {
    return this.editedTravellersList.travellers
      .filter(item => item.passengerTypeCode === option.code);
  }

  ageOptionsForOption(option) {
    return this.ageOptions[option.code];
  }

  guestAge(option, idx) {
    return this.ageOptionsForOption(option).find(item => item.value === this.ageProfiles(option)[idx].age) || null;
  }

  setGuestAge(option, idx, value) {
    if (!value) {
      this.ageProfiles(option)[idx].age = null;
      return;
    }
    this.ageProfiles(option)[idx].age = value.value;
  }

  setMobileGuestAge(option, idx, value) {
    this.setGuestAge(option, idx, value);
    this.checkOptions();
    const uiTravellerMobileInput = this.$refs.uiTravellerMobileInput as HTMLElement;
    if (uiTravellerMobileInput) {
      uiTravellerMobileInput.focus();
    }
  }

  mobileFocus() {
    if (this.disabled) {
      return;
    }
    clearTimeout(this.blurTimeout);
    this.isActive = true;
    this.isSetTravellersSum = true;
    this.cleanOptions = _.cloneDeep(this.dirtyOptions);

    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  mobileClick($event) {
    const el = $event.target;
    const isUiAutocomplete = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-field');
    const isUiAutocompleteInput = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-input');
    const isUiAutocompleteToggle = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-toggle');
    const isUiAutocompleteOption = hasSomeParentTheClass(el, 'option-item');
    const isUiAutocompleteOptions = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-options');
    
    if (this.isActive && isUiAutocompleteOption) {
      const parent = getParentByClass($event.target, 'option-item');
      
    } else if (
      this.isActive && ((isUiAutocomplete && isUiAutocompleteToggle) ||
      (!isUiAutocompleteOption && isUiAutocompleteOptions))
    ) {
      return this.$refs.uiTravellerMobileInput && 
        (this.$refs.uiTravellerMobileInput as HTMLInputElement).blur();
    }

    this.$nextTick(() => {
      return this.$refs.uiTravellerMobileInput && 
        (this.$refs.uiTravellerMobileInput as HTMLInputElement).focus();
    });
  }

  mobileBlur($event) {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => {
      this.isActive = false;
      if (this.isInPopup && window.innerWidth < 800) {
        EventBus.$emit('unfreeze-popup', this.popupContainer);
      }
    }, 100);
  }

  setTravellersSum() {
    return this.travellersSum = this.dirtyOptions.reduce((prev, cur) => prev + cur.value, 0);
  }

  get error() {
    return this.travellersSumTemp >= 10;
  }

  onClick(e: Event) {
    if (this.disabled) {
      return;
    }
    const target = e.target as HTMLElement;
    if (target.className.includes('fake-input')) {
      this.isSetTravellersSum = true;
      this.showFull = true;
      this.cleanOptions = _.cloneDeep(this.dirtyOptions);
      return;
    }
    this.showFull = true;
  }

  get hasMobileSubMenu() {
    if (
      router.currentRoute.matched[0].meta &&
      router.currentRoute.matched[0].meta.hasMobileSubMenu
    ) {
      return true;
    }
    return false;
  }


  get mobileClasses() {
    return {
      'ui-autocomplete__mobile--has-submenu': this.hasMobileSubMenu && !this.isInPopup,
      'ui-autocomplete__mobile--has-values': 
        this.value && this.value.length,
      'ui-autocomplete__mobile--active': this.isActive,
    };
  }



  checkOptions() {
    this.setTravellersSum();
    this.prepareTravellersModel();
    this.showFull = false;
  }

  cancel() {
    this.isSetTravellersSum = false;
    this.showFull = false;
    this.dirtyOptions = this.cleanOptions;
    this.travellersSumTemp = this.dirtyOptions.reduce((prev, cur) => prev + cur.value, 0);
    this.prepareTravellersModel();
  }

  updateValue(option, value) {
    this.travellersSumTemp = this.dirtyOptions.reduce((prev, cur) => prev + cur.value, 0);

    const otherProfiles = this.editedTravellersList.travellers
      .filter(i => i.passengerTypeCode !== option.code);
    const optionProfiles = this.editedTravellersList.travellers
      .filter(i => i.passengerTypeCode === option.code);
    
    if (value > optionProfiles.length) {
      for (let i = optionProfiles.length; i < value; i++) {
        this.editedTravellersList.travellers.push({
          isVirtual: true,
          passengerTypeCode: option.code,
          age: null,
        } as TravellerModel);
      }
    } else if (value < optionProfiles.length) {
      const howMany = optionProfiles.length - value;
      optionProfiles.splice(-howMany, howMany);
      this.editedTravellersList.travellers = [
        ...optionProfiles,
        ...otherProfiles,
      ];
    }
  }

  updateMobileValue(option, value) {
    this.updateValue(option, value);
    this.checkOptions();
  }

  windowOnClick(e) {
    if (window.innerWidth < 800) {
      return;
    }
    let target = e.target as HTMLInputElement;
    if (
      this.showFull &&
      !this.$el.contains(target) &&
      this.isSetTravellersSum &&
      (target && target.offsetParent && !target.offsetParent.className.includes('search-travellers'))
    ) {
      setTimeout(() => {
        if (!this.skipTravellers) {
          return;
        }
        this.checkOptions();
      });
    }
  }

  prepareTravellersModel() {
    if (!this.editedTravellersList.travellers.length) {
      let trav: any[] = [];
      this.dirtyOptions.forEach(element => {
        for (let i = 0; i < element.value; i++) {
          let obj = {
            passengerTypeCode: element.code,
            isVirtual: true,
            childAge: null,
            age: null,
          };
          trav.push(obj);
        }
      });

      SearchStore.updateEditedTravellers({
        travellers: trav,
      });

      return;
    }
    this.travellers(this.editedTravellersList.travellers);
  }

  travellers(trav) {
    if (trav) {
      if (trav && trav.length) {
        if (-1 === trav.findIndex(item => !!item.isMainTraveller)) {
          const firstAdult = trav.find(item => item.passengerTypeCode === 'ADT');
          if (firstAdult) {
            firstAdult.isMainTraveller = true;
          } else {
            trav[0].isMainTraveller = true;
          }
        }
      }
      this.onChange({
        ...this.travellersSearchList,
        travellers: trav,
      });
    }
  }

  prepareAgeOptions() {
    searchConst.guestTravellerOptions
      .forEach(option => {
        if (!option.trainAge) {
          return;
        }
        this.ageOptions[option.code] = [];

        for (let i = option.ageOptions[0]; i <= option.ageOptions[1]; i++) {
          let label = i === 1 ?
            translate('search-hotel.singular-years-old', {
              number: i,
            }) :
            translate('search-hotel.plural-years-old', {
              number: i,
            });
          
          this.ageOptions[option.code].push({
            label: i === 65 ? translate('search-train.sixty-five-and-over') : label,
            value: i,
          });
        }
      });
  }

  @Emit('change')
  onChange(value) {
    return value;
  }

  onFocus() {
    if (this.disabled) {
      return;
    }
    this.isSetTravellersSum = true;
    this.showFull = true;
    this.cleanOptions = _.cloneDeep(this.dirtyOptions);
  }

  checkTravellers() {
    let travellers = this.initialTravellersList && this.initialTravellersList.travellers || [];

    if (
      this.editedTravellersList &&
      this.editedTravellersList.travellers &&
      this.editedTravellersList.travellers.length
    ) {
      travellers = this.editedTravellersList.travellers;
    }
    if (travellers.length > 0) {
      this.dirtyOptions.forEach(option => {
        option.value = 0;
        travellers.forEach(traveller => {      
          if (option.code === traveller.passengerTypeCode || traveller.passengerTypeCode === null) {
            option.value = option.value + 1;
          }
        });
      });
    }
    
    this.cleanOptions = _.cloneDeep(this.dirtyOptions);
    this.setTravellersSum();
    if (travellers.length === 0) {
      this.prepareTravellersModel();
    }
  }

  @Watch('hasItemsWithoutAge', { immediate: true })
  onValidate(value) {
    this.$emit('invalid', value);
  }

  created() {
    this.prepareAgeOptions();
    this.checkTravellers();
    window.addEventListener('click', this.windowOnClick);
  }

  beforeDestroy() {
    window.removeEventListener('click', this.windowOnClick);
  }
}
