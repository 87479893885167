











import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';

import BasketStore from '@/modules/basket/basket.store';

@Component({})
export default class SabreAncillaryPrice extends Vue {
  @Prop() offer!: any;
  @Prop() profileId!: any;
  
  get isSelected() {
    return !!BasketStore.selectedAncillaries.find(offer => {
      return offer.profileId === this.profileId &&
        offer.id === this.offer.id;
    });
  }
}

