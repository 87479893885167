

















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { TransitionEvent } from '@/core/which-transition';

@Component({})
export default class UiToggleView extends Vue {
  @Prop({ default: false }) toggled!: boolean;
  @Prop({ default: 'normal' }) speed!: string;
  @Prop({ default: false }) emitEvents!: boolean;

  animating = false;
  preparing = false;
  slotHeight = 0;

  get height() {
    if (this.toggled || this.preparing) {
      return this.slotHeight;
    }
    return 0;
  }

  get styles() {
    if (this.toggled && !this.preparing && !this.animating) {
      return {};
    }
    if (!this.toggled && this.preparing && this.animating) {
      return {};
    }
    return {
      height: this.height + 'px',
    };
  }

  get classes() {
    let classes: string[] = [];

    if (this.preparing) {
      classes.push('preparing');
    } else if (this.animating || !this.toggled) {
      classes.push('animating');
    }

    if (this.speed === 'fast') {
      classes.push('speed-fast');
    }
    return classes;
  }

  updateSlotHeight() {
    const el: HTMLElement = this.$refs.wrapper as HTMLElement;
    if (!el) {
      return;
    }
    this.slotHeight = el.offsetHeight;
  }

  onTransitionFinished($event) {
    if (
      this.preparing || !$event || 'height' !== $event.propertyName
    ) {
      return;
    }
    this.preparing = true;
    this.removeEvents();
    setTimeout(() => {
      this.updateSlotHeight();
      this.animating = false;
      this.preparing = false;
      if (this.emitEvents) {
        this.$emit('animation-finished');
      }
    });
  }

  addEvents() {
    if (!TransitionEvent) {
      return;
    }
    const el: HTMLElement = this.$refs.view as HTMLElement;
    el.addEventListener(TransitionEvent, this.onTransitionFinished);
  }

  removeEvents() {
    if (!TransitionEvent) {
      return;
    }
    const el: HTMLElement = this.$refs.view as HTMLElement;
    if (el) {
      el.removeEventListener(TransitionEvent, this.onTransitionFinished);
    }
  }

  mounted() {
    this.updateSlotHeight();
  }

  @Watch('toggled')
  onChange(value) {
    this.removeEvents();
    this.addEvents();
    this.updateSlotHeight();
    this.preparing = true;
    setTimeout(() => {
      this.animating = true;
      this.preparing = false;
      if (this.emitEvents) {
        this.$emit(value ? 'on-opening' : 'on-closing', this.height);
      }
    });
  }

  onWindowResize() {
    this.updateSlotHeight();
    this.$emit('resize', this.height);
  }

  @Watch('emitEvents', { immediate: true })
  onEmitEventsChange(value) {
    if (value) {
      window.addEventListener('resize', this.onWindowResize);
      return;
    }
    window.removeEventListener('resize', this.onWindowResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  }
}

