








import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';

const AfterAnimationDelay = 750;

@Component({})
export default class DynamicHeightTransition extends Vue {
  @Prop() watch!: string | number | boolean;

  slotHeight: number = 0;

  @Debounce({
    delay: AfterAnimationDelay,
  })
  afterAnimation() {
    const container = this.$refs.container as HTMLElement;
    if (container) {
      container.style.height = 'auto';
      container.style.overflow = 'visible';
    }
  }
  getSlotHeight() {
    return (this.$slots.default || []).reduce((prev, next) => {
      if (next) {
        const elm = next.elm as HTMLElement;
        return prev + elm.getBoundingClientRect().height;
      }
      return prev;
    }, 0);
  }
  recalculateHeight() {
    const container = this.$refs.container as HTMLElement;
    if (container) {
      container.style.height = `${this.slotHeight}px`;
      this.$nextTick(() => {
        this.slotHeight = this.getSlotHeight();
        container.style.height = `${this.slotHeight}px`;
        container.style.overflow = 'hidden';
        this.afterAnimation();
      });
    }
  }
  mounted() {
    this.slotHeight = this.getSlotHeight();
  }
  created() {
    window.addEventListener('resize', this.recalculateHeight);
  }
  destroyed() {
    window.removeEventListener('resize', this.recalculateHeight);
  }

  @Watch('watch', { immediate: true })
  onRecalculateHandler() {
    this.recalculateHeight();
  }
}
