import reloadPolicy from './reload-policy.config';
import productionTip from './production-tip.config';
import axiosConfig from './axios.config';
import globalComponents from './global-components.config';
import bootstrapComponents from './bootstrap-components.config';
import vuelidateConfig from './vuelidate.config';
import accountConfig from './account.config';
import filtersConfig from './filters.config';
import routerGuardsConfig from './router-guards.config';
import pluginsConfig from './plugins.config';
import portalConfig from './portal.config';
import calendarConfig from './calendar.config';
import zoomOnHoverConfig from './vue-zoom-on-hover.config';
import perfectScrollbarConfig from './perfect-scrollbar.config';
import inputMaskConfig from './input-mask.config';
import vueCookies from './cookies.config';
import sncfSeatmapComponentConfig from './sncf-seatmap-component.config';
import vueClipboardConfig  from './vue-clipboard2.config';

export default [
  reloadPolicy,
  productionTip,
  axiosConfig,
  globalComponents,
  bootstrapComponents,
  vuelidateConfig,
  accountConfig,
  filtersConfig,
  routerGuardsConfig,
  pluginsConfig,
  portalConfig,
  calendarConfig,
  zoomOnHoverConfig,
  perfectScrollbarConfig,
  inputMaskConfig,
  sncfSeatmapComponentConfig,
  vueCookies,
  vueClipboardConfig,
];
