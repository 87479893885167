import Basket from '@/modules/basket/Basket.vue';
import BasketAddSegment from '@/modules/basket/BasketAddSegment.vue';
import BasketAside from '@/modules/basket/BasketAside.vue';
import BasketMessages from '@/modules/basket/BasketMessages.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/basket-add-segment/:id',
  name: 'basketAddSegment',
  component: Basket,
  components: {
    default: Basket,
    intro: BasketAddSegment,
    aside: BasketAside,
    messages: BasketMessages,
  },
  meta: {
    introClass: 'intro-search',
    permission: 'SearchOffers',
    startWithHiddenAside: true,
    waitForDicts: true,
    preserveAsideFrom: [
      'basket',
      'basketTravellers',
    ],
    transitions: {
      intro: {
        basket: AnimationName.UP,
      },
    },
  },
};