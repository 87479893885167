import SettingsById from '@/modules/settings/SettingsById.vue';
import companyInfo from './company.route';
import companyStructure from './company-structure/company-structure.route';
import airProviderConfigurations from './air-provider-configuration.route';
import railProviderConfigurations from './rail-provider-configuration.route';
import carProviderConfigurations from './car-provider-configuration.route';
import accommodationProviderConfigurations from './accommodation-provider-configuration.route';
import createCompany from './create-company.route';
import roles from './roles.route';
import community from './community.route';
import travelPolicy from './travel-policy.route';
import displayPreferences from './display-preferences.route';
import billing from './billing/billing.route';
import billingCustomFields from './billing-custom-fields/billing-custom-fields.route';
import approvalWorkflow from './approval-workflow/approval-workflow.route';
import delayTicketing from './delay-ticketing/delay-ticketing.route';
import theme from './theme/theme.route';
import negoFareConfiguration from './nego-fare-configuration.route';
import providersNotifications from './providers-notifications/providers-notifications.route';
import customFields from './custom-fields-configuration/custom-fields.route';
import projects from './projects-configuration/projects.route';
import expensePolicy from './expense-configuration/expense.route';
import customers from './customers.route';
import createAgency from './create-agency.route';
import lodgeCards from './lodge-cards/lodge-cards.route';
import paymentMethodsConfiguration from './payment-methods-configuration.route';
import pnrRemarks from './pnr-remarks/pnr-remarks.route';
import profileSync from './profile-sync/profile-sync.route';
import fees from './agency-fees/fees.route';
import markups from './agency-markup/markups.route';
import systemMarkup from './system-markup/system-markup.route';
import plugins from './plugins/plugins.route';
import security from './security/security.route';
import clientEmailNotifications from './client-email-notifications/client-email-notifications.route';
import missedSavings from './missed-savings/missed-savings.route';
import faresMapping from './fares-mapping/fares-mapping.route';
import paymentGateway from './payment-gateway/payment-gateway.route';
import virtualCards from './virtual-cards/virtual-cards.route';
import currencyExchange from './currency-exchange/currency-exchange.route';
import travellerCategories from './traveller-categories/traveller-categories-wrapper.route';

export default {
  path: ':id',
  // name: 'settingsById',
  component: SettingsById,
  meta: {
    introClass: 'intro-settings',
    permission: 'AccessSettings'
  },
  children: [
    companyInfo,
    companyStructure,
    customers,
    travelPolicy,
    displayPreferences,
    createCompany,
    community,
    travellerCategories,
    roles,
    airProviderConfigurations,
    railProviderConfigurations,
    carProviderConfigurations,
    accommodationProviderConfigurations,
    negoFareConfiguration,
    providersNotifications,
    billing,
    billingCustomFields,
    approvalWorkflow,
    delayTicketing,
    theme,
    customFields,
    projects,
    expensePolicy,
    createAgency,
    lodgeCards,
    paymentMethodsConfiguration,
    pnrRemarks,
    profileSync,
    fees,
    markups,
    systemMarkup,
    plugins,
    security,
    clientEmailNotifications,
    missedSavings,
    currencyExchange,
    faresMapping,
    paymentGateway,
    virtualCards,
  ],
};
