
























































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import {
  AvailableDateFormats,
  AvailableTimeFormats,
} from '@/api/profile/company.model';
import { AirBookingsApi } from '@/api/air-engine/air-bookings.api';

@Component({})
export default class SabreTripHistoryItemInvoluntaryChanges extends Vue {
  @Prop() item!: any;



  showDetails: boolean = false;
  loading: boolean = false;
  errors: any[] = [];
  details: any = null;
  imagesConst: string = '/assets/img/loader/1.gif';



  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }

  get toggled() {
    return this.showDetails && !this.loading;
  }



  fieldsOfFlight(index) {
    return {
      itemLabel: {
        label: translate('basket-trip-history.item-x', {
          x: index + 1,
        }),
        class: 'basket-trip-history__involuntary-changes-label-column',
      },
      itemValue: {
        label: '',
      },
    };
  }

  itemsOfFlight(flight) {
    return [
      {
        label: translate('basket-trip-history.action'),
        value: flight.action,
      },
      {
        label: translate('basket-trip-history.arrival-date-time'),
        value: this.formatDate(flight.arrivalDateTime),
      },
      {
        label: translate('basket-trip-history.departure-date-time'),
        value: this.formatDate(flight.departureDateTime),
      },
      {
        label: translate('basket-trip-history.flight-number'),
        value: flight.flightNumber,
      },
      {
        label: translate('basket-trip-history.status'),
        value: flight.status,
      },
      {
        label: translate('basket-trip-history.destination-airport'),
        value: flight.destinationLocation,
      },
      {
        label: translate('basket-trip-history.marketing-airline-code'),
        value: flight.marketingAirlineCode,
      },
      {
        label: translate('basket-trip-history.origin-location'),
        value: flight.originLocation,
      },
      {
        label: translate('basket-trip-history.supplier-ref-id'),
        value: flight.supplierRefId,
      },
    ];
  }

  formatDate(date) {
    return moment(date).utc().format(this.dateTimeFormat);
  }

  async loadDetails() {
    this.loading = true;
    this.details = null;
    this.errors = [];
    try {
      const response = await AirBookingsApi.getSabreHistory(this.item.providerReferenceId);
      this.details = response.data;
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
    this.$nextTick(() => {
      this.loading = false;
    });
  }

  toggle() {
    if (!this.showDetails && !this.loading) {
      this.loadDetails();
    }
    this.showDetails = !this.showDetails;
  }
}
