
function whichTransitionEvent() {
  const el = document.createElement('fakeelement');
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };
  let t: any = null;
  let result;

  for (t in transitions) {
    if (undefined !== el.style[t]) {
      result = transitions[t];
      break;
    }
  }

  el.remove();

  return result;
}

export const TransitionEvent = whichTransitionEvent();
