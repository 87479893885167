import Markups from '@/modules/settings/agency-markup/Markups.vue';
import MarkupsConfigurations from './markups-configurations.route';
import MarkupsConfiguration from './markups-configuration.route';
import MarkupRuleDefinitionAir from './markup-rule-definition-air.route';
import MarkupRuleDefinitionRail from './markup-rule-definition-rail.route';
import MarkupRuleDefinitionHotel from './markup-rule-definition-hotel.route';

export default {
  path: 'markups',
  component: Markups,
  displayName: 'settings-sidebar.agency-markup',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditAgencyMarkupsConfiguration',
    icon: 'price_change',
    breadCrumb: `Markups configurations' list`,
    parentName: 'markups',
  },
  children: [
    MarkupsConfigurations,
    MarkupsConfiguration,
    MarkupRuleDefinitionAir,
    MarkupRuleDefinitionRail,
    MarkupRuleDefinitionHotel,
  ],
};