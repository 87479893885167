









































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class GestourCustomer extends Vue {
  @Prop() customer!: any;

}

