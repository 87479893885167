






import { Vue, Component } from 'vue-property-decorator';

import Table3 from './Table3.vue';

@Component({
  components: {
    Table3,
  },
})
export default class Table3View extends Vue {}

