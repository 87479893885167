















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import SearchConst from '@/const/search.const';
import { translate } from '@/i18n';

const updateLeg: string = 'update:leg';

@Component({})
export default class SearchOptionCabinClass extends Vue {
  @Prop() leg!: any;
  @Prop() searchModes!: any;

  classLabels: string[] = [
    translate('search-air.cabin-class-departure'),
    translate('search-air.cabin-class-return')
  ];

  get cabinClasses() {
    return SearchConst.cabinClasses;
  }

  translateLabel(value) {
    return translate(value.label);
  }

  @Emit()
  [updateLeg](value) {
    return value;
  }

}
