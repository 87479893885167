






































































import { Vue, Component } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import TrainSearchStore from './train-search.store';
import TrainEditedResultsRow from './TrainEditedResultsRow.vue';

@Component({
  components: {
    TrainEditedResultsRow,
  }
})
export default class EditTrainAgencyMarkupPopup extends Vue {
  showing: boolean = false;
  loading: boolean = false;
  isFeeValueChanging: boolean = false;
  feeValue: any = null;
  errors: any[] = [];
  initialFeeValue: number = 0;



  get offer() {
    if (!TrainSearchStore.editedFeeProposal) {
      return TrainSearchStore.editedFeeOffer;
    }
    return {
      ...TrainSearchStore.editedFeeOffer,
      travelPolicy: TrainSearchStore.editedFeeProposal.travelPolicy,
    };
  }

  get proposal() {
    return TrainSearchStore.editedFeeProposal;
  }

  get feeCurrency() {
    if (this.proposal && this.proposal.agencyMarkup && this.proposal.agencyMarkup.currency) {
      return this.proposal.agencyMarkup.currency.symbol || this.proposal.agencyMarkup.currency.code; 
    }
    return '\u20AC';
  }

  get basePrice() {
    return this.proposal.totalPrice.amount - this.initialFeeValue;
  }

  get totalPrice() {
    return {
      amount: this.feeValue + this.basePrice,
      currency: {
        ...this.proposal.agencyMarkup.currency,
      },
    };
  }



  onEvent() {
    if (this.offer && this.proposal) {
      this.feeValue = this.proposal.agencyMarkup.amount;
      this.initialFeeValue = this.feeValue;
      this.showing = true;
    }
  }

  closePopup() {
    this.showing = false;
    TrainSearchStore.setEditedOffer({ offer: null, proposal: null });
  }

  async save() {
    if (this.loading || this.isFeeValueChanging) {
      return;
    }
    this.loading = true;
    const response = await TrainSearchStore.updateAgencyMarkup(this.feeValue);

    if (response.errors && response.errors.length) {
      this.errors = response.errors;
    } else {
      this.closePopup();
    }

    this.loading = false;
  }



  mounted() {
    EventBus.$on('show-train-edit-agency-markup-popup', this.onEvent);
  }

  beforeDestroy() {
    EventBus.$off('show-train-edit-agency-markup-popup', this.onEvent);
  }
}

