var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basket-ancillary__flights"},[(_vm.offer.isForAllFlights)?_c('span',{staticClass:"basket-ancillary__for-all",class:{
      'faded': !_vm.isSelected,
    }},[_vm._v("\n    "+_vm._s(_vm.$t('basket-ancillaries.for-all-flights'))+"\n  ")]):[_c('span',{staticClass:"basket-ancillary__for xs-hidden-mobile",class:{
        'faded': !_vm.isSelected,
      }},[_vm._v(_vm._s(_vm.$t('basket-ancillaries.for')))]),_vm._l((_vm.itemFlights),function(flight){return _c('div',{key:flight.id,staticClass:"basket-ancillary__flight",class:{
        'faded': !_vm.isSelected,
      }},[_c('UiAirCarrierLogo',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: flight.marketingCarrierCode, classes: 'tooltip-column' }),expression:"{ content: flight.marketingCarrierCode, classes: 'tooltip-column' }"}],staticClass:"xs-hidden-mobile",class:{
          'faded': !_vm.isSelected,
        },attrs:{"carrier":{
          code: flight.marketingCarrierCode,
        },"alt":flight.marketingCarrierCode}}),_vm._v("\n      "+_vm._s(flight.originLocation)+"\n      "),_c('UiIcon',{staticClass:"basket-ancillary__flight-arrow",attrs:{"icon":"arrow_right_alt"}}),_vm._v("\n      "+_vm._s(flight.destinationLocation)+"\n     "),_c('span',{staticClass:"xs-hidden-mobile"},[_vm._v(" |\n      "+_vm._s(flight.marketingCarrierCode)+"\n      "+_vm._s(flight.flightNumber)+"\n      ")])],1)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }