import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';
import _ from 'lodash';

import { store } from '@/store';
import AccountStore from './account.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { CarVendorsApi } from '@/api/car-engine/car-vendors.api';
import { Country, LanguageCode, AirLine, CarExtra } from '@/api/dictionary/dictionary.model';
import { CarVendorsModel } from '@/api/car-engine/car-vendors.model';
import { TravelPolicyApi } from '@/api/travel-policy/travel-policy.api';
import { RentalCompany } from '@/api/travel-policy/travel-policy.model';
import $handleErrors from '@/core/errors/handle-errors.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'dictionary'
})
class DictionaryStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  countries: Country[] | null = null;
  airLines: AirLine[] | null = null;
  carExtras: CarExtra[] | null = null;
  carVendors: CarVendorsModel[] | null = null;
  rentalCompanies: RentalCompany[] | null = null;
  errors: any[] = [];
  dictionariesLoadedWithErrors: boolean = false;
  dictionariesShouldBeLoaded: boolean = false;



  get allCountries(): Country[] | null {
    return this.countries;
  }

  get allAirLines(): AirLine[] | null {
    return this.airLines;
  }

  get allRentalCompanies() {
    return this.rentalCompanies;
  }
  
  get allAirLinesSorted(): AirLine[] {
    return (this.allAirLines || []).sort((a, b) => {
      return a.businessName.localeCompare(b.businessName);
    });
  }



  @Mutation
  public setCountries(countries: Country[]) {
    this.countries = countries;
  }

  @Mutation
  setAirlines(airlines: AirLine[]) {
    this.airLines = airlines;
  }

  @Mutation
  setCarExtras(extras: CarExtra[]) {
    this.carExtras = extras;
  }

  @Mutation
  setCarVendors(data) {
    this.carVendors = data;
  }

  @Mutation
  setRentalCompany(rentalCompanies: RentalCompany[]) {
    this.rentalCompanies = rentalCompanies;
  }

  @Mutation
  setErrors(errors) {
    this.errors = errors;
  }

  @Mutation
  setLoading(loading) {
    this.loading = loading;
  }

  @Mutation
  setLoaded(loaded) {
    this.loaded = loaded;
  }

  @Mutation
  setDictionariesLoadedWithErrors(value: boolean) {
    this.dictionariesLoadedWithErrors = value;
  }

  @Mutation
  setDictionariesShouldBeLoaded(value: boolean) {
    this.dictionariesShouldBeLoaded = value;
  }


  @Action
  public async loadCountries(languageCode?: LanguageCode) {
    if (!languageCode) {
      languageCode = LanguageCode.EN;
    }
    this.setErrors([]);
    try {
      const result = await DictionaryApi.getCountries(languageCode);
      this.setCountries(_.sortBy(result.data, ['countryName']));
    } catch (error) {
      this.setErrors([...this.errors, $handleErrors(error)]);
    } finally {
    }
  }

  @Action
  public async getCountry(code: string) {
    return this.allCountries!.find((country: Country) => { return country.code === code; });
  }

  @Action
  public async loadAirlines() {
    this.setErrors([]);
    try {
      const result = await DictionaryApi.getAirlines();
      this.setAirlines(result.data);
    } catch (error) {
      this.setErrors([...this.errors, $handleErrors(error)]);
    } finally {
    }
  }

  @Action
  public async loadCarExtras(languageCode: LanguageCode) {
    try {
      const result = await DictionaryApi.getCarExtras(languageCode);
      this.setCarExtras(result.data);
    } catch (error) {
      this.setErrors([...this.errors, $handleErrors(error)]);
    }
  }

  @Action
  public async loadCarVendors() {
    try {
      const result = await CarVendorsApi.getCarVendors();
      this.setCarVendors(result.data);
    } catch (error) {
      this.setErrors($handleErrors(error));
    }
  }

  @Action
  public async loadRentalCompany() {
    this.setErrors([]);
    try {
      const result = await TravelPolicyApi.getRentalCompanys();
      this.setRentalCompany(result.data);
    } catch (error) {
      this.setErrors($handleErrors(error));
    }
  }

  @Action
  async fetchCarExtras(language) {
    try {
      const result = await DictionaryApi.getCarExtras(language);
      this.setCarExtras(result.data);
    } catch (error) {
      this.setErrors($handleErrors(error));
    }
  }

  @Action
  public async init() {
    if (!AccountStore.current) {
      return;
    }
    this.setErrors([]);
    this.setLoaded(false);
    this.setLoading(true);

    const language = AccountStore.current.profile.displayLanguage.toUpperCase() as LanguageCode;
    await Promise.all([
      this.loadCountries(language),
      this.loadAirlines(),
      this.fetchCarExtras(language),
    ]);
    if (this.errors.length) {
      this.setDictionariesLoadedWithErrors(true);
    }
    this.setLoaded(true);
    this.setLoading(false);
  }
}

export default getModule(DictionaryStore);
