import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { store } from '@/store';
import {
  DEFAULT_DURATION_HALF,
  DEFAULT_METAKEY,
  DEFAULT_TRANSITIONS,
  DEFAULT_TRANSITIONS_SHOW,
  DEFAULT_TRANSITIONS_HIDE,
  SHOULD_ANIMATE_ON_START,
  TRANSITION_DURATIONS,
  TRANSITION_MODES,
  COMMENT_NAME,
} from './layout.const';
import {
  SlotState,
  SlotTransition,
  SlotMeta,
  AnimationName,
  AnimationMode
} from './layout.model';
import { ScrollManager, hasSomeParentTheClass } from './scroll-manager';
import EventBus from '@/services/event-handler';
import nonCrucialStylesheets from '@/const/non-crucial-stylesheets.const';

export class Slots {
  intro: Slot;
  header: Slot;
  sidebar: Slot;
  aside: Slot;
  default: Slot;
  subintro: Slot;
  submenu: Slot;
  background: Slot;
  messages: Slot;

  constructor() {
    this.intro = new Slot('intro');
    this.header = new Slot('header');
    this.sidebar = new Slot('sidebar');
    this.aside = new Slot('aside');
    this.default = new Slot('default');
    this.subintro = new Slot('subintro');
    this.submenu = new Slot('submenu');
    this.background = new Slot('background', true);
    this.messages = new Slot('messages');
  }

  forEach(callback: (slot: Slot) => void) {
    Object.keys(this).forEach(key => {
      const slot = this[key];
      callback(slot);
    });
  }
}

export class Slot {
  private _state: SlotState;
  meta: SlotMeta;
  transition: SlotTransition;
  wrapper: HTMLElement | null = null;
  fullscreen!: boolean;

  get state() {
    return this._state;
  }

  get transitioning() {
    return -1 < [
      SlotState.SHOWING,
      SlotState.HIDING,
      SlotState.SWAPPING,
    ].indexOf(this._state);
  }

  constructor(public name: string, fullscreen: boolean = false) {
    this._state = SlotState.INITIAL;
    this.meta = {
      height: 0,
      width: 100,
      empty: true,
      component: '',
      fullscreen: fullscreen,
      top: 0,
    } as SlotMeta;
    this.transition = {
      name: AnimationName.NONE,
      duration: 0,
      mode: AnimationMode.DEFAULT,
    } as SlotTransition;
  }

  routeChanges(to, from) {
    let routeDef = to.matched[0];
    let component: any = null;
    if (this.name === 'default' && routeDef && routeDef.component) {
      component = routeDef.component;
    } else {
      component = routeDef && routeDef.components ? routeDef.components[this.name] : null;
    }
    const componentName = component && component.cid ? 
      'cid_' + component.cid : (component && component.__file ? component.__file : (component && component.render ? 
      component.render.name : ''));
    const routeMeta = to.meta.transitions;
    let candidate;

    this.updateDimensions();

    if (this.state === SlotState.INITIAL) {
      if (!SHOULD_ANIMATE_ON_START && component) {
        this.meta.component = componentName;
        this.meta.empty = false;
        this._state = SlotState.SHOWN;

        setTimeout(() => {
          EventBus.$emit('showing-with-no-animation', this.name);
        }, 50);
      } else {
        this._state = SlotState.EMPTY;
        this.meta.empty = true;
      }
    }

    if (!component && '' === this.meta.component) {
      this._state = SlotState.EMPTY;
      this.meta.empty = true;
      return;
    } else if (component && componentName === this.meta.component) {
      this._state = SlotState.SHOWN;
      return;
    } else if (!component && this.meta.component) {
      this._state = SlotState.HIDING;
      this.meta.component = '';
      this.meta.empty = true;
    } else if (component && !this.meta.component) {
      this._state = SlotState.SHOWING;
      this.meta.component = componentName;
      this.meta.empty = false;
    } else {
      this._state = SlotState.SWAPPING;
      this.meta.component = componentName;
    }

    if (routeMeta) {
      const section = routeMeta[this.name];

      if (section) {
        if (section[from.name]) {
          candidate = section[from.name];
        } else if (section.hasOwnProperty(DEFAULT_METAKEY)) {
          candidate = section[DEFAULT_METAKEY];
        } else {
          if ('string' === typeof section) {
            candidate = section;
          }
        }
      }
    }

    if (!candidate) {
      const NAME = this.name.toUpperCase();
      if ('' === this.meta.component && component) {
        candidate = DEFAULT_TRANSITIONS_SHOW[NAME];
      } else if (!component) {
        candidate = DEFAULT_TRANSITIONS_HIDE[NAME];
      } else {
        candidate = DEFAULT_TRANSITIONS[NAME];
      }
    } else {
      candidate = {
        name: candidate,
        duration: TRANSITION_DURATIONS[candidate],
        mode: TRANSITION_MODES[candidate],
      } as SlotTransition;
    }

    this.transition = candidate;
  }

  applyDimensions() {
    if (this.meta.empty) {
      this.meta.height = 0;
    }
  }

  animationFinished() {
    if (this.state === SlotState.HIDING) {
      this._state = SlotState.EMPTY;
    } else {
      this._state = SlotState.SHOWN;
    }
  }

  getLastChildHeight(): number {
    if (this.wrapper && !this.meta.empty) {
      let lastItem: any = null;
      const items = [...this.wrapper.childNodes]
        .filter(item => item.nodeName !== COMMENT_NAME);

      if (items.length) {
        lastItem = items[items.length - 1];
        return lastItem.offsetHeight;
      }
    }

    return 0;
  }

  getFullscreenHeight() {
    const viewport = window.innerHeight;
    const nav = document.querySelector('.navbar') as HTMLElement;
    const mainView = document.querySelector('.main-viewport .view') as HTMLElement;
    const messages = document.querySelector('.messages-wrapper') as HTMLElement;
    let candidateHeight = (viewport && nav) ?
      (viewport - nav.offsetHeight) :
      mainView ? mainView.offsetHeight : 300;
    if (messages && messages.offsetHeight) {
      candidateHeight -= messages.offsetHeight;
    }

    return candidateHeight;
  }

  updateDimensions() {
    let candidateHeight = this.getLastChildHeight();

    if (this.meta.fullscreen) {
      candidateHeight = this.getFullscreenHeight();
    }

    if (this.name === 'default') {
      this.meta.height = Math.max(this.meta.height, candidateHeight);
    } else {
      this.meta.height = candidateHeight;
    }
  }

  setWrapper(ref) {
    this.wrapper = ref;
  }
}

export const isElementInput = el => {
  return el.nodeName.toLowerCase() === 'input' && (el as HTMLInputElement).type === 'text';
};

export const isElementUiSelect = el => {
  return el.nodeName.toLowerCase() === 'div' && (el as HTMLDivElement).className.indexOf('ui-select__mobile-field') > -1;
};

export const isElementUiMultiSelect = el => {
  return el.nodeName.toLowerCase() === 'div' && (el as HTMLDivElement).className.indexOf('ui-multi-select__mobile-field') > -1;
};

export const isElementUiAutocomplete = el => {
  return el.nodeName.toLowerCase() === 'div' && (el as HTMLDivElement).className.indexOf('ui-autocomplete__mobile') > -1;
};

export const isElementUiDatePicker = el => {
  return el.nodeName.toLowerCase() === 'div' && (el as HTMLDivElement).className.indexOf('ui-date-picker__2mobile-field') > -1;
};

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'layout',
})
class LayoutStore extends VuexModule {
  slots: Slots = new Slots();
  fakeSidebar: HTMLElement | null = null;
  sidebarHidden: boolean = false;
  sidebarToggling: boolean = false;
  sidebarTogglingTimeout: any;
  sidebarTimeout: any;
  asideHidden: boolean = false;
  asideToggling: boolean = false;
  asideTogglingTimeout: any;
  asideTimeout: any;
  scrollManager: ScrollManager = new ScrollManager();
  depthCategory: string = '';
  depth: number = 0;
  lastDepth: number = 0;
  isMenuActive: boolean = false;
  scrollbarWidth: number = 0;
  scrollAreaId: number = 0;
  scrollTop: number = 0;
  isFullscreenRoute: boolean = false;
  isSafari: boolean = false;

  get depthDirection(): number {
    if (this.lastDepth < this.depth) {
      return 1;
    } else if (this.lastDepth > this.depth) {
      return -1;
    }
    return 0;
  }

  get isLayoutFullscreen(): boolean {
    return this.isFullscreenRoute;
  }

  @Mutation
  updateIsFullScreenRoute(value: boolean) {
    this.isFullscreenRoute = value;
  }

  @Mutation
  updateScrollTop({ id, top }) {
    this.scrollAreaId = id;
    this.scrollTop = top;
  }

  @Action
  routeChange({to, from}) {
    this.checkDepths({ to, from });

    if (to.meta.fullscreen) {
      this.updateIsFullScreenRoute(true);
      if (!this.sidebarHidden) {
        setTimeout (() =>
          this.toggleSidebar(), DEFAULT_DURATION_HALF);
      }
    } else {
      this.updateIsFullScreenRoute(false);
    }

    if (to.meta.startWithHiddenAside && !this.asideHidden) {
      if (-1 === to.meta.preserveAsideFrom.indexOf(from.name)) {
        this.toggleAside();
      }
    }

    this.slots.forEach(slot => {
      slot.routeChanges(to, from);
    });
    if (this.sidebarHidden) {
      if (to.meta.preserveSidebar && to.meta.preserveSidebar[from.name]) {
        return;
      }
      clearTimeout(this.sidebarTimeout);
      this.sidebarTimeout = setTimeout(() => {
        this.toggleSidebar();
      }, DEFAULT_DURATION_HALF);
    }
  }

  @Mutation
  checkDepths({ to, from }) {
    if (to.name === from.name) {
      return;
    }
    if (to.meta.depthCategory === from.meta.depthCategory) {
      this.lastDepth = from.meta.depth;
      this.depth = to.meta.depth;
    } else {
      this.depth = 0;
      this.lastDepth = 0;
    }
  }

  @Mutation
  menusActive(value) {
    this.isMenuActive = value;
  }

  @Mutation
  applyDimensions() {
    this.slots.forEach(slot => {
      slot.applyDimensions();
    });
  }

  @Mutation
  updateDimensions({ slot }) {
    if ((slot === 'sidebar' || slot === 'aside') && this.fakeSidebar) {
      this.slots[slot].meta.width = this.fakeSidebar.offsetWidth;
    }
    this.slots[slot].updateDimensions();
  }

  @Mutation
  setWrapper({ slot, wrapper }) {
    this.slots[slot].setWrapper(wrapper);
  }

  @Mutation
  animationFinished({ slot }) {
    this.slots[slot].animationFinished();
  }

  @Mutation
  registerFakeSidebar({ ref }) {
    this.fakeSidebar = ref;
  }

  @Mutation
  setScrollbarWidth(value) {
    this.scrollbarWidth = value;
  }

  @Mutation
  setIsSafari(value) {
    this.isSafari = value;
  }

  @Mutation
  toggleSidebar() {
    this.sidebarHidden = !this.sidebarHidden;
    if (this.sidebarHidden) {
      this.sidebarToggling = true;
    } else {
      clearTimeout(this.sidebarTogglingTimeout);
      this.sidebarTogglingTimeout = setTimeout(() => {
        if (!this.sidebarHidden) {
          this.sidebarToggling = false;
        }
      }, DEFAULT_DURATION_HALF);
    }
  }

  @Mutation
  toggleAside(value?) {
    if (value !== undefined && this.asideHidden === !value) {
      return;
    }
    this.asideHidden = !this.asideHidden;
    if (this.asideHidden) {
      this.asideToggling = true;
    } else {
      clearTimeout(this.asideTogglingTimeout);
      this.asideTogglingTimeout = setTimeout(() => {
        if (!this.asideHidden) {
          this.asideToggling = false;
        }
      }, DEFAULT_DURATION_HALF);
    }
  }

  @Action
  scrollUp() {
    this.scrollManager.start();
  }

  @Action
  scrollToElement(el: Element) {
    this.scrollManager.scrollTo(el);
  }

  @Action
  scrollToElementAlways(el: Element) {
    this.scrollManager.scrollTo(el, false);
  }

  @Action
  updateVHProperty() {
    const vh = .01 * window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @Action
  discoverScrollbarWidth() {
    const getScrollbarWidth = () => {
      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      // @ts-ignore
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);
    
      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);
    
      // Calculating difference between container's full width and the child width
      const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
    
      // Removing temporary elements from the DOM
      if (outer.parentNode) {
        outer.parentNode.removeChild(outer);
      }
    
      return scrollbarWidth;
    };

    this.setScrollbarWidth(getScrollbarWidth());
  }

  @Action
  onScrollableOrPopupFocus() {
    if (!document.activeElement) {
      return;
    }

    setTimeout(() => {
      this.updateVHProperty();
    }, 500);

    if (this.isSafari) {
      return;
    }
    const el = document.activeElement;

    const isInput = isElementInput(el);
    const isUiSelect = isElementUiSelect(el);
    const isUiMultiSelect = isElementUiMultiSelect(el);
    const isUiAutocomplete = isElementUiAutocomplete(el);
    const isUiDatePicker = isElementUiDatePicker(el);

    if (
      !isInput && !isUiSelect && !isUiMultiSelect &&
      !isUiAutocomplete && !isUiDatePicker
    ) {
      return;
    }
    this.scrollManager.setSlow();
    if (isUiSelect || isUiMultiSelect || isUiAutocomplete || isUiDatePicker) {
      const isInPopup = hasSomeParentTheClass(el, 'modal-container') || false;

      if (isInPopup) {
        this.scrollManager.setFast();
      }
    }
    this.scrollToElement(el);
  }

  @Action
  initStyles({ scrollableArea, popupsArea, isSafari }) {
    this.updateVHProperty();
    this.discoverScrollbarWidth();
    this.setIsSafari(isSafari);
    window.addEventListener('resize', this.updateVHProperty);

    if (!scrollableArea) {
      return;
    }

    scrollableArea.addEventListener('focus', this.onScrollableOrPopupFocus, true);
    popupsArea.addEventListener('focus', this.onScrollableOrPopupFocus, true);
  }

  @Action
  areaScrolled(scrollTop: number) {
    this.updateScrollTop({
      id: this.scrollAreaId + 1, 
      top: scrollTop
    });
  }

  @Action
  addNonCrucialStyleSheets() {
    nonCrucialStylesheets.paths.forEach(path => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = path;
      document.body.appendChild(link);
    });
  }
}

export default getModule(LayoutStore);
