import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { LodgeCardConfiguration, LodgeCard, UpdateLodgeCardMessage } from './lodge-cards.model';

class LodgeCardsApiClass {
  public getLodgeCardsConfiguration(configurationId: string): Promise<AxiosResponse<UpdateLodgeCardMessage>> {
    return http.execute({
      path: settings.apiProfile + '/lodge-card-configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public addLodgeCardsConfiguration(params: LodgeCardConfiguration): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/lodge-card-configurations',
      method: RequestMethod.POST
    }, {}, params);
  }

  public updateLodgeCardsConfiguration(configurationId: string, params: UpdateLodgeCardMessage): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/lodge-card-configurations/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }
}

export const LodgeCardsApi: LodgeCardsApiClass = new LodgeCardsApiClass();