






















































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { ExpenseAuditRulesApi } from '@/api/expense/expense-audit-rules.api';
import { AuditRuleModel } from '@/api/expense/expense.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class AuditRules extends BaseTable<AuditRuleModel[]> {
  @Prop({}) processType: any;

  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  currentPage: number = 1;
  loading: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: any | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  scope: string = '';
  fields = {
    name: {
      sortable: false,
      label: translate('settings-expense.name'),
    },
    description: {
      sortable: false,
      label: translate('settings-expense.description'),
    },
    severity: {
      sortable: false,
      label: translate('settings-expense.severity'),
    },
    escalation: {
      sortable: false,
      label: translate('settings-expense.escalation'),
    },
    order: {
      sortable: false,
      label: translate('settings-expense.order'),
    },
    actions: {
      sortable: false,
      label: ''
    }
  };

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get auditRules() {
    return ExpensePolicyStore.auditRules;
  }

  get currentActiveTab() {
    if (this.processType === 'ExpenseReportValidation') {
      return 'ExpenseReportValidation';
    }
    if (this.processType === 'SingleExpenseValidation') {
      return 'SingleExpenseValidation';
    }

    return 'MissionPreapproval';
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get count() {
    return this.auditRules.length || 0;
  }

  toggleMenu(item) {
    if (this.selected !== item) {
      this.selected = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  edit(item) {
    router.push({
      name: 'audit-rule',
      params: {
        id: this.currentCompany!.rootCompanyId,
        itemId: router.currentRoute.params.itemId,
        configurationId: this.$route.params.configurationId,
        auditRuleId: item.id
      }
    });
  }

  async removeField() {
    try {
      await ExpenseAuditRulesApi.removeAuditRule(this.selected.id);
      this.removePopup = false;
      const obj = {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-removed'),
        message: translate('settings-expense.policy-removed-success')
      };
      EventHandler.$emit('show-toast', obj);
      this.loadData();
    } catch (error) {
      this.errorsOnPopup = $handleErrors(error, true);
    }
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.removePopup = false;
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'workflow-settings',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  loadData() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.id && this.$route.params.configurationId && this.$route.params.configurationId !== 'undefined') {
      if (this.$route.params.auditRuleId === undefined) {
        this.goToConfiguration();
      }
      if (this.currentActiveTab === 'MissionPreapproval' || this.currentActiveTab === 'ExpenseReportValidation') {
        this.scope = 'Mission';
      } else {
        this.scope = 'Expense';
      }

      ExpensePolicyStore.getAuditRules({policyId: this.policyId, stage: this.currentActiveTab , scope: this.scope});
    }
  }

  @Watch('currentActiveTab', { immediate: true })
  onTabChange() {
    this.loadData();
  }
}
