












import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from './layout.store';
import {
  CSS_TRANSITION_END_EVENTS,
  COMMENT_NAME,
  SHOULD_ANIMATE_ON_START,
} from '@/modules/layout/layout.const';
import { SlotState } from './layout.model';

@Component({})
export default class HeightTransition extends Vue {

  onEnter(element) {
    const width = getComputedStyle(element).width;

    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';

    const height = getComputedStyle(element).height;

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = 0;

    // forces repaint
    getComputedStyle(element).height;

    requestAnimationFrame(() => {
      element.style.height = height;
    });
  }

  onAfterEnter(element) {
    element.style.height = 'auto';
  }

  onLeave(element) {
    const height = getComputedStyle(element).height;
      
    element.style.height = height;

    // forces repaint
    getComputedStyle(element).height;

    requestAnimationFrame(() => {
      element.style.height = 0;
    });
  }

}

