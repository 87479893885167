






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import BasketItem from './BasketItem.vue';
import BasketSeatsMap from './BasketSeatsMap.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import BasketStore from './basket.store';

@Component({
  components: {
    BasketItem,
    BasketSeatsMap,
    BasketExpirationCountdown,
  }
})
export default class BasketSeatsView extends Vue {
  @Prop() basketExpired!: boolean;

  loaded = false;
  canShow = false;

  get basket() {
    return BasketStore.basket;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get loading() {
    return BasketStore.loadingSeatsMap;
  }

  get basketSeatsMap() {
    return BasketStore.basketSeatsMap;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'AIR_SABRE_SEAT_SELECTION') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped;
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.resetPriceChangesForStep({ tripItemId: this.itemId, step: this.bookingStep });
    await BasketStore.loadBasketSeats(this.itemId);
    BasketStore.setBookingStepLoading(false);
  }

  beforeDestroy() {
    BasketStore.resetSelectedSeats();
  }
}

