










































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { userFullName } from '@/core/user-full-name';
import accountStore from '@/store/account.store';
import settings from '@/settings';
import { ProfileApi } from '@/api/profile/profile.api';

const toggleSyncName = 'update:toggle';
const togglePopupSyncName = 'update:togglePopup';

@Component
export default class AppProfileCard extends Vue {
  @Prop() toggle!: boolean;

  get user() {
    return accountStore.current;
  }

  get logoutUrl() {
    return settings.logout;
  }

  get toggleValue() {
    return this.toggle;
  }

  get canBackToMainProfile() {
    return accountStore.CurrentUser && accountStore.CurrentUser.profile.id !== accountStore.CurrentUser.originalProfileId;
  }

  set toggleValue(value: boolean) {
    this[toggleSyncName](value);
  }

  get helpLink() {
    return sanitizeUrl(settings.helpLink + accountStore.currentLanguage);
  }



  userFullName(user) {
    return userFullName(user);
  }

  @Emit()
  [toggleSyncName](val) {
    return val;
  }

  @Emit()
  [togglePopupSyncName](val) {
    return val;
  }

  hide() {
    this.toggleValue = false;
  }

  switchProfile() {
    this[togglePopupSyncName](true);
    this.hide();
  }

  async backToMainProfile() {
    await ProfileApi.backToMainProfile();
    location.replace('/');
  }
}
