import ProfileCustomFields from '@/modules/profile/custom-fields/ProfileCustomFields.vue';

export default {
  path: 'custom-fields',
  name: 'profile-custom-fields',
  component: ProfileCustomFields,
  displayName: 'profile-custom-fields.custom-fields',
  meta: {
    permission: 'ReadProfileCustomFields',
    icon: 'input',
  }
};
