var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basket-steps-checkbox basket-steps-breadcrumb-item",class:{
    'highlighted': _vm.currentStep,
    'active': _vm.isActive,
  },on:{"click":_vm.clickItem}},[(_vm.currentStep)?_c('div',{staticClass:"basket-steps-breadcrumbs__bar-line-in-step",class:{
      'is-first-step': _vm.isFirstStep,
      'is-last-step': _vm.isLastStep,
    }}):_vm._e(),_c('span',{staticClass:"basket-steps-breadcrumb-item__circle",class:{
      'basket-steps-breadcrumb-item__circle--filled': _vm.stepFilled,
    }}),[_vm._v("\n    "+_vm._s(_vm.$t('basket-service-name-by-code.' + _vm.step.code))+"\n  ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }