
























































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';

import AirSearchStore from './air-search.store';
import AirResultsRow from './AirResultsRow.vue';
import EventBus from '@/services/event-handler';
import { AirApi } from '@/api/air-engine/air-search.api';

@Component({
  components: {
    AirResultsRow,
  },
})
export default class AirMissedSavingsPopup extends Vue {
  reason: string = '';
  errors: any[] = [];
  loadingData: boolean = false;
  savingData: boolean = false;
  timeoutId: any = null;
  $v;



  @Validation()
  validationObject() {
    return {
      reason: {
        required,
      },
    };
  }



  get showing() {
    return AirSearchStore.showingMissedSavingsModal;
  }

  get loading() {
    return !AirSearchStore.searchCompleted || this.loadingData;
  }

  get reasonObject() {
    return this.reasonOptions.find(item => item.value === this.reason) || null;
  }

  set reasonObject(value) {
    if (!value) {
      this.reason = '';
      return;
    }
    this.reason = value.value;
  }

  get reasonOptions() {
    if (!this.missedSavingReasonCodes) {
      return [];
    }
    return this.missedSavingReasonCodes
      .map(item => ({
        value: item.reasonCode,
        label: item.displayValue,
      }));
  }

  get searchCompleted() {
    return AirSearchStore.searchCompleted;
  }

  get missedSavingsInfo() {
    return AirSearchStore.missedSavingsInfo;
  }

  get cheapestRecommendation() {
    if (!this.missedSavingsInfo || !this.missedSavingsInfo.missedSaving) {
      return null;
    }
    return this.missedSavingsInfo.missedSaving.cheapestRecommendation;
  }

  get chosenRecommendation() {
    if (!this.missedSavingsInfo || !this.missedSavingsInfo.missedSaving) {
      return null;
    }
    return this.missedSavingsInfo.missedSaving.chosenRecommendation;
  }

  get missedSavingReasonCodes() {
    if (!this.missedSavingsInfo || !this.missedSavingsInfo.missedSaving) {
      return null;
    }
    return this.missedSavingsInfo.missedSaving.missedSavingReasonCodes;
  }

  get missedSavingValue() {
    if (
      !this.missedSavingsInfo ||
      !this.missedSavingsInfo.missedSaving ||
      !this.missedSavingsInfo.missedSaving.missedSavingValue
    ) {
      return null;
    }
    return this.missedSavingsInfo.missedSaving.missedSavingValue;
  }

  get cheapestOffer() {
    if (!this.cheapestRecommendation) {
      return null;
    }

    return this.cheapestRecommendation.airRecommendation;
  }

  get chosenOffer() {
    if (!this.chosenRecommendation) {
      return null;
    }

    return this.chosenRecommendation.airRecommendation;
  }



  @Watch('showing')
  onShowingChanged(value) {
    if (!value) {
      AirSearchStore.setBasketItemRequest(null);
      this.reason = '';
      this.errors = [];
      this.$v.$reset();
    }

    if (
      this.$route.name === 'airResultDetailsView' &&
      this.missedSavingsInfo &&
      this.missedSavingsInfo.isMissedSavingsCalculationInProgress
    ) {
      this.stepChecking();
    }
  }

  @Watch('searchCompleted')
  onSearchCompleted(value) {
    if (!value) {
      return;
    }

    if (this.showing) {
      this.loadData();
    }
  }

  async stepChecking() {
    try {
      const response = await AirApi.createBasket(AirSearchStore.searchId, AirSearchStore.basketItemsRequest);

      if (response && response.data && !response.data.isMissedSavingsCalculationInProgress) {
        AirSearchStore.setMissedSavingsInfo(response.data);
        AirSearchStore.setSearchCompleted(true);
      } else {
        this.timeoutId = setTimeout(() => {
          this.stepChecking();
        }, 1000);
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

  async loadData() {
    if (this.loadingData || !AirSearchStore.basketItemsRequest) {
      return;
    }

    this.loadingData = true;
    await AirSearchStore.CheckIfCanAddOfferToCart(AirSearchStore.basketItemsRequest);

    this.loadingData = false;
  }

  closePopup() {
    EventBus.$emit('selecting-aborted');
    AirSearchStore.clear();
    AirSearchStore.setBasketItemRequest(null);
    AirSearchStore.setMissedSavingsInfo(null);
    AirSearchStore.toggleMissedSavingsModal(false);
  }

  async save() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.savingData = true;
    await AirSearchStore.AddOfferToCartWithReason(this.reason);
    this.errors = AirSearchStore.errMessages;
    this.savingData = false;

    if (this.errors.length > 0) {
      return;
    }

    this.closePopup();
  }

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  }
}
