import Search from '@/modules/search/Search.vue';
import Home from '@/modules/home/Home.vue';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/search',
  name: 'search',
  components: {
    default: Home,
    intro: Search,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-search',
    waitForDicts: true,
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      }
    },
    permission: 'SearchOffers'
  }
};
