import BillingConfigurations from '@/modules/settings/billing/BillingConfigurations.vue';

export default {
  path: '',
  name: 'billing-configurations',
  component: BillingConfigurations,
  displayName: 'Billing Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadBackOfficeConfiguration',
    depthCategory: 'billing-configurations',
    depth: 1,
    parentName: 'billing-configurations',
  }
};