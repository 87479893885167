import HotelMap from '@/modules/search/hotel/HotelMap.vue';
import HotelMapFilters from '@/modules/search/hotel/HotelMapFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/hotel-results-map/:searchId/:selectedId?',
  name: 'hotelMap',
  components: {
    sidebar: HotelMapFilters,
    background: HotelMap,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-hotel-results',
    permission: 'SearchOffers',
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        hotelDetails: AnimationName.DOWN,
        hotelTravellers: AnimationName.DOWN,
      },
      default: {
        hotel: AnimationName.LEFT,
        home: AnimationName.LEFT,
      },
    },
    fullscreen: true
  }
};
