






































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength } from 'vuelidate/lib/validators';
import { PreferencesApi } from '@/api/profile/preferences.api';
import { PreferencesModel } from '@/api/profile/preferences.model';
import DictionaryStore from '@/store/dictionary.store';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class RailPreferencesPopup extends Vue {
  @Prop() preferences!: any;
  serverErrors: any[] = [];
  formPending: boolean = false;
  Form: PreferencesModel = {
    railPreferences: {
      seatType: null,
      generalInformations: '',
      carriers: [],
      departureStations: [],
      arrivalStations: [],
    },
  };
  $v;
  seatTypeOptions = [
    {
      value: 'Aisle',
      name: translate('profile-preferences.aisle'),
    },
    {
      value: 'Window',
      name: translate('profile-preferences.window'),
    },
  ];

  @Validation()
  validationObject() {
    return {
      Form: {
        railPreferences: {
          generalInformations: {
            maxLength: maxLength(2048),
          }
        }
      },
    };
  }

  get allAirlines() {
    return DictionaryStore.allAirLines;
  }

  updateFormData(data) {
    if (data) {
      this.Form = {
        railPreferences: {
          seatType: data.seatType !== 'Undefined' ? this.seatTypeOptions.find(type => type.value === data.seatType) : null,
          generalInformations: data.generalInformations,
          carriers: data.carriers,
          departureStations: data.departureStations,
          arrivalStations: data.arrivalStations,
        }
      };
    }
  }

  @Emit()
  close() {
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    try {
      if (this.preferences) {
        let profileId = this.$route.params.id;
        let request = {
          travellerType: this.preferences.travellerType,
          currencyCode: this.preferences.currencyCode,          
          flightPreferences: {
            seatType: this.preferences.flightPreferences!.seatType,
            generalInformations: this.preferences.flightPreferences!.generalInformations,
            carriers: this.preferences.flightPreferences!.carriers.map((carrier: any) => carrier.code),
            departureAirports: this.preferences.flightPreferences!.departureAirports.map(departure => departure.code),
            arrivalAirports: this.preferences.flightPreferences!.arrivalAirports.map(arrival => arrival.code),
          },
          railPreferences: {
            seatType: this.Form.railPreferences!.seatType ? this.Form.railPreferences!.seatType.value : null,
            generalInformations: this.Form.railPreferences!.generalInformations,
            carriers: this.Form.railPreferences!.carriers,
            departureStations: this.Form.railPreferences!.departureStations,
            arrivalStations: this.Form.railPreferences!.arrivalStations,
          },
          accommodationPreferences: {
            ...this.preferences.accommodationPreferences,
            roomTypes: this.preferences.accommodationPreferences!.roomTypes.map(room => room.code ? room.code : room),
          },
          carPreferences: {
            ...this.preferences.carPreferences
          },
        };

        await PreferencesApi.updatePreferences(profileId, request);
      }
      let obj = {
        type: 'success',
        title: translate('profile-personal.data-saved'),
        message: translate('profile-personal.rail-saved')
      };
      EventHandler.$emit('show-toast', obj);
      EventHandler.$emit('reload-loyalty');
      EventHandler.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  created() {
    if (this.preferences && this.preferences.railPreferences) {
      this.updateFormData(this.preferences.railPreferences);
    }
  }

}
