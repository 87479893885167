





























































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import TrainTimeline from './TrainTimeline.vue';
import TrainDetails from './TrainDetails.vue';
import SearchStore from '@/modules/search/search.store';
import TrainSearchStore from './train-search.store';
import { TrainOfferResult, Fare, Proposal } from '@/api/train-engine/train-search.model';
import EventBus from '@/services/event-handler';
import { SupplierName } from '@/services/supplier-name.service';
import { router } from '@/router';
import { translate } from '@/i18n';
import formatPrice from '@/filters/format-price.filter';
import { trainLogo } from '@/const/train-logo-mappings.const';
import settings from '@/settings';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({
  components: {
    TrainTimeline,
    TrainDetails,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    }
  }
})
export default class TrainResultsRow extends Vue {
  @Prop() offer!: TrainOfferResult;
  @Prop({ default: false }) offerSelectionDisabled: boolean = false;
  @Prop() proposal!: Proposal;
  isOpen: boolean = false;
  showDetails: boolean = false;
  disabledInfo: string = 'Max. 3 offers can be selected';
  selectedOfferId: string = '';
  showFullConditionsPopup: boolean = false;
  imageProcessed: boolean = false;
  imageLoading: boolean = true;
  imageVisible: boolean = true;

  get trainSearchParameters() {
    return SearchStore.getTrainDefaultState;
  }

  get timelinesCollapsed() {
    return false;
  }

  get currentUser() {
    return TrainSearchStore.currentUser;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get uniqTrainById() {
    return _.uniqBy(this.offer.journeys[0].trains, 'segmentKey');
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get trainTypeInfo() {
    return this.offer.journeys[0].trains.filter(train => train.type !== 'Stop');
  }

  get legNumber() {
    return this.$route.params.leg ? parseInt(this.$route.params.leg as string) : 1;
  }

  get providerClass() {
    if (this.offer && this.offer.supplier) {
      return 'flag flag__' + (this.offer.supplier).toLowerCase();
    }
  }

  get flagClasses() {
    return {
      'flag__public': this.offer.selectedProposal![0].isNego === false,
      'flag__nego': this.offer.selectedProposal![0].isNego === true,
    };
  }

  get offerPrice() {
    let selectedFares: Fare[] = [];
    let price = 0;
    this.uniqTrainById.forEach(train => {
      this.offer.proposals.forEach(proposal => {
        if (train.segmentKey === proposal.segmentKey) {
          let fare = proposal.fares.find(p => p.isSelected);
          selectedFares.push(fare as Fare);
        }
      });
    });
    if (selectedFares.length) {
      selectedFares.forEach(s => {
        if (s) {
          price += (s as Fare).price.amount;
        }
      });
    }

    return price;
  }

  get modifyMode() {
    return -1 < [
      'trainModification',
    ].indexOf(this.$route.name || '');
  }


  displayImg(ride) {
    if (!this.imageLoading || !ride || !ride.trainInfo) {
      return '';
    }
    const key = this.offer.supplier === 'Benerail' ? ride.trainInfo.trainCode : ride.trainInfo.trainType;
    return trainLogo[key];
  }

  filterTrains(trains) {
    const filterTrain = trains
      .filter(item => item.type !== 'Stop')
      .filter((el, index, array) => {
        const pathIndex = array
          .map((ride) => this.displayImg(ride))
          .indexOf(this.displayImg(el));
        return pathIndex === index;
      });
    return filterTrain;
  }

  getTrainClasses(proposals: any[]) {
    if (proposals.length) {
      return proposals.map(proposal => {
        if (proposal.class) {
          return proposal.class;
        }
      }).join(', ');
    } else {
      return '';
    }
  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  getProposalList(train) {
    return this.offer.proposals.find(proposal => {
      return proposal.segmentKey === train.segmentKey;
    });
  }

  toggleDetails() {
     if (window.innerWidth <= 800) {
      return;
    }

    this.showDetails = !this.showDetails;
    this.isOpen = true;
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  priceTooltip() {
    let tooltip = ``;

    if (this.offer.selectedProposal && this.offer.selectedProposal[0].rawProviderPrice) {
      tooltip += `<div>${translate('basket.raw-provider-price')} ${this.offer.selectedProposal[0].rawProviderPrice.currency.symbol || this.offer.selectedProposal[0].rawProviderPrice.currency.code} ${formatPrice(this.offer.selectedProposal[0].rawProviderPrice.amount)}</div>`;
    }
    if (this.offer.minSystemMarkup) {
      tooltip += `<div class="mb-3">${translate('basket.system-markup')} ${this.offer.minSystemMarkup.currency.symbol || this.offer.minSystemMarkup.currency.code} ${formatPrice(this.offer.minSystemMarkup.amount)}</div>`;
    }

    if (this.offer.selectedProposal && this.offer.selectedProposal[0].basePrice) {
      tooltip += `<div>${translate('basket.incl')} ${this.offer.selectedProposal[0].basePrice.currency.symbol || this.offer.selectedProposal[0].basePrice.currency.code} ${formatPrice(this.offer.selectedProposal[0].basePrice.amount)} ${translate('search-air.provider-price')}`;

      tooltip += `</div>`;
    }


    if (this.offer.minAgencyMarkup) {
      tooltip += `<div>${translate('basket.incl')} ${this.offer.minAgencyMarkup.currency.symbol || this.offer.minAgencyMarkup.currency.code} ${formatPrice(this.offer.minAgencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</div>`;
    }
    if (this.offer.minAgencyFee !== null) {
      tooltip += `<div>${translate('basket.incl')} ${this.offer.minAgencyFee.currency.symbol || this.offer.minAgencyFee.currency.code} ${formatPrice(this.offer.minAgencyFee.amount)} ${translate('search-air.fee')}</div>`;
    }
    return tooltip;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  currentlySelectedOffer(offerId) {
    return offerId === this.selectedOfferId;
  }

  imageError(ride) {
    if ('' === this.displayImg(ride)) {
      this.imageProcessed = true;
      return;
    }

    this.imageVisible = false;
    this.imageProcessed = true;
  }

  async selectOffer(offer) {
    let data = {
      searchId: this.$route.params.searchId,
      leg: this.$route.params.leg,
      offer: offer
    };

    await TrainSearchStore.selectOffer(data);
    this.showDetails = false;
  }

  trainSelectOffer(offer) {
    this.selectOffer(offer);
  }

  mounted() {
    EventBus.$on('train-proposal-offer', this.trainSelectOffer);
  }

  beforeDestroy() {
    EventBus.$off('train-proposal-offer', this.trainSelectOffer);
  }

  showMobileOfferDetails(offer) {
    if (this.modifyMode) {
      const modifyName = 'trainModificationDetailsView';

      router.push({
        name: modifyName,
        params: {
          searchId: this.$route.params.searchId,
          recomendationId: offer.id,
          basketId: this.$route.params.basketId,
          leg: this.$route.params.leg,
        }
      });

    } else {
      let name = 'trainResultDetailsView';

      router.push({
        name: name,
        params: {
          searchId: this.$route.params.searchId,
          recomendationId: offer.id,
          leg: this.$route.params.leg,
        }
      });
    }
  }

  @Watch('$route', { deep: true, immediate: true })
  routeHandler() {
    this.isOpen = false;
  }
}
