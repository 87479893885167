







































































import { Component } from 'vue-property-decorator';
import moment from 'moment';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { TripsTableParams } from '@/modules/trips/trips-table.params';
import { TripApi } from '@/api/trip/trip.api';
import {
  SearchTrip,
} from '@/api/trip/trip.model';
import { translate } from '@/i18n';

@Component({})
export default class TripsIncludedMissionTable extends BaseTable<SearchTrip> {
  missionId: string | null = null;
  useQueryParams: boolean = true;
  params: TripsTableParams = new TripsTableParams({});
  fields = {
    name: {
      label: translate('add-mission.trip-name'),
      class: 'add-mission-table__column--name',
    },
    created: {
      label: translate('add-mission.trip-created-date'),
      class: 'expense-tableadd-mission-table__column--created-date',
    },
    dates: {
      label: translate('add-mission.trip-dates'),
      class: 'add-mission-table__column--dates',
    },
    status: {
      label: translate('add-mission.trip-status'),
      class: 'add-mission-table__column--status',
    },
    totalPrice: {
      label: translate('add-mission.trip-total-price'),
      class: 'expense-tableadd-mission-table__column--total-price',
    },
    actionsColumn: {
      label: '',
      class: 'add-mission-table__column--actions',
    },
  };
  formPending: boolean = false;
  errMessages: any[] = [];

  async getItems(params: TripsTableParams): Promise<GetItemsResult<SearchTrip>> {
    this.missionId = this.$route.params.missionId || this.$route.params.id;
    let searchParams = {
      period: params.period,
      destination: params.destination,
      startDate: {
        from: params.startDate.from ?  moment(params.startDate.from).format('YYYY-MM-DD') : null,
        to: params.startDate.to ?  moment(params.startDate.to).format('YYYY-MM-DD') : null,
      },
      endDate: {
        from: params.endDate.from ?  moment(params.endDate.from).format('YYYY-MM-DD') : null,
        to: params.endDate.to ?  moment(params.endDate.to).format('YYYY-MM-DD') : null,
      },
      tripCode: params.tripCode,
      basketStatuses: params.basketStatuses.map(status => status.value),
      serviceTypes: params.serviceTypes.map(type => type.value),
      bookers: params.bookers.map(user => user.id),
      travellers: params.travellers.map(user => user.id),
      companies: params.companies.map(user => user.id),
      sortOnField: params.sortOnField,
      sortDirection: params.sortDirection,
      mission: 1,
      missionId: this.missionId,
    };

    try {
      const result = await TripApi.search({
        page: params.page,
        size: params.size,
      }, searchParams);
     
      return {
        page: result.data.page,
        results: result.data.results,
      };
    } catch (error) {
      this.errMessages = this.$handleErrors(error);
      throw(error);
    }
  }

  tripDestination(destinations) {
    return destinations && destinations.length ? destinations.join(', ') : '';
  }
}
