





















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class UiInput extends Vue {
  @Prop() value: any;
  @Prop() placeholder!: string;
  @Prop() maxlength!: string;
  @Prop({
    default: false
  }) disabled!: boolean;
  @Prop({ default: false }) showReset!: boolean;
  @Prop() autocomplete!: string;
  @Prop({ default: true }) trim!: boolean;

  get listeners() {
    return {
      ...this.$listeners,
      input: event => this.Input(event.target.value),
    };
  }

  @Emit('input')
  Input(value) {
    return this.trim ? value.trim() : value;
  }

  resetValue() {
    this.Input('');
    this.$emit('reset-event');
  }

  focus() {
    const inputRef = this.$refs.input as HTMLElement;
    if (inputRef) {
      inputRef.focus();
    }
  }
}
