import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { Permission } from '@/const/permission.enum';
import { ExpensePerDiemRatesApi } from '@/api/expense/expense-per-diem.api';
import {
  PerDiemSettingsResult,
  PerDiemRatesResult,
  LengthOfStayPerDiemRuleResult,
  ReductionReasonPerDiemRulesResult,
} from '@/api/expense/expense-per-diem.model';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import $handleErrors from '@/core/errors/handle-errors.service';
import { translate } from '@/i18n';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'expensePerDiemStore'
})
class ExpensePerDiemStore extends VuexModule {
  errMessages: string[] = [];
  showError: boolean = false;
  loading: boolean = false;
  perDiemActiveTab: string = 'LengthOfStay';
  perDiemSettings: PerDiemSettingsResult = new PerDiemSettingsResult();
  perDiemRates: any[] = [];
  perDiemRate: PerDiemRatesResult = new PerDiemRatesResult();
  lengthOfStayPerDiemRules: any[] = [];
  lengthOfStayPerDiemRule: LengthOfStayPerDiemRuleResult = new LengthOfStayPerDiemRuleResult();
  reductionReasonPerDiemRules: any[] = [];
  reductionReasonPerDiemRule: ReductionReasonPerDiemRulesResult = new ReductionReasonPerDiemRulesResult();

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setPerDiemActiveTab(value) {
    this.perDiemActiveTab = value;
  }

  @Mutation
  setPerDiemRates(value) {
    this.perDiemRates = value;
  }

  @Mutation
  setPerDiemRate(value) {
    this.perDiemRate = value;
  }

  @Mutation
  setLengthOfStayPerDiemRules(value) {
    this.lengthOfStayPerDiemRules = value;
  }

  @Mutation
  setLengthOfStayPerDiemRule(value) {
    this.lengthOfStayPerDiemRule = value;
  }

  @Mutation
  setReductionReasonPerDiemRules(value) {
    this.reductionReasonPerDiemRules = value;
  }

  @Mutation
  setReductionReasonPerDiemRule(value) {
    this.reductionReasonPerDiemRule = value;
  }

  @Mutation
  setPerDiemSettings(value) {
    this.perDiemSettings = value;
  }

  @Action
  async getPerDiemSettings(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getPerDiemSettings(policyId);
      if (result && result.data) {
        this.setPerDiemSettings(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async savePerDiemSettings(data) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.savePerDiemSettings(data.policyId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.settings-saved'),
        message: translate(
          'settings-expense.settings-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getPerDiemRates(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getPerDiemRates(policyId);
      if (result && result.data) {
        this.setPerDiemRates(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getPerDiemRate(perDiemRateId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getPerDiemRate(perDiemRateId);
      if (result && result.data) {
        this.setPerDiemRate(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createPerDiemRate(params) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.createPerDiemRate(params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.rate-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updatePerDiemRate(data) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.updatePerDiemRate(data.perDiemRateId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.rate-updated-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getLengthOfStayPerDiemRules(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getLengthOfStayPerDiemRules(policyId);
      if (result && result.data) {
        this.setLengthOfStayPerDiemRules(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getLengthOfStayPerDiemRule(perDiemRateId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getLengthOfStayPerDiemRule(perDiemRateId);
      if (result && result.data) {
        this.setLengthOfStayPerDiemRule(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createLengthOfStayPerDiemRule(params) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.createLengthOfStayPerDiemRule(params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.length-rule-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateLengthOfStayPerDiemRule(data) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.updateLengthOfStayPerDiemRule(data.perDiemRateId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.length-rule-updated-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getReductionReasonPerDiemRules(policyId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getReductionReasonPerDiemRules(policyId);
      if (result && result.data) {
        this.setReductionReasonPerDiemRules(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getReductionReasonPerDiemRule(perDiemRateId) {
    try {
      this.setLoading(true);
      const result = await ExpensePerDiemRatesApi.getReductionReasonPerDiemRule(perDiemRateId);
      if (result && result.data) {
        this.setReductionReasonPerDiemRule(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createReductionReasonPerDiemRule(params) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.createReductionReasonPerDiemRule(params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-saved'),
        message: translate(
          'settings-expense.reason-rule-saved-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateReductionReasonPerDiemRule(data) {
    try {
      this.setLoading(true);
      await ExpensePerDiemRatesApi.updateReductionReasonPerDiemRule(data.perDiemRateId, data.params);
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-expense.configuration-updated'),
        message: translate(
          'settings-expense.reason-rule-updated-message'
        )
      });
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(ExpensePerDiemStore);