import AccountStore from '@/store/account.store';
import DictionaryStore from '@/store/dictionary.store';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

export default async () => {
  try {
    const response = await http.execute({
      path: '/settings.json',
      method: RequestMethod.GET,
    }, {}, {});
    settings.update(response.data);
    AccountStore.setSettingsLoaded(true);
  } catch (error) {
    AccountStore.setPreloadError(true);
    return;
  }

  await AccountStore.init();
  DictionaryStore.init();
};
