import MyPools from '@/modules/home/MyPools.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/my-pools',
  name: 'myPools',
  components: {
    default: MyPools,
  },
  meta: {
    introClass: '',
    permission: 'ReadCompanyStructure',
    transitions: {
      intro: {
        $default: AnimationName.FADE_UP
      },
    }
  }
};
