


















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PaymentCardProfileModel } from '@/api/profile/payment-cards.model';
import { PaymentCardsApi } from '@/api/profile/payment-cards.api';
import PaymentCardPopup from './PaymentCardPopup.vue';
import PaymentCardRemovePopup from './PaymentCardRemovePopup.vue';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({
  components: {
    PaymentCardPopup,
    PaymentCardRemovePopup,
  }
})
export default class PaymentCards extends Vue {
  paymentCards: PaymentCardProfileModel[] = [];
  profileId: string | null = null;
  showCardPopup: boolean = false;
  showCardRemovePopup: boolean = false;
  newTitlePopup: boolean = false;
  selectedCard: PaymentCardProfileModel | null = null;
  selectedCardToRemove: PaymentCardProfileModel | null = null;
  serverErrors: any[] = [];

  async init() {
    try {
      this.profileId = this.$route.params.id;
      const paymentCardsResult = await PaymentCardsApi.getPaymentCards(this.profileId);

      if (paymentCardsResult && paymentCardsResult.data) {
        this.paymentCards = paymentCardsResult.data;
      }
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    }
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    this.serverErrors = [];
    this.init();
  }

  updateCard() {
    this.init();
  }

  removeCard(card) {
    this.selectedCardToRemove = Object.assign({}, card);
    this.showCardRemovePopup = true;
  }

  formatCreditCard(cardNumer) {
    if (cardNumer) {
      let prefix = cardNumer.substring(0, 4);
      let numberStars = cardNumer.split('*').length - 1;
      let middlePrefix;
      if (numberStars < 8) {
        middlePrefix = cardNumer.substring(4, 4 + numberStars);
      } else {
        middlePrefix = cardNumer.substring(4, 8) + ' ' + cardNumer.substring(8, 12);
      }

      let lastPrefix = cardNumer.slice(-4);
      return prefix + ' ' + middlePrefix + ' ' + lastPrefix;
    }
  }

  editCard(card) {
    if (card) {
      this.selectedCard = Object.assign({}, card);
      this.showCardPopup = true;
      this.newTitlePopup = false;
    }
  }

  showAddNewCardPopup() {
    this.selectedCard = null;
    this.showCardPopup = true;
    this.newTitlePopup = true;
  }
}
