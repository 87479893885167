import AccountStore from '@/store/account.store';

export default async (to, from, next) => {
  // only applies to profile section
  if (!to.matched.length || 'profile' !== to.matched[0].name) {
    next();
    return;
  }

  const defaultRedirect = (redirect) => {
    if (!from.matched.length) {
      redirect.replace = true;
    }
    next(redirect);
  };

  if (1 === to.matched.length) {
    // redirect to current profile
    const profileId = AccountStore.CurrentUser!.profile.id;
    let redirect = {
      path: to.fullPath + '/' + profileId,
    };
    defaultRedirect(redirect);
    return;
  }

  if (2 <= to.matched.length) {
    const result = to.matched[to.matched.length - 1].regex.exec(to.fullPath);

    if (result.length && result.length >= 2) {
      if ('default' === result[1]) {
        // redirect to default profile
        const profileId = AccountStore.CurrentUser!.originalProfileId;

        let redirect = {
          path: to.fullPath.replace('default', profileId),
        };
        defaultRedirect(redirect);
        return;
      }
      
      if ('current' === result[1]) {
        // redirect to current profile
        const profileId = AccountStore.CurrentUser!.profile.id;

        let redirect = {
          path: to.fullPath.replace('current', profileId),
        };
        defaultRedirect(redirect);
        return;
      }
    }
  }
  next();
};
