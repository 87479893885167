var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper container"},[_c('div',{staticClass:"search-params d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('UiIcon',{staticClass:"search-params__service-icon",attrs:{"icon":"airplanemode_active"}}),_c('div',{staticClass:"tab-container"},[(_vm.airSearchParameters.searchMode === 'MultiLeg')?_c('UiHorizontalScrollSection',{attrs:{"hideYScroll":true}},[_c('ul',{staticClass:"nav nav-tabs air-trip-tabs nav-tabs-danger search-form-tabs search-results",attrs:{"role":"tablist"}},_vm._l((_vm.airSearchParameters.legs),function(leg,index){return _c('li',{key:leg.legNumber,staticClass:"nav-item click-link"},[_c('a',{staticClass:"nav-link active show leg-tab-link",attrs:{"href":"#results-leg-1","data-toggle":"tab","role":"tab","aria-selected":"true"}},[_c('div',{staticClass:"leg-date"},[_vm._v(_vm._s(_vm.multiDepartureDate(leg))+" ("+_vm._s(_vm.multiDepartureDay(leg))+")")]),_c('div',{staticClass:"leg-locations",class:{'multi-city': _vm.airSearchParameters.searchMode === 'MultiLeg'}},[(_vm.multiFromName(leg))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: _vm.multiFromName(leg),
                    }),expression:"{\n                      content: multiFromName(leg),\n                    }"}],ref:'nameFromFirstWith' + index,refInFor:true,staticClass:"leg-locations__place"},[_vm._v(_vm._s(_vm.multiFromName(leg)))]):_vm._e(),_c('UiIcon',{attrs:{"icon":"play_arrow"}}),(_vm.multiToName(leg))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: _vm.multiToName(leg),
                    }),expression:"{\n                      content: multiToName(leg),\n                    }"}],ref:'nameToFirstWith' + index,refInFor:true,staticClass:"leg-locations__place"},[_vm._v(_vm._s(_vm.multiToName(leg)))]):_vm._e()],1)])])}),0)]):_c('ul',{staticClass:"nav nav-tabs air-trip-tabs nav-tabs-danger search-form-tabs search-results",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item click-link"},[_c('a',{staticClass:"nav-link active show leg-tab-link",attrs:{"href":"#results-leg-1","data-toggle":"tab","role":"tab","aria-selected":"true"}},[_c('div',{staticClass:"leg-date"},[_vm._v(_vm._s(_vm.departureDate)+" ("+_vm._s(_vm.departureDay)+")")]),_c('div',{staticClass:"leg-locations"},[(_vm.fromName)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.fromName,
                  }),expression:"{\n                    content: fromName,\n                  }"}],ref:"nameFromFirstWith",staticClass:"leg-locations__place"},[_vm._v(_vm._s(_vm.fromName)),(_vm.nameFromFirstWith)?_c('span'):_vm._e()]):_vm._e(),_c('UiIcon',{attrs:{"icon":"play_arrow"}}),(_vm.toName)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.toName,
                  }),expression:"{\n                    content: toName,\n                  }"}],ref:"nameToFirstWith",staticClass:"leg-locations__place"},[_vm._v(_vm._s(_vm.toName)),(_vm.nameToFirstWith)?_c('span'):_vm._e()]):_vm._e()],1)])]),(_vm.airSearchParameters.searchMode == 'RoundTrip')?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active show leg-tab-link",attrs:{"href":"#results-leg-2","data-toggle":"tab","role":"tab","aria-selected":"true"}},[_c('div',{staticClass:"leg-date"},[_vm._v(_vm._s(_vm.returnDate)+" ("+_vm._s(_vm.returnDay)+")")]),_c('div',{staticClass:"leg-locations"},[(_vm.toName)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.toName,
                  }),expression:"{\n                    content: toName,\n                  }"}],ref:"nameToSecondWith",staticClass:"leg-locations__place"},[_vm._v(_vm._s(_vm.toName)),(_vm.nameToSecondWith)?_c('span'):_vm._e()]):_vm._e(),_c('UiIcon',{attrs:{"icon":"play_arrow"}}),(_vm.fromName)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.fromName,
                  }),expression:"{\n                    content: fromName,\n                  }"}],ref:"nameFromSecondWith",staticClass:"leg-locations__place"},[_vm._v(_vm._s(_vm.fromName)),(_vm.nameFromSecondWith)?_c('span'):_vm._e()]):_vm._e()],1)])]):_vm._e()])],1),_c('div',{staticClass:"edit-search"},[_c('span',{staticClass:"edit-search-link",on:{"click":_vm.criteria}},[_c('UiIcon',{attrs:{"icon":"edit"}})],1)])],1),_c('div',{staticClass:"travellers-section"},[_c('div',{staticClass:"row search-travellers justify-content-end flex-nowrap"},[(_vm.travellersSearchList.travellers.length > 1 && !_vm.skipTravellers)?_c('div',{staticClass:"search-traveller show-text",on:{"click":_vm.travellers}},[_c('span',[_vm._v(_vm._s(_vm.$t('search-air.show-more')))])]):_vm._e(),_c('SearchTripTravellers')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }