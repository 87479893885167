













































import { Vue, Component, Prop } from 'vue-property-decorator';
import { TrainOfferResult, Train } from '@/api/train-engine/train-search.model';
import TrainProposal from './TrainProposal.vue';
import TrainTimelineDetailsBasket from './TrainTimelineDetailsBasket.vue';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: {
    TrainProposal,
    TrainTimelineDetailsBasket,
  },
  filters: {
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('dddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    momentDate2(date) {
      return moment(date).format('Do MMM');
    },
  }
})
export default class TrainDetailsBasket extends Vue {
  @Prop() provider!: string;
  @Prop() offer!: TrainOfferResult;
  @Prop() travellers!: any[];
  @Prop({ default: false }) isInPopup!: boolean;

  selectedTrain: Train | null = null;
  showMore: boolean = true;

  uniqTrains(journey, uniqTrain) {
    const startIndex = journey.trains.findIndex(train => train.segmentKey === uniqTrain.segmentKey);
    let lastIndexOf = journey.trains.map(train => train.segmentKey).lastIndexOf(uniqTrain.segmentKey);
    if (lastIndexOf < journey.trains.length - 1 && journey.trains[lastIndexOf + 1].type === 'Stop') {
      lastIndexOf++;
    }

    return journey.trains.slice(startIndex, lastIndexOf + 1);
  }

  getOfferProposals(proposals, train) {
    let fare = null;
    proposals.forEach(proposal => {
      if (proposal.segmentKey === train.segmentKey) {
        fare = {
          segmentKey: proposal.segmentKey,
          ...proposal.fares[0],
        };
      }
    });

    return fare;
  }

  uniqTrainInJourney(journey) {
    return _.uniqBy(journey.trains.filter(train => {
      return train.type === 'Train';
    }) as Train[], 'segmentKey');
  }
}
