import LoyaltyPrograms from '@/modules/profile/loyalty-programs/LoyaltyPrograms.vue';

export default {
  path: 'loyalty-programs',
  name: 'loyalty-programs',
  component: LoyaltyPrograms,
  displayName: 'profile-loyalty.loyalty-programs',
  meta: {
    permission: 'ReadProfileLoyalties',
    icon: 'loyalty',
  }
};