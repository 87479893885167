import FaresMappingConfiguratios from '@/modules/settings/fares-mapping/FaresMappingConfiguration.vue';

export default {
  path: ':configurationId?/fares-mapping-configuration',
  name: 'fares-mapping-configuration',
  component: FaresMappingConfiguratios,
  displayName: 'settings-sidebar.fares-mapping',
  meta: {
    breadCrumb: 'Fares mapping configuration',
    introClass: 'intro-settings',
    permission: 'CanEditFareMapping',
    parentName: 'fares-mapping-configurations',
    depthCategory: 'fares-mapping-configurations',
    depth: 2,
  }
};