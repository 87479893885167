


























































































































import { Vue, Component } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { Validation } from 'vue-plugin-helper-decorator';
import { TripApi } from '@/api/trip/trip.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import BasketStore from '@/modules/basket/basket.store';

const Status = {
  view: 'view',
  edit: 'edit',
};

@Component({})
export default class BasketTripNotes extends Vue {
  Status = Status;
  $v;
  loading: boolean = false;
  removing: boolean = false;
  savingErrors: any[] = [];
  removingErrors: any[] = [];
  noteContent: string = '';
  showPopup: boolean = false;
  status: string = Status.view;

  @Validation()
  validationObject() {
    return {
      noteContent: { required },
    };
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get basketNotes() {
    return BasketStore.basket && BasketStore.basket.notes;
  }

  get notes() {
    return this.basketNotes && this.basketNotes.notes;
  }

  get canEditNote() {
    return this.basketNotes && this.basketNotes.canEditNote;
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  async updateBasket() {
    try {
      this.loading = true;
      this.noteContent = '';
      await BasketStore.updateBasket();
    } finally {
      this.loading = false;
    }
  }

  async removeTripNote() {
    try {
      this.removing = true;
      await TripApi.removeTripNote(this.basketId);
      this.showPopup = false;
    } catch (error) {
      this.removingErrors = $handleErrors(error);
    } finally {
      this.removing = false;
      await this.updateBasket();
    }
  }

  async saveTripNote() {
    const isValid = this.isValid();
    if (isValid) {
      try {
        this.loading = true;
        await TripApi.saveTripNote(this.basketId, { noteContent: this.noteContent });
        this.$v.$reset();
        this.status = Status.view;
      } catch (error) {
        this.savingErrors = $handleErrors(error);
      } finally {
        this.loading = false;
        await this.updateBasket();
      }
    }
  }

  onAddClick() {
    this.status = Status.edit;
  }

  onEditClick() {
    this.status = Status.edit;
    this.noteContent = this.notes || '';
  }

  onCancelClick() {
    this.status = Status.view;
    this.noteContent = '';
    this.$v.$reset();
  }
}
