































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import axios, { CancelTokenSource } from 'axios';
import { maxLength } from 'vuelidate/lib/validators';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import { TripApi } from '@/api/trip/trip.api';
import DictionaryStore from '@/store/dictionary.store';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import { Country } from '@/api/dictionary/dictionary.model';
import BasketStore from './basket.store';
import GestourCustomer from './GestourCustomer.vue';
import { Validation } from 'vue-plugin-helper-decorator';
import { isNameValid } from '@/core/validation/is-name-valid.validator';

@Component({
  components: {
    GestourCustomer,
    HeightTransition,
  },
})
export default class CustomerCodeSection extends Vue {
  @Prop() data!: any;

  showingPopup: boolean = false;
  shouldShowMoreFilters: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  items: any[] = [];
  params = {
    usualName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    zipCode: '',
    city: '',
    countryCode: '',
  };
  countryQuery: string = '';
  fields = {
    customer: {
      label: translate('basket-gestour.customer-data'),
      class: 'basket-customer-code-section__column-customer',
      tdClass: 'mw-250',
    },
    actions: {
      label: '',
      class: 'basket-customer-code-section__column-actions',
    },
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  hintErrors: any[] = [];
  errors: any[] = [];
  selectErrors: any[] = [];
  firstTime: boolean = true;
  cancelToken: CancelTokenSource = axios.CancelToken.source();

  isNotWhiteSpaced(model: string) {
    return model && model.length ? !!(model.trim()) : true;
  }

  @Validation()
  validationObject() {
    return {
      params: {
        usualName: {
          maxLength: maxLength(19),
          isNameValid,
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        }
      }
    };
  }

  get basketTravellers() {
    return BasketStore.basketTravellers;
  }

  get mainTraveller() {
    return this.basketTravellers.find(item => !!item.isMainTraveller) || this.basketTravellers[0];
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get customer() {
    if (!this.data.selectedCustomerCode) {
      return null;
    }

    return this.data.customers[0];
  }

  get countries() {
    return DictionaryStore.countries || [];
  }

  get filteredCountries() {
    const lowerCase = this.countryQuery.toLowerCase();

    return this.countries
      .filter(item => {
        return -1 < item.code.toLowerCase().indexOf(lowerCase) ||
          -1 < item.countryName.toLowerCase().indexOf(lowerCase);
      })
      .sort((a, b) => {
        if (a.code.toLowerCase() === lowerCase) {
          return -1;
        }
        if (b.code.toLowerCase() === lowerCase) {
          return 1;
        }
        return 0;
      });
  }

  get countryValue() {
    return this.countries.find(opt => opt.code === this.params.countryCode) || null;
  }

  set countryValue(value: Country | null) {
    this.params.countryCode = value ? value.code : '';
  }



  async select(customer) {
    if (this.saving) {
      return;
    }
    this.selectErrors = [];
    this.saving = true;
    try {
      await TripApi.saveGestourCustomer(this.basketId, customer);
      this.data.customers = [customer];
      this.data.selectedCustomerCode = customer.customerCode;

      this.showingPopup = false;
      this.validate();
    } catch (error) {
      this.selectErrors = this.$handleErrors(error);
      this.scrollToError();
    } finally {
      this.saving = false;
    }
  }

  async loadCustomers() {
    if (this.loading) {
      return;
    }
    this.errors = [];
    this.loading = true;
    this.firstTime = false;
    this.cancelToken = axios.CancelToken.source();
    try {
      const response = await TripApi.searchForGestourCustomer(this.basketId, this.params, this.cancelToken);

      this.items = [...response.data];
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  filterCountries(query) {
    this.countryQuery = query;
  }

  showPopup() {
    this.items = [];
    this.params = {
      usualName: '',
      lastName: '',
      email: '',
      phone: '',
      street: '',
      zipCode: '',
      city: '',
      countryCode: '',
    };
    if (this.data.selectedCustomerCode && this.data.customers && this.data.customers.length) {
      this.params.usualName = (this.data.customers[0].lastName + ' ' + this.data.customers[0].firstName).substring(0, 19);
      this.params.lastName = this.data.customers[0].lastName;
    } else if (this.mainTraveller) {
      this.params.usualName = (this.mainTraveller.lastName + ' ' + this.mainTraveller.firstName).substring(0, 19);
      this.params.lastName = this.mainTraveller.lastName;
    }
    this.shouldShowMoreFilters = false;
    this.showingPopup = true;
    this.loadCustomers();
  }

  get isFormInvalid() {
    return this.$v.$invalid;
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.error) {
        return;
      }
      ((this.$refs.error as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  validate() {
    this.hintErrors = [];
    BasketStore.setCustomerCodeSectionError(false);
    if (this.data.allowCustomerSearch && !this.data.selectedCustomerCode) {
      BasketStore.setCustomerCodeSectionError(true);
      this.hintErrors = [{
        message: translate('basket-gestour.no-customer-code-info'),
      }];
    }
  }

  focusOnSearch() {
    setTimeout(() => {
      const btn = this.$refs.search as Vue;

      if (!btn) {
        return;
      }
      (btn.$el as HTMLElement).focus();
    }, 100);
  }

  @Watch('showingPopup')
  cancelOnHide(value) {
    if (value) {
      return;
    }
    this.cancelToken.cancel();
  }



  created() {
    EventBus.$on('assign-travellers-data', this.validate);
  }

  beforeDestroy() {
    EventBus.$off('assign-travellers-data', this.validate);
  }
}

