






import { Vue, Component } from 'vue-property-decorator';

import Table2 from './Table2.vue';

@Component({
  components: {
    Table2,
  },
})
export default class Table2View extends Vue {}

