















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import CarSearchParamOnResults from './CarSearchParamOnResults.vue';

@Component({
  components: {
    CarSearchParamOnResults,
  }
})
export default class CarSearchShortParams extends Vue {
  searchId: string | null = null;

  get carSearchParameters() {
    return SearchStore.getCarDefaultState;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  showCriteria() {
    if (!router.currentRoute.params.searchId) {
      router.push({
        name: 'search'
      });
    } else {
      router.push({
        name: 'carDetails',
        params: router.currentRoute.params,
      });
    }
  }
  showTravellers() {
    router.push({
      name: 'carTravellers',
      params: router.currentRoute.params,
    });
  }
}
