

















































import { Vue, Component, Model, Prop } from 'vue-property-decorator';
import { AirFranceExtrasType } from '@/modules/search/air/air-airfrance-exchange.model';

const eventName = 'change';

@Component({
})
export default class AirAirFranceExchangeOfferExtrasTypeSelector extends Vue {
  @Model(eventName) ancillaryTypeModel!: any;
  @Prop() showReselectSeatsOption!: boolean;
  @Prop() showReselectAncillariesOption!: boolean;

  get ancillaryType() {
    return this.ancillaryTypeModel;
  }

  set ancillaryType(value) {
    this.$emit('change', value);
  }

  get options() {
    return [
      ...(this.showReselectSeatsOption ? [{ value: AirFranceExtrasType.Seats, label: 'exchange.reselect-seats'}] : []),
      ...(this.showReselectAncillariesOption ? [{ value: AirFranceExtrasType.Ancillaries, label: 'exchange.reselect-ancillaries'}] : []),
      { value: AirFranceExtrasType.None, label: 'exchange.reselect-none' }
    ];
  }
}

