

















































































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, numeric } from 'vuelidate/lib/validators';

import { userFullName } from '@/core/user-full-name';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { router } from '@/router';
import { Permission } from '@/const/permission.enum';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { ProfileApi } from '@/api/profile/profile.api';
import { AssignedManualApprover } from '@/api/expense/expense-workflow.model';

const closeApproverView = 'update:closeView';

@Component({})
export default class MissionApprovalResponsibilities extends Vue {
  @Prop() approver!: any | null;
  @Prop() approverSearch!: any[];
  @Prop() closeView!: boolean;
  $v;
  approverModel: any | null = null;
  formHasErrors: boolean = false;
  isLoading: boolean = false;
  approverList: any[] = [];

  @Validation()
  validationObject() {
    return {
      approverModel: {
        name: {
          required,
        },
        description: {
          maxLength: maxLength(1024)
        },
        approvalOrder: {
          required,
          numeric,
          maxLength: maxLength(64)
        },
      },
    };
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get isCopy() {
    if (router.currentRoute.params.isCopy === 'false') {
      return false;
    } else if (router.currentRoute.params.isCopy === 'true') {
      return true;
    }
    return router.currentRoute.params.isCopy;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get policyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get missionData() {
    return ExpensePolicyStore.expenseWorkflowSetting;
  }



  userFullName(user) {
    return userFullName(user);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async asyncFind(query: string) {
    this.isLoading = true;
    this.approverList = [];
    let companyId = this.currentCompany!.companyId;
    const response = await ProfileApi.searchApprovers( companyId, query, Permission.ReadApprovalWorkflow );
    if (response && response.data) {
      if (this.missionData && this.missionData.assignedManualApprovers && this.missionData.assignedManualApprovers.length) {
        this.approverList = response.data.filter(e => !this.missionData.assignedManualApprovers.some(a => a.profileId === e.profileId));
      } else {
        this.approverList = response.data;
      }
    } else {
      this.approverList = [];
    }
    this.isLoading = false;
  }

  close(value: boolean) {
    this.approverModel = null;
    this[closeApproverView](value);
  }

  @Emit()
  [closeApproverView](val) {
    return val;
  }

  focusNext() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'workflow-settings',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async saveApprover() {
    let configId = this.configurationId;
    this.$v.approverModel.$touch();
    if (this.$v.approverModel.$pending || this.$v.approverModel.$error) {
      return;
    }

    const exist = this.missionData.assignedManualApprovers.find(app => app.profileId === this.approverModel.profileId);
    const index = this.missionData.assignedManualApprovers.indexOf(exist as AssignedManualApprover);
    if (index > -1) {
      this.missionData.assignedManualApprovers[index] = {
        profileId: this.approverModel!.name.profileId,
        description: this.approverModel!.description,
        approvalOrder: this.approverModel!.approvalOrder,
      };
    } else {
      let dataToSend = {
        profileId: this.approverModel!.name.profileId,
        description: this.approverModel!.description,
        approvalOrder: this.approverModel!.approvalOrder,
      };
      ExpensePolicyStore.addNewApprover(dataToSend);
    }

    this.close(false);
  }

  mapperModel(data) {
    if (data) {
      this.approverModel = data;
    }
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    this.mapperModel(this.approver);
    if (this.approverSearch.length && this.approver) {
      this.approverModel.name = this.approverSearch.find(list => list.profileId === this.approver!.profileId);
    }
    if (!this.configuration.name) {
      if (this.$route.params.configurationId === undefined) {
        router.push({
          name: 'workflow-settings',
        });
      }
    }
  }
}
