




















































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import { BasketApi } from '@/api/trip/basket.api';
import EventBus from '@/services/event-handler';
import { userFullName } from '@/core/user-full-name';
import BasketCustomFieldItem from './BasketCustomFieldItem.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import BasketStore from './basket.store';

@Component({
  components: {
    BasketCustomFieldItem,
    BasketExpirationCountdown,
  },
})
export default class BasketCustomFieldsView extends Vue {
  @Prop({ default: false }) hasCustomFields!: boolean;
  @Prop() basketExpired!: boolean;

  fields: any[] = [];
  errors: any[] = [];

  async reloadItems() {
    if (!this.$hasAccess('ReadTrip')) {
      return;
    }
    BasketStore.setHasCustomFieldsLoaded(false);
    BasketStore.setHasCustomFields(false);
    this.loading = true;
    try {
      const result = await BasketApi.getBookingCustomFields(this.$route.params.id);
      this.fields = result.data;
      this.$emit('update:hasCustomFields', !!this.fields.length);
      BasketStore.setCustomFields(this.fields);
      BasketStore.setHasCustomFields(true);
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
      BasketStore.setHasCustomFieldsLoaded(true);
    }
  }

  get readonly() {
    if (this.hasCustomFields && this.canShowCustomFieldsInfoForApprover) {
      return false;
    }
    return !this.isInWizard;
  }

  get hasApprovalWorkflowAction() {
    return BasketStore.hasApprovalWorkflowAction;
  }

  get canShowCustomFieldsInfoForApprover() {
    return this.hasApprovalWorkflowAction && this.canApproveTrip && this.canValidateTrips;
  }

  get canApproveTrip() {
    return BasketStore.canApproveTrip;
  }

  get canValidateTrips() {
    return AccountStore.HasPermission('ValidateTrips');
  }

  get loading() {
    return BasketStore.customFieldsLoading;
  }

  set loading(value) {
    BasketStore.setCustomFieldsLoading(value);
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get portalDestination() {
    if (!this.isInWizard) {
      return 'basket-subheader';
    }
    return 'basket-header';
  }

  get globalFields() {
    return this.fields.filter(field => !field.travellerId && !field.isForNonCompliantOnly);
  }

  get globalNonCompliantFields() {
    return this.fields.filter(field => !field.travellerId && field.isForNonCompliantOnly);
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get travellerFields() {
    return this.travellers.map(traveller => {
      return {
        traveller,
        fields: this.fields.filter(field => field.travellerId === traveller.id && !field.isForNonCompliantOnly),
        nonCompliantFields: this.fields.filter(field => field.travellerId === traveller.id && field.isForNonCompliantOnly),
      };
    }).filter(group => !!group.fields.length || !!group.nonCompliantFields.length);
  }

  userFullName(user) {
    return userFullName(user);
  }

  assignCustomFields() {
    BasketStore.setCustomFields(this.fields);
    BasketStore.setCustomFieldsResponded(true);
  }

  mounted() {
    EventBus.$on('assign-custom-fields', this.assignCustomFields);
    this.reloadItems();
  }

  beforeDestroy() {
    EventBus.$off('assign-custom-fields', this.assignCustomFields);
  }

}
