
















































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { HotelLocationModel } from '@/api/dictionary/dictionary.model';

@Component
export default class UiHotelAutocompleteTemplate extends Vue {
  @Prop() props!: any;
  @Prop() options!: HotelLocationModel[];

}

