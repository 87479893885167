





























































import { Vue, Component, Watch } from 'vue-property-decorator';

import { CustomFieldsProfileApi } from '@/api/custom-fields/custom-fields-profile.api';
import { ProfileCustomFieldsAccessType, UpdateCustomFieldMethodType } from '@/api/custom-fields/custom-fields-profile.model';
import EditCustomFieldValue from '@/modules/profile/custom-fields/EditCustomFieldValue.vue';
import ProfileStore from '@/modules/profile/profile.store';
import { userFullName } from '@/core/user-full-name';

@Component({
  components: {
    EditCustomFieldValue,
  },
})
export default class ProfileArrangerCustomFields extends Vue {
  loading: boolean = true;
  showEditField: boolean = false;
  isVisible: boolean = false;
  errors: any[] = [];
  profileFields: any[] = [];
  accessLevel: ProfileCustomFieldsAccessType = ProfileCustomFieldsAccessType.NoAccess;
  updateCustomFieldMethodType: UpdateCustomFieldMethodType = UpdateCustomFieldMethodType.ArrangerCustomFields;
  selectedField: any = null;

  get canShowSection() {
    return this.isVisible &&
      this.$hasAccess('CanViewArrangerCustomFieldsInProfile');
  }

  get canEdit() {
    return this.$hasAccess('CanAssignArrangerCustomFieldsToProfile');
  }

  get canView() {
    return this.$hasAccess('CanViewArrangerCustomFieldsInProfile');
  }

  get currentProfile() {
    return ProfileStore.userProfile;
  }

  get fields() {
    return this.profileFields.map(field => {
      let profileValues: any = {};

      if (
        (field.profileValue === '' || field.profileValue === null) &&
        field.customField.typeName === 'Dictionary' &&
        field.profileDictionaryItemId === null
      ) {
        profileValues = {
          profileValue: '',
          profileDictionaryItemId: null,
        };
      } else if (field.customField.typeName !== 'Dictionary' && field.profileValue === null) {
        profileValues = {
          profileValue: '',
        };
      }

      return {
        ...field,
        ...profileValues,
      };
    });
  }

  get userFullName() {
    return userFullName(this.currentProfile);
  }

  updateField(field) {
    this.selectedField = {
      ...field,
    };
    this.showEditField = true;
  }

  async reloadItems() {
    ProfileStore.setHasAccessToProfileArrangerCustomFields(false);
    this.errors = [];
    this.profileFields = [];
    try {
      const result = await CustomFieldsProfileApi
        .getProfileArrangerCustomFields(this.$route.params.id);
      
      this.profileFields = result.data.getProfileCustomFieldValues;
      this.accessLevel = result.data.accessLevel;
      this.isVisible = result.data.accessLevel !== ProfileCustomFieldsAccessType.NoAccess;
      ProfileStore.setHasAccessToProfileArrangerCustomFields(this.isVisible);
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  @Watch('$route', { immediate: true , deep: true})
  onchangeRoute() {
    this.reloadItems();
  }
}
