import DictionaryStore from '@/store/dictionary.store';

export default (to, from, next) => {
  const waitForDicts = (to.matched || []).reduce((prev, next) => {
    return prev || (next.meta && next.meta.waitForDicts);
  }, false);

  DictionaryStore.setDictionariesShouldBeLoaded(waitForDicts);

  if (!waitForDicts || DictionaryStore.loaded) {
    next();
    return;
  }

  next({
    name: 'loading',
    query: {
      path: encodeURI(to.fullPath),
    },
  });
};
