import ClientEmailNotificationsConfiguration from '@/modules/settings/client-email-notifications/ClientEmailNotificationsConfiguration.vue';

export default {
  path: ':configurationId/configuration',
  name: 'client-email-notifications-configuration',
  component: ClientEmailNotificationsConfiguration,
  displayName: `Client email notifications configuration`,
  meta: {
    breadCrumb: 'Fees configuration',
    introClass: 'intro-settings',
    permission: 'CanEditClientEmailNotifications',
    parentName: 'clientEmailNotifications',
    depthCategory: 'clientEmailNotifications',
    depth: 2,
  }
};