import Profile from '@/modules/profile/Profile.vue';
import ProfileSidebar from '@/modules/profile/ProfileSidebar.vue';
import profileById from './profile-by-id.route';

export default {
  path: '/profile',
  name: 'profile',
  components: {
    default: Profile,
    sidebar: ProfileSidebar
  },
  children: [
    profileById
  ],
  meta: {
    hasMobileSubMenu: true,
    waitForDicts: true,
  },
};
