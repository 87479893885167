
export const CarCategory = [
  {
    code: 'M',
    label: 'car-category.mini'
  },
  {
    code: 'N',
    label: 'car-category.mini-elite'
  },
  {
    code: 'E',
    label: 'car-category.economy'
  },
  {
    code: 'H',
    label: 'car-category.economy-elite'
  },
  {
    code: 'C',
    label: 'car-category.compact'
  },
  {
    code: 'D',
    label: 'car-category.compact-elite'
  },
  {
    code: 'I',
    label: 'car-category.intermediate'
  },
  {
    code: 'J',
    label: 'car-category.intermediate-elite'
  },
  {
    code: 'S',
    label: 'car-category.standard'
  },
  {
    code: 'R',
    label: 'car-category.standard-elite'
  },
  {
    code: 'F',
    label: 'car-category.fullsize'
  },
  {
    code: 'G',
    label: 'car-category.fullsize-elite'
  },
  {
    code: 'P',
    label: 'car-category.premium'
  },
  {
    code: 'U',
    label: 'car-category.premium-elite'
  },
  {
    code: 'L',
    label: 'car-category.luxury'
  },
  {
    code: 'W',
    label: 'car-category.luxury-elite'
  },
  {
    code: 'O',
    label: 'car-category.oversize'
  },
  {
    code: 'X',
    label: 'car-category.special'
  }  
];

export const CarTransmission = [
  {
    code: 'M',
    label: 'car-transmission.manual-unspecified'
  },
  {
    code: 'N',
    label: 'car-transmission.manual-4wd'
  },
  {
    code: 'C',
    label: 'car-transmission.manual-awd'
  },
  {
    code: 'A',
    label: 'car-transmission.auto-unspecified'
  },
  {
    code: 'B',
    label: 'car-transmission.auto-4wd'
  },
  {
    code: 'D',
    label: 'car-transmission.auto-awd'
  }
];