








































































































































































































































































































































































































































































































































































  import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
  import { Validation } from 'vue-plugin-helper-decorator';
  import {
    required,
    numeric,
    maxLength,
    minLength,
    email,
  } from 'vuelidate/lib/validators';
  
  import moment from 'moment';
  import { translate } from '@/i18n';
  import AccountStore from '@/store/account.store';
  import {
    AvailableDateFormats,
    AvailableTimeFormats,
  } from '@/api/profile/company.model';
  import EventBus from '@/services/event-handler';
  import { isNameValid } from '@/core/validation/is-name-valid.validator';
  import BasketStore from './basket.store';
  import { ProfileApi } from '@/api/profile/profile.api';
  import { Permission } from '@/const/permission.enum';
  import { PhoneCode } from '@/api/profile/contacts.model';
  import DictionaryStore from '@/store/dictionary.store';
  import BasketTravellerLoyaltyProgramsModifyTravellers from './BasketTravellerLoyaltyProgramsModifyTravellers.vue';
  import BasketTravellerDocuments from './BasketTravellerDocuments.vue';
  import LoyaltyPopup from '@/modules/profile/loyalty-programs/LoyaltyPopup.vue';
  import LoyaltyRemovePopup from '@/modules/profile/loyalty-programs/LoyaltyRemovePopup.vue';
  
@Component({
  components: {
    BasketTravellerLoyaltyProgramsModifyTravellers,
    BasketTravellerDocuments,
    LoyaltyPopup,
    LoyaltyRemovePopup,
  }
})
  export default class BasketModifyBritishAirwaysTraveller extends Vue {
    @Prop() traveller!: any;
    @Prop() index!: number;
  
    isLoadingPersonal: boolean = false;
    shouldChangeBasicData: boolean = false;
    shouldChangeDocuments: boolean = false;
    shouldChangeLoyaltyProgram: boolean = false;
    loaderImagePath: string = '/assets/img/loader/1.gif';
    serverErrors: any[] = [];
    personalData: any = null;
  
    titleOptions = [
      {
        value: 'Mr',
        name: translate('common.mr'),
      },
      {
        value: 'Mrs',
        name: translate('common.mrs'),
      },
      {
        value: 'Miss',
        name: translate('common.miss'),
      },
    ];
    $v;
  
    phoneCodeEmptyValue: PhoneCode = {
      phoneCode: '',
      countryName: '',
      code: '',
      threeLetterCode: '',
      shortPhoneCodeDisplayName: translate('common.phone-code'),
      fullPhoneCodeDisplayName: translate('common.none'),
    };

    primaryPhoneNumberCode: PhoneCode | null = null;

    get travellerLoyaltyProgram() {
      if (!this.traveller || !this.traveller.loyaltyPrograms || this.traveller.loyaltyPrograms.length < 1) {
        return null;
      }

      return this.traveller.loyaltyPrograms[0];
    }

    get loyaltyPrograms() {
      if (
        !this.traveller || 
        !this.traveller.loyaltyPrograms
      ) {
        return [];
      }

      return this.traveller.loyaltyPrograms;
    }

    get personalDataTitleValue() {

      if (this.personalData.title.value) {
        return this.personalData.title.value;
      }

      if (this.personalData.title.name) {
        const titleOption = this.titleOptions.find(item => item.name.toLowerCase() === this.personalData.title.name.toLowerCase());
        if (titleOption) {
          return titleOption.value;
        }
      }

      return null;
    }

    get shouldDisablePersonalData() {
      if (!this.traveller || !this.traveller.passengerName) {
        return true;
      }
  
      return !this.traveller.passengerName.canBeModified;
    }
  
    get currentDateFormat() {
      return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
    }
  
    get currentTimeFormat() {
      return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
    }
  
    get dateTimeFormat() {
      return this.currentDateFormat;
    }
  
    get isBasicFormInvalid() {
      return this.$v.$invalid && this.shouldChangeBasicData && this.shouldChangeDocuments;
    }

    get isNoSectionToModify() {
      return !this.shouldChangeBasicData && !this.shouldChangeDocuments && !this.shouldChangeLoyaltyProgram;
    }

    get phoneCode() {
    if (!this.personalData) {
      return null;
    }

    const code = this.personalData.primaryPhone.code;

    return this.allPhoneCountryCodes.find(el => el.phoneCode === code);
    
  }

  set phoneCode(value) {
    if (!this.personalData) {
      return;
    }

    if (!value) {
      this.personalData.primaryPhone.code = null;
      return;
    }

    this.personalData.primaryPhone.code = value.phoneCode;
  }

  get allCountries() {
    return DictionaryStore.allCountries || [];
  }

  get allPhoneCountryCodes() {
    if (!DictionaryStore.allCountries) {
      return [];
    }

    const allPhoneCodes = this.allCountries
      .filter(country => country && country.phoneCode!.length > 0)
      .map((country) => {
        return { 
          ...country,
          shortPhoneCodeDisplayName: '+' + country.phoneCode,
          fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
        };
      });

    return [this.phoneCodeEmptyValue].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get systemTraveller() {
    if (!BasketStore.basket) {
      return null;
    }
    return BasketStore.basket.travellers
      .find(traveller => traveller.id === this.traveller.id);
  }

  get systemTravellerIndex() {
    if (!BasketStore.basket) {
      return 0;
    }
    return BasketStore.basket.travellers
      .findIndex(traveller => traveller.id === this.traveller.id);
  }
  
  get travellerDocuments() {
    if (
      !this.traveller || 
      !this.traveller.documents
    ) {
      return [];
    }

    return this.traveller.documents;
  }

  @Validation()
    validationObject() {
      return {
        personalData: {
          firstName: {
            required,
            isNameValid,
            maxLength: maxLength(100),
          },
          middleName: {
            isNameValid,
            maxLength: maxLength(100),
          },
          lastName: {
            required,
            isNameValid,
            maxLength: maxLength(100),
          },
          primaryEmail: {
            required,
            email,
            maxLength: maxLength(320),
          },
          primaryPhone: {
            number: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(16),
              numeric,
            },
            code: {
              required,
            },
          }
        },
      };
    }
  
    formatDate(date) {
      return moment(date).format(this.dateTimeFormat);
    }

    onPrimaryPhoneCodeSelect(selected) {
      if (selected && !selected.phoneCode) {
        this.personalData.primaryPhone.number = '';
      } else if (selected && selected.phoneCode) {
        this.personalData.primaryPhone.code = selected.phoneCode;
      }
      setTimeout(() => {
        const el = (this.$refs.primaryNumber as Vue).$refs.input as HTMLElement;
        el.focus();
      });
    }

    async loadPersonalData(id: string) {
      this.isLoadingPersonal = true;
      this.serverErrors = [];
  
      try {
        const response = await ProfileApi.getById(id, Permission.ReadProfilePersonal);
  
        if (response && response.data) {
          this.personalData = response.data;
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      } finally {
        this.isLoadingPersonal = false;
      }
    }
  
    updateData() {

      const loyaltyData = BasketStore.modifyTravellersLoyaltyPrograms
        .find(item => item.profileId === this.traveller.id);

      if (!this.personalData && this.shouldChangeBasicData) {
        return;
      }

      let data: any = {
        id: this.traveller.id,
        shouldChangeBasicData: this.shouldChangeBasicData,
        shouldChangeDocuments: this.shouldChangeDocuments, 
        shouldChangeLoyaltyProgram: this.shouldChangeLoyaltyProgram
      };

      if (this.personalData) {
        data = {
          ...data,
          passengerName: {
            title: this.personalDataTitleValue,
            firstName: this.personalData.firstName,
            middleName: this.personalData.middleName,
            lastName: this.personalData.lastName,
          },
          passengerContact: {
            emailAddress: this.personalData.primaryEmail,
            phoneNumberCode: this.personalData.primaryPhone.code,
            phoneNumber: this.personalData.primaryPhone.number,
          },
        };
      }

      if (loyaltyData && loyaltyData.selectedOption) {
        data = {
          ...data,
          passengerLoyaltyCard: {
            selectedLoyaltyCardId: loyaltyData.selectedOption.cardId,
          },
        };
      }

      BasketStore.setModifiedTraveller({
        data,
        index: this.index,
      });
    }
  
    @Watch('isBasicFormInvalid', { immediate: true })
    onBasicFormInvalid(value) {
      BasketStore.setModifyTravellersDetailsFormStatus({
        travellerId: this.traveller.id,
        form: value,
        loyalty: null,
        noSection: null,
      });
    }
  
    @Watch('isNoSectionToModify', { immediate: true })
    onNoSectionToModify(value) {
      BasketStore.setModifyTravellersDetailsFormStatus({
        travellerId: this.traveller.id,
        form: null,
        loyalty: null,
        noSection: value,
      });
    }
  
    @Watch('shouldChangeBasicData')
    onshouldChangeBasicData(value) {
      if (!value) {
        return;
      }
  
      this.loadPersonalData(this.traveller.id);
    }

    created() {
      EventBus.$on('update-modified-details', this.updateData);
    }
  
    beforeDestroy() {
      EventBus.$off('update-modified-details', this.updateData);
    }
  }
  