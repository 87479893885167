import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { Permission } from '@/const/permission.enum';
import { PaymentGatewayConfiguration } from './payment-gateway.model';

export class PaymentGatewayConfigurationApiClass {
  public createConfiguration(requestMessage: PaymentGatewayConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/payment-gateways-configuration',
      method: RequestMethod.POST,
      permission: Permission.CanEditPaymentGateways,
    }, {}, requestMessage );
  }

  public getConfiguration(configurationId: string): Promise<AxiosResponse<PaymentGatewayConfiguration>> {
    return http.execute({
      path: settings.apiTrip + '/api/payment-gateways-configuration/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateConfiguration(message: PaymentGatewayConfiguration): Promise<AxiosResponse<PaymentGatewayConfiguration>> {
    return http.execute({
      path: settings.apiTrip + '/api/payment-gateways-configuration/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, { name: message.name, vivaWalletSecretKey: message.vivaWalletSecretKey });
  }

}

export const PaymentGatewayConfigurationApi: PaymentGatewayConfigurationApiClass = new PaymentGatewayConfigurationApiClass();