

































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import BasketStore from './basket.store';

@Component({})
export default class BasketUnconfirmedNotificationsPopup extends Vue {
  @Prop() show!: boolean;



  get tripCode() {
    return (BasketStore.basket && BasketStore.basket.tripCode) || '';
  }


  showNotifications() {
    this.$router.push({
      name: 'notifications',
      query: {
        tripCode: this.tripCode,
      }
    });
    this.closePopup();
  }

  @Emit('update:show')
  closePopup() {
    return false;
  }
}

