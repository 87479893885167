











































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { translate } from '@/i18n';
import CarSearchStore from '@/modules/search/car/car-search.store';

@Component({})
export default class CarSort extends Vue {
  imagesConst = '/assets/img/loader/1.gif';
  toggleSortOptions: boolean = false;

  get loading() {
    return CarSearchStore.loading;
  }

  get isDisabled() {
    return CarSearchStore.selectingOffer;
  }

  get sortOptions() {
    return CarSearchStore.sortOptions;
  }

  get priceSorterEnabled() {
    return this.sortOptions!.PRICE !== undefined;
  }

  get priceSorterStatus() {
    return this.sortOptions!.PRICE === 'Ascending';
  }

  get currentSortType() {
    return 'sort-by-cheapest-offer';
  }

  get offersVisible() {
    return CarSearchStore.offersVisible;
  }

  get displayedOffers() {
    return Math.min(this.offersVisible, this.totalOffers);
  }

  get totalOffers() {
    return CarSearchStore.totalOffers;
  }

  get recommendationsCarCount() {
    return CarSearchStore.recommendationsCarCount;
  }

  get isNotFiltered() {
    return this.totalOffers === this.recommendationsCarCount;
  }

  get filters() {
    return CarSearchStore.filters;
  }

  get isSearchCompleted() {
    return CarSearchStore.searchCompleted;
  }

  get offers() {
    return CarSearchStore.offers;
  }

  toggleSort(sorterCode) {
    if (this.isDisabled) {
      return;
    }
    if (this.sortOptions[sorterCode] === undefined) {
      CarSearchStore.setNewSort({ sorterCode, isAscending: true });
      CarSearchStore.setOffersVisible(10);
      CarSearchStore.getOffers(this.$route.params.searchId);
    }
    this.toggleSortOptions = false;
  }

  toggleSortDropdown() {
    if (this.isDisabled) {
      return;
    }
    this.toggleSortOptions = !this.toggleSortOptions;
  }
}
