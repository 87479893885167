

















































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import TrainTimeline from './TrainTimeline.vue';
import TrainSearchStore from './train-search.store';
import { Fare } from '@/api/train-engine/train-search.model';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';
import formatPrice from '@/filters/format-price.filter';
import { trainLogo } from '@/const/train-logo-mappings.const';

@Component({
  components: {
    TrainTimeline,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    }
  }
})
export default class TrainEditedResultsRow extends Vue {
  @Prop() offer!: any;
  @Prop() price!: any;
  showDetails: boolean = false;
  disabledInfo: string = 'Max. 3 offers can be selected';
  selectedOfferId: string = '';
  imageProcessed: boolean = false;
  imageLoading: boolean = true;
  imageVisible: boolean = true;

  get timelinesCollapsed() {
    return false;
  }

  get uniqTrainById() {
    return _.uniqBy(this.offer.journeys[0].trains, 'segmentKey');
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get trainTypeInfo() {
    return this.offer.journeys[0].trains.filter(train => train.type !== 'Stop');
  }

  get legNumber() {
    return this.$route.params.leg ? parseInt(this.$route.params.leg as string) : 1;
  }

  get offerPrice() {
    let selectedFares: Fare[] = [];
    let price = 0;
    this.uniqTrainById.forEach(train => {
      this.offer.proposals.forEach(proposal => {
        if (train.segmentKey === proposal.segmentKey) {
          let fare = proposal.fares.find(p => p.isSelected);
          selectedFares.push(fare as Fare);
        }
      });
    });
    if (selectedFares.length) {
      selectedFares.forEach(s => {
        if (s) {
          price += (s as Fare).price.amount;
        }
      });
    }

    return price;
  }

  displayImg(ride) {
    if (!this.imageLoading || !ride || !ride.trainInfo) {
      return '';
    }
    const key = this.offer.supplier === 'Benerail' ? ride.trainInfo.trainCode : ride.trainInfo.trainType;
    return trainLogo[key];
  }

  filterTrains(trains) {
    const filterTrain = trains
      .filter(item => item.type !== 'Stop')
      .filter((item, index, array) => {
        const i = array.findIndex(el => el.trainInfo.trainType === item.trainInfo.trainType);
        if (i < index) {
          return false;
        }

        return true;
      });

    return filterTrain;
  }

  getTrainClasses(proposals: any[]) {
    if (proposals.length) {
      return proposals.map(proposal => {
        if (proposal.class) {
          return proposal.class;
        }
      }).join(', ');
    } else {
      return '';
    }
  }

  getProposalList(train) {
    return this.offer.proposals.find(proposal => {
      return proposal.segmentKey === train.segmentKey;
    });
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  imageError(ride) {
    if ('' === this.displayImg(ride)) {
      this.imageProcessed = true;
      return;
    }

    this.imageVisible = false;
    this.imageProcessed = true;
  }
}
