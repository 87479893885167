import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  BasketItemModel,
  BasketItemIdentifier,
  UpdateRailSncfSeatPreference,
  Traveller,
  BasketFlatItemModel,
  UpdateRailBenerailSeatPreferences,
  RailBenerailLegs,
} from './basket.model';
import {
  OfferParameter,
  BasketParameter,
  CreateBasketModel,
  RailSeatPreferenceRequestModel,
  AirLuftHansaTicketingMessage,
  SabrePreEmdAnswer,
  AmadeusPreEmdAnswer,
  RailDiscountCodes,
  RailDiscountCodesResponse,
  PriceChangeDetailsModel,
  TripItemPaymentInfoRedirectUrl,
  TripItemFeePaymentModel,
} from './basket-item.model';

class BasketItemApiClass {
  public getItemCustomParameters(itemId: string, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<OfferParameter[]>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/parameters',
      method: RequestMethod.GET,
    }, { itemId }, {}, false, cancelTokenSource);
  }

  public saveBasketCustomParameters(basketId: string, parameters: BasketParameter[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/parameters',
      method: RequestMethod.PUT,
    }, {
        basketId
      }, {
        items: parameters
      });
  }

  public desynchronizeItem(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/desynchronize',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public exchangeBasketItem({ itemId }, param): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/exchange',
      method: RequestMethod.POST,
    }, { itemId }, param);
  }

  public basketPaymentVerification(recommendationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/completePaymentVerification',
      method: RequestMethod.POST,
    }, {}, { recommendationId: recommendationId });
  }

  public basketPaymentVerificationV2(tripItemId: string, paymentMethodId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/completePaymentVerification',
      method: RequestMethod.POST,
    }, {}, { tripItemId, paymentMethodId });
  }

  public getBasketItem(itemId: string): Promise<AxiosResponse<BasketItemModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public getFlatBasketItem(tripItemId: string): Promise<AxiosResponse<BasketFlatItemModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{tripItemId}',
      method: RequestMethod.GET,
    }, { tripItemId }, {});
  }

  public getTripItemHistory(tripItemId) {
    return http.execute({
      path: settings.apiTrip + '/api/trip-history/{tripItemId}',
      method: RequestMethod.GET,
    }, { tripItemId }, {});
  }

  public addItemAndCreateTrip(param): Promise<AxiosResponse<CreateBasketModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/items',
      method: RequestMethod.POST,
    }, {}, param);
  }

  public setCarTripItemExtras(itemId: string, extras: string[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/car-extras',
      method: RequestMethod.PUT,
    }, { itemId }, { extras });
  }

  public saveConnectionTripExpense(itemId, expenseId) {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/expenses',
      method: RequestMethod.POST,
    }, { itemId }, { expenseId });
  }

  public setRailSeatPreferences(itemId: string, seats: RailSeatPreferenceRequestModel[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/rail-items/{itemId}/seats',
      method: RequestMethod.PUT,
    }, { itemId }, { seats });
  }

  public getBasketItemIdentifiers(itemId: string, permission: string): Promise<AxiosResponse<BasketItemIdentifier>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/identifiers',
      permission,
      method: RequestMethod.GET,
    }, { itemId }, { permission });
  }

  public answerLufthansaTicketing(itemId: string, userDecision: AirLuftHansaTicketingMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/confirm-lhg-ticketing',
      method: RequestMethod.POST,
    }, { itemId }, { userDecision });
  }

  public answerSabreTicketing(itemId: string, userDecision: SabrePreEmdAnswer): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/confirm-sabre-emd-issuance',
      method: RequestMethod.POST,
    }, { itemId }, { userDecision });
  }

  public answerAmadeusTicketing(itemId: string, userDecision: AmadeusPreEmdAnswer): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/confirm-amadeus-emd-issuance',
      method: RequestMethod.POST,
    }, { itemId }, { userDecision });
  }

  public getRailSncfSeatPreferences(offerId: string, railSupplier: string): Promise<AxiosResponse<any[]>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/seats-preferences/options?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, railSupplier }, {});
  }

  public getRailBenerailSeatPreferences(offerId: string, railSupplier: string): Promise<AxiosResponse<RailBenerailLegs>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/seats-preferences/options?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, railSupplier }, {});
  }

  public updateRailSncfSeatPreferences(offerId: string, supplier: string, params: UpdateRailSncfSeatPreference[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/seats-preferences?railSupplier={supplier}',
      method: RequestMethod.PUT,
    }, { offerId, supplier }, params, true);
  }

  public updateRailBenerailSeatPreferences(offerId: string, supplier: string, params: UpdateRailBenerailSeatPreferences): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/seats-preferences?railSupplier={supplier}',
      method: RequestMethod.PUT,
    }, { offerId, supplier }, params, true);
  }

  public getRailSncfSeatMap(offerId: string, segmentId: string, supplier: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/seat-map/{segmentId}?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, segmentId, railSupplier: supplier }, {});
  }

  public getRailDiscountCodes(offerId: string, railSupplier: string): Promise<AxiosResponse<RailDiscountCodesResponse>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/voucher?railSupplier={railSupplier}',
      method: RequestMethod.GET,
    }, { offerId, railSupplier }, {});
  }

  public saveRailDiscountCodes(offerId: string, railSupplier: string, discounts: RailDiscountCodes[]): Promise<AxiosResponse<RailDiscountCodesResponse>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/offers/{offerId}/voucher?railSupplier={railSupplier}',
      method: RequestMethod.PUT,
    }, { offerId, railSupplier }, {
      data: discounts
    }, true);
  }

  public updateTravellerData(itemId: string, travellers: Traveller[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/travellers',
      method: RequestMethod.PUT,
    }, { itemId }, travellers, true);
  }

  public updateFeeOnItem({ basketId, tripItemId, agencyFee }): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{tripItemId}/fee',
      method: RequestMethod.PUT,
    }, { basketId, tripItemId }, { agencyFee });
  }

  public updateAgencyMarkupOnItem({ basketId, tripItemId, agencyMarkup }): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/baskets/{basketId}/items/{tripItemId}/agency-markup',
      method: RequestMethod.PUT,
    }, { basketId, tripItemId }, agencyMarkup);
  }

  public getPriceChangeDetails(recommendationId: string): Promise<AxiosResponse<PriceChangeDetailsModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{recommendationId}/price-change',
      method: RequestMethod.GET,
    }, { recommendationId }, {});
  }

  public getTripItemPaymentInfoRedirectUrl(itemId: string): Promise<AxiosResponse<TripItemPaymentInfoRedirectUrl>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/payment-info/redirect-url',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public getTripItemFeePaymentInfo(itemId: string, paymentMethod: string): Promise<AxiosResponse<TripItemFeePaymentModel>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/fee-payment-info?paymentMethod={paymentMethod}',
      method: RequestMethod.POST,
    }, { itemId, paymentMethod }, {});
  }

  public getTripItemPaymentInfo2RedirectUrl(itemId: string, paymentMethodId: string): Promise<AxiosResponse<TripItemPaymentInfoRedirectUrl>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/payment-info/redirect-url/{paymentMethodId}',
      method: RequestMethod.PUT,
    }, { itemId, paymentMethodId }, {});
  }

  public cancel3dsChallange(tripItemId: string, paymentMethodId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{tripItemId}/cancel-3ds-challenge/{paymentMethodId}',
      method: RequestMethod.POST,
    }, { tripItemId, paymentMethodId }, {});
  }

  public initItemBookingDataSync(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/sync-air-booking',
      method: RequestMethod.POST,
    }, { itemId }, {});
  }

  public getMissedSavings(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/missed-saving',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public updateMissedSavings(itemId: string, reason: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/v2/items/{itemId}/missed-saving',
      method: RequestMethod.PUT,
    }, { itemId }, reason);
  }

  public getLoyaltyCardOptions(itemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiTrip + '/api/items/{itemId}/loyalty-cards/options',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }
}

export const BasketItemApi: BasketItemApiClass = new BasketItemApiClass();
