






























































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { Journey, Fare } from '@/api/train-engine/train-search.model';
import AccountStore from '@/store/account.store';
import moment from 'moment';

@Component({
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes) as any;
      if (rminutes && rminutes < 10) {
        rminutes = '0' + rminutes;
      }
      return rhours + 'h' + rminutes + 'm';
    },
    monthDate(date) {
      return moment(date).format('MMMM');
    },
    dayStringDate(date) {
      return moment(date).format('dddd');
    },
  }
})
export default class TrainTimeline extends Vue {
  @Prop() journey!: Journey;
  @Prop() value!: boolean;

  get isAnotherDay() {
    let startOfDay = moment(this.journey.trains[0].departure).startOf('day');
    let endOfDay = moment(this.journey.trains[this.journey.trains.length - 1].arrival).endOf('day');

    return endOfDay.diff(startOfDay, 'days');
  }

  get tripTime() {
    let time = 0;
    if (this.journey.trains && this.journey.trains.length > 1) {
      this.journey.trains.forEach(l => {
        time += l.duration;
      });
      return time;
    }
  }

  get basketMode() {
    return -1 < [
      'basket',
      'basket-expired',
      'basketTravellers',
      'basketAddSegment',
    ].indexOf(this.$route.name || '');
  }

  get user() {
    return AccountStore.current;
  }

  dayNumberDate(date) {
    if (!date) {
      return null;
    }
    if (this.user && this.user.profile.displayLanguage === 'en' || this.user && this.user.profile.displayLanguage === 'fr') {
      return moment(date).format('D');
    } else {
      return moment(date).format('Do');
    }
  }

  dayNumberLastDate(date) {
    if (date && this.user && this.user.profile.displayLanguage === 'en') {
      return moment(date).format('Do').slice(-2);
    } else {
      return null;
    }
  }

  toggleDetails() {
    this.$emit('input', !this.value);
  }
}
