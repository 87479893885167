import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  SessionProfileModel,
  ProfileModel,
  ProfileFullNameModel,
  TravellerModel,
  ApproverModel,
  ProfileUpdateMessage,
  ProfileAssignCompanyMessage,
  ProfileCreateMessage,
  AvatarModel,
  AvatarVariants,
  CreateAccountMessage,
  ProfileResultsModel,
  AssignedAgencyMessage,
  ProfileState,
  CreateProfileResponse,
  EmailConfirmedResponse,
  InvitationEmailResendResponse,
  LoyaltyTypeEnum,
  ProfileDataModel,
  CheckTravellersData,
  CheckTravellersModel,
} from './profile.model';
import {
  DesignatedTravellersItem,
  DesignatedApproversItem,
} from '@/api/approval-workflow/approval-workflow.model';

class ProfileApiClass {
  public current(): Promise<AxiosResponse<SessionProfileModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/current',
      method: RequestMethod.GET,
    }, {}, {});
  }

  public searchTravellers(searchPhrase, includeGuests?, includeDeletedProfiles?): Promise<AxiosResponse<TravellerModel[]>> {
    let url = '/api/travellers?searchPhrase={searchPhrase}';
    if (includeGuests) {
      url += '&includeGuests=true';
    }
    if (includeDeletedProfiles) {
      url += '&includeDeletedProfiles=true';
    }

    return http.execute({
      path: settings.apiProfile + url,
      method: RequestMethod.GET,
    }, { searchPhrase }, {});
  }

  public searchApprovers(companyId, searchPhrase, permission): Promise<AxiosResponse<ApproverModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/approvers?companyId={companyId}&limit=10&approversSource=Root&phrase={searchPhrase}',
      permission: permission,
      method: RequestMethod.GET,
    }, { companyId, searchPhrase },  {permission} );
  }

  public searchApproversV2(searchPhrase): Promise<AxiosResponse<ApproverModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/approvers?phrase={searchPhrase}',
      method: RequestMethod.GET,
    }, { searchPhrase }, {} );
  }

  public getById(profileId, permission?): Promise<AxiosResponse<ProfileModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}',
      permission: permission ? permission : 'Client',
      method: RequestMethod.GET,
    }, { profileId }, { permission });
  }

  public getFullNameById(profileId, permission): Promise<AxiosResponse<ProfileFullNameModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/full-name',
      permission: permission,
      method: RequestMethod.GET,
    }, { profileId }, { permission });
  }

  public getActiveInfo(profileId: string, accountId?): Promise<AxiosResponse<EmailConfirmedResponse>> {
    return http.execute({
      path: settings.apiProfile + `/api/profiles/{profileId}/email-confirmed${ accountId ? '?accountId=' + accountId : '' }`,
      method: RequestMethod.GET,
    }, { profileId, accountId }, {});
  }

  public assignCompanyToProfile(profileId, params: ProfileAssignCompanyMessage): Promise<AxiosResponse<SessionProfileModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/company',
      method: RequestMethod.PUT,
    }, {
      profileId,
    }, params);
  }

  public switchProfile(switchToProfileId: string): Promise<AxiosResponse<SessionProfileModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/current?switchToProfileId={switchToProfileId}',
      method: RequestMethod.POST,
    }, {
      switchToProfileId,
    }, {});
  }

  public backToMainProfile(): Promise<AxiosResponse<SessionProfileModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/current/reset',
      method: RequestMethod.POST,
    }, {}, {});
  }

  public getProfiles(phrase, limitTo): Promise<AxiosResponse<ProfileResultsModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles?phrase={phrase}&limitTo={limitTo}',
      method: RequestMethod.GET,
    }, { phrase, limitTo }, {});
  }

  public updateProfile(profileId, params: ProfileUpdateMessage): Promise<AxiosResponse<ProfileModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}',
      method: RequestMethod.PUT,
    }, { profileId }, params);
  }

  public getCompanyProfiles(companyId: string, pageSize: number, pageNo: number, phrase: string, unitId: string, isGuest: boolean): Promise<AxiosResponse<ProfileResultsModel>> {
    let url = '/api/v2/companies/{companyId}/community?pageNo={pageNo}&pageSize={pageSize}&phrase={phrase}&unitId={unitId}';
    if (isGuest !== null) {
      url += '&isGuest={isGuest}';
    }
    return http.execute({
      path: settings.apiProfile + url,
      method: RequestMethod.GET,
    }, {
      companyId,
      pageSize,
      pageNo,
      phrase,
      unitId,
      isGuest,
    }, {});
  }

  public createProfile(message: ProfileCreateMessage): Promise<AxiosResponse<CreateProfileResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/profiles',
      method: RequestMethod.POST,
    }, {}, message);
  }

  public changeProfileStatus(params: ProfileState, profileId: string) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/state',
      method: RequestMethod.PUT,
    }, { profileId }, params);
  }

  public deleteProfile(profileId: string) {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}',
      method: RequestMethod.DELETE,
    }, { profileId }, {});
  }

  public getAvatars(profileId: string): Promise<AxiosResponse<AvatarModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/avatars',
      method: RequestMethod.GET,
    }, { profileId }, {});
  }

  public getAvatar(profileId: string, size: string = AvatarVariants.large): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/avatarImage/{size}',
      method: RequestMethod.GET,
    }, { profileId, size }, {});
  }

  public updateAvatars(profileId: string, request: any[]): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/avatars',
      method: RequestMethod.PUT,
    }, { profileId }, request, true);
  }

  public removeAvatars(profileId: string): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/avatars',
      method: RequestMethod.DELETE,
    }, { profileId }, {});
  }

  public createAccount(profileId, params: CreateAccountMessage): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/account',
      method: RequestMethod.PUT,
    }, { profileId }, params);
  }

  public getProjectsDictionary(configurationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/projects/names?configurationId={configurationId}',
      method: RequestMethod.GET,
    }, {configurationId}, {});
  }

  public getAssignedAgency(companyId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/agency',
      method: RequestMethod.GET,
    }, { companyId }, {});
  }

  public assignedAgency(companyId: string, params: AssignedAgencyMessage): Promise<AxiosResponse> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/agency',
      method: RequestMethod.PUT,
    }, { companyId }, params);
  }

  public getLoyaltyProgramsForProfiles(profileIds: string[], serviceType?: string, loyaltyType?: LoyaltyTypeEnum): Promise<AxiosResponse<any>> {
    let path = '/api/loyalties?';
    profileIds.forEach((id, index) => {
      if (index > 0) {
        path += '&';
      }
      path += 'profileId=' + id;
    });
    if (serviceType) {
      path += '&serviceType=' + serviceType;
    }
    if (loyaltyType) {
      path += '&loyaltyType=' + loyaltyType;
    }
    return http.execute({
      path: settings.apiProfile + path,
      method: RequestMethod.GET,
    }, {}, {});
  }

  public resendInvitationEmail(profileId: string): Promise<AxiosResponse<InvitationEmailResendResponse>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/resend-invitation-email',
      method: RequestMethod.POST,
    }, { profileId }, {});
  }

  public getCompanyBreadcrumbs(companyId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/breadcrumbs',
      method: RequestMethod.GET,
    }, { companyId }, {});
  }

  public getProfileData(profileId, permission?): Promise<AxiosResponse<ProfileDataModel>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/data',
      permission: permission ? permission : 'Client',
      method: RequestMethod.GET,
    }, { profileId }, { permission });
  }

  public checkTravellersExistence(travellers: CheckTravellersData): Promise<AxiosResponse<CheckTravellersModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/travellers/check-travellers-existence',
      method: RequestMethod.POST,
    }, {}, travellers);
  }

  public getProfiles2faInfo(profiles: string[]): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/2fa-info',
      method: RequestMethod.POST
    }, {}, profiles, true);
  }

  public saveProfile2faSetting(profileId: string, message: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/set-2fa-phone',
      method: RequestMethod.POST
    }, { profileId }, message);
  }

  public saveProfileSsoSetting(profileId: string, message: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/set-sso-login',
      method: RequestMethod.POST
    }, { profileId }, message);
  }

  public updateDateOfBirth(profileId: string, dateOfBirth: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/date-of-birth',
      method: RequestMethod.PUT
    }, { profileId }, { dateOfBirth });
  }

  public getProfileManager(profileId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/manager',
      method: RequestMethod.GET
    }, { profileId }, {});
  }

  public updateProfileManager(profileId: string, managerId: string | null): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/manager',
      method: RequestMethod.PUT
    }, { profileId }, { managerId });
  }

  public searchProfileManagers(profileId: string, phrase: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/profiles/{profileId}/managerprofiles?phrase={phrase}',
      method: RequestMethod.GET
    }, { phrase, profileId }, {});
  }

  public getDesignatedTravellers(profileId: string, phrase: string): Promise<AxiosResponse<DesignatedTravellersItem[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/designated-travellers?profileId={profileId}&phrase={phrase}',
      method: RequestMethod.GET
    }, { profileId, phrase }, {});
  }

  public getDesignatedApprovers(profileId: string, phrase: string): Promise<AxiosResponse<DesignatedApproversItem[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/designated-approvers?profileId={profileId}&phrase={phrase}',
      method: RequestMethod.GET
    }, { profileId, phrase }, {});
  }
}

export const ProfileApi: ProfileApiClass = new ProfileApiClass();
