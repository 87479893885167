import HotelDetails from '@/modules/search/hotel/HotelDetails.vue';
import HotelSearchShortParams from '@/modules/search/hotel/HotelSearchShortParams.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/hotel-details-basket/:propertyId',
  name: 'hotelDetailsBasket',
  components: {
    default: HotelDetails,
  },
  meta: {
    introClass: 'intro-hotel-results',
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        hotel: AnimationName.UP,
      },
      default: {
        basket: AnimationName.DOWN,
      }
    }
  }
};
