






































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Recommendation } from '@/api/air-engine/air-search.model';
import { IOfferDetails } from '@/api/air-engine/offers.model';
import AirSearchStore from '@/modules/search/air/air-search.store';
import { translate } from '@/i18n';
import formatPrice from '@/filters/format-price.filter';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { TravellerTypeOptions } from '@/const/profile-personal.const';

@Component({})
export default class AirFareConditions extends Vue {
  @Prop() offerDetails!: IOfferDetails;
  @Prop() offer!: Recommendation;
  @Prop() proposal?: any;
  @Prop() searchModeType?: string;
  @Prop({ default: false }) isInBasket!: boolean;

  showFullFareRules: boolean = false;
  showTaxes: boolean = true;

  get enableFullFareRulesSwitch() {
    if (!this.offerDetails || !this.offerDetails.legDetails) {
      return false;
    }

    for (const leg of this.offerDetails.legDetails) {
      for (const segment of leg.segments) {
        const anysegment = segment as any;
        const hasDescription = anysegment.conditionsInfo &&
          anysegment.conditionsInfo.segmentClass &&
          anysegment.conditionsInfo.segmentClass.description;

        if ('Sabre' === this.supplier && hasDescription) {
          return true;
        }

        if (['Lufthansa', 'AmericanAirlines'].indexOf(this.supplier) > -1 && hasDescription) {
          return false;
        }

        if (hasDescription && anysegment.conditionsInfo.penalty) {
          return true;
        }
      }
    }

    return false;
  }

  get supplier() {
    if (this.offer && this.offer.supplier) {
      return this.offer.supplier;
    }
    return this.proposal ? this.proposal.supplier : this.offer.proposal.supplier;
  }

  get providerSearchId() {
    return this.proposal ? this.proposal.providerSearchId : this.offer.providerSearchId;
  }

  get offerId() {
    return this.proposal ? this.proposal.id : this.offer.id;
  }

  get isFullFareRuleOnly() {
    if (!this.offerDetails || !this.offerDetails.legDetails) {
      return false;
    }

    for (const leg of this.offerDetails.legDetails) {
      for (const segment of leg.segments) {
        const anysegment = segment as any;
        const fullFareRuleOnly = anysegment.conditionsInfo.useFullFareRulesOnly;

        if (fullFareRuleOnly) {
          return true;
        }
      }
    }

    return false;
  }

  toggleTaxes() {
    this.showTaxes = !this.showTaxes;
  }

  translationByCode(code) {
    const typeByCode = TravellerTypeOptions.find(item => {
      if (item.codes) {
        return -1 < item.codes.indexOf(code);
      }
      return item.code === code;
    }) || null;

    if (!typeByCode) {
      return '';
    }
    return typeByCode.name;
  }

  infoLink(info) {
    return sanitizeUrl(info);
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  getPenaltyText(penaltyPrice, ischange) {
    if (!penaltyPrice) {
      return translate('search-air.not-allowed');
    }
    if (penaltyPrice.isPotentiallyIncorrect) {
      return translate('search-air.check-full-fare-rates');
    }
    if (!penaltyPrice.isAllowed) {
      return translate('search-air.not-allowed');
    }

    if (!penaltyPrice.isChargeable) {
      if (ischange) {
        return translate('search-air.no-charge-modifica');
      } else {
        return translate('search-air.no-charge-refund');
      }
    }

    return translate('search-air.allowed');
  }

  created() {
    this.showFullFareRules = ['Lufthansa', 'AmericanAirlines'].indexOf(this.supplier) > -1
        ? false
        : !this.enableFullFareRulesSwitch;

    if (this.offerDetails.detailedPrice && this.offerDetails.detailedPrice.priceChanged) {
      const offer = {
        totalPrice: this.offerDetails.detailedPrice.totalPrice,
        offerId: this.offer.id,
      };
      AirSearchStore.updateOfferPrice({ offer, proposal: this.proposal });
    }
  }

}
