import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  FareMappingConfigurations,
  UpdateFareMappingConfigurationMessage,
  UpdateFareMappingConfigurationResponse,
} from './fare-mapping-configuration.model';
import { Permission } from '@/const/permission.enum';
class FareMappingConfigurationApiClass {
  public getById(configurationId: string): Promise<AxiosResponse<FareMappingConfigurations>> {
    return http.execute({
      path: settings.apiAirEngine + '/fare-mapping-configurations/{configurationId}',
      method: RequestMethod.GET,
      permission: Permission.CanEditFareMapping,
    }, { configurationId }, {});
  }

  public create(params: FareMappingConfigurations): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/fare-mapping-configurations',
      method: RequestMethod.POST,
      permission: Permission.CanEditFareMapping,
    }, {}, params);
  }

  public update(message: UpdateFareMappingConfigurationMessage): Promise<AxiosResponse<UpdateFareMappingConfigurationResponse | Array<any>>> {
    return http.execute({
      path: settings.apiAirEngine + '/fare-mapping-configurations/{configurationId}',
      method: RequestMethod.PUT,
      permission: Permission.CanEditFareMapping,
    }, { configurationId: message.configurationId }, { name: message.params.name, fareMappingConfigurations: message.params.fareMappingConfigurations });
  }
}

export const FareMappingConfigurationApi: FareMappingConfigurationApiClass = new FareMappingConfigurationApiClass();