export class CurrencyExchangeConfiguration {
  configurationId?: string = '';
  name?: string = '';
  rootCompanyId?: string = '';
  displayCurrencyCode: string = '';
  settlementCurrencyCode: string = '';

  constructor(data?: any) {
    if (!data) {
      return;
    } else {
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.displayCurrencyCode !== undefined) {
        this.displayCurrencyCode = data.displayCurrencyCode;
      }
      if (data.settlementCurrencyCode !== undefined) {
        this.settlementCurrencyCode = data.settlementCurrencyCode;
      }
    }
  }
}