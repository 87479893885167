import ExpensePaymentInstruments from '@/modules/settings/expense-configuration/ExpensePaymentInstruments.vue';

export default {
  path: ':configurationId?/payment-methods/:itemId?/:isCopy?',
  name: 'payment-methods',
  component: ExpensePaymentInstruments,
  displayName: 'Payment instruments',
  meta: {
    breadCrumb: 'Expense definition',
    introClass: 'intro-settings',
    permission: 'ReadPaymentOptionDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};