import TrainSearchShortParams from '@/modules/search/train/TrainSearchShortParams.vue';
import TrainResultDetailsView from '@/modules/search/train/TrainResultDetailsView.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/train-results-details-view/:searchId/:recomendationId/:leg?',
  name: 'trainResultDetailsView',
  components: {
    default: TrainResultDetailsView,
    intro: TrainSearchShortParams,
  },
  meta: {
    introClass: 'intro-train-results',
    permission: 'SearchOffers',
    mobile: true,
    preserveSidebar: {
      trainDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        trainDetails: AnimationName.DOWN,
      },
      subintro: {
        train: AnimationName.UP,
      },
    }
  }
};
