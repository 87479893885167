var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sabre-history-compare"},[(_vm.shouldShowHeaderInfo)?_c('div',{staticClass:"sabre-history-compare__header-info"},[_vm._v("\n    "+_vm._s(_vm.headerInfo.createDateTime)+" |\n    "+_vm._s(_vm.$t('basket-trip-history.creation-agent'))+" "+_vm._s(_vm.headerInfo.creationAgent)+" |\n    "+_vm._s(_vm.$t('basket-trip-history.received-from'))+" "+_vm._s(_vm.headerInfo.receivedFrom)+"\n  ")]):_vm._e(),(_vm.generalInfo)?_c('div',{staticClass:"sabre-history-compare__general-info"},[_c('h5',{staticClass:"sabre-history-compare__general-info-header"},[_vm._v("\n      "+_vm._s(_vm.$t('basket-trip-history.general-info'))+"\n    ")]),_c('p',[_vm._v(_vm._s(_vm.generalInfo))])]):_vm._e(),(_vm.newBookingFlights.length || _vm.previousBookingFlights.length)?_c('div',{staticClass:"sabre-history-compare__section"},[_c('h5',{staticClass:"sabre-history-compare__general-info-header"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),(_vm.newBookingFlights.length)?_c('div',{staticClass:"sabre-history-compare__section-part-header"},[_vm._v("\n      "+_vm._s(_vm.$t('common.new'))+"\n    ")]):_vm._e(),(_vm.newBookingFlights.length)?_c('div',_vm._l((_vm.newFlightsMapped),function(flight,idx){return _c('b-table',{key:'newFlight' + idx,staticClass:"sabre-history-compare__flight-details",attrs:{"striped":"","stacked":"md","fields":_vm.fieldsOfFlight(flight),"items":flight.items,"per-page":20,"current-page":1,"no-sort-reset":true},scopedSlots:_vm._u([{key:"itemLabel",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.label)+"\n        ")]}},{key:"itemValue",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("\n            "+_vm._s(item.value)+"\n          ")])]}}],null,true)})}),1):_vm._e(),(_vm.previousBookingFlights.length)?_c('div',{staticClass:"sabre-history-compare__section-part-header"},[_vm._v("\n      "+_vm._s(_vm.$t('common.previous'))+"\n    ")]):_vm._e(),(_vm.previousBookingFlights.length)?_c('div',_vm._l((_vm.previousFlightsMapped),function(flight,idx){return _c('b-table',{key:'newFlight' + idx,staticClass:"sabre-history-compare__flight-details",attrs:{"striped":"","stacked":"md","fields":_vm.fieldsOfFlight(flight),"items":flight.items,"per-page":20,"current-page":1,"no-sort-reset":true},scopedSlots:_vm._u([{key:"itemLabel",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.label)+"\n        ")]}},{key:"itemValue",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("\n            "+_vm._s(item.value)+"\n          ")])]}}],null,true)})}),1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }