








































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { translate } from '@/i18n';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import AccountStore from '@/store/account.store';
import moment from 'moment';
import { router } from '@/router';
import EventBus from '@/services/event-handler';

@Component({})
export default class BasketTooltip extends Vue {
  @Prop() show!: boolean;

  showFull: boolean = false;
  loading: boolean = false;
  errors: any[] = [];

  
  get currentBasket() {
    return BasketStore.latestBasketData;
  }

  get basketItems() {
    return this.currentBasket ? this.currentBasket.items : [];
  }

  get basketTitle() {
    return this.currentBasket!.name;
  }

  get currentUser() {
    return AccountStore.current;
  }

  get desiredBasketItem() {
    return this.basketItems ? this.basketItems[0] : null;
  }

  get latestBasketExpirationDate() {
    return this.currentBasket && this.currentBasket.expirationDate ? moment(this.currentBasket.expirationDate) : null;
  }

  get clientTimezoneOffset() {
    return moment.parseZone(new Date()).utcOffset() as any;
  }

  get basketExpirationTime() {
    if (this.currentBasket && this.currentBasket.expirationDate) {
      let time = moment(this.currentBasket.expirationDate).utcOffset(this.clientTimezoneOffset).format('HH:mm');
      let zoneOffset = moment(this.currentBasket.expirationDate).utcOffset(this.clientTimezoneOffset).format('Z');
      return `${time} (GMT ${zoneOffset})`;
    }
    return  '';
  }

  get isBasketExpired() {
    if (this.latestBasketExpirationDate) {
      return moment().isAfter(this.latestBasketExpirationDate);
    }
    return false;
  }

  onClick(e: Event) {
    const target = e.target as HTMLElement;
    if ((target.className.includes('basket-tooltip'))) {
      this.showFull = true;
      return;
    } else {
    }
    this.showFull = !this.showFull;
  }

  goToTripList() {
    router.push({
      name: 'trips',
      query: {
        arrangers: [this.currentUser!.profile.id],
        basketStatuses: ['Draft']
      }
    });
    EventBus.$emit('close-basket-tooltip');
  }

  goToBasket() {
    router.push({
      name: 'basket',
      params: {
        id: this.currentBasket!.id
      }
    });
    EventBus.$emit('close-basket-tooltip');
  }

  dateString(start: string, end: string) {
    const sameMonth = moment(start).isSame(end, 'month');

    return sameMonth ?
    `${moment(start).format('dddd, MMM DD')} - ${moment(end).format('DD')}` :
    `${moment(start).format('dddd MMM DD')} - ${moment(end).format('MMM DD')}`;
  }

  textMap(itemType: string) {
    switch (itemType) {
      case 'Air': 
        return translate('basket.flight-to');
      case 'Rail':
        return translate('basket.train-to');
      case 'Car':
        return translate('basket.car-in');
      case 'Accommodation':
        return translate('basket.hotel-stay');
      default: 
        return '';
    }
  }

  iconMap(itemType: string) {
    switch (itemType) {
      case 'Air': 
        return 'flight';
      case 'Rail':
        return 'train';
      case 'Car':
        return 'directions_car';
      case 'Accommodation':
        return 'hotel';
      default: 
        return 'check';
    }
  }

  @Watch('show')
  async onShow(show) {
    if (show) {
      try {
        this.errors = [];
        this.loading = true;
        await BasketStore.getLatestBasketData();
      } catch (error) {
        this.errors = this.$handleErrors(error);
      } finally {
        this.loading = false;
      }
    }
  }

  onWindowClick(e) {
    let target = e.target as HTMLInputElement;
    if (!this.$el.contains(target) && (target.parentElement && !target.parentElement.classList.contains('basket-tooltip'))) {
      EventBus.$emit('close-basket-tooltip');
    }
  }

  created() {
    window.addEventListener('click', this.onWindowClick);
  }

  beforeDestroy() {
    window.removeEventListener('click', this.onWindowClick);
  }
}
