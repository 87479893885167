




















































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { numeric } from 'vuelidate/lib/validators';

import { AirApi } from '@/api/air-engine/air-search.api';
import { userFullName } from '@/core/user-full-name';
import SearchStore from '../search.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';
import {
  AdvancedSearchOptionsQuery,
  AdvancedSearchOptions,
  AdvancedSearchParameters} from '@/api/air-engine/air-search.model';

const eventName = 'update:show';

@Component({})
export default class AirDiscountsAndVouchersPopup extends Vue {
  @Prop() show!: boolean;

  loading: boolean = true;
  loaded: boolean = false;
  saving: boolean = false;
  showNotAvailableInfo: boolean = false;

  selectedTravellerId: string = '';

  suppliers: string[] = [];
  formData: AdvancedSearchOptions = {
    travellers: [],
    isEnabledForAirFranceKlm: false
  };
  advancedSearchParameters: AdvancedSearchParameters[] = [];
  errors: any[] = [];
  $v;

  get travellerParameters() {
    if (!this.advancedSearchParameters || !this.selectedTravellerId || !this.formData || !this.formData.travellers) {
      return null;
    }

    return this.advancedSearchParameters.find(x => x.travellerId === this.selectedTravellerId);
  }

  get shouldShowAirFranceParameters() {
    if (!this.selectedTravellerId || !this.formData) {
      return false;
    }

    return this.formData.isEnabledForAirFranceKlm;
  }

  get currentCompanyId() {
    if (SearchStore.skipTravellers) {
      return SearchStore.skipTravellersCompany.id;
    }

    return SearchStore.editedTravellers.travellers[0].businessUnitId;
  }

  get travellers() {
    return SearchStore.editedTravellersState.travellers;
  }

  get searchCompanyId() {
    if (!this.travellers.length) {
        return '';
    }

    const traveller0 = this.travellers[0] as any;

    if (traveller0.isVirtual) {
        return SearchStore.skipTravellersCompany.id;
    }

    return traveller0.companyId;
  }

  get airSearchState() {
    return SearchStore.getAirDefaultState;
  }

  @Validation()
  validationObject() {
    return { 
      travellerParameters: {
        airFranceAdvancedSearchParameters: {
          flyingBlueCardNumber: {
            numeric,
          },
        },
      },
    };
  }

  userFullName(user) {
    return userFullName(user);
  }

  getTravellerLabel(guestCode) {
    const option = searchConst.guestTravellerOptions.find(guest => {
      return guest.code === guestCode;
    });

    if (option) {
      return `${translate('search.guest')} (${translate(option.label)})`;
    }

    return guestCode;
  }

  selectTraveller(travellerId: string, index: number) {
    this.selectedTravellerId = travellerId;

    const travellerRef = (this.$refs.traveller as HTMLElement[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', travellerRef);
  }

  async confirmNow() {
    this.$v.$touch();
    if (this.$v.$error) {
      return;
    }
    this.saving = true;

    try {
      if (this.airSearchState.advancedSearchParametersId) {
        await AirApi.updateAdvancedOptions(this.airSearchState.advancedSearchParametersId, this.advancedSearchParameters);
      } else {
        const response = await AirApi.createAdvancedOptions(this.advancedSearchParameters);

        this.airSearchState.advancedSearchParametersId = response.data;
      }

      this.hidePopup();
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.saving = false;
    }
  }

  reset() {
    this.loading = true;
    this.loaded = false;
    this.saving = false;
    this.showNotAvailableInfo = false;
    this.suppliers = [];
    this.formData = {
      travellers: [],
      isEnabledForAirFranceKlm: false
    };
    this.advancedSearchParameters = [];
    this.errors = [];
  }

  async loadData() {
    try {
      const availabilityResponse = await AirApi.getAdvancedOptionsAvailability(this.currentCompanyId);

      if (!availabilityResponse.data || !availabilityResponse.data.length) {
        this.showNotAvailableInfo = true;

        return;
      }

      this.suppliers = availabilityResponse.data;

      const formDataRequest: AdvancedSearchOptionsQuery = {
        searchCompanyId: this.searchCompanyId,
        suppliers: this.suppliers,
        travellers: this.travellers
      };

      const formDataResponse = await AirApi.getAdvancedOptions(formDataRequest);

      if (!formDataResponse.data) {
        this.showNotAvailableInfo = true;

        return;
      }

      this.formData = formDataResponse.data;

      await this.applyData();

      this.loaded = true;
      this.selectedTravellerId = this.formData.travellers[0].id;
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }

  async applyData() {
    if (this.airSearchState.advancedSearchParametersId) {
      const parametersResponse = await AirApi.getSavedAdvancedOptions(this.airSearchState.advancedSearchParametersId);

      this.advancedSearchParameters = this.formData.travellers.map((t, index) => {
        let travellerParameters = parametersResponse.data.find(x => x.travellerId === t.id);

        if ((this.travellers[0] as any).isVirtual === true) {
          travellerParameters = parametersResponse.data[index];

          if (travellerParameters) {
            travellerParameters.travellerId = t.id;
            travellerParameters.passengerTypeCode = t.passengerTypeCode;
          }
        }

        if (!travellerParameters) {
          const newParameters: AdvancedSearchParameters = {
            airFranceAdvancedSearchParameters: {
              flyingBlueCardNumber: ''
            },
            passengerTypeCode: t.passengerTypeCode,
            travellerId: t.id
          };

          return newParameters;
        }

        return travellerParameters;
      });
    } else {
      this.advancedSearchParameters = this.formData.travellers.map(t => ({
        travellerId: t.id,
        passengerTypeCode: t.passengerTypeCode,
        airFranceAdvancedSearchParameters: {
          flyingBlueCardNumber: ''
        }
      }));
    }
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }

  @Watch('show', { immediate: true })
  onShow(value: boolean) {
    if (!value) {
      return;
    }

    this.$v.$reset();
    this.reset();
    this.loadData();
  }
}

