













import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import AirSearchStore from './air-search.store';
import AirSearchExchangeParamOnResults from './AirSearchExchangeParamOnResults.vue';

@Component({
  components: {
    AirSearchExchangeParamOnResults,
  }
})
export default class AirSearchExchangeShortParams extends Vue {
  searchId: string | null = null;

  get airSearchParameters() {
    return {
      locations: AirSearchStore.getExchangeLocations || {
        from: [],
        to: [],
      },
      dates: AirSearchStore.getExchangeDates || [],
      offer: AirSearchStore.getExchangedOffer,
    };
  }

  get travellersSearchList() {
    return {
      travellers: AirSearchStore.exchangeTravellers || [],
    };
  }
}
