






































































































































































































































































































































































































import { Vue, Prop, Component, Emit } from 'vue-property-decorator';

import { OffersApi } from '@/api/air-engine/offers.api';
import { TrainOrdersApi } from '@/api/train-engine/train-orders.api';
import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { userFullName } from '@/core/user-full-name';

const eventName = 'update:show';

@Component({
  components: {
  },
})
export default class BasketProviderBookingPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() item: any;

  errors: any = [];
  bookingDetailsData: any = null;
  loading: boolean = true;
  bookingDetailsDataToCopy: any = null;

  async created() {
    this.errors = [];
    try {
      if (this.item.type === 'Air') {
        const provider = this.item.supplier.toLowerCase();
        const { data } = await OffersApi.getProviderBookingDetails(this.item.providerReferenceId, provider);
        this.bookingDetailsData = data;
        this.bookingDetailsDataToCopy = JSON.stringify(data);

        if (['Lufthansa', 'AmericanAirlines', 'AirFranceKlm'].indexOf(this.item.supplier) > -1) {
          if (this.bookingDetailsData.orderItemStatus) {
            this.getOrderItemStatus();
          }
        }
      } else if (this.item.type === 'Rail') {
        const { data } = await TrainOrdersApi.getProviderBookingDetails(this.item.providerReferenceId, this.item.supplier);
        this.bookingDetailsData = data;
        this.bookingDetailsDataToCopy = JSON.stringify(data);
        
      } else if (this.item.type === 'Accommodation') {
        const { data } = await AccommodationSearchApi.getProviderBookingDetails(this.item.providerReferenceId, this.item.supplier);
        this.bookingDetailsData = data;
        this.bookingDetailsDataToCopy = JSON.stringify(data);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.errors.push({ message: translate('common-error.not-exist') });
      } else {
        this.errors = this.$handleErrors(error);
      }
    } finally {
      this.loading = false;
    }
  }

  async removeFlightOrderService(recomendationId, orderItemId) {
    let ordersToRemove: string[] = [];
    this.bookingDetailsData.orderItemStatus.forEach(order => {
      if (order.selected || order.orderItemID === orderItemId) {
        ordersToRemove.push(order.orderItemID);
      }
    });
    this.errors = [];
    try {
      this.bookingDetailsData = null;
      await OffersApi.deleteLufthansaOrderItems(recomendationId, ordersToRemove);

      const { data } = await OffersApi.getLufthansaBookingDetails(this.item.providerReferenceId);

      this.bookingDetailsData = data;
      this.bookingDetailsDataToCopy = JSON.stringify(data);
      if (this.bookingDetailsData.orderItemStatus) {
        this.getOrderItemStatus();
      }
    } catch (error) {
      this.errors = this.$handleErrors(error);
    }
  }

  userFullName(user) {
    return userFullName(user);
  }

  shouldShowRemoveOrder(order) {
    return order.services.find(service => -1 < ['HN', 'HD'].indexOf(service.serviceStatus));
  }

  getOrderItemStatus() {
    this.bookingDetailsData.orderItemStatus = this.bookingDetailsData.orderItemStatus.map(orderItem => {
      return {
        ...orderItem,
        selected: false,
      };
    }); 
  }

  onSuccess() {
    EventBus.$emit('show-toast', {
      type: translate('common.success'),
      message: translate('basket.copy-succes')
    });
  }

  onError() {
    EventBus.$emit('show-toast', {
      type: translate('common.error'),
      message: translate('basket.copy-error')
    });
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}
