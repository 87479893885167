import DelayTicketingConfiguration from '@/modules/settings/delay-ticketing/DelayTicketingConfiguration.vue';

export default {
  path: ':configurationId?/delay-ticketing-configuration',
  name: 'delay-ticketing-configuration',
  component: DelayTicketingConfiguration,
  displayName: 'Delay Ticketing Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditDelayTicketing',
    parentName: 'delay-ticketing-configurations',
    depthCategory: 'delay-ticketing',
    depth: 2,
  }
};