import DisplayPreferences from '@/modules/settings/display-preferences/configurations/DisplayPreferences.vue';
import DisplayPreferencesConfigurations from './display-preferences/display-preferences-configurations.route';
import DisplayPreferencesConfiguration from './display-preferences/display-preferences-configuration.route';
import DisplayPreferencesFlightRules from './display-preferences/display-preferences-flight-rule.route';

export default {
  path: 'display-preferences',
  component: DisplayPreferences,
  displayName: 'settings-sidebar.display-preferences',
  meta: {
    permission: 'CanEditDisplayPreferences',
    introClass: 'intro-settings',
    icon: 'favorite',
    parentName: 'display-preferences-configurations',
  },
  children: [
    DisplayPreferencesConfigurations,
    DisplayPreferencesConfiguration,
    DisplayPreferencesFlightRules,
  ],
};
