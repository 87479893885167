









































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import SearchShortTravellers from '@/modules/search/SearchShortTravellers.vue';
import AirSearch from './air/AirSearch.vue';
import HotelSearch from './hotel/HotelSearch.vue';
import TrainSearch from './train/TrainSearch.vue';
import CarSearch from './car/CarSearch.vue';
import NoSearch from './NoSearch.vue';
import PKPSearch from './PKPSearch.vue';
import { serviceClassEnum } from '@/api/home/home.model';
import SearchStore from './search.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import settings from '@/settings';
import { Debounce } from '@/core/decorators/debounce.decorator';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import DebounceConst from '@/const/debounce.const';
import AccountStore from '@/store/account.store';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import BasketStore from '@/modules/basket/basket.store';
import DynamicHeightTransition from '@/modules/layout/DynamicHeightTransition.vue';
import DiscountsAndVouchersPopup from './train/DiscountsAndVouchersPopup.vue';
import AirDiscountsAndVouchersPopup from './air/AirDiscountsAndVouchersPopup.vue';
import HotelSearchStore from './hotel/hotel-search.store';
import { SearchInitModes } from '@/api/profile/profile.model';
import TrainSearchStore from './train/train-search.store';
import UiGuestTravellersRoomsSelect from '@/controls/UiGuestTravellersRoomsSelect.vue';
import UiTravellersSelect from '@/controls/UiTravellersSelect.vue';
import UiTrainTravellersSelect from '@/controls/UiTrainTravellersSelect.vue';

@Component({
  components: {
    AirSearch,
    HotelSearch,
    TrainSearch,
    CarSearch,
    NoSearch,
    PKPSearch,
    SearchShortTravellers,
    DiscountsAndVouchersPopup,
    AirDiscountsAndVouchersPopup,
    DynamicHeightTransition,
  }
})
export default class SearchChooseService extends Vue {
  @Prop() isInBasket!: boolean;

  showingDiscountsVouchersPopup: boolean = false;
  showingAirDiscountsVouchersPopup: boolean = false;
  companiesLoading: boolean = false;
  model: any | null = null;
  selectedServiceIndex: number = 0;
  heightMonitor: any = null;
  setHeightValue: number = 62;
  pending: boolean = true;
  nextService: any | null = null;

  get isDiscountsAndVouchersVisible() {
    if (!this.editedTravellers || !this.editedTravellers.travellers || this.editedTravellers.travellers.length < 1) {
      return false;
    }

    if (this.currentService === this.ServiceClassEnum.Flight) {
      return this.editedTravellers.travellers.every(x => Boolean(x.isVirtual));
    }

    return this.currentService === this.ServiceClassEnum.Train;
  }
  get trainSearchParameters() {
    return SearchStore.getTrainDefaultState;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get advancedSearchIcon() {
    let parametersId = '';

    if (this.currentService === this.ServiceClassEnum.Train) {
      parametersId = this.trainSearchParameters.advancedSearchParametersId;
    }

    if (this.currentService === this.ServiceClassEnum.Flight) {
      parametersId = this.airSearchParameters.advancedSearchParametersId;
    }

    return parametersId ? 'add_circle' : 'add_circle_outline';
  }

  get advancedSearchButtonText() {
    if (this.currentService === this.ServiceClassEnum.Train) {
      return this.$t('rail-discount-codes.apply-travel-preferences');
    }

    if (this.currentService === this.ServiceClassEnum.Flight) {
      return this.$t('air-discount-codes.apply-discount-and-vouchers');
    }

    return '';
  }

  get guestTravellerOptions() {
    return searchConst.guestTravellerOptions.map(option => {
      return {
        ...option,
        name: translate(option.label)
      };
    });
  }

  get canSearchMixedHotelRooms() {
    return this.$hasAccess('CanSearchMixedHotelRooms');
  }

  get pkpAgencyIds() {
    return settings.hasOwnProperty('pkpAgencyIds') ? JSON.parse(settings.pkpAgencyIds) : false;
  }

  get serviceLinks() {
    return searchConst.serviceLinks.map((link) => {
      return {
        ...link,
        label: translate(link.label)
      };
    }).filter(link => {
      if (link.permission !== '') {
        return this.$hasAccess(link.permission);
      } else if (this.pkpAgencyIds && this.pkpAgencyIds.length > 0 && this.user!.profile.agencyId) {
        return this.pkpAgencyIds.some((pkpId) => pkpId === this.user!.profile.agencyId);
      }
      
    }); 
  }

  get editedTravellers() {
    return SearchStore.editedTravellers;
  }

  get ServiceClassEnum() {
    return serviceClassEnum;
  }

  get guestRooms() {
    return HotelSearchStore.guestRooms;
  }

  set guestRooms(value) {
    HotelSearchStore.setGuestRooms(value);
  }

  get currentService() {
    return SearchStore.serviceNavigating;
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get canShowSkipTravellers() {
    return 'true' === settings.enableExternalSkipTravellers;
  }

  get companies() {
    return SearchStore.companies;
  }

  get user() {
    return AccountStore.current;
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  get isSearchView() {
    return this.$route.name === 'search';
  }

  get isResultsView() {
    return ( this.$route.name === 'airDetails' ||
      this.$route.name === 'trainDetails' ||
      this.$route.name === 'hotelResultsSearch' ||
      this.$route.name === 'carDetails' );
  }

  get companyModel() {
    return this.user ? {
      name: this.user!.profile.companyName,
      code: this.user!.profile.companyCode,
      id: this.user!.profile.companyId,
      isDisabled: false,
      parentId: null,
      rootId: this.user!.profile.rootCompanyId,
      isSelected: false,
    } : null;
  }

  get basketMetadata() {
    return BasketStore.basketMetadata ? BasketStore.basketMetadata.metadata : null;
  }

  get isInExistingBasket() {
    return !!this.basketMetadata || this.$router.currentRoute.name === 'basketAddSegment';
  }

  get isMobile() {
    return window.innerWidth <= 910;
  }

  get classes() {
    return {
      'xs-hidden-mobile': !this.$hasAccess('SelectTravellersForSearch')
    };
  }

  get canSelectTravellersForSearch() {
    return this.$hasAccess('SelectTravellersForSearch');
  }

  get canSelectCompanyForSearch() {
    const currentUser = AccountStore.CurrentUser;
    return currentUser && currentUser.profile &&
      currentUser.profile.defaultSearchMode !== SearchInitModes.SelectGuestsWithNoCompanySelection;
  }

  get isInBasketAddSegment() {
    return 'basketAddSegment' === this.$route.name;
  }



  showDiscountsVouchersPopup() {
    if (this.currentService === this.ServiceClassEnum.Train) {
      this.showingDiscountsVouchersPopup = true;
    }
    
    if (this.currentService === this.ServiceClassEnum.Flight) {
      this.showingAirDiscountsVouchersPopup = true;
    }
  }

  resultsServiceMap() {
    switch (this.$route.name) {
      case 'airDetails':
        return this.ServiceClassEnum.Flight;
      case 'trainDetails':
        return this.ServiceClassEnum.Train;
      case 'hotelResultsSearch':
        return this.ServiceClassEnum.Hotel;
      case 'carDetails':
        return this.ServiceClassEnum.Car;
      default:
        return '';
    }
  }

  serviceIcon(serviceName) {
    let service = searchConst.serviceLinks.find(sv => {
      return sv.name === serviceName;
    });

    return service ? service.icon : '';
  }

  focusOn(elementId) {
    this.$nextTick(() => {
      const inputValue = document.getElementById(elementId);
      if (inputValue) {
        inputValue.focus();
      }
    });
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  selectTravellersComponent() {
    const travellersSelect = this.$refs.travellersSelect as Vue;
    
    if (travellersSelect) {
      (travellersSelect.$refs.uiTravellerSelectWrapper as HTMLElement).focus();
    }
  }

  onCompanyClose(value) {
    this.selectTravellersComponent();
  }

  select(service) {
    if (service.disabled) {
      return;
    }

    if (this.pending) {
      this.nextService = service;
      return;
    }
    setTimeout(() => {
      SearchStore.selectNavigated(service.name);
    });

    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.$emit('focusSearch');
    } else {
      this.focusOn(service.patch + '-search-' + (service.patch === 'hotel' ? 'to' : 'from'));
    }
  }

  @AnimatedIn()
  whenAnimatedIn() {
    this.pending = false;
    if (this.nextService) {
      this.select(this.nextService);
    }
  }

  showTravellers() {
    router.push({
      name: 'searchTravellersList',
    });
  }

  showTravellersOnResults() {
    switch (router.currentRoute.name) {
      case 'airDetails':
        router.push({
          name: 'airResultsTravellersList',
          params: {
            ...router.currentRoute.params,
            service: this.resultsServiceMap()
          }
        });
        return;
      case 'trainDetails':
        router.push({
          name: 'trainResultsTravellersList',
          params: {
            ...router.currentRoute.params,
            service: this.resultsServiceMap()
          }
        });
        return;
      case 'hotelResultsSearch':
        router.push({
          name: 'hotelResultsTravellersList',
          params: {
            ...router.currentRoute.params,
            service: this.resultsServiceMap()
          }
        });
        return;
      case 'carDetails':
        router.push({
          name: 'carResultsTravellersList',
          params: {
            ...router.currentRoute.params,
            service: this.resultsServiceMap()
          }
        });
        return;
      default: 
        return;
    }
  }

  @Watch('model')
  onCompanyChange(company) {
    SearchStore.setSkipTravellersCompany(company);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'companiesLoading',
  })
  async loadCompanies(phrase: string) {
    await SearchStore.loadCompanies(phrase);
    this.companiesLoading = false;
  }

  initCompanyModel() {
    if (null === SearchStore.skipTravellersCompany) {
      this.model = this.companyModel;
      return;
    }
    this.model = SearchStore.skipTravellersCompany;
  }

  updateTravellers(value) {
    SearchStore.getTrainDefaultState.advancedSearchParametersId = '';
    SearchStore.getAirDefaultState.advancedSearchParametersId = '';
    SearchStore.updateEditedTravellers(value);
  }

  updateTrainTravellers(value) {
    this.updateTravellers(value);
    TrainSearchStore.setTravellers(value);
  }

  updateTravellersAndRooms(param) {
    const travellers = this.guestRooms.reduce((prev, cur) => {
      return [...prev, ...cur.profiles];
    }, []);
    this.updateTravellers({
      travellers,
    });
  }

  onUpdateTravellers() {
    if (!this.isResultsView && this.$route.name !== 'search') {
      return;
    }

    SearchStore.updateTravellersDefaultState(SearchStore.editedTravellers);
  }

  onGuestsInvalid(value) {
    if (this.currentService === serviceClassEnum.Train) {
      EventBus.$emit('train-guests-invalid', value);
      return;
    }
    EventBus.$emit('hotel-guests-invalid', value);
  }

  onUpdateSkipTravellersCompany() {
    this.model = SearchStore.skipTravellersCompany;
  }

  closeTravellersSelect() {
    const travellersSelect = (this.$refs.travellersSelect || this.$refs.guestTravellersRoomsSelect) as UiTravellersSelect | UiTrainTravellersSelect | UiGuestTravellersRoomsSelect;
    if (travellersSelect && travellersSelect.showFull) {
      travellersSelect.checkOptions();
    }
  }

  created() {
    this.initCompanyModel();
    EventBus.$on('update-travellers', this.onUpdateTravellers);
    EventBus.$on('showing-with-no-animation', this.whenAnimatedIn);
    EventBus.$on('update-skip-travellers-company', this.onUpdateSkipTravellersCompany);

    this.editedTravellers.travellers = SearchStore.getTravellersState.travellers
      .map(traveller => {
        return {
          ...traveller,
        };
      });
  }

  beforeDestroy() {
    EventBus.$off('update-travellers', this.onUpdateTravellers);
    EventBus.$off('showing-with-no-animation', this.whenAnimatedIn);
    EventBus.$off('update-skip-travellers-company', this.onUpdateSkipTravellersCompany);
  }
}
