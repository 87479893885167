


















































































































































































































































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { userFullName } from '@/core/user-full-name';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import SettingsStore from '@/modules/settings/settings.store';
import AccountStore from '@/store/account.store';
import { Permission } from '@/const/permission.enum';
import { BaseTable } from '@/core/base-table.class';
import { CommunityTableParams } from './community-table-params';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';
import { sortByName } from '@/core/utils';

@Component({})
export default class CommunityProfiles extends BaseTable<any> {
  loading: boolean = false;
  useQueryParams: boolean = true;
  params: CommunityTableParams = new CommunityTableParams({
    sort: '',
    size: 10,
    page: 1,
    usePageParam: true,
  });
  $v;
  profile: any | null = null;
  deactivateProfilePopup: boolean = false;
  activateProfilePopup: boolean = false;
  deleteProfilePopup: boolean = false;
  deleteInProgress: boolean = false;
  popupError: any[] = [];
  menuActive: boolean = false;
  unitSelectOptions: any[] = [];
  serverErrors: any[] = [];
  Form: any = {
    searchUser: '',
    searchUnit: null,
    travellerType: null
  };
  travellerTypeOptions: any[] = [
    {
      name: translate('settings-community.guest-profile'),
      label: 'GuestTravellers',
      value: true,
    },
    {
      name: translate('settings-community.standard-profile'),
      label: 'RegularTravellers',
      value: false,
    },
    {
      name: translate('settings-community.all'),
      label: 'All',
      value: 'all',
    },
  ];
  imagesConst: string = '/assets/img/loader/1.gif';
  detailsErrors: any[] = [];
  popup2FAErrors: any[] = [];
  show2FAPopup: boolean = false;
  is2FAPopupEdit: boolean = false;
  popupPhoneNumber: string = '';
  disableInProgress: boolean = false;
  enableInProgress: boolean = false;
  showSSOPopup: boolean = false;
  popupSSOErrors: any[] = [];
  isSSOPopupEdit: boolean = false;
  upnSso: string = '';

  get fields() {
    const profileName = {
      profileName: {
        label: translate('settings-community.profile-name'),
        class: 'community__column--profile-name',
      },
    };
    const profileDetails = {
      travellerType: {
        label: translate('settings-community.profile-type'),
        class: 'community__column--traveller-type',
      },
      primaryEmail: {
        label: translate('settings-community.primary-email'),
        class: 'community__column--email',
      },
    };
    const actionColumnns = {
      isActive: {
        sortable: false,
        label: '',
        class: 'community__column--is-active',
      },
      actions: {
        sortable: false,
        label: '',
        class: 'community__column--actions',
      }
    };
    let fields;

    if ((this.$hasAccess('CanAssign2FaSms') || this.$hasAccess('CanAssignSso')) && this.$hasAccess('ManageProfile')) {
      fields = {
        ...profileName,
        username: {
          label: translate('settings-community.username'),
          class: 'community__column--username',
        },
        ...profileDetails,
        ...actionColumnns,
      };
    } else if (this.$hasAccess('ManageProfile')) {
      fields = {
        ...profileName,
        ...profileDetails,
        ...actionColumnns,
      };
    } else {
      fields = {
        ...profileName,
        ...profileDetails,
      };
    }

    return fields;
  }

  get travellerRowClass() {
    return {
      'row community__traveller-row': !this.$hasAccess('CanAssign2FaSms') && !this.$hasAccess('CanAssignSso'),
      'row community__traveller-short-row': this.$hasAccess('CanAssign2FaSms') || this.$hasAccess('CanAssignSso'),
    };
  }

  get travellerClass() {
    return {
      'community__traveller-name': !this.$hasAccess('CanAssign2FaSms') && !this.$hasAccess('CanAssignSso'),
      'community__traveller-short-name': this.$hasAccess('CanAssign2FaSms') || this.$hasAccess('CanAssignSso'),
    };
  }

  get unitClass() {
    return {
      'community__traveller-unit': !this.$hasAccess('CanAssign2FaSms') && !this.$hasAccess('CanAssignSso'),
      'community__traveller-short-unit': this.$hasAccess('CanAssign2FaSms') || this.$hasAccess('CanAssignSso'),
    };
  }

  get companyId() {
    return this.$route.params.id;
  }

  get count() {
    return this.results.length;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get companyIdForAutocomplete() {
    return SettingsStore.currentCompany ? SettingsStore.currentCompany.companyId : AccountStore.current!.profile.companyId;
  }

  get canDeleteProfiles() {
    return this.$hasAccess('CanDeleteProfile');
  }

  get emailClass() {
    return {
      'community__email--short': (this.$hasAccess('CanAssign2FaSms') || this.$hasAccess('CanAssignSso')) && this.$hasAccess('ManageProfile'),
    };
  }

  get addProfileUrl() {
    return sanitizeUrl(`/settings/${this.currentCompany!.companyId}/community/add-profile?unitId=${this.Form.searchUnit ? this.Form.searchUnit.id : ''}`);
  }



  userFullName(user) {
    return userFullName(user);
  }

  travellerUrl(trav) {
    return sanitizeUrl('/profile/' + trav.id);
  }

  async loadProfileDetails(results) {
    this.detailsErrors = [];
    try {
      const response = await ProfileApi.getProfiles2faInfo(results.map(item => item.id));
      const details = response.data;

      results.forEach(item => {
        const detailsItem = details.find(el => el.profileId === item.id);

        if (!detailsItem) {
          return;
        }

        item.username = detailsItem.userName;
        item.phoneNo = detailsItem.phoneNo;
        item.accountId = detailsItem.accountId;
        item.upnSso = detailsItem.upnSso;
        if (detailsItem.isActive === false) {
          if (detailsItem.accountId !== null) {
            item.isActiveMessage = translate('settings-community.account-not-activated');
          } else {
            item.isActiveMessage = '';
          }
        }
        
        if (detailsItem.twoFactorEnabled === false) {
          if (detailsItem.accountId !== null) {
            item.authenticationType = translate('settings-community.authentication-password');
          } else {
            item.authenticationType = '';
          }
          item.auth2FAType = '';
        } else if (detailsItem.authentication2FaType === 'Phone') {
          item.authenticationType = translate('settings-community.authentication-2fa-sms');
          item.auth2FAType = 'sms';
        } else if (detailsItem.authentication2FaType === 'Sso') {
          item.authenticationType = translate('settings-community.authentication-2fa-sso');
          item.auth2FAType = 'Sso';
        } else {
          item.authenticationType = translate('settings-community.authentication-2fa-app');
          item.auth2FAType = 'app';
        }
        item.isLoading = false;
      });
    } catch (error) {
      this.detailsErrors = this.$handleErrors(error);
      results.forEach(item => {
        item.isLoading = null;
      });
    }
  }

  @Validation()
  validationObject() {
    return {
      popupPhoneNumber: {
        required,
      },
      upnSso: {
        required,
      }
    };
  }

  enable2FA() {
    this.$v.$reset();
    this.popup2FAErrors = [];
    this.popupPhoneNumber = this.profile.phoneNo;
    this.is2FAPopupEdit = false;
    this.show2FAPopup = true;
  }

  edit2FA() {
    this.$v.$reset();
    this.popup2FAErrors = [];
    this.popupPhoneNumber = this.profile.phoneNo;
    this.is2FAPopupEdit = true;
    this.show2FAPopup = true;
  }

  async disable2FA() {
    if (!this.profile) {
      return;
    }

    this.disableInProgress = true;
    this.popup2FAErrors = [];
    try {
      await ProfileApi.saveProfile2faSetting(this.profile.id, {
        phoneNo: null,
        enable2faViaPhone: false
      });

      this.show2FAPopup = false;
      this.disableInProgress = false;
      this.updateProfileInfo();
    } catch (error) {
      this.popup2FAErrors = this.$handleErrors(error, true);
      this.disableInProgress = false;
    }
  }

  async updatePhone2FA() {
    if (!this.profile) {
      return;
    }
    this.$v.popupPhoneNumber.$touch();
    if (this.$v.popupPhoneNumber.$pending || this.$v.popupPhoneNumber.$error) {
      return;
    }

    this.enableInProgress = true;
    this.popup2FAErrors = [];
    try {
      await ProfileApi.saveProfile2faSetting(this.profile.id, {
        phoneNo: this.popupPhoneNumber,
        enable2faViaPhone: true
      });

      this.show2FAPopup = false;
      this.enableInProgress = false;
      this.updateProfileInfo();
    } catch (error) {
      this.popup2FAErrors = this.$handleErrors(error, true);
      this.enableInProgress = false;
    }
  }

  async enablePhone2FA() {
    if (!this.profile) {
      return;
    }
    this.$v.popupPhoneNumber.$touch();
    if (this.$v.popupPhoneNumber.$pending || this.$v.popupPhoneNumber.$error) {
      return;
    }

    this.enableInProgress = true;
    this.popup2FAErrors = [];
    try {
      await ProfileApi.saveProfile2faSetting(this.profile.id, {
        phoneNo: this.popupPhoneNumber,
        enable2faViaPhone: true
      });

      this.show2FAPopup = false;
      this.enableInProgress = false;
      this.updateProfileInfo();
    } catch (error) {
      this.popup2FAErrors = this.$handleErrors(error, true);
      this.enableInProgress = false;
    }
  }

  enableSSO() {
    this.$v.$reset();
    this.popupSSOErrors = [];
    this.isSSOPopupEdit = false;
    this.upnSso = '';
    this.showSSOPopup = true;
  }

  editSSO() {
    this.$v.$reset();
    this.popupSSOErrors = [];
    this.isSSOPopupEdit = true;
    this.upnSso = this.profile.upnSso;
    this.showSSOPopup = true; 
  }

  async disableSSO() {
    if (!this.profile) {
      return;
    }

    this.disableInProgress = true;
    this.popupSSOErrors = [];
    try {
      await ProfileApi.saveProfileSsoSetting(this.profile.id, {
        upnSso: null,
        enableSso: false
      });

      this.showSSOPopup = false;
      this.disableInProgress = false;
      this.updateProfileInfo();
    } catch (error) {
      this.popupSSOErrors = this.$handleErrors(error, true);
      this.disableInProgress = false;
    }
  }

  async enableUpnSso() {
    if (!this.profile) {
      return;
    }
    this.$v.upnSso.$touch();
    if (this.$v.upnSso.$pending || this.$v.upnSso.$error) {
      return;
    }

    this.enableInProgress = true;
    this.popupSSOErrors = [];
    try {
      await ProfileApi.saveProfileSsoSetting(this.profile.id, {
        upnSso: this.upnSso,
        enableSso: true
      });

      this.showSSOPopup = false;
      this.enableInProgress = false;
      this.updateProfileInfo();
    } catch (error) {
      this.popupSSOErrors = this.$handleErrors(error, true);
      this.enableInProgress = false;
    }
  }

  async updateProfileInfo() {
    this.detailsErrors = [];
    try {
      const item = this.results.find(el => el.id === this.profile.id);
      
      if (!item) {
        return;
      }

      item.isLoading = true;
      const response = await ProfileApi.getProfiles2faInfo([
        this.profile.id,
      ]);

      if (!response.data.length) {
        return;
      }

      const updated = response.data[0];

      item.username = updated.userName;
      item.phoneNo = updated.phoneNo;
      item.accountId = updated.accountId;
      item.upnSso = updated.upnSso;
      if (updated.isActive === false) {
        if (updated.accountId !== null) {
          item.isActiveMessage = translate('settings-community.account-not-activated');
          item.auth2FAType = '';
        } else {
          item.isActiveMessage = '';
        }
      }
      if (updated.twoFactorEnabled === false) {
        if (updated.accountId !== null) {
          item.authenticationType = translate('settings-community.authentication-password');
        } else {
          item.authenticationType = '';
        }
        item.auth2FAType = '';
      } else if (updated.authentication2FaType === 'Phone') {
        item.authenticationType = translate('settings-community.authentication-2fa-sms');
        item.auth2FAType = 'sms';
      } else if (updated.authentication2FaType === 'Sso') {
        item.authenticationType = translate('settings-community.authentication-2fa-sso');
        item.auth2FAType = 'Sso';
      } else {
        item.authenticationType = translate('settings-community.authentication-2fa-app');
        item.auth2FAType = 'app';
      }
      item.isLoading = false;
    } catch (error) {
      this.detailsErrors = this.$handleErrors(error);
    }
  }

  showProfile({ id }) {
    this.$router.push({
      name: 'personal',
      params: {
        id,
      },
    });
  }

  showAddProfilePopup() {
    this.$router.push({
      name: 'community-add-profile',
      params: {
        id: this.$route.params.id,
      },
      query: {
        unitId: this.Form.searchUnit ? this.Form.searchUnit.id : ''
      }
    });
  }

  toggleConfigurationMenu(item) {
    if (this.profile !== item) {
      this.profile = item;
      this.menuActive = true;
    } else {
      this.menuActive = !this.menuActive;
    }
  }

  activateProfile() {
    this.popupError = [];
    this.menuActive = false;
    this.activateProfilePopup = true;
  }

  deactivateProfile() {
    this.popupError = [];
    this.menuActive = false;
    this.deactivateProfilePopup = true;
  }

  showDeleteProfilePopup() {
    this.popupError = [];
    this.menuActive = false;
    this.deleteProfilePopup = true;
  }

  hideDeleteProfilePopup() {
    this.deleteProfilePopup = false;
  }

  async deleteProfile() {
    if (!this.profile) {
      this.hideDeleteProfilePopup();
      return;
    }
    this.popupError = [];
    this.deleteInProgress = true;
    try {
      await ProfileApi.deleteProfile(this.profile.id);

      this.reload();
      this.hideDeleteProfilePopup();
    } catch (error) {
      if (
        error && error.response && error.response.data && error.response.data.error &&
        'OWN_PROFILE_DELETION' === error.response.data.error.code
      ) {
        this.popupError = [{
          message: translate('settings-community.unable-to-delete-or-disable'),
        }];
      } else {
        this.popupError = this.$handleErrors(error, true);
      }
    } finally {
      this.deleteInProgress = false;
    }
  }

  async changeStatus(state: string) {
    try {
      let message = {
        state: state
      };

      await ProfileApi.changeProfileStatus(message, this.profile.id);

      this.reload();
      this.deactivateProfilePopup = false;
      this.activateProfilePopup = false;

    } catch (error) {
      if (
        error && error.response && error.response.data && error.response.data.error &&
        'DISABLING_OWN_PROFILE' === error.response.data.error.code
      ) {
        this.popupError = [{
          message: translate('settings-community.unable-to-delete-or-disable'),
        }];
      } else {
        this.popupError = $handleErrors(error, true);
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  async loadCompanies(phrase: string) {
    this.serverErrors = [];
    try {
      const result = await ProfileCompanyApi.getCompanyUnits(this.currentCompany!.rootCompanyId, phrase, Permission.ReadCommunity);

      this.unitSelectOptions = sortByName(result.data);
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.searchButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  updateParams() {
    if (this.Form.searchUnit) {
      this.params.unitId = this.Form.searchUnit.id;
    } else {
      this.params.unitId = '';
    }
    if (this.Form.searchUser) {
      this.params.phrase = this.Form.searchUser;
    } else {
      this.params.phrase = '';
    }
    if (this.Form.travellerType) {
      if (this.Form.travellerType.value === 'all') {
        this.params.isGuest = null;
      } else {
        this.params.isGuest = this.Form.travellerType.value;
      }
    } else {
      this.params.isGuest = null;
    }
  }

  submitForm() {
    this.updateParams();
    this.resetList();
    this.reload();
  }

  async fillParameters(query) {
    if (query.unitId) {
      const company = this.unitSelectOptions.find(item => item.id === query.unitId);
      if (company) {
        this.Form.searchUnit = company;
      } else {
        let companyInfo = await ProfileCompanyApi.getById(query.unitId, Permission.AccessSettings);
        this.Form.searchUnit = companyInfo.data;
      }
    }

    if (query.travellerType) {
      const type = this.travellerTypeOptions.find(item => item.label === query.travellerType);

      if (!type) {
        this.Form.travellerType = this.travellerTypeOptions.find(item => item.label === 'All');
      } else {
        this.Form.travellerType = type;
      }
    } else {
      this.Form.travellerType = this.travellerTypeOptions.find(item => item.label === 'All');
    }

    if (query.user) {
      this.Form.searchUser = query.user;
    } else {
      this.Form.searchUser = '';
    }

    this.updateParams();
  }

  fillQuery(query) {
    if (this.Form.searchUnit) {
      query.unitId = this.Form.searchUnit.id;
    }

    if (this.Form.travellerType && this.Form.travellerType.value !== 'all') {
      query.travellerType = this.Form.travellerType.label;
    }

    if (this.Form.searchUser) {
      query.user = this.Form.searchUser;
    }
  }

  async getItems(params: any) {
    const id = this.Form.searchUnit.id;
    
    this.serverErrors = [];
    try {
      this.loading = true;
      const result = await ProfileApi.getCompanyProfiles(id, params.size, params.page, params.phrase, params.unitId, params.isGuest);
      const results = result.data.results.map(item => {
        return {
          ...item,
          isLoading: true,
          username: null,
          authenticationType: null,
          isActiveMessage: null,
          auth2FAType: null,
          phoneNo: null,
          accountId: null,
        };
      });

      if (this.$hasAccess('CanAssign2FaSms') || this.$hasAccess('CanAssignSso')) {
        this.loadProfileDetails(results);
      }
      return {
        results,
        page: result.data.page,
      };
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }
}
