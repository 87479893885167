import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import { Permission } from '@/const/permission.enum';
import { MissedSavingsConfiguration } from './missed-savings-configuration.model';

export class MissedSavingsConfigurationApiClass {
  public createConfiguration(requestMessage: MissedSavingsConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/missed-savings-configuration',
      method: RequestMethod.POST,
      permission: Permission.CanEditMissedSavings,
    }, {}, requestMessage );
  }

  public getConfiguration(configurationId: string): Promise<AxiosResponse<MissedSavingsConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/missed-savings-configuration/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateConfiguration(message: MissedSavingsConfiguration): Promise<AxiosResponse<MissedSavingsConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/missed-savings-configuration/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, {
      name: message.name,
      missedSavings: message.missedSavings,
    });
  }

}

export const MissedSavingsApi: MissedSavingsConfigurationApiClass = new MissedSavingsConfigurationApiClass();