
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

const stateClasses = {
  idle: {
    postpone: 600,
    event: 'animation-finished',
    classes: [],
  },
  starting: {
    duration: 500,
    next: 'loading',
    classes: [
      'to-rounded',
    ],
  },
  loading: {
    cycle: [
      {
        classes: [],
        duration: 400,
      },
      {
        classes: [
          'rounded-first'
        ],
        duration: 600,
      },
      {
        classes: [
          'rounded-first',
          'rounded-second',
        ],
        duration: 500,
      },
      {
        classes: [
          'rounded-first',
          'rounded-second',
          'rounded-third',
        ],
        duration: 400,
      },
      {
        classes: [
          'rounded-first',
          'rounded-second',
          'rounded-third',
          'rounded-fourth'
        ],
        duration: 300,
      },
      {
        classes: [
          'rounded-second',
          'rounded-third',
          'rounded-fourth'
        ],
        duration: 400,
      },
      {
        classes: [
          'rounded-third',
          'rounded-fourth'
        ],
        duration: 200,
      },
      {
        classes: [
          'rounded-fourth'
        ],
        duration: 200,
      },
    ],
    classes: [
      'to-rounded',
      'loader-rounded',
    ],
  },
  hiding: {
    duration: 100,
    next: 'stillHiding',
    classes: [
      'to-rounded',
      'to-button',
    ],
  },
  stillHiding: {
    duration: 300,
    next: 'idle',
    classes: [
      'to-button',
    ],
  },
  success: {
    duration: 5000,
    next: (comp) => {
      if (comp.hideSuccess) {
        return 'invisible';
      }
      return 'hiding';
    },
    classes: [
      'to-rounded',
      'success',
    ],
  },
  failure: {
    duration: 5000,
    next: 'hiding',
    classes: [
      'to-rounded',
      'failure',
    ],
  },
  invisible: {
    postpone: 600,
    event: 'animation-finished',
    classes: [
      'to-rounded',
      'to-invisible',
    ],
  },
};

const stateNames = [
  'idle',
  'starting',
  'loading',
  'hiding',
  'stillHiding',
  'success',
  'failure',
  'invisible',
];

const loaderClasses = {
  idle: {
    event: 'animation-finished',
    classes: [],
  },
  starting: {
    duration: 500,
    next: 'loading',
    classes: [],
  },
  loading: {
    cycle: [
      {
        classes: [],
        duration: 400,
      },
      {
        classes: [
          'rounded-first'
        ],
        duration: 600,
      },
      {
        classes: [
          'rounded-first',
          'rounded-second',
        ],
        duration: 500,
      },
      {
        classes: [
          'rounded-first',
          'rounded-second',
          'rounded-third',
        ],
        duration: 400,
      },
      {
        classes: [
          'rounded-first',
          'rounded-second',
          'rounded-third',
          'rounded-fourth'
        ],
        duration: 300,
      },
      {
        classes: [
          'rounded-second',
          'rounded-third',
          'rounded-fourth'
        ],
        duration: 400,
      },
      {
        classes: [
          'rounded-third',
          'rounded-fourth'
        ],
        duration: 200,
      },
      {
        classes: [
          'rounded-fourth'
        ],
        duration: 200,
      },
    ],
    classes: [
      'loader-rounded',
    ],
  },
  hiding: {
    duration: 100,
    next: 'stillHiding',
    classes: [
      'loading-finishing'
    ],
  },
  stillHiding: {
    duration: 300,
    next: 'idle',
    classes: [
      'to-invisible',
    ],
  }
};

const loaderStateNames = [
  'idle',
  'starting',
  'loading',
  'hiding',
  'stillHiding'
];

@Component({})
export default class UiButton extends Vue {
  @Prop({ default: 'default' }) sizeName!: string;
  @Prop({ default: 'primary' }) typeName!: string;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: null }) success!: boolean | null;
  @Prop({ default: false }) hideSuccess!: boolean;

  state: number = 0;
  timestamp: number = 0;
  cycleCounter: number = 0;
  animating: boolean = false;

  get addonClasses() {
    if (!stateClasses[stateNames[this.state]].cycle) {
      return [];
    }
    return stateClasses[stateNames[this.state]].cycle[this.cycleCounter].classes;
  }

  get stateClasses() {
    return stateClasses[stateNames[this.state]].classes;
  }

  get loaderStateClasses() {
    return loaderClasses[loaderStateNames[this.state]].classes;
  }

  get loaderAddonClasses() {
    if (!loaderClasses[loaderStateNames[this.state]].cycle) {
      return [];
    }
    return loaderClasses[loaderStateNames[this.state]].cycle[this.cycleCounter].classes;
  }

  get classes() {
    return [
      'btn-' + this.sizeName + '-' + this.typeName,
      ...this.stateClasses,
      ...this.addonClasses,
    ].join(' ');
  }

  get buttonClasses() {
    return [
      'btn-' + this.sizeName + '-' + this.typeName,
      this.loading ? 'pending-status' : 'loaded-status',
      this.sizeName === 'rounded' ? 'dont-shrink' : '',
    ].join(' ').trim();
  }

  get loaderClasses() {
    return [
      ...this.loaderStateClasses,
      ...this.loaderAddonClasses
    ].join(' ');
  }

  @Watch('loading', { immediate: true })
  onLoadingChange(value) {
    if (value) {
      this.startLoading();
    } else {
      this.stopLoading();
    }
  }

  startLoading() {
    if (-1 !== [1, 2].indexOf(this.state)) {
      return;
    }
    this.timestamp = new Date().getTime();
    this.state = 1;
    this.startInterval();
  }
  stopLoading() {
    if (-1 === [1, 2].indexOf(this.state)) {
      return;
    }
    this.timestamp = new Date().getTime();

    if (null !== this.success) {
      this.state = stateNames.indexOf(this.success ? 'success' : 'failure');
    } else {
      this.state = loaderStateNames.indexOf('hiding');
    }
    this.startInterval();
  }
  startInterval() {
    if (!this.animating) {
      this.animating = true;
      this.interval();
    }
  }
  interval() {
    const stateObject = loaderClasses[loaderStateNames[this.state]];
    const currentTime = new Date().getTime();
    const elapsed = currentTime - this.timestamp;

    if (stateObject.duration) {
      if (stateObject.duration < elapsed) {
        this.timestamp = currentTime - (elapsed - stateObject.duration);
        this.cycleCounter = 0;
        let next = stateObject.next;
        if (typeof next !== 'string') {
          next = next(this);
        }
        this.state = loaderStateNames.indexOf(next);
      }
    } else if (stateObject.cycle) {
      const cycle = stateObject.cycle[this.cycleCounter];
      if (cycle.duration < elapsed) {
        this.timestamp = currentTime - (elapsed - cycle.duration);
        this.cycleCounter = (this.cycleCounter + 1) % stateObject.cycle.length;
      }
    } else if (stateObject.postpone) {
      if (stateObject.postpone < elapsed) {
        this.$emit(stateObject.event);
        this.animating = false;
        return;
      }
    } else  {
      this.animating = false;
      return;
    }
    setTimeout(() => {
      this.interval();
    }, 30);
  }
}

