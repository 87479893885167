import Home from '@/modules/home/Home.vue';
import SearchHome from '@/modules/search/SearchHome.vue';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/',
  name: 'home',
  components: {
    default: Home,
    intro: SearchHome,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-choose-travellers',
    transitions: {
      intro: {
        search: AnimationName.RIGHT,
        air: AnimationName.RIGHT,
        airDetails: AnimationName.RIGHT,
      },
      background: {
        hotelMap: AnimationName.RIGHT,
      }
    }
  }
};
