


















































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import { router } from '@/router';
import LodgeCardsStore from './lodge-cards.store';
import DictionaryStore from '@/store/dictionary.store';
import { PaymentCardType, PaymentCardMainType } from '@/api/profile/payment-cards.model';
import { PaymentCardTypeList } from '@/const/paymentcard-type-list.const';
import { CompanyPaymentCardProvider } from '@/api/lodge-cards/lodge-cards.model';
import settings from '@/settings';
import { getSecureFields } from '@/plugins/secure-fields';
import { translate } from '@/i18n';
import _ from 'lodash';
import { DEFAULT_DURATION } from '@/modules/layout/layout.const';
import { isNameValid } from '@/core/validation/is-name-valid.validator';

const expireDateRegex = /^(0[1-9]|10|11|12)\/\d{2}$/;

const phoneRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^\+?(?:\d●?)*\d$/.test(value);
};

const CreditCardImg = {
  VIS: 'visa',
  ECA: 'maste_card',
  DIN: 'diners_club_card',
  DIS: 'discover_card',
  AMX: 'american_express',
  UAP: 'UATP',
  JCB: 'not_found',
  CUP: 'not_found'
};

@Component({})
export default class LodgeCardForm extends Vue {
  $v;
  loading: boolean = false;
  formPending: boolean = false;
  selectedCard: string | null = null;
  calculatedCardType: PaymentCardMainType | null = null;
  paymentMethod: string | null = null;
  secureFields: any | null = null;
  secureFieldsError: string | null = null;
  cardNumberValid: boolean = true;
  cardNumberLength: number = 0;
  Form: any = {
    id: '',
    displayName: '',
    transactionId: '',
    maskedNumber: '',
    expireDate: '',
    holderFirstName: '',
    holderLastName: '',
    type: null,
    provider: CompanyPaymentCardProvider.None,
    email: '',
    phoneNumber: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    cityName: '',
    stateRegion: '',
    postalCode: '',
    countryCode: '',
    cardToken: '',
  };
  imagesConst: string = '/assets/img/loader/1.gif';
  tempIdPrefix: string = 'paymentCard_';
  errors: any[] = [];
  cardProviderOptions: any[] = [
    CompanyPaymentCardProvider.None,
    CompanyPaymentCardProvider.AirPlus,
    CompanyPaymentCardProvider.Bta,
  ];

  isNotWhiteSpaced(model: string) {
    return model && model.length ? !!(model.trim()) : true;
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        transactionId: {},
        expireDate: {
          required,
          regex(expireDate) {
            return (
              expireDateRegex.test(expireDate) &&
              expireDate.length === 5
            );
          },
          checkDate(expireDate) {
            let month = new Date().getMonth() + '';
            let year = new Date().getFullYear() + '';
            let stringYear = year.substring(2, 4);
            let checkYear = true;
            let checkMonthAndYear = true;
            let checkMonth = true;
            if (expireDate.length === 5) {
              let splitValue = expireDate.split('/');
              checkMonthAndYear = parseFloat(splitValue[1]) === parseFloat(stringYear)
                                  && parseFloat(splitValue[0]) <= parseFloat(month);
              checkYear = parseFloat(splitValue[1]) < parseFloat(stringYear) ||
                          parseFloat(splitValue[1]) > (parseFloat(stringYear) + 50);
            }
            return !checkMonthAndYear && !checkYear;
          }
        },
        type: {
          required,
        },
        holderFirstName: {
          required,
          maxLength: maxLength(128),
          isNameValid,
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        holderLastName: {
          required,
          maxLength: maxLength(128),
          isNameValid,
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        displayName: {
          maxLength: maxLength(50),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        companyName: {
          maxLength: maxLength(64),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        addressLine1: {
          required,
          maxLength: maxLength(256),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        addressLine2: {
          maxLength: maxLength(256),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        cityName: {
          required,
          maxLength: maxLength(128),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        stateRegion: {
          maxLength: maxLength(128),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        postalCode: {
          required,
          maxLength: maxLength(16),
          whitespaced: (model) => this.isNotWhiteSpaced(model),
        },
        country: {
          required,
        }
      }
    };
  }

  get availableTypes() {
    let listToReturn = PaymentCardTypeList;

    if (this.isCardNew && (this.cardNumberLength === 0 || !this.paymentMethod)) {
      this.Form.type = null;
    }

    if (this.calculatedCardType === PaymentCardMainType.Visa) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.VisaCredit
                || type.value === PaymentCardType.VisaDebit
                || type.value === PaymentCardType.VisaElectron;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.MasterCard) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.MasterCardCredit
                || type.value === PaymentCardType.MasterCardDebit;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.Discover) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.Discover;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.DinersClub) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.DinersClub;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.Maestro) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.Maestro;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.AmericanExpress) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.AmericanExpress;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.UATP) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.UATP;
      });
    } else {
      if (this.Form && this.isCardNew && this.paymentMethod) {
        this.Form.type = null;

        if (this.paymentMethod === 'VIS') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.VisaCredit
                || type.value === PaymentCardType.VisaDebit
                || type.value === PaymentCardType.VisaElectron;
          });
        } else if (this.paymentMethod === 'ECA') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.MasterCardCredit
                || type.value === PaymentCardType.MasterCardDebit;
          });
        } else if (this.paymentMethod === 'AMX') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.AmericanExpress;
          });
        } else if (this.paymentMethod === 'DIN') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.Discover;
          });
        } else if (this.paymentMethod === 'DIS') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.DinersClub;
          });
        } else if (this.paymentMethod === 'JCB') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.JapanCreditBureau;
          });
        } else if (this.paymentMethod === 'CUP') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.ChinaUnionPay;
          });
        } else if (this.paymentMethod === 'UAP') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.UATP;
          });
        }

      }
      this.calculatedCardType = null;
      // doeas not filter credit card types when not known return [];
    }

    if (this.Form && this.isCardNew && listToReturn.length === 1) {
        this.Form.type = listToReturn[0];
      }

    return listToReturn;
  }

  get inEditMode() {
    return !!this.lodgeCard.id;
  }

  get readOnly() {
    return !!(this.$route.query.readOnly);
  }

  get errMessages() {
    return LodgeCardsStore.errMessages;
  }

  get showErrMessages() {
    return LodgeCardsStore.showError;
  }

  get lodgeCard() {
    return LodgeCardsStore.selectedCard;
  }

  get configuration() {
    return LodgeCardsStore.lodgeCardConfiguration;
  }

  get configurationId() {
    return router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new';
  }

  get pciProxyMockEnabled() {
    return settings.pciProxy_MockEnabled === 'true';
  }
  
  get pciProxyMerchantId() {
    return settings.pciProxy_MerchantId;
  }

  get displayImg() {
    if (this.paymentMethod) {
      return `/assets/img/credit-cards/${CreditCardImg[this.paymentMethod]}.png`;
    }
  }

  get expirationDateFormat() {
    if (this.Form) {
      return this.Form.expireDate;
    }
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get isCardNew() {
    return !this.lodgeCard.id || this.lodgeCard.id.includes(this.tempIdPrefix);
  }

  set expirationDateFormat(value) {
    if (value) {
      if (value.includes('/')) {
        this.Form!.expireDate = value;
        return;
      }
      if (value.slice(1, 2) === '/' && value.length === 2) {
        value = this.setCharAt(value, 0 , '0' + value.substr(0, 1)) + '';
      }
      if (value.slice(2, 3) !== '/') {
        value = this.setCharAt(value, 2 , '/') + '';
      }
      this.Form!.expireDate = value;
    }
  }

  setCharAt(str, index, char) {
    if (index > str.length)  {
      return str;
    }
    return str.substr(0, index) + char + str.substr( index + 1);
  }

  onCardMainTypeChange(newCartType) {
    this.calculatedCardType = newCartType;
    if (this.isCardNew && this.Form) {
      this.Form.type = this.availableTypes[0];
    }
  }
  
  initSecureFields() {
    const self = this;
    self.secureFieldsError = null;
    if (this.isCardNew && !this.pciProxyMockEnabled) {
      this.secureFields = getSecureFields();

      let styles = {
        '*': 'border-radius: 4px; border: 1px solid #999999;min-height: 35px; text-transform: inherit; margin:2px; max-width: calc(100% - 5px); height: 35px;color: #4D4D4D;padding-left: 25px; font-family: Orkney, "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, sans-serif; font-size: 14px; outline: 0;',
        '*:focus': 'box-shadow:  0 0 0 .13rem rgba(51, 82, 111, .25);',
        '*::placeholder': 'color: #D8D8D8',
        '*:-ms-input-placeholder': 'color: #D8D8D8',
        '@font-face': {
          '*': {
              fontFamily: 'Orkney',
              fontStyle: 'normal',
              fontWeight: 400,
              src: 'url(\'Orkney Regular.woff2\') format(\'woff2\'), url(\'Orkney Regular.eot\') format(\'embedded-opentype\'), url(\'Orkney Regular.woff\') format(\'woff2\'),url(\'Orkney Regular.otf\') format(\'opentype\'),url(\'Orkney Regular.ttf\') format(\'truetype\')',
          }        
        }
      };

      this.secureFields.on('validate', (data) => {
        if (data.fields.cardNumber) {
          self.cardNumberValid = data.fields.cardNumber.valid;
          self.cardNumberLength = data.fields.cardNumber.length;

          if (!this.cardNumberValid) {
            this.$nextTick(() => {
              (this.$refs.cardField as HTMLElement).scrollIntoView({
                behavior: 'smooth'
              });
            });
          }
        }
      });

      this.secureFields.on('change', (data) => {
        self.cardNumberLength = data.fields.cardNumber.length;
        if (data.fields.cardNumber.paymentMethod) {
          this.paymentMethod = data.fields.cardNumber.paymentMethod;
        } else {
          this.paymentMethod = null;
        }
      });

      this.secureFields.on('success', (data) => {
        if (data.transactionId) {
          self.Form.transactionId = data.transactionId;
          self.submit();
        }
      });

      this.secureFields.on('error', (data) => {
        if (data) {
          self.secureFieldsError = data.error;
        }
      });

      this.secureFields.initTokenize(this.pciProxyMerchantId, {
        cardNumber: {
          placeholderElementId: 'card-number-placeholder',
          inputType: 'tel',
          placeholder: translate('profile-payment.your-card-number'),
        }
      }, 
      {            
        styles: styles,
        focus: 'cardNumber',
      });
    }
  }

  goToConfiguration() {
    this.$router.push({
      name: 'lodge-card-configuration',
      params: {
        id: this.$route.params.id,
        configurationId: this.$route.params.configurationId
      }
    });
  }

  resetStoreErrors() {
    LodgeCardsStore.setErrMessages([]);
    LodgeCardsStore.setShowError(false);
  }

  focusOnSave() {
    const vueEl = this.$refs['confirmButton'] as Vue;
    if (!vueEl) {
      return;
    }
    const button = vueEl.$el as HTMLInputElement;
    setTimeout(() => {
      button.focus();
    }, 100);
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        id: data.id || _.uniqueId(this.tempIdPrefix),
        displayName: data.displayName || '',
        transactionId: data.transactionId ? data.transactionId : data.maskedCardNumber,
        maskedCardNumber: data.maskedCardNumber,
        expireDate: data.expireDate,
        holderFirstName: data.holderFirstName,
        holderLastName: data.holderLastName,
        type: this.availableTypes.find(card => {
          return card.value === data.type;
        }),
        provider: this.cardProviderOptions.find(card => {
          return card === data.provider;
        }) ? this.cardProviderOptions.find(card => {
          return card === data.provider;
        }) : CompanyPaymentCardProvider.None,
        email: data.email,
        phoneNumber: data.phoneNumber,
        companyName: data.companyName,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        cityName: data.cityName,
        stateRegion: data.stateRegion,
        postalCode: data.postalCode,
        countryCode: data.countryCode,
        cardToken: data.cardToken || '',
        country: this.allCountries!.find(ctr => {
          return ctr.code === data.countryCode;
        }),
        status: data.status
      };
    } else {
      this.Form = {
        id: _.uniqueId(this.tempIdPrefix),
        displayName: '',
        transactionId: '',
        maskedCardNumber: '',
        expireDate: '',
        holderFirstName: '',
        holderLastName: '',
        type: null,
        provider: CompanyPaymentCardProvider.None,
        email: '',
        phoneNumber: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '',
        cityName: '',
        stateRegion: '',
        postalCode: '',
        countryCode: '',
        cardToken: '',
      };
    }
  }

  async submitForm() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }
    if (this.isCardNew && !this.pciProxyMockEnabled) {
      this.cardNumberValid = true;
      this.secureFieldsError = null;
      this.submitSecureFields();
    } else {
      this.submit();
    }
  }

  async submitSecureFields() {
    await this.secureFields.submit();
  }

  async submit() {
    this.$v.Form.$touch();
    if (this.$v.Form.$invalid) {
      return;
    }
    let type = this.Form.type ? this.Form.type.value : null;
    if (this.calculatedCardType && this.calculatedCardType === PaymentCardMainType.Unknown) {
      type = PaymentCardType.Unknown;
    }
    let card = {
      ...this.Form,
      type,
      countryCode: this.Form.country.code,
      status: 'draft',
    };
    if (this.inEditMode) {
      LodgeCardsStore.setIsFromManage(true);
      this.resetStoreErrors();
      LodgeCardsStore.editLodgeCard(card);
      this.goToConfiguration();
    } else {
      LodgeCardsStore.setIsFromManage(true);
      this.resetStoreErrors();
      LodgeCardsStore.addLodgeCard(card);
      this.goToConfiguration();
    }
  }

  async created() {
    if (this.configurationId !== 'new' && !this.configuration.configurationName) {
      await this.goToConfiguration();
    }
    await setTimeout(() => {
      this.initSecureFields();
    }, DEFAULT_DURATION);
    this.mapFormData(this.lodgeCard);
  }
}
