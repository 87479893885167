














































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import accountStore from '@/store/account.store';
import { ApprovalTaskModel, MissionState, ApprovalTasksStage } from '@/api/expense/expense.model';
import { ExpenseApi } from '@/api/expense/expense.api';
import { translate } from '@/i18n';
import ExpenseStore from '../expense.store';
import { CityCountryModel } from '@/api/dictionary/dictionary.model';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileModel } from '@/api/profile/profile.model';
import { Permission } from '@/const/permission.enum';
import TripsIncludedMissionTable from '@/modules/expense/my-missions/TripsIncludedMissionTable.vue';
import ReportedExpenseTable from '@/modules/expense/my-missions/ReportedExpenseTable.vue';

const validationResultIcon = {
  Risk: 'notification_important',
  Violation: 'block',
  Warning: 'warning',
  Information: 'info',
};

@Component({
  components: {
    TripsIncludedMissionTable,
    ReportedExpenseTable,
  },
})
export default class ApprovalTask extends Vue {
  ApprovalTask: ApprovalTaskModel = new ApprovalTaskModel({});

  activeTab: string = 'mission-data';
  missionTabs = [
    {
      label: translate('add-mission.mission-data'),
      value: 'mission-data',
    },
    {
      label: translate('expense-add.comments'),
      value: 'comments',
    },
    {
      label: translate('expense-add.attachments'),
      value: 'attachments',
    },
    {
      label: translate('expense-add.history'),
      value: 'history',
    }
  ];
  activeRelatedTab: string = 'trips';
  relatedTabs = [
    {
      label: translate('add-mission.trips'),
      value: 'trips',
    },
    {
      label: translate('add-mission.reported-expenses'),
      value: 'reported-expenses',
    },
  ];
  $v;
  formPending: boolean = false;
  serverErrors: any[] = [];
  editMode: boolean = false;
  approvalTaskId: string | null = null;
  addExpenseMode: boolean = false;
  submitter: ProfileModel | null = null;
  locationOptions: CityCountryModel[] = [];
  loading: boolean = false;

  get tabNames() {
    return this.missionTabs;
  }

  get bottomTabs() {
    return this.relatedTabs;
  }

  get currentProfileId() {
    return ExpenseStore.currentProfileId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get currencyOptions() {
    return ExpenseStore.currencyOptions;
  }

  get projectList() {
    return ExpenseStore.projectList;
  }

  get chackEndDate() {
    if (this.ApprovalTask!.missionStart) {
      return new Date(this.ApprovalTask!.missionStart);
    }
  }

  get chackStartDate() {
    if (this.ApprovalTask!.missionEnd) {
      return new Date(this.ApprovalTask!.missionEnd);
    }
  }

  get companyId() {
    return accountStore.CurrentUser!.profile.companyId;
  }

  get showConfigurationError() {
    return ExpenseStore.showConfigurationError;
  }

  get displayLocationOptions() {
    return this.locationOptions.map(location => ({ ...location, uniqId: location.cityId + location.countryCode }));
  }

  get validationResultsList() {
    return ExpenseStore.validationResults;
  }



  userFullName(user) {
    return userFullName(user);
  }

  back() {
    router.push({
      name: 'approval-tasks'
    });
  }

  stateClasses(state) {
    return {
      'counter--empty': MissionState.Draft === state,
      'counter--yellow': MissionState.MissionRequestSubmitted === state,
      'counter--orange': MissionState.MissionRequestInApproval === state,
      'counter--strong-grey': MissionState.MissionCancelled === state || MissionState.ExpenseReportCancelled === state,
      'counter--light-green': MissionState.MissionApproved === state,
      'counter--red-grey': MissionState.AmendmentNeeded === state || MissionState.ReportAmendmentNeeded === state,
      'counter--strong-red': MissionState.MissionRequestRejected === state || MissionState.ExpenseReportRejected === state,
      'counter--green': MissionState.ExpenseReportApproved === state,
      'counter--orange-green': MissionState.ExpenseReportInApproval === state,
      'counter--yellow-green': MissionState.ExpenseReportSubmitted === state,
    };
  }

  stageClasses(state) {
    return {
      'counter--green': ApprovalTasksStage.MissionPreapproval === state,
      'counter--red': ApprovalTasksStage.SingleExpenseValidation === state,
      'counter--purple': ApprovalTasksStage.ExpenseReportValidation === state,
    };
  }

  validationResultClasses(validationResult) {
    return {
      'error': validationResult.severity === 'Violation',
      'warning': validationResult.severity === 'Warning',
      'info-level': validationResult.severity === 'Information',
      'risk': validationResult.severity === 'Risk',
    };
  }

  validationResultIcons(validationResult) {
    return validationResultIcon[validationResult.severity];
  }

  async getUser(id) {
    let user = await ProfileApi.getById(id, Permission.ReadProfilePersonal);
    if (user && user.data) {
      return user.data;
    }
    return null;
  }

  async initView() {
    this.loading = true;
    await ExpenseStore.getDefaultExpensePolicy(Permission.ValidateMission);
    await ExpenseStore.getCurrencies();
    this.approvalTaskId = this.$route.params.id;

    if (this.approvalTaskId) {
      this.editMode = true;
      const response = await ExpenseApi.getMissionItem(this.approvalTaskId);
      this.ApprovalTask = new ApprovalTaskModel(response.data);

      if (this.ApprovalTask.state !== MissionState.Draft && this.ApprovalTask.state !== MissionState.MissionCancelled) {
        const params = {
          missionId: this.approvalTaskId,
          expenseId: undefined,
        };
        await ExpenseStore.getValidationsResults(params);
      }
    }

    if (this.ApprovalTask && this.ApprovalTask.reporterId !== '') {
      this.submitter = await this.getUser(this.ApprovalTask.reporterId);
    }

    if (this.ApprovalTask.destinations.length === 0) {
      let startDate = null;

      if (this.ApprovalTask.missionStart) {
        startDate = this.ApprovalTask.missionStart;
      }

      this.ApprovalTask.destinations.push(
        {
          location: null,
          startDate: startDate,
          endDate: null,
        });
    }

    if (this.ApprovalTask.destinations && this.ApprovalTask.destinations.length) {
      let cities: string[] = [];
      for (let i = 0; i < this.ApprovalTask.destinations.length; i++) {
        this.ApprovalTask.destinations[i].startDate = this.ApprovalTask.destinations[i].startDate ? new Date(this.ApprovalTask.destinations[i].startDate) : null;
        this.ApprovalTask.destinations[i].endDate = this.ApprovalTask.destinations[i].endDate ? new Date(this.ApprovalTask.destinations[i].endDate) : null;
      }
    }

    this.loading = false;
  }

  async decisionManualApprovalTasks(value) {
    this.approvalTaskId = this.$route.params.taskId;
    this.formPending = true;

    if (this.approvalTaskId) {
      try {
        const response = await ExpenseApi.updateManualApprovalTasks(this.approvalTaskId, { decision: value });
        if (response) {
          router.push({
            name: 'approval-tasks'
          });
        }
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }  finally {
        this.formPending = false;
      }
    }
  }

  @Watch('$route.params.id', { immediate: true })
  routeHandler() {
    this.initView();
  }
}
