




























































import { Vue, Component, Watch } from 'vue-property-decorator';
import { VclCode } from 'vue-content-loading';

import SearchStore from '@/modules/search/search.store';
import HotelSearchStore from './hotel-search.store';
import EventBus from '@/services/event-handler';
import { serviceClassEnum } from '@/api/home/home.model';
import HotelResultsRow from './HotelResultsRow.vue';
import EditHotelFeePopup from './EditHotelFeePopup.vue';
import EditHotelAgencyMarkupPopup from './EditHotelAgencyMarkupPopup.vue';

@Component({
  components: {
    VclCode,
    HotelResultsRow,
    EditHotelFeePopup,
    EditHotelAgencyMarkupPopup,
  }
})
export default class HotelResults extends Vue {

  selectedOffer: any = null;
  isNotMapUpdating: boolean = true;

  get filters() {
    return HotelSearchStore.filters;
  }

  get offers() {
    return HotelSearchStore.offers;
  }

  get loading() {
    return HotelSearchStore.loading;
  }

  get isFreezed() {
    return HotelSearchStore.searchFreezed;
  }

  get filtersChanging() {
    return HotelSearchStore.filtersChanging;
  }

  get offersVisible() {
    return HotelSearchStore.offersVisible;
  }

  get placeholderVisible() {
    return (
      (this.loading && (!this.offers || this.offers.length === 0)) ||
      this.isFreezed || this.filtersChanging
    ) && !this.isMapViewMode;
  }

  get renderShowMoreOffers() {
    return (this.offers && this.offers.length > this.offersVisible) && !HotelSearchStore.isMapView;
  }

  get isMapViewMode() {
    return HotelSearchStore.isMapView;
  }

  get disableOfferSelection() {
    return HotelSearchStore.selectingOffer;
  }

  get showError() {
    return HotelSearchStore.showError;
  }

  get serverErrors() {
    return HotelSearchStore.serverErrors;
  }

  scrollToError() {
    this.$nextTick(() => {
      if (!this.$refs.uiError) {
        return;
      }
      ((this.$refs.uiError as Vue).$el as HTMLElement).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  @Watch('serverErrors', {deep: true})
  onErrorShow() {
    this.scrollToError();
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    const searchId = this.$route.params.searchId;

    this.selectedOffer = null;
    if (searchId && ('-' !== searchId)) {
      HotelSearchStore.setFiltersChanging(false);
      HotelSearchStore.setSearchCompleted(false);
      HotelSearchStore.getSearchSession(searchId);
      HotelSearchStore.getOffers(searchId);
      SearchStore.selectNavigated(serviceClassEnum.Hotel);
      SearchStore.select(serviceClassEnum.Hotel);
    }
  }

  showMoreOffer() {
    let curr = this.offersVisible;
    curr += 10;
    HotelSearchStore.setOffersVisible(curr);
  }

  switchMapViewMode(value: boolean) {
    HotelSearchStore.setMapViewMode(value);
  }


  reloadMap() {
    this.isNotMapUpdating = false;
    this.$nextTick(() => {
      this.isNotMapUpdating = true;
    });
  }

  mounted() {
    EventBus.$on('hotel-filters-reloaded', this.reloadMap);
  }

  beforeDestroy() {
    EventBus.$off('hotel-filters-reloaded', this.reloadMap);

    if ('hotelMap' !== this.$route.name) {
      HotelSearchStore.stopSearch();
    }
  }
}
