





































































































import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { Table3Params } from './table3.params';
import { Table3DataRow } from './table3';
import { Table3ItemsService } from './table3-items.service';

@Component({})
export default class Table3 extends BaseTable<Table3DataRow> {
  itemsService: Table3ItemsService = new Table3ItemsService();
  useQueryParams: boolean = true;
  defaultSort: string = 'name';
  defaultDesc: boolean = false;
  params: Table3Params = new Table3Params({
    sort: 'name',
    desc: false,
    name: '',
    email: '',
  });
  fields = {
    status: {
      label: '',
      sortable: false,
      class: 'status-column',
    },
    name: {
      sortable: true,
      class: 'name-column',
    },
    email: {
      sortable: true,
      class: 'email-column',
    },
    details: {
      sortable: false,
      class: 'details-column',
    }
  };

  async fillParameters(query) {
    this.params.name = query.name || '';
    this.params.email = query.email || '';
  }

  fillQuery(query) {
    query.name = this.params.name;
    query.email = this.params.email;
  }

  async getItems(params: Table3Params): Promise<any> {
    const result = await this.itemsService.get(params);
    return result;
  }
}
