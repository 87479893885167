
































































import settings from '@/settings';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import HotelSearchStore from '@/modules/search/hotel/hotel-search.store.ts';
import SearchStore from '@/modules/search/search.store.ts';
import LayoutStore from '@/modules/layout/layout.store';
import L, { Map, Marker, TileLayer, LatLng, LayerGroup, Popup, PopupOptions } from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { Currency } from '@/api/trip/basket.model';
import { AccommodationSearchApi } from '@/api/accommodation-engine/accommodation-search.api';
import { MapPropertiesResponse, SearchState } from '@/api/accommodation-engine/accommodation-search.model';
import { HotelSearchStateParams } from './hotel-search.params';
import BasketStore from '@/modules/basket/basket.store';
import { TravellersStateParams } from '../travellers.params';
import EventBus from '@/services/event-handler';
import { DEFAULT_DURATION } from '@/modules/layout/layout.const';

import 'leaflet.markercluster.freezable';


@Component({})
export default class HotelMap extends Vue {
  attribution: string = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors';
  defaultZoom: number = 15;
  center: LatLng = new LatLng(this.lastKnownCoords.latLng.lat, this.lastKnownCoords.latLng.lon);
  map?: Map;
  tileLayer?: TileLayer;
  markers: Marker[] = [];
  markerWrappers: any[] = [];
  markerLayer: LayerGroup = new LayerGroup();
  specialMarkerLayer: LayerGroup = new LayerGroup();
  destinationLocation: any = null;
  popups: Popup[] = [];
  errors: any[] = [];
  markerCluster: any = new MarkerClusterGroup({
    disableClusteringAtZoom: 17,
    averageCenter: true,
  });
  specialMarkerCluster: any = new MarkerClusterGroup({
    disableClusteringAtZoom: 17,
    averageCenter: true,
  });
  switchToggled: boolean = true;
  imagesConst = '/assets/img/loader/1.gif';
  beenZoomed: boolean = false;
  shouldBlockRedirect: boolean = false;

  get properties() {
    return HotelSearchStore.mapProperties;
  }

  get propertiesLoading() {
    return HotelSearchStore.mapPropertiesLoading;
  }

  get lastKnownCoords() {
    return HotelSearchStore.lastKnownCoords;
  }

  get isDefaultCoords() {
    return (this.lastKnownCoords.latLng.lat === 0 &&
      this.lastKnownCoords.latLng.lon === 0 &&
      this.lastKnownCoords.zoom === 15);
  }

  get sidebarToggled() {
    return !LayoutStore.sidebarHidden;
  }

  get showError() {
    return HotelSearchStore.showError;
  }

  get serverErrors() {
    return HotelSearchStore.serverErrors;
  }

  get loading() {
    return HotelSearchStore.loading;
  }

  get totalOffers() {
    return HotelSearchStore.totalOffers;
  }

  get recommendationsHotelCount() {
    return HotelSearchStore.recommendationsHotelCount;
  }

  get stateId() {
    return HotelSearchStore.stateId;
  }

  initMap(divId: string = 'hotel-map') {
    let self = this;
    if (!this.map) {
      this.map = L.map(divId).on('moveend', this.onCenterUpdate).setView({
        lat: this.lastKnownCoords.latLng.lat,
        lng: this.lastKnownCoords.latLng.lon,
      }, this.lastKnownCoords.zoom)
      .on('popupopen', (e: any) => {
        let content = e.popup._container;
        content.addEventListener('click', () => this.handlePopupClick(e.popup.options.relatedPropertyId));
      })
      .on('zoom', (e: any) => {
        self.beenZoomed = true;
      });
    }
  }

  initTiles() {
    this.tileLayer = L.tileLayer(settings.hotelMapTileServerUrl, {
      attribution: settings.hotelMapAttribution,
      tileSize: 512,
      zoomOffset: -1
    }).addTo(this.map as Map);
  }

  centerMap() {
    let markerGroup = L.featureGroup(this.markers);
    if (this.map) {
      setTimeout(() => {
        this.map!.fitBounds(markerGroup.getBounds());
      }, DEFAULT_DURATION);
      return;
    } else {
      this.initMap();
      this.centerMap();
      return;
    }
  }

  generateHotelMarker(price: number, currency: Currency, compliance: string) {
    return L.divIcon({
      className: 'hotel-map__marker',
      html: `<div class="hotel-map__price-tag">
              ${ compliance !== 'Empty' ? 
                  compliance === 'Compliant' ?
                    `<em class="notranslate material-icons compliant">done</em>` :
                    `<em class="notranslate material-icons non-compliant">priority_high</em>` :
                  `` } 
              <span>${currency.symbol ? currency.symbol : currency.code} ${price}</span>
            </div>
            <div class="hotel-map__marker-tip-container">
              <div class="hotel-map__marker-tip"></div>
            </div>`
    });
  }

  generateCenterMarker(text: string) {
    return L.divIcon({
      className: 'hotel-map__marker',
      html: `<div class="hotel-map__price-tag"> 
              <span>${text}</span>
            </div>
            <div class="hotel-map__marker-tip-container">
              <div class="hotel-map__marker-tip"></div>
            </div>`
    }); 
  }

  findSelectedOffer(id) {
    let property = this.properties.find((prop) => {
      return prop.id === id;
    });
    return property ? property : false;
  }

  clearCluster() {
    this.markerCluster.clearLayers();
    this.specialMarkerCluster.clearLayers();
  }

  clearMarkers() {
    this.markers = [];
    this.markerWrappers = [];
    this.markerLayer.clearLayers();
    this.specialMarkerLayer.clearLayers();
  }

  createCenterMarker() {
    if (this.destinationLocation) {
      let marker: Marker = L.marker(
        [this.destinationLocation.location.lat, this.destinationLocation.location.lon],
      );
      let popup = L.popup({
        className: 'hotel-map__center-popup'
      });
      marker.bindPopup(popup);
      marker.setPopupContent(`${ this.destinationLocation.type === 'City' ? this.destinationLocation.cityName : this.destinationLocation.displayText }`);
      marker.on('mouseover', (event) => {
        marker.openPopup();
      });
      marker.on('mouseout', (event) => {
        marker.closePopup();
      });
      this.markers.push(marker);
      marker.addTo(this.markerLayer);
      marker.addTo(this.map!);
    }
  }

  createMarkers() {
    const selectedId = this.$route.params.selectedId;
    this.createCenterMarker();
    this.properties.forEach(element => {
      let marker =
        L.marker(
          [element.lat, element.lon],
          {
            icon: this.generateHotelMarker(element.cheapestOfferPrice.amount, element.cheapestOfferPrice.currency, element.travelPolicy)
          }
        );
      let popup = new L.Popup({
        className: 'hotel-map__popup',
        relatedPropertyId: element.id,
      } as PopupOptions);
      marker.bindPopup(popup);
      marker.setPopupContent(
        `<div class="hotel-map__popup-container" style="background: url(${ element.mainImageUrl })">
          <div class="hotel-map__popup-info">
            <span class="hotel-map__popup-info-name">${ element.name }</span>
            <span class="hotel-map__popup-click-for-more">${ this.$t('search-hotel.click-for-more') }</span>
          </div>
        </div>`
      );
      marker.on('mouseover', (event) => {
        if (!marker.isPopupOpen()) {
          marker.openPopup();
        }
      });
      this.markerWrappers.push({
        id: element.id,
        marker,
      });
      this.markers.push(marker);
      this.popups.push(popup);
      if (element.id === selectedId) {
        marker.addTo(this.specialMarkerLayer);
      } else {
        marker.addTo(this.markerLayer);
      }
    });
  }

  createClusters() {
    const selectedId = this.$route.params.selectedId;

    this.markerWrappers.forEach(wrapper => {
      if (wrapper.id === selectedId) {
        this.specialMarkerCluster.addLayer(wrapper.marker);
        this.map!.addLayer(this.specialMarkerCluster);
      } else {
        this.markerCluster.addLayer(wrapper.marker);
        this.map!.addLayer(this.markerCluster);
      }
    });
  }

  onCenterUpdate(centerObject) {
    if (this.map) {
      let latLng = this.map.getCenter();
      let zoom = this.map.getZoom();
      HotelSearchStore.setLastKnownCoords({ 
        latLng: {
          lat: latLng.lat,
          lon: latLng.lng,
        }, zoom });
    } else {
      HotelSearchStore.setLastKnownCoords({
        latLng: {
          lat: centerObject.target._lastCenter.lat,
          lon: centerObject.target._lastCenter.lng,
        },
        zoom: centerObject.target._zoom,
      });
    }
  }

  handlePopupClick(id) {
    if (this.shouldBlockRedirect) {
      return;
    }
    let property = this.findSelectedOffer(id);
    if (property) {
      HotelSearchStore.setDetailedOffer(property);
      this.shouldBlockRedirect = true;
      const routeData = this.$router.resolve({
        name: 'hotelDetails',
        params: { 
          searchId: this.$route.params.searchId,
          propertyId: property.id
        }
      });
      window.open(routeData.href, '_blank');
      setTimeout(() => {
        this.shouldBlockRedirect = false;
      }, 100);
    }
  }

  gotoResults() {
    LayoutStore.slots.background.meta.height = 700;
    router.push({
      name: 'hotel',
      params: this.$route.params
    });
  }

  showMobileFilters() {
    router.push({
      name: 'hotelFilters',
      query: {
        fromMap: 'true'
      }
    });
  }

  @Watch('sidebarToggled', { immediate: true })
  onToggled(value) {
    this.switchToggled = value;
  }

  @Watch('switchToggled')
  onToggledClick(value) {
    if (value !== this.sidebarToggled) {
      LayoutStore.toggleSidebar();
    }
  }

  @Watch('properties')
  updateProperties() {
    this.clearMarkers();
    this.clearCluster();
    this.createMarkers();
    this.createClusters();
    this.selectMarkerPopup();
  }

  selectMarkerPopup() {
    const selectedId = this.$route.params.selectedId;

    if (!selectedId) {
      return;
    }

    const marker = this.markerWrappers.find(marker => marker.id === selectedId);

    if (!marker) {
      return;
    }
    this.$nextTick(() => {
      marker.marker.openPopup();
    });
  }

  async search(searchId) {
    let resp;
    try {
      resp = await AccommodationSearchApi.getSearchSession(searchId);
      const session = resp.data.requestMessage;
      SearchStore.updateHotelDefaultState(new HotelSearchStateParams({
        checkInDate: session.checkInDate,
        checkOutDate: session.checkOutDate,
        distance: session.radius,
        packageRates: session.packageRates,
        to: {
          ...session.destinationLocation,
        },
      }));
      SearchStore.updateTravellersDefaultState(new TravellersStateParams({
        travellers: session.travellers,
      }));
      if (resp.data && resp.data.metadata && resp.data.metadata.basketId) {
        await BasketStore.getBasketMetadata(resp.data.metadata.basketId);
      }
    } catch (error) {
      HotelSearchStore.setServerErrors(error);
      HotelSearchStore.setShowError(true);
      HotelSearchStore.setLoading(false);
    } finally {
      if (resp && resp.data) {
        const session = resp.data.requestMessage;
        if (session.destinationLocation) {
          this.destinationLocation = session.destinationLocation;
          HotelSearchStore.setLastKnownCoords({
            latLng: {
              lat: session.destinationLocation.location.lat,
              lon: session.destinationLocation.location.lon,
            },
            zoom: 15,
          });
        }
      }
    }

    this.initMap();
    this.initTiles();

    try {
      HotelSearchStore.setMapPropertiesLoading(true);
      const properties = await AccommodationSearchApi.getMapProperties(this.$route.params.searchId);
      if (properties && properties.data) {
        HotelSearchStore.updateMapPropertiesData(properties.data);
      }
    } catch (error) {
      HotelSearchStore.setServerErrors(error);
      HotelSearchStore.setShowError(true);
      HotelSearchStore.setLoading(false);
    } finally {
      HotelSearchStore.setMapPropertiesLoading(false);
    }

    setTimeout(() => {
      EventBus.$emit('realign-background');
      if (!this.beenZoomed) {
        this.centerMap();
      }
    }, DEFAULT_DURATION * 1);
  }

  getOffers(searchId) {
    HotelSearchStore.setShowError(false);
    HotelSearchStore.clearSearchTimeout();
    HotelSearchStore.setupSearchLoaded(searchId);
    HotelSearchStore.setLoading(true);
    HotelSearchStore.updateSearchStateId('');
    this.stepLoading(searchId);
  }

  async stepLoading(searchId) {
    if (HotelSearchStore.searchId !== searchId) {
      return;
    }

    try {
      let searchCompleted = HotelSearchStore.searchCompleted;
      const response = await AccommodationSearchApi.getSearchState(searchId, this.stateId || undefined);

      if (HotelSearchStore.searchId !== searchId) {
        return;
      }

      searchCompleted = response.data.searchState === SearchState.Completed;

      if (response.data.newResultsAvailable) {
        HotelSearchStore.updateSearchStateId(response.data.stateId);
        await this.search(searchId);

        if (HotelSearchStore.searchId !== searchId) {
          return;
        }
      }

      if (searchCompleted) {
        HotelSearchStore.finishLoading();
        HotelSearchStore.setSearchCompleted(true);
        HotelSearchStore.setLoading(false);
        return;
      }

      if (HotelSearchStore.searchId !== searchId) {
        return;
      }

      HotelSearchStore.clearSearchTimeout();
      HotelSearchStore.setSearchTimeout(() => {
        this.stepLoading(searchId);
      });
    } catch (error) {
      HotelSearchStore.setServerErrors(error);
      HotelSearchStore.setShowError(true);
      HotelSearchStore.setLoading(false);
    }
  }

  mounted() {
    let searchId = this.$route.params.searchId;
    this.getOffers(searchId);
  }

  beforeDestroy() {
    if ('hotel' !== this.$route.name) {
      HotelSearchStore.stopSearch();
    }
  }
}
