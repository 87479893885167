



























import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import { ProfileSyncStatus } from '@/api/profile/profile-sync.model';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';

@Component({})
export default class ProfileSyncInformation extends Vue {
  @Prop() status!: ProfileSyncStatus;



  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }

  get datetime() {
    if (!this.status || !this.status.acknowledgeDate) {
      return '';
    }

    const zoneOffset = moment(this.status.acknowledgeDate)
      .utcOffset(moment.parseZone(new Date()).utcOffset()).format('Z');
    return moment(this.status.acknowledgeDate)
      .format(this.dateTimeFormat) + ' (GMT ' + zoneOffset + ')';
  }

  get statusValue() {
    return this.status.status;
  }

}
