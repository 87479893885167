import Vue from 'vue';

export default () => {
  if (Vue.config.ignoredElements.length) {
    Vue.config.ignoredElements.push('pao-seatmap-ui');
  } else {
    Vue.config.ignoredElements = [
      'pao-seatmap-ui',
    ];
  }
};
