





















import { Vue, Component, Prop } from 'vue-property-decorator';
import settings from '@/settings';

@Component({})
export default class UiErrors extends Vue {
  @Prop({
    default: () => { return []; }
  }) errors!: any;

  get account2FALink() {
    return settings.manageAccount + '/TwoFactorAuthentication';
  }

  classes(error) {
    return {
      'errors error': !error.isMessage ,
      'errors basket-error justify-content-center': error.isMessage
    };
  }

  accountRedirect() {
    window.open(this.account2FALink, '_blank');
  }
}

