import PaymentCarConfiguration from '@/modules/settings/payment-methods/PaymentCarConfiguration.vue';

export default {
  path: ':configurationId?/car-configuration',
  name: 'car-configuration',
  component: PaymentCarConfiguration,
  displayName: 'Car configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPaymentMethodConfiguration',
    depthCategory: 'payment-methods',
    depth: 3,
    parentName: 'payment-methods-configurations',
  },
};