






















































































































































































































































































import _ from 'lodash';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, email } from 'vuelidate/lib/validators';
import AccountStore from '@/store/account.store';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import { CompareOffersApi } from '@/api/air-engine/compare-offers.api';
import AirResultsRow from '@/modules/search/air/AirResultsRow.vue';
import AirResultsRowView from'@/modules/search/air/AirResultsRowView.vue';
import AirSearchStore from '@/modules/search/air/air-search.store';
import SearchStore from '@/modules/search/search.store';
import { translate } from '@/i18n';
import { ContactsApi } from '@/api/profile/contacts.api';
import AirCompareLocalStore from './air-compare.local-store';
import { VclCode } from 'vue-content-loading';
import EventHandler from '@/services/event-handler';

const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

@Component({
  components: {
    AirResultsRow,
    AirResultsRowView,
    VclCode,
  }
})
export default class AirTemplateOffers extends Vue {
  $v;
  errors: any[] = [];
  travellersList: any[] = [];
  travellersListOption: any[] = [];
  isTravellersLoading: boolean = false;
  isBookerLoading: boolean = false;
  bookerListOption: any[] = [];
  queryTo: string = '';
  queryCc: string = '';
  saveLoading: boolean = false;
  languageOptions: LanguagesModel[] = [];

  @Validation()
  validationObject() {
    return {
      model: {
        emailTo: {
          required,
        },
        emailCc: {
        },
        title: {
          required
        },
        content: {},
        language: {
          required
        },
      }
    };
  }

  model: any = {
    emailTo: [],
    emailCc: [],
    title: this.customTitle,
    content: '',
    language: null
  };

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get templateOffers() {
    if (this.compareOfferDetailsData && this.compareOfferDetailsData.length) {
      return this.compareOfferDetailsData.map(offer => offer.airRecommendation);
    }

    return [];
  }

  get offersToShow() {
    if (this.templateOffers && this.templateOffers.length > 0) {
      let index = 0;
      return _.map(this.templateOffers.slice(0, 3), (template: any) => {
        let templatedOffer;

        templatedOffer = {
          ...template,
          title:
            index === 0 ? translate('search-air.main-recommendation') : translate('search-air.alternative-recommendation')
        };

        index++;
        return templatedOffer;
      });
    } else {
      return [];
    }
  }

  get user() {
    return AccountStore.current;
  }

  get bookerList() {
    return [{...this.user!.profile}] || [];
  }

  get localStore() {
    return AirCompareLocalStore.localStore;
  }

  get loaderCompare() {
    return AirSearchStore.loaderCompare;
  }

  get compareOfferDetailsData() {
    return AirSearchStore.compareOfferDetailsData;
  }

  get errCompareMessages() {
    return AirSearchStore.errCompareMessages;
  }

  async loadLanguages() {
    try {
      const result = await LanguagesApi.getLanguages();

      if (result && result.data) {
        this.languageOptions = result.data;
      }

    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
  }

  addTag(newTag) {
    this.travellersListOption.push(newTag);
  }

  async asyncFind(query: string) {
    if (query && query.length > 1) {
      this.isTravellersLoading = true;
      query = query.toLowerCase();
      this.queryTo = query;

      if (this.travellersList) {
        const response = this.travellersList.filter((traveller: any) => traveller.email.toLowerCase().includes(query));

        this.travellersListOption = [];

        if (response) {
          response.forEach((element) => {
            this.travellersListOption.push(element);
          });
        }
      }

      this.isTravellersLoading = false;
    } else {
      this.isTravellersLoading = false;
      this.travellersListOption = [];
    }
  }

  async asyncFindBooker(query: string) {
    if (query && query.length > 1) {
      this.isBookerLoading = true;
      query = query.toLowerCase();
      this.queryCc = query;

      if (this.bookerList) {
        const response = this.bookerList.filter((traveller: any) => traveller.primaryEmail.toLowerCase().includes(query));

        this.bookerListOption = [];

        if (response) {
          response.forEach((element) => {
            this.bookerListOption.push(element as any);
          });
        }
      }

      this.isBookerLoading = false;
    } else {
      this.isBookerLoading = false;
      this.bookerListOption = [];
    }
  }

  checkMultiToQuery() {
    let isMoreThenOne = this.queryTo.indexOf(';');
    if (isMoreThenOne) {
      let emailList = this.queryTo.split(';');
      if (emailList && emailList.length) {
        emailList.forEach(data => {
          if (this.validateEmail(data) && -1 === this.model.emailTo.map(emailTo => emailTo.email).indexOf(data)) {
            this.model.emailTo.push({email: data});
          }
        });
      }
      this.queryTo = '';
      this.$forceUpdate();
      this.focusOnButton();
    }
  }

  manualValueTo() {
    if (this.queryTo && -1 === this.model.emailTo.map(to => to.email).indexOf(this.queryTo)) {
      if (this.validateEmail(this.queryTo)) {
        this.model.emailTo.push({email: this.queryTo});
        this.queryTo = '';
        this.focusOnButton();            
        this.$forceUpdate();
      } else {
        this.checkMultiToQuery();
      }
    }
  }

  checkMultiCcQuery() {
    let isMoreThenOne = this.queryCc.indexOf(';');
    if (isMoreThenOne) {
      let emailList = this.queryCc.split(';');
      if (emailList && emailList.length) {
        emailList.forEach(data => {
          if (this.validateEmail(data) && -1 === this.model.emailCc.map(emailCc => emailCc.email).indexOf(data)) {
            this.model.emailCc.push({email: data});
          }
        });
      }
      this.queryCc = '';
      this.$forceUpdate();
      this.focusOnButton();
    }
  }

  manualValueCc() {
    if (this.queryCc && -1 === this.model.emailCc.map(cc => cc.email).indexOf(this.queryCc)) {
      if (this.validateEmail(this.queryCc)) {
        this.model.emailCc.push({email: this.queryCc});
        this.queryCc = '';
        this.focusOnButton();
        this.$forceUpdate();
      } else {
        this.checkMultiCcQuery();
      }
    }
  }

  validateEmail(data) {
    return emailRegex.test(data);
  }

  backToResults() {
    this.resetModel();
    return;
  }

  focusOnButton() {
    ((this.$refs.sendButton as Vue).$el as HTMLElement).focus();
  }

  async sendOffers() {
    this.$v.model.$touch();
    if (this.$v.model.$pending || this.$v.model.$error) {
      return;
    }
    this.saveLoading = true;
    let selectedOffers: any[] = [];
    let profileIds: any[] = [];
    let emails: any[] = [];
    this.offersToShow.forEach(offer => {
      let obj = {
        title: offer.title,
        recommendationId: offer.id,
        proposalId: offer.proposal.id,
        sessionKey: this.$route.params.searchId,
      };
      selectedOffers.push(obj);
    });

    if (this.model.emailTo && this.model.emailTo.length) {
      this.model.emailTo.forEach(emailTo => {
        if (emailTo.id) {
          profileIds.push(emailTo.id);
        } else {
          emails.push(emailTo.email);
        }
      });
    }

    let request = {
      selectedOffers,
      profileIds,
      emails,
      title: this.model.title,
      body: this.model.content,
      language: this.model.language ? this.model.language.code : 'en',
      emailCopies: this.model.emailCc.map(emailCc => emailCc.email ? emailCc.email : emailCc.primaryEmail),
    };

    try {
      await CompareOffersApi.compareOffers(request);
      this.saveLoading = false;
      this.tooltipInfo();
      this.resetModel();
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.saveLoading = false;
    }
  }

  tooltipInfo() {
    let obj = {
      type: translate('common.success'),
      message: translate('search-air.email-sent')
    };
    EventHandler.$emit('show-toast', obj);
  }

  resetModel() {
    this.model = {
      emailTo: '',
      emailCc: '',
      title: this.customTitle,
      content: '',
      language: null,
    };
    this.$emit('close');
  }

  async getCompareOfferDetails() {
    if (this.localStore) {
      let param = this.localStore.map(data => {
        return {
          searchId: data.searchId,
          recommendationId: data.offerId,
          proposalId: data.proposalId,
          isGroupedModeEnabled: data.isNewFlight,
        };
      });
      await AirSearchStore.compareOfferDetails(param);
    }
  }

  updateEmailsData() {
    if (this.user) {
      if (this.travellersList.length) {
        if (-1 === this.travellersList.map(list => list.email).indexOf(this.user.profile.primaryEmail) ) {
          this.model.emailCc = this.bookerList;
        }
      } else {
        this.model.emailCc = this.bookerList;
      }
    }
  }

  async updateView() {
    AirSearchStore.setLoaderCompare(true);
    await this.loadLanguages();
    AirCompareLocalStore.getLocalStore();
    this.getCompareOfferDetails();
    this.model.title = this.customTitle;
    this.travellersList = [];
    if (this.user && this.user.profile && this.user.profile.displayLanguage && this.languageOptions && this.languageOptions.length) {
      this.model.language = this.languageOptions.find(lang => lang.code === this.user!.profile.displayLanguage);
    }
    if (this.airSearchParameters && this.airSearchParameters.travellers && this.airSearchParameters.travellers.length) {
      this.airSearchParameters.travellers.forEach(async traveller => {
        const contacts = await ContactsApi.getContacts(traveller.id);
        if (contacts && contacts.data) {
          this.travellersList.push({
            ...traveller,
            email: contacts.data.primary.email,
          });
          this.model.emailTo = [...this.travellersList];
        }
      });
      this.updateEmailsData();
    }
  }

  get customTitle() {
    if (this.airSearchParameters.searchMode && this.airSearchParameters.searchMode === 'MultiLeg') {
      return translate('search-air.trip-offer') + ` ${
        this.airSearchParameters.legs[0].from.cityName
      } to ${this.airSearchParameters.legs[this.airSearchParameters.legs.length - 1].to.cityName} on ${
        this.airSearchParameters.legs[0].departureDate
      }.`;
    } else {
      return translate('search-air.trip-offer') + ` ${
        this.airSearchParameters.from && this.airSearchParameters.from.cityName
      } to ${this.airSearchParameters.to && this.airSearchParameters.to.cityName} on ${
        this.airSearchParameters.departureDate
      }.`;
    }
  }

  created() {
    this.updateView();
  }

  @Watch('route')
  onRouteChange() {
    this.updateView();
  }
}
