<template>
  <div class="popups-page">
    <div class="card">
      <div class="card-header">
        Popup
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <UiButton
              size-name="small"
              @click="showModal = true"
            >
              Show popup
            </UiButton>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <UiButton
              size-name="small"
              @click="showToast()"
            >
              Show toast
            </UiButton>
            <UiButton
              size-name="small"
              @click="showToast()"
            >
              Show toast
            </UiButton>
            <UiButton
              size-name="small"
              @click="showToast()"
            >
              Show toast
            </UiButton>
          </div>
        </div>
      </div>
      <UiPopup :show="showModal" :title="'PopUp title'" @close="showModal = false">
        <Forms1 @close="showModal = false"/>
      </UiPopup>
    </div>
  </div>
</template>

<script>
import Forms1 from './Form.vue';
import { Vue, Component } from 'vue-property-decorator';
import EventHandler from '@/services/event-handler';

@Component({
  components: {
    Forms1,
  }
})
export default class Popups extends Vue {
  showModal = false;
  obj = null;

  showToast() {
    this.obj = {
      type: 'error',
      title: 'error',
      message: 'error error error'
    };
    EventHandler.$emit('show-toast', this.obj);
  }
}

</script>