





































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import { HomeApi } from '@/api/home/home.api';
import { AirLocationModel } from '@/api/home/home.model';
import { LanguageCode } from '@/api/dictionary/dictionary.model';
import AccountStore from '@/store/account.store';
import EventBus from '@/services/event-handler';
import Multiselect from 'vue-multiselect';

const updateAirSearchParameters: string = 'update:airSearchParameters';

@Component({})
export default class SearchAutocomplete extends Vue {
  @Prop() returnAvailable!: any;
  @Prop() leg!: any;

  fromLocations: AirLocationModel[] = [];
  toLocations: AirLocationModel[] = [];
  shouldFocus: boolean = false;
  isSwapLocationsActive: boolean = false;


  get toLocationsOptions() {
    return this.toLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get fromLocationsOptions() {
    return this.fromLocations.map(location => ({ ...location, uniqId: location.cityCode + location.airportCode }));
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }


  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'leg.isFromLoading',
  })
  async findFromAirLocation(query) {
    if (query && query.length > 2) {
      this.leg.isFromLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocations = [];
        this.fromLocations = response.data;
      }

      this.leg.isFromLoading = false;
    } else {
      this.leg.isFromLoading = false;
      this.fromLocations = [];
      if (this.leg.from) {
        this.fromLocations.push(this.leg.from);
      }
    }
    this[updateAirSearchParameters](this.leg);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'leg.isToLoading',
  })
  async findToAirLocation(query) {
    if (query && query.length > 2) {
      this.leg.isToLoading = true;
      const response = await HomeApi.findAirLocation(query, this.languageCode);
      this.toLocations = [];
      if (response && response.data) {
        this.toLocations = [];
        this.toLocations = response.data;
      }

      this.leg.isToLoading = false;
    } else {
      this.leg.isToLoading = false;
      this.toLocations = [];
      if (this.leg.to) {
        this.toLocations.push(this.leg.to);
      }
    }
    this[updateAirSearchParameters](this.leg);
  }

  onReceive(data) {
    if (data) {
      const element: Vue = this.$refs.fromSelect as Vue;
      const from: Multiselect = element.$refs.multiselect as Vue;
      const searchInput: HTMLInputElement = from.$refs.search as HTMLInputElement;

      if (!searchInput) {
        return;
      }
      return searchInput && searchInput.focus();
    }
  }

  @AnimatedIn()
  animatedIn() {
    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.dateEnter();
    } else if (this.shouldFocus) {
      this.onReceive(true);
    }
  }

  onFocus() {
    this.shouldFocus = true;
  }

  dateEnter() {
    this.$emit('focusSearch');
  }

  swapLocations() {
    this.isSwapLocationsActive = true;
    let from = this.leg.from;
    this.leg.from = this.leg.to;
    this.leg.to = from;
    this[updateAirSearchParameters](this.leg);

    this.prepareTo();
    this.prepareFrom();

    setTimeout(() => {
      this.isSwapLocationsActive = false;
    }, 450);
  }

  prepareFrom() {
    this.fromLocations = [];
    if (this.leg.from) {
      this.fromLocations.push(this.leg.from);
    }
  }

  prepareTo() {
    this.toLocations = [];
    if (this.leg.to) {
      this.toLocations.push(this.leg.to);
    }
  }

  focusOnDate(legNumber) {
    EventBus.$emit('focus-on-date', legNumber);
  }

  focusOn(elementId) {
    if (window.innerWidth < 800) {
      return;
    }
    const vueEl = this.$refs[elementId] as Vue;
    if (!vueEl) {
      return;
    }
    const inputValue = vueEl.$el as HTMLInputElement;
    inputValue.getElementsByTagName('input')[0].focus();
  }

  @Emit()
  [updateAirSearchParameters](value) {
    return value;
  }

  created() {
    EventBus.$on('focus-from', this.onFocus);
  }

  beforeDestroy() {
    EventBus.$off('focus-from', this.onFocus);
  }
}

