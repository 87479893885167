






















































































































































import { Component, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import { translate } from '@/i18n';
import SettingsStore from '@/modules/settings/settings.store';
import { SupplierName } from '@/services/supplier-name.service';
import { FeeRule } from '@/api/air-engine/fees-configuration.model';
import FeesConfigurationStore from './fees-configuration.store';

@Component({})
export default class FeesListRail extends BaseTable<FeeRule> {
  @Prop({}) readonly!: boolean;
  
  params: BaseTableParams = new BaseTableParams({
    sortBy: 'name',
  });
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  imagesConst: string = '/assets/img/loader/1.gif';
  selected: FeeRule | null = null;
  menuActive: boolean = false;
  removePopup: boolean = false;
  errorsOnPopup: any[] = [];
  fields = {
    offerSources: {
      sortable: false,
      label: translate('settings-fees.offer-sources'),
    },
    journeys: {
      sortable: false,
      label: translate('settings-fees.journeys'),
    },
    action: {
      sortable: false,
      label: translate('settings-fees.action')
    },
    paymentMethods: {
      sortable: false,
      label: translate('settings-fees.payment-methods')
    },
    showDetails: {
      sortable: false,
      label: ''
    },
    fee: {
      sortable: false,
      label: translate('settings-fees.fee')
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    actions: {
      sortable: false,
      label: ''
    }
  };
  paymentMethodsOptions: any[] = [
    {
      value: 'CentralPayment',
      label: translate('settings-payment-methods.central-payment'),
    },
    {
      value: 'PaymentCard',
      label: translate('settings-payment-methods.user-payment-card'),
    },
    {
      value: 'LodgeCard',
      label: translate('settings-payment-methods.lodge-card'),
    }
  ];
  tripTypeOptions: any[] = [
    {
      value:  'Domestic',
      label:  translate('settings-travel-policy.domestic')
    },
    {
      value: 'International',
      label:  translate('settings-travel-policy.international')
    },
    {
      value: 'Intercontinental',
      label: translate('settings-travel-policy.intercontinental')
    }
  ];
  fareTypeOptions: any[] = [
    {
      value: 'Public',
      label: translate('search-air.public'),
    },
    {
      value: 'Private',
      label: translate('profile-contacts.private'),
    },
    {
      value: 'Nego',
      label: translate('search-air.nego'),
    }
  ];
  whenToApplyOptions: any[] = [
    {
      value: 'MakingAReservation',
      label: translate('settings-fees.making-a-reservation'),
    },
  ];

  get isConfigurationEnabled() {
    return FeesConfigurationStore.isTrainConfigurationEnabled;
  }

  set isConfigurationEnabled(newValue) {
    FeesConfigurationStore.setIsTrainConfigurationEnabled(newValue);
  }

  get activeTab() {
    return FeesConfigurationStore.selectedTab;
  }

  get entries() {
    return FeesConfigurationStore.trainRulesList;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configuration() {
    return FeesConfigurationStore.currentTrainConfiguration;
  }

  get configurationId() {
    return router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : undefined;
  }

  get count() {
    return this.entries ? this.entries.length : 0;
  }

  get errors() {
    return FeesConfigurationStore.trainErrors;
  }

  get loadingPage() {
    return FeesConfigurationStore.trainLoading;
  }

  getSupplierString(suppliers: any[]) {
    if (suppliers.length > 2) {
      return `${suppliers.length} ${translate('settings-travel-policy.lowercase-suppliers')}`;
    }
    const result: any[] = [];
    suppliers.forEach((item: any) => {
      result.push(item.label ? item.label : SupplierName(item));
    });

    return result.join(', ');
  }

  getJourneysString(journeys: any[]) {
    if (journeys.length > 2) {
      return  `${journeys.length} ${translate('settings-fees.lowercase-journeys')}`;
    }
    const result: any[] = [];
    journeys.forEach((item: any) => {
      const entry = this.tripTypeOptions.find(type => { return type.value === item; });
      if (entry && entry.label) {
        result.push(entry.label);
      }
    }); 

    return result.join(', ');
  }

  getFareTypesString(fareTypes: any[]) {
    const result: any[] = [];
    fareTypes.forEach((item: any) => {
      const entry = this.fareTypeOptions.find(type => { return type.value === item; });
      if (entry && entry.label) {
        result.push(entry.label);
      }
    });

    return result.join(', ');
  }

  getPaymentMethodsString(paymentMethods: any[]) {
    if (paymentMethods.length > 2) {
      return `${paymentMethods.length} ${translate('settings-fees.lowercase-payment-methods')}`;
    }
    let result: any[] = [];
    paymentMethods.forEach((item: any) => {
      let entry = this.paymentMethodsOptions.find(type => { return type.value === item; });
      if (entry && entry.label) {
        result.push(entry.label);
      }
    });

    return result.join(', ');
  }

  getFeeString(item) {
    if (item.feeType === 'Fixed') {
      return `${item.fixedFee.amount} ${item.fixedFee.currency.code}`;
    } else if (item.feeType === 'Percentage') {
      return `${item.percentageFee}%`;
    }
  }

  toggleMenu(item) {
    if (!this.selected || this.selected.id !== item.id) {
      this.selected = item;
      this.menuActive = true;
    } else if (this.selected && this.selected.id === item.id) {
      this.menuActive = false;
      this.selected = null;
    } else {
      this.selected = item;
      this.menuActive = !this.menuActive;
    }
  }

  addRule() {
    FeesConfigurationStore.setTrainSelectedRule({});
    if (this.configurationId) {
      router.push({
        name: 'fee-rule-rail',
        params: {
          configurationId: this.configurationId,
        }
      });
    } else {
      router.push({
        name: 'fee-rule-rail',
        params: {
          configurationId: 'new',
        }
      });
    }
  }

  editRule(data) {
    FeesConfigurationStore.setTrainSelectedRule(data);
    if (this.configurationId) {
      router.push({
        name: 'fee-rule-rail',
        params: {
          configurationId: this.configurationId,
        }
      });
    } else {
      router.push({
        name: 'fee-rule-rail',
        params: {
          configurationId: 'new',
        }
      });
    }
  }

  onRemovePopupClose() {
    this.removePopup = false;
    this.selected = null;
    this.menuActive = false;
  }

  removeRule() {
    FeesConfigurationStore.removeTrainRule(this.selected);
    this.closeRemovePopup();
  }

  remove() {
    this.menuActive = false;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errorsOnPopup = [];
    this.onRemovePopupClose();
  }
}

