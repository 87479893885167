import HotelResults from '@/modules/search/hotel/HotelResults.vue';
import Search from '@/modules/search/Search.vue';
import HotelResultsFilters from '@/modules/search/hotel/HotelResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';
import HotelSubintro from '@/modules/search/hotel/HotelSubintro.vue';

export default {
  path: '/hotel-results-search/:searchId',
  name: 'hotelResultsSearch',
  components: {
    default: HotelResults,
    intro: Search,
    subintro: HotelSubintro,
    sidebar: HotelResultsFilters,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-search',
    permission: 'SearchOffers',
    waitForDicts: true,
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        hotel: AnimationName.UP,
      },
    }
  }
};
