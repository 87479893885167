






















































































































































































































































































































































































































































































































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, requiredIf, minLength, email } from 'vuelidate/lib/validators';

import { userFullName } from '@/core/user-full-name';
import { PaymentCardsApi } from '@/api/profile/payment-cards.api';
import { PaymentCardModel, PaymentCardProfileModel, PaymentCardType, PaymentCardMainType} from '@/api/profile/payment-cards.model';
import { PaymentCardTypeList } from '@/const/paymentcard-type-list.const';
import { PhoneCode } from '@/api/profile/contacts.model';
import BasketStore from '@/modules/basket/basket.store';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';
import settings from '@/settings';
import { getSecureFields } from '@/plugins/secure-fields';
import DictionaryStore from '@/store/dictionary.store';
import { isNameValid } from '@/core/validation/is-name-valid.validator';
import EventBus from '@/services/event-handler';

const expireDateRegex = /^(0[1-9]|10|11|12)\/\d{2}$/;

const phoneregex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^\d{3,17}$/.test(value);
};

const postalCodeRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^[0-9a-zA-Z\u3012]{1,6}-?[0-9a-zA-Z\s]{0,10}[0-9a-zA-Z]$/.test(value);
};

const cityRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }

  return /^[a-zA-Z\u0080-\u024F]+(?:([\ \-\']|(\.\ ))[a-zA-Z\u0080-\u024F]+)*$/.test(value);
};
const CreditCardImg = {
  VIS: 'visa',
  ECA: 'maste_card',
  DIN: 'diners_club_card',
  DIS: 'discover_card',
  AMX: 'american_express',
  UAP: 'UATP',
  JCB: 'not_found',
  CUP: 'not_found'
};

@Component({})
export default class PaymentCardPopup extends Vue {
  @Prop() cardData?: PaymentCardProfileModel;
  @Prop() profileId!: string;
  @Prop() isNew!: boolean;
  @Prop({ default: false }) isInBasket?: boolean;
  @Prop({ default: null }) tripItemId?: any;

  $v;
  cardCreateErrorMessage: string | null = null;
  selectedCard: string | null = null;
  calculatedCardType: PaymentCardMainType | null = null;
  Form: PaymentCardModel | null =  {
    expireDate: '',
    holderFirstName: '',
    holderLastName: '',
    displayName: '',
    transactionId: '',
    type: null,
    email: null,
    phoneNumber: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    cityName: '',
    stateRegion: '',
    postalCode: '',
    country: null,
    selectedProfile: null,
    phone: {code: '', number: '', isMobile: false},
  };

  phoneCodeEmptyValue: PhoneCode = {
    phoneCode: '',
    countryName: '',
    code: '',
    threeLetterCode: '',
    shortPhoneCodeDisplayName: 'Phone code',
    fullPhoneCodeDisplayName: 'None',
  };
  serverErrors: any[] = [];
  formPending: boolean = false;
  secureFields: any | null = null;
  paymentMethod: string | null = null;
  cardNumberValid: boolean = true;
  cardNumberLength: number = 0;
  secureFieldsError: string | null = null;
  selectTravellerProfile: boolean = true;
  singleUseCreditCard: boolean = false;
  isReadOnly: boolean = false;
  selectedPhoneNumberCode: PhoneCode | null = null;

  isNotWhiteSpaced(model: string) {
    return model && model.length ? !!(model.trim()) : true;
  }

  @Validation()
  validationObject() {
    let Form: any = {
      transactionId:  {},
      expireDate: {
        required,
        regex(expireDate) {
          return (
            expireDateRegex.test(expireDate) &&
            expireDate.length === 5
          );
        },
        checkDate(expireDate) {
          let month = new Date().getMonth() + '';
          let year = new Date().getFullYear() + '';
          let stringYear = year.substring(2, 4);
          let checkYear = true;
          let checkMonthAndYear = true;
          let checkMonth = true;
          if (expireDate.length === 5) {
            let splitValue = expireDate.split('/');
            checkMonthAndYear = parseFloat(splitValue[1]) === parseFloat(stringYear)
                                && parseFloat(splitValue[0]) <= parseFloat(month);
            checkYear = parseFloat(splitValue[1]) < parseFloat(stringYear) ||
                        parseFloat(splitValue[1]) > (parseFloat(stringYear) + 50);
          }
          return !checkMonthAndYear && !checkYear;
        }
      },
      type: {
        required,
      },
      holderFirstName: {
        required,
        isNameValid,
        whitespaced: (model) => this.isNotWhiteSpaced(model),
        maxLength: maxLength(128),
      },
      holderLastName: {
        required,
        isNameValid,
        whitespaced: (model) => this.isNotWhiteSpaced(model),
        maxLength: maxLength(128),
      },
      displayName: {
        maxLength: maxLength(50),
      },
      companyName: {
        maxLength: maxLength(64),
      },
      addressLine1: {
        required,
        whitespaced: (model) => this.isNotWhiteSpaced(model),
        maxLength: maxLength(256),
      },
      addressLine2: {
        maxLength: maxLength(256),
      },
      cityName: {
        required,
        cityRegex,
        whitespaced: (model) => this.isNotWhiteSpaced(model),
        maxLength: maxLength(128),
      },
      stateRegion: {
        maxLength: maxLength(128),
      },
      postalCode: {
        required,
        postalCodeRegex,
        whitespaced: (model) => this.isNotWhiteSpaced(model),
        maxLength: maxLength(11),
      },
      country: {
        required,
      },
      selectedProfile: {
        required: requiredIf(() => {
          return this.singleUseCreditCard && this.selectTravellerProfile && this.canAddPaymentCardsToTravellers && this.isInBasket;
        }),
      },
      email: {
        required: requiredIf(() => {
          return this.isVisa && this.Form && this.Form.phone && !this.Form.phone.number;
        }),
        maxLength: maxLength(255),
        email,
      },
      phone: {
        number: {
          minLength: minLength(3),
          maxLength: maxLength(17),
          phoneregex,
          required: requiredIf(() => {
            return this.isVisa && this.Form && !this.Form.email;
          }),
        },
      },
    };

    return {
      Form,
    };
  }

  get selectTravellerProfileModel() {
    if (this.canAddPaymentCardsToTravellers) {
      return this.selectTravellerProfile;
    }

    return false;
  }

  set selectTravellerProfileModel(value) {
    this.selectTravellerProfile = value;
  }

  get availableTypes() {
    let listToReturn = PaymentCardTypeList;

    if (this.isNew && (this.cardNumberLength === 0 || !this.paymentMethod)) {
      this.Form!.type = null;
    }

    if (this.calculatedCardType === PaymentCardMainType.Visa) {
      listToReturn = this.setVisaList();
    } else if (this.calculatedCardType === PaymentCardMainType.MasterCard) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.MasterCardCredit
                || type.value === PaymentCardType.MasterCardDebit;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.Discover) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.Discover;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.DinersClub) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.DinersClub;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.Maestro) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.Maestro;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.AmericanExpress) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.AmericanExpress;
      });
    } else if (this.calculatedCardType === PaymentCardMainType.UATP) {
      listToReturn = listToReturn.filter(type => {
        return type.value === PaymentCardType.UATP;
      });
    } else {
      if (this.Form && this.isNew && this.paymentMethod) {
        this.Form.type = null;
        
        if (this.paymentMethod === 'VIS') {
          listToReturn = this.setVisaList();
        } else if (this.paymentMethod === 'ECA') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.MasterCardCredit
                || type.value === PaymentCardType.MasterCardDebit;
          });
        } else if (this.paymentMethod === 'AMX') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.AmericanExpress;
          });
        } else if (this.paymentMethod === 'DIN') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.DinersClub;
          });
        } else if (this.paymentMethod === 'DIS') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.Discover;
          });
        } else if (this.paymentMethod === 'JCB') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.JapanCreditBureau;
          });
        } else if (this.paymentMethod === 'CUP') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.ChinaUnionPay;
          });
        } else if (this.paymentMethod === 'UAP') {
          listToReturn = listToReturn.filter(type => {
            return type.value === PaymentCardType.UATP;
          });
        }

      }
      this.calculatedCardType = null;
      // doeas not filter credit card types when not known return [];
    }

    if (this.Form && this.isNew && listToReturn.length === 1) {
      this.Form.type = listToReturn[0];
    }

    return listToReturn;
  }

  get expirationDateFormat() {
    if (this.Form) {
      return this.Form.expireDate;
    }
  }

  get allPhoneCountryCodes() {
    if (!DictionaryStore.allCountries) {
      return [];
    }

    let allPhoneCodes = DictionaryStore.allCountries.filter((country) => { return country && country.phoneCode!.length > 0; })
    .map((country) => {
      return { 
        ...country,
        shortPhoneCodeDisplayName: '+' + country.phoneCode,
        fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
      };
    });

    return [this.phoneCodeEmptyValue].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get phoneNumberCodeRequired() {
    return this.isVisa && this.Form && this.Form.phone
    && this.Form.phone.number
    && this.Form.phone.number.length > 0 && (!this.selectedPhoneNumberCode || !this.selectedPhoneNumberCode.phoneCode);
  }

  get phoneNumberOrEmailRequired() {
    return this.isVisa && (this.$v.Form.phone.number.$error && !this.$v.Form.phone.number.required || this.$v.Form.email.$error && !this.$v.Form.email.required);
  }

  get phoneNumberRequired() {
    return this.isVisa && this.selectedPhoneNumberCode && this.selectedPhoneNumberCode.phoneCode &&
    this.Form!.phone!.number === '';
  }

  get pciProxyMerchantId() {
    return settings.pciProxy_MerchantId;
  }

  get pciProxyMockEnabled() {
    return settings.pciProxy_MockEnabled === 'true';
  }

  get basketTravellers() {
    return BasketStore.basketTravellers;
  }

  get allCountries() {
    return DictionaryStore.allCountries;
  }

  get canAddPaymentCardsToTravellers() {
    return this.$hasAccess('CanAddPaymentCardToTraveller');
  }

  get basketId() {
    return BasketStore.basketId;
  }

  get isVisa() {
    return this.Form && this.Form.type && this.Form.type.value === PaymentCardType.VisaCredit ||
      this.Form && this.Form.type && this.Form.type.value === PaymentCardType.VisaDebit ||
      this.Form && this.Form.type && this.Form.type.value === PaymentCardType.VisaElectron;
  }

  setVisaList() {
    return PaymentCardTypeList.filter(type => {
      return type.value === PaymentCardType.VisaCredit
        || type.value === PaymentCardType.VisaDebit
        || type.value === PaymentCardType.VisaElectron;
    });
  }

  set expirationDateFormat(value) {
    if (value) {
      if (value.includes('/')) {
        this.Form!.expireDate = value;
        return;
      }
      if (value.slice(1, 2) === '/' && value.length === 2) {
        value = this.setCharAt(value, 0 , '0' + value.substr(0, 1)) + '';
      }
      if (value.slice(2, 3) !== '/') {
        value = this.setCharAt(value, 2 , '/') + '';
      }
      this.Form!.expireDate = value;
    }
  }

  userFullName(user) {
    return userFullName(user);
  }

  setCharAt(str, index, chr) {
    if (index > str.length)  {
      return str;
    }
    return str.substr(0, index) + chr + str.substr( index + 1);
  }

  setSelectetedPhoneNumberCode() {
    if (this.Form && this.Form!.phone && this.Form.phone.code) {
      this.selectedPhoneNumberCode = this.allPhoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === this.Form!.phone!.code; })!;
    }
  }

  onPhoneNumberCodeChange(selectedPhoneCode) {
    if (this.Form && selectedPhoneCode && !selectedPhoneCode.phoneCode) {
      this.Form.phone!.number = '';
    }
    setTimeout(() => {
      const el = (this.$refs.phoneNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  updateData(cardData) {
    let type: any | null = null;
    let country: any | null = null;
    let phoneCode: any | null = null;
    if (cardData) {
      type = PaymentCardTypeList.find(item => { return item.value === cardData.type; });
      country = this.allCountries!.find(item => {return item.code === cardData.countryCode; });
      phoneCode = this.allPhoneCountryCodes!.find(item => {return item.phoneCode === cardData.phone.code; });
    }

    this.Form = {
      expireDate: cardData ? cardData.expireDate : '',
      holderFirstName: cardData ? cardData.holderFirstName : '',
      holderLastName: cardData ? cardData.holderLastName : '',
      displayName: cardData ? cardData.displayName : '',
      type: type,
      transactionId: cardData ? cardData.maskedNumber : '',
      email: cardData ? cardData.email : '',
      phoneNumber: cardData ? cardData.phoneNumber : '',
      companyName: cardData ? cardData.companyName : '',
      addressLine1: cardData ? cardData.addressLine1 : '',
      addressLine2: cardData ? cardData.addressLine2 : '',
      cityName: cardData ? cardData.cityName : '',
      stateRegion: cardData ? cardData.stateRegion : '',
      postalCode: cardData ? cardData.postalCode : '',
      country: country,
      phone: {
        code: cardData && cardData.phone ? phoneCode : null,
        number: cardData && cardData.phone ? cardData.phone.number : '',
        isMobile: false,
      }
    };

    this.selectedPhoneNumberCode = phoneCode ? phoneCode : null;
    
  }

  onCardMainTypeChange(newCartType) {
    this.calculatedCardType = newCartType;
    if (this.isNew) {
      this.Form!.type = this.availableTypes[0];
    }
  }

  onValidate(data) {
    if (data.fields.cardNumber) {
      this.cardNumberValid = data.fields.cardNumber.valid;
      this.cardNumberLength = data.fields.cardNumber.length;

      if (!this.cardNumberValid) {
        this.$nextTick(() => {
          (this.$refs.cardField as HTMLElement).scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    }
  }

  onChange(data) {
    this.cardNumberLength = data.fields.cardNumber.length;
    if (data.fields.cardNumber.paymentMethod) {
      this.paymentMethod = data.fields.cardNumber.paymentMethod;
    } else {
      this.paymentMethod = null;
    }
  }

  onSuccess(data) {
    if (data.transactionId) {
      this.Form!.transactionId = data.transactionId;
      this.submit();
    }
  }

  onError(data) {
    if (data) {
      this.secureFieldsError = data.error;
    }
  }

  initSecureFields() {
    this.secureFieldsError = null;
    if (this.isNew && !this.pciProxyMockEnabled) {
      this.secureFields = getSecureFields();

      let styles = {
        '*': 'border-radius: 4px; border: 1px solid #999999;min-height: 35px; text-transform: inherit; margin:2px; max-width: calc(100% - 5px); height: 35px;color: #4D4D4D;padding-left: 25px; font-family: Orkney, "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, sans-serif; font-size: 14px; outline: 0;',
        '*:focus': 'box-shadow:  0 0 0 .13rem rgba(51, 82, 111, .25);',
        '*::placeholder': 'color: #D8D8D8',
        '*:-ms-input-placeholder': 'color: #D8D8D8',
        '@font-face': {
          '*': {
              fontFamily: 'Orkney',
              fontStyle: 'normal',
              fontWeight: 400,
              src: 'url(\'Orkney Regular.woff2\') format(\'woff2\'), url(\'Orkney Regular.eot\') format(\'embedded-opentype\'), url(\'Orkney Regular.woff\') format(\'woff2\'),url(\'Orkney Regular.otf\') format(\'opentype\'),url(\'Orkney Regular.ttf\') format(\'truetype\')',
          }        
        }
      };

      this.secureFields.on('validate', this.onValidate);
      this.secureFields.on('change', this.onChange);
      this.secureFields.on('success', this.onSuccess);
      this.secureFields.on('error', this.onError);

      this.secureFields.initTokenize(this.pciProxyMerchantId, {
        cardNumber: {
          placeholderElementId: 'card-number-placeholder',
          inputType: 'tel',
          placeholder: translate('profile-payment.your-card-number'),
        }
      }, 
      {            
        styles: styles,
        focus: 'cardNumber',
      });
    }
  }

  focusOnSave() {
    const vueEl = this.$refs.backButton as Vue;
    if (!vueEl) {
      return;
    }
    const button = vueEl.$el as HTMLInputElement;
    setTimeout(() => {
      button.focus();
    }, 100);
  }

  submitForm() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    if (this.isVisa && (this.phoneNumberCodeRequired || this.phoneNumberRequired) && this.phoneNumberOrEmailRequired) {
      return;
    }

    if (this.isNew && !this.pciProxyMockEnabled) {
      this.cardNumberValid = true;
      this.secureFieldsError = null;
      this.submitSecureFields();
    } else {
      this.submit();
    }
  }

  async submitSecureFields() {
    await this.secureFields.submit();
  }

  get displayImg() {
    if (this.paymentMethod) {
      return `/assets/img/credit-cards/${CreditCardImg[this.paymentMethod]}.png`;
    }
  }

  @Emit()
  close() {
  }

  async submit() {
    let type = this.Form!.type ? this.Form!.type.value : null;
    if (this.calculatedCardType && this.calculatedCardType === PaymentCardMainType.Unknown) {
      type = PaymentCardType.Unknown;
    }
    try {
      this.formPending = true;
      let selectedProfile = this.selectTravellerProfile && this.isInBasket && this.Form && this.Form.selectedProfile
        ? this.Form.selectedProfile.id : this.profileId;

      if (this.isNew) {
        let request = {
          transactionId: this.Form!.transactionId,
          expireDate: this.Form!.expireDate,
          holderFirstName: this.Form!.holderFirstName,
          holderLastName: this.Form!.holderLastName,
          displayName: this.Form!.displayName,
          type: type,
          email: this.Form!.email,
          phoneNumber: this.Form!.phoneNumber,
          companyName: this.Form!.companyName,
          addressLine1: this.Form!.addressLine1,
          addressLine2: this.Form!.addressLine2,
          cityName: this.Form!.cityName,
          stateRegion: this.Form!.stateRegion,
          postalCode: this.Form!.postalCode,
          countryCode: this.Form!.country.code,
          tripId: this.isInBasket && !this.singleUseCreditCard && this.basketId ? this.basketId : '',
          phone: {
            code: this.isVisa && this.selectedPhoneNumberCode ? this.selectedPhoneNumberCode.phoneCode : '',
            number: this.isVisa && this.Form &&  this.Form!.phone && this.Form!.phone.number ? this.Form!.phone.number : '',
            isMobile: false,
          }
        };

        let response = await PaymentCardsApi.createPaymentCard(selectedProfile, request);
        if (response && response.data) {
          BasketStore.setPaymentForTripId({
            paymentCardId: response.data.id,
            tripItemId: this.tripItemId,
          });
        }
      } else {
        let request = {
          expireDate: this.Form!.expireDate,
          holderFirstName: this.Form!.holderFirstName,
          holderLastName: this.Form!.holderLastName,
          displayName: this.Form!.displayName,
          type: type,
          email: this.Form!.email,
          phoneNumber: this.Form!.phoneNumber,
          companyName: this.Form!.companyName,
          addressLine1: this.Form!.addressLine1,
          addressLine2: this.Form!.addressLine2,
          cityName: this.Form!.cityName,
          stateRegion: this.Form!.stateRegion,
          postalCode: this.Form!.postalCode,
          countryCode: this.Form!.country.code,
          phone: {
            code: this.isVisa && this.selectedPhoneNumberCode ? this.selectedPhoneNumberCode.phoneCode : '',
            number: this.isVisa && this.Form &&  this.Form!.phone && this.Form!.phone.number ? this.Form!.phone.number : '',
            isMobile: false,
          }
        };

        await PaymentCardsApi.updatePaymentCard(selectedProfile, this.cardData!.id, request);
      }

      this.$emit('updateCard');
      EventBus.$emit('profile-data-saved');
      if (this.isInBasket) {
        BasketStore.loadPaymentMethods();
      }

      this.close();

    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }

  mounted() {
    this.initSecureFields();
    if (this.cardData && !this.isNew) {
      this.updateData(this.cardData);
    }
  }
}
