























































































import { Vue, Component, Prop, Watch, Model, Emit } from 'vue-property-decorator';
import _ from 'lodash';
import expressionCreatorConst from '@/const/expressioncreator.const';
import { ExpenseApi } from '@/api/expense/expense.api';
import { WonderModel } from '@/api/wonder-search/wonder-search.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';

@Component({})
export default class ExpressionCreator extends Vue {
  @Model('change') expression: any;

  options: any[] = [];
  errors: any[] = [];
  isLoading: boolean = false;
  creatorParameters: any[] = [];
  asyncTimer: number | null = null;
  typeValuePopup: boolean = false;
  typeValue: any = null;
  isEdit: boolean = false;
  selectedOption: any = null;

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get optionsMaped() {
    if (this.options && this.options.length) {
      return this.options.map((param, index) => ({...param, uniqId: param.type + param.value + index}));
    }
    return [];
  }

  get creatorParametersMaped() {
    if (this.creatorParameters && this.creatorParameters.length) {
      return this.creatorParameters.map((param, index) => ({...param, uniqId: param.type + param.value + index}));
    }
    return [];
  }

  set creatorParametersMaped(value) {
    this.creatorParameters = value;
    this.onChange(value);
  }

  get placeHolderMessage() {
    if (!this.creatorParameters || this.creatorParameters.length === 0) {
      return this.$t('navigation-wondersearch.placeholder');
    }
    return '';
  }

  @Emit('change')
  onChange(value) {
    return value;
  }

  classes(option) {
    return {
      'scope-color': option.type === 'Scope',
      'operator-color': option.type === 'Operator',
      'parameter-color': option.type === 'Parameter',
    };
  }

  delayedSearch(query, retrieveParametersFromApi) {
    let self = this;
    this.isLoading = true;

    if (this.asyncTimer) {
      clearTimeout(this.asyncTimer);
      this.asyncTimer = null;
    }

    this.asyncTimer = setTimeout(() => {
      self.asyncFind(query, retrieveParametersFromApi);
    }, 200);
  }

  refreshOptions(option, removing) {
    this.options = [];
    this.isLoading = true;
    let findRoutine = this.asyncFind;
    let delayedFindRoutine = this.delayedSearch;

    if (removing) {
      this.$emit(expressionCreatorConst.EVENT_SEARCH_READY, null);
      setTimeout(() => {
        delayedFindRoutine('', true);
      }, 200);
    }
    else {
      this.creatorParameters.push(option);
      findRoutine('', true);
    }
  }

  onRemove(option) {
    this.refreshOptions(option, true);
  }

  editOption(option) {
    this.selectedOption = option;
    if (option.type === 'Value' || option.type === 'ListValue') {
      this.isEdit = true;
      this.typeValue = option.value;
      this.typeValuePopup = true;
    }
  }

  onSelect(option) {
    this.selectedOption = option;
    if (option.type === 'Value' || option.type === 'ListValue') {
      this.isEdit = false;
      this.typeValue = null;
      this.typeValuePopup = true;
      this.focusOnInput();

      return;
    }

    this.refreshOptions(option, false);
  }

  focusOnInput() {
    setTimeout(() => {
      let el = (this.$refs.typeValue as Vue).$refs.input as HTMLElement;
      el.focus();
    }, 100);
  }

  focusOnExpenseCreator() {
    this.$nextTick(() => {
      let el = (this.$refs.expenseCreator as Vue).$refs.search as HTMLElement;
      el.focus();
    });
  }

  clearAll() {
    this.creatorParameters = [];
    this.asyncFind('', null);
  }

  confirmValuePopup() {
    this.asyncFind('', true, true);
    this.typeValuePopup = false;
    this.focusOnExpenseCreator();
  }

  closePopup() {
    if (!this.isEdit) {
      this.creatorParameters.pop();
    }
    this.typeValuePopup = false;
    this.selectedOption = null;
    this.focusOnExpenseCreator();
  }

  keydown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.confirmValuePopup();
    }
  }

  async asyncFind(query, retrieveParametersFromApi, istypeValue?) {
    this.isLoading = true;

    let wonderRequest: WonderModel = {
      policyId: this.expensePolicyId,
      currentEntry: query,
      parameters: this.creatorParameters,
    };

    if (istypeValue) {
      if (this.creatorParameters
        && this.creatorParameters.length
        && this.creatorParameters[this.creatorParameters.length - 1].type === 'Value') {
        this.creatorParameters.pop();
      }

      let index = this.creatorParametersMaped.indexOf(this.selectedOption);
      let isLast = (this.creatorParameters.length - 1) === index;
      let option = {
        type: this.selectedOption.type,
        name: '',
        displayName: '',
        description: '',
        value: this.typeValue
      };
      if (-1 < index && !isLast && this.isEdit && this.selectedOption) {
        this.creatorParameters[index] = option;
      } else {
        if (this.creatorParameters[this.creatorParameters.length - 1].type === 'ListValue' && this.creatorParameters[this.creatorParameters.length - 1].value === '') {
          wonderRequest.parameters.pop();
        }
        wonderRequest.parameters.push(option);
      }
    }

    try {
      const response = await ExpenseApi.expressionCreator(wonderRequest);
      if (response && response.data) {
        this.options = response.data.hints;
        if (retrieveParametersFromApi && response.data.parameters) {
          this.creatorParameters = response.data.parameters;
          this.onChange(this.creatorParameters);
        }
      }
      this.isLoading = false;
    } catch (error) {
      this.errors = $handleErrors(error, true);
      this.isLoading = false;
    }
  }

  @Watch('expression', { immediate: true })
  onExpressionChange(value) {
    if (value !== '') {
      this.creatorParameters = value;
    }
    this.asyncFind('', null);
  }
}
