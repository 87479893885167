
































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class TrainWagon extends Vue {
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: false }) selected!: boolean;
  @Prop({ default: null }) trainNumber!: string | null;

  get color() {
    if (this.isActive) {
      if (this.selected) {
        return '#33526F';
      }
      return '#666666';
    }
    return '#999999';
  }

  emitClick() {
    this.$emit('click');
  }
}
