import ProjectsConfigurations from '@/modules/settings/projects-configuration/ProjectsConfigurations.vue';

export default {
  path: '',
  name: 'projects-configurations',
  component: ProjectsConfigurations,
  displayName: `Projects' configurations' list`,
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadProjects',
    parentName: 'projects',
    depthCategory: 'projects',
    depth: 1,
  }
};