import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  AirSearchSession,
  AirOffersModel,
  FilterOffersQuery,
  OffersQuery,
  ResponseAirModel,
  AgencyMarkup,
  AirSearchSessionState,
  InitAirSearchMessage,
  AirOffersResponseModel,
  AirSearchFlightsModel,
  SearchFlightProposalsModel,
  BasketItemsRequest,
  BasketResult,
  AdvancedSearchOptionsQuery,
  AdvancedSearchOptions,
  AdvancedSearchParameters,
} from './air-search.model';

class AirApiClass {
  public initAirSearch(params: InitAirSearchMessage): Promise<AxiosResponse<ResponseAirModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public initExchangeAirSearch(params: any, basketId: string): any {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/exchange?basketId={basketId}',
      method: RequestMethod.POST,
    }, { basketId }, params);
  }

  public initAirSearchForMission(params: InitAirSearchMessage, missionId): Promise<AxiosResponse<ResponseAirModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search?missionId={missionId}',
      method: RequestMethod.POST,
    }, { missionId }, params);
  }

  public addSegment(params, basketId): Promise<AxiosResponse<ResponseAirModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search?basketId={basketId}',
      method: RequestMethod.POST,
    }, { basketId }, params);
  }

  public addSegmentForMission(params, basketId, missionId): Promise<AxiosResponse<ResponseAirModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search?basketId={basketId}&missionId={missionId}',
      method: RequestMethod.POST,
    }, { basketId, missionId }, params);
  }

  public getAirSearchSession(searchId): Promise<AxiosResponse<AirSearchSession>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/{searchId}',
      method: RequestMethod.GET,
    }, searchId, {});
  }

  public getExchangeAirSearchSession({ searchId }): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/exchange/{searchId}',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public getOffers(searchId): Promise<AxiosResponse<AirOffersModel>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/{searchId}/offers',
      method: RequestMethod.GET,
    }, searchId, {});
  }

  public getExchangeOffers(query: OffersQuery): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/exchange/{searchId}/offers?${query.sortQuery}`,
      method: RequestMethod.GET,
    }, query, {});
  }

  public getAirOffers(query: OffersQuery, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<AirOffersResponseModel>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/search/{searchId}/offers?${query.sortQuery}`,
      method: RequestMethod.GET,
    }, query, {}, false, cancelTokenSource);
  }

  public getFilterAirOffers(query: FilterOffersQuery): Promise<AxiosResponse<AirOffersModel>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/search/{searchId}/offers/filter/{filterId}?${query.sortQuery}`,
      method: RequestMethod.GET,
    }, query, {});
  }

  public updateFilterOffers(mode, searchId, params, cancelTokenSource?: CancelTokenSource) {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/{mode}/{searchId}/offers/filters',
      method: RequestMethod.PUT,
    }, { mode, searchId }, params, false, cancelTokenSource);
  }

  public setAgencyMarkupFilters(searchId, param): Promise<AxiosResponse<AgencyMarkup>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/{searchId}/offers/agency-markup',
      method: RequestMethod.PUT,
    }, searchId, param);
  }

  public getAirSearchState(searchId, stateId, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<AirSearchSessionState>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/{searchId}/state?stateId={stateId}',
      method: RequestMethod.GET,
    }, { searchId, stateId }, {}, false, cancelTokenSource);
  }

  public getExchangeAirSearchState({ searchId }): Promise<AxiosResponse<AirSearchSessionState>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/exchange/{searchId}/state',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public getEnabledAirSuppliers() {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/suppliers',
      method: RequestMethod.GET,
    }, {}, {});
  }

  public getAirSearchFlights(query: OffersQuery, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<AirSearchFlightsModel>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/search/{searchId}/flights?${query.sortQuery}`,
      method: RequestMethod.GET,
    }, query, {}, false, cancelTokenSource);
  }

  public getSearchFlightProposals(query: OffersQuery): Promise<AxiosResponse<SearchFlightProposalsModel>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/search/{searchId}/proposals?${query.sortQuery}${query.flightsHashes}`,
      method: RequestMethod.GET,
    }, query, {});
  }

  public getExchangeFlights(query: OffersQuery): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/exchange/{searchId}/flights?${query.sortQuery}`,
      method: RequestMethod.GET,
    }, query, {});
  }

  public getExchangeProposals(query: OffersQuery): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + `/api/air/exchange/{searchId}/proposals?${query.sortQuery}${query.flightsHashes}`,
      method: RequestMethod.GET,
    }, query, {});
  }

  public createBasket(searchId, params: BasketItemsRequest): Promise<AxiosResponse<BasketResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/search/{searchId}/add-offer-to-trip',
      method: RequestMethod.POST,
    }, { searchId }, params);
  }

  public checkIfCanCreateBasket(searchId, recommendationId): Promise<AxiosResponse<BasketResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/search/{searchId}/check-missed-saving/{recommendationId}',
      method: RequestMethod.GET,
    }, { searchId, recommendationId }, {});
  }

  public exchangeBasketItem(searchId, params): Promise<AxiosResponse<BasketResult>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/search/{searchId}/exchange-trip-item-offer',
      method: RequestMethod.POST,
    }, { searchId }, params);
  }

  public updateFeeOnOffer({ searchId, offerId, agencyFee }): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/{searchId}/offers/{offerId}/fee',
      method: RequestMethod.PUT,
    }, { searchId, offerId }, { agencyFee });
  }

  public updateAgencyMarkupOnOffer({ searchId, offerId, agencyMarkup }): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/{searchId}/offers/{offerId}/agency-markup',
      method: RequestMethod.PUT,
    }, { searchId, offerId }, agencyMarkup);
  }

  public getAdvancedOptionsAvailability(searchCompanyId: string): Promise<AxiosResponse<string[]>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/advanced/availability?searchCompanyId={searchCompanyId}',
      method: RequestMethod.GET
    }, { searchCompanyId }, {});
  }

  public getAdvancedOptions(request: AdvancedSearchOptionsQuery): Promise<AxiosResponse<AdvancedSearchOptions>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/advanced/options',
      method: RequestMethod.POST
    }, {  }, request);
  }

  public getUpsellData(searchId: string, recommendationId: string, providerSearchId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/offers/{recommendationId}/upsell?providerSearchId={providerSearchId}&searchId={searchId}',
      method: RequestMethod.GET
    }, { recommendationId, providerSearchId, searchId }, {});
  }

  public createAdvancedOptions(request: AdvancedSearchParameters[]): Promise<AxiosResponse<string>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/advanced',
      method: RequestMethod.POST,
    }, { }, request, true);
  }

  public updateAdvancedOptions(advancedSearchParametersId: string, request: AdvancedSearchParameters[]): Promise<AxiosResponse<string>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/advanced/{advancedSearchParametersId}',
      method: RequestMethod.PUT,
    }, { advancedSearchParametersId }, request, true);
  }

  public getSavedAdvancedOptions(advancedSearchParametersId: string): Promise<AxiosResponse<AdvancedSearchParameters[]>> {
    return http.execute({
      path: settings.apiAirEngine + '/api/air/search/advanced/{advancedSearchParametersId}',
      method: RequestMethod.GET,
    }, { advancedSearchParametersId }, {});
  }
}

export const AirApi: AirApiClass = new AirApiClass();
