



































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import ProfileSyncStore from './profile-sync.store';

@Component({})
export default class ProfileSyncConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  Form: any = {
    configurationData: {
      name: '',
      providersConfigurationData: [
        {
          profileSynchronizationProvider: '',
          enabled: false,
          secretKey: '',
        }
      ]
    },
  };
  imagesConst: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      Form: {
        configurationData: {
          name: {
            required,
            maxLength: maxLength(128),
          },
          providersConfigurationData: {
            $each: {
              secretKey: {
                required: requiredIf((item) => {
                  return item.enabled;
                }),
              }
            }
          },
        }
      }
    };
  }

  get currentConfiguration() {
    return ProfileSyncStore.ProfileSyncConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditProfilesSynchronization');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return ProfileSyncStore.errMessages;
  }

  get showErrMessages() {
    return ProfileSyncStore.showError;
  }

  get loading() {
    return ProfileSyncStore.loading;
  }
  
  get showError() {
    return ProfileSyncStore.showError;
  }

  get canShowCustomError() {
    return ProfileSyncStore.canShowCustomError;
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'profile-sync-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    ProfileSyncStore.setErrMessages([]);
    ProfileSyncStore.setShowError(false);
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(false);
      await ProfileSyncStore.addProfileSyncConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(true);
      await ProfileSyncStore.updateProfileSyncConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  mapFormDataForRequest(edit: boolean = false) {
    let request;
    if (edit) {
      request = {
        configurationId: this.Form.id,
        params: {
          name: this.Form.configurationData.name,
          providersConfigurationData: this.Form.configurationData.providersConfigurationData,
        }
      };
    } else {
      request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.configurationData.name,
        providersConfigurationData: this.Form.configurationData.providersConfigurationData,
      };
    }
    
    return request;
  }

  async created() {
    if (this.inEditMode) {
      await ProfileSyncStore.getProfileSyncConfiguration(this.$route.params.configurationId);
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
    } else {
      await ProfileSyncStore.getEmptyConfiguration();
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
    }
  }
}
