import CarProviderConfigurationDetail from '@/modules/settings/configurations/CarProviderConfigurationDetail.vue';

export default {
  path: 'new',
  name: 'new-car-provider-configuration',
  component: CarProviderConfigurationDetail,
  displayName: 'New car provider',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCarProviders',
    depthCategory: 'car-providers',
    depth: 2,
    parentName: 'car-provider-configurations',
  },
};