import ClientEmailNotifications from '@/modules/settings/client-email-notifications/ClientEmailNotifications.vue';
import ClientEmailNotificationsConfigurations from './client-email-notifications-configurations.route';
import ClientEmailNotificationsConfiguration from './client-email-notifications-configuration.route';

export default {
  path: 'client-email-notifications',
  component: ClientEmailNotifications,
  displayName: 'settings-sidebar.client-email-notifications',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditClientEmailNotifications',
    icon: 'confirmation_number',
    breadCrumb: `Client email notifications configurations' list`,
    parentName: 'clientEmailNotifications',
  },
  children: [
    ClientEmailNotificationsConfigurations,
    ClientEmailNotificationsConfiguration,
  ],
};