



































import { Vue, Component, Prop } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import formatTime from '@/filters/format-time.filter';
import AccountStore from '@/store/account.store';

@Component({})
export default class RangeTimeFilter extends Vue {
  @Prop() categories;
  @Prop() code!: string;
  @Prop({ default: 15 }) sliderStep!: number;
  @Prop() label!: string;
  @Prop({ default: 0 }) initMinLimit!: number;
  @Prop() initMaxLimit!: number;
  @Prop({ default: 0 }) initMin!: number;
  @Prop() initMax!: number;
  @Prop({ default: 1000 }) inputDelay!: number;
  @Prop({ default: () => { return {}; } }) meta: any;

  minValue = this.initMin;
  maxValue = this.initMax;
  minLimit: number = 0;
  maxLimit: number = 1439;
  timeout: number | null = null;
  visible = true;
  expanded = false;
  checkedUnwatch: any = null;

  get displayValue() {
    if (this.sliderValue) {
      let hours1: any = Math.floor(this.sliderValue[0] / 60);
      let minutes1 = this.sliderValue[0] - (hours1 * 60) + '';

      let hours2: any = Math.floor(this.sliderValue[1] / 60);
      let minutes2 = this.sliderValue[1] - (hours2 * 60) + '';

      hours1 += '';
      hours2 += '';

      if (hours1.length === 1) {
        hours1 = '0' + hours1;
      }
      if (minutes1.length === 1) {
        minutes1 = '0' + minutes1;
      }
      if (hours2.length === 1) {
        hours2 = '0' + hours2;
      }
      if (minutes2.length === 1) {
        minutes2 = '0' + minutes2;
      }
      
      let time1: any = '2020-02-02T' + hours1 + ':' + minutes1 + ':00';
      let time2: any = '2020-02-02T' + hours2 + ':' + minutes2 + ':00';

      return formatTime(time1) + ' - ' + formatTime(time2);
    }
  }

  get sliderValue() {
    if (this.categories && this.categories.length) {
      let selected = this.categories.find(cat => cat.selected);
      if (selected) {
        let minValue = selected.minLimit;
        let maxValue = selected.maxLimit;
        return [minValue, maxValue];
      }
    }
    return [this.minValue, this.maxValue];
  }

  set sliderValue(value) {
    this.minValue = value[0];
    this.maxValue = value[1];

    this.valuesChanged();
  }

  get user() {
    return AccountStore.current;
  }

  get isPlLang() {
    return this.user!.profile.displayLanguage === 'pl';
  }

  selectCategory(category) {
    if (category.selected) {
      this.categories.forEach(ca => ca.selected = false);
    } else {
      this.categories.forEach(ca => ca.selected = false);
      category.selected = true;
    }

    if (category.selected) {
      this.$emit('change', {
        code: this.code,
        data: {
          minValue: category.minLimit,
          maxValue: category.maxLimit,
          minLimit: 0,
          maxLimit: 1439,
        }
      });
    } else {
      this.minValue = 0;
      this.maxValue = 1439;
      this.$emit('change', {
        code: this.code,
        data: {
          minValue: 0,
          maxValue: 1439,
          minLimit: 0,
          maxLimit: 1439,
        }
      });
    }
    this.$emit('change', {
      code: this.code,
      data: {
        minValue: category.selected ? category.minLimit : 0,
        maxValue: category.selected ? category.maxLimit : 1439,
        minLimit: 0,
        maxLimit: 1439,
      }
    });
  }

  manualChange() {
    this.categories.forEach(cat => cat.selected = false);
  }

  emitChange() {
    this.$emit('change', {
      code: this.code,
      data: {
        minValue: this.minValue,
        maxValue: this.maxValue,
        minLimit: 0,
        maxLimit: 1439,
      }
    });
  }

  valuesChanged() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(this.emitChange.bind(this), this.inputDelay);
  }

  clearFilter() {
    this.categories.forEach(category => {
      category.selected = false;
    });
    this.minValue = this.initMinLimit || 0;
    this.maxValue = this.initMaxLimit || 1439;
    this.emitChange();
    this.categories.forEach(category => {
      if (
        category.minLimit === this.minValue &&
        category.maxLimit === this.maxValue
      ) {
        category.selected = true;
      }
    });
  }

  refresh(data) {
    if (data) {
      this.minLimit = Math.floor(data.minLimit);
      this.maxLimit = Math.ceil(data.maxLimit);
      this.minValue = Math.floor(data.minValue);
      this.maxValue = Math.ceil(data.maxValue);
    }
  }

  refreshWhenNeeded(params) {
    if (this.code !== params.code) {
      return;
    }

    this.refresh(params.data);
  }



  created() {
    EventBus.$on('clear-filters', this.clearFilter);
    EventBus.$on('refresh-filter-component', this.refreshWhenNeeded);
  }

  beforeDestroy() {
    EventBus.$off('clear-filters', this.clearFilter);
    EventBus.$off('refresh-filter-component', this.refreshWhenNeeded);
  }

}
