























































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

import BasketStore from './basket.store';
import AccountStore from '@/store/account.store';
import BasketApproval from './BasketApproval.vue';
import BasketDelayTicketingDeadline from './BasketDelayTicketingDeadline.vue';
import settings from '@/settings';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';
import BasketTripNotes from './BasketTripNotes.vue';
import LayoutStore from '@/modules/layout/layout.store';

@Component({
  components: {
    BasketApproval,
    BasketDelayTicketingDeadline,
    BasketExpirationCountdown,
    BasketTripNotes,
  },
})
export default class BasketBookView extends Vue {
  @Prop() basketExpired!: boolean;

  loaded = false;
  isShowHoldExplanation = false;
  isShowBookExplanation = false;

  get basketStatus() {
    return BasketStore.status && BasketStore.status.status;
  }

  get displayApproverEmpty() {
    return BasketStore.displayApproverEmpty;
  }

  get displayObligatoryConfirmationEmpty() {
    return BasketStore.displayObligatoryConfirmationEmpty;
  }
  
  get delayTicketingDeadline() {
    return BasketStore.delayTicketingDeadline;
  }

  get watchDisplayError() {
    if (this.displayApproverEmpty) {
      return this.$refs.approvalWorkflow;
    }
    if (this.displayObligatoryConfirmationEmpty) {
      return this.$refs.obligatoryConfirmation;
    }
    return null;
  }

  get canShowObligatoryConfirmation() {
    return BasketStore.canShowObligatoryConfirmation;
  }

  set displayApproverEmpty(value) {
    BasketStore.setDisplayApproverEmpty(value);
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }
  
  get option() {
    return BasketStore.finalBookOption;
  }

  set option(value) {
    BasketStore.setFinalBookOption(value);
  }

  get approvalWorkflowForBasket() {
    return BasketStore.approvalWorkflowForBasket;
  }

  get isAutoApproval() {
    if (!this.approvalWorkflowForBasket) {
      return false;
    }

    return !!this.approvalWorkflowForBasket.workflowLevels
      .find(level => level.approvalWorkflowResult === 'AutoApprove');
  }

  get canShowApprovalOptions() {
    if ('hold' === this.option && this.approvalVisible) {
      return true;
    }
    if ('approval' === this.option) {
      return true;
    }

    return false;
  }

  get canShowDelayTicketingDeadline() {
    return this.delayTicketingDeadline && this.delayTicketingDeadline.state === 'Active';
  }

  get showQuickBook() {
    return BasketStore.showQuickBook;
  }

  get showApprovalOptions() {
    return BasketStore.showApprovalOptions;
  }

  get holdDeadline() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.holdDeadline : null;
  }

  get holdVisible() {
    if (settings.enableBookingHoldAction !== 'true') {
      return false;
    }
    return BasketStore.preBookingData ? BasketStore.preBookingData.isHoldEnabled : null;
  }

  get holdDisabled() {
    return BasketStore.preBookingData ? !BasketStore.preBookingData.isHoldPossible : true;
  }

  get approvalDeadline() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.approvalDeadline : null;
  }

  get canDeadlinesChange() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.canDeadlinesChange : false;
  }

  get approvalVisible() {
    return BasketStore.preBookingData ? BasketStore.preBookingData.isApprovalEnabled : null;
  }

  get approvalDisabled() {
    return BasketStore.preBookingData ? !BasketStore.preBookingData.isApprovalPossible : true;
  }

  get willBeAutoApprovedOnDeadline() {
    return BasketStore.willBasketBeApprovedAutomatically;
  }

  get basket() {
    return BasketStore.basket;
  }

  get user() {
    return AccountStore.current;
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ', ' + this.currentTimeFormat + ' (\\G\\M\\T Z)';
  }

  get shouldShowAdditionalConsent() {
    return BasketStore.preBookingData &&
      BasketStore.preBookingData.additionalConsent !== null;
  }

  get additionalConsent() {
    if (
      !BasketStore.preBookingData ||
      !BasketStore.preBookingData.additionalConsent
    ) {
      return '';
    }

    return BasketStore.preBookingData.additionalConsent;
  }

  get checkTravelConsent() {
    return BasketStore.checkTravelConsent;
  }

  set checkTravelConsent(value) {
    BasketStore.setCheckTravelConsent(value);
    BasketStore.setDisplayObligatoryConfirmationEmpty(!value);
  }

  toggleShowHoldExplanation() {
    this.isShowHoldExplanation = !this.isShowHoldExplanation;
  }

  toggleShowBookExplanation() {
    this.isShowBookExplanation = !this.isShowBookExplanation;
  }

  async created() {
    BasketStore.setBasketApproverError(true);
    await Promise.all([
      BasketStore.loadPreBookingData(),
      BasketStore.getApprovalWorkflowForBasket(BasketStore.basketId),
      BasketStore.getDelayTickietingForBasket(BasketStore.basketId),
    ]);
    this.loaded = true;
  }

  dateTime(date) {
    const mmt = moment.parseZone(date);
    return mmt.format(this.dateTimeFormat);
  }

  @Watch('watchDisplayError')
  onDisplayObligatoryConfirmationEmpty(ref: HTMLElement) {
    LayoutStore.scrollManager.scrollTo(ref, false);
  }
}
