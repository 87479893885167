import HotelDetails from '@/modules/search/hotel/HotelDetails.vue';
import SearchTravellersList from '@/modules/search/SearchTravellersList.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/hotelDetailsTravellers/:searchId',
  name: 'hotelDetailsTravellers',
  components: {
    default: HotelDetails,
    intro: SearchTravellersList,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-hotel-results',
    permission: 'SelectTravellersForSearch',
    transitions: {
      intro: {
        search: AnimationName.UP,
        home: AnimationName.LEFT,
        hotel: AnimationName.UP,
      },
    }
  }
};
