import { RailLocationModel } from '@/api/home/home.model';

export interface TrainOffersResult {
  propertyId: string;
  offers: TrainOfferResult[];
}

export interface TrainOfferResult {
  id: string;
  orderId: string;
  recommendations?: TrainRecommendationsResult[];
  offerProvider: Provider;
  recommendationId?: string;
  journeys: Journey[];
  proposals: Proposal[];
  isTravelPolicyCompliant?: boolean;
  price: Price;
  selectedProposal?: Train;
  travelPolicy?: string;
  supplier?: string;
  hasDiscountCodes?: string;
  minAgencyFee?: any;
  minAgencyMarkup?: any;
  minSystemMarkup?: any;
}

export interface TrainRecommendationsResult {
  id: string;
  legRecommendationId: string;
  offerProvider: Provider;
  journeys: Journey[];
  proposals: Proposal[];
  isTravelPolicyCompliant: boolean;
  price: Price;
  selectedProposal?: Train;
  providerSearchId: string;
  supplier?: string;
  isAgencyFeeEditable?: boolean;
}

export interface Provider {
  code: string;
  name: string;
}

export interface TrainSearchProviderError {
  supplier: string;
  errorCode: string;
  providerError: string;
  serviceTag: string;
}
export interface SortOptions {
  START_DATE?: string;
  PRICE?: string;
}

export interface OffersQuery {
  searchId: string;
  sortQuery: string;
}

export interface Leg {
  arrival: string;
  departure: string;
  duration: number;
  from: From;
  to: From;
  trainInfo: TrainInfo;
  type: string;
  legkey: number;
}

export interface TrainInfo {
  trainType: string;
  number: number | null;
}

export interface From {
  cityCode: string;
  cityName: string;
}

export interface TrainLocation {
  type: string;
  code: string;
  name: string | undefined;
}

export interface InitTrainSearchMessage {
  searchMode: string;
  travellers: Traveller[];
  from: RailLocationModel | null;
  to: RailLocationModel | null;
  departureDate: string;
  departureTimeSpan: DepartureTimeSpan;
  returnDate: string;
  returnTimeSpan: DepartureTimeSpan;
  searchCompanyId: string | null;
}

export interface DepartureTimeSpan {
  from: number;
  to: number;
}

export interface Traveller {
  id: string;
  firstName: string;
  lastName: string;
}

export interface ResponseTrainModel {
  searchId: string;
  searchState: string;
  basketId: string;
  recommendationsTotalCount: number;
}

export interface TrainSearchSession {
  metadata: Metadata;
  request: Request;
  selectedLegs: SelectedLeg[];
}

export interface SelectedLeg {
  legNumber: number;
  recommendation: Recommendation;
}

export interface Recommendation {
  id: string;
  providerSearchId: string;
  offerProvider: OfferProvider;
  journeys: Journey[];
  minPrice: Price;
  proposals: Proposal[];
  price: Price;
  selectedProposal?: Fare[];
  basketItemStatus?: string;
  itemTravellers?: any[];
}

export interface Proposal {
  basePrice: any;
  agencyFee: any;
  rawProviderPrice?: any;
  systemMarkup?: any;
  agencyMarkup: any;
  segmentKey: string;
  fares: Fare[];
  id: string;
  isSelected?: boolean;
  recommendationId?: string;
}

export interface Fare {
  id: string;
  class: string;
  fullCondition: string;
  isSelected: boolean;
  offer: string;
  price: Price;
  isTravelPolicyCompliant: boolean;
  isNego: boolean;
  shortFareCondidtion: ShortFareCondidtion[];
  segmentKey?: string;
}

export interface ShortFareCondidtion {
  value: string;
  type: string;
}

export interface Price {
  amount: number;
  currency: Currency;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface Journey {
  legNumber: number;
  trains: Train[];
}

export interface Train {
  segmentKey: string;
  segmentId: string;
  type: string;
  from: From2;
  to: From2;
  departure: string;
  arrival: string;
  duration: number;
  trainInfo: TrainInfo;
  selectedProposal?: Fare | null;
  selectedSeats?: any;
  selectedAncillaries?: any;
}

export interface TrainInfo {
  trainType: string;
  number: number | null;
}

interface From2 {
  stationCode?: string;
  stationName: string;
  cityName: string;
  cityCode?: string;
}

export interface OfferProvider {
  code: string;
  name: string;
}

export interface Request {
  searchMode: string;
  travellers: Traveller[];
  from: From;
  to: From;
  departureDate: string;
  departureTimeSpan: DepartureTimeSpan;
  returnDate: string;
  returnTimeSpan: DepartureTimeSpan;
}

export interface DepartureTimeSpan {
  from: number;
  to: number;
}

export interface Metadata {
  searchId: string;
  searchState: string;
  basketId: string;
  recommendationsTotalCount: number;
  missionId: any | null;
  isFranceDomesticDepatureAndOuigoAvailable?: boolean;
}

export interface SortOptions {
  PRICE?: string;
  DEPARTURE_TIME?: string;
}

export interface TrainOffersResponseModel {
  statistics: Statistics;
  searchResults: SearchResults;
  currency: Currency;
}

export interface Statistics {

}

export interface SearchResults {
  searchState: string;
  recommendations: Recommendation[];
  recommendationsTotalCount: number;
  recommendationsFilteredCount: number;
  moreRecommendationAvailable: boolean;
}

export interface TrainSetProposalsModel {
  legNumber: number;
  recommendationId: string;
  fares: Fare[];
}

export enum SearchState {
  Undefined = '',
  InProgress = 'InProgress',
  Completed = 'Completed',
}