












































































































































































import { Component } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import EventHandler from '@/services/event-handler';
import SettingsStore from '../settings.store';
import { Permission } from '@/const/permission.enum';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import {
  ConfigurationRow,
  companyConfigurationType
} from '@/api/profile/configurations.model';
import { translate } from '@/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

@Component({})
export default class AccommodationProviderConfigurations extends BaseTable<ConfigurationRow> {
  useQueryParams: boolean = true;
  showAccommodationProviderConfigurationDetailPopup: boolean = false;
  editMode: boolean = false;
  loading: boolean = false;
  params: BaseTableParams = new BaseTableParams({
    size: 10,
    page: 1,
    usePageParam: true,
  });
  fields = {
    name: {
      label: translate('common.configuration-name')
    },
    actions: {
      sortable: false,
      label: '',
      class: 'settings-subpage__actions-column',
    }
  };
  items: ConfigurationRow[] = [];
  selectedSet: ConfigurationRow | null = null;
  selectedSetId: string | null = null;
  company = this.currentCompany;
  errors: any[] = [];
  removePopup: boolean = false;
  Form: any = {
    searchName: '',
  };


  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get count() {
    return this.items.length;
  }

  get isInRootBusinessUnit() {
    return SettingsStore.currentCompany ? SettingsStore.currentCompany.isRoot : false;
  }

  get addNewUrl() {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/accommodation-provider-configurations/new');
  }

  editConfigurationUrl(item) {
    return sanitizeUrl('/settings/' + this.currentCompany!.rootCompanyId + '/accommodation-provider-configurations/' + item.id);
  }

  hasErrors(item) {
    return -1 < SettingsStore.accommodationProvidersErrors.indexOf(item.id);
  }

  toggleConfigurationSetMenu(item) {
    if (this.selectedSet && this.selectedSet.id === item.id) {
      this.selectedSet = null;
    } else {
      this.selectedSet = item;
    }
  }

  addConfigrations() {
    this.$router.push({
      name: 'new-accommodation-provider-configuration',
    });
  }

  remove(item) {
    this.selectedSet = item;
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.errors = [];
    this.removePopup = false;
  }

  async removeConfiguration() {
    try {
      this.loading = true;
      await ProfileConfigurationsApi.deleteConfiguration(this.selectedSet!.id);
      this.reload();

      EventHandler.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-provider.configuration-removed'),
        message: translate('settings-provider.info-removed')
      });
      this.removePopup = false;
    }
    catch (error) {
      this.errors = this.$handleErrors(error, true);
      this.loading = false;
    }
  }

  editConfigrations(item) {
    this.$router.push({
      name: 'accommodation-provider-configuration',
      params: {
        configurationId: item.id,
      },
    });
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getNames() {
    this.submitForm();
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  async getItems() {
    if (this.company) {
      this.loading = true;
      let request = {
        searchName: this.Form.searchName,
      };

      try {
        const result = await ProfileConfigurationsApi.getByRootCompanyId(
          this.company.rootCompanyId,
          companyConfigurationType.accommodationProviderConfiguration,
          this.params.size,
          this.params.page,
          Permission.ReadAccommodationProviders,
          request.searchName
        );
        this.items = result.data.results;
        this.loading = false;

        return {
          page: result.data.page,
          results: result.data.results,
        };
      } catch (error) {
        this.errors = this.$handleErrors(error, true);
      } finally {
        this.loading = false;
      }
    }
  }
}
