import AccountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';
import { SearchInitModes } from '@/api/profile/profile.model';

export default async (to, from, next) => {
  const currentUser = AccountStore.CurrentUser;
  const chooseTravellerMode = AccountStore.chooseTravellerMode;
  const hasPermission = AccountStore.HasPermission('SearchOffers');

  if (
    to.matched[0] &&
    'home' === to.matched[0].name &&
    currentUser &&
    currentUser.profile
  ) {
    SearchStore.initSearchParams();
  }

  if (
    hasPermission &&
    !chooseTravellerMode &&
    to.matched[0] &&
    'home' === to.matched[0].name &&
    currentUser && currentUser.profile &&
    (currentUser.profile.defaultSearchMode === SearchInitModes.SelectGuests ||
      currentUser.profile.defaultSearchMode === SearchInitModes.SelectGuestsWithNoCompanySelection)
  ) {
    let redirect: any = {
      path: '/search',
    };

    SearchStore.updateSkipTravellers(true);
    if (!from.matched.length) {
      redirect.replace = true;
    }
    next(redirect);

    return;
  }

  next();
};
