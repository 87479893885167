import { 
  Range,
  PagingQuery,
} from './trip.model';

export interface SearchOfflineRequestsMessage {
  requestStatuses: OfflineRequestStatus[];
  requestorsNames: string[];
  agentsNames: string[];
  companies: string[];
  requestTypes: OfflineRequestType[];
  associatedTripCode: string;
  requestDate: any;
}

export interface OfflineRequestsSearchResult {
  isMoreOfflineRequestsAvailable: boolean;
  offlineRequests: OfflineRequestSearch[];
}

export interface OfflineRequestSearch {
  id: string;
  title: string;
  requestStatus: OfflineRequestStatus;
  requestType: OfflineRequestType;
  requestDate: any;
  requestorName: string;
  requestorCompanyName: string;
  tripCode: string;
  associatedAgentName: string;
  basketId: string;
}

export interface OfflineRequestSummary {
  id: string;
  requestId: string;
  title: string;
  requestorName: string;
  requestorCompanyName: string;
  requestorId: string;
  requestDate: string;
  requestStatus: string;
  requestType: string;
}

export enum OfflineRequestStatus {
  New = 'New',
  InProgress = 'InProgress',
  Closed = 'Closed',
}

export enum OfflineRequestType {
  Unknown = 'Unknown',
  NewBooking = 'NewBooking',
  Modification = 'Modification',
  Cancellation = 'Cancellation',
  AdditionalService = 'AdditionalService',
}

export interface OfflineRequest {
  id: string;
  requestId: string;
  title: string;
  description: string;
  finishComment: string;
  requestorName: string;
  requestorCompanyName: string;
  associatedAgentName: string;
  tripCode: string;
  requestorId: string;
  basketId: string;
  associatedAgentId: string;
  requestDate: string;
  finishDate: string;
  requestStatus: OfflineRequestStatus;
  requestType: OfflineRequestType;
}

export interface CreateOfflineRequestMessage {
  title: string;
  description: string;
  basketId?: string;
  requestorId: string;
  associatedAgentId?: string;
  requestType: OfflineRequestType;  
}

export interface UpdateOfflineRequestMessage {
  title?: string;
  description?: string;
  finishComment: string;
  basketId?: string;
  requestStatus: OfflineRequestStatus;
}

export interface OfflinePagingQuery extends PagingQuery {
  userId: string;
}
