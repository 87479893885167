




















































































































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength } from 'vuelidate/lib/validators';
import { HomeApi } from '@/api/home/home.api';
import { PreferencesApi } from '@/api/profile/preferences.api';
import { PreferencesModel } from '@/api/profile/preferences.model';
import { AirLine } from '@/api/dictionary/dictionary.model';
import DictionaryStore from '@/store/dictionary.store';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

@Component({})
export default class FlightPreferencesPopup extends Vue {
  @Prop() preferences!: any;
  serverErrors: any[] = [];
  carriersLoading: boolean = false;
  departureAirportsLoading: boolean = false;
  arrivalAirportsLoading: boolean = false;
  departureAirportsOptions: any = [];
  arrivalAirportsOptions: any = [];
  allAirlinesOption: any = [];
  formPending: boolean = false;
  Form: PreferencesModel = {
    flightPreferences: {
      seatType: null,
      generalInformations: '',
      carriers: [],
      departureAirports: [],
      arrivalAirports: [],
    },
  };
  $v;
  seatTypeOptions = [
    {
      value: 'Aisle',
      name: translate('profile-preferences.aisle'),
    },
    {
      value: 'Window',
      name: translate('profile-preferences.window'),
    },
  ];

  get allAirlines() {
    return DictionaryStore.allAirLines;
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        flightPreferences: {
          generalInformations: {
            maxLength: maxLength(2048),
          }
        }
      },
    };
  }

  updateFormData(data) {
    if (data) {
      this.Form = {
        flightPreferences: {
          seatType: data.seatType !== 'Undefined' ? this.seatTypeOptions.find(type => type.value === data.seatType) : null,
          generalInformations: data.generalInformations,
          carriers: data.carriers,
          departureAirports: data.departureAirports,
          arrivalAirports: data.arrivalAirports,
        }
      };
    }
  }

  focusOnNext() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'carriersLoading',
  })

  async findAirlines(query: string) {
    if (query && query.length > 1) {
      this.carriersLoading = true;
      query = query.toLowerCase();

      if (this.allAirlines) {
        const response = this.allAirlines.filter((airline: AirLine) => airline.code.toLowerCase().includes(query) || airline.businessName.toLowerCase().includes(query));

        this.allAirlinesOption = [];

        if (response) {
          response.forEach((element) => {
            this.allAirlinesOption.push(element as any);
          });
        }
      }

      this.carriersLoading = false;
    } else {
      this.carriersLoading = false;
      this.allAirlinesOption = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'departureAirportsLoading',
  })
  async findDepartureAirports(query) {
    if (query && query.length > 1) {
      this.departureAirportsLoading = true;
      const response = await HomeApi.findFromLocation({
        query
      });

      this.departureAirportsOptions = [];
      if (response && response.data) {
        this.departureAirportsOptions = response.data.filter(data => data.type !== 'City');
      }

      this.departureAirportsLoading = false;
    } else {
      this.departureAirportsLoading = false;
      this.departureAirportsOptions = [];
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'arrivalAirportsLoading',
  })
  async findArrivalAirports (query) {
    if (query && query.length > 1) {
      this.arrivalAirportsLoading = true;
      const response = await HomeApi.findToLocation({
        query
      });

      this.arrivalAirportsOptions = [];
      if (response && response.data) {
        this.arrivalAirportsOptions = response.data.filter(data => data.type !== 'City');
      }

      this.arrivalAirportsLoading = false;
    } else {
      this.arrivalAirportsLoading = false;
      this.arrivalAirportsOptions = [];
    }
  }

  @Emit()
  close() {
    return;
  }

  async save() {
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    try {
      if (this.preferences) {
        let profileId = this.$route.params.id;
        let request = {
          travellerType: this.preferences.travellerType,
          currencyCode: this.preferences.currencyCode,
          flightPreferences: {
            seatType: this.Form.flightPreferences!.seatType ? this.Form.flightPreferences!.seatType.value : null,
            generalInformations: this.Form.flightPreferences!.generalInformations,
            carriers: this.Form.flightPreferences!.carriers.map((carrier: any) => carrier.code),
            departureAirports: this.Form.flightPreferences!.departureAirports.map(departure => departure.code),
            arrivalAirports: this.Form.flightPreferences!.arrivalAirports.map(arrival => arrival.code),
          },
          railPreferences: {
            ...this.preferences.railPreferences,
          },
          accommodationPreferences: {
            ...this.preferences.accommodationPreferences,
            roomTypes: this.preferences.accommodationPreferences!.roomTypes.map(room => room.code ? room.code : room),
          },
          carPreferences: {
            ...this.preferences.carPreferences
          },
        };
        await PreferencesApi.updatePreferences(profileId, request);
      }
      let obj = {
        type: 'success',
        title: translate('profile-personal.data-saved'),
        message: translate('profile-personal.flight-saved')
      };
      EventHandler.$emit('show-toast', obj);
      EventHandler.$emit('reload-loyalty');
      EventHandler.$emit('profile-data-saved');
      this.close();
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  created() {
    if (this.preferences && this.preferences.flightPreferences) {
      this.updateFormData(this.preferences.flightPreferences);
    }
  }

}
