import EditCustomMessage from '@/modules/global-settings/custom-messages/EditCustomMessage.vue';

export default {
  path: ':messageId',
  name: 'edit-custom-message',
  component: EditCustomMessage,
  displayName: 'Air fee rule definition',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanAccessGlobalSettings',
    icon: 'chat',
    breadCrumb: `Custom messages' list`,
    parentName: 'custom-messages',
    depth: 2,
  }
};