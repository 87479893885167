



















import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';

@Component({})
export default class TrainWagonCabin extends Vue {
  @Prop({ default: null }) coach!: any | null;

  get x1() {
    return consts.margin + consts.colWidth / 2;
  }

  get y1() {
    return consts.margin + consts.rowHeight / 2;
  }

  get width() {
    if (!this.coach) {
      return 2 * consts.thickness;
    }
    return (this.coach.width - 1) * consts.colWidth;
  }

  get height() {
    if (!this.coach) {
      return 2 * consts.thickness;
    }
    return this.coach.length * consts.rowHeight;
  }

  get coachPath() {
    return [
      'M' + (this.x1 - consts.thickness),
      (this.y1 - consts.thickness),
      'h' + (this.width + 2 * consts.thickness),
      'v' + (this.height + 2 * consts.thickness),
      'h' + (-(this.width + 2 * consts.thickness)) + 'z',
      'M' + (this.x1 + consts.thickness),
      (this.y1 + consts.thickness),
      'v' + (this.height - 2 * consts.thickness),
      'h' + (this.width - 2 * consts.thickness),
      'v' + (-(this.height - 2 * consts.thickness)) + 'z',
    ].join(' ');
  }
}
