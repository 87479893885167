import CreateCompany from '@/modules/settings/CreateCompany.vue';
import { translate } from '@/i18n';

export default {
  path: 'create-company',
  name: 'create-company',
  component: CreateCompany,
  displayName: 'settings-sidebar.create-company',
  meta: {
    introClass: 'intro-settings',
    permission: 'CreateCompany'
  }
};
