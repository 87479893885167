import LodgeCards from '@/modules/settings/lodge-cards/LodgeCards.vue';
import LodgeCardConfigurations from './lodge-card-configurations.route';
import LodgeCardConfiguration from './lodge-card-configuration.route';
import LodgeCard from './lodge-card.route';
import { translate } from '@/i18n';

export default {
  path: 'lodge-cards',
  component: LodgeCards,
  displayName: 'settings-sidebar.lodge-cards',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyPaymentCards',
    breadCrumb: 'Company payment cards configuration',
    parentName: 'lodge-cards-configurations',
    icon: 'credit_card',
  },
  children: [
    LodgeCardConfigurations,
    LodgeCardConfiguration,
    LodgeCard,
  ],
};