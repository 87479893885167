import PluginsConfiguration from '@/modules/settings/plugins/PluginsConfiguration.vue';

export default {
  path: ':configurationId?/plugins-configuration',
  name: 'plugins-configuration',
  component: PluginsConfiguration,
  displayName: 'Plugins Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditPlugins',
    parentName: 'plugins-configurations',
    depthCategory: 'plugins-configurations',
    depth: 2,
  }
};