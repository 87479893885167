
export class AgencyMarkupConfiguration {
  configurationId: string = '';
  name?: string = '';
  isFeeAdjustableToTravelPolicy: boolean = false;
  isEnabled: boolean = true;
  feesRules: AgencyMarkupRule[] = [];

  constructor(data?: any) {
    if (data) {
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.isFeeAdjustableToTravelPolicy !== undefined) {
        this.isFeeAdjustableToTravelPolicy = data.isFeeAdjustableToTravelPolicy;
      }
      if (data.isEnabled !== undefined) {
        this.isEnabled = data.isEnabled;
      }
      if (data.feesRules !== undefined) {
        this.feesRules = data.feesRules;
      }
    }
  }
}

export interface AgencyMarkupConfigurationResult {
  configurationId: string;
  succeeded: boolean;
  error: Error;
}

export interface AgencyMarkupRule {
  id?: any;
  status?: any;
  order: number;
  feeType: AgencyMarkupTypeEnum;
  fixedFee: {
    amount: number;
    currency: Currency;
  };
  percentageFee: number;
  calculationMethod: AgencyMarkupCalculationMethodEnum;
  paymentMethod: string;
  isLodgeCardInformationSelected: boolean;
  supplier: string[];
  airlines: string[];
  tripTypes: string[];
  fareTypes: string[];
  fareBasisCodes: string[];
}

export interface Currency {
  code: string;
  symbol: string | null;
}

export interface AgencyMarkupConfigData {
  name?: string;
  id?: string;
  isFeeAdjustableToTravelPolicy: boolean;
  isEnabled: boolean;
  feesRules: AgencyMarkupRule[];
}

export interface AgencyMarkupConfig {
  rootCompanyId?: string;
  data: AgencyMarkupConfigData;
}

export interface Error {
  code: string;
  message: string;
  messageEng: string;
  serviceTag: string;
  details: Detail[];
  devDetails: DevDetails;
  isTranslated: boolean;
  translationLanguage: string;
}

export interface DevDetails {
  extendedInfo: any;
  message: string;
  stacktrace: string;
  serverErrorSource: string;
  innerException: string;
}

export interface Detail {
  code: string;
  message: string;
  messageEng: string;
  field: string;
  params: Params;
  resourceId: string;
  isTranslated: boolean;
  translationLanguage: string;
}

export interface Params {
  additionalProp1: any;
  additionalProp2: any;
  additionalProp3: any;
}

export enum AgencyMarkupTypeEnum {
  Fixed = 'Fixed',
  Percentage = 'Percentage',
}

export enum AgencyMarkupCalculationMethodEnum {
  PerBooking = 'PerBooking',
  PerPassengerInBooking = 'PerPassengerInBooking',
  PerLeg = 'PerLeg',
  PerPassengerInLeg = 'PerPassengerInLeg',
}
