export class CustomFieldExpenseModel {
  id: string = '';
  expenseExstensionId: string = '';
  isActive: boolean = false;
  policyId: string = '';
  expenseType: string = '';
  conditions: Condition[] = [
    {
      expenseState: 'Claimed',
      isRequired: false,
      index: 1
    },
    {
      expenseState: 'Cancelled',
      isRequired: false,
      index: 6
    },
    {
      expenseState: 'Closed',
      isRequired: false,
      index: 5
    },
    {
      expenseState: 'Rejected',
      isRequired: false,
      index: 4
    },
    {
      expenseState: 'Draft',
      isRequired: false,
      index: 0
    },
    {
      expenseState: 'Violation',
      isRequired: false,
      index: 2
    },
    {
      expenseState: 'Completed',
      isRequired: false,
      index: 3
    }
  ];
  customFieldDefinition?: any = '';
  customFieldDefinitionId: string = '';
  order: number | null = null;
  isDictionaryType: boolean = false;

  public constructor(params?: any) {
    if (!params) {
      return;
    }

    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.expenseExstensionId !== undefined) {
      this.expenseExstensionId = params.expenseExstensionId;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }    
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.expenseType !== undefined) {
      this.expenseType = params.expenseType;
    }
    if (params.conditions !== undefined) {
      this.conditions.forEach(c => {
        params.conditions.forEach(p => {
          if (c.expenseState === p.expenseState) {
            c.isRequired = p.isRequired;
          }
        });
      });
    }
    if (params.customFieldDefinition !== undefined) {
      this.customFieldDefinition = params.customFieldDefinition;
    }
    if (params.customFieldDefinitionId !== undefined) {
      this.customFieldDefinitionId = params.customFieldDefinitionId;
    }
    if (params.order !== undefined) {
      this.order = params.order;
    }
    if (params.isDictionaryType !== undefined) {
      this.isDictionaryType = params.isDictionaryType;
    }
  }
}

export interface CustomFieldExpenseCreateModel {
  policyId: string;
  expenseType: string;
  conditions: Condition[];
  customFieldDefinitionId: string;
  order: number;
  isActive: boolean;
  isDictionaryType: boolean;
}

export interface CustomFieldExpenseUpdateModel {
  expenseType: string;
  conditions: Condition[];
  customFieldDefinitionId: string;
  order: number;
  isActive: boolean;
  isDictionaryType: boolean;
}

export interface Condition {
  expenseState: string;
  isRequired: boolean;
  index?: number;
}