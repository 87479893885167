export class ProfileSyncConfigurationEntry {
  profileSynchronizationProvider!: string;
  enabled!: boolean;
  secretKey: string | null = null;
}

export class ProfileSyncConfigurationData {
  name?: string = '';
  rootCompanyId?: string = '';
  providersConfigurationData: ProfileSyncConfigurationEntry[] = [];
}

export class ProfileSyncConfiguration {
  id?: string = '';
  configurationData: ProfileSyncConfigurationData = new ProfileSyncConfigurationData();

  constructor(data?: any) {
    if (!data) {
      return;
    }

    if (data.configurationData !== undefined) {
      this.configurationData = data.configurationData;
    }
    if (data.id !== undefined) {
      this.id = data.id;
    }
  }
}

export enum ProfileSyncStatusEnum {
  Pending = 'Pending',
  Failed = 'Failed',
  Success = 'Success',
}

export interface ProfileSyncStatus {
  id: string;
  profileId: string;
  acknowledgeDate: string | null;
  creationDate: string;
  provider: string;
  status: ProfileSyncStatusEnum;
  errorMessage: string;
}
