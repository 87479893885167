


















































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import { VueContentLoading } from 'vue-content-loading';
import { LegDetail } from '@/api/air-engine/offers.model';

@Component({
  components: {
    VueContentLoading,
  }
})
export default class AirBritishAirwaysShortOfferDetails extends Vue {
  @Prop() offerDetails!: LegDetail;
  @Prop() flightType!: string;
  @Prop() flightCarrierCode!: string;
  @Prop() flightNumber!: string;


  penalty() {
    if (!this.offerDetails || !this.offerDetails.segments || this.offerDetails.error) {
      return null;
    }
    if (this.flightType === 'Stop') {
      return null;
    }
    const flightDetails = this.offerDetails.segments.find((segment) => segment.carrierCode === this.flightCarrierCode && segment.flightNumber === this.flightNumber);

    if (!flightDetails) {
      return null;
    }

    return flightDetails.penalty;
  }

  getPenaltyText(penaltyPrice, ischange) {
    if (!penaltyPrice) {
      return translate('search-air.not-allowed');
    }
    if (penaltyPrice.isPotentiallyIncorrect) {
      return translate('search-air.check-full-fare-rates');
    }
    if (!penaltyPrice.isAllowed) {
      return translate('search-air.not-allowed');
    }

    if (!penaltyPrice.isChargeable) {
      if (ischange) {
        return translate('search-air.no-charge-modifica');
      } else {
        return translate('search-air.no-charge-refund');
      }
    }

    return translate('search-air.allowed');
  }

}

