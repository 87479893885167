import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  CurrencyExchangeConfiguration,
} from './currency-exchange.model';


class CurrencyExchangeApiClass {
  public getConfiguration(configurationId: string): Promise<AxiosResponse<CurrencyExchangeConfiguration>> {
    return http.execute({
      path: settings.apiProfile + '/api/currency-configurations/{configurationId}',
      method: RequestMethod.GET
    }, { configurationId }, {});
  }

  public createConfiguration(params: CurrencyExchangeConfiguration): Promise<AxiosResponse<{ configurationId: string }>> {
    return http.execute({
      path: settings.apiProfile + '/api/currency-configurations',
      method: RequestMethod.POST,
    }, {}, params);
  }

  public updateConfiguration(configurationId: string, params: CurrencyExchangeConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/currency-configurations/{configurationId}',
      method: RequestMethod.PUT
    }, { configurationId }, params);
  }
}

export const CurrencyExchangeApi: CurrencyExchangeApiClass = new CurrencyExchangeApiClass();