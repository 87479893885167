import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { TravelPolicyApi } from '@/api/travel-policy/travel-policy.api';
import { AccomodationRuleConfigurationCreateModel, AccomodationRuleCreateModel } from '@/api/travel-policy/travel-policy.model';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import _ from 'lodash';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'travelPolicyAccomodation'
})
class TravelPolicyAccomodationStore extends VuexModule {
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow ;
  travelPolicy: AccomodationRuleConfigurationCreateModel = {
    configurationName: '',
    rootCompanyId: '',
    accomodationRules: [],
    hideNonCompliantRates: false,
    isPostSalesEnabledInNonCompliantBooking: false,
  };
  currentSelectedRules: AccomodationRuleCreateModel | null = null;
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  get getAccomodationTravelPolicy() {
    return this.travelPolicy;
  }

  get getAccomodationRules() {
    return this.travelPolicy.accomodationRules;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get currentAccomodationRules() {
    return this.currentSelectedRules;
  }

  @Mutation
  selectedAccomodationRule(payload) {
    this.currentSelectedRules = payload;
  }

  @Mutation
  public SetCurrentConfiguration(currentConfiguration: ConfigurationRow | null) {
    this.currentConfiguration = currentConfiguration!;
  }

  @Mutation
  clearAccomodationRules() {
    this.travelPolicy = {
      configurationName: '',
      rootCompanyId: '',
      accomodationRules: [],
      hideNonCompliantRates: false,
      isPostSalesEnabledInNonCompliantBooking: false,
    };
  }

  @Mutation
  setHideNonCompliantRates(value) {
    this.travelPolicy.hideNonCompliantRates = value;
  }

  @Mutation
  setIsPostSalesEnabledInNonCompliantBooking(value) {
    this.travelPolicy.isPostSalesEnabledInNonCompliantBooking = value;
  }
 
  @Mutation
  setAccomodationRules(payload) {
    this.travelPolicy.accomodationRules = payload;
  }

  @Mutation
  addAccomodationRule(payload) {
    if (this.travelPolicy.accomodationRules) {
      this.travelPolicy.accomodationRules.push(payload);
    }
  }

  @Mutation
  editAccomodationRule(payload) {
    let index = _.findIndex(this.travelPolicy.accomodationRules, fr => fr.id === payload.id);
    if (index >= -1) {
      this.travelPolicy.accomodationRules[index] = payload;
    }
  }

  @Mutation
  addName(payload) {
    this.travelPolicy.configurationName = payload;
  }

  @Action
  async getRules(currentSelectedId) {
    try {
      TravelPolicyStore.clearErrors();

      const result = await TravelPolicyApi.getAccomodationRules(currentSelectedId);
      if (result && result.data) {
        this.addName(result.data.configurationName);
        TravelPolicyStore.setCurrentConfigName(result.data.configurationName);
        this.setAccomodationRules(result.data.accomodationRules);
        this.setHideNonCompliantRates(result.data.hideNonCompliantRates);
        this.setIsPostSalesEnabledInNonCompliantBooking(result.data.isPostSalesEnabledInNonCompliantBooking);
        TravelPolicyStore.setLoading(false);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        TravelPolicyStore.setCustomError(true);
      } else {
        TravelPolicyStore.setErrors(error);
      }
    }
  }

  @Action
  async getAccomodationRulesConfiguration(currentSelectedId) {
    TravelPolicyStore.setLoading(true);
    if (currentSelectedId) {
      if (this.travelPolicy.accomodationRules && this.travelPolicy.accomodationRules.length) {
        this.setAccomodationRules(this.travelPolicy.accomodationRules);
      } else {
        await this.getRules(currentSelectedId);
      }
    } else {
      TravelPolicyStore.setLoading(false);
    }
  }
}

export default getModule(TravelPolicyAccomodationStore);