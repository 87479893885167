




















import { Vue, Component } from 'vue-property-decorator';
import _ from 'lodash';
import BasketStore from './basket.store';
import { getPayThreeDSConnector } from '@/plugins/pay-three-ds-connector';
import EventBus from '@/services/event-handler';

@Component
export default class BasketExpedia3DSView extends Vue {
  loading: boolean = true;
  connector: any = null;
  version: string | null = null;
  encodedBrowserMetadata:  any = null;
  stopProcess: boolean = false;
  paymentSessionId: string | null = null;

  get pendingAction() {
    return BasketStore.basketItemPendingActionCode;
  }

  get loadErrors() {
    return BasketStore.errors;
  }

  get basketStatus() {
    return BasketStore.status;
  }

  get expedia3DSTripItemId() {
    return BasketStore.expedia3DSTripItemId;
  }

  get basketItems() {
    return BasketStore.basketItemsMapped;
  }

  get basketStatusItems() {
    if (!this.basketStatus) {
      return [];
    }
    return this.basketStatus.items.map(item => {
      const object = this.basketItems.find(i => i.id === item.id);

      if (object && object.bookingErrors && object.bookingErrors.length) {
        object.bookingErrors.forEach(element => {
          element.isOpen = false;
        });

        object.groupeErrors = _.groupBy(object.bookingErrors, 'errorLevel');
      }

      return {
        ...item,
        object,
      };
    });
  }

  get item() {
    return this.basketStatusItems.find(i => i.id === this.expedia3DSTripItemId);
  }

  get expedia3DSUrl() {
    return BasketStore.expedia3DSUrl;
  }

  get expedia3DSSandbox() {
    return BasketStore.expedia3DSSandbox;
  }

  mounted() {
    EventBus.$on('3DS-init-payment-session', this.initPaymentSession);
    EventBus.$on('3DS-challenge', this.challenge);
  }

  setup() {
    if (this.item) {
      const action = this.item.pendingActions.find(i => i.code === 'EXP_SETUP_3DS');
      const id = 'threedsiframe' + this.expedia3DSTripItemId;

      this.connector = getPayThreeDSConnector( id, 'https://static.pay.expedia.com');

      const rndInt = action!.additionalData.ApiKey + '_' + this.expedia3DSTripItemId;
      this.connector.setup({referenceId: rndInt}).then(setupResponse => {
        this.version = setupResponse.version;
        this.encodedBrowserMetadata = setupResponse.encodedBrowserMetadata;
          
        const availableResponseAnswer = {
          tripItemId: this.expedia3DSTripItemId,
          actionCode: this.pendingAction,
          answer: {
            selectedAnswer: this.pendingAction,
            data: {
              browserVersion: this.version,
              encodedBrowserMetadata: this.encodedBrowserMetadata,
            }
          }
        };
        BasketStore.sendTripItemAvailableResponseAnswer(availableResponseAnswer);
      });   
    }
  }

  initPaymentSession() {
    if (this.item) {
      const action = this.item.pendingActions.find(i => i.code === 'EXP_INIT_PAYMENT_SESSION');
      this.paymentSessionId = action!.additionalData.PaymentSessionId;
    
      this.connector.initSession({
        paymentSessionId: action!.additionalData.PaymentSessionId,
        encodedInitConfig: action!.additionalData.EncodedInitConfig,
      }).then(initSessionResponse => {
        const initSessionObject = initSessionResponse;
 
        const availableResponseAnswer = {
          tripItemId: this.expedia3DSTripItemId,
          actionCode: this.pendingAction,
          answer: {
            selectedAnswer: action!.availableResponses[0],
            data: {
              message: initSessionObject.message,
              statusCode: initSessionObject.statusCode
            }
          }
        };

        BasketStore.sendTripItemAvailableResponseAnswer(availableResponseAnswer);
      });
    }
  }

  challenge() {
    if (this.item) {
      this.loading = false;     
      const action = this.item.pendingActions.find(i => i.code === 'EXP_CHALLENGE');

      this.connector.challenge({
        paymentSessionId: this.paymentSessionId,
        encodedChallengeConfig: action!.additionalData.EncodedChallengeConfig
      }).then(response => {
        const challengeObject = response; 

        let selectedAnswerCode;
        if (challengeObject.statusCode === 'SUCCESS') {
          selectedAnswerCode = action!.availableResponses[0];
        } else {
          selectedAnswerCode = action!.availableResponses[1];
        }

        const availableResponseAnswer = {
          tripItemId: this.expedia3DSTripItemId,
          actionCode: this.pendingAction,
          answer: {
            selectedAnswer: selectedAnswerCode,
            data: {
              message: challengeObject.message,
              statusCode: challengeObject.statusCode
            }
          }
        };

        BasketStore.sendTripItemAvailableResponseAnswer(availableResponseAnswer);
        BasketStore.setExpedia3DSTripItemId('');
        BasketStore.setShowBasketExpedia3DSPopup(false);
      });
    }
  }

  beforeDestroy() {
    EventBus.$off('3DS-init-payment-session', this.initPaymentSession);
    EventBus.$off('3DS-challenge', this.challenge);
  }
}

