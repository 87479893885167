export class PerDiemSettingsResult {
  isEnabled: boolean = false;
  defaultRate: number = 0;
  rulesOrder: string = 'ReductionReasonFirst';

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.isEnabled !== undefined) {
      this.isEnabled = params.isEnabled;
    }
    if (params.defaultRate !== undefined) {
      this.defaultRate = params.defaultRate;
    }
    if (params.rulesOrder !== undefined) {
      this.rulesOrder = params.rulesOrder;
    }
  }
}

export class PerDiemRatesResult {
  id: string = '';
  policyId: string = '';
  validFrom: ValidFrom | null = null;
  validTo: ValidFrom | null = null;
  rate: Rate | null = null;
  location: string = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.validFrom !== undefined) {
      this.validFrom = params.validFrom;
    }
    if (params.validTo !== undefined) {
      this.validTo = params.validTo;
    }
    if (params.rate !== undefined) {
      this.rate = params.rate;
    }
    if (params.location !== undefined) {
      this.location = params.location;
    }
  }
}

export interface Rate {
  amount: number;
  currency: Currency;
}

export interface Currency {
  code: string;
  symbol: string;
}

export interface ValidFrom {
  day: number;
  month: number;
}

export class LengthOfStayPerDiemRuleResult {
  minPerDiemHours: number = 0;
  maxPerDiemHours: number = 0;
  id: string = '';
  policyId: string = '';
  name: string = '';
  description: string = '';
  isActive: boolean = false;
  reductionPercent: number = 0;
  fixedReductionAmount: number = 0;

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.minPerDiemHours !== undefined) {
      this.minPerDiemHours = params.minPerDiemHours;
    }
    if (params.maxPerDiemHours !== undefined) {
      this.maxPerDiemHours = params.maxPerDiemHours;
    }
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
    if (params.reductionPercent !== undefined) {
      this.reductionPercent = params.reductionPercent;
    }
    if (params.fixedReductionAmount !== undefined) {
      this.fixedReductionAmount = params.fixedReductionAmount;
    }
  }
}

export class ReductionReasonPerDiemRulesResult {
  reductionReason: string = '';
  id: string = '';
  policyId: string = '';
  name: string = '';
  description: string = '';
  isActive: boolean = false;
  reductionPercent: number = 0;
  fixedReductionAmount: number = 0;


  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.reductionReason !== undefined) {
      this.reductionReason = params.reductionReason;
    }
    if (params.policyId !== undefined) {
      this.policyId = params.policyId;
    }
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
    if (params.reductionPercent !== undefined) {
      this.reductionPercent = params.reductionPercent;
    }
    if (params.fixedReductionAmount !== undefined) {
      this.fixedReductionAmount = params.fixedReductionAmount;
    }
  }
}