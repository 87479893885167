import NegoFareAirCodeForm from '@/modules/settings/nego-fare/NegoFareAirCodeForm.vue';

export default {
  path: ':configurationId?/nego-fare-air-code',
  name: 'air-corporate-code',
  component: NegoFareAirCodeForm,
  displayName: 'Air Nego Fare Corporate Code',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadNegoFare',
    depthCategory: 'nego-fare',
    waitForDicts: true,
    depth: 3,
    parentName: 'nego-fare-configurations',
  },
};