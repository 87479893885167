import CompanyStructureConfigurations from '@/modules/settings/company-structure/CompanyStructureConfigurations.vue';

export default {
  path: '',
  name: 'company-structure-configurations',
  component: CompanyStructureConfigurations,
  displayName: 'Company Structure Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCompanyStructure',
    depthCategory: 'company-structure',
    depth: 1,
    parentName: 'company-structure',
  }
};