import VirtualCardsConfiguration from '@/modules/settings/virtual-cards/VirtualCardsConfiguration.vue';

export default {
  path: ':configurationId?/configuration',
  name: 'virtual-cards-configuration',
  component: VirtualCardsConfiguration,
  displayName: 'Virtual cards Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditVirtualCards',
    parentName: 'virtual-cards-configurations',
    depthCategory: 'virtual-cards-configurations',
    depth: 2,
  }
};