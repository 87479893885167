






































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import AccountStore from '@/store/account.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from './basket.store';
import { TripApi } from '@/api/trip/trip.api';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import { ContactsApi } from '@/api/profile/contacts.api';
import { router } from '@/router';
import { translate } from '@/i18n';
import EventHandler from '@/services/event-handler';

const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

@Component({})
export default class BasketItineraryEmailPopup extends Vue {
  $v;
  errors: any[] = [];
  travellersList: any[] = [];
  travellersListOption: any[] = [];
  isTravellersLoading: boolean = false;
  isBookerLoading: boolean = false;
  bookerListOption: any[] = [];
  queryTo: string = '';
  queryCc: string = '';
  saveLoading: boolean = false;
  languageOptions: LanguagesModel[] = [];

  @Validation()
  validationObject() {
    return {
      model: {
        emailTo: {
          required,
        },
        emailCc: {
        },
        title: {
          required
        },
        content: {},
        language: {
          required
        },
      }
    };
  }

  model: any = {
    emailTo: [],
    emailCc: [],
    title: this.customTitle,
    content: '',
    language: null
  };

  get user() {
    return AccountStore.current;
  }

  get basketId() {
    return router.currentRoute.params.id;
  }

  get bookerList() {
    return [{...this.user!.profile}] || [];
  }

  get errCompareMessages() {
    return AirSearchStore.errCompareMessages;
  }

  get basket() {
    return BasketStore.basket;
  }

  async loadLanguages() {
    try {
      const result = await LanguagesApi.getLanguages();

      if (result && result.data) {
        this.languageOptions = result.data;
      }

    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
  }

  addTag(newTag) {
    this.travellersListOption.push(newTag);
  }

  async asyncFind(query: string) {
    if (query && query.length > 1) {
      this.isTravellersLoading = true;
      query = query.toLowerCase();
      this.queryTo = query;

      if (this.travellersList) {
        const response = this.travellersList.filter((traveller: any) => traveller.email.toLowerCase().includes(query));

        this.travellersListOption = [];

        if (response) {
          response.forEach((element) => {
            this.travellersListOption.push(element);
          });
        }
      }

      this.isTravellersLoading = false;
    } else {
      this.isTravellersLoading = false;
      this.travellersListOption = [];
    }
  }

  async asyncFindBooker(query: string) {
    if (query && query.length > 1) {
      this.isBookerLoading = true;
      query = query.toLowerCase();
      this.queryCc = query;

      if (this.bookerList) {
        const response = this.bookerList.filter((traveller: any) => traveller.primaryEmail.toLowerCase().includes(query));

        this.bookerListOption = [];

        if (response) {
          response.forEach((element) => {
            this.bookerListOption.push(element as any);
          });
        }
      }

      this.isBookerLoading = false;
    } else {
      this.isBookerLoading = false;
      this.bookerListOption = [];
    }
  }

  checkMultiToQuery() {
    let isMoreThenOne = this.queryTo.indexOf(';');
    if (isMoreThenOne) {
      let emailList = this.queryTo.split(';');
      if (emailList && emailList.length) {
        emailList.forEach(data => {
          if (this.validateEmail(data) && -1 === this.model.emailTo.map(emailTo => emailTo.email).indexOf(data)) {
            this.model.emailTo.push({email: data});
          }
        });
      }
      this.queryTo = '';
      this.$forceUpdate();
      this.focusOnButton();
    }
  }

  manualValueTo() {
    if (this.queryTo && -1 === this.model.emailTo.map(to => to.email).indexOf(this.queryTo)) {
      if (this.validateEmail(this.queryTo)) {
        this.model.emailTo.push({email: this.queryTo});
        this.queryTo = '';
        this.focusOnButton();            
        this.$forceUpdate();
      } else {
        this.checkMultiToQuery();
      }
    }
  }

  checkMultiCcQuery() {
    let isMoreThenOne = this.queryCc.indexOf(';');
    if (isMoreThenOne) {
      let emailList = this.queryCc.split(';');
      if (emailList && emailList.length) {
        emailList.forEach(data => {
          if (this.validateEmail(data) && -1 === this.model.emailCc.map(emailCc => emailCc.email).indexOf(data)) {
            this.model.emailCc.push({email: data});
          }
        });
      }
      this.queryCc = '';
      this.$forceUpdate();
      this.focusOnButton();
    }
  }

  manualValueCc() {
    if (this.queryCc && -1 === this.model.emailCc.map(cc => cc.email).indexOf(this.queryCc)) {
      if (this.validateEmail(this.queryCc)) {
        this.model.emailCc.push({email: this.queryCc});
        this.queryCc = '';
        this.focusOnButton();
        this.$forceUpdate();
      } else {
        this.checkMultiCcQuery();
      }
    }
  }

  validateEmail(data) {
    return emailRegex.test(data);
  }

  backToResults() {
    this.resetModel();
  }

  focusOnButton() {
    ((this.$refs.sendButton as Vue).$el as HTMLElement).focus();
  }

  async sendOffers() {
    this.$v.model.$touch();
    if (this.$v.model.$pending || this.$v.model.$error) {
      return;
    }
    this.saveLoading = true;
    let profileIds: any[] = [];
    let emails: any[] = [];

    if (this.model.emailTo && this.model.emailTo.length) {
      this.model.emailTo.forEach(emailTo => {
        if (emailTo.id) {
          profileIds.push(emailTo.id);
        } else {
          emails.push(emailTo.email);
        }
      });
    }

    let request = {
      profileIds,
      emails,
      title: this.model.title,
      body: this.model.content,
      language: this.model.language ? this.model.language.code : 'en',
      emailCopies: this.model.emailCc.map(emailCc => emailCc.email ? emailCc.email : emailCc.primaryEmail),
    };

    try {
      await TripApi.createItineraryEmail(this.basketId, request);
      this.saveLoading = false;
      this.tooltipInfo();
      this.resetModel();
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.saveLoading = false;
    }
  }

  tooltipInfo() {
    let obj = {
      type: translate('common.success'),
      message: translate('search-air.email-sent')
    };
    EventHandler.$emit('show-toast', obj);
  }

  resetModel() {
    this.model = {
      emailTo: '',
      emailCc: '',
      title: this.customTitle,
      content: '',
      language: null,
    };
    this.$emit('close');
  }

  updateEmailsData() {
    if (this.user) {
      if (this.travellersList.length) {
        if (-1 === this.travellersList.map(list => list.email).indexOf(this.user.profile.primaryEmail) ) {
          this.model.emailCc = this.bookerList;
        }
      } else {
        this.model.emailCc = this.bookerList;
      }
    }
  }

  async updateView() {
    AirSearchStore.setLoaderCompare(true);
    await this.loadLanguages();
    this.model.title = this.customTitle;
    this.travellersList = [];
    if (this.user && this.user.profile && this.user.profile.displayLanguage && this.languageOptions && this.languageOptions.length) {
      this.model.language = this.languageOptions.find(lang => lang.code === this.user!.profile.displayLanguage);
    }
    if (this.basket && this.basket.travellers && this.basket.travellers.length) {
      this.basket.travellers.forEach(async traveller => {
        const contacts = await ContactsApi.getContacts(traveller.id);
        if (contacts && contacts.data) {
          this.travellersList.push({
            ...traveller,
            email: contacts.data.primary.email,
          });
          this.model.emailTo = [...this.travellersList];
        }
      });
      this.updateEmailsData();
    }
  }

  get customTitle() {
    if (!this.basket) {
      return '';
    }
    return this.basket.name;
  }

  created() {
    this.updateView();
  }

  @Watch('route')
  onRouteChange() {
    this.updateView();
  }
}
