




































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import settings from '@/settings';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { TripApi } from '@/api/trip/trip.api';
import { TripsTableParams } from './trips-table.params';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileFullNameModel, TravellerModel } from '@/api/profile/profile.model';
import {
  SearchTripPeriod,
  TripStatus,
  BasketItemType,
  SearchTrip,
  SearchTripsSortOnEnum,
  SearchTripsSortDirectionEnum
} from '@/api/trip/trip.model';
import { translate } from '@/i18n';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import searchConst from '@/const/search.const';
import { Permission } from '@/const/permission.enum';
import AccountStore from '@/store/account.store';
import { AvailableDateFormats } from '@/api/profile/company.model';
import ExportConfirmationPopup from './ExportConfirmationPopup.vue';

@Component({
  components: {
    HeightTransition,
    ExportConfirmationPopup,
  },
})
export default class TripsTable extends BaseTable<SearchTrip> {
  useQueryParams: boolean = true;
  showFilters: boolean = false;
  params: TripsTableParams = new TripsTableParams({});
  toggleSortOptions: boolean = false;
  showExportConfirmation: boolean = false;
  exportInProgress: boolean = false;

  fields = {
    travellerName: {
      label: translate('trips-table.traveller-name'),
      class: 'trips-table__column--traveller-name',
      tdClass: 'mw-250',
    },
    arrangedBy: {
      label: translate('trips-table.arranged-by'),
      class: 'trips-table__column--arranged-by',
      tdClass: 'mw-200',
    },
    tripName: {
      label: translate('trips-table.trip-name'),
      class: 'trips-table__column--trip-name',
      tdClass: 'mw-200',
    },
    createdDate: { 
      label: translate('trips-table.created-date'),
      class: 'trips-table__column--created-date',
      tdClass: 'mw-200',
    },
    dates: {
      label: translate('trips-table.dates'),
      class: 'trips-table__column--dates',
      tdClass: 'mw-200',
    },
    status: {
      label: translate('trips-table.status'),
      class: 'trips-table__column--status',
      tdClass: 'mw-200',
    },
    totalPrice: {
      label: translate('trips-table.total-price'),
      class: 'trips-table__column--total-price',
      tdClass: 'mw-200',
    },
    actionsColumn: {
      label: '',
      class: 'trips-table__column--actions',
    },
  };
  periods = [
    {
      label: translate('trips-table.all'),
      value: SearchTripPeriod.All,
    },
    {
      label: translate('trips-table.past'),
      value: SearchTripPeriod.Past,
    },
    {
      label: translate('trips-table.current'),
      value: SearchTripPeriod.Current,
    },
    {
      label: translate('trips-table.upcoming'),
      value: SearchTripPeriod.Upcoming,
    }
  ];
  defaultPeriod = SearchTripPeriod.All;
  tripStatuses = [
    {
      label: translate('trips-filters.draft'),
      value: TripStatus.Draft,
    },
    {
      label: translate('trips-table.held'),
      value: TripStatus.Held,
    },
    {
      label: translate('trips-filters.confirmed'),
      value: TripStatus.Confirmed,
    },
    {
      label: translate('trips-filters.cancelled'),
      value: TripStatus.Cancelled,
    },
    {
      label: translate('trips-filters.desynchronized'),
      value: TripStatus.Desynchronized,
    },
    {
      label: translate('trips-filters.pendingApproval'),
      value: TripStatus.PendingApproval,
    },
    {
      label: translate('trips-filters.agencySupportRequired'),
      value: TripStatus.AgencySupportRequired,
    },
    {
      label: translate('trips-filters.userActionRequired'),
      value: TripStatus.UserActionRequired,
    },
     {
      label: translate('trips-filters.delayedTicketing'),
      value: TripStatus.DelayedTicketing,
    },
  ];
  serviceTypes = [
    {
      label: translate('trips-filters.air'),
      value: BasketItemType.Air,
    },
    {
      label: translate('trips-filters.rail'),
      value: BasketItemType.Rail,
    },
    {
      label: translate('trips-filters.accommodation'),
      value: BasketItemType.Accommodation,
    },
    {
      label: translate('trips-filters.car'),
      value: BasketItemType.Car,
    },
  ];
  sortDirectionOptions = [
    {
      label: translate('trips-table.sort-direction-ascending'),
      value: SearchTripsSortDirectionEnum.Ascending,
    },
    {
      label: translate('trips-table.sort-direction-descending'),
      value: SearchTripsSortDirectionEnum.Descending,
    },
  ];
  sortOnOptions = [
    {
      label: translate('trips-table.sort-on-create-date'),
      value: SearchTripsSortOnEnum.CreateDate,
    },
    {
      label: translate('trips-table.sort-on-start-date'),
      value: SearchTripsSortOnEnum.StartDate,
    },
  ];

  arrangers: TravellerModel[] = [];
  arrangersLoading: boolean = false;
  travellers: TravellerModel[] = [];
  travellersLoading: boolean = false;
  companies: string = '';
  errors: any[] = [];
  usersErrors: any[] = [];
  shouldShowMoreFilters: boolean = false;
  locations: any[] = [];
  locationsLoading: boolean = false;
  locationsErrors: any[] = [];
  locationsQueryLength: number = 0;

  get fromDateRange() {
    return {
      start: this.params.startDate.from ? moment(this.params.startDate.from).toDate() : null,
      end: this.params.startDate.to ? moment(this.params.startDate.to).toDate() : null,
    };
  }

  set fromDateRange(val) {
    if (val && val.start && val.end) {
      this.params.startDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.startDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.startDate.from = null;
      this.params.startDate.to = null;
    }
  }

  get toDateRange() {
    return {
      start: this.params.endDate.from ? moment(this.params.endDate.from).toDate() : null,
      end: this.params.endDate.to ? moment(this.params.endDate.to).toDate() : null,
    };
  }

  set toDateRange(val) {
    if (val && val.start && val.end) {
      this.params.endDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.endDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.endDate.from = null;
      this.params.endDate.to = null;
    }
  }

  get createDateRange() {
    return {
      start: this.params.createDate.from ? moment(this.params.createDate.from).toDate() : null,
      end: this.params.createDate.to ? moment(this.params.createDate.to).toDate() : null,
    };
  }

  set createDateRange(val) {
    if (val && val.start && val.end) {
      this.params.createDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.createDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.createDate.from = null;
      this.params.createDate.to = null;
    }
  }

  get periodModel() {
    return this.periods.find(item => this.params.period === item.value);
  }

  set periodModel(data) {
    if (data) {
      this.params.period = data.value;
    }
  }

  get sortOnModel() {
    return this.sortOnOptions.find(item => this.params.sortOnField === item.value);
  }

  set sortOnModel(data) {
    if (data) {
      this.params.sortOnField = data.value;
    }
  }

  get sortDirectionModel() {
    return this.sortDirectionOptions.find(item => this.params.sortDirection === item.value);
  }

  set sortDirectionModel(data) {
    if (data) {
      this.params.sortDirection = data.value;
    }
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }



  userFullName(user) {
    return userFullName(user);
  }

  displayDate(startDate, endDate) {
    if (moment(startDate).isSame(moment(endDate), 'day')) {
      return moment(endDate).format(this.currentDateFormat);
    } else {
      return moment(startDate).format(this.currentDateFormat) + ' - ' + moment(endDate).format(this.currentDateFormat);
    }
  }

  getTravLabel(guestCode) {
    let option = searchConst.guestTravellerOptions.find(opt => {
      return opt.code === guestCode;
    });
    if (option) {
      return `${translate('search.guest')} (${translate(option.label)})`;
    } else {
      return translate('search.guest');
    }
  }

  bookerTooltip(bookerData) {
    let tooltip = `
      <span>${userFullName(bookerData)}</span>
    `;
    if (bookerData.businessUnitName) {
      tooltip += `<span>${bookerData.businessUnitName}</span>`;
    }
    return tooltip;
  }

  @Watch('sortOnModel')
  onSortOnChanged() {
    this.resetList(this.currentPage);
    this.reload();
  }

  @Watch('sortDirectionModel')
  onSortDirectionChanged() {
    this.resetList(this.currentPage);
    this.reload();
  }

  mapDestinationObject(destinationObject) {
    if (destinationObject && destinationObject.cityCode) {
      return destinationObject.cityCode;
    } else if (destinationObject && destinationObject.iataCode) {
      return destinationObject.iataCode;
    } else if (destinationObject && !destinationObject.cityCode && !destinationObject.iataCode) {
      return destinationObject;
    } else {
      return undefined;
    }
  }

  async fillParameters(query) {
    this.params.period = query.period || this.defaultPeriod;
    this.params.destination = this.locations.find(location => {
      return location.cityCode === query.destination;
    });
    if (!this.params.destination && query.destination) {
      const destination = await DictionaryApi.getCity(query.destination);
      if (destination && destination.data) {
        this.params.destination = {
          type: 'City',
          cityId: destination.data.id,
          cityName: destination.data.name,
          countryCode: destination.data.countryCode,
          countryName: destination.data.countryName,
          cityCode: destination.data.iataCode,
        };
      }
    } else if (!this.params.destination) {
      this.params.destination = '';
    }
    this.params.startDate = {
      from: query.startDateFrom,
      to: query.startDateTo,
    };
    this.params.endDate = {
      from: query.endDateFrom,
      to: query.endDateTo,
    };
    this.params.createDate = {
      from: query.createDateFrom,
      to: query.createDateTo,
    };
    this.params.tripCode = query.tripCode;

    if (query.basketStatuses) {
      let statuses = query.basketStatuses;
      if (!(statuses instanceof Array)) {
        statuses = [statuses];
      }
      this.params.basketStatuses = statuses
        .map(value => this.tripStatuses.find(item => item.value === value));
    } else {
      this.params.basketStatuses = [];
    }
    if (query.serviceTypes) {
      let btypes = query.serviceTypes;
      if (!(btypes instanceof Array)) {
        btypes = [btypes];
      }
      this.params.serviceTypes = btypes
        .map(value => this.serviceTypes.find(item => item.value === value));
    } else {
      this.params.serviceTypes = [];
    }

    if (query.bookingReference) {
      this.params.bookingReference = query.bookingReference;
    } else {
      this.params.bookingReference = '';
    }

    if (query.companyCode) {
      this.params.companyCode = query.companyCode;
    } else {
       this.params.companyCode = '';
    }

    if (query.arrangers) {
      let a: string[] | string = query.arrangers;
      if (!(a instanceof Array)) {
        a = [a];
      }
      const result: AxiosResponse<ProfileFullNameModel>[] = await Promise.all(
        a.map(id => ProfileApi.getFullNameById(id, Permission.ShowTripList))
      );

      this.params.bookers = result.map(res => res.data);
    } else {
      this.params.bookers = [];
    }
    if (query.travellers) {
      let a: string[] | string = query.travellers;
      if (!(a instanceof Array)) {
        a = [a];
      }
      const result: AxiosResponse<ProfileFullNameModel>[] = await Promise.all(
        a.map(id => ProfileApi.getFullNameById(id, Permission.ShowTripList))
      );

      this.params.travellers = result.map(res => res.data);
    } else {
      this.params.travellers = [];
    }
    this.params.companies = query.companies || [];

    if (query.sortOn && query.sortOn !== SearchTripsSortOnEnum.CreateDate) {
      const item: any = this.sortOnOptions
        .map(opt => opt.value)
        .find(value => value === query.sortOn);
      
      this.params.sortOnField = item;
    }
    if (query.sortDirection && query.sortDirection !== SearchTripsSortDirectionEnum.Descending) {
      const item: any = this.sortDirectionOptions
        .map(dOpt => dOpt.value)
        .find(value => value === query.sortDirection);
      
      this.params.sortDirection = item;
    }
  }

  fillQuery(query) {
    query.period = this.params.period !== this.defaultPeriod ? this.params.period : '';
    query.destination = this.mapDestinationObject(this.params.destination);
    query.startDateFrom = this.params.startDate.from;
    query.startDateTo = this.params.startDate.to;
    query.endDateFrom = this.params.endDate.from;
    query.endDateTo = this.params.endDate.to;
    query.createDateFrom = this.params.createDate.from;
    query.createDateTo = this.params.createDate.to;
    query.tripCode = this.params.tripCode;
    query.basketStatuses = this.params.basketStatuses.map(status => status.value);
    query.serviceTypes = this.params.serviceTypes.map(type => type.value);
    query.arrangers = this.params.bookers.map(user => user.id);
    query.travellers = this.params.travellers.map(user => user.id);
    query.companies = this.params.companies.map(user => user.id);
    query.sortOn = this.params.sortOnField !== SearchTripsSortOnEnum.CreateDate ? this.params.sortOnField : undefined;
    query.sortDirection = this.params.sortDirection !== SearchTripsSortDirectionEnum.Descending ? this.params.sortDirection : undefined;
    query.bookingReference = this.params.bookingReference;
    query.companyCode = this.params.companyCode;
    if (this.params.page && this.params.page > 1) {
      query.page = this.params.page;
    }
  }

  async getItems(params: TripsTableParams): Promise<GetItemsResult<SearchTrip>> {
    let searchParams = {
      period: params.period,
      destination: this.mapDestinationObject(this.params.destination),
      destinationCityId: this.params.destination ? this.params.destination.cityId : '',
      destinationCityName: this.params.destination ? this.params.destination.cityName : '',
      startDate: {
        from: params.startDate.from ?  moment(params.startDate.from).format('YYYY-MM-DD') : null,
        to: params.startDate.to ?  moment(params.startDate.to).format('YYYY-MM-DD') : null,
      },
      endDate: {
        from: params.endDate.from ?  moment(params.endDate.from).format('YYYY-MM-DD') : null,
        to: params.endDate.to ?  moment(params.endDate.to).format('YYYY-MM-DD') : null,
      },
      createDate: {
        from: params.createDate.from ?  moment(params.createDate.from).format('YYYY-MM-DD') : null,
        to: params.createDate.to ?  moment(params.createDate.to).format('YYYY-MM-DD') : null,
      },
      tripCode: params.tripCode,
      basketStatuses: params.basketStatuses.map(status => status.value),
      serviceTypes: params.serviceTypes.map(type => type.value),
      bookers: params.bookers.map(user => user.id),
      travellers: params.travellers.map(user => user.id),
      companies: params.companies.map(user => user.id),
      sortOnField: params.sortOnField,
      sortDirection: params.sortDirection,
      bookingReference: params.bookingReference,
      companyCode: params.companyCode,
    };
  
    try {
      const result: any = await TripApi.search({
        page: params.page,
        size: params.size,
      }, searchParams);
     
      return {
        results: result.data.results,
        page: result.data.page,
      };
    } catch (error) {
      this.errors = this.$handleErrors(error);
      throw(error);
    }
  }

  tryExportTrips() {
    this.showExportConfirmation = true;
  }

  exportTrips() {
    this.exportTripsWithParams(this.params);
  }

  exportTripsWithParams(params: TripsTableParams) {
    this.exportInProgress = true;
    let searchParams = {
      period: params.period,
      destination: this.mapDestinationObject(this.params.destination),
      startDate: {
        from: params.startDate.from ?  moment(params.startDate.from).format('YYYY-MM-DD') : null,
        to: params.startDate.to ?  moment(params.startDate.to).format('YYYY-MM-DD') : null,
      },
      endDate: {
        from: params.endDate.from ?  moment(params.endDate.from).format('YYYY-MM-DD') : null,
        to: params.endDate.to ?  moment(params.endDate.to).format('YYYY-MM-DD') : null,
      },
      createDate: {
        from: params.createDate.from ?  moment(params.createDate.from).format('YYYY-MM-DD') : null,
        to: params.createDate.to ?  moment(params.createDate.to).format('YYYY-MM-DD') : null,
      },
      tripCode: params.tripCode,
      basketStatuses: params.basketStatuses.map(status => status.value),
      serviceTypes: params.serviceTypes.map(type => type.value),
      bookers: params.bookers.map(user => user.id),
      travellers: params.travellers.map(user => user.id),
      companies: params.companies.map(user => user.id),
      sortOnField: params.sortOnField,
      sortDirection: params.sortDirection,
      bookingReference: params.bookingReference,
      companyCode: params.companyCode,
    };
  
    let self = this;
    self.loading = true;
    axios({
      method: 'POST',
      url: settings.apiTrip + '/api/trips/export-to-file',
      responseType: 'blob',
      data: searchParams
    })
    .then((response) => {
      let filename;
      if (response && response.headers && response.headers['content-disposition']) {
        let disposition = response.headers['content-disposition'];
        if (!!disposition && disposition.indexOf('filename=') !== -1) {
          const fileNameIndex = disposition.indexOf('filename=');

          disposition = disposition.substring(fileNameIndex, disposition.length);
          const firstSemicolonIndex = disposition.indexOf(';');
          filename = disposition.substring(0, firstSemicolonIndex).replace('filename=', '').trim();
        }
      }

      if (!filename) {
        filename = 'Trips.xlsx';
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(function (error) {
      self.errors = self.$handleErrors(error);
    }).finally(function() {
      self.loading = false;
      self.exportInProgress = false;
      self.showExportConfirmation = false;
    });
  }

  onPaginationChange(value) {
    this.params.page = value;
  }

  showTripFilters() {
    this.showFilters = true;
  }

  hideTripFilters() {
    this.showFilters = false;
  }

  tripDestination(destinations) {
    return destinations && destinations.length ? destinations.join(', ') : '';
  }

  toggleSort(type, direction) {
    this.params.sortOnField = type;
    this.params.sortDirection = direction;
    this.toggleSortOptions = false;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'arrangersLoading',
  })
  async loadArrangers(query) {
    try {
      this.usersErrors = [];
      this.arrangersLoading = true;
      const result = await ProfileApi.searchTravellers(query, false, false);
      if (result && result.data) {
        this.arrangers = result.data;
      } else {
        this.arrangers = [];
      }
    } catch (error) {
      this.usersErrors = this.$handleErrors(error);
    } finally {
      this.arrangersLoading = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'travellersLoading',
  })
  async loadTravellers(query) {
    try {
      this.usersErrors = [];
      this.travellersLoading = true;
      const result = await ProfileApi.searchTravellers(query, true, false);
      if (result && result.data) {
        this.travellers = result.data;
      } else {
        this.travellers = [];
      }
    } catch (error) {
      this.usersErrors = this.$handleErrors(error);
    } finally {
      this.travellersLoading = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  async loadLocations(query) {
    this.locationsQueryLength = query.length;
    if (!query || query.length < 3) {
      return;
    }
    try {
      this.locations = [];
      this.locationsErrors = [];
      this.locationsLoading = true;
      const result = await DictionaryApi.findCityCountry(query);
      if (result && result.data) {
        this.locations = result.data.filter(entry => {
          return entry.type === 'City';
        });
      } else {
        this.locations = [];
      }
    } catch (error) {
      this.locationsErrors = this.$handleErrors(error);
    } finally {
      this.locationsLoading = false;
    }
  }

  action(item) {
    router.push({
      name: 'basket',
      params: { id: item.id }
    });
  }

  showMoreFilters() {
    this.shouldShowMoreFilters = true;
  }

  hideMoreFilters() {
    this.shouldShowMoreFilters = false;
  }

  handleEnterPress() {
    setTimeout(() => {
      if (window.innerWidth >= 800) {
        const btn = ((this.$refs.searchButton as Vue).$el as HTMLInputElement);
        btn.focus();
      }
    }, 50);
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getResults() {
    this.resetList(); 
    this.reload();
  }
}
