

















import { Vue, Component } from 'vue-property-decorator';

import { router } from '@/router';

@Component({})
export default class TrainFiltersSubintro extends Vue {

  hideMobileFilters() {
    router.push({
      name: 'train',
    });
  }

}
