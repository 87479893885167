import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { ConfigurationListResponse, companyConfigurationType } from './configurations.model';
import { CompanyConfiguration } from './company.model';

class ProfileConfigurationApiClass {
  public getByRootCompanyId(companyId: string, configurationType: companyConfigurationType, pageSize: number = 10, pageNo: number = 1, permission?: string, phrase?: string)
    : Promise<AxiosResponse<ConfigurationListResponse>> {
      return http.execute({
        path: settings.apiProfile + '/api/configurations?rootCompanyId={companyId}&configurationType={configurationType}&pageSize={pageSize}&pageNo={pageNo}&phrase={phrase}',
        permission: permission,
        method: RequestMethod.GET
      }, {
        companyId,
        configurationType,
        pageNo,
        pageSize,
        phrase,
      }, { permission });
  }

  public getConfigurationTypes(permission: string, companyId: string): Promise<AxiosResponse<string[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/configurations/types?companyId={companyId}',
      permission: permission,
      method: RequestMethod.GET,
    }, { companyId, permission }, {});
  }

  public getCompanyConfigurations({
    rootCompanyId,
    configurationType,
    phrase,
    permission
  }): Promise<AxiosResponse<CompanyConfiguration[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/configurations?rootCompanyId={rootCompanyId}&configurationType={configurationType}&phrase={phrase}',
      permission: permission,
      method: RequestMethod.GET,
    }, {
      rootCompanyId,
      configurationType,
      phrase,
      permission
    }, {});
  }

  public deleteConfiguration(configurationId: string, permission?: string) {
    return http.execute({
      path: settings.apiProfile + '/api/configurations/{configurationId}',
      permission: permission,
      method: RequestMethod.DELETE
    }, {
      configurationId,
      permission,
    }, {});
  }
}

export const ProfileConfigurationsApi: ProfileConfigurationApiClass = new ProfileConfigurationApiClass();
