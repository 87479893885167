import CarProviderConfigurations from '@/modules/settings/configurations/CarProviderConfigurations.vue';

export default {
  path: '',
  name: 'car-provider-configurations',
  component: CarProviderConfigurations,
  displayName: 'Car providers',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadCarProviders',
    depthCategory: 'car-providers',
    depth: 1,
    parentName: 'car-provider-configurations',
  },
};