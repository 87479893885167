import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import moment from 'moment';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'airCompareLocalStore'
})
class AirCompareLocalStore extends VuexModule {
  localStore: any[] = [];


  @Mutation
  setLocalStore(payload) {
    let offers = payload;
    let obj: any[] = [];
    offers.forEach(offer => {
      if (moment().diff(moment(offer.timeStamp), 'minutes') < 15) {
        obj.push(offer);
      }
    });
    this.localStore = obj;
    if (obj && obj.length) {
      localStorage.setItem('Air_compare_offer', JSON.stringify(this.localStore));
    }
  }

  @Mutation
  resetLocalStore() {
    this.localStore = [];
  }

  @Action
  removeLocalStoreBrowser(payload) {
    let array: any[] = [];
    if (payload && payload.proposal) {
      array = this.localStore.filter(offer => offer.proposalId !== payload.proposal.id);
    } else {
      array = this.localStore.filter(offer => offer.offerId !== payload.id);
    }

    if (array && array.length) {
      localStorage.setItem('Air_compare_offer', JSON.stringify(array));
    } else {
      localStorage.removeItem('Air_compare_offer');
    }
    this.getLocalStore();
  }

  @Action
  setLocalTemplateOffersStore(payload) {
    const index = this.localStore
      .findIndex(item => item.offerId === payload.offerId && item.proposalId === payload.proposalId && item.searchId === payload.searchId);
    if (-1 < index) {
      this.localStore.splice(index, 1, payload);
    } else {
      this.localStore.push(payload);
    }
    this.setLocalStore(this.localStore);
  }

  @Action
  getLocalStore() {
    if (localStorage.getItem('Air_compare_offer')) {
      this.setLocalStore(JSON.parse(localStorage.getItem('Air_compare_offer') || '{}'));
    } else {
      this.setLocalStore([]);
    }
  }
}

export default getModule(AirCompareLocalStore);
