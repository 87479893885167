import MyMissions from '@/modules/expense/my-missions/MyMissions.vue';

export default {
  path: 'missions',
  name: 'my-missions',
  component: MyMissions,
  displayName: 'My missions',
  meta: {
    permission: 'ReadMissionDefinition'
  }
};