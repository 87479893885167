import GlobalSettingsStore from '@/modules/global-settings/global-settings.store';

export default async (to, from, next) => {
  // only applies to global settings section
  if (!to.matched.length || '/global-settings' !== to.matched[0].path) {
    next();
    return;
  }
  
  if (1 === to.matched.length) {
    // redirect to first available section
    const routes: any = GlobalSettingsStore.routes;
    let redirect: any = {
      path: to.fullPath,
    };

    if (routes && routes.length) {
      const firstLink = routes[0].path;
      redirect.path += '/' + firstLink;
    }

    if (!from.matched.length) {
      redirect.replace = true;
    }

    next(redirect);
    return;
  }

  next();
};
