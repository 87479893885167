

















import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import {
  AvailableDateFormats,
  AvailableTimeFormats,
} from '@/api/profile/company.model';
import BasketTripHistoryItem from './BasketTripHistoryItem.vue';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import BasketStore from './basket.store';
import BasketTripHistoryItemTripNotes from '@/modules/basket/BasketTripHistoryItemTripNotes.vue';

@Component({
  components: {
    BasketTripHistoryItemTripNotes,
    BasketTripHistoryItem,
  }
})
export default class BasketTripHistoryView extends Vue {
  loading: boolean = true;
  errors: any[] = [];
  history: any[] = [];



  

  get basketItems() {
    return BasketStore.basketItems;
  }

  get tripItemsIds() {
    return this.basketItems.map(item => item.id);
  }

  get basketItemsAndHistory() {
    return this.basketItems.map(item => {
      return {
        id: item.id,
        item,
        history: this.history.find(element => element.itemId === item.id) || null,
      };
    });
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get dateTimeFormat() {
    return this.currentDateFormat + ' ' + this.currentTimeFormat;
  }



  async getItems() {
    await Promise.all(this.tripItemsIds.map(id => {
      return this.getItemHistory(id);
    }));
    this.loading = false;
  }

  async getItemHistory(itemId) {
    try {
      const { data } = await BasketItemApi.getTripItemHistory(itemId);
      const changes = data.changes
        .map(change => {
          return {
            ...change,
            changeParameters: change.changeParameters
              .filter(parameter => parameter.valueTo !== parameter.valueFrom)
              .filter(parameter => 'TripItems.IsRefunded' !== parameter.fieldName)
              .map(parameter => {
                let priceFrom: any = undefined;
                let priceTo: any = undefined;

                if (-1 < [
                    'TripItems.GrossClientPriceAmount',
                    'TripItems.GrossRawProviderPriceAmount',
                    'TripItems.GrossProviderPriceAmount',
                    'TripItems.AgencyFee',
                    'TripItems.GrossAgencyMarkupAmount',
                    'TripItems.GrossSystemMarkupAmount',
                    'TripItems.NetProviderPriceAmount',
                    'TripItems.NetClientPriceAmount',
                    'TripActions.PriceChangedDifference',
                    'Synchronization.TotalPrice',
                  ].indexOf(parameter.fieldName)
                ) {
                  try {
                    const from = JSON.parse(parameter.valueFrom);
                    const to = JSON.parse(parameter.valueTo);
                    priceFrom = {
                      amount: from.Amount,
                      currency: {
                        code: from.Currency.Code,
                        symbol: from.Currency.Symbol,
                      }
                    };
                    priceTo = {
                      amount: to.Amount,
                      currency: {
                        code: to.Currency.Code,
                        symbol: to.Currency.Symbol,
                      }
                    };
                  } catch (error) {
                  }
                }

                return {
                  ...parameter,
                  fieldNameNormalized: parameter.fieldName.replace('.', '-'),
                  priceFrom,
                  priceTo,
                };
              }),
            changeDateFormated: moment.utc(change.changeDate).local().format(this.dateTimeFormat),
            invokerMapped: !change.invoker ? null : {
              id: change.invoker.invokerId,
              firstName: change.invoker.invokerFirstName,
              middleName: change.invoker.invokerMiddleName || '',
              lastName: change.invoker.invokerLastName,
              username: change.invoker.invokerUsername,
              businessUnitName: change.invoker.invokerBusinessUnitName,
            },
          };
        })
        .filter(change => {
          return !!change.changeParameters.length;
        });
      this.history.push({
        itemId,
        changes,
        error: [],
      });
    } catch (errors) {
      this.history.push({
        itemId,
        changes: [],
        error: this.$handleErrors(errors),
      });
    }
  }



  mounted() {
    this.getItems();
  }

}
