










import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

const eventName = 'change';

@Component
export default class UiRadio extends Vue {
  @Model(eventName) modelValue!: any;
  @Prop() value: any;
  @Prop({ default: '' }) valueKey!: string;

  get radioValue() {
    if (this.valueKey && this.value) {
      return this.value[this.valueKey];
    }
    return JSON.stringify(this.value);
  }

  @Emit(eventName)
  changeRadio(value) {
    return this.value;
  }

  get isChecked() {
    if (this.modelValue == null) {
      return false;
    }
    if (this.valueKey && this.modelValue) {
      return this.modelValue[this.valueKey] === this.radioValue;
    }
    return JSON.stringify(this.modelValue) === this.radioValue;
  }
}
