





































































import { Vue, Component } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import HotelSearchStore from './hotel-search.store';
import HotelResultsRow from './HotelResultsRow.vue';

@Component({
  components: {
    HotelResultsRow,
  }
})
export default class EditHotelFeePopup extends Vue {
  showing: boolean = false;
  loading: boolean = false;
  isFeeValueChanging: boolean = false;
  feeValue: any = 0;
  errors: any[] = [];
  initialFeeValue: number = 0;



  get offer() {
    return HotelSearchStore.editedFeeOffer;
  }

  get result() {
    return HotelSearchStore.editedFeeResult;
  }

  get feeCurrency() {
    if (this.offer && this.offer.agencyFee && this.offer.agencyFee.currency) {
      return this.offer.agencyFee.currency.symbol ? this.offer.agencyFee.currency.symbol : this.offer.agencyFee.currency.code; 
    }
    return '\u20AC';
  }

  get basePrice() {
    return this.offer.totalPrice.amount - this.initialFeeValue;
  }

  get totalPrice() {
    return {
      amount: this.feeValue + this.basePrice,
      currency: {
        ...this.offer.providerPrice.currency,
      },
    };
  }



  onEvent() {
    if (this.offer && this.result) {
      this.feeValue = this.offer.agencyFee.amount;
      this.initialFeeValue = this.feeValue;
      this.showing = true;
    }
  }

  closePopup() {
    this.showing = false;
    HotelSearchStore.setEditedOffer({ offer: null, result: null });
  }

  async save() {
    if (this.loading || this.isFeeValueChanging) {
      return;
    }
    this.loading = true;
    const response = await HotelSearchStore.updateFee({
      feeValue: this.feeValue,
      totalPrice: this.totalPrice.amount,
    });

    if (response.errors && response.errors.length) {
      this.errors = response.errors;
    } else {
      this.closePopup();
    }

    this.loading = false;
  }



  mounted() {
    EventBus.$on('show-hotel-edit-fee-popup', this.onEvent);
  }

  beforeDestroy() {
    EventBus.$off('show-hotel-edit-fee-popup', this.onEvent);
  }
}

