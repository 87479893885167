import {
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { RefundProcessModel, InitRefundProcessModel, UpdateRefundProcessModel } from './air-sabre-refund.model';

class AirSabreRefundApiClass {
  public getSabreCurrentRefundProcess(itemId: string): Promise<AxiosResponse<RefundProcessModel>> {
    return http.execute({
      path: settings.apiTrip + '/air-sabre-items/{itemId}/tickets-refunds',
      method: RequestMethod.GET,
    }, { itemId }, {});
  }

  public initSabreRefundProcess(itemId: string, param: InitRefundProcessModel) {
    return http.execute({
      path: settings.apiTrip + '/air-sabre-items/{itemId}/tickets-refunds',
      method: RequestMethod.POST,
    }, { itemId }, param);
  }

  public updateSabreCurrentRefundProcess(itemId: string, param: UpdateRefundProcessModel): Promise<AxiosResponse<RefundProcessModel>> {
    return http.execute({
      path: settings.apiTrip + '/air-sabre-items/{itemId}/tickets-refunds',
      method: RequestMethod.PUT,
    }, { itemId }, param);
  }
}

export const AirSabreRefundApi: AirSabreRefundApiClass = new AirSabreRefundApiClass();