



























































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import BasketStore from './basket.store';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AccountStore from '@/store/account.store';
import BasketCancellationStore from './basket-cancellation.store';
import { userFullName } from '@/core/user-full-name';

const eventName = 'update:show';

@Component({})
export default class BasketAirAmadeusRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: any;

  selectedOption: any = 'Void';
  $v;
  shouldShowCommissionErrors: boolean = false;
  commissionMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
  });

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };

  get couponsStatus() {
    return BasketCancellationStore.couponsStatus;
  }

  get loadingCoupons() {
    return BasketCancellationStore.loadingCoupons;
  }

  get couponsLoaded() {
    return BasketCancellationStore.couponsLoaded;
  }

  get processing() {
    return BasketCancellationStore.processing;
  }

  get loadingDetails() {
    return BasketCancellationStore.loadingDetails;
  }

  get title() {
    return this.$t('refund.cancellation-process');
  }

  get loading() {
    return this.processing || this.loadingCoupons || this.loadingDetails;
  }

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.basketItemId;
    });
  }

  get processErrors() {
    return BasketCancellationStore.processErrors;
  }

  get showError() {
    return BasketCancellationStore.showError;
  }

  get statusCount() {
    return this.couponsStatus.length;
  }

  get canCancelTicket() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canVoidFlightTicket() {
    return AccountStore.HasPermission('CanVoidFlightTicket');
  }

  get isVoidAvailable() {
    if ('Exchanged' === this.item.statusTag) {
      return false;
    }
    for (const status of this.couponsStatus) {
      if (status.isVoidAvailable) {
        return true;
      }
    }

    return false;
  }

  userFullName(user) {
    return userFullName(user);
  }

  issueDate(date) {
    return moment(date).format('DD MMM YYYY');
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  async beginProcess() {
    BasketCancellationStore.setProcessing(true);
    let voidResponse;
    try {
      voidResponse = await RefundBasketItemApi.startAmadeusVoid(this.basketItemId);
    } catch (error) {
      BasketCancellationStore.setProcessErrors(error);
    } finally {
      if (voidResponse && voidResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
      BasketCancellationStore.setProcessing(false);
    }
  }

  async init() {
    await BasketCancellationStore.getItemCoupons(this.basketItemId);
  }

  created() {
    BasketCancellationStore.setProcessing(false);
    this.init();
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}

