

import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

class AccommodationFeesApiClass {
  public updateSearchOfferFee(searchId, offerId, value): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/search/{searchId}/offers/{offerId}/agency-fee',
      method: RequestMethod.PUT,
    }, { searchId, offerId }, value);
  }

  public updateSearchOfferAgencyMarkup(searchId, offerId, value): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/search/{searchId}/offers/{offerId}/agency-markup',
      method: RequestMethod.PUT,
    }, { searchId, offerId }, value);
  }
}

export const AccommodationFeesApi: AccommodationFeesApiClass = new AccommodationFeesApiClass();
