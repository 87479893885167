





import DictionaryStore from '@/store/dictionary.store';
import { Vue, Component, Watch } from 'vue-property-decorator';

@Component({})
export default class StillLoading extends Vue {

  get dictsAreLoading() {
    return DictionaryStore.dictionariesShouldBeLoaded && DictionaryStore.loading;
  }

  get path() {
    return decodeURI(this.$route.query.path as string);
  }



  @Watch('dictsAreLoading', { immediate: true })
  onLoadingChange(value) {
    if (!value && this.$route.query) {
      this.$router.replace(this.path);
    }
  }
}

