


















































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';

@Component({
})
export default class BasketAirBritishAirwaysRefundView extends Vue {
  @Prop() offer!: any;
  @Prop() travellers!: any;
  @Prop() refundDetails!: any;

  get travellersMapped() {
    const priceDifferences = (this.refundDetails as any).passengerPriceDifferences || [];
    return this.travellers
      .map((traveller) => {
        const priceInfo = priceDifferences.find((p) => p.profileId === traveller.id);
        if (priceInfo) {
          Object.keys(priceInfo).forEach(key => {
            if (typeof priceInfo[key] === 'object' && priceInfo[key] !== null) {
              priceInfo[key].isToggled = false;
            }
          });
          return { ...traveller, priceInfo };
        }
        return traveller;
      })
      .filter((traveller) => traveller.priceInfo != null);
}

  userFullName(user) {
    return userFullName(user);
  }

  clickTax(key, index) {
    this.travellersMapped[index].priceInfo[key].isToggled = !this.travellersMapped[index].priceInfo[key].isToggled;
    this.$forceUpdate();
  }
}

