import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import NegoFaresStore from './nego-fares.store';
import { CorporateCodesModel } from '@/api/air-engine/nego-fares-configuration.model';
import { NegoFareConfiguration } from '@/api/nego-fares/nego-fares.model';
import { NegoFaresApi } from '@/api/nego-fares/nego-fares.api';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'negoFaresAccommodation'
})
class NegoFaresAccommodationStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: NegoFareConfiguration = new NegoFareConfiguration();
  corporateCodesList: any[] = [];
  selectedCorporateCode: CorporateCodesModel | null = null;
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';
  errors: any[] = [];

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get CorporateCodes() {
    return this.corporateCodesList;
  }

  get currentAccommodationCode() {
    return this.selectedCorporateCode;
  }

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  @Mutation
  selectCorporateCode(value) {
    this.selectedCorporateCode = value;
  }

  @Mutation
  setConfiguration(value) {
    this.currentConfiguration = value;
  }

  @Mutation
  clearCurrentConfiguration() {
    this.currentConfiguration = new NegoFareConfiguration();
  }

  @Mutation
  setNegoFaresConfigurations(value) {
    this.currentConfiguration.negoFaresConfigurations = value;
  }

  @Mutation
  addCorporateCode(value) {
    this.corporateCodesList.push({
      ...value
    });
  }

  @Mutation
  setCorporateCodesList(value) {
    this.corporateCodesList = value;
  }

  @Mutation
  setErrors(value) {
    this.errors = value;
  }

  @Mutation
  clearErrMessages() {
    this.errors = [];
  }

  @Action
  editCorporateCode(value) {
    let oldValIndex = this.corporateCodesList.findIndex(code => {
      return _.isEqual(code, this.selectedCorporateCode);
    });
    if (-1 < oldValIndex) {
      this.corporateCodesList.splice(oldValIndex, 1, value);
    }
  }

  @Action
  removeCorporateCode(value) {
    let oldValIndex = this.corporateCodesList.findIndex(code => {
      return _.isEqual(code, value);
    });
    if (-1 < oldValIndex) {
      this.corporateCodesList.splice(oldValIndex, 1);
    }
  }

  @Action
  async getAccommodationNegoFareConfiguration(configurationId: string) {
    try {
      const result = await NegoFaresApi.getAccommodationNegoFareConfiguration(configurationId);
      if (result && result.data) {
        NegoFaresStore.setCurrentConfigurationName(result.data.name);
        NegoFaresStore.setConfigurationId(configurationId);
        this.setConfiguration(result.data);
        if (result.data.negoFaresConfigurations) {
          this.mapCorporateCodes(result.data.negoFaresConfigurations);
          this.setNegoFaresConfigurations(result.data.negoFaresConfigurations);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        NegoFaresStore.setCustomError(true);
      } else {
        NegoFaresStore.setErrMessages(error);
      }
    }
  }

  @Action
  mapCorporateCodes(configuration: any[]) {
    let codes: any = [];
    let errors: any = [];
    configuration.forEach(cfg => {
      if (cfg.corporateCodes && cfg.corporateCodes.length) {
        let cfgCodes = cfg.corporateCodes.map(code => {
          return {
            supplier: cfg.supplier,
            ...code,
            id: _.uniqueId()
          };
        });
        codes.push(...cfgCodes);
      }
      else if (cfg.error && !cfg.succeeded) {
        errors.push({
          supplier: cfg.supplier,
          error: $handleErrors(cfg.error)
        });
      }
    });
    this.setErrors(errors);
    this.setCorporateCodesList(codes);
  }

  @Action
  async updateNegoFareConfiguration({ configurationId, name, negoFaresConfigurations }) {
    try {
      NegoFaresStore.setLoading(true);
      NegoFaresStore.clearErrMessages();
      const result = await NegoFaresApi.updateAccommodationNegoFareConfiguration({ configurationId, params: { name, negoFaresConfigurations }});
      if (result && result.data) {
        this.getAccommodationNegoFareConfiguration(configurationId);
        if (result.data && (result.data as any[]).length) {
          let errorElements = (result.data as any[]).filter(element => {
            return element.error !== null;
          });
          if (errorElements && errorElements.length) {
            NegoFaresStore.setErrMessages([...errorElements.map(elem => { return elem.error; })]);
          }
        }
      }
    } catch (error) {
      NegoFaresStore.setErrMessages(error);
    } finally {
      NegoFaresStore.setLoading(false);
    }
  }
}

export default getModule(NegoFaresAccommodationStore);