

















































import { Vue, Prop, Component } from 'vue-property-decorator';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';

@Component({})
export default class BasketTravellerFillDateOfBirthForm extends Vue {
  @Prop({}) traveller: any;
  @Prop({}) index: any;


  get minimalBirthDate() {
    let date = new Date();
    date.setFullYear( date.getFullYear() - 150 );
    return date;
  }

  get dateOfBirth() {
    return this.traveller.dateOfBirth;
  }

  set dateOfBirth(value) {
    this.traveller.dateOfBirth = value;
  }


  onFillDateOfBirth() {
    BasketStore.updateTravellersNewDateOfBirth({
      id: this.traveller.id,
      dateOfBirth: this.dateOfBirth ? moment(this.dateOfBirth).utc(true).format('YYYY-MM-DD') : null,
    });
  }

  userFullName(user) {
    return userFullName(user);
  }

  getCodeTranslation(code) {
    const value = searchConst.guestTravellerOptions.find(item => item.code === code);

    if (!value) {
      return '';
    }

    return translate(value.label);
  }

  created() {
    EventBus.$on('basket-travellers-fill-date-of-birth', this.onFillDateOfBirth);
  }

  beforeDestroy() {
    EventBus.$off('basket-travellers-fill-date-of-birth', this.onFillDateOfBirth);
  }
}
