import CustomFieldDictionary from '@/modules/settings/custom-fields-configuration/CustomFieldDictionary.vue';

export default {
  path: ':configurationId?/dictionary-definition',
  name: 'dictionary-definition',
  component: CustomFieldDictionary,
  displayName: 'Dictionary definition',
  meta: {
    permission: 'ReadCustomFieldDefinitions',
    parentName: 'custom-fields',
    depthCategory: 'custom-fields',
    introClass: 'intro-settings',
    depth: 3,
  }
};