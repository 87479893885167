import ProjectDefinition from '@/modules/settings/projects-configuration/ProjectDefinition.vue';

export default {
  path: ':configurationId?/definition/:itemId?/:isCopy?',
  name: 'definition',
  component: ProjectDefinition,
  displayName: 'Project definition',
  meta: {
    breadCrumb: 'Project definition',
    introClass: 'intro-settings',
    permission: 'ReadProjects',
    parentName: 'projects',
    depthCategory: 'projects',
    depth: 3,
  }
};