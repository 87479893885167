





























































































































































import { Vue, Component } from 'vue-property-decorator';
import { router } from '@/router';
import _ from 'lodash';
import SettingsStore from '@/modules/settings/settings.store';
import { SupplierName } from '@/services/supplier-name.service';
import FaresMappingStore from '@/modules/settings/fares-mapping/fare-mapping.store';
import AirFaresMappingStore from '@/modules/settings/fares-mapping/air-fare-mapping.store';
import DictionaryStore from '@/store/dictionary.store';
import { AirLine } from '@/api/dictionary/dictionary.model';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import { AirlinesString } from '@/services/airline-name.service';


@Component({})
export default class FareMappingAirRule extends Vue {
  $v;
  Form: any = {
    supplier: null,
    supplierName: null,
    airlines: [],
    airlinesText: [],
    fareBasisCode: '',
    label: '',
  };
  validated: boolean = false;
  formMapped: boolean = false;
  saving: boolean = false;
  allSupplier: any[] = [];
  allAirline: any[] = [];

  get enabledAirSuppliers() {
    return AirFaresMappingStore.enabledAirSuppliers;
  }

  get providerOptions() {
    return this.enabledAirSuppliers.filter(air => air !== 'Fake').map(supplier => {
      return {
        label: SupplierName(supplier),
        value: supplier,
      };
    });
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return FaresMappingStore.configurationName;
  }

  get airlines() {
    return DictionaryStore.allAirLinesSorted;
  }

  get supplier() {
    return this.providerOptions.find(provider => provider.value === this.Form.supplier) || null;
  }

  set supplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      this.Form.supplierName = null;
      return;
    }
    this.Form.supplier = value.value;
    this.Form.supplierName = SupplierName(value.value);
  }

  get selectedFaresMapping() {
    return AirFaresMappingStore.selectedFareMapping;
  }

  get codeExists() {
    return !!this.selectedFaresMapping && Object.keys(this.selectedFaresMapping).length > 0;
  }

  get entries() {
    return AirFaresMappingStore.fareMapping;
  }

  get isDuplicate() {
    if (!this.Form.supplier || this.saving) {
      return false;
    }
    const findByCode = this.entries
      .filter(entry => entry.supplier === this.Form.supplier)
      .find(entry => {
        return entry.fareBasisCode === this.Form.fareBasisCode;
      });

    if (!findByCode) {
      return false;
    }
    return !this.codeExists || (this.selectedFaresMapping && findByCode.id !== this.selectedFaresMapping.id);
  }

  @Validation()
  validationObject() {
    return {
      Form: {
        supplier: {
          required,
        },
        airlines: {},
        fareBasisCode: {
          required,
          maxLength: maxLength(20),
          duplicate: () => {
            return !this.isDuplicate;
          },
        },
        label: {
          required,
          maxLength: maxLength(50),
        }
      }
    };
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  returnToConfiguration() {
    FaresMappingStore.setIsFromManage(true);
    router.push({
      name: 'fares-mapping-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  clearData() {
    this.Form = {
      supplier: null,
      supplierName: null,
      airlines: [],
      airlinesText: [],
      fareBasisCode: '',
      label: '',
    };
  }

  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      this.Form = {
        supplier: data.supplier,
        supplierName: SupplierName(this.Form.supplier),
        airlines: data.airlines,
        airlinesText: AirlinesString(this.Form.airlines),
        fareBasisCode: data.fareBasisCode,
        label: data.label,
      };
    }
    this.formMapped = true;
  }

  createAirFare() {
    let item = {
      fareBasisCode: this.Form.fareBasisCode,
      label: this.Form.label,
      supplier: this.Form.supplier,
      supplierName: SupplierName(this.Form.supplier),
      airlines: this.Form.airlines,
      airlinesText: AirlinesString(this.Form.airlines),
      status: AirFaresMappingStore.draftNewStatus,
      id: _.uniqueId(),
    };

    if (this.codeExists) {
      AirFaresMappingStore.editFareMapping(item);
    } else {
      AirFaresMappingStore.addFareMapping(item);
    }
  }

  confirmAndAddNext() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.saving = true;
      this.createAirFare();
      AirFaresMappingStore.selectFareMapping({});
      this.validated = false;
      this.$v.Form.$reset();
      this.clearData();
      this.saving = false;
    }
  }

  confirmAndGoBack() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.saving = true;
      this.createAirFare();
      this.returnToConfiguration();
      this.$v.Form.$reset();
      this.clearData();
      this.saving = false;
    }
  }

  onSupplierChange() {
    if (this.formMapped) {
      this.Form.codeType = null;
      this.Form.airlineId = null;
    }
  }

  async created() {
    await AirFaresMappingStore.getEnabledAirSuppliers();
    if (this.selectedFaresMapping) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedFaresMapping)));
    }

  }
}
