

















































































































import { Prop, Component, Watch } from 'vue-property-decorator';

import { router } from '@/router';
import { BaseTable } from '@/core/base-table.class';
import { BaseTableParams } from '@/core/base-table-params';
import {
  RailRuleConfigurationUpdateModel,
  RailRuleCreateModel,
  SpeedTrainType,
  RailProviderType
} from '@/api/travel-policy/travel-policy.model';
import SettingsStore from '@/modules/settings/settings.store';
import TravelPolicyRailStore from '@/modules/settings/travel-policy/travel-policy-rail.store';
import TravelPolicyStore from '@/modules/settings/travel-policy/travel-policy.store';
import EventBus from '@/services/event-handler';
import _ from 'lodash';
import { translate } from '@/i18n';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})

export default class TravelPolicyRail extends BaseTable<RailRuleConfigurationUpdateModel> {
  @Prop({ default: false }) formPending;
  @Prop({ default: false }) readOnly?;

  params: BaseTableParams = new BaseTableParams({
    sort: '',
    size: 10,
    start: 0,
  });
  fields = {
    price: {
      sortable: false,
      label: translate('settings-travel-policy.price'),
    },
    trainTypes: {
      sortable: false,
      label: translate('settings-travel-policy.train-types'),
      formatter: value => {
        return value && value.length ? 
          value.map((item) => {
            if (item.label) {
              return item.label;
            } else {
              if (typeof(item) === 'number') {
                return SpeedTrainType[item];
              } else {
                return item;
              }
            }
          }).join(', ') : ' ';
      },
    },
    routings : {
      sortable: false,
      label: translate('settings-travel-policy.routings'),
      formatter: value => {
        return `${ value && value.length ? value.length + ' ' + translate('settings-travel-policy.lowercase-routings') : ' '}`;
      },
    },
    providers: {
      sortable: false,
      label: translate('settings-travel-policy.providers'),
      formatter: value => {
        return value && value.length ? 
          value.map(item => {
            if (item.label) {
              return this.getSupplierString(item.label);
            } else {
              if (typeof(item) === 'number') {
                return this.getSupplierString(RailProviderType[item]);
              } else {
                return this.getSupplierString(item);
              }
            }
          }).join(', ')  : ' ';
      }
    },
    ruleLegTimeComputed: {
      sortable: false,
      label: translate('settings-travel-policy.table-leg-time'),
    },
    status: {
      sortable: false,
      label: '',
      formatter: value => {
        if (value === undefined) {
          return '';
        } else if (value !== '') {
          return translate('settings-travel-policy.draft');
        }
      },
    },
    seeAll: {
      sortable: false,
      label: '',
    },
    actions: {
      sortable: false,
      label: '',
    },
  };
  $v;
  menuActive: boolean = false;
  rule: RailRuleConfigurationUpdateModel | null = null;
  modifyPopup: boolean = false;
  removePopup: boolean = false;
  maxSize: number = Number.MAX_SAFE_INTEGER;
  currentPage: number = 1;
  itemList: RailRuleCreateModel[] = [];

  get ruleList() {
    return TravelPolicyRailStore.getRailRules;
  }

  get hideNonCompliantRates() {
    return TravelPolicyRailStore.travelPolicy.hideNonCompliantRates;
  }

  set hideNonCompliantRates(value) {
    TravelPolicyRailStore.setHideNonCompliantRates(value);
  }

  get currentConfigurationId() {
    return this.$route.params.configurationId;
  }

  get currentConfigurationName() {
    return this.$route.params.configurationName;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isReadOnly() {
    return !!this.$route.query.readOnly;
  }

  get count() {
    if (!this.results) {
      return 0;
    }

    return this.results.length;
  }

  get loadingList() {
    return TravelPolicyStore.loading;
  }

  getSupplierString(name) {
    return SupplierName(name);
  }

  modifyRule(item) {
    TravelPolicyRailStore.selectedRailRule(item);
    router.push({
      name: 'rail-rule',
      params: {
        configurationId: this.currentConfigurationId,
      },
      query: {
        readOnly: this.readOnly.toString()
      }
    });
  }

  removeRule(selected) {
    if (selected) {
      let filterList = _.filter(this.ruleList, (item) => {
        return item.id !== selected.id;
      });
      TravelPolicyRailStore.setRailRules(filterList);
      this.reload();
    }
  }

  async getItems() {
    await new Promise(resolve => setTimeout(resolve));
    return {
      page: {
        pageSize: this.params.size,
        pageNo: this.params.start,
        moreResultsAvailable: false,
      },
      results: this.ruleList,
    };
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort(function(a, b) {
      let result = 0;
      if (a.rulePriority < b.rulePriority) {
        result = -1;
      } else if (a.rulePriority > b.rulePriority) {
        result = 1;
      }
      return result;
    });
  }

  toggleRuleMenu(item) {
    this.rule = item;
    this.menuActive = !this.menuActive;
  }

  addRule() {
    TravelPolicyRailStore.selectedRailRule({});
    if (this.currentConfigurationId) {
      router.push({
        name: 'rail-rule',
        params: {
          configurationId: this.currentConfigurationId
        },
      });
    } else {
      router.push({
        name: 'rail-rule',
      });
    }
  }

  onReceive() {
    this.reload();
  }

  created() {
    EventBus.$on('refresh-data', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('refresh-data', this.onReceive);
  }

  @Watch('ruleList')
  onRuleListChange() {
    this.reload();
  }
}
