import VirtualCards from '@/modules/settings/virtual-cards/VirtualCards.vue';
import VirtualCardsConfigurations from './virtual-cards-configurations.route';
import VirtualCardsConfiguration from './virtual-cards-configuration.route';

export default {
  path: 'virtual-cards-configurations',
  component: VirtualCards,
  displayName: 'settings-sidebar.virtual-cards',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditVirtualCards',
    icon: 'credit_card',
    breadCrumb: 'Virtual cards configurations',
    parentName: 'virtual-cards-configurations',
  },
  children: [
    VirtualCardsConfigurations,
    VirtualCardsConfiguration,
  ],
};