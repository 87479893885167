


















































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';

import EventHandler from '@/services/event-handler';
import { UpdateRoleModel } from '@/api/roles/roles.model';
import rolesStore from './roles.store';
import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';

@Component({})
export default class EditRoleForm extends Vue {
  loading: boolean = true;
  $v;
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';

  get errors() {
    return rolesStore.errors;
  }

  get canManageSystem() {
    return AccountStore.HasPermission('CanManageSystem');
  }

  get editedRole() {
    return rolesStore.EditedRole;
  }

  get loadingPage() {
    return rolesStore.loading;
  }

  @Validation()
  validationObject() {
    return {
      editedRole: {
        roleName: { required }
      },
    };
  }

  selectAll() {
    if (!this.editedRole || !this.$hasAccess('WriteRoles')) {
      return;
    }
    this.editedRole.permissions.forEach((section) => {
      section.permissions.forEach(p => {
        p.isSelected = true;
      });
    });
  }

  deselectAll() {
    if (!this.editedRole || !this.$hasAccess('WriteRoles')) {
      return;
    }
    this.editedRole.permissions.forEach((section) => {
      section.permissions.forEach(p => {
        p.isSelected = false;
      });
    });
  }

  async mounted() {
    const roleId = this.$route.params.roleId;
    await rolesStore.editRole(roleId);
    this.loading = false;
  }

  close() {
    this.$router.push({
      name: 'settings-roles',
    });
  }

  async submit() {
    this.$v.editedRole.$touch();
    if (this.$v.editedRole.$pending || this.$v.editedRole.$error) {
      return;
    }
    this.formPending = true;

    if (this.editedRole != null && this.editedRole.permissions != null) {
      let updateRole = new UpdateRoleModel();
      updateRole.name = this.editedRole.roleName;

      this.editedRole.permissions.forEach(catPermission => {
        catPermission.permissions.filter(x => x.isSelected).forEach(perm => {
          updateRole.permissions.push(perm.code);
        });
      });

      try {
        await rolesStore.updateRole(updateRole);
        EventHandler.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('common.data-saved')
        });
        this.close();
      }
      finally {
        this.formPending = false;
      }
    }
  }
}
