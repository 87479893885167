




import { Vue, Component, Watch } from 'vue-property-decorator';
import { Intercom } from '@mathieustan/vue-intercom';

import AccountStore from '@/store/account.store';
import LayoutWrapper from '@/modules/layout/LayoutWrapper.vue';
import settings from '@/settings';
import { userFullName } from '@/core/user-full-name';
import LayoutStore from './modules/layout/layout.store';

@Component({
  components: {
    LayoutWrapper,
  },
})
export default class App extends Vue {
  $intercom: any = null;

  get settingsLoaded() {
    return AccountStore.settingsLoaded;
  }

  get isUserLoading() {
    return AccountStore.IsLoading;
  }

  get isUserLoaded() {
    return AccountStore.IsLoaded;
  }

  @Watch('settingsLoaded', { immediate: true })
  onLoaded(val) {
    if (!val) {
      return;
    }
    
    if (settings.pciProxy_WebSecureFieldsUrl !== '') {
      const script = document.createElement('script');
      script.setAttribute('src', settings.pciProxy_WebSecureFieldsUrl);
      document.body.appendChild(script);
    }
  }

  onIntercomReady() {
    const account = AccountStore.current!;
    const profile = account.profile;
    const name = userFullName(profile);
    const companyName = `${profile.companyName} (${profile.companyCode})`;

    if (!account.intercomSettings) {
      return;
    }

    this.$intercom.boot({
      app_id: account.intercomSettings.workspaceId,
      user_id: account.accountName,
      name: name,
      email: profile.primaryEmail ? profile.primaryEmail : '',
      company: {
        company_id: profile.companyName,
        name: companyName,
      },
      language_override: profile.displayLanguage,
      phone: profile.primaryPhone ? profile.primaryPhone.code + profile.primaryPhone.number : '',
    });
  }

  @Watch('isUserLoading', { immediate: true })
  onUserLoading(val) {
    if (!this.isUserLoaded || !!val ) {
      return;
    }

    const account = AccountStore.current!;

    if (!account.intercomSettings || 'false' === settings.pluginProviderEnableIntercom) {
      return;
    }

    const appId = account.intercomSettings.workspaceId;

    this.$intercom = new Intercom({ appId });
    this.$intercom.on('ready', this.onIntercomReady);
  }

  mounted() {
    LayoutStore.addNonCrucialStyleSheets();
  }
}
