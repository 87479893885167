



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class TrainProposalFareName extends Vue {
  @Prop() fare!: string;

  withEllipsis: boolean = true;

  get fareName() {
    return this.fare.substr(0, 1).toUpperCase() + this.fare.substr(1).toLowerCase();
  }

  mounted() {
    const wrapper = this.$refs.wrapper as HTMLElement;
    const content = this.$refs.content as HTMLElement;

    if (content.offsetWidth < wrapper.offsetWidth) {
      this.withEllipsis = false;
    }
  }
}
