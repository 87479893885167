








































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, requiredIf, not } from 'vuelidate/lib/validators';
import moment from 'moment';

import { translate } from '@/i18n';
import { router } from '@/router';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { ImportanceLevel } from '@/api/profile/custom-messages.model';
import { CustomMessagesApi } from '@/api/profile/custom-messages.api';
import { CompanyModel } from '@/api/profile/company.model';
import $handleErrors from '@/core/errors/handle-errors.service';
import CustomMessagesStore from './custom-messages.store';

@Component({})
export default class EditCustomMessage extends Vue {
  message: any = {
    title: '',
    startDate: '',
    endDate: '',
    importance: ImportanceLevel.Normal,
    isEnabled: true,
    translations: [{
      languageCode: 'en',
      title: '',
      body: '',
    }, {
      languageCode: 'fr',
      title: '',
      body: '',
    }, {
      languageCode: 'it',
      title: '',
      body: '',
    }, {
      languageCode: 'pl',
      title: '',
      body: '',
    }],
    recipients: [],
  };
  selectedTab = 'en';
  currentPage: number = 1;
  selectedTranslationIndex = 0;
  params = {
    size: Number.MAX_SAFE_INTEGER,
    sort: '',
    start: 0,
  };
  perPageOptions = [
    5,
    10,
    15,
    20,
    30,
    40,
    50,
    100,
    Number.MAX_SAFE_INTEGER
  ];
  imagesConst: string = '/assets/img/loader/1.gif';
  query: string = '';
  searchQuery: string = '';
  queryInProgress: boolean = false;
  items: any[] = [];
  loading: boolean = false;  
  serverErrors: any[] = [];

  $v;

  @Validation()
  validationObject() {
    return {
      message: {
        translations: {
          leastOneFilled: not(() => this.message.translations.every((item) => !(item.title && item.body))),
          leastOneLanguageError: not(() => this.languagesErrors.some((item) => item)),
          $each: {
            title: {
              maxLength: maxLength(250),
              requiredIf: requiredIf((item) => !!item.body),
            },
            body: {
              maxLength: maxLength(2500),
              requiredIf: requiredIf((item) => !!item.title)
            },
          },
        },
      },
    };
  }



  get languagesErrors() {
    return this.message.translations.map((item, index) => this.$v.message.translations.$each[index].$invalid);
  }
  
  get links() {
    return [
      {
        value: 'en',
        name: translate('common-languages.english'),
        disabled: false,
      },
      {
        value: 'fr',
        name: translate('common-languages.french'),
        disabled: false,
      },
      {
        value: 'it',
        name: translate('common-languages.italian'),
        disabled: false,
      },
      {
        value: 'pl',
        name: translate('common-languages.polish'),
        disabled: false,
      }
    ];
  }

  get importanceOptions() {
    return [
      {
        value: ImportanceLevel.Normal,
        name: translate('global-settings.importance-normal'),
      },
      {
        value: ImportanceLevel.High,
        name: translate('global-settings.importance-high'),
      },
    ];
  }


  get fields() {
    return {
      actions: {
        sortable: false,
        label: ''
      },
      name: {
        label: translate('settings-company.name'),
        sortable: false,
      },
      code: {
        label: translate('settings-company.code'),
        sortable: false,
      },
      type: {
        sortable: false,
        label: ''
      },
    };
  }

  get selectedMessage() {
    return CustomMessagesStore.selected;
  }

  get currentTranslation() {
    if (!this.message || !this.message.translations) {
      return null;
    }

    return this.message.translations
      .find(item => item.languageCode.toLowerCase() === this.selectedTab.toLowerCase());
  }

  get isNew() {
    return 'new' === this.$route.params.messageId;
  }

  get importanceValue() {
    return this.importanceOptions
      .find(opt => opt.value === this.message.importance);
  }

  set importanceValue(value) {
    if (!value) {
      return;
    }
    this.message.importance = value.value;
  }

  get count() {
    return this.itemsFiltered.length;
  }

  get startDate() {
    return this.message.startDate;
  }

  set startDate(value) {
    if (!value) {
      this.message.startDate = '';
      return;
    }
    this.message.startDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  }

  get endDate() {
    return this.message.endDate;
  }

  set endDate(value) {
    if (!value) {
      this.message.endDate = '';
      return;
    }
    this.message.endDate = moment(value).format('YYYY-MM-DDTHH:mm:ss');
  }

  get exactMatches() {
    if (!this.searchQuery) {
      return [];
    }
    const query = this.searchQuery.toLowerCase();

    return this.items.filter(item => {
      return item.name.toLowerCase().indexOf(query) > -1 || item.code.toLowerCase().indexOf(query) > -1;
    });
  }

  get exactMatchesIds() {
    return this.exactMatches.map(el => el.id);
  }

  get itemsFiltered() {
    if (!this.searchQuery) {
      return this.items;
    }
    const exactMatches = this.exactMatches;

    const ids = exactMatches.reduce((previous, current) => {
      return this.findAllParents(current, previous);
    }, exactMatches.map(el => el.id));

    return this.items.filter(item => {
      const parentIds = this.findAllParents(item, []);
      const hasParent = parentIds.reduce((prev, cur) => {
        return prev || !!exactMatches.find(match => match.id === cur);
      }, false);

      return ids.indexOf(item.id) > -1 || hasParent;
    }).map(item => {
      if (-1 === ids.indexOf(item.id)) {
        return item;
      }
      const matchedName = this.matchedItemName(item.name);
      const matchedCode = this.matchedItemName(item.code);
      return {
        ...item,
        namePartBefore: matchedName[0],
        namePartExact: matchedName[1],
        namePartAfter: matchedName[2],
        codePartBefore: matchedCode[0],
        codePartExact: matchedCode[1],
        codePartAfter: matchedCode[2],
      };
    });
  }

  get selectedRecipientsIds() {
    return this.items
      .filter(item => item.isSelected)
      .map(item => item.id);
  }



  changeSelectedTab(item, index) {
    if (!item || item.disabled) {
      return;
    }
    this.selectedTab = item.value;
    this.selectedTranslationIndex = index;
  }

  findAllParents(item, parents) {
    if (!item.parentId || !this.items.find(el => el.id === item.parentId)) {
      return parents;
    }

    const parent = this.items.find(el => el.id === item.parentId);

    return this.findAllParents(parent, [...parents, item.parentId]);
  }

  matchedItemName(name) {
    const query = this.searchQuery.toLowerCase();
    const index = name.toLowerCase().indexOf(query);

    if (index === -1) {
      return [name, '', ''];
    }

    const before = name.substr(0, index);
    const exact = name.substr(index, query.length);
    const after = name.substr(index + query.length);

    return [before, exact, after];
  }

  classes(level) {
    if (level > 1) {
      return {
        paddingLeft: ((level - 1) * 30) + 'px',
      };
    }
  }

  rowSelected(item, $event) {
    const el = this.items.find(i => i.id === item.id);

    el.isSelected = $event;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'queryInProgress',
  })
  updateSearchQuery() {
    this.searchQuery = this.query;
    this.$nextTick(() => {
      this.queryInProgress = false;
    });
  }

  @Watch('query')
  onQueryChange() {
    this.updateSearchQuery();
  }

  async load() {
    try {
      this.loading = true;
      this.serverErrors = [];
      
      let result;

      if (this.isNew) {
        result = await CustomMessagesApi.getNewMessageBusinessUnits();
      } else {
        result = await CustomMessagesApi.getMessageBusinessUnits(this.$route.params.messageId);
      }

      if (result && result.data) {
        let sortedHierarchy: CompanyModel[] = [];
        this.prepareHierarchy(result.data, sortedHierarchy, 0, null);
        this.items = sortedHierarchy;
        this.loading = false;
      }
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  prepareHierarchy(arrayToSort, sortedArray, currentLevel, parentId) {
    if (arrayToSort && sortedArray) {
      let currentLevelItems = arrayToSort.filter(function (item) {
        if (!parentId) {
          return !item.parentId || !arrayToSort.find(el => el.id === item.parentId);
        } else {
          return item.parentId === parentId;
        }
      });
      if (currentLevelItems.length > 0) {
        currentLevelItems = this.sortByName(currentLevelItems);
        currentLevelItems.forEach(element => {
          element.level = currentLevel;
          sortedArray.push(element);
          this.prepareHierarchy(arrayToSort, sortedArray, currentLevel + 1, element.id);
        });
      }
    }
  }

  sortByName(arrayToSort) {
    return arrayToSort.sort((a, b) => a.name.localeCompare(b.name));
  }

  selectAll() {
    this.items.forEach(item => item.isSelected = true);
  }

  deselectAll() {
    this.items.forEach(item => item.isSelected = false);
  }

  selectAllAgencies() {
    this.items
      .filter(item => 'Agency' === item.recipientType)
      .forEach(item => item.isSelected = true );
  }

  selectAllCustomers() {
    this.items
      .filter(item => 'Customer' === item.recipientType)
      .forEach(item => item.isSelected = true );
  }

  backToList() {
    router.push({
      name: 'custom-messages',
    });
  }

  @Watch('selectedRecipientsIds', { immediate: true, deep: true })
  onRecipientsChange(value) {
    this.message.recipients = value;
  }
  

  async saveMessage() {
    this.$v.message.$touch();
    if (this.$v.message.$pending || this.$v.message.$error) {
      return;
    }
    try {
      if (this.isNew) {
        await CustomMessagesApi.addCustomMessage(this.message);
      } else {
        await CustomMessagesApi.updateCustomMessage(this.message.id, this.message);
      }

      this.backToList();
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    }
  }



  created() {
    if (!this.isNew && !this.selectedMessage) {
      this.backToList();
    }
    if (this.selectedMessage) {
      this.message = this.selectedMessage;
    }
    this.load();
  }
}

