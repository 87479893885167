























































































































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import BasketStore from './basket.store';
import {
  CancellationMethods,
  RefundDecisionMessage,
  RefundActionStatuses,
} from '@/modules/search/air/air-refund.model';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import BasketCancellationStore from './basket-cancellation.store';
import BasketAirBritishAirwaysRefundView from './BasketAirBritishAirwaysRefundView.vue';
import AirResultsRow from '@/modules/search/air/AirResultsRow.vue';
import BasketAirOffer from './BasketAirOffer.vue';
import settings from '@/settings';
import { userFullName } from '@/core/user-full-name';

const eventName = 'update:show';

@Component({
  components: {
    BasketAirBritishAirwaysRefundView,
    AirResultsRow,
    BasketAirOffer,
  }
})
export default class BasketAirBritishAirwaysRefundPopup extends Vue {
  @Prop() basketItemId!: string;
  @Prop() show!: boolean;
  @Prop() actionType!: any;
  @Prop() recomedationId!: string;

  selectedOption: any = '';
  taxBreakdowns: any[] = [];
  isCommissionValid: boolean = true;
  refundErrors: any[] = [];

  couponsFields = {
    departureDate: {
      label: translate('refund.departure-date')
    },
    departureTime: {
      label: translate('refund.departure-time')
    },
    coupons: {
      label: translate('refund.coupons')
    },
    status: {
      label: translate('refund.status')
    }
  };

  get isRefundAlwaysAvailable() {
    return 'true' === settings.isRefundAlwaysAvailable;
  }

  get couponsStatus() {
    return BasketCancellationStore.couponsStatus;
  }

  get loadingCoupons() {
    return BasketCancellationStore.loadingCoupons;
  }

  get couponsLoaded() {
    return BasketCancellationStore.couponsLoaded;
  }

  get processing() {
    return BasketCancellationStore.processing;
  }

  get loadingDetails() {
    return BasketCancellationStore.loadingDetails;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get isMobile() {
    return window.innerWidth <= 799.98;
  }
  get title() {
    return this.$t('refund.cancellation-process');
  }

  get loading() {
    return this.processing || this.loadingCoupons || this.loadingDetails;
  }

  get item(): any {
    return BasketStore.basketItemsMapped.find(item => {
      return item.id === this.basketItemId;
    });
  }

  get processErrors() {
    return BasketCancellationStore.processErrors;
  }

  get canVoidFlightTicket() {
    return AccountStore.HasPermission('CanVoidFlightTicket');
  }

  get canRefundFlightTicket() {
    return AccountStore.HasPermission('CanRefundFlightTicket');
  }

  get isVoidAvailable() {
    return this.couponsStatus.some((status) => status.isVoidAvailable);
  }

  get isRefundAvailable() {
    if (this.isRefundAlwaysAvailable) {
        return true;
    }
    return this.couponsStatus.some((status) => !status.isVoidAvailable);
  }

  get britishAirwaysCancelationsDetails() {
    return BasketCancellationStore.britishAirwaysCancelationDetails;
  }

  get britishAirwaysRefundDetailsLoaded() {
    return BasketCancellationStore.britishAirwaysRefundDetailsLoaded;
  }

  get showError() {
    return BasketCancellationStore.showError;
  }

  get airDetailsInfo() {
    return BasketCancellationStore.airDetailsInfo;
  }

  get isValidCouponStatus() {
    const coupon = this.couponsStatus.find((item) => item.isValidCouponStatus !== null);
    return coupon ? coupon.isValidCouponStatus : true;
  }

  userFullName(user) {
    return userFullName(user);
  }

  issueDateTime(date) {
    return moment.parseZone(date).format('DD/MM/YYYY, ') + '23:59:59 (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  departureDate(date) {
    return moment(date).format('DD MMM');
  }

  departureTime(date) {
    return moment(date).format('HH:mm');
  }

  @Watch('actionType', { immediate: true })
  onChangeAction(val) {
    if (-1 < [
      RefundActionStatuses.BACheckConditions,
    ].indexOf(val.code)) {
      this.loadRefundDetails();
      BasketCancellationStore.setProcessing(false);
    } else if (val.code === RefundActionStatuses.VerifyStatus) {
      this.init();
      BasketCancellationStore.setProcessing(false);
    }
  }

  async beginProcess() {
    BasketCancellationStore.setProcessErrors([]);
    BasketCancellationStore.setProcessWarning(null);
    BasketCancellationStore.setShowError(false);
    BasketCancellationStore.setProcessing(true);
    try {
      let response;
      if (this.selectedOption === CancellationMethods.void) {
        response = await RefundBasketItemApi.startVoid(this.basketItemId);
        this.hidePopup();
      } else if (this.selectedOption === CancellationMethods.refund) {
        response = await RefundBasketItemApi.startRefund(this.basketItemId);
      }
      if (response && response.status === 204) {
        EventBus.$emit('refresh-basket-status');
      }
    } catch (error) {
      BasketCancellationStore.setProcessErrors(error);
      BasketCancellationStore.setShowError(true);
    } finally {
      if (this.selectedOption === CancellationMethods.void) {
        BasketCancellationStore.setProcessing(false);
      }
    }
  }

  async loadRefundDetails() {
    await BasketCancellationStore.getBritishAirwaysOfferCancelationConditions(this.recomedationId);
  }

  async finalizeRefund() {
    this.refundErrors = [];
    BasketCancellationStore.setProcessing(true);
    try {
      const refundResponse = await RefundBasketItemApi.acceptRefundCancellationConditions(this.basketItemId);
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
        this.hidePopup();
      }
    } catch (error) {
      this.refundErrors = this.$handleErrors(error);
    } finally {
      BasketCancellationStore.setProcessing(false);
    }
  }

  async exitRefundPopup() {
    this.refundErrors = [];
    let refundResponse;
    BasketCancellationStore.setProcessing(true);
    try {
      refundResponse = await RefundBasketItemApi.rejectRefund(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      if (refundResponse && refundResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
      }
      BasketCancellationStore.setProcessing(false);
      this.hidePopup();
    }
  }

  async answerRefundConditions(decision: boolean) {
    const message: RefundDecisionMessage = {
      itemId: this.basketItemId,
      isConfirmed: decision
    };
    try {
      await RefundBasketItemApi.sendCouponDecision(message);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async answerRefundConditionsErrors() {
    try {
      await RefundBasketItemApi.answerCouponDecisionError(this.basketItemId);
    } catch (error) {
      BasketStore.setErrors(this.$handleErrors(error));
    } finally {
      EventBus.$emit('refresh-basket-status');
      this.hidePopup();
    }
  }

  async init() {
    BasketCancellationStore.setBritishAirwaysCancelationDetails(null);
    BasketCancellationStore.setBritishAirwaysRefundDetailsLoaded(false);
    await BasketCancellationStore.getAirDetails(this.item.providerReferenceId);
    await BasketCancellationStore.getItemCoupons(this.basketItemId);
  }

  @Watch('commissionError')
  onCommissionErrorChange(val) {
    this.isCommissionValid = !val;
  }

  created() {
    BasketCancellationStore.setProcessing(false);
    this.init();
  }

  beforeDestroy() {
    BasketCancellationStore.setProcessErrors([]);
    BasketCancellationStore.setProcessWarning(null);
    BasketCancellationStore.setShowError(false);
  }

  @Emit(eventName)
  hidePopup() {
    return false;
  }
}

