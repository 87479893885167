



















import { Vue, Component } from 'vue-property-decorator';
import EventHandler from '@/services/event-handler';
import _ from 'lodash';

@Component({})
export default class UiToast extends Vue {
  toastList: any[] = [];
  data = {
    type: null
  };

  classes(toast) {
    return toast.type ? (toast.type === 'error' ? 'toast__error' : 'toast__success index') : '';
  }

  removeClass(toast) {
    setTimeout(() => {
      toast.className += ' hideToast';
        setTimeout(() => {
          this.toastList.shift();
        }, 1000);
    }, 4000);
  }

  removeToast() {
    let self = this;
    let toasts = document.getElementsByClassName('toast');

    _.forEach(toasts, (toast) => {
      this.removeClass(toast);
    });
  }

  created() {
    EventHandler.$on('show-toast', this.onReceive);
  }

  onReceive(data) {
    data.id = '_' + Math.random().toString(36).substr(2, 9);
    this.toastList.push(data);
    setTimeout(() => {
      this.removeToast();
    });
  }

  beforeDestroy() {
    this.toastList = [];
    EventHandler.$off('show-toast', this.onReceive);
  }
}
