export default {
  rowHeight: 15,
  colWidth: 15,
  margin: 30,
  thickness: 2,

  legend: {
    available: {
      legend: true,
      height: 2,
      width: 2,
      x: 0,
      y: 0,
      seatAvailability: 'Open',
    },
    paid: {
      legend: true,
      height: 2,
      width: 2,
      x: 0,
      y: 3,
      seatAvailability: 'Open',
      prices: [{
        price: {
          amount: 1,
        }
      }],
    },
    taken: {
      legend: true,
      height: 2,
      width: 2,
      x: 0,
      y: 6,
      seatAvailability: 'Reserved',
    },
    restricted: {
      legend: true,
      height: 2,
      width: 2,
      x: 0,
      y: 9,
      seatAvailability: 'Restricted',
    },
    lavatory: {
      legend: true,
      x: 0,
      y: 12,
      width: 2,
      height: 2,
    },
    luggage: {
      legend: true,
      x: 0,
      y: 15,
      width: 2,
      height: 2,
    },
    exit: {
      legend: true,
      x: 0,
      y: 0,
      width: 1,
      height: 2,
    },
    window: {
      legend: true,
      x: 0,
      y: 0,
      width: 1,
      height: 2,
    },
    table: {
      legend: true,
      x: 0,
      y: 24.5,
      width: 2,
      height: 1,
    },
  },

  sncf: {
    primaryColor: '#192A4A',
    secondaryColor: '#7E7F74',
    ternaryColor: '#4e5053',
    quaternaryColor: '#7E7F74',
  },
};
