import { Table2DataRow } from './table2';
import { Table2Params } from './table2.params';
import Data2 from '../data2.json';

export class Table2ItemsService {
  maxCount: number = Data2.length;

  filter(params: Table2Params): Table2DataRow[] {
    // filtering
    const filtered = Data2
      .filter((item) => {
        return true;
      });
    this.maxCount = filtered.length;
    return filtered;
  }

  sort(params: Table2Params, items: Table2DataRow[]): Table2DataRow[] {
    const sorted = items.sort((a, b) => {
      if (!params || !params.sort) {
        return 1;
      }
      const decision = (key) => {
        if (a[key] === b[key]) {
          return 0;
        }
        const result = a[key] > b[key];
        if (params.desc) {
          return result ? 1 : -1;
        }
        return result ? -1 : 1;
      };
      return decision(params.sort);
    });
    return sorted;
  }

  page(params: Table2Params, items: Table2DataRow[]): Table2DataRow[] {
    if (!params || undefined === params.page || !params.size) {
      return items;
    }
    // paging
    const paged = items
      .slice(params.page, params.page + params.size);
    return paged;
  }
}