



















import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import { router } from '@/router';
import { PreferencesApi } from '@/api/profile/preferences.api';
import { PreferencesModel } from '@/api/profile/preferences.model';
import GeneralPreferencesSection from './GeneralPreferencesSection.vue';
import FlightPreferencesSection from './FlightPreferencesSection.vue';
import AccommodationPreferencesSection from './AccommodationPreferencesSection.vue';
import RailPreferencesSection from './RailPreferencesSection.vue';
import CarPreferencesSection from './CarPreferencesSection.vue';
import PreferencesStore from './preferences.store';
import EventHandler from '@/services/event-handler';
import _ from 'lodash';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    GeneralPreferencesSection,
    FlightPreferencesSection,
    AccommodationPreferencesSection,
    RailPreferencesSection,
    CarPreferencesSection,
  }
})
export default class Preferences extends Vue {

  get serverErrors() {
    return PreferencesStore.serverErrors;
  }

  get showError() {
    return PreferencesStore.showError;
  }

  get preferences() {
    return PreferencesStore.preferences;
  }

  onReceive() {
    let profileId = this.$route.params.id;
    PreferencesStore.loadPreferences(profileId);
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    let profileId = this.$route.params.id;
    PreferencesStore.loadPreferences(profileId);
  }

  mounted() {
    EventBus.$on('refresh-preferences', this.onReceive);
  }

  beforeDestroy() {
    EventBus.$off('refresh-preferences', this.onReceive);
  }
}
