














































































































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import moment from 'moment';
import AccountStore from '@/store/account.store';

@Component({
  filters: {
    moment(date) {
      return moment(date).format('hh:mm');
    },
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    momentTime(date) {
      return moment(date).format('HH:mm');
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
    monthDate(date) {
      return moment(date).format('MMMM');
    },
    dayStringDate(date) {
      return moment(date).format('dddd');
    },
  }
})
export default class AirLegTimeline extends Vue {
  @Prop() leg!: any;
  @Prop() value!: boolean;
  @Prop({ default: false }) flightSelected!: Boolean;
  @Prop({ default: false }) isOneWay!: boolean;

  internalFlightSelected = this.flightSelected;

  get isAnotherDay() {
    let startOfDay = moment(this.leg.flights[0].departure).startOf('day');
    let endOfDay = moment(this.leg.flights[this.leg.flights.length - 1].arrival).endOf('day');

    return endOfDay.diff(startOfDay, 'days');
  }

  get totalTime() {
    let time = 0;
    if (this.leg && this.leg.flights) {
      this.leg.flights.forEach(flight => {
        time += flight.duration;
      });
    }

    return time;
  }

  get user() {
    return AccountStore.current;
  }

  get numberOfTechStops() {
    return this.leg.flights.map(flight => {
      return flight.additionalStops && flight.additionalStops.length || 0;
    }).reduce((prev, cur) => prev + cur, 0);
  }

  toggleDetails() {
    this.$emit('input', !this.value);
  }

  setFlightSelectedState(state) {
    this.internalFlightSelected = state;
  }

  dayNumberDate(date) {
    if (!date) {
      return null;
    }
    if (this.user && this.user.profile.displayLanguage === 'en' || this.user && this.user.profile.displayLanguage === 'fr' || this.user && this.user.profile.displayLanguage === 'pl') {
      return moment(date).format('D');
    } else {
      return moment(date).format('Do');
    }
  }

  dayNumberLastDate(date) {
    if (date && this.user && this.user.profile.displayLanguage === 'en') {
      return moment(date).format('Do').slice(-2);
    } else {
      return null;
    }
  }
}
