
















































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { CompanyModel } from '@/api/profile/company.model';
import settingsStore from './settings.store';

@Component({})
export default class SettingsIntro extends Vue {
  switchActive: boolean = false;
  companiesLoading: boolean = false;
  skip: boolean = false;
  model: CompanyModel | null = this.companyModel;

  get currentCompany() {
    return settingsStore.currentCompany;
  }

  get companyLoaded() {
    return settingsStore.loaded;
  }

  get companies() {
    return settingsStore.companies;
  }

  get serverErrors() {
    return settingsStore.serverErrors;
  }

  get breadcrumbstCompany() {
    return settingsStore.breadcrumbstCompany;
  }

  get companyModel() {
    return this.currentCompany ? {
      name: this.currentCompany.companyName,
      code: this.currentCompany.companyCode,
      id: this.currentCompany.companyId,
      isDisabled: false,
      parentId: null,
      rootId: this.currentCompany.rootCompanyId,
      isSelected: false,
      phoneNumber: this.currentCompany.phoneNumber,
      faxNumber: this.currentCompany.faxNumber,
      address: this.currentCompany.address,
      emailAddress: this.currentCompany.emailAddress
    } : null;
  }

  get agencyAssigned() {
    return settingsStore.agencyAssigned;
  }

  get IsAgency() {
    return settingsStore.isAgency;
  }

  get isRootCompany() {
    return settingsStore.isRootCompany;
  }

  get canShowCustomError() {
    return settingsStore.canShowCustomError;
  }

  async toggleSwitchCompany() {
    if (!this.switchActive && this.model && this.model.id !== this.currentCompany!.companyId) {
      this.skip = true;
      this.model = this.companyModel;
    }
    this.switchActive = !this.switchActive;
    setTimeout(() => {
      if (this.$refs && this.$refs.cancelLink) {
        (this.$refs.cancelLink as HTMLElement).blur();
      }
    });
  }

  get agencyLink() {
    if (!this.agencyAssigned) {
      return '';
    }

    return `/settings/${this.agencyAssigned.id}/`;
  }

  get agencyLinkSanitized() {
    return sanitizeUrl(this.agencyLink);
  }

  gotoGlobalSettings() {
    this.$router.push('/global-settings');
  }

  createCompany() {
    this.$router.push('/settings/current/create-company');
  }

  editAgency(item) {
    this.$router.push({
      name: 'company-info',
      params: {
        id: item.id
      }
    });
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'companiesLoading',
  })
  async loadCompanies(phrase: string) {
    await settingsStore.loadCompanies(phrase);
    this.companiesLoading = false;
  }

  @Watch('model')
  switchCompany(value) {
    if (this.skip) {
      this.skip = false;
      return;
    }
    settingsStore.switchCompany(value.id);

    this.switchActive = false;
  }
}
