












































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import BasketStore from './basket.store';
import BasketItem from './BasketItem.vue';
import BasketModifyTraveller from './BasketModifyTraveller.vue';

@Component({
  components: {
    BasketItem,
    BasketModifyTraveller,
  }
})
export default class BasketModifyTravellersDetails extends Vue {
  @Prop() item!: any;



  get isTravellersDetailsLoading() {
    return BasketStore.isModifyTravellersDetailsLoading;
  }

  get travellersDetails() {
    return BasketStore.modifyTravellersDetails;
  }

  get basket() {
    return BasketStore.basket;
  }

  get errors() {
    return BasketStore.modifyTravellersDetailsLoadingErrors;
  }

  get saveErrors() {
    return BasketStore.modifyTravellersDetailsSaveErrors;
  }

  get itemId() {
    return BasketStore.bookingStepDef.tripItemId;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }



  @Watch('bookingStep', { immediate: true })
  onBookingStepChange() {
    BasketStore.loadModifyTravellersDetails({
      itemId: this.item.id,
      recommendationId: this.item.providerReferenceId,
    });
  }
}
