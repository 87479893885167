export function ToTwoDigitStringValue(numberValue) {
    let result = Math.floor(numberValue) + '';
    if (result.length === 1) {
      result = '0' + result;
    }
    return result;
  }

export function FromTwoDigitStringValue(dateString: string) {
  let times = dateString.split(':');
  let result = parseInt(times[0]) * 60 + parseInt(times[1]);
  return result;
}

export const isCancelError = (error) => {
  if (error.__proto__ && error.__proto__.__CANCEL__ === true) {
    return true;
  }
  return false;
};

export const sortByName = (arrayToSort) => {
  return arrayToSort.sort((a, b) => {
    let result = 0;
    if (a.name < b.name) {
      result = -1;
    } else if (a.name > b.name) {
      result = 1;
    }
    return result;
  });
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    const cont = await callback(array[index], index, array);
    if (cont === false) {
      break;
    }
  }
};
