






















import _ from 'lodash';

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { OfferParameter } from '@/api/trip/basket-item.model';
import BasketStore from '@/modules/basket/basket.store';

import ParameterBoolean from '@/modules/basket/parameters/parameter-boolean.vue';
import ParameterFormattedtext from '@/modules/basket/parameters/parameter-formattedtext.vue';
import ParameterMultiselect from '@/modules/basket/parameters/parameter-multiselect.vue';
import ParameterOther from '@/modules/basket/parameters/parameter-other.vue';
import ParameterRemark from '@/modules/basket/parameters/parameter-remark.vue';
import ParameterSingleselect from '@/modules/basket/parameters/parameter-singleselect.vue';
import ParameterText from '@/modules/basket/parameters/parameter-text.vue';
import ParameterCountrycode from '@/modules/basket/parameters/parameter-countrycode.vue';

@Component({
  components: {
    ParameterBoolean,
    ParameterFormattedtext,
    ParameterMultiselect,
    ParameterOther,
    ParameterRemark,
    ParameterSingleselect,
    ParameterText,
    ParameterCountrycode,
  }
})
export default class CustomParameters extends Vue {
  @Prop() itemId!: string;

  customParameters: OfferParameter[] = [];

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get isBasketReady() {
    return BasketStore.basket ? true : false;
  }

  get travellers() {
    return BasketStore.basket ? BasketStore.basket.travellers : [];
  }

  get parametersFiltered() {
    return this.customParameters.filter(p => p.type !== 'Remark' && p.type !== 'Other' && p.isPerTraveler && p.key !== 'DateOfBirth');
  }

  get canShowTravellerFields() {
    return this.parametersFiltered.length > 0;
  }



  userFullName(user) {
    return userFullName(user);
  }

  passengerFormParameters(profileId: string) {
    return _.sortBy(this.parametersFiltered.filter(p => p.isPerTraveler && p.profileId === profileId), 'key');
  }

  parameterComponent(parameter: OfferParameter) {
    return `parameter-${parameter.type.toLowerCase()}`;
  }

  async loadItemCustomParameters() {
    let parameters = await BasketStore.loadItemCustomParameters(this.itemId);

    this.customParameters = parameters;
  }

  @Watch('customParameters', { deep: true })
  onCustomParametersChange(value) {
    let selectedAncillaries: any[] = [];
    value.forEach(param => {
      if (
        -1 < ['singleselect', 'multiselect'].indexOf(param.type.toLowerCase()) &&
        param.value &&
        param.profileId !== null
      ) {
        if (param.type.toLowerCase() === 'singleselect') {
          const value = param.options.find(option => param.value === option.key);

          if (value && value.price) {
            selectedAncillaries.push({
              itemId: param.itemId,
              profileId: param.profileId,
              totalPrice: value.price,
            });
          }
        } else {
          const valueKeys = param.value.split(';');
          const values = param.options.filter(option => -1 < valueKeys.indexOf(option.key));

          values.forEach(value => {
            if (value.price) {
              selectedAncillaries.push({
                itemId: param.itemId,
                profileId: param.profileId,
                totalPrice: value.price,
              });
            }
          });
        }
      }
    });
    BasketStore.setSelectedAncillaries(selectedAncillaries);
  }

  @Watch('bookingStep', { immediate: true })
  onStepEnter() {
    this.loadItemCustomParameters();
  }
}
