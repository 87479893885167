






























































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import PaymentMethodsTrainStore from './payment-methods-train.store';
import { SupplierEnum } from '@/api/train-engine/payment-methods-configuration.model';
import { AirNegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';

@Component({})
export default class PaymentTrainConfiguration extends Vue {
  $v;
  validated: boolean = false;

  Form: any = {
    supplier: null,
    isCentralPaymentEnabled: false,
    isUserCardPaymentEnabled: false,
    isLodgeCardPaymentEnabled: false,
    defaultPaymentMethod: null,
    apiAccountNumber: '',
    enablePaymentGateway: false,
    paymentGatewayOption: 'TotalBookingAmount',
    hidePaymentMethodIfItIsNotNeeded: false,
  };

  providerOptions: any[] = [
    SupplierEnum.Ntv,
    SupplierEnum.Trenitalia,
    SupplierEnum.Sncf,
    SupplierEnum.Ouigo,
    SupplierEnum.Benerail,
  ];

  @Validation()
  validationObject() {
    return {
      Form: {
        supplier: {
          required,
        },
        isCentralPaymentEnabled: {
        },
        isUserCardPaymentEnabled: {
        },
        defaultPaymentMethod: {
        },
        apiAccountNumber: {
          required: requiredIf(() => {
            return this.shouldShowAccountNumber;
          })
        },
      }
    };
  }

  get readOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get filterProviderList() {
    if (this.currentPaymentList && this.currentPaymentList.length && this.isNew) {
      return this.providerOptions.filter(e => !this.currentPaymentList.some(a => a.supplier === e && a.succeeded));
    }

    return this.providerOptions;
  }

  get filteredTranslatedProviderList() {
    return this.filterProviderList.map(supplier => {
      return {
        value: supplier,
        label: SupplierName(supplier),
      };
    });
  }

  get numberOfPaymentMethodsEnabled() {
    if (!this.Form.supplier) {
      return 0;
    }

    const paymentMethodsEnabled = [
      this.Form.isCentralPaymentEnabled,
      this.Form.isUserCardPaymentEnabled,
      this.Form.isLodgeCardPaymentEnabled,
    ];

    const paymentMethodsNumberByProvider = {
      Ntv: [0, 1, 2],
      Trenitalia: [0],
      Sncf: [0],
      Ouigo: [0],
      Benerail: [0],
    };

    return paymentMethodsEnabled.filter((item, index) => {
      return paymentMethodsNumberByProvider[this.Form.supplier].includes(index) && item;
    }).length;
  }

  get isHidePaymentMethodDisabled() {
    return (this.numberOfPaymentMethodsEnabled !== 1) ||
      this.Form.isUserCardPaymentEnabled;
  }

  get selectedSupplier() {
    return this.filteredTranslatedProviderList.find(item => item.value === this.Form.supplier) || null;
  }

  set selectedSupplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      return;
    }

    this.Form.supplier = value.value;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return PaymentMethodsTrainStore.configurationName;
  }

  get selectedPayment() {
    return PaymentMethodsTrainStore.selectedPayment;
  }

  get providers() {
    return AirNegoFareConfigurationSupplierEnum;
  }

  get currentPaymentList() {
    return PaymentMethodsTrainStore.paymentList;
  }

  get isConfigNtv() {
    return this.Form.supplier ? this.Form.supplier === SupplierEnum.Ntv : false;
  }

  get isConfigTrenitalia() {
    return this.Form.supplier ? this.Form.supplier === SupplierEnum.Trenitalia : false;
  }

  get isConfigSncf() {
    return this.Form.supplier ? this.Form.supplier === SupplierEnum.Sncf : false;
  }

  get isConfigOuigo() {
    return this.Form.supplier ? this.Form.supplier === SupplierEnum.Ouigo : false;
  }

  get isConfigBenerail() {
    return this.Form.supplier ? this.Form.supplier === SupplierEnum.Benerail : false;
  }

   get shouldShowAccountNumber() {
    return this.isConfigNtv;
  }

  get isNew() {
    if (this.selectedPayment) {
      return Object.entries(this.selectedPayment as any).length === 0;
    }
    return false;
  }

  goToConfiguration() {
    PaymentMethodsTrainStore.setIsFromManage(true);
    router.push({
      name: 'payment-methods-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      this.Form = {
        supplier: data.supplier,
        isCentralPaymentEnabled: data.isCentralPaymentEnabled,
        isUserCardPaymentEnabled: data.isUserCardPaymentEnabled,
        isLodgeCardPaymentEnabled: data.isLodgeCardPaymentEnabled,
        defaultPaymentMethod: data.defaultPaymentMethod,
        apiAccountNumber: data.apiAccountNumber,
        enablePaymentGateway: !!data.paymentGatewayOption ? true : false,
        paymentGatewayOption: data.paymentGatewayOption ? data.paymentGatewayOption : 'TotalBookingAmount',
        hidePaymentMethodIfItIsNotNeeded: data.hidePaymentMethodIfItIsNotNeeded,
      } as any;
    } else {
      this.Form = {
        supplier: null,
        isCentralPaymentEnabled: false,
        isUserCardPaymentEnabled: false,
        isLodgeCardPaymentEnabled: false,
        defaultPaymentMethod: null,
        apiAccountNumber: '',
        enablePaymentGateway: false,
        paymentGatewayOption: 'TotalBookingAmount',
        hidePaymentMethodIfItIsNotNeeded: false,
      } as any;
    }
  }

  createPayment() {
    let item = {
      supplier: this.Form.supplier,
      isCentralPaymentEnabled: this.Form.isCentralPaymentEnabled,
      isUserCardPaymentEnabled: this.Form.isUserCardPaymentEnabled,
      isLodgeCardPaymentEnabled: this.Form.isLodgeCardPaymentEnabled,
      paymentGatewayOption: this.Form.enablePaymentGateway ? this.Form.paymentGatewayOption : null,
      hidePaymentMethodIfItIsNotNeeded: this.Form.hidePaymentMethodIfItIsNotNeeded,
      defaultPaymentMethod: 
        this.Form.isCentralPaymentEnabled === false &&
        this.Form.isUserCardPaymentEnabled === false &&
        this.Form.isLodgeCardPaymentEnabled === false
        ? 'None'
        : this.Form.defaultPaymentMethod,
      status: PaymentMethodsTrainStore.draftNewStatus,
      apiAccountNumber: this.shouldShowAccountNumber ? this.Form.apiAccountNumber : '',
      succeeded: true,
      id: _.uniqueId()
    };
    if (
      Object.entries(this.selectedPayment as any).length === 0 || 
      !this.currentPaymentList.find(entry => {
        return entry.supplier === item.supplier;
      })
    ) {
      PaymentMethodsTrainStore.addSelectedPayment(item);
    } else {
      PaymentMethodsTrainStore.editSelectedPayment(item);
    }
  }

  confirmAndGoBack() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.createPayment();
      this.goToConfiguration();
    }
  }

  @Watch('selectedPayment', { deep: true, immediate: true })
  onCodeChange(payment) {
    if (!payment) {
      router.go(-1);
    }
  }

  @Watch('isHidePaymentMethodDisabled', { immediate: true })
  onIsHidePaymentMethodDisabledChanged() {
    if (this.isHidePaymentMethodDisabled) {
      this.Form.hidePaymentMethodIfItIsNotNeeded = false;
    }
  }

  paymentMethodChanged(value, paymentType) {
    if (value && this.Form.defaultPaymentMethod) {
      return;
    }
    if (value && !this.Form.defaultPaymentMethod) {
      this.Form.defaultPaymentMethod = paymentType;
      return;
    }
    if (!value && this.Form.defaultPaymentMethod === paymentType) {
      if (this.Form.isCentralPaymentEnabled) {      
        this.Form.defaultPaymentMethod = 'CentralPayment';
      } else if (this.Form.isUserCardPaymentEnabled) {
        this.Form.defaultPaymentMethod = 'PaymentCard';
      } else if (this.Form.isLodgeCardPaymentEnabled) {
        this.Form.defaultPaymentMethod = 'LodgeCard';
      } else {
        this.Form.defaultPaymentMethod = null;
      }
    }
  }

  created() {
    if (this.selectedPayment) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedPayment)));
    } else {
      this.mapFormData();
    }
  }
}
