





















































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Emit, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, helpers, maxValue, numeric, requiredIf, decimal } from 'vuelidate/lib/validators';
import moment from 'moment';
import { router } from '@/router';
import accountStore from '@/store/account.store';
import routes from '@/router/routes';
import EventHandler from '@/services/event-handler';
import { 
  SearchExpense, 
  ExpenseCategoryResult, 
  ExpenseAmount, 
  MissionNamesResult, 
  ExpectedExpenseModel,
  ExpenseState 
} from '@/api/expense/expense.model';
import formatDateMoment from '@/filters/format-data-moment.filter';
import { ExpenseApi } from '@/api/expense/expense.api';
import { CurrencyApi } from '@/api/currency/currency.api';
import _ from 'lodash';
import { translate } from '@/i18n';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import settings from '@/settings';
import ExpenseImagePopup from './ExpenseImagePopup.vue';
import ExpenseStore from '../expense.store';
import ExpenseCustomFields from '../ExpenseCustomFields.vue';
import dictionaryStore from '@/store/dictionary.store';
import EventBus from '@/services/event-handler';
import { Permission } from '@/const/permission.enum';

const priceRegex = value => {
  return /^(-?\d{1,10})(\.\d{1,2})?$/.test(value);
};

const validationResultIcon = {
  Risk: 'notification_important',
  Violation: 'block',
  Warning: 'warning',
  Information: 'info',
};

const sourceTypeIcon = {
  ManualEntry: 'account_balance_wallet', 
  OcrReceipt: 'account_balance_wallet', 
  TripItem: 'card_travel', 
  BankTransaction: 'payments',
};

@Component({
   components: {
    ExpenseImagePopup,
    ExpenseCustomFields,
  },
})
export default class AddExpense extends Vue {
  ExpenseForm: SearchExpense = new SearchExpense({});

  activeTab: string = 'expense-data';
  amountOptions = [
    'net',
    'gross',
  ];
  expenseTabs = [
    {
      label: translate('expense-add.expense-data'),
      value: 'expense-data',
    },
    {
      label: translate('expense-add.comments'),
      value: 'comments',
    },
    {
      label: translate('expense-add.attachments'),
      value: 'attachments',
    },
    {
      label: translate('expense-add.history'),
      value: 'history',
    }
  ];
  $v;
  typeAmount: string = 'net';
  formPending: boolean = false;
  serverErrors: any[] = [];
  editMode: boolean = false;
  expenseId: string | null = null;
  deleteExpensePopup: boolean = false;
  expenseRemovalErrorMessage: string | null = null;
  seletedCategory: ExpenseCategoryResult | null = null;
  selectedMission: MissionNamesResult | null = null;
  sourceVatAmount: any = null;
  expenseAmount: any = {
    grossValue: {
      amount: null,
      currency: null,
    },
    netValue:  {
      amount: null,
      currency: null,
    },
    vatAmount:  {
      amount: null,
      currency: null,
    }
  };
  expenseCode: string = '';
  sourceType: string | null = null;
  expectedCurrency: string = 'EUR';
  exchangedPrice: any | null = null;
  exchangedWatchEnabled: boolean = false;
  imageSize: string = 'Large';
  expenseImage: string = '';
  missionId: string | null = null;
  expenseImageFormat: string = '';
  BASE64_STRING_PREFIX: string = '';
  expenseType: string = 'Expenditure';
  expectedExpenseModel: ExpectedExpenseModel | null = null;
  seletedProject: any | null = null;
  seletedPaymentInstrument: any | null = null;
  showCancellationExpensePopup: boolean = false;
  loading: boolean = false;
  selectedCountry: any | null = null;

  get tabNames() {
    return this.expenseTabs;
  }

  get currentProfileId() {
    return accountStore.CurrentUser!.profile.id;
  }

  get companyId() {
    return ExpenseStore.companyId;
  }

  get rootCompanyId() {
    return ExpenseStore.rootCompanyId;
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get showExpenseImagePopup() {
    return ExpenseStore.showExpenseImagePopup;
  }

  get showClaimedExpensePopup() {
    return ExpenseStore.showClaimedExpensePopup;
  }

  get currencyOptions() {
    return ExpenseStore.currencyOptions;
  }

  get categoryOptions() {
    return ExpenseStore.categoryOptions;
  }

  get paymentInstrumentsOptions() {
    return ExpenseStore.paymentInstrumentsOptions;
  }

  get projectsOptions() {
    return ExpenseStore.projectsOptions;
  }

  get compoundProperty() {
    return [this.ExpenseForm.sourceAmount.value.amount, this.ExpenseForm.sourceAmount.vatRate, this.typeAmount].join();
  }

  get exchangedPriceGroup() {
    return [this.ExpenseForm.sourceAmount.value.amount, this.ExpenseForm.sourceAmount.vatRate, this.typeAmount, this.ExpenseForm.sourceAmount.value.currency, this.ExpenseForm.incurrenceDate].join();
  }

  get missionNamesList() {
    return ExpenseStore.missionNamesList;
  }

  get showPaymentInstrument() {
    return ExpenseStore.ifPaymentInstrument;
  }

  get ifPaymentInstrumentRequired() {
    return ExpenseStore.paymentInstrumentRequired;
  }

  get showProject() {
    return ExpenseStore.ifProject;
  }

  get ifProjectRequired() {
    return ExpenseStore.projectRequired;
  }

  get ifPaymentInstrumentRequiredToClaimed() {
    return ExpenseStore.ifPaymentInstrumentRequiredToClaimed;
  }

  get ifProjectRequiredToClaimed() {
    return ExpenseStore.ifProjectRequiredToClaimed;
  }

  get customFieldConditions() {
    return ExpenseStore.customFieldConditions;
  }

  get customFieldTransitionRules() {
    return ExpenseStore.customFieldTransitionRules;
  }

  get disabledForm() {
    return this.ExpenseForm.state !== ExpenseState.Draft && this.ExpenseForm.state !== ExpenseState.Violation;
  }

  get showSaveButton() {
    return this.ExpenseForm.state === ExpenseState.Draft;
  }

  get showClaimAgainButton() {
    return this.ExpenseForm.state === ExpenseState.Violation;
  }

  get showCancelButton() {
    return this.ExpenseForm.state === ExpenseState.Claimed || this.ExpenseForm.state === ExpenseState.Violation || this.ExpenseForm.state === ExpenseState.Completed;
  }

  get showConfigurationError() {
    return ExpenseStore.showConfigurationError;
  }

  get allCountries() {
    return dictionaryStore.allCountries;
  }

  get validationResultsList() {
    return ExpenseStore.validationResults;
  }

  @Validation()
  validationObject() {
    return {
      ExpenseForm: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        description: {
          maxLength: maxLength(1024),
        },
        sourceAmount: {
          value: {
            amount: {
              required,
              priceRegex,
            },
            currency: {
              required,
            },
          },
          vatRate: {
            required,
            maxValue: maxValue(27),
            numeric,
          },
        },
        incurrenceDate: {
          required, 
        },
        categoryId: {},
        merchant: {
          maxLength: maxLength(128),
        }
      },
      seletedCategory: {
          required,
      },
      selectedCountry: {
          required,
      },
      seletedProject: {
        required: requiredIf(() => {
          return this.ifProjectRequired && this.showProject;
        }),
      },
      seletedPaymentInstrument: {
         required: requiredIf(() => {
          return this.ifPaymentInstrumentRequired && this.showPaymentInstrument;
        })
      },
      customFieldConditions: {
        $each: {
          value: { 
            required: requiredIf(function(this: any, vm) {
              return vm.isRequired;
            }),
            ifRegex(value, vm) {
              if (vm.validationRegExpRule !== '' && (vm.type.type !== 'Dictionary' && vm.type.type !== 'Bool')) {
                let newValidationRegExpRule = new RegExp(vm.validationRegExpRule);
                if (value) {
                  return ( newValidationRegExpRule.test(value) );
                } else {
                  return true;
                }
              } else {
                return true;
              }
            }
          }
        }
      }
    };
  }

  validationResultClasses(validationResult) {
    return {
      'error': validationResult.severity === 'Violation',
      'warning': validationResult.severity === 'Warning',
      'info-level': validationResult.severity === 'Information',
      'risk': validationResult.severity === 'Risk',
    };
  }

  validationResultIcons(validationResult) {
    return validationResultIcon[validationResult.severity];
  }

  stateClasses(state) {
    return {
      'counter--empty': ExpenseState.Draft === state,
      'counter--orange': ExpenseState.Claimed === state,
      'counter--grey': ExpenseState.Cancelled === state,
      'counter--green': ExpenseState.Completed === state,
      'counter--green-grey': ExpenseState.Closed === state,
      'counter--red': ExpenseState.Rejected === state,
    };
  }

  sourceTypeIcons(sourceType) {
    return sourceTypeIcon[sourceType];
  }

  @Watch('$route.params.expenseId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  close() {
    router.push({
      name: 'expense-list',
    });
  }

  cancelExpense() {
    this.showCancellationExpensePopup = true;
  }

  backToExpense() {
    this.showCancellationExpensePopup = false;
  }

  sendCancellationExpense() {
    this.ExpenseForm.state = ExpenseState.Cancelled;
    this.submit();
    this.showCancellationExpensePopup = false;
  }

  onSelectCountry() {
    const selection = this.$refs.expenseCountry as Vue;
    const selectionEl = selection.$el.children[0].getElementsByTagName('input')[0] as HTMLElement;
    
    if (selectionEl) {
      setTimeout(() => {
        selectionEl.blur();
      });
    }
  }

  async getExchangedPrice() {
    if (this.ExpenseForm.sourceAmount && this.ExpenseForm.sourceAmount.value.currency && this.ExpenseForm.incurrenceDate) {
      const sourceCurrency = this.ExpenseForm.sourceAmount.value.currency.code;
      const sourcePrice = this.ExpenseForm.sourceAmount.value.amount;
      const exchangeDate = formatDateMoment(this.ExpenseForm.incurrenceDate);
      const targetCurrency = this.expectedCurrency;
      try {
        const response = await CurrencyApi.getExchangedPrice({sourceCurrency, sourcePrice, targetCurrency, exchangeDate});
        this.exchangedPrice = response.data;      
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }
    }
  }

  async initView() {
    this.loading = true;
    this.sourceVatAmount = null;
    this.exchangedPrice = null;

    await ExpenseStore.getDefaultExpensePolicy(Permission.WriteExpense);
    
    await ExpenseStore.getCurrencies();

    const request = {
      expenseAssignmentAllowed: true,
      policyId: this.policyId
    };
    await ExpenseStore.getMissionsNames(request);
    await ExpenseStore.getPaymentInstruments();
    await ExpenseStore.getProjects();

    this.expenseId = this.$route.params.expenseId;
    this.missionId = this.$route.params.missionId;

    if (this.missionId) {
      this.selectedMission = this.missionNamesList.find(missionName => { return missionName.id === this.missionId; })!;
    }

    if (this.expenseId) {
      this.editMode = true;
      this.exchangedWatchEnabled = true;
      const response = await ExpenseApi.getExpenseItem(this.expenseId);
      this.ExpenseForm = new SearchExpense(response.data);
      await ExpenseStore.getExpectedExpenseModel(this.ExpenseForm.state);
      if (this.ExpenseForm.state !== ExpenseState.Draft && this.ExpenseForm.state !== ExpenseState.Cancelled) {
        const params = {
          missionId: this.ExpenseForm.mission.id,
          expenseId: this.expenseId
        };
        await ExpenseStore.getValidationsResults(params);
      }
      
      if (response.data.expenseAmount) {
        this.expenseAmount = response.data.expenseAmount;
      }
      this.expenseCode = response.data.code;
      this.sourceType = response.data.sourceType;

      if (this.ExpenseForm.countryCode) {
        this.selectedCountry = this.allCountries!.find(country => { return country.code === this.ExpenseForm.countryCode; })!;
      }

      if (this.ExpenseForm.paymentInstrumentId) {
        this.seletedPaymentInstrument = this.paymentInstrumentsOptions.find(payment => { return payment.id === this.ExpenseForm.paymentInstrumentId; })!;
      }

      if (this.ExpenseForm.projectId) {
        this.seletedProject = this.projectsOptions.find(project => { return project.id === this.ExpenseForm.projectId; })!;
      }

      if (this.ExpenseForm.mission) {
        this.selectedMission = this.ExpenseForm.mission;
      }
      if (this.ExpenseForm.sourceAmount.grossProvided === false) {
        this.typeAmount = 'net';
      } else if (this.ExpenseForm.sourceAmount.grossProvided === true) {
        this.typeAmount = 'gross';
      }
      await this.getExchangedPrice();
      this.exchangedWatchEnabled = false;

      if (this.ExpenseForm.customFieldValues && this.ExpenseForm.customFieldValues.length) {
        for (let i = 0; i < this.ExpenseForm.customFieldValues.length; i++) {
          for (let j = 0; j < this.customFieldConditions.length; j++) {
            if (this.ExpenseForm.customFieldValues[i].customFieldDefinitionId === this.customFieldConditions[j].customFieldDefinitionId) {
              if (this.ExpenseForm.customFieldValues[i].customFieldDictionaryItemId) {
                this.customFieldConditions[j].value = this.customFieldConditions[j].customFieldDictionaryItems.find(
                  item => { return item.id === this.ExpenseForm.customFieldValues[i].customFieldDictionaryItemId; })!;
              } else if (this.ExpenseForm.customFieldValues[i].type === 'Bool') {
                if (this.ExpenseForm.customFieldValues[i].value === 'true') {
                  this.customFieldConditions[j].value = true;
                } else {
                  this.customFieldConditions[j].value = false;
                }
              }  else {
                this.customFieldConditions[j].value = this.ExpenseForm.customFieldValues[i].value;
              }
            }
          }
        }
      }

      if (this.ExpenseForm.hasReceipt) {
        this.expenseImage = '';
        this.expenseImageFormat = '';
        const imageResponse = await ExpenseApi.getExpenseReceiptImage(this.expenseId, this.imageSize);
        if (imageResponse.status === 200) {
          this.expenseImageFormat = imageResponse.data.format;

          await this.setBase64Prefix();
          
          this.setExpenseImage(this.BASE64_STRING_PREFIX + imageResponse.data.file);
        }
      }
    } else {
      await ExpenseStore.getExpectedExpenseModel(this.ExpenseForm.state);
    }

    const params = {
      policyId: this.policyId,
      disabledForm: this.disabledForm
    };

    await ExpenseStore.getExpenseCategory(params);

    if (this.expenseId && this.ExpenseForm.categoryId) {
      this.seletedCategory = this.categoryOptions.find(category => { return category.id === this.ExpenseForm.categoryId; }) || null;
    }

    this.loading = false;
  }

  reloadView() {
    this.initView();
  }

  saveExpense() {
    this.$v.ExpenseForm.$touch();
    this.$v.seletedCategory.$touch();
    this.$v.selectedCountry.$touch();

    if (this.showProject && this.ifProjectRequired) {
      this.$v.seletedProject.$touch();
    }

    if (this.showPaymentInstrument && this.ifPaymentInstrumentRequired) {
      this.$v.seletedPaymentInstrument.$touch();
    }

    if (this.customFieldConditions && this.customFieldConditions.length) {
      this.$v.customFieldConditions.$each.$touch();
    }
    
    if ((this.$v.ExpenseForm.$pending || this.$v.ExpenseForm.$error) || (this.$v.seletedCategory.$pending || this.$v.seletedCategory.$error) 
    || (this.$v.seletedProject.$pending || this.$v.seletedProject.$error) || (this.$v.seletedPaymentInstrument.$pending || this.$v.seletedPaymentInstrument.$error) 
    || (this.$v.customFieldConditions.$pending || this.$v.customFieldConditions.$error) 
    || (this.$v.selectedCountry.$pending || this.$v.selectedCountry.$error)) {
      return;
    }

    let showPopup = true;

    if (this.customFieldTransitionRules && this.customFieldTransitionRules.length) {
      for (let i = 0; i < this.customFieldTransitionRules.length; i++) {
        if (this.customFieldTransitionRules[i].targetState === 'Claimed' && this.customFieldTransitionRules[i].isRequired) {
          for (let j = 0; j < this.customFieldConditions.length; j++) {
            if (this.customFieldTransitionRules[i].customFieldDefinitionId === this.customFieldConditions[j].customFieldDefinitionId && this.customFieldConditions[j].value === '') {
              showPopup = false;
            }
          }
        }
      }
    }

    if (this.ifPaymentInstrumentRequiredToClaimed || this.ifProjectRequiredToClaimed) {
      if ((this.ifPaymentInstrumentRequiredToClaimed && this.seletedPaymentInstrument === null) || (this.ifProjectRequiredToClaimed && this.seletedProject === null)) {
        showPopup = false;
      }
    }

    if (this.selectedMission === null) {
      showPopup = false;
    }

    if (showPopup) {
      ExpenseStore.setShowClaimedExpensePopup(true);
    } else {
      this.submit();
    }
  }

  async saveAndClaimExpense() {
    if (this.showClaimAgainButton) {
      await this.updateValidationResults(this.validationResultsList);
    }
    this.ExpenseForm.state = ExpenseState.Claimed;
    this.submit();
    ExpenseStore.setShowClaimedExpensePopup(false);
  }

  closeSaveAndClaimPopup() {
    ExpenseStore.setShowClaimedExpensePopup(false);
  }

  onlySaveExpense() {
    this.submit();
    ExpenseStore.setShowClaimedExpensePopup(false);
  }

  async submit() {
    this.formPending = true;
    this.expenseId = this.$route.params.expenseId;
    this.ExpenseForm.incurrenceDate = formatDateMoment(this.ExpenseForm.incurrenceDate);

    if (this.seletedCategory) {
      this.ExpenseForm.categoryId = this.seletedCategory.id;
    }

     if (this.selectedCountry) {
      this.ExpenseForm.countryCode = this.selectedCountry.code;
    }

    if (this.selectedMission) {
      this.ExpenseForm.missionId = this.selectedMission.id;
    }

    if (this.seletedPaymentInstrument) {
      this.ExpenseForm.paymentInstrumentId = this.seletedPaymentInstrument.id;
    }

    if (this.seletedProject) {
      this.ExpenseForm.projectId = this.seletedProject.id;
    }

    if (this.customFieldConditions && this.customFieldConditions.length) {
      this.ExpenseForm.customFieldValues = [];
      for (let i = 0; i < this.customFieldConditions.length; i++) {
        if (this.customFieldConditions[i].value !== '') {
          let customField = {};
          if (this.customFieldConditions[i].type.type === 'Dictionary') {
            customField = {
              customFieldDefinitionId : this.customFieldConditions[i].customFieldDefinitionId,
              customFieldDictionaryItemId: this.customFieldConditions[i].value.id,
              customFieldDictionaryId: this.customFieldConditions[i].customFieldDictionaryId,
            };
          } else {
            customField = {
              customFieldDefinitionId : this.customFieldConditions[i].customFieldDefinitionId,
              value: this.customFieldConditions[i].value,
            };
          }
         this.ExpenseForm.customFieldValues.push(customField);
        } 
      }
    }

    if (this.typeAmount) {
      if (this.typeAmount === 'net') {
        this.ExpenseForm.sourceAmount.grossProvided = false;
      } else if (this.typeAmount === 'gross') {
        this.ExpenseForm.sourceAmount.grossProvided = true;
      }
    }

    if (this.expenseId) {
      try {
        const response = await ExpenseApi.updateExpenseItem(this.expenseId, this.ExpenseForm);

        let obj = {
          type: translate('common.success'),
          title: translate('expense-add.update-title'),
          message: translate('expense-add.update-message')
        };
        EventHandler.$emit('show-toast', obj);

        router.push({
          name: 'expense-list'
        });
        
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
        this.ExpenseForm.incurrenceDate = new Date(this.ExpenseForm.incurrenceDate);
      } finally {
        this.formPending = false;
      }
    } else {
      this.ExpenseForm.reporterId = this.currentProfileId;
      this.ExpenseForm.policyId = this.policyId;
      this.ExpenseForm.companyId = this.rootCompanyId;
      this.ExpenseForm.businessUnitId = this.companyId;

      try {
        const response = await ExpenseApi.addExpense(this.ExpenseForm);
        if (response) {
          let obj = {
            type: translate('common.success'),
            title: translate('expense-add.add-expense-title'),
            message: translate('expense-add.add-expense-message')
          };
          EventHandler.$emit('show-toast', obj);

          router.push({
            name: 'expense-list'
          });
        }
     
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
        this.ExpenseForm.incurrenceDate = new Date(this.ExpenseForm.incurrenceDate);
      } finally {
        this.formPending = false;
      }
    }
  }

  async updateValidationResults(value) {
    try {
      if (value && value.length) {
        let a: any[] | any = value;
        let params = { 
          resolutionExplanation: 'Resolved',
        };
        if (!(a instanceof Array)) {
          a = [a];
        }
        await Promise.all(
          a.map(validationResult => ExpenseApi.updateValidationResults(validationResult.id, params))
        );
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    }
  }

  setBase64Prefix() {
    if (this.expenseImageFormat === 'pdf') {
      this.BASE64_STRING_PREFIX = 'data:application/' + this.expenseImageFormat + ';base64,';
    } else {
      this.BASE64_STRING_PREFIX = 'data:image/' + this.expenseImageFormat + ';base64,';
    }
  }

  setExpenseImage(value) {
    if (!!value) {
      this.expenseImage = value;
    } else {
      this.expenseImage = '';
    }
  }

  initRemoveExpense() {
    this.expenseRemovalErrorMessage = null;
    this.deleteExpensePopup = true;
  }

  async removeExpense() {
    this.expenseId = this.$route.params.expenseId;
    this.formPending = true;
    
    if (this.expenseId) {
      try {
        const response = await ExpenseApi.removeExpenseItem(this.expenseId);
        this.deleteExpensePopup = false;
        if (response) {
          let obj = {
            type: translate('common.success'),
            title: translate('expense-list.remove-expense-title'),
            message: translate('expense-list.remove-expense-message')
          };
          EventHandler.$emit('show-toast', obj);
          router.push({
            name: 'expense-list'
          });
        }
      } catch (error) {
        if (!error.response.data.error.details) {
          this.expenseRemovalErrorMessage = error.response.data.error.message;
        }
      }  finally {
        this.formPending = false;
      }
    }
  }

  getGrossValue(netValue, vatRate) {
    if (netValue === 0 || vatRate === 0) {
      return 0;
    } else {
      return parseFloat((netValue * (1 + vatRate / 100)).toFixed(2));
    }
  }

  getNetValue(grossValue, vatRate) {
    if (grossValue === 0 || vatRate === 0) {
      return 0;
    } else {
      return parseFloat((grossValue / (1 + vatRate / 100)).toFixed(2));
    }
  }

  async getExpectedAmount() {
    if (this.ExpenseForm.sourceAmount.value.amount) {
      await this.getExchangedPrice();

      if (this.exchangedPrice) {
        if (this.typeAmount === 'net') {
          this.expenseAmount.grossValue.amount = this.getGrossValue(this.exchangedPrice.targetPrice.amount, this.ExpenseForm.sourceAmount.vatRate);
          this.expenseAmount.grossValue.currency = this.exchangedPrice.targetPrice.currency;
          this.expenseAmount.netValue = this.exchangedPrice.targetPrice;
        } else if (this.typeAmount === 'gross') {
          this.expenseAmount.netValue.amount = this.getNetValue(this.exchangedPrice.targetPrice.amount, this.ExpenseForm.sourceAmount.vatRate);
          this.expenseAmount.netValue.currency = this.exchangedPrice.targetPrice.currency;
          this.expenseAmount.grossValue = this.exchangedPrice.targetPrice;
        } 
        this.expenseAmount.vatAmount.amount = parseFloat((this.expenseAmount.grossValue.amount - this.expenseAmount.netValue.amount).toFixed(2));
        this.expenseAmount.vatAmount.currency = this.exchangedPrice.targetPrice.currency;
      }
    }
  }

  showExpensePopup() {
    ExpenseStore.setShowExpenseImagePopup(true);
  }

  closeExpensePopup() {
    ExpenseStore.setShowExpenseImagePopup(false);
  }

  getVatAmount() {
    if (this.$v.ExpenseForm.sourceAmount.vatRate.$error || this.$v.ExpenseForm.sourceAmount.value.amount.$error) {
      this.sourceVatAmount = null;
    } else {
      if (this.ExpenseForm.sourceAmount.vatRate === 0) {
        this.sourceVatAmount = 0;
      } else {
        if (this.typeAmount === 'net') {
          this.sourceVatAmount = parseFloat((this.ExpenseForm.sourceAmount.value.amount * (this.ExpenseForm.sourceAmount.vatRate / 100)).toFixed(2));
        } else if (this.typeAmount === 'gross') {
          this.sourceVatAmount = parseFloat((this.ExpenseForm.sourceAmount.value.amount - (this.ExpenseForm.sourceAmount.value.amount / (1 + this.ExpenseForm.sourceAmount.vatRate / 100))).toFixed(2));
        } 
      }
    }
  }
  
  @Watch('ExpenseForm.incurrenceDate')
  onChangedDate(val) {
    if (val && this.ExpenseForm && new Date(val).getTime() > new Date().getTime()) {
      this.ExpenseForm.incurrenceDate = new Date();
    }
  }

  @Watch('compoundProperty')
  onChangeValue(val) {
    if (val && this.ExpenseForm && this.ExpenseForm.sourceAmount.vatRate) {
      this.getVatAmount();
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
  })
  exchangedSourceAmount(val) {
    if (this.$v.ExpenseForm.sourceAmount.vatRate.$error || this.$v.ExpenseForm.sourceAmount.value.amount.$error) {
      this.exchangedPrice = null;
      return;
    }

    if (val && this.ExpenseForm && this.ExpenseForm.sourceAmount.vatRate && this.ExpenseForm.sourceAmount.value.currency && !this.exchangedWatchEnabled) {
      this.getExpectedAmount();
    }
  }

  @Watch('exchangedPriceGroup', { deep: true})
  onChangeExchangedPriceGroup(val) {
    this.exchangedSourceAmount(val);
  }

  @Watch('selectedMission')
  onChangedSelectedMission(val) {
    if (this.selectedMission && val) {
      if (Object.keys(this.selectedMission).length !== 0) {
        ExpenseStore.setMissionForExpense(this.selectedMission);
      } else {
        ExpenseStore.clearMissionForExpense();
      }
    }
  }

  created() {
    EventBus.$on('refresh-expense', this.reloadView);
  }

  beforeDestroy() {
    EventBus.$off('refresh-expense', this.reloadView);
    ExpenseStore.setValidationResults(null);
  }
}
