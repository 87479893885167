export class PaymentGatewayConfiguration {
  rootCompanyId?: string;
  configurationId?: string;
  name: string = '';
  vivaWalletSecretKey: string = '';

  constructor(data?: any) {
    if (data) {
      if (data.rootCompanyId !== undefined) {
        this.rootCompanyId = data.rootCompanyId;
      }
      if (data.configurationId !== undefined) {
        this.configurationId = data.configurationId;
      }
      if (data.name !== undefined) {
        this.name = data.name;
      }
      if (data.vivaWalletSecretKey !== undefined) {
        this.vivaWalletSecretKey = data.vivaWalletSecretKey;
      }
    }
  }
}