



































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import BasketItem from './BasketItem.vue';
import BasketStore from './basket.store';
import SabreBasketAncillaries from './sabre/SabreBasketAncillaries.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

@Component({
   components: {
    SabreBasketAncillaries,
    BasketItem,
    BasketExpirationCountdown,
  }
})
export default class BasketAncillariesView extends Vue {
  @Prop() basketExpired!: boolean;

  get loading() {
    return BasketStore.loadingAncillaries;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    if (this.bookingStep < 1) {
      return '';
    }
    return this.wizardSteps[this.bookingStep - 1].tripItemId;
  }

  get basketAncillaries() {
    return BasketStore.basketAncillaries;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }
 
  get basket() {
    return BasketStore.basket;
  }

  get item() {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'AIR_SABRE_ANCILLARIES') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped[0];
  }

  @Watch('bookingStep', { immediate: true })
  async onStepChange() {
    BasketStore.setBookingStepLoading(true);
    BasketStore.resetPriceChangesForStep({step: this.bookingStep, tripItemId: this.itemId});
    await BasketStore.loadBasketAncillaries(this.itemId);
    BasketStore.setBookingStepLoading(false);
  }

  beforeDestroy() {
    BasketStore.resetSelectedAncillaries();
  }
}

