







































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required } from 'vuelidate/lib/validators';
import PaymentMethodsStore from './payment-methods.store';
import { AirNegoFareConfigurationSupplierEnum, NegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';
import { translate } from '@/i18n';

@Component({})
export default class PaymentAirConfiguration extends Vue {
  $v;
  validated: boolean = false;

  get providerOptions() {
    return PaymentMethodsStore.enabledAirSuppliers.filter(supplier => {
      return supplier !== 'Fake';
    });
  }

  Form: any = {
    supplier: null,
    regular: {
      isTfPayEnabled: false,
      isCentralPaymentEnabled: false,
      isCardPaymentEnabled: false,
      isLodgeCardEnabled: false,
      isPreRegisteredCardEnabled: false,
      isVirtualCardPaymentEnabled: false,
      defaultPaymentMethod: null,
      enablePaymentGateway: false,
      paymentGatewayOption: 'TotalBookingAmount',
      fallbackPaymentMethod: 'None',
      hidePaymentMethodIfItIsNotNeeded: false,
    },
    lcc: {
      isTfPayEnabled: false,
      isCentralPaymentEnabled: false,
      isCardPaymentEnabled: false,
      isLodgeCardEnabled: false,
      isPreRegisteredCardEnabled: false,
      isVirtualCardPaymentEnabled: false,
      defaultPaymentMethod: null,
      enablePaymentGateway: false,
      paymentGatewayOption: 'TotalBookingAmount',
      fallbackPaymentMethod: 'None',
      hidePaymentMethodIfItIsNotNeeded: false,
    },
  };

  fallbackPaymentMethodOptions = [
    {
      value: 'None',
      label: translate('settings-payment-methods.none'),
    },
    {
      value: 'CentralPayment',
      label: translate('settings-payment-methods.central-payment'),
    },
    {
      value: 'LodgeCard',
      label: translate('settings-payment-methods.lodge-card'),
    },
  ];

  lccFallbackPaymentMethodOptions = [
    {
      value: 'None',
      label: translate('settings-payment-methods.none'),
    },
    {
      value: 'LodgeCard',
      label: translate('settings-payment-methods.lodge-card'),
    },
  ];

  // for TF no central payment
  // add lodge card everywhere

  @Validation()
  validationObject() {
    return {
      Form: {
        supplier: {
          required,
        },
        isCentralPaymentEnabled: {
        },
        isCardPaymentEnabled: {
        },
        isVirtualCardPaymentEnabled: {
        },
        defaultPaymentMethod: {
        },
      }
    };
  }

  get shouldShowLccAndHeaders() {
    return -1 < [
      AirNegoFareConfigurationSupplierEnum.Amadeus,
      AirNegoFareConfigurationSupplierEnum.Sabre,
    ].indexOf(this.Form.supplier);
  }

  get shouldShowLccFallbackPaymentMethodOptions() {
    return -1 < [
      AirNegoFareConfigurationSupplierEnum.Sabre,
    ].indexOf(this.Form.supplier);
  }

  get shouldShowFallbackPaymentMethodOptions() {
    return -1 < [
      NegoFareConfigurationSupplierEnum.Lufthansa,
      AirNegoFareConfigurationSupplierEnum.Sabre,
      NegoFareConfigurationSupplierEnum.AirFranceKlm,
      AirNegoFareConfigurationSupplierEnum.Amadeus,
      NegoFareConfigurationSupplierEnum.AmericanAirlines,
      NegoFareConfigurationSupplierEnum.BritishAirways,
    ].indexOf(this.Form.supplier);
  }

  get filterProviderList() {
    if (this.currentPaymentList && this.currentPaymentList.length && this.isNew) {
      return this.providerOptions.filter(e => !this.currentPaymentList.some(a => a.paymentMethodConfiguration.supplier === e && a.succeeded));
    }

    return this.providerOptions;
  }

  get filteredTranslatedProviderList() {
    return this.filterProviderList.map(supplier => {
      return {
        value: supplier,
        label: SupplierName(supplier),
      };
    });
  }

  get numberOfPaymentMethodsEnabled() {
    if (!this.Form.supplier || !this.Form.regular) {
      return 0;
    }

    const paymentMethodsEnabled = [
      this.Form.regular.isCentralPaymentEnabled,
      this.Form.regular.isTfPayEnabled,
      this.Form.regular.isCardPaymentEnabled,
      this.Form.regular.isLodgeCardPaymentEnabled,
      this.Form.regular.isPreRegisteredCardEnabled,
      this.Form.regular.isVirtualCardPaymentEnabled,
    ];

    const paymentMethodsNumberByProvider = {
      Amadeus: [0, 2, 3, 5],
      TravelFusion: [1, 2, 3, 4],
      Sabre: [0, 2, 3, 5],
      AirFranceKlm: [0, 2, 3, 5],
      Lufthansa: [0, 2, 3, 5],
      AmericanAirlines: [0, 2, 3, 5],
      BritishAirways: [0, 2, 3, 5]
    };

    return paymentMethodsEnabled.filter((item, index) => {
      return paymentMethodsNumberByProvider[this.Form.supplier].includes(index) && item;
    }).length;
  }

  get numberOfLccPaymentMethodsEnabled() {
    if (!this.Form.supplier || !this.Form.lcc) {
      return 0;
    }

    const paymentMethodsEnabled = [
      this.Form.lcc.isCentralPaymentEnabled,
      this.Form.lcc.isTfPayEnabled,
      this.Form.lcc.isCardPaymentEnabled,
      this.Form.lcc.isLodgeCardPaymentEnabled,
      this.Form.lcc.isPreRegisteredCardEnabled,
      this.Form.lcc.isVirtualCardPaymentEnabled,
    ];

    const paymentMethodsNumberByProvider = {
      Amadeus: [2, 3, 5],
      TravelFusion: [1, 2, 3, 4],
      Sabre: [2, 3, 5],
      AirFranceKlm: [],
      Lufthansa: [2, 3, 5],
      AmericanAirlines: [2, 3, 5],
      BritishAirways: [2, 3, 5]
    };

    return paymentMethodsEnabled.filter((item, index) => {
      return paymentMethodsNumberByProvider[this.Form.supplier].includes(index) && item;
    }).length;
  }

  get isHidePaymentMethodDisabled() {
    return (this.numberOfPaymentMethodsEnabled !== 1) ||
      this.Form.regular.isCardPaymentEnabled;
  }

  get isLccHidePaymentMethodDisabled() {
    return (this.numberOfLccPaymentMethodsEnabled !== 1) ||
      this.Form.lcc.isCardPaymentEnabled;
  }

  get selectedSupplier() {
    return this.filteredTranslatedProviderList.find(item => item.value === this.Form.supplier) || null;
  }

  set selectedSupplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      return;
    }

    this.Form.regular.isCentralPaymentEnabled = false;
    this.Form.regular.isTfPayEnabled = false;
    this.Form.regular.isCardPaymentEnabled = false;
    this.Form.regular.isLodgeCardPaymentEnabled = false;
    this.Form.regular.isPreRegisteredCardEnabled = false;
    this.Form.regular.isVirtualCardPaymentEnabled = false;
    this.Form.regular.defaultPaymentMethod = null;
    
    this.Form.lcc.isCentralPaymentEnabled = false;
    this.Form.lcc.isTfPayEnabled = false;
    this.Form.lcc.isCardPaymentEnabled = false;
    this.Form.lcc.isLodgeCardPaymentEnabled = false;
    this.Form.lcc.isPreRegisteredCardEnabled = false;
    this.Form.lcc.isVirtualCardPaymentEnabled = false;
    this.Form.lcc.defaultPaymentMethod = null;

    this.Form.supplier = value.value;
  }

  get selectedRegularFallbackPaymentMethod() {
    return this.fallbackPaymentMethodOptions.find(item => item.value === this.Form.regular.fallbackPaymentMethod) || null;
  }

  set selectedRegularFallbackPaymentMethod(value) {
    if (value == null) {
      this.Form.regular.fallbackPaymentMethod = 'None';
      return;
    }
    this.Form.regular.fallbackPaymentMethod = value.value;
  }

  get selectedLccFallbackPaymentMethod() {
    return this.fallbackPaymentMethodOptions.find(item => item.value === this.Form.lcc.fallbackPaymentMethod) || null;
  }

  set selectedLccFallbackPaymentMethod(value) {
    if (value == null) {
      this.Form.lcc.fallbackPaymentMethod = 'None';
      return;
    }
    this.Form.lcc.fallbackPaymentMethod = value.value;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return PaymentMethodsStore.configurationName;
  }

  get selectedPayment() {
    return PaymentMethodsStore.selectedPayment;
  }

  get providers() {
    return AirNegoFareConfigurationSupplierEnum;
  }

  get currentPaymentList() {
    return PaymentMethodsStore.paymentList;
  }

  get isConfigTF() {
    return this.Form.supplier === NegoFareConfigurationSupplierEnum.TravelFusion;
  }

  get isConfigAFKLM() {
    return this.Form.supplier === NegoFareConfigurationSupplierEnum.AirFranceKlm;
  }

  get isConfigAmadeus() {
    return this.Form.supplier === NegoFareConfigurationSupplierEnum.Amadeus;
  }

  get supplierAllowsVirtualCards() {
    return this.Form.supplier === NegoFareConfigurationSupplierEnum.Lufthansa
      || this.Form.supplier === NegoFareConfigurationSupplierEnum.Sabre
      || this.Form.supplier === NegoFareConfigurationSupplierEnum.Amadeus
      || this.Form.supplier === NegoFareConfigurationSupplierEnum.AirFranceKlm
      || this.Form.supplier === NegoFareConfigurationSupplierEnum.AmericanAirlines
      || this.Form.supplier === NegoFareConfigurationSupplierEnum.BritishAirways;
  }

  get isNew() {
    if (this.selectedPayment) {
      return Object.entries(this.selectedPayment as any).length === 0;
    }
    return false;
  }

  get readOnly() {
    return this.$route.query.readOnly === 'true';
  }

  goToConfiguration() {
    PaymentMethodsStore.setIsFromManage(true);
    router.push({
      name: 'payment-methods-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      const regular = {
        isTfPayEnabled: data.paymentMethodConfiguration.regular.isTfPayEnabled,
        isCentralPaymentEnabled: data.paymentMethodConfiguration.regular.isCentralPaymentEnabled,
        isCardPaymentEnabled: data.paymentMethodConfiguration.regular.isCardPaymentEnabled,
        isLodgeCardPaymentEnabled: data.paymentMethodConfiguration.regular.isLodgeCardPaymentEnabled,
        isPreRegisteredCardEnabled: data.paymentMethodConfiguration.regular.isPreRegisteredCardEnabled,
        isVirtualCardPaymentEnabled: data.paymentMethodConfiguration.regular.isVirtualCardPaymentEnabled,
        defaultPaymentMethod: data.paymentMethodConfiguration.regular.defaultPaymentMethod,
        enablePaymentGateway: !!data.paymentMethodConfiguration.regular.paymentGatewayOption,
        paymentGatewayOption: data.paymentMethodConfiguration.regular.paymentGatewayOption ? data.paymentMethodConfiguration.regular.paymentGatewayOption : 'TotalBookingAmount',
        fallbackPaymentMethod: data.paymentMethodConfiguration.regular.fallbackPaymentMethod,
        hidePaymentMethodIfItIsNotNeeded: data.paymentMethodConfiguration.regular.hidePaymentMethodIfItIsNotNeeded,
      };
      let lcc: any = null;
      if (data.paymentMethodConfiguration.lcc) {
        lcc = {
          isTfPayEnabled: data.paymentMethodConfiguration.lcc.isTfPayEnabled,
          isCentralPaymentEnabled: data.paymentMethodConfiguration.lcc.isCentralPaymentEnabled,
          isCardPaymentEnabled: data.paymentMethodConfiguration.lcc.isCardPaymentEnabled,
          isLodgeCardPaymentEnabled: data.paymentMethodConfiguration.lcc.isLodgeCardPaymentEnabled,
          isPreRegisteredCardEnabled: data.paymentMethodConfiguration.lcc.isPreRegisteredCardEnabled,
          isVirtualCardPaymentEnabled: data.paymentMethodConfiguration.lcc.isVirtualCardPaymentEnabled,
          defaultPaymentMethod: data.paymentMethodConfiguration.lcc.defaultPaymentMethod,
          enablePaymentGateway: !!data.paymentMethodConfiguration.lcc.paymentGatewayOption,
          paymentGatewayOption: data.paymentMethodConfiguration.lcc.paymentGatewayOption ? data.paymentMethodConfiguration.lcc.paymentGatewayOption : 'TotalBookingAmount',
          fallbackPaymentMethod: data.paymentMethodConfiguration.lcc.fallbackPaymentMethod,
          hidePaymentMethodIfItIsNotNeeded: data.paymentMethodConfiguration.lcc.hidePaymentMethodIfItIsNotNeeded,
        };
      }
      this.Form = {
        supplier: data.paymentMethodConfiguration.supplier,
        regular,
        lcc,
      } as any;
    } else {
      this.Form = {
        supplier: null,
        regular: {
          isTfPayEnabled: false,
          isCentralPaymentEnabled: false,
          isCardPaymentEnabled: false,
          isLodgeCardPaymentEnabled: false,
          isPreRegisteredCardEnabled: false,
          isVirtualCardPaymentEnabled: false,
          defaultPaymentMethod: null,
          enablePaymentGateway: false,
          paymentGatewayOption: 'TotalBookingAmount',
          fallbackPaymentMethod: 'None',
          hidePaymentMethodIfItIsNotNeeded: false,
        },
        lcc: {
          isTfPayEnabled: false,
          isCentralPaymentEnabled: false,
          isCardPaymentEnabled: false,
          isLodgeCardPaymentEnabled: false,
          isPreRegisteredCardEnabled: false,
          isVirtualCardPaymentEnabled: false,
          defaultPaymentMethod: null,
          enablePaymentGateway: false,
          paymentGatewayOption: 'TotalBookingAmount',
          fallbackPaymentMethod: 'None',
          hidePaymentMethodIfItIsNotNeeded: false,
        },
      } as any;
    }
  }

  createPayment() {
    let lcc: any = null;
    if (this.Form.lcc && -1 < [
      AirNegoFareConfigurationSupplierEnum.Amadeus,
      AirNegoFareConfigurationSupplierEnum.Sabre,
    ].indexOf(this.Form.supplier)) {
      lcc = {
        isTfPayEnabled: this.Form.lcc.isTfPayEnabled,
        isCentralPaymentEnabled: this.Form.lcc.isCentralPaymentEnabled,
        isCardPaymentEnabled: this.Form.lcc.isCardPaymentEnabled,
        isLodgeCardPaymentEnabled: this.Form.lcc.isLodgeCardPaymentEnabled,
        isPreRegisteredCardEnabled: this.Form.lcc.isPreRegisteredCardEnabled,
        isVirtualCardPaymentEnabled: this.Form.lcc.isVirtualCardPaymentEnabled,
        paymentGatewayOption: this.Form.lcc.enablePaymentGateway ? this.Form.lcc.paymentGatewayOption : null,
        fallbackPaymentMethod: this.Form.lcc.fallbackPaymentMethod,
        hidePaymentMethodIfItIsNotNeeded: this.Form.lcc.hidePaymentMethodIfItIsNotNeeded,
        defaultPaymentMethod:
          this.Form.lcc.isTfPayEnabled === false &&
          this.Form.lcc.isCentralPaymentEnabled === false &&
          this.Form.lcc.isCardPaymentEnabled === false &&
          this.Form.lcc.isLodgeCardPaymentEnabled === false &&
          this.Form.lcc.isPreRegisteredCardEnabled === false &&
          this.Form.lcc.isVirtualCardPaymentEnabled === false
          ? 'None'
          : this.Form.lcc.defaultPaymentMethod,
      };
    }
    let item = {
      supplier: this.Form.supplier,
      regular: {
        isTfPayEnabled: this.Form.regular.isTfPayEnabled,
        isCentralPaymentEnabled: this.Form.regular.isCentralPaymentEnabled,
        isCardPaymentEnabled: this.Form.regular.isCardPaymentEnabled,
        isLodgeCardPaymentEnabled: this.Form.regular.isLodgeCardPaymentEnabled,
        isPreRegisteredCardEnabled: this.Form.regular.isPreRegisteredCardEnabled,
        isVirtualCardPaymentEnabled: this.Form.regular.isVirtualCardPaymentEnabled,
        paymentGatewayOption: this.Form.regular.enablePaymentGateway ? this.Form.regular.paymentGatewayOption : null,
        fallbackPaymentMethod: this.Form.regular.fallbackPaymentMethod,
        hidePaymentMethodIfItIsNotNeeded: this.Form.regular.hidePaymentMethodIfItIsNotNeeded,
        defaultPaymentMethod:
          this.Form.regular.isTfPayEnabled === false &&
          this.Form.regular.isCentralPaymentEnabled === false &&
          this.Form.regular.isCardPaymentEnabled === false &&
          this.Form.regular.isLodgeCardPaymentEnabled === false &&
          this.Form.regular.isPreRegisteredCardEnabled === false &&
          this.Form.regular.isVirtualCardPaymentEnabled === false
          ? 'None'
          : this.Form.regular.defaultPaymentMethod,
      },
      lcc,
      status: PaymentMethodsStore.draftNewStatus,
      succeeded: true,
      id: _.uniqueId()
    };
    if (
      Object.entries(this.selectedPayment as any).length === 0 || 
      !this.currentPaymentList.find(entry => {
        return entry.paymentMethodConfiguration.supplier === item.supplier;
      })
    ) {
      PaymentMethodsStore.addSelectedPayment(item);
    } else {
      PaymentMethodsStore.editSelectedPayment(item);
    }
  }

  confirmAndGoBack() {
    this.$v.Form.$touch();
    this.validated = true;
    if (!this.$v.Form.$invalid) {
      this.createPayment();
      this.goToConfiguration();
    }
  }

  @Watch('selectedPayment', { deep: true, immediate: true })
  onCodeChange(payment) {
    if (!payment) {
      router.go(-1);
    }
  }

  @Watch('isHidePaymentMethodDisabled', { immediate: true })
  onIsHidePaymentMethodDisabledChanged() {
    if (this.isHidePaymentMethodDisabled) {
      this.Form.regular.hidePaymentMethodIfItIsNotNeeded = false;
    }
  }

  @Watch('isLccHidePaymentMethodDisabled', { immediate: true })
  onIsLccHidePaymentMethodDisabledChanged() {
    if (this.isLccHidePaymentMethodDisabled) {
      this.Form.lcc.hidePaymentMethodIfItIsNotNeeded = false;
    }
  }

  paymentMethodChanged(value, type, paymentType) {
    if (value && this.Form[type].defaultPaymentMethod && this.Form[type].defaultPaymentMethod !== 'None') {
      return;
    }
    if (value && (!this.Form[type].defaultPaymentMethod || (this.Form[type].defaultPaymentMethod && this.Form[type].defaultPaymentMethod === 'None'))) {
      this.Form[type].defaultPaymentMethod = paymentType;
      return;
    }
    if (!value && this.Form[type].defaultPaymentMethod === paymentType) {
      const paymentMethodMap = {
        isCentralPaymentEnabled: 'CentralPayment',
        isCardPaymentEnabled: 'PaymentCard',
        isLodgeCardPaymentEnabled: 'LodgeCard',
        isTfPayEnabled: 'TfPay',
        isPreRegisteredCardEnabled: 'PreRegisteredCard',
        isVirtualCardPaymentEnabled: 'VirtualCard',
      };

      const defaultPaymentMethod = Object.keys(paymentMethodMap).find(key => this.Form[type][key]);

      this.Form[type].defaultPaymentMethod = defaultPaymentMethod ? paymentMethodMap[defaultPaymentMethod] : null;
    }
  }

  created() {
    if (this.selectedPayment) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedPayment)));
    } else {
      this.mapFormData();
    }
  }
}
