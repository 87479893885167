import PaymentMethodsConfiguration from '@/modules/settings/payment-methods/PaymentMethodsConfiguration.vue';

export default {
  path: ':configurationId?/payment-methods-configuration/:isCopy?',
  name: 'payment-methods-configuration',
  component: PaymentMethodsConfiguration,
  displayName: 'Payment methods configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPaymentMethodConfiguration',
    depthCategory: 'payment-methods',
    depth: 2,
    parentName: 'payment-methods-configurations',
  },
};