import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  CompanyConfigurationUpdateMessage,
  CompanyConfiguration,
  UnitConfiguration,
  CompanyListConfiguration
} from './company-configurations.model';

class CompanyConfigurationsApiClass {
  public getCompanyConfigurations(companyId: string, configurationType: string): Promise<AxiosResponse<CompanyConfiguration[]>> {
    let url = '/api/companies/{companyId}/configurations';
    if (configurationType) {
      url += '?configurationTypes={configurationType}';
    }

    return http.execute({
      path: settings.apiProfile + url,
      method: RequestMethod.GET
    }, {
      companyId,
      configurationType
    }, {});
  }

  public getUnitConfigurations(companyId: string) {
    let url = '/api/v2/companies/{companyId}/configurations';
    return http.execute({
      path: settings.apiProfile + url,
      method: RequestMethod.GET
    }, { companyId }, {});
  }

  public setCompanyConfigurations(companyId: string, configurations: CompanyConfigurationUpdateMessage) {
    return http.execute({
      path: settings.apiProfile + '/api/companies/{companyId}/configurations',
      method: RequestMethod.PUT
    }, {
      companyId,
    }, configurations);
  }

  public getCompanyListConfigurations({
    companyId,
    permission
  }): Promise<AxiosResponse<CompanyListConfiguration[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/v2/companies/{companyId}/configurations',
      permission: permission,
      method: RequestMethod.GET,
    }, {
      companyId,
      permission
    }, {});
  }
}

export const CompanyConfigurationsApi: CompanyConfigurationsApiClass = new CompanyConfigurationsApiClass();
