import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  CustomMessagesTranslationDto,
  CustomMessageDto,
  CustomMessageModel,
} from './custom-messages.model';


class CustomMessagesApiClass {
  public getActiveMessages(): Promise<AxiosResponse<CustomMessageModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages/active',
      method: RequestMethod.GET
    }, {}, {});
  }

  public getAllMessages(): Promise<AxiosResponse<CustomMessageModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages',
      method: RequestMethod.GET
    }, {}, {});
  }

  public addCustomMessage(message: CustomMessageDto): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages',
      method: RequestMethod.POST
    }, {}, message);
  }

  public updateCustomMessage(messageId: string, message: CustomMessageDto): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages/{messageId}',
      method: RequestMethod.PUT
    }, { messageId }, message);
  }

  public removeCustomMessage(messageId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages/{messageId}',
      method: RequestMethod.DELETE
    }, { messageId }, {});
  }

  public getNewMessageBusinessUnits(): Promise<AxiosResponse<CustomMessageModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages/companies',
      method: RequestMethod.GET
    }, {}, {});
  }

  public getMessageBusinessUnits(messageId: string): Promise<AxiosResponse<CustomMessageModel[]>> {
    return http.execute({
      path: settings.apiProfile + '/api/custom-messages/companies/{messageId}',
      method: RequestMethod.GET
    }, { messageId }, {});
  }
}

export const CustomMessagesApi: CustomMessagesApiClass = new CustomMessagesApiClass();