




















import { Vue, Component, Prop } from 'vue-property-decorator';

import HotelRooms from './HotelRooms.vue';
import SearchStore from '@/modules/search/search.store';
import formatDate from '@/filters/format-date.filter';

@Component({
  components: {
    HotelRooms,
  },
})
export default class HotelDetailsSection extends Vue {
  @Prop() offers!: any;
  @Prop() property!: any;
  @Prop() hasNoOffers!: boolean;
  @Prop() searchId!: string;
  @Prop({ default: false }) disabled!: boolean;

  get checkInDate() {
    return formatDate(SearchStore.hotelSearchParameters.checkInDate);
  }

  get checkOutDate() {
    return formatDate(SearchStore.hotelSearchParameters.checkOutDate);
  }

  get location() {
    return SearchStore.hotelSearchParameters.to ? SearchStore.hotelSearchParameters.to!.cityName : false;
  }
}
