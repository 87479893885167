import {AxiosResponse} from 'axios';
import {http, RequestMethod} from '@/services/http';
import settings from '@/settings';
import {CreateProviderMappingMessage, CreateProviderMappingResponse, ProviderMappingModel} from './custom-fields.model';

export class CustomFieldsProviderMappingsApiClass {
    public getMappings(configurationId: string): Promise<AxiosResponse<ProviderMappingModel[]>> {
        return http.execute({
            path: settings.apiProfile + '/customFields/provider-mappings?configurationId={configurationId}',
            method: RequestMethod.GET
        }, {
            configurationId
        }, {});
    }

    public getMapping(id: string): Promise<AxiosResponse<ProviderMappingModel>> {
        return http.execute({
            path: settings.apiProfile + '/customFields/provider-mappings/{id}',
            method: RequestMethod.GET
        }, {
            id
        }, {});
    }

    public createMapping(request: CreateProviderMappingMessage): Promise<AxiosResponse<CreateProviderMappingResponse>> {
        return http.execute({
            path: settings.apiProfile + '/customFields/provider-mappings',
            method: RequestMethod.POST
        }, {}, {...request});
    }

    public updateMapping(id: string, request: CreateProviderMappingMessage): Promise<AxiosResponse<any>> {
        return http.execute({
            path: settings.apiProfile + '/customFields/provider-mappings/{id}',
            method: RequestMethod.PUT
        }, {id}, {...request});
    }

    public deleteMapping(id: string): Promise<AxiosResponse<any>> {
        return http.execute({
            path: settings.apiProfile + '/customFields/provider-mappings/{id}',
            method: RequestMethod.DELETE
        }, {
            id
        }, {});
    }
}

export const CustomFieldsProviderMappingsApi: CustomFieldsProviderMappingsApiClass = new CustomFieldsProviderMappingsApiClass();