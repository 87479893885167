import NegoFareConfiguration from '@/modules/settings/nego-fare/NegoFareConfiguration.vue';

export default {
  path: 'new',
  name: 'new-nego-fare-configuration',
  component: NegoFareConfiguration,
  displayName: 'New Nego Fare',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadNegoFare',
    depthCategory: 'nego-fare',
    depth: 2,
    parentName: 'nego-fare-configurations',
  },
};