import Tables from '@/modules/demo/tables/Tables.vue';
import table1 from './table1.route';
import table2 from './table2.route';
import table3 from './table3.route';

export default {
  path: 'tables',
  name: 'tables',
  component: Tables,
  children: [
    table1,
    table2,
    table3,
  ],
};