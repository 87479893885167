import CarSearchShortParams from '@/modules/search/car/CarSearchShortParams.vue';
import CarFiltersSubintro from '@/modules/search/car/CarFiltersSubintro.vue';
import CarResultsFilters from '@/modules/search/car/CarResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/car-results-filters/:searchId',
  name: 'carFilters',
  components: {
    default: CarResultsFilters,
    intro: CarSearchShortParams,
    subintro: CarFiltersSubintro,
  },
  meta: {
    introClass: 'intro-car-results',
    permission: 'SearchOffers',
    mobile: true,
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airDetails: AnimationName.DOWN,
      },
      subintro: {
        air: AnimationName.UP,
      },
    }
  }
};
