




































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import formatTime from '@/filters/format-time.filter';

import { BasketApi } from '@/api/trip/basket.api';

@Component({})
export default class TrainTrenitaliaCancellationPolicy extends Vue {
  @Prop() itemId!: string;
  @Prop({ default: 'cancel' }) type!: string;

  errors: any[] = [];
  message: any = {};
  loaded: boolean = false;
  isConfirmError: boolean = false;

  hideForm() {
    this.$emit('close');
  }

  dateFilter(date) {
    return moment.parseZone(date).format('Do MMMM YYYY ') + formatTime(date.slice(0, date.length - 6)) + ' (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  async confirm() {
    try {
      const response = await BasketApi.confirmTrenitaliaItemCancel(this.itemId, this.type === 'cancel' ? true : false);
      this.$emit('confirm');
    } catch (error) {
      if (error.response && error.response.data.error.code === 'CANCELLATION_NO_LONGER_AVAILABLE') {
        this.isConfirmError = true;

        return;
      }

      this.errors = this.$handleErrors(error, true);
    }
  }

  async loadData() {
    this.message = '';
    try {
      const response = await BasketApi.getTrainTrenitaliaCancellationPolicy(this.itemId, this.type === 'cancel' ? true : false);
      this.message = response.data;
      this.loaded = true;
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    }
  }

  mounted() {
    this.loadData();
  }
}
