

























































































import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import {
  maxLength,
  minLength,
  email,
} from 'vuelidate/lib/validators';
import { ProfileApi } from '@/api/profile/profile.api';
import { CreateAccountMessage, ProfileUpdateMessage } from '@/api/profile/profile.model';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import profileStore from '@/modules/profile/profile.store';
import { Permission } from '@/const/permission.enum';

const closeShowPopup = 'update:showPopup';

@Component({})
export default class CreateAccountPopup extends Vue {
  @Prop({ default: false }) showPopup: boolean = false;
  @Prop() profile!: ProfileUpdateMessage;
  serverErrors: any[] = [];
  Form: CreateAccountMessage = new CreateAccountMessage();
  formPending: boolean = false;
  $v;

  @Validation()
  validationObject() {
    let Form: any = {
      userName: {
        minLength: minLength(5),
        maxLength: maxLength(255),
      },
      email: {
        email,
        maxLength: maxLength(320),
      },
    };

    return {
      Form
    };
  }

  async createAccount() {
    this.serverErrors = [];
    this.$v.Form.$touch();
    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }
    this.formPending = true;

    try {
      const profileId = router.currentRoute.params.id;
      let request: CreateAccountMessage = {
        userName: this.Form.userName,
        email: this.Form.email ? this.Form.email : (this.profile!.primaryEmail ? this.profile!.primaryEmail : ''),
        sendInvitationEmail: this.Form.sendInvitationEmail
      };

      if (this.$hasAccess('CreateAccount')) {
        await ProfileApi.createAccount(
          profileId,
          request,
        );
        const obj = {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-company.saved')
        };
        EventHandler.$emit('show-toast', obj);
        EventHandler.$emit('refresh-profile');
        const profile = await ProfileApi.getById(this.$route.params.id, Permission.ReadProfilePersonal);
        if (profile && profile.data) {
          profileStore.setUserProfile(profile.data);
        }
        this.formPending = false;
        this.closePopup();
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
      this.formPending = false;
    }
  }

  closePopup() {
    this.formPending = false;
    this.serverErrors = [];
    this[closeShowPopup](false);
  }

  @Emit()
  [closeShowPopup](val) {
    return val;
  }

  @Watch('showPopup')
  onPopupShow() {
    this.Form = new CreateAccountMessage();
  }
}
