import {
  translate,
  translationExists,
} from '@/i18n';

export const SupplierName = (name) => {
  const key: string = 'supplier.' + name;

  if (translationExists(key)) {
    return translate(key);
  }

  return name;
};
