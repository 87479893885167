










import { Vue, Component, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import { BasketApi } from '@/api/trip/basket.api';
import { BasketModel, BasketItemModel } from '@/api/trip/basket.model';
import LayoutStore from '@/modules/layout/layout.store';
import SearchTravellersList from '@/modules/search/SearchTravellersList.vue';

@Component({
  components: {
    SearchTravellersList,
  },
})
export default class BasketTravellersList extends Vue {

}
