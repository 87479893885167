import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
import { AirFeesConfigurationApi } from '@/api/air-engine/fees-configuration.api';
import { TrainFeesConfigurationApi } from '@/api/train-engine/train-fees-configuration.api';
import { AccommodationFeesConfigurationApi } from '@/api/accommodation-engine/accommodation-fees-configuration.api';
import {
  FeesConfiguration,
  FeesConfig,
  FeeRule,
} from '@/api/air-engine/fees-configuration.model';
import { ProfileConfigurationsApi } from '@/api/profile/configurations.api';
import { companyConfigurationType } from '@/api/profile/configurations.model';
import { Permission } from '@/const/permission.enum';
import { TrainFeesConfiguration } from '@/api/train-engine/train-fees-configuration.model';

const uniqueIdPrefix: string = 'air-rule_';
const uniqueTrainIdPrefix: string = 'train-rule_';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'feesConfigurations'
})
class FeesConfigurationStore extends VuexModule {
  isAirConfigurationEnabled: boolean = true;
  isTrainConfigurationEnabled: boolean = true;
  isHotelConfigurationEnabled: boolean = true;
  loading: boolean = false;
  trainLoading: boolean = false;
  hotelLoading: boolean = false;
  currentConfiguration: FeesConfiguration = new FeesConfiguration();
  currentTrainConfiguration: TrainFeesConfiguration | null = null;
  currentHotelConfiguration: TrainFeesConfiguration | null = null;
  configurationName: string = '';
  configurationId: string = '';
  selectedTab: string = 'Flight';
  isFromManage: boolean = false;
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';
  errMessages: any[] = [];
  saveErrors: any[] = [];
  trainErrors: any[] = [];
  hotelErrors: any[] = [];
  trainSaveErrors: any[] = [];
  hotelSaveErrors: any[] = [];
  showError: boolean = false;
  selectedRule: any = null;
  selectedTrainRule: any = null;
  selectedHotelRule: any = null;
  rulesList: FeeRule[] = [];
  trainRulesList: FeeRule[] = [];
  hotelRulesList: FeeRule[] = [];
  customError: boolean = false;
  trainCustomError: boolean = false;
  hotelCustomError: boolean = false;


  get canShowCustomError() {
    return this.customError;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get errors() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get Loading() {
    return this.loading;
  }

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  get rules() {
    return this.rulesList;
  }

  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setTrainLoading(value) {
    this.trainLoading = value;
  }

  @Mutation
  setHotelLoading(value) {
    this.hotelLoading = value;
  }

  @Mutation
  setConfiguration(value) {
    this.currentConfiguration = new FeesConfiguration(value);
  }

  @Mutation
  setTrainConfiguration(value) {
    this.currentTrainConfiguration = value;
  }

  @Mutation
  setHotelConfiguration(value) {
    this.currentHotelConfiguration = value;
  }

  @Mutation
  clearConfiguration() {
    this.currentConfiguration = new FeesConfiguration();
  }

  @Mutation
  setConfigurationName(value) {
    this.configurationName = value;
  }

  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
  }

  @Mutation
  setIsFromManage(value) {
    this.isFromManage = value;
  }

  @Mutation
  setSelectedTab(value) {
    this.selectedTab = value;
  }

  @Mutation
  setIsAirConfigurationEnabled(value) {
    this.isAirConfigurationEnabled = value;
  }

  @Mutation
  setIsTrainConfigurationEnabled(value) {
    this.isTrainConfigurationEnabled = value;
  }

  @Mutation
  setIsHotelConfigurationEnabled(value) {
    this.isHotelConfigurationEnabled = value;
  }

  @Mutation
  setRules(value: any[]) {
    this.rulesList = value.map(item => {
      return {
        ...item,
        id: _.uniqueId(uniqueIdPrefix),
      };
    });
  }

  @Mutation
  setTrainRules(value) {
    this.trainRulesList = value.map(item => {
      return {
        ...item,
        id: _.uniqueId(uniqueTrainIdPrefix),
      };
    });
  }

  @Mutation
  setHotelRules(value) {
    this.hotelRulesList = value.map(item => {
      return {
        ...item,
        supplier: item.supplier || [],
        id: _.uniqueId(uniqueTrainIdPrefix),
      };
    });
  }

  @Mutation
  setErrors(value) {
    this.errMessages = value;
  }

  @Mutation
  setTrainErrors(value) {
    this.trainErrors = value;
  }

  @Mutation
  setHotelErrors(value) {
    this.hotelErrors = value;
  }

  @Mutation
  setSaveErrors(value) {
    this.saveErrors = value;
  }

  @Mutation
  setTrainSaveErrors(value) {
    this.trainSaveErrors = value;
  }

  @Mutation
  setHotelSaveErrors(value) {
    this.hotelSaveErrors = value;
  }

  @Mutation
  setShowError(value) {
    this.showError = value;
  }

  @Mutation
  clearErrors() {
    this.showError = false;
    this.errMessages = [];
  }

  @Mutation
  setSelectedRule(value) {
    this.selectedRule = value;
  }

  @Mutation
  addRule(value) {
    this.rulesList.push({
      ...value,
      id: _.uniqueId(uniqueIdPrefix),
    });
  }

  @Mutation
  editRule(value) {
    let index = this.rulesList.findIndex(item => {
      return _.isEqual(item, this.selectedRule);
    });
    if (-1 < index) {
      this.rulesList.splice(index, 1, value);
    }
  }

  @Mutation
  removeRule(value) {
    let index = this.rulesList.findIndex(item => {
      return _.isEqual(item, value);
    });
    if (-1 < index) {
      this.rulesList.splice(index, 1);
    }
  }

  @Mutation
  setTrainSelectedRule(value) {
    this.selectedTrainRule = value;
  }

  @Mutation
  addTrainRule(value) {
    this.trainRulesList.push({
      ...value,
      id: _.uniqueId(uniqueTrainIdPrefix),
    });
  }

  @Mutation
  editTrainRule(value) {
    let index = this.trainRulesList.findIndex(item => {
      return _.isEqual(item, this.selectedTrainRule);
    });
    if (-1 < index) {
      this.trainRulesList.splice(index, 1, value);
    }
  }

  @Mutation
  removeTrainRule(value) {
    let index = this.trainRulesList.findIndex(item => {
      return _.isEqual(item, value);
    });
    if (-1 < index) {
      this.trainRulesList.splice(index, 1);
    }
  }

  @Mutation
  setHotelSelectedRule(value) {
    this.selectedHotelRule = value;
  }

  @Mutation
  addHotelRule(value) {
    this.hotelRulesList.push({
      ...value,
      id: _.uniqueId(uniqueTrainIdPrefix),
    });
  }

  @Mutation
  editHotelRule(value) {
    let index = this.hotelRulesList.findIndex(item => {
      return _.isEqual(item, this.selectedHotelRule);
    });
    if (-1 < index) {
      this.hotelRulesList.splice(index, 1, value);
    }
  }

  @Mutation
  removeHotelRule(value) {
    let index = this.hotelRulesList.findIndex(item => {
      return _.isEqual(item, value);
    });
    if (-1 < index) {
      this.hotelRulesList.splice(index, 1);
    }
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Mutation
  setTrainCustomError(value) {
    this.trainCustomError = value;
  }

  @Mutation
  setHotelCustomError(value) {
    this.hotelCustomError = value;
  }

  @Action
  clearData() {
    this.setConfiguration(new FeesConfiguration());
    this.setIsFromManage(false);
    this.setConfigurationId('');
    this.setConfigurationName('');
    this.setRules([]);
    this.setTrainRules([]);
    this.setHotelRules([]);
    this.setTrainSelectedRule(null);
    this.setHotelSelectedRule(null);
  }

  @Action
  async createAirConfiguration(request: FeesConfig) {
    this.setSaveErrors([]);
    try {
      const response = await AirFeesConfigurationApi.create(request);
      if (response && response.data) {
        if (response.data.error) {
          this.setSaveErrors($handleErrors([response.data.error], true));
          this.setShowError(true);
        }
        this.setConfigurationId(response.data.configurationId);
      }
    } catch (error) {
      this.setSaveErrors($handleErrors(error, true));
      this.setShowError(true);
    }
  }

  @Action
  async updateAirConfiguration(data) {
    this.setSaveErrors([]);
    try {
      const response = await AirFeesConfigurationApi.update(data.configurationId, data.params);
      if (response && response.data) {
        if (response.data.error) {
          this.setSaveErrors($handleErrors([response.data.error], true));
          this.setShowError(true);
        }
        this.getAirConfiguration(data.configurationId);
      }
    } catch (error) {
      this.setSaveErrors($handleErrors(error, true));
      this.setShowError(true);
    }
  }

  @Action
  async createTrainConfiguration(request) {
    this.setTrainSaveErrors([]);
    try {
      const { data } = await TrainFeesConfigurationApi.createFeeConfiguration(request);

      if (data && data.error) {
        this.setTrainSaveErrors($handleErrors([data.error], true));
      }
      this.trainRulesList.forEach(item => {
        delete item.status;
      });
    } catch (error) {
      this.setTrainSaveErrors($handleErrors(error, true));
    }
  }

  @Action
  async updateTrainConfiguration({ configurationId, data }) {
    this.setTrainSaveErrors([]);
    try {
      const response = await TrainFeesConfigurationApi.updateFeeConfiguration(configurationId, data);

      if (response.data && response.data.error) {
        this.setTrainSaveErrors($handleErrors([response.data.error], true));
      }
      this.getTrainConfiguration(configurationId);
    } catch (error) {
      this.setTrainSaveErrors($handleErrors(error, true));
    }
  }

  @Action
  async createHotelConfiguration(request) {
    this.setHotelSaveErrors([]);
    try {
      const { data } = await AccommodationFeesConfigurationApi.createFeeConfiguration(request);

      if (data && data.error) {
        this.setHotelSaveErrors($handleErrors([data.error], true));
      }
      this.hotelRulesList.forEach(item => {
        delete item.status;
      });
    } catch (error) {
      this.setHotelSaveErrors($handleErrors(error, true));
    }
  }

  @Action
  async updateHotelConfiguration({ configurationId, data }) {
    this.setHotelSaveErrors([]);
    try {
      const response = await AccommodationFeesConfigurationApi.updateFeeConfiguration(configurationId, data);

      if (response.data && response.data.error) {
        this.setHotelSaveErrors($handleErrors([response.data.error], true));
      }
      this.getHotelConfiguration(configurationId);
    } catch (error) {
      this.setHotelSaveErrors($handleErrors(error, true));
    }
  }

  @Action
  async getAirConfiguration(configurationId: string) {
    try {
      this.setRules([]);
      this.setErrors([]);
      this.setIsAirConfigurationEnabled(true);
      this.setShowError(false);
      this.setLoading(true);
      this.setCustomError(false);
      const result = await AirFeesConfigurationApi.getById(configurationId);
      if (result && result.data) {
        this.setConfiguration(result.data);
        this.setIsAirConfigurationEnabled(result.data.isEnabled);
        this.setConfigurationId(configurationId);
        if (result.data.feesRules) {
          this.setRules(result.data.feesRules);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrors($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getTrainConfiguration(configurationId: string) {
    try {
      this.setTrainRules([]);
      this.setTrainConfiguration(null);
      this.setTrainErrors([]);
      this.setTrainLoading(true);
      this.setTrainCustomError(false);
      const result = await TrainFeesConfigurationApi.getFeeConfiguration(configurationId);
      if (result && result.data) {
        this.setTrainConfiguration(result.data);
        this.setIsTrainConfigurationEnabled(result.data.isEnabled);
        if (result.data.feesRules) {
          this.setTrainRules(result.data.feesRules);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setTrainCustomError(true);
        this.setIsTrainConfigurationEnabled(true);
        this.setTrainConfiguration({
          configurationId,
          isVisibleToCustomer: false,
          isFeeAdjustableToTravelPolicy: false,
          isEnabled: true,
          feesRules: [],
        });
      } else {
        this.setTrainErrors(error);
      }
    } finally {
      this.setTrainLoading(false);
    }
  }

  @Action
  async getHotelConfiguration(configurationId: string) {
    try {
      this.setHotelRules([]);
      this.setHotelConfiguration(null);
      this.setHotelErrors([]);
      this.setHotelLoading(true);
      this.setHotelCustomError(false);
      const result = await AccommodationFeesConfigurationApi.getFeeConfiguration(configurationId);
      if (result && result.data) {
        this.setHotelConfiguration(result.data);
        this.setIsHotelConfigurationEnabled(result.data.isEnabled);
        if (result.data.feesRules) {
          this.setHotelRules(result.data.feesRules);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setHotelCustomError(true);
        this.setIsHotelConfigurationEnabled(true);
        this.setHotelConfiguration({
          configurationId,
          isVisibleToCustomer: false,
          isFeeAdjustableToTravelPolicy: false,
          isEnabled: true,
          feesRules: [],
        });
      } else {
        this.setHotelErrors(error);
      }
    } finally {
      this.setHotelLoading(false);
    }
  }

  @Action
  async getConfigurationName({companyId, configurationId}) {
    const result = await ProfileConfigurationsApi.getByRootCompanyId(
      companyId,
      companyConfigurationType.feesConfiguration,
      10,
      1,
      Permission.CanEditFeesConfiguration,
      ''
    );
    if (result && result.data) {
      let cfgFound = result.data.results.find(cfg => {
        return cfg.id === configurationId;
      });
      if (cfgFound && cfgFound.name) {
        this.setConfigurationName(cfgFound.name);
      }
    }
  }
}

export default getModule(FeesConfigurationStore);