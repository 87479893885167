

















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import VueContentLoading from 'vue-content-loading';
import { TrainOffersApi } from '@/api/train-engine/train-offers.api';

@Component({
  components: {
    VueContentLoading,
  }
})
export default class TrainFareDiscount extends Vue {
  @Prop() fareId!: string;
  @Prop() offerId!: string;
  @Prop() provider!: string;
  @Prop() isInBasket!: boolean;
  @Prop({ default: true }) basketMode!: boolean;

  loading: boolean = true;
  errors: any[] = [];
  data: any = null;

  async getData() {
    this.loading = true;
    let response;
    try {
      if (!this.isInBasket) {
        response = await TrainOffersApi.getVoucherCodesDetails(
          this.offerId,
          this.fareId,
          this.provider,
        );
      } else {
        if (this.basketMode) {
          response = await TrainOffersApi.getVoucherCodesOffersBasket(
            this.offerId,
            this.provider,
          );
        } else {
          response = await TrainOffersApi.getVoucherCodesOrdersBasket(
            this.offerId,
            this.provider,
          );
        }
      }

      if (response && response.data) {
        this.data = response.data;
      }

    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    this.getData();
  }
}
