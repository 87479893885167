










































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class TrainLoco extends Vue {

  get color() {
    return '#999999';
  }

}
