import RolesList from '@/modules/settings/roles/RolesList.vue';

export default {
  path: '',
  name: 'settings-roles',
  component: RolesList,
  displayName: 'Roles & permissions',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadRoles',
    depthCategory: 'settings-roles',
    depth: 1,
  },
};
