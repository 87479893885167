import ApprovalWorkflow from '@/modules/settings/approval-workflow/ApprovalWorkflow.vue';
import ApprovalWorkflowConfigurations from './approval-workflow-configurations.route';
import ApprovalWorkflowConfiguration from './approval-workflow-configuration.route';
import { translate } from '@/i18n';

export default {
  path: 'approval-workflow',
  component: ApprovalWorkflow,
  displayName: 'settings-sidebar.approval-workflow',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadApprovalWorkflow',
    icon: 'fact_check',
    breadCrumb: 'Approval Workflow configuration',
    parentName: 'approval-workflow-configurations',
  },
  children: [
    ApprovalWorkflowConfigurations,
    ApprovalWorkflowConfiguration,
  ],
};