




















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
  },
})
export default class BasketStepCheckbox extends Vue {
  @Prop() step: any;

  get stepValue() {
    return this.step.selected;
  }

  set stepValue(value) {
    BasketStore.setWizardStepSelected({
      index: this.step.index,
      value: value,
    });
  }
}
