




























































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { TripApi } from '@/api/trip/trip.api';
import { NotificationsTableParams } from './notifications.params';
import {
  BasketItemType,
  NotificationsStatus,
  SearchNotification,
} from '@/api/trip/trip.model';
import { translate } from '@/i18n';
import HeightTransition from '@/modules/layout/HeightTransition.vue';
import NotificationPopup from './NotificationPopup.vue';
import NotificationBritishAirwaysPopup from './NotificationBritishAirwaysPopup.vue';
import NotificationStatus from './NotificationStatus.vue';
import { SupplierName } from '@/services/supplier-name.service';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    HeightTransition,
    NotificationStatus,
    NotificationPopup,
    NotificationBritishAirwaysPopup,
  },
  filters: {
    dateFilter(date) {
      return moment.parseZone(date).format('YYYY-MM-DD HH:mm');
    },
  },
})
export default class NotificationsTable extends BaseTable<SearchNotification> {
  useQueryParams: boolean = true;
  showFilters: boolean = false;
  shouldShowMoreFilters: boolean = false;
  imagesConst = '/assets/img/loader/1.gif';
  showMarkConfirmationPopup: boolean = false;
  showBritishAirwaysNotificationPopup: boolean = false;
  inProgress: boolean = false;
  currentRow: any | null = null;
  errors: any[] = [];
  params: NotificationsTableParams = new NotificationsTableParams({});
  unreadNotifications: number = 0;

  fields = {
    date: {
      label: translate('notifications.date'),
      class: 'notifications__column--date',
    },
    tripCode: {
      label: translate('notifications.trip-code'),
      class: 'notifications__column--trip-code',
    },
    affectedBooking: {
      label: translate('notifications.affected-booking'),
      class: 'notifications__column--affected-booking',
    },
    companyName: { 
      label: translate('notifications.business-unit-company'),
      class: 'notifications__column--company-name',
    },
    notificationType: {
      label: translate('notifications.notification-type'),
      class: 'notifications__column--notification-type',
    },
    status: {
      label: translate('notifications.status'),
      class: 'notifications__column--status',
    },
    maskAsRead: {
      label: '',
      class: 'notifications__column--mark-as-read',
    },
    actionsColumn: {
      label: '',
      class: 'notifications__column--actions',
    },
  };
  statuses = [
    {
      label: translate('notifications.all'),
      value: NotificationsStatus.All,
    },
    {
      label: translate('notifications.read'),
      value: NotificationsStatus.Read,
    },
    {
      label: translate('notifications.unread'),
      value: NotificationsStatus.Unread,
    },
  ];
  iconsByType = {
    [BasketItemType.Unknown]: '',
    [BasketItemType.Air]: 'airplanemode_active',
    [BasketItemType.Rail]: 'train',
    [BasketItemType.Accommodation]: 'hotel',
    [BasketItemType.Car]: 'directions_car',
  };

  get notificationDateRange() {
    return {
      start: this.params.notificationDate.from ? moment(this.params.notificationDate.from).toDate() : null,
      end: this.params.notificationDate.to ? moment(this.params.notificationDate.to).toDate() : null,
    };
  }

  set notificationDateRange(val) {
    if (val && val.start && val.end) {
      this.params.notificationDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.notificationDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.notificationDate.from = null;
      this.params.notificationDate.to = null;
    }
  }

  get statusModel() {
    let status = this.params.status;
    if (status === '' || status === undefined) {
      status = 'All';
    }
    return this.statuses.find(item => status === item.value);
  }

  set statusModel(data) {
    if (data) {
      if (data.value === 'All') {
        this.params.status = '';
      } else {
        this.params.status = data.value;
      }
    }
  }

  async fillParameters(query) {
    this.params.tripCode = query.tripCode;
    this.params.type = query.type;
    this.params.status = query.status;
    this.params.affectedBooking = query.affectedBooking;
    this.params.companyName = query.companyName;
    this.params.notificationDate = {
      from: query.timestampRangeStart || '',
      to: query.timestampRangeEnd || '',
    };
  }

  fillQuery(query) {
    query.tripCode = this.params.tripCode;
    query.type = this.params.type;
    query.status = this.params.status;
    query.affectedBooking = this.params.affectedBooking;
    query.companyName = this.params.companyName;
    query.timestampRangeStart = this.params.notificationDate.from;
    query.timestampRangeEnd = this.params.notificationDate.to;
    if (this.params.page && this.params.page > 1) {
      query.page = this.params.page;
    }
  }

  async getItems(params: NotificationsTableParams): Promise<GetItemsResult<SearchNotification>> {
    let searchParams = {
      tripCode: params.tripCode,
      typePhrase: params.type,
      status: params.status,
      companyNamePhrase: params.companyName,
      providerReference: params.affectedBooking,
      timestampRangeStart: !!params.notificationDate.from ? new Date(params.notificationDate.from).toISOString() : undefined,
      timestampRangeEnd: !!params.notificationDate.to ? new Date(params.notificationDate.to).toISOString() : undefined,
    };

    try {
      const result = await TripApi.getNotifications({
        page: params.page,
        size: params.size,
      }, searchParams);

      this.unreadNotifications = result.data.unReadNotificationCount;
      return {
        page: result.data.page,
        results: result.data.notifications.map(item => {
          return {
            ...item,
            message: '',
            toggled: false,
          };
        }),
      };
    } catch (error) {
      this.errors = this.$handleErrors(error);
      throw(error);
    }
  }

  async submitForm() {
    this.resetList();
    this.reload();
  }

  showTripFilters() {
    this.showFilters = true;
  }

  hideTripFilters() {
    this.showFilters = false;
  }

  showMoreFilters() {
    this.shouldShowMoreFilters = true;
  }

  hideMoreFilters() {
    this.shouldShowMoreFilters = false;
  }

  handleEnterPress() {
    setTimeout(() => {
      const btn = ((this.$refs.searchButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  @Debounce({ delay: DebounceConst.defaultDelay })
  getResults() {
    this.resetList(); 
    this.reload();
  }

  itemProviderName(item) {
    return SupplierName(item.provider);
  }

  itemProviderReferences(item) {
    if (['Lufthansa', 'AmericanAirlines'].indexOf(item.provider) > -1) {
      return item.providerReference.filter(reference => {
        return reference.type === 'PNR' && reference.remarks !== 'F1';
      });
    }

    return item.providerReference;
  }

  hideAndDontMarkAsRead() {
    this.showMarkConfirmationPopup = false;
    this.showBritishAirwaysNotificationPopup = false;
    this.currentRow = null;
  }

  updateCurrentRow(value) {
    this.currentRow.item.hasBeenRead = value;
  }

  refreshData() {
    this.resetList(this.currentPage);
    this.reload();
    this.currentRow = null;
    EventBus.$emit('refresh-notifications-count');
  }

  showMessage(row) {
    this.currentRow = row;
    const isBritishAirways = this.currentRow.item.source === 'AirBritishAirways';
    this.showBritishAirwaysNotificationPopup = isBritishAirways;
    this.showMarkConfirmationPopup = !isBritishAirways;
  }
}
