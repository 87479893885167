

















import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class LHNDCPartnerWigdet extends Vue {
  canShowMore: boolean = true;
  elemHight = 0;

  mounted() {
    const el = this.$refs.widgetText as HTMLElement;
    if (el) {
      this.elemHight = el.offsetHeight;
    }
  }
}
