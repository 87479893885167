


































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';

import MissedSavingsStore from '@/modules/settings/missed-savings/missed-savings.store';

@Component({})
export default class MissedSavingsConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  Form: any = {
    name: '',
    missedSavings: [
      {
        reasonCode: '',
        displayValue: '',
      },
    ],
  };
  selectedTab: any = this.links[0].value;

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        missedSavings: {
          $each: {
            reasonCode: {
              maxLength: maxLength(20),
              required,
            },
            displayValue: {
              maxLength: maxLength(320),
              required,
            },
          }
        }
      }
    };
  }

  get links() {
    return [
      {
        value: 'Flight',
        name: translate('settings-travel-policy.flight'),
        disabled: false,
      },
      {
        value: 'Rail',
        name: translate('settings-travel-policy.rail'),
        disabled: true,
      },
      {
        value: 'Accommodation',
        name: translate('settings-travel-policy.accomodation'),
        disabled: true,
      },
      {
        value: 'Car',
        name: translate('settings-travel-policy.car'),
        disabled: true,
      }
    ];
  }

  get currentConfiguration() {
    return MissedSavingsStore.currentConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditMissedSavings');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return MissedSavingsStore.errMessages;
  }

  get showErrMessages() {
    return MissedSavingsStore.showError;
  }

  get loading() {
    return MissedSavingsStore.loading;
  }

  get canShowCustomError() {
    return MissedSavingsStore.canShowCustomError;
  }



  changeSelectedTab(item) {
    if (item.disabled) {
      return;
    }
    this.selectedTab = item.value;
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'missed-savings-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    MissedSavingsStore.clearErrors();
  }

  removeCode(index) {
    this.Form.missedSavings.splice(index, 1);
  }

  addCode() {
    this.Form.missedSavings.push({
      value: '',
    });
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.name,
        missedSavings: this.Form.missedSavings,
      };
      await MissedSavingsStore.createConfiguration(request);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-email-notifications.config-missed-savings-saved'),
        message: translate('settings-email-notifications.config-missed-savings-message'),
      };
      EventHandler.$emit('show-toast', obj);
      if (!this.errMessages.length) {
        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        configurationId: this.$route.params.configurationId,
        name: this.Form.name,
        missedSavings: this.Form.missedSavings,
      };
      await MissedSavingsStore.updateConfiguration(request);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-email-notifications.config-missed-savings-saved'),
        message: translate('settings-email-notifications.config-missed-savings-message')
      };
      EventHandler.$emit('show-toast', obj);
      if (!this.errMessages.length) {
        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        name: data.name,
        missedSavings: data.missedSavings,
      };
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        missedSavings: [
          {
            reasonCode: '',
            displayValue: '',
          },
        ],
      };
    }
  }

  async created() {
    if (this.inEditMode) {
      await MissedSavingsStore.getConfiguration(this.$route.params.configurationId);
      this.mapFormData(JSON.parse(JSON.stringify(this.currentConfiguration)));
    } else {
      this.mapFormData();
    }
  }
}
