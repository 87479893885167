






























import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import appConst from '@/const/app.const';
import { SkyScannerModel } from '@/api/air-engine/air-search.model';

@Component({})
export default class SkyScannerWidget extends Vue {
  @Prop() state!: SkyScannerModel;

  get primaryColor() {
    return appConst.primaryColor;
  }

  initSkyScannerWidget() {
    if (this.state) {
      const tripWidget: any = this.$refs.tripWidget;
      const searchWidget: any = this.$refs.searchWidget;
      tripWidget.dataset.originName = `'${this.state.fromCity}'`;
      tripWidget.dataset.destinationName = `'${this.state.targetCity}'`;
      tripWidget.dataset.currency = 'EUR';

      searchWidget.dataset.originName = `'${this.state.fromCity}'`;
      searchWidget.dataset.destinationName = `'${this.state.targetCity}'`;
      searchWidget.dataset.flightOutboundDate = moment(this.state.startDate).format('YYYY-MM-DD');
      searchWidget.dataset.currency = 'EUR';

      let returnDate = null;
      if (this.state && this.state.endDate && !this.state.isOneWay) {
       searchWidget.dataset.flightInboundDate = moment(this.state.endDate).format('YYYY-MM-DD');
      }

      setTimeout(() => {
        let script = 'https://widgets.skyscanner.net/widget-server/js/loader.js';
        let scriptIsLoaded = document.querySelectorAll(`script[src="${script}"]`).length > 0;
        let scriptelement = document.createElement('script');
        scriptelement.setAttribute('src', script);

        if (scriptIsLoaded) {
          document.body.childNodes.forEach(child => {
            if ((child as HTMLScriptElement).src === script) {
              document.body.replaceChild(scriptelement, child);
            }
          });
        } else {
          document.body.appendChild(scriptelement);
        }
      });
    }
  }

  mounted() {
    this.initSkyScannerWidget();
  }
}
