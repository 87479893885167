




































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CarApi } from '@/api/car-engine/car-search.api';
import BasketStore from './basket.store';
import SabreBasketExtras from './sabre/SabreBasketExtras.vue';
import BasketExpirationCountdown from './BasketExpirationCountdown.vue';

const ERROR_EXCEPTION_CODE = 'NO_RULE_FROM_VENDOR';
const ERROR_VEHICLE_RATE_CODE = 'VEH_RATE_RULE_FAILURE';

@Component({
   components: {
     SabreBasketExtras,
     BasketExpirationCountdown,
  }
})
export default class BasketExtrasView extends Vue {
  @Prop() basketExpired!: boolean;

  extrasVisible: boolean = true;

  get loading() {
    return BasketStore.loadingExtras;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    return this.wizardSteps[0].tripItemId;
  }

  get basketExtras() {
    return BasketStore.basketExtras;
  }

  get basketExtrasErrors() {
    return BasketStore.basketExtrasErrors;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get item(): any {
    const stepDef = this.wizardSteps[this.bookingStep - 1];
    if (stepDef && stepDef.code === 'CAR_SABRE_EXTRAS') {
      return this.basketItemsMapped.find(item => item.id === stepDef.tripItemId);
    }

    return this.basketItemsMapped;
  }

  async getItemConditions() {
    BasketStore.setBasketExtras([]);
    BasketStore.setCarExtrasLoadingFailed(false);
    try {
      let offer = this.item.car;
      BasketStore.setLoadingExtras(true);
      const response = await CarApi.getFareConditions({
        supplier: this.item.supplier,
        supplierSearchId: null,
        searchId: null,
        extras: null,
      }, offer.id);
      if (response && response.data) {
        BasketStore.setBasketExtras(response.data.carExtraCharges.map(e => {
          return {
            ...e,
            isSelected: true,
          };
        }));
        BasketStore.setSelectedExtras();
        BasketStore.setBasketItemPrice({
          id: this.item.id,
          price: response.data.totalPrice
        });
        BasketStore.setPriceChangesForStep({
          tripItemId: this.item.tripItemId,
          step: this.bookingStep,
          type: 'extras',
          prices: (BasketStore.selectedExtras)
            .forEach(i => {
              return {
                ...this.item,
                amount: -this.item.subTotalPrice.amount,
              };
            }),
        });
      }
    } catch (error) {
      BasketStore.setCarExtrasLoadingFailed(true);
      if (error.response && error.response.status !== 418 && error.response.data.error.code !== ERROR_EXCEPTION_CODE) {
        BasketStore.setBasketExtrasErrors(this.$handleErrors(error));
      }
      if (error.response && error.response.status === 418 && error.response.data.error.code === ERROR_VEHICLE_RATE_CODE) {
        this.extrasVisible = false;
      }
    } finally {
      BasketStore.setLoadingExtras(false);
    }
  }

  @Watch('bookingStep')
  async onNewItem(item) {
    this.extrasVisible = true;
    this.getItemConditions();
  }

  async created() {
    BasketStore.resetPriceChangesForStep({step: this.bookingStep, tripItemId: this.itemId});
    this.getItemConditions(); 
  }

  beforeDestroy() {
    BasketStore.resetSelectedExtras();
  }
}
