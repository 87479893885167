



















































import { Vue, Component } from 'vue-property-decorator';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { router } from '@/router';
import { userFullName } from '@/core/user-full-name';
import SearchStore from './search.store';
import BasketStore from '@/modules/basket/basket.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import { serviceClassEnum } from '@/api/home/home.model';

@Component({
  created: () => {
    -1 < [
      'air',
      'airTravellers',
      'airFilters',
      'airModification',
      'airModificationTravellers',
      'airResultDetails',
      'trainTravellers',
      'carTravellers',
      'hotelTravellers',
      'basketTravellers',
      'searchTravellersList',
      'airResultsTravellersList',
      'trainResultsTravellersList',
      'hotelResultsTravellersList',
      'carResultsTravellersList',
    ].indexOf(router.currentRoute.name || '') ?
      SearchStore.updateHideShowLess(false) :
      SearchStore.updateHideShowLess(true);
  }
})
export default class SearchTravellersList extends Vue {

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get editedTravellers() {
    return SearchStore.editedTravellers;
  }

  get shouldBeHidden() {
    return SearchStore.shouldHideShowLess;
  }

  get currentAirSearchIdFromApi() {
    return AirSearchStore.currentSearchIdFromApi;
  }

  get isSearchView() {
    return this.$route.name === 'search';
  }

  get isSearchDetails() {
    return -1 < [
      'airResultsTravellersList',
      'searchTravellersList',
      'airDetails',
      'trainDetails',
      'hotelResultsSearch',
      'carDetails',
    ].indexOf(this.$route.name || '');
  }

  get basketMetadata() {
    return BasketStore.basketMetadata ? BasketStore.basketMetadata.metadata : null;
  }

  get isEditEnabled() {
    return (this.isSearchView || this.isSearchDetails) && !this.basketMetadata;
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }
  
  get travellers() {
    if (this.isEditEnabled) {
      return this.editedTravellers.travellers;
    }
    return this.travellersSearchList.travellers;
  }



  userFullName(user) {
    return userFullName(user);
  }

  travellerUrl(trav) {
    return sanitizeUrl('/profile/' + trav.id);
  }

  selectMain(trav) {
    if (!this.isEditEnabled || this.skipTravellers) {
      return;
    }
    SearchStore.setMainTraveller(trav);
  }
  
  hideTravellers() {
    let params = {
      ...router.currentRoute.params
    };
    if (router.currentRoute.name === 'basketTravellers') {
      router.push({
        name: 'basket',
        params,
      });
      return;
    }
    if (router.currentRoute.name === 'airModificationTravellers') {
      router.push({
        name: 'airModification',
        params,
      });
      return;
    }
    if (router.currentRoute.name === 'searchTravellersList') {
      router.push({
        name: 'search',
        params,
      });
      return;
    }
    if (router.currentRoute.name!.includes('resultsTravellersList')) {
      if (router.currentRoute.params.service) {
        if (params.service) {
          delete params.service;
        }
        switch (router.currentRoute.params.service) {
          case serviceClassEnum.Flight:
            if (router.currentRoute.params.searchId === '-' && this.currentAirSearchIdFromApi !== '') {
              params.searchId = this.currentAirSearchIdFromApi;
            }
            router.push({
              name: 'air',
              params,
            });
            return;
          case serviceClassEnum.Train:
            router.push({
              name: 'train',
              params,
            });
            return;
          case serviceClassEnum.Hotel:
            router.push({
              name: 'hotel',
              params,
            });
            return;
          case serviceClassEnum.Car:
            router.push({
              name: 'car',
              params,
            });
            return;
          default: 
            router.push({
              name: 'search',
              params,
            });
            return;
        }
      } else {
        router.push({
          name: 'search',
          params,
        });
        return;
      }
    }
    switch (SearchStore.service) {
      case serviceClassEnum.Flight:
        if (router.currentRoute.params.searchId === '-' && this.currentAirSearchIdFromApi !== '') {
          params.searchId = this.currentAirSearchIdFromApi;
        }
        router.push({
          name: 'air',
          params,
        });
      break;
      case serviceClassEnum.Train:
        router.push({
          name: 'train',
          params,
          query: { leg:  this.$route.query.leg },
        });
      break;
      case serviceClassEnum.Hotel:
        if (this.$route.name === 'hotelDetailsTravellers') {
          router.push({
            name: 'hotelDetails',
            params,
          });
        } else {
          router.push({
            name: 'hotel',
            params,
          });
        }
      break;
      case serviceClassEnum.Car:
        router.push({
          name: 'car',
          params,
        });
      break;
    }
  }
}
