





















































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';

import SecurityStore from './security.store';

@Component({})
export default class SecurityConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  Form: any = {
    name: '',
    is2faRequired: false,
  };
  imagesConst: string = '/assets/img/loader/1.gif';

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
      }
    };
  }

  get currentConfiguration() {
    return SecurityStore.SecurityConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditSecurityConfiguration');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return SecurityStore.errMessages;
  }

  get showErrMessages() {
    return SecurityStore.showError;
  }

  get loading() {
    return SecurityStore.loading;
  }

  get errors() {
    return SecurityStore.errMessages;
  }
  
  get showError() {
    return SecurityStore.showError;
  }

  get canShowCustomError() {
    return SecurityStore.canShowCustomError;
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'security-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    SecurityStore.setErrMessages([]);
    SecurityStore.setShowError(false);
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(false);
      await SecurityStore.addSecurityConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.resetStoreErrors();
      let request = this.mapFormDataForRequest(true);
      await SecurityStore.updateSecurityConfiguration(request);
      if (!(this.errMessages && this.errMessages.length)) {
        this.goToConfigurationList();
      }
    }
  }

  mapFormDataForRequest(edit: boolean = false) {
    let request;
    if (edit) {
      request = {
        configurationId: this.$route.params.configurationId,
        params: {
          name: this.Form.name,
          is2faRequired: this.Form.is2faRequired,
        }
      };
    } else {
      request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.name,
        is2faRequired: this.Form.is2faRequired,
      };
    }
    
    return request;
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        name: data.name,
        rootCompanyId: data.rootCompanyId,
        is2faRequired: data.is2faRequired,
      };
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        rootCompanyId: this.$route.params.id,
        is2faRequired: false,
      };
    }
  }

  async created() {
    if (this.inEditMode) {
      await SecurityStore.getSecurityConfiguration(this.$route.params.configurationId);
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
      this.Form = {
        configurationId: this.Form.configurationId,
        name: this.Form.name,
        rootCompanyId: this.Form.rootCompanyId,
        is2faRequired: this.Form.is2faRequired,
      };
    } else {
      SecurityStore.clearData();
      this.Form = {
        configurationId: this.Form.configurationId,
        name: this.Form.name,
        rootCompanyId: this.Form.rootCompanyId,
        is2faRequired: this.Form.is2faRequired,
      };
    }
  }
}
