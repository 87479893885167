








































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import settings from '@/settings';
import HotelSearchStore from './hotel-search.store';
import HotelOfferConditions from './HotelOfferConditions.vue';
import HotelRoomName from './HotelRoomName.vue';
import HotelRoomNameWithTenants from './HotelRoomNameWithTenants.vue';
import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    HotelOfferConditions,
    HotelRoomName,
    HotelRoomNameWithTenants,
  },
  filters: {
    dateTime(date) {
      return moment.parseZone(date).format('Do MMMM YYYY ');
    }
  },
})
export default class HotelRoom extends Vue {
  @Prop() offer!: any;
  @Prop({ default: false }) disabled!: boolean;



  get disableOfferSelection() {
    return HotelSearchStore.selectingOffer || this.disabled;
  }

  get searchId() {
    return this.$route.params.searchId;
  }

  get selectedOfferId() {
    return HotelSearchStore.selectedOfferId;
  }

  get offerTenants() {
    if (!this.offer.roomOffers || !this.offer.roomOffers.length) {
      return '';
    }
    let text = translate('search.adults') + ': ' + this.offer.roomOffers[0].adultsCount;

    if (this.offer.roomOffers[0].childrenCount) {
      text += ' | ' + translate('search.children') + ': ' + this.offer.roomOffers[0].childrenCount;
    }

    return text;
  }

  get canShowExternalAgencyMarkup() {
    return 'true' === settings.enableExternalAgencyMarkup;
  }

  get canAddOfferToTrip() {
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get currentDateTimeFormat() {
    return `${this.currentDateFormat} ${this.currentTimeFormat}`;
  }

  get refundableUntil() {
    if (!this.offer || !this.offer.refundableUntil) {
      return '';
    }
    return moment.parseZone(this.offer.refundableUntil).format(this.currentDateTimeFormat);
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasEditAgencyMarkupPermission() {
    return this.$hasAccess('CanEditAgencyMarkup');
  }

  get currentlySelectedOffer() {
    const offerId = this.offer.id;
    return offerId === this.selectedOfferId;
  }
  
  get providerImage() {
    const provider = this.offer.offerProvider;
    return provider === 'Fake' ? provider.toLowerCase() : provider;
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get tooltipContent() {
    const offer = this.offer;
    let result = '';
    
    if (offer.rawProviderPrice) {
      result += `<span>${translate('basket.raw-provider-price')} ${this.formatPrice(offer.rawProviderPrice)}</span>`;
    }
    if (offer.systemMarkup) {
      result += `<span class="mb-3">${translate('basket.system-markup')} ${this.formatPrice(offer.systemMarkup)}</span>`;
    }

    result += `<span>${this.formatPrice(offer.pricePerRoomPerNight)} ${translate('search-hotel.room-per-night')}</span>`;
    if (offer.providerPrice) {
      result += `<span>${translate('basket.incl')} ${this.formatPrice(offer.providerPrice)} ${translate('search-hotel.provider-price')}</span>`;
    }

    if (offer.agencyMarkup) {
      result += `<span>${translate('basket.incl')} ${this.formatPrice(offer.agencyMarkup)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if (offer.agencyFee) {
      result += `<span>${translate('basket.incl')} ${this.formatPrice(offer.agencyFee)} ${translate('search-air.fee')}</span>`;
    }
    return result;
  }

  editFee() {
    EventBus.$emit('edit-fee-for-room', this.offer);
  }

  editAgencyMarkup() {
    EventBus.$emit('edit-agency-markup-for-room', this.offer);
  }

  formatPrice(price) {
    return (price.currency.symbol || price.currency.code) + ' ' + (Math.round(100 * price.amount) / 100).toFixed(2);
  }
  
  selectOffer() {
    if (this.disabled) {
      return;
    }

    this.$emit('select-offer', this.offer);
  }

  showConditions() {
    this.$emit('show-conditions', this.offer);
  }

  onOfferFeeEdited({ offerId, feeValue, totalPrice }) {
    if (this.offer.id !== offerId) {
      return;
    }

    this.offer.agencyFee.amount = feeValue;
    this.offer.totalPrice.amount = totalPrice;
    this.offer.pricePlusMarkup.amount = totalPrice;
  }

  onOfferAgencyMarkupEdited({ offerId, agencyMarkupValue, totalPrice }) {
    if (this.offer.id !== offerId) {
      return;
    }

    this.offer.agencyMarkup.amount = agencyMarkupValue;
    this.offer.totalPrice.amount = totalPrice;
    this.offer.pricePlusMarkup.amount = totalPrice;
  }

  onUpdateRoomTravelPolicy(offers) {
    const newOffer = offers.find(item => item.id === this.offer.id);

    if (!newOffer) {
      return;
    }

    this.offer.travelPolicy = newOffer.travelPolicy;
  }

  mounted() {
    EventBus.$on('offer-fee-edited', this.onOfferFeeEdited);
    EventBus.$on('offer-agency-markup-edited', this.onOfferAgencyMarkupEdited);
    EventBus.$on('update-hotel-room-travel-policy', this.onUpdateRoomTravelPolicy);
  }

  beforeDestroy() {
    EventBus.$off('offer-fee-edited', this.onOfferFeeEdited);
    EventBus.$off('offer-agency-markup-edited', this.onOfferAgencyMarkupEdited);
    EventBus.$off('update-hotel-room-travel-policy', this.onUpdateRoomTravelPolicy);
  }
}
