


























































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, minLength, email } from 'vuelidate/lib/validators';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { ContactsApi } from '@/api/profile/contacts.api';
import { ContactsModel, PhoneCode } from '@/api/profile/contacts.model';
import dictionaryStore from '@/store/dictionary.store';
import ProfileStore from '@/modules/profile/profile.store';
import { translate } from '@/i18n';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
import AccountStore from '@/store/account.store';

const phoneregex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^\d{3,17}$/.test(value);
};

const successTag = 'ActionEndedSuccessfully';
const failTag = 'ActionEndedWithErrors';

@Component({})
export default class Contacts extends Vue {
  Form: ContactsModel = this.createEmptyForm();
  showFullEmergency: boolean = false;

  createEmptyForm () {
    return {
      primary: {
        email: '',
        phone: {
          code: '',
          number: '',
          isMobile: false,
        },
      },
      secondary: {
        email: '',
        phone: {
          code: '',
          number: '',
          isMobile: false,
        },
      },
      private: {
        email: '',
        phone: {
          code: '',
          number: '',
          isMobile: false,
        },
      },
      emergency: {
        firstName: '',
        lastName: '',
        relationType: '',
        addressLine: '',
        cityName: '',
        postalCode: '',
        countryCode: null,
        email: '',
        phone: {
          code: '',
          number: '',
          isMobile: false,
        },
      },
      isRecoveryEmailEditable: false,
      passwordRecoveryEmail: '',
    };
  }

  phoneCodeEmptyValue: PhoneCode = {
    phoneCode: '',
    countryName: '',
    code: '',
    threeLetterCode: '',
    shortPhoneCodeDisplayName: 'Phone code',
    fullPhoneCodeDisplayName: 'None',
  };

  selectedPrimaryPhoneNumberCode: PhoneCode | null = null;
  selectedSecondaryPhoneNumberCode: PhoneCode | null = null;
  selectedPrivatePhoneNumberCode: PhoneCode | null = null;
  selectedEmergencyPhoneNumberCode: PhoneCode | null = null;
  selectedEmergencyCountryCode: any | null = null;

  serverErrors: any[] = [];
  $v;
  accountId: string | null = null;

  formPending: boolean = false;
  

  get user() {
    return AccountStore.user;
  }

  get profile() {
    return ProfileStore.userProfile;
  }

  get currentUser() {
    return AccountStore.user;
  }

  get isRecoveryEmailSectionVisible() {
    return !!this.accountId;
  }

  get allCountries() {
    return dictionaryStore.allCountries;
  }

  get allPhoneCountryCodes() {
    if (!dictionaryStore.allCountries) {
      return [];
    }

    let allPhoneCodes = dictionaryStore.allCountries.filter((country) => { return country && country.phoneCode!.length > 0; })
    .map((country) => {
      return { 
        ...country,
        shortPhoneCodeDisplayName: '+' + country.phoneCode,
        fullPhoneCodeDisplayName: country.countryName + ' +' + country.phoneCode
      };
    });

    return [this.phoneCodeEmptyValue].concat(allPhoneCodes as Array<PhoneCode>);
  }

  get primaryPhoneNumberCodeRequired() {
    return this.Form.primary.phone
    && this.Form.primary.phone.number
    && this.Form.primary.phone.number.length > 0 && (!this.selectedPrimaryPhoneNumberCode || !this.selectedPrimaryPhoneNumberCode.phoneCode);
  }

  get secondaryPhoneNumberCodeRequired() {
    return this.Form.secondary!.phone && this.Form.secondary!.phone.number && this.Form.secondary!.phone.number.length > 0 && (!this.selectedSecondaryPhoneNumberCode || !this.selectedSecondaryPhoneNumberCode.phoneCode);
  }

  get privatePhoneNumberCodeRequired() {
    return this.Form.private!.phone && this.Form.private!.phone.number && this.Form.private!.phone.number.length > 0 && (!this.selectedPrivatePhoneNumberCode || !this.selectedPrivatePhoneNumberCode.phoneCode);
  }

  get emergencyPhoneNumberCodeRequired() {
    return this.Form.emergency!.phone && this.Form.emergency!.phone.number && this.Form.emergency!.phone.number.length > 0 && (!this.selectedEmergencyPhoneNumberCode ||  !this.selectedEmergencyPhoneNumberCode.phoneCode);
  }

  get primaryPhoneNumberRequired() {
    return this.selectedPrimaryPhoneNumberCode && this.selectedPrimaryPhoneNumberCode.phoneCode &&
    (!this.Form.primary.phone!.number || this.Form.primary.phone!.number.length === 0);
  }

  get secondaryPhoneNumberRequired() {
    return this.selectedSecondaryPhoneNumberCode && this.selectedSecondaryPhoneNumberCode.phoneCode &&
    (!this.Form.secondary!.phone!.number || (this.Form.secondary!.phone!.number && this.Form.secondary!.phone!.number.length === 0));
  }

  get privatePhoneNumberRequired() {
    return this.selectedPrivatePhoneNumberCode && this.selectedPrivatePhoneNumberCode.phoneCode &&
    (!this.Form.private!.phone!.number || (this.Form.private!.phone!.number && this.Form.private!.phone!.number.length === 0));
  }

  get emergencyPhoneNumberRequired() {
    return this.selectedEmergencyPhoneNumberCode && this.selectedEmergencyPhoneNumberCode.phoneCode &&
    (!this.Form.emergency!.phone!.number || (this.Form.emergency!.phone!.number && this.Form.emergency!.phone!.number.length === 0));
  }

  get disableSaveButton() {
    if (this.formPending || this.$v.Form.$error || this.primaryPhoneNumberCodeRequired || this.secondaryPhoneNumberCodeRequired || this.privatePhoneNumberCodeRequired
    || this.emergencyPhoneNumberCodeRequired || this.primaryPhoneNumberRequired || this.secondaryPhoneNumberRequired || this.privatePhoneNumberRequired || this.emergencyPhoneNumberRequired) {
      return true;
    } else {
      return false;
    }
  }

  @Validation()
  validationObject() {
    let validation: any = {
      Form: {
        primary: {
          email: {
            required,
            maxLength: maxLength(255),
            email,
          },
          phone: {
            number: {
              minLength: minLength(3),
              maxLength: maxLength(17),
              phoneregex,
            },
          },
        },
        secondary: {
          email: {
            maxLength: maxLength(255),
            email,
          },
          phone: {
            number: {
              minLength: minLength(3),
              maxLength: maxLength(17),
              phoneregex,
            },
          },
        },
        private: {
          email: {
            maxLength: maxLength(255),
            email,
          },
          phone: {
            number: {
              minLength: minLength(3),
              maxLength: maxLength(17),
              phoneregex,
            },
          },
        },
        emergency: {
          firstName: {
            maxLength: maxLength(100),
          },
          lastName: {
            maxLength: maxLength(255),
          },
          relationType: {
            maxLength: maxLength(255),
          },
          addressLine: {
            maxLength: maxLength(255),
          },
          cityName: {
            maxLength: maxLength(100),
          },
          postalCode: {
            maxLength: maxLength(10),
          },
          email: {
            maxLength: maxLength(255),
            email,
          },
          phone: {
            number: {
              minLength: minLength(3),
              maxLength: maxLength(17),
              phoneregex,
            },
          },
        },
      }
    };

    if (this.Form.isRecoveryEmailEditable && this.isRecoveryEmailSectionVisible) {
      validation.Form = {
        ...validation.Form,
        passwordRecoveryEmail: {
          required,
          maxLength: maxLength(255),
          email,
        },
      };
    }
    return validation;
  }

  triggerFullEmergency() {
    this.showFullEmergency = !this.showFullEmergency;
  }

  onPrimaryPhoneNumberCodeChange(selectedPhoneCode) {
    if (this.Form.primary && selectedPhoneCode && !selectedPhoneCode.phoneCode) {
      this.Form.primary.phone!.number = '';
    }
    setTimeout(() => {
      const el = (this.$refs.primaryNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  onSecondaryPhoneNumberCodeChange(selectedPhoneCode) {
    if (this.Form && selectedPhoneCode && !selectedPhoneCode.phoneCode) {
      this.Form.secondary!.phone!.number = '';
    }
    setTimeout(() => {
      const el = (this.$refs.secondaryNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  onPrivatePhoneNumberCodeChange(selectedPhoneCode) {
    if (this.Form && selectedPhoneCode && !selectedPhoneCode.phoneCode) {
      this.Form.private!.phone!.number = '';
    }
    setTimeout(() => {
      const el = (this.$refs.privateNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  onEmergencyPhoneNumberCodeChange(selectedPhoneCode) {
    if (this.Form && selectedPhoneCode && !selectedPhoneCode.phoneCode) {
      this.Form.emergency!.phone!.number = '';
    }
    setTimeout(() => {
      const el = (this.$refs.emergencyNumber as Vue).$refs.input as HTMLElement;
      el.focus();
    });
  }

  focusOnSaveButton() {
    setTimeout(() => {
      const btn = ((this.$refs.saveButton as Vue).$el as HTMLInputElement);
      btn.focus();
    }, 50);
  }

  clearSelectedCode() {
    this.selectedPrimaryPhoneNumberCode = null;
    this.selectedSecondaryPhoneNumberCode = null;
    this.selectedPrivatePhoneNumberCode = null;
    this.selectedEmergencyPhoneNumberCode = null;
  }

  customMap(objValue, srcValue, key, object, source) {
    const value = typeof(srcValue) === 'object' ? _.assignInWith(objValue, srcValue, this.customMap) : srcValue;

    return srcValue === null ? objValue : value;
  }

  setSelectetedPrimaryPhoneNumberCode() {
    if (this.Form.primary && this.Form.primary.phone && this.Form.primary.phone.code) {
      this.selectedPrimaryPhoneNumberCode = this.allPhoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === this.Form.primary.phone!.code; })!;
    }
  }

  setSelectedSecondaryPhoneNumberCode() {
    if (this.Form.secondary && this.Form.secondary.phone && this.Form.secondary.phone.code) {
      this.selectedSecondaryPhoneNumberCode = this.allPhoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === this.Form.secondary!.phone!.code; })!;
    }
  }

  setSelectedPrivatePhoneNumberCode() {
    if (this.Form.private && this.Form.private.phone && this.Form.private.phone.code) {
      this.selectedPrivatePhoneNumberCode = this.allPhoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === this.Form.private!.phone!.code; })!;
    }
  }

  setEmergencyCodes() {
    if (this.Form.emergency && this.Form.emergency.phone && this.Form.emergency.phone.code) {
      this.selectedEmergencyPhoneNumberCode = this.allPhoneCountryCodes.find(phoneCode => { return phoneCode.phoneCode === this.Form.emergency!.phone!.code; })!;
    }
    if (this.Form.emergency && this.Form.emergency.countryCode && this.allCountries) {
      this.selectedEmergencyCountryCode = this.allCountries.find(country => { return country.code === this.Form.emergency!.countryCode; })!;
    }
  }

  async init() {
    try {
      let profileId = this.$route.params.id;
      const contacts = await ContactsApi.getContacts(profileId);
      if (contacts && contacts.data) {
        this.Form = _.assignInWith(this.Form, contacts.data, this.customMap);
        this.clearSelectedCode();

        this.setSelectetedPrimaryPhoneNumberCode();
        this.setSelectedSecondaryPhoneNumberCode();
        this.setSelectedPrivatePhoneNumberCode();
        this.setEmergencyCodes();

        if (ProfileStore.userProfile && ProfileStore.userProfile.id !== router.currentRoute.params.id) {
          this.accountId = this.profile!.accountId;
        } else {
          let profile = this.profile!;

          this.accountId = profile.accountId;
        }
      }
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    }
  }

  @Watch('$route', { deep: true, immediate: true})
  onRouteChange() {
    this.serverErrors = [];
    this.Form = this.createEmptyForm();
    this.init();
  }

  requestMap(objValue, srcValue, key, object, source) {
    if (srcValue === null) {
      return null;
    }

    if (key === 'email') {
      return srcValue.trim() === '' ? null : srcValue;
    }

    let phone = null;
    if (srcValue.hasOwnProperty('phone') && srcValue.phone !== null) {
      phone = (srcValue.phone.code === null || srcValue.phone.code.trim() === '') && srcValue.phone.number.trim() === '' ? null : srcValue.phone;
    }

    if (srcValue.hasOwnProperty('email') && (srcValue.email === null || srcValue.email.trim() === '') && phone === null) {
      if (key === 'emergency') {
        return typeof(srcValue) === 'object' ? _.assignInWith(objValue, srcValue, this.requestMap) : srcValue;
      } else {
        return null;
      }
    }

    if (key === 'phone') {
      return srcValue.code.trim() === '' && srcValue.number.trim() === '' ? null : srcValue;
    }

    return typeof(srcValue) === 'object' ? _.assignInWith(objValue, srcValue, this.requestMap) : srcValue;
  }

  async submit() {
    this.$v.Form.$touch();

    if (this.$v.Form.$pending || this.$v.Form.$error) {
      return;
    }

    if (this.primaryPhoneNumberCodeRequired || this.secondaryPhoneNumberCodeRequired || this.privatePhoneNumberCodeRequired || this.emergencyPhoneNumberCodeRequired) {
      return;
    }

    if (this.primaryPhoneNumberRequired || this.secondaryPhoneNumberRequired || this.privatePhoneNumberRequired || this.emergencyPhoneNumberRequired) {
      return;
    }

    this.formPending = true;

    this.Form.primary.phone!.code = this.selectedPrimaryPhoneNumberCode ? this.selectedPrimaryPhoneNumberCode.phoneCode : '';
    this.Form.secondary!.phone!.code = this.selectedSecondaryPhoneNumberCode ? this.selectedSecondaryPhoneNumberCode.phoneCode : '';
    this.Form.private!.phone!.code = this.selectedPrivatePhoneNumberCode ? this.selectedPrivatePhoneNumberCode.phoneCode : '';
    this.Form.emergency!.phone!.code = this.selectedEmergencyPhoneNumberCode ? this.selectedEmergencyPhoneNumberCode.phoneCode : '';
    this.Form.emergency!.countryCode = this.selectedEmergencyCountryCode ? this.selectedEmergencyCountryCode.code : '';

    try {
      const profileId = router.currentRoute.params.id;

      let emptyRequest: ContactsModel = {} as ContactsModel;
      emptyRequest = _.assignInWith(emptyRequest, this.Form, this.requestMap);
      this.Form = _.assignInWith(this.Form, emptyRequest, this.customMap);

      const { data } = await ContactsApi.updateContacts(
        profileId,
        emptyRequest,
      );

      if (data.contactsUpdated === successTag) {
        EventHandler.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('profile-contacts.save-info')
        });
        EventHandler.$emit('profile-data-saved');
      }
      if (data.contactsUpdated === failTag) {
        EventHandler.$emit('show-toast', {
          type: translate('common.error'),
          title: translate('common-error.error'),
          message: translate('profile-contacts.save-failed-info')
        });
      }
      if (data.passwordRecoveryEmailUpdated === failTag) {
        EventHandler.$emit('show-toast', {
          type: translate('common.error'),
          title: translate('common-error.error'),
          message: translate('profile-contacts.recovery-save-failed-info')
        });
      }
    } catch (error) {
      this.serverErrors = $handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }
}

