import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { PluginsApi } from '@/api/profile/plugins.api';
import {
  PluginsConfiguration,
  PluginsConfigurationEntry
} from '@/api/profile/plugins.model';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'plugins'
})
class PluginsStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  currentConfigurationName: string = '';
  pluginsConfiguration: PluginsConfiguration = new PluginsConfiguration();
  pluginsConfigurationItems: PluginsConfigurationEntry[] = [];
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }
  
  get PluginsConfiguration() {
    return this.pluginsConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.currentConfigurationName = value;
  }

  @Mutation
  setPluginsConfiguration(payload) {
    this.pluginsConfiguration = payload;
  }

  @Mutation
  setPluginsConfigurationItems(payload) {
    this.pluginsConfigurationItems = payload;
  }

  @Mutation
  setName(value) {
    this.pluginsConfiguration.name = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'plugins-configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }
  
  @Action
  clearData() {
    this.setPluginsConfiguration(new PluginsConfiguration());
    this.setCurrentConfigurationName('');
  }

  @Action
  async getPluginsConfiguration(configurationId) {
    try {
      this.setCustomError(false);
      this.setLoading(true);
      this.setShowError(false);
      if (configurationId) {
        const result = await PluginsApi.getConfiguration(configurationId);
        if (result && result.data) {
          this.setPluginsConfiguration(result.data);
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getEmptyConfiguration() {
    try {
      this.setCustomError(false);
      this.setLoading(true);
      this.setShowError(false);
      const result = await PluginsApi.getEmpty();
      if (result && result.data) {
        this.setPluginsConfiguration(result.data);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async addPluginsConfiguration(request) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result: any = await PluginsApi.createConfiguration(request);
      if (result && result.data) {
        this.setConfigurationId(result.data.configurationId);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-plugins.config-saved')
        });

        this.getPluginsConfiguration(result.data.configurationId);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updatePluginsConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      await PluginsApi.updateConfiguration(data.configurationId, data.params);

      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('common.saved'),
        message: translate('settings-plugins.config-saved')
      });

    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(PluginsStore);
