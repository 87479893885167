


























































































































































import _ from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import { VueContentLoading } from 'vue-content-loading';
import SearchStore from '@/modules/search/search.store';
import AccountStore from '@/store/account.store';
import AirSearchStore from './air-search.store';

@Component({
  components: {
    VueContentLoading
  }
})
export default class AirSort extends Vue {
  isProviderErrorsVisible: boolean = false;
  isExchangeErrorsVisible: boolean = false;
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  imagesConst = '/assets/img/loader/1.gif';
  toggleSortOptions: boolean = false;

  get loading() {
    return AirSearchStore.loading;
  }

  get currentFilters() {
    return AirSearchStore.filters;
  }

  get sortOptions() {
    return AirSearchStore.sortOptions;
  }

  get isDisabled() {
    return AirSearchStore.isSelected;
  }

  get priceSorterEnabled() {
    return this.sortOptions.PRICE !== undefined;
  }

  get priceSorterStatus() {
    return this.sortOptions.PRICE === 'Ascending';
  }

  get departureTimeSorterEnabled() {
    return this.sortOptions.DEPARTURE_TIME !== undefined;
  }

  get shortestSumFlightSorterEnabled() {
    return this.sortOptions.SHORTEST !== undefined;
  }

  get bestFareSorterEnabled() {
    return this.sortOptions.BEST_FARE_DEPARTURE_TIME !== undefined;
  }

  get departureTimeSorterStatus() {
    return this.sortOptions.DEPARTURE_TIME === 'Ascending';
  }

  get bestFareSorterStatus() {
    return this.sortOptions.BEST_FARE_DEPARTURE_TIME === 'Ascending';
  }

  get currentSortType() {
    if (this.bestFareSorterEnabled) {
      return 'sort-by-best-fare';
    } else if (this.priceSorterEnabled) {
      return 'sort-by-cheapest-offer';
    } else if (this.departureTimeSorterEnabled) {
      return 'sort-by-earliest-departure';
    }
    return 'sort-by-shortest-flight';
  }

  get offerVisible() {
    return AirSearchStore.offersVisible;
  }

  get displayedOffers() {
    return Math.min(this.offerVisible, this.totalOffers);
  }

  get totalOffers() {
    return AirSearchStore.totalOffers;
  }

  get filters() {
    return AirSearchStore.filters;
  }

  get offers() {
    return AirSearchStore.offers;
  }

  get recommendationsAirCount() {
    return AirSearchStore.recommendationsAirCount;
  }

  get isNotFiltered() {
    return this.totalOffers === this.recommendationsAirCount;
  }

  get isSearchCompleted() {
    return AirSearchStore.searchCompleted;
  }

  get exchangeErrors() {
    return [...AirSearchStore.initExchangeErrors, ...AirSearchStore.exchangeErrors];
  }

  get providersErrors() {
    return AirSearchStore.providersErrors;
  }

  get groupedProvidersErrors() {
    return _.groupBy(AirSearchStore.providersErrors, 'supplier');
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get switchNewDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get classes() {
    return {
      'justify-content-end': this.offers && this.offers.length === 0,
      'justify-content-between': this.offers && this.offers.length > 0,
    };
  }

  set switchNewDisplay(value) {
    AirSearchStore.updateFilters([]);
    AirSearchStore.updateOffers([]);
    SearchStore.resetTemplateOffers();
    AirSearchStore.setErrMessages(null);
    AirSearchStore.setNewFlightDisplay(value);
    this.$cookies.set('NewFlightDisplay', value, '30d');
    let searchId = this.$route.params.searchId;
    if (searchId && !this.searchModifyState) {
      AirSearchStore.loader(true);
      if (this.searchModifyState) {
        AirSearchStore.getExchangeOffers(searchId);
      } else {
        AirSearchStore.getOffers(searchId);
      }
    } else {
      AirSearchStore.loader(true);
      AirSearchStore.getExchangeOffers(searchId);
    }
  }

  get canSeeProviderWarnings() {
    return AccountStore.HasPermission('CanSeeProviderWarnings');
  }


  toggleSort(sorterCode) {
    if (this.isDisabled) {
      return;
    }
    if (this.sortOptions[sorterCode] === undefined) {
      if (sorterCode === 'BEST_FARE_DEPARTURE_TIME') {
        AirSearchStore.setNewSort({ sorterCode, isAscending: false });
      } else {
        AirSearchStore.setNewSort({ sorterCode, isAscending: true });
      }
      AirSearchStore.setOffersVisible(10);

      AirSearchStore.setSearchFreezed(true);
      const searchId = this.$route.params.searchId;
      if (this.searchModifyState) {
        AirSearchStore.getExchangeOffers(searchId);
      } else {
        AirSearchStore.getOffers(searchId);
      }
    }
    this.toggleSortOptions = false;
  }

  toggleSortDropdown() {
    if (this.isDisabled) {
      return;
    }
    this.toggleSortOptions = !this.toggleSortOptions;
  }

  showProviderErrors() {
    this.isProviderErrorsVisible = true;
  }


  hideProviderErrors() {
    this.isProviderErrorsVisible = false;
  }

  showExchangeErrors() {
    this.isExchangeErrorsVisible = true;
  }

  hideExchangeErrors() {
    this.isExchangeErrorsVisible = false;
  }

  translationOrEmpty(key: string) {
    return this.$te(key) ? this.$t(key) : '';
  }

}
