export class BillingConfigurationItem {
  code: string = '';
  value: string | null = null;

  constructor(item?: any) {
    if (item.code !== undefined) {
      this.code = item.code;
    }
    if (item.value !== undefined) {
      this.value = item.value;
    }
  }
}

export class BillingConfiguration {
  rootCompanyId: string = '';
  name: string = '';
  mode: BillingConfigurationModeEnum | null = null;
  backofficeConfiguration?: BackOfficeConfiguration = new BackOfficeConfiguration();
  gestourConfiguration?: GestourConfiguration = new GestourConfiguration();

  constructor(configuration?: any) {
    if (configuration) {
      if (configuration.rootCompanyId !== undefined) {
        this.rootCompanyId = configuration.rootCompanyId;
      }
      if (configuration.name !== undefined) {
        this.name = configuration.name;
      }
      if (configuration.mode !== undefined) {
        this.mode = configuration.mode;
      }
      if (configuration.backofficeConfiguration !== undefined) {
        this.backofficeConfiguration = configuration.backofficeConfiguration;
      }
      if (configuration.gestourConfiguration !== undefined) {
        this.gestourConfiguration = configuration.gestourConfiguration;
      }
    }
  }
}

export class GestourConfiguration {
  secretApiKey: string = '';
  customerCode: string = '';

  constructor(data?: any) {
    if (data) {
      if (data.secretApiKey !== undefined) {
        this.secretApiKey = data.secretApiKey;
      }
      if (data.customerCode !== undefined) {
        this.customerCode = data.customerCode;
      }
    }
  }
}

export class BackOfficeConfiguration {
  billingAccountId: string = '';
  notificationMode: string | null = null;
  url: string = '';

  constructor(data?: any) {
    if (data) {
      if (data.billingAccountId !== undefined) {
        this.billingAccountId = data.billingAccountId;
      }
      if (data.notificationMode !== undefined) {
        this.notificationMode = data.notificationMode;
      }
      if (data.url !== undefined) {
        this.url = data.url;
      }
    }
  }
}

export interface UpdateBillingConfigurationParams {
  name: string;
  mode: BillingConfigurationModeEnum;
  values: BillingConfigurationItem[];
}

export interface UpdateBillingConfigurationMessage {
  configurationId: string;
  params: UpdateBillingConfigurationParams;
}

export enum BillingConfigurationModeEnum {
  BackOffice = 'BackOffice',
  Gestour = 'Gestour'
}