


































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength} from 'vuelidate/lib/validators';
import _ from 'lodash';
import DictionaryStore from '@/store/dictionary.store';
import { AirLine } from '@/api/dictionary/dictionary.model';
import PnrRemarksStore from './pnr-remarks.store';

@Component({})
export default class PnrRemarksSsrCodes extends Vue {
  @Prop({}) supplier!: string;
  @Prop({}) remarks!: any[];
  @Prop({}) service!: string;
 

  $v;
  allAirline: any[] = [];
  airlines: any[] = [];
  sabreCodeTypes: any[] = ['CLID', 'OSI'];

  @Validation()
  validationObject() {
    const cantBeSame = (value, model) => {
      const entryCheck = entry => entry.type === model.type &&
          entry.value === model.value;
      const firstIndex = this.entries.findIndex(entryCheck);
      const lastIndex = this.entries.findLastIndex(entryCheck);

      return firstIndex === lastIndex;
    };
    return {
      entries: {
        $each: {
          type: {
            required,
            maxLength: maxLength(10),
            cantBeSame,
          },
          value: {
            required,
            maxLength: maxLength(64),
            cantBeSame,
          },
        }
      }
    };
  }

  get entries() {
    const remarksFound = this.providerObject && this.providerObject.pnrSsrCodes ? this.providerObject.pnrSsrCodes : [];
    return remarksFound;
  }

  get providerObject() {
    return this.remarks.find(e => {
      return e.supplier === this.supplier;
    });
  }

  get isAmadeus() {
    return this.supplier === 'Amadeus';
  }

  get isSabre() {
    return this.supplier === 'Sabre';
  }

  get hasWritePermission() {
    return this.$hasAccess('WritePnrRemarks');
  }



  airlinesMaper() {
    this.airlines = this.allAirline = DictionaryStore.allAirLinesSorted;
  }

  findAirlines(query) {
    query = query.toLowerCase();
    if (this.airlines) {
      const response = this.airlines.filter((air: AirLine) => air.code.toLowerCase().includes(query) || air.businessName.toLowerCase().includes(query));
      let indexCode = response.findIndex(item => query === item.code.toLowerCase());
      if ( indexCode > -1) {
        let item = response.splice(indexCode, 1);
        response.unshift(...item);
      }

      this.allAirline = [];

      if (response) {
        response.forEach((element) => {
          this.allAirline.push(element);
        });
      } else {
        this.allAirline = this.airlines;
      }
    }
  }

  newCodeDisabled() {
    return this.entries[this.entries.length - 1] && this.entries[this.entries.length - 1].type && this.entries[this.entries.length - 1].value ?
      !this.entries[this.entries.length - 1].type.length && !this.entries[this.entries.length - 1].value.length :
      this.entries.length > 0;
  }

  newCode() {
    if (!this.newCodeDisabled()) {
      let pnrRemarks = this.providerObject && this.providerObject.pnrRemarks ? this.providerObject.pnrRemarks : [];
      PnrRemarksStore.addAirSsrCode({supplier: this.supplier, pnrRemarks: pnrRemarks});
    }
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  deleteCode(index) {
    PnrRemarksStore.removeAirSsrCode({ index: index, supplier: this.supplier });
    if (this.$v.$dirty) {
      this.$v.entries.$touch();
    }
  }

  created() {
    this.airlinesMaper();
  }
}
