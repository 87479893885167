import SystemMarkup from '@/modules/settings/system-markup/SystemMarkup.vue';
import SystemMarkupConfigurations from './system-markup-configurations.route';
import SystemMarkupConfiguration from './system-markup-configuration.route';
import SystemMarkupRuleDefinitionAir from './system-markup-rule-definition-air.route';
import SystemMarkupRuleDefinitionRail from './system-markup-rule-definition-rail.route';
import SystemMarkupRuleDefinitionHotel from './system-markup-rule-definition-hotel.route';

export default {
  path: 'system-markup',
  component: SystemMarkup,
  displayName: 'settings-sidebar.system-markup',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditSystemMarkupConfiguration',
    icon: 'price_change',
    breadCrumb: `System markup configurations' list`,
    parentName: 'system-markup',
  },
  children: [
    SystemMarkupConfigurations,
    SystemMarkupConfiguration,
    SystemMarkupRuleDefinitionAir,
    SystemMarkupRuleDefinitionRail,
    SystemMarkupRuleDefinitionHotel,
  ],
};