import PaymentMethodsConfigurations from '@/modules/settings/payment-methods/PaymentMethodsConfigurations.vue';

export default {
  path: '',
  name: 'payment-methods-configurations',
  component: PaymentMethodsConfigurations,
  displayName: 'Payment methods configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPaymentMethodConfiguration',
    depthCategory: 'payment-methods',
    icon: 'payments',
    depth: 1,
    parentName: 'payment-methods-configurations',
  },
};