export enum AirFranceExchangeSteps {
  ViewExchangeOffer = 'ViewExchangeOffer',
  ViewExchangeOfferAndChooseExtrasTypeToReselect = 'ViewExchangeOfferAndChooseExtrasTypeToReselect',
  ReselectSeats = 'ReselectSeats',
  ReselectAncillaries = 'ReselectAncillaries',
  AcceptExchangeOffer = 'AcceptExchangeOffer'
}

export enum AirFranceExtrasType {
  None = 'None',
  Seats = 'Seats',
  Ancillaries = 'Ancillaries'
}