






























import { Vue, Component } from 'vue-property-decorator';
import { VueContentLoading, VclBulletList } from 'vue-content-loading';

@Component({
  components: {
    VueContentLoading,
    VclBulletList
  },
})
export default class FiltersContentLoading extends Vue {
}

