import Notifications from '@/modules/expense/notifications/Notifications.vue';

export default {
  path: 'notifications',
  name: 'expense-notifications',
  component: Notifications,
  displayName: 'Notifications',
  meta: {
    permission: 'ReadNotifications'
  }
};