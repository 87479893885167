import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ProfileModel, ProfileDataModel } from '@/api/profile/profile.model';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileSyncApi } from '@/api/profile/profile-sync.api';
import { Permission } from '@/const/permission.enum';
import $handleErrors from '@/core/errors/handle-errors.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'profileStore'
})
class ProfileStore extends VuexModule {
  userProfile: ProfileModel | null = null;
  userData: ProfileDataModel | null = null;
  serverErrors: any[] = [];
  profileSyncErrors: any[] = [];
  profileSyncResponse: any[] = [];
  hasAccessToManagerAssignment: boolean = false;
  hasAccessToApprovalWorkflow: boolean = false;
  hasAccessToProfileArrangerCustomFields: boolean = false;

  get profileSyncInfo() {
    if (this.userProfile === null) {
      return null;
    }
    return this.profileSyncResponse
      .find(item => item.profileId === this.userProfile!.id);
  }

  @Mutation
  setUserProfile(payload) {
    this.userProfile = payload;
  }

  @Mutation
  setProfileSyncResponse(value) {
    this.profileSyncResponse = value;
  }

  @Mutation
  setUserData(payload) {
    this.userData = payload;
  }

  @Mutation
  setServerErrors(error) {
    this.serverErrors = $handleErrors(error, true);
  }

  @Mutation
  setSyncErrors(error) {
    this.profileSyncErrors = $handleErrors(error, true);
  }

  @Mutation
  setHasAccessToApprovalWorkflow(value: boolean) {
    this.hasAccessToApprovalWorkflow = value;
  }

  @Mutation
  setHasAccessToProfileArrangerCustomFields(value: boolean) {
    this.hasAccessToProfileArrangerCustomFields = value;
  }

  @Mutation
  setHasAccessToManagerAssignment(value: boolean) {
    this.hasAccessToManagerAssignment = value;
  }


  @Action
  public async loadUserData(id: string) {
    try {
      let response = await ProfileApi.getProfileData(id, Permission.ReadProfilePersonal);
      if (response && response.data) {
        this.setUserData(response.data);
      }
    } catch (error) {
      this.setServerErrors(error);
    }
  }

  @Action
  public async loadUserProfileSyncStatus(id: string) {
    this.setProfileSyncResponse([]);
    try {
      const response = await ProfileSyncApi.getProfileSyncStatus(id);
      this.setProfileSyncResponse(response.data);
    } catch (error) {
      this.setSyncErrors(error);
    }
  }
}

export default getModule(ProfileStore);
