import ExpenseMenuRoute from '@/modules/expense/ExpenseMenuRoute.vue';
import notifications from './notifications.route';
import myMissions from './my-missions.route';
import approvalTasks from './approval-tasks.route';
import expenseList from './expense-list.route';

export default {
  path: '/',
  component: ExpenseMenuRoute,
  children: [
    expenseList,
    notifications,
    myMissions,
    approvalTasks,
  ],
  meta: {
    permission: 'Client',
  },
};
