import moment from 'moment';

import { HotelLocationModel } from '@/api/dictionary/dictionary.model';

export class HotelSearchStateParams {
  to: HotelLocationModel | null = null;
  distance: number | null = null;
  withMeal: boolean = false;
  refundable: boolean = false;
  withNego: boolean = false;
  checkInDate: string = moment()
    .format('YYYY-MM-DD');
  checkOutDate: string = moment()
    .add(1, 'days')
    .format('YYYY-MM-DD');
  packageRates?: boolean = false;

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.to !== undefined) {
      this.to = params.to;
    }
    if (params.distance !== undefined) {
      this.distance = params.distance;
    }
    if (params.withNego !== undefined) {
      this.withNego = params.withNego;
    }
    if (params.withMeal !== undefined) {
      this.withMeal = params.withMeal;
    }
    if (params.refundable !== undefined) {
      this.refundable = params.refundable;
    }
    if (params.checkInDate !== undefined) {
      this.checkInDate = params.checkInDate;
    }
    if (params.checkOutDate !== undefined) {
      this.checkOutDate = params.checkOutDate;
    }
    if (params.packageRates !== undefined) {
      this.packageRates = params.packageRates;
    }
  }
}
