
























































































































import { Vue, Component } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required } from 'vuelidate/lib/validators';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import VirtualCardsStore from './virtual-cards.store';

@Component({})
export default class VirtualCardsConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  selectedProvider = 'AirPlus';
  Form: any = {
    configurationName: '',
    airPlusSecretKey: '',
  };

  @Validation()
  validationObject() {
    return {
      Form: {
        configurationName: {
          required,
          maxLength: maxLength(64),
        },
        airPlusSecretKey: {
          required,
          maxLength: maxLength(127),
        }
      }
    };
  }


  get currentConfiguration() {
    return VirtualCardsStore.currentConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditVirtualCards');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return VirtualCardsStore.errMessages;
  }

  get showErrMessages() {
    return VirtualCardsStore.showError;
  }

  get loading() {
    return VirtualCardsStore.loading;
  }


  goToConfigurationList() {
    this.resetStoreErrors();
    this.$router.push({
      name: 'virtual-cards-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    VirtualCardsStore.clearErrors();
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        rootCompanyId: this.$route.params.id,
        configurationName: this.Form.configurationName,
        airPlusSecretKey: this.Form.airPlusSecretKey,
      };
      await VirtualCardsStore.createConfiguration(request);
      if (!this.errMessages.length) {
        const obj = {
          type: translate('common.success'),
          title: translate('settings-virtual-cards.config-saved'),
          message: translate('settings-virtual-cards.config-saved-message')
        };
        EventHandler.$emit('show-toast', obj);
        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        configurationId: this.$route.params.configurationId,
        configurationName: this.Form.configurationName,
        airPlusSecretKey: this.Form.airPlusSecretKey,
      };
      await VirtualCardsStore.updateConfiguration(request);

      if (!this.errMessages.length) {
        const obj = {
          type: translate('common.success'),
          title: translate('settings-virtual-cards.config-saved'),
          message: translate('settings-virtual-cards.config-saved-message')
        };
        EventHandler.$emit('show-toast', obj);

        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        configurationName: data.configurationName,
        rootCompanyId: data.rootCompanyId,
        airPlusSecretKey: data.airPlusSecretKey,
      };
    } else {
      this.Form = {
        configurationName: '',
        configurationId: '',
        rootCompanyId: data.rootCompanyId,
        airPlusSecretKey: '',
      };
    }
  }

  async created() {
    if (this.inEditMode) {
      await VirtualCardsStore.getConfiguration(this.$route.params.configurationId);
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
    } else {
      this.Form = JSON.parse(JSON.stringify(this.currentConfiguration));
    }
  }
}
