




































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';

import AccountStore from '@/store/account.store';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import EventBus from '@/services/event-handler';
import SearchStore from '@/modules/search/search.store';
import CarSearchStore from './car-search.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import moment from 'moment';
import { LanguageCode, HotelLocationModel } from '@/api/dictionary/dictionary.model';
import { DisplayTimeLabels, serviceClassEnum } from '@/api/home/home.model';
import { CarSearchLocationType } from '@/api/car-engine/car-search.model';
import UiDatePicker from '@/controls/UiDatePicker.vue';
import { translate } from '@/i18n';
import CarSearchExtrasSelect from './CarSearchExtrasSelect.vue';
import DictionaryStore from '@/store/dictionary.store';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    CarSearchExtrasSelect,
  }
})
export default class CarSearch extends Vue {
  fromLocations:  HotelLocationModel[] = [];
  toLocations: HotelLocationModel[] = [];
  isFromLoading: boolean = false;
  isToLoading: boolean = false;
  labelNames: DisplayTimeLabels = {
    start: translate('search-car.departure'),
    end: translate('search-car.return')
  };
  extras: any[] = [];
  shouldFocus: boolean = false;
  returnModel: any | null = null;
  isSwapLocationsActive: boolean = false;
  distanceOptions = [
    {
      value: 1,
      label: '1 km',
    },
    {
      value: 2,
      label: '2 km',
    },
    {
      value: 3,
      label: '3 km',
    },
    {
      value: 4,
      label: '4 km',
    },
    {
      value: 5,
      label: '5 km',
    },
    {
      value: 6,
      label: '6 km',
    },
    {
      value: 7,
      label: '7 km',
    },
    {
      value: 8,
      label: '8 km',
    },
    {
      value: 9,
      label: '9 km',
    },
    {
      value: 10,
      label: '10 km',
    },
  ];

  returnOptions = [
    {
      value: true,
      label: translate('search-car.different-return-location'),
    },
    {
      value: false,
      label: translate('search-car.same-return-location')
    }
  ];

  get classesLocation() {
    return this.carSearchParameters.differentReturnLocation ? 'location-select__roundtrip' : 'location-select__one-way';
  }

  get carSearchParameters() {
    return SearchStore.getCarDefaultState;
  }

  get fromLocationType() {
    return (this.carSearchParameters.pickupLocation && this.carSearchParameters.pickupLocation.type) ? this.carSearchParameters.pickupLocation.type : '';
  }

  get toLocationType() {
    return (this.carSearchParameters.returnLocation && this.carSearchParameters.returnLocation.type) ? this.carSearchParameters.returnLocation.type : '';
  }

  get searchLocationType() {
    return CarSearchStore.searchLocationType;
  }

  get searchDate() {
    return {
      start: moment(this.carSearchParameters.pickupDate).toDate(),
      end: moment(this.carSearchParameters.returnDate).toDate(),
    };
  }

  set searchDate(data: any) {
    if (data && data.start && data.end) {
      this.carSearchParameters.pickupDate = data.start;
      this.carSearchParameters.returnDate = data.end;
    }
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get minReturnDate() {
    return this.carSearchParameters.pickupDate
      ? moment(this.carSearchParameters.pickupDate, 'YYYY-MM-DD')
      : moment();
  }

  get fromLocationsMapped() {
    const type = this.toLocationType;
    if (!type) {
      return this.fromLocations;
    } else {
      if (this.toLocationType === 'City' || this.toLocationType === 'Address') {
         return this.fromLocations.filter(e => {
          return e.type === 'City' || e.type === 'Address';
        });
      } else if (this.toLocationType === 'Airport') {
        return this.fromLocations.filter(e => {
          return e.type === 'Airport';
        });
      } else {
        return this.fromLocations;
      }
    }
  }

  get toLocationsMapped() {
    const type = this.fromLocationType;
    if (!this.fromLocationType) {
      return this.toLocations;
    } else {
      if (this.fromLocationType === 'City' || this.fromLocationType === 'Address') {
         return this.toLocations.filter(e => {
          return e.type === 'City' || e.type === 'Address';
        });
      } else if (this.fromLocationType === 'Airport') {
        return this.toLocations.filter(e => {
          return e.type === 'Airport';
        });
      } else {
        return this.toLocations;
      }
    }
  }

  get extrasAvailable() {
    return DictionaryStore.carExtras;
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get mobileDistance() {
    if (this.carSearchParameters.searchRadius) {
      return this.distanceOptions.find(distance => { return distance.value === this.carSearchParameters.searchRadius; });
    } else {
      return null;
    }
  }

  set mobileDistance(value) {
    if (value && value.value !== this.carSearchParameters.searchRadius) {
      this.carSearchParameters.searchRadius = value.value;
    }
  }


  onExtrasUpdate(extras) {
    this.carSearchParameters.extras = extras;
  }

  getAirportName(option) {
    let name = '';
    if (option.type === 'City') {
      name += option.cityName;
      name += ' (' + translate('search.all-airports') + ')';
    } else if (-1 < ['CityAirport', 'Airport'].indexOf(option.type)) {
      name += option.airportName;
      name += ' (' + option.cityName + ')';
    }
    return name;
  }

  get shouldShowRadius() {
    return this.searchLocationType === CarSearchLocationType.GeoPoint;
  }

  onDateInvalid(value) {
    EventBus.$emit('date-invalid', {service: serviceClassEnum.Car, value});
  }

  displayTime(obj) {
    if (obj) {
      let val = obj.value ? obj.value : obj;
      let hours1 = Math.floor(val / 60);
      let minutes1 = val - (hours1 * 60) + '';
      if (minutes1 === '0') {
        minutes1 = '00';
      }
      return hours1 + '.' + minutes1;
    }
  }

  focusOn(elementId) {
    if (window.innerWidth < 800) {
      return;
    }
    const vueEl = this.$refs[elementId] as Vue;
    if (!vueEl) {
      return;
    }
    const inputValue = vueEl.$el as HTMLInputElement;
    inputValue.getElementsByTagName('input')[0].focus();
  }

  onReceive(data) {
    if (data) {
      const element: Vue = this.$refs.fromSelect as Vue;
      const from: Multiselect = element.$refs.multiselect as Multiselect;
      const searchInput: HTMLInputElement = from.$refs.search as HTMLInputElement;

      if (!searchInput) {
        return;
      }
      return searchInput && searchInput.focus();
    }
  }

  @AnimatedIn()
  animatedIn() {
    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.dateEnter();
    } else if (this.shouldFocus) {
      this.onReceive(true);
    }
  }

  onFocus() {
    this.shouldFocus = true;
  }

  dateEnter() {
    this.$emit('focusSearch');
  }

  isDateValid(date) {
    let timestamp = Date.parse(date);
    return isNaN(timestamp) ? false : true;
  }

  handleEnterPress() {
    setTimeout(() => {
      if (this.isDateValid(this.searchDate.start) && this.isDateValid(this.searchDate.end)) {
        EventBus.$emit('focus-on-search');
      }
    });
  }

  @Watch('carSearchParameters.pickupDate')
  onChangedDeparture(val) {
    if (val) {
      this.carSearchParameters.pickupDate = moment(this.carSearchParameters.pickupDate).format('YYYY-MM-DD');
    }
    if (moment(this.carSearchParameters.pickupDate).isAfter(this.carSearchParameters.returnDate)) {
        this.carSearchParameters.returnDate = moment(this.carSearchParameters.pickupDate).add(1, 'days').format('YYYY-MM-DD');
    }
    if (new Date(val).getTime() < new Date().getTime()) {
      this.carSearchParameters.pickupDate = moment().format('YYYY-MM-DD');
    }
  }

  @Watch('carSearchParameters.returnDate')
  onChangedReturn(val) {
    if (this.carSearchParameters.returnDate) {
      this.carSearchParameters.returnDate = moment(this.carSearchParameters.returnDate).format('YYYY-MM-DD');
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isFromLoading',
  })
  async findFromLocation(query) {
    if (query && query.length > 2) {
      this.isFromLoading = true;
      const response = await DictionaryApi.findHotelToLocation(query, this.languageCode);

      if (response && response.data) {
        this.fromLocations = response.data;
      }

      this.isFromLoading = false;
    } else {
      this.isFromLoading = false;
      this.fromLocations = [];
      if (this.carSearchParameters.pickupLocation) {
        this.fromLocations.push(this.carSearchParameters.pickupLocation);
      }
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isToLoading',
  })
  async findToLocation(query) {
    if (query && query.length > 2) {
      this.isToLoading = true;
      const response = await DictionaryApi.findHotelToLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocations = response.data;
      }

      this.isToLoading = false;
    } else {
      this.isToLoading = false;
      this.toLocations = [];
      if (this.carSearchParameters.returnLocation) {
        this.toLocations.push(this.carSearchParameters.returnLocation);
      }
    }
  }

  prepareFrom() {
    this.fromLocations = [];
    if (this.carSearchParameters.pickupLocation) {
      this.fromLocations.push(this.carSearchParameters.pickupLocation);
    }
  }

  prepareTo() {
    this.toLocations = [];
    if (this.carSearchParameters.returnLocation) {
      this.toLocations.push(this.carSearchParameters.returnLocation);
    }
  }

  swapLocations() {
    this.isSwapLocationsActive = true;
    let from = this.carSearchParameters.pickupLocation;
    this.carSearchParameters.pickupLocation = this.carSearchParameters.returnLocation;
    this.carSearchParameters.returnLocation = from;
    this.prepareTo();
    this.prepareFrom();
    setTimeout(() => {
      this.isSwapLocationsActive = false;
    }, 450);
  }

  setModelValue() {
    this.carSearchParameters.differentReturnLocation = this.returnModel.value;
  }

  @Watch('carSearchParameters.differentReturnLocation', { immediate: true })
  onChangeddifferentReturnLocation(val) {
    if (!val) {
      this.carSearchParameters.returnLocation = null;
    }
    this.returnModel = this.returnOptions.find(returnOption => { return returnOption.value === val; });
  }

  created() {
    EventBus.$on('focus-from', this.onFocus);
  }

  beforeDestroy() {
    EventBus.$off('focus-from', this.onFocus);
  }
}
