import PaymentGatewayConfiguration from '@/modules/settings/payment-gateway/PaymentGatewayConfiguration.vue';

export default {
  path: ':configurationId?/configuration',
  name: 'payment-gateway-configuration',
  component: PaymentGatewayConfiguration,
  displayName: 'Payment gateway Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditPaymentGateways',
    parentName: 'payment-gateway-configurations',
    depthCategory: 'payment-gateway-configurations',
    depth: 2,
  }
};