import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import {
  FareMappingConfigurations,
  FareMappingConfigurationModel,
  FareMapping,
} from '@/api/air-engine/fare-mapping-configuration.model';
import  { FareMappingConfigurationApi } from '@/api/air-engine/fare-mapping-configuration.api';
import AirFaresMappingStore from './air-fare-mapping.store';
import $handleErrors from '@/core/errors/handle-errors.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'faresMapping'
})
class FaresMappingStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: FareMappingConfigurationModel = new FareMappingConfigurationModel();
  configuration: FareMappingConfigurations | null = new FareMappingConfigurations();
  configurationName: string = '';
  configurationId: string = '';
  selectedTab: string = 'Flight';
  fareMappingList: FareMapping[] = [];
  selectedFareMapping: FareMapping | null = null;
  isCopy: boolean = false;
  isFromManage: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get FareMappingList() {
    return this.fareMappingList;
  }

  get Loading() {
    return this.loading;
  }


  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setConfiguration(value) {
    this.configuration = new FareMappingConfigurations(value);
  }

  @Mutation
  setCurrentConfiguration(value) {
    this.currentConfiguration = new FareMappingConfigurationModel(value);
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.configurationName = value;
  }

  @Mutation
  setSelectedTab(value) {
    this.selectedTab = value;
  }

  @Mutation
  setIsFromManage(value) {
    this.isFromManage = value;
  }

  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
  }

  @Mutation
  setFareMappingList(value) {
    this.fareMappingList = value;
  }

  @Mutation
  selectFareMapping(value?) {
    this.selectedFareMapping = value ? new FareMapping(value) : null;
  }

  @Mutation
  setErrMessages(value) {
    this.errMessages = $handleErrors(value, true);
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }


  @Action
  clearData() {
    this.setCurrentConfigurationName('');
    AirFaresMappingStore.clearCurrentConfiguration();
    this.setConfigurationId('');
    this.setConfiguration({});
    AirFaresMappingStore.setFareMappingList([]);
    AirFaresMappingStore.clearErrors();
  }

  @Action
  async getConfiguration(configurationId: string) {
    this.setCustomError(false);
    try {
      this.setLoading(true);
      const result = await FareMappingConfigurationApi.getById(configurationId);
      if (result && result.data) {
        this.setConfiguration(result.data);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages(error);
      }
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async createConfiguration(request) {
    try {
      this.setLoading(true);
      const response = await FareMappingConfigurationApi.create(request);
      if (response && response.data) {
        this.setConfigurationId(response.data.configurationId);
        let errorElements = response.data.result.filter(element => {
          return element.error !== null;
        });
        if (errorElements && errorElements.length) {
          this.setErrMessages([...errorElements.map(elem => { return elem.error; })]);
        }

        await AirFaresMappingStore.getAirFareMappingConfiguration(response.data.configurationId);
      }
    } catch (error) {
      this.setErrMessages(error);
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(FaresMappingStore);