






import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({})
export default class BasketApprovalCurrentLevel extends Vue {
  @Prop() level?: number;
  @Prop() levelCount?: number;
}
