























































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import BasketStore from '@/modules/basket/basket.store';
import SabreAncillary from './SabreAncillary.vue';
import SabreAncillaryFlights from './SabreAncillaryFlights.vue';
import SabreAncillaryPrice from './SabreAncillaryPrice.vue';
import EventBus from '@/services/event-handler';
import BasketItemBusinessErrors from '../BasketItemBusinessErrors.vue';
import { OffersApi } from '@/api/air-engine/offers.api';
import { translate } from '@/i18n';

@Component({
  components: {
    SabreAncillary,
    SabreAncillaryFlights,
    SabreAncillaryPrice,
    BasketItemBusinessErrors,
  },
})
export default class SabreBasketAncillaries extends Vue {
  @Prop() offer!: any;
  selectedTraveller: any | null = null;
  shouldShowWarning: boolean = false;
  errors: any[] = [];
  warningFlights: any[] = [];

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get itemId() {
    if (this.bookingStep < 1) {
      return '';
    }
    return this.wizardSteps[this.bookingStep - 1].tripItemId;
  }

  get travellers() {
    return BasketStore.basketAncillaries.map(item => {
      const profile = BasketStore.basketTravellers.find(t => t.id === item.profileId);

      return {
        ...profile,
        ...item,
      };
    });
  }

  get maxOneAncillary() {
    return BasketStore.maxOneAncillaryPerSegment;
  }

  get maxOneBagAncillary() {
    return BasketStore.maxOneBagAncillaryPerSegment;
  }

  get restrictToSameTypeForNewAncillaries() {
    return BasketStore.restrictToSameTypeForNewAncillaries;
  }

  get minimumOneAncillary() {
    return BasketStore.minimumAncillaryCount === 1;
  }

  get selectedAncillaries() {
    return BasketStore.selectedAncillaries;
  }

  get maxOnAncillaryErrors() {
    if (
      BasketStore.showMaxOneAncillaryError &&
      (BasketStore.hasMaxOneAncillaryError ||
      BasketStore.hasExactOneAncillaryError)
    ) {
      return [{
        message: translate('basket-ancillaries.please-select-one-ancillary-in-segment'),
      }];
    }
    return [];
  }

  get maxOneBagAncillaryErrors() {
    if (
      BasketStore.showMaxOneBagAncillaryError &&
      BasketStore.hasMaxOneBagAncillaryError
    ) {
      return [{
        message: translate('basket-ancillaries.please-select-one-bag-ancillary-in-segment'),
      }];
    }
    return [];
  }

  get restrictToSameTypeForNewAncillariesError() {
    if (
      BasketStore.showRestrictToSameTypeForNewAncillariesError &&
      BasketStore.hasRestrictToSameTypeForNewAncillariesError
    ) {
      return [{
        message: translate('basket-ancillaries.please-select-one-ancillary-type'),
      }];
    }
    return [];
  }

  get selectedAncillariesPrice() {
    return BasketStore.totalAncillariesPrice;
  }

  get businessErrors() {
    return BasketStore.businessErrors;
  }

  get flights() {
    return BasketStore.basketAncillariesFlights;
  }

  get noAncillaries() {
    return !this.selectedTraveller || !this.selectedTraveller.ancillaryOffers || !this.selectedTraveller.ancillaryOffers.length;
  }

  get isEasyJetOffer() {
    return !this.noAncillaries && this.hasEasyJetOffers;
  }
  
  get hasEasyJetOffers() {
    return !!this.selectedTraveller.ancillaryOffers
      .find(ancillary => {
        return ancillary.airlineCode === 'U2';
      });
  }



  userFullName(user) {
    return userFullName(user);
  }

  classesForRow(index) {
    if (!this.selectedTraveller) {
      return '';
    }
    const ancillaries = this.selectedTraveller.ancillaryOffers;
    if (index >= ancillaries.length) {
      return '';
    }

    if (index === ancillaries.length - 1) {
      return 'basket-ancillaries__cell--last-in-group';
    }
    
    const current = ancillaries[index];
    const next = ancillaries[index + 1];

    if (next.commercialName === current.commercialName) {
      return '';
    }
    return 'basket-ancillaries__cell--last-in-group';
  }

  selectTraveller(traveller, index) {
    this.selectedTraveller = traveller;

    const element = (this.$refs.traveller as any[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  async findWarningFlights(marks) {
    const flightNumbers: string[] = [];
    let details: any = null;
    this.warningFlights = [];
    try {
      const response = await OffersApi.getOffer(this.offer.providerReferenceId);

      details = response.data;

      marks.forEach((mark, index) => {
        if (!mark) {
          flightNumbers.push(this.flights[index].flightNumber);
        }
      });

      flightNumbers.forEach(num => {
        const flightLeg = details.legFlights.find(leg => {
          return leg.flights.find(flight => {
            return flight.flightInfo && flight.flightInfo.flightNumber === num;
          });
        });
        if (!flightLeg) {
          return;
        }
        const flight = flightLeg.flights.find(flight => {
          return flight.flightInfo && flight.flightInfo.flightNumber === num;
        });
        if (!flight) {
          return;
        }
        this.warningFlights.push(flight);
      });

      this.shouldShowWarning = true;
    } catch (error) {
      this.errors = this.$handleErrors(error);
      BasketStore.setAncillariesLoadingFailed(true);
    }
  }

  checkMaxOneAncillary(ancillaries) {
    const flights: any[] = [];

    ancillaries.forEach(item => {
      item.flights.forEach(flightId => {
        const existing = flights.find(el => el.flightId === flightId && el.profileId === item.profileId);
        if (!existing) {
          flights.push({
            flightId,
            profileId: item.profileId,
            count: 1,
          });
          return;
        }
        existing.count++;
      });
    });
    const hasError = !!flights.find(item => item.count > 1);
    BasketStore.setHasMaxOneAncillaryError(hasError);
  }

  checkMaxOneBagAncillary(ancillaries) {
    const flights: any[] = [];

    ancillaries.filter(item => item.isBag).forEach(item => {
      item.flights.forEach(flightId => {
        const existing = flights.find(el => el.flightId === flightId && el.profileId === item.profileId);
        if (!existing) {
          flights.push({
            flightId,
            profileId: item.profileId,
            count: 1,
          });
          return;
        }
        existing.count++;
      });
    });
    const hasError = !!flights.find(item => item.count > 1);
    BasketStore.setHasMaxOneBagAncillaryError(hasError);
  }

  checkAncillariesTypesUniqueness(ancillaries) {
    const hasError = !!(ancillaries.some(item => item.isBag && item.isAlreadyBooked === false) 
                      && ancillaries.some(item => !item.isBag && item.isAlreadyBooked === false));

    BasketStore.setHasRestrictToSameTypeForNewAncillariesError(hasError);
  }

  checkExactOneAncillary(ancillaries) {
    const travellersToConsider = this.travellers
      .filter(item => !!item.ancillaryOffers.length);
    const numberOfTravellersToConsider = travellersToConsider.length;
    const flightsToConsider = travellersToConsider
      .reduce((prev, current) => {
        const differentFlights = current.ancillaryOffers
          .reduce((p, c) => {
            const flightIds = [...p];
            c.flights.forEach(flight => {
              if (-1 === flightIds.indexOf(flight)) {
                flightIds.push(flight);
              }
            });
            return flightIds;
          }, prev);

        return differentFlights;
      }, []);
    const numberOfFlightsToConsider = flightsToConsider.length;
    const flights: any[] = [];

    ancillaries.forEach(item => {
      item.flights.forEach(flightId => {
        const existing = flights.find(el => el.flightId === flightId && el.profileId === item.profileId);
        if (!existing) {
          flights.push({
            flightId,
            profileId: item.profileId,
            count: 1,
          });
          return;
        }
        existing.count++;
      });
    });
    const hasError = !!flights.find(item => item.count > 1);
    BasketStore.setHasExactOneAncillaryError(!(!hasError && flights.length === numberOfFlightsToConsider * numberOfTravellersToConsider));
  }

  @Watch('selectedAncillaries', { immediate: true })
  onAncillariesChange(ancillaries) {
    this.checkNumberOfSelectedAncillaries(ancillaries);
    this.checkTypesOfSelectedAncillaries(ancillaries);
  }

  checkTypesOfSelectedAncillaries(ancillaries) {
    if (this.restrictToSameTypeForNewAncillaries) {
      this.checkAncillariesTypesUniqueness(ancillaries);
    }
  }

  checkNumberOfSelectedAncillaries(ancillaries) {
    if (this.maxOneBagAncillary) {
      this.checkMaxOneBagAncillary(ancillaries);
      return;
    }
    if (this.minimumOneAncillary && this.maxOneAncillary) {
      this.checkExactOneAncillary(ancillaries);
      return;
    }
    if (!this.maxOneAncillary || !ancillaries.length) {
      return;
    }

    this.checkMaxOneAncillary(ancillaries);
  }

  @Watch('itemId', { immediate: true })
  onInit() {
    this.selectedTraveller = this.travellers[0];

    let marks = this.flights.map(item => {
      return false;
    });

    this.travellers.forEach(traveller => {
      this.flights.forEach((flight, index) => {
        if (traveller.ancillaryOffers.find(offer => {
          return -1 < offer.flights.indexOf(flight.id);
        })) {
          marks[index] = true;
        }
      });
    });

    if (-1 < marks.findIndex(item => !item)) {
      this.findWarningFlights(marks);
    }

    BasketStore.setHasMaxOneAncillaryError(false);
    BasketStore.setShowMaxOneAncillaryError(false);
    BasketStore.setHasMaxOneBagAncillaryError(false);
    BasketStore.setShowMaxOneBagAncillaryError(false);
    BasketStore.setHasRestrictToSameTypeForNewAncillariesError(false);
    BasketStore.setShowRestrictToSameTypeForNewAncillariesError(false);
  }

}

