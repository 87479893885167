









































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';

import { router } from '@/router';
import LayoutStore from '@/modules/layout/layout.store';
import AccountStore from '@/store/account.store';
import BasketStore from './basket.store';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import BasketStepsCheckboxes from './BasketStepsCheckboxes.vue';
import BasketStepsBreadcrumb from './BasketStepsBreadcrumb.vue';
import TripDescriptionText from './TripDescriptionText.vue';
import { translate } from '@/i18n';
import EventBus from '@/services/event-handler';



const expandIndicatorsMinHeight = 120;
  
@Component({
  components: {
    BasketStepsCheckboxes,
    BasketStepsBreadcrumb,
    TripDescriptionText,
  },
})
export default class BasketAside extends Vue {
  fixedAside: boolean = false;
  asideWidth: number = 0;
  asideHeight: number = 0;
  shouldShowExpandLess: boolean = false;
  shouldShowExpandMore: boolean = false;
  shouldShowAnyExpand: boolean = true;
  expandIntervalId: number = -1;
  scrollUpInterval: number = -1;
  scrollDownInterval: number = -1;
  scrollStep: number = 17;
  dateTimeInterval: number = -1;
  currentDateTime: any = moment();
  showDesynchronizeConfirmModal: boolean = false;
  desyncErrors: any[] = [];
  desyncInProgress: boolean = false;

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get isExtrasProviderError() {
    return this.pendingActionErrorTripItem === 'EXTRAS_PROVIDER_ERROR';
  }

  get loadingItems() {
    return BasketStore.loadingItems;
  }

  get loading() {
    return BasketStore.loading;
  }

  get bookingStepLoading() {
    return BasketStore.bookingStepLoading;
  }

  get bookingStep() {
    return BasketStore.bookingStep;
  }

  get showRefreshButton() {
    return BasketStore.showRefreshButton;
  }

  get pendingActions() {
    return BasketStore.status && BasketStore.status.pendingActions || [];
  }

  get itemPendingActions() {
    return BasketStore.status && BasketStore.status.items || [];
  }

  get basketHoldDeadline() {
    return BasketStore.basketHoldDeadline;
  }

  get basketApprovalDeadline() {
    return BasketStore.basketApprovalDeadline;
  }

  get basketExpirationDate() {
    return BasketStore.basketExpirationDate;
  }

  get basketStatus() {
    return BasketStore.status && BasketStore.status.status ? BasketStore.status.status : '';
  }

  get isDraft() {
    return this.basketStatus && this.basketStatus === 'Draft';
  }

  get isHeldStatus() {
    return this.basketStatus && this.basketStatus === 'Held';
  }

  get isInProgress() {
    return BasketStore.status ? BasketStore.status.isInProgress : false;
  }

  get basketExpirationTime() {
    return BasketStore.basketExpirationTime;
  }

  get clientTimezoneOffset() {
    return BasketStore.clientTimezoneOffset;
  }

  get isBasketExpired() {
    if (this.basketExpirationDate) {
      return this.currentDateTime.isAfter(this.basketExpirationDate);
    }
    return false;
  }

  get hasSaveTravelersButton() {
    return BasketStore.basketSkipTravelersMode && BasketStore.basketTravelersUnsaved;
  }

  get introState() {
    return LayoutStore.slots.intro.state;
  }

  get user() {
    return AccountStore.current;
  }

  get btnClasses() {
    return {
      'mobile-fr-btn': this.user!.profile.displayLanguage === 'fr',
      'mobile-it-btn': this.user!.profile.displayLanguage === 'it',
    };
  }

  get fixButtonForIt() {
    return -1 < [
      'TICKETING_FAILED',
      'BOOKING_ERROR',
    ].indexOf(this.pendingActionCodeItem) && this.user && this.user.profile && this.user.profile.displayLanguage === 'it';
  }

  get holdButtonsClasses() {
    return {
      'mobile-wt-100': this.pendingActionErrorTripItem  === 'BOOK_TRIP_ITEM_ERROR' || this.fixButtonForIt,
      'text-wrap': this.user && this.user.profile.displayLanguage === 'pl',
    };
  }

  get checkTravelConsent() {
    return BasketStore.checkTravelConsent;
  }

  get checkModifyTravellerDetails() {
    return BasketStore.checkModifyTravellerDetails;
  }

  set checkModifyTravellerDetails(value) {
    BasketStore.setCheckModifyTravellerDetails(value);
  }

  get shouldShowModifyTravellerDetailsConfirm() {
    if (
      this.wizardSteps &&
      this.wizardSteps.length &&
      this.bookingStep > 0 &&
      this.wizardSteps[this.bookingStep - 1]
    ) {
      return this.wizardSteps[this.bookingStep - 1].code === 'MODIFY_TRAVELLERS_DETAILS' &&
        BasketStore.bookingStepTripItem &&
        BasketStore.bookingStepTripItem.supplier === 'Sabre';
    }
    return false;
  }

  get shouldShowModifyTravellerDetailsTooltip() {
    if (this.wizardSteps && this.wizardSteps.length && this.bookingStep > 0) {
      return this.wizardSteps[this.bookingStep - 1].code === 'MODIFY_TRAVELLERS_DETAILS' &&
        BasketStore.bookingStepTripItem &&
        (BasketStore.bookingStepTripItem.supplier === 'Sabre' || BasketStore.bookingStepTripItem.supplier === 'AirFranceKlm');
    }
    return false;
  }

  get notAcceptedBookingConditions() {
    return !this.canBookWithoutConsent && this.isDraft && !this.checkTravelConsent && this.showBookWithoutConsent;
  }

  get wizardSteps() {
    return BasketStore.wizardSteps;
  }

  get showBookWithoutConsent() {
    if (this.wizardSteps && this.wizardSteps.length && this.bookingStep > 0) {
      return this.wizardSteps[this.bookingStep - 1].code === 'DO_TRIP';
    }
    return false;
  }

  get isLastApprovalLevel() {
    return this.basketApproverData && this.basketApproverData.level === this.basketApproverData.levelCount;
  }

  get shouldShowDesynchronizeLink() {
    return AccountStore.HasPermission('CanMarkAsDesynchronized');
  }

  tooltipContinue() {
    if (this.bookingStep > 0 && this.canUserBookTrip && this.hasStartBookingButton && !this.isLastStep && !this.showRetrySection) {
      return `${translate('basket.if-completed-click')} <strong>${translate('basket.continue-next')}</strong> ${translate('basket.next-step')}`;
    } else if (this.canUserBookTrip && this.hasStartBookingButton && this.isLastStep && !this.showRetrySection) {
      return `${translate('basket.if-click')} <strong>${translate('basket.continue-next')}</strong> ${translate('basket.start-booking')}`;
    }
  }

  tooltipBookExtras() {
    if (this.shouldShowModifyTravellerDetailsTooltip) {
      return `${translate('basket.if-click')} <strong>${translate('basket.continue-next')}</strong> ${translate('basket.modification-process')}`;
    } else {
      return `${translate('basket.if-click')} <strong>${translate('basket.continue-next')}</strong> ${translate('basket.start-booking')}`;
    }
  }

  tooltipGoBack() {
    return `${translate('basket.not-done-click')} <strong>${translate('basket.go-back')}</strong> ${translate('basket.complete-trip')}`;
  }

  tooltipAddBooking() {
    return `${translate('basket.add-another-booking')} <strong>${translate('basket.add-booking')}</strong>.`;
  }

  get hasNoSectionsToModify() {
    return BasketStore.modifyTravellersDetailsNoSectionToModify &&
      BasketStore.bookingStepTripItem &&
      BasketStore.bookingStepTripItem.supplier === 'AirFranceKlm';
  }

  get shouldDisableContinue() {
    if (BasketStore.isLastStep && !BasketStore.finalBookOption) {
      return true;
    } else if (BasketStore.wizardSteps && BasketStore.wizardSteps.length) {
      const currentStep = BasketStore.wizardSteps[BasketStore.bookingStep - 1];
      const conditions: any[] = [
        {
          stepCode: 'TRAVELLERS_CHOICE',
          value: () => BasketStore.guestBasketTravellersError || BasketStore.bookingStepLoading,
        },
        {
          stepCode: 'AIR_SABRE_ANCILLARIES',
          value: () => (BasketStore.showMaxOneAncillaryError && (BasketStore.hasMaxOneAncillaryError || BasketStore.hasExactOneAncillaryError))
            || (BasketStore.showMaxOneBagAncillaryError && BasketStore.hasMaxOneBagAncillaryError)
            || (BasketStore.showRestrictToSameTypeForNewAncillariesError && BasketStore.hasRestrictToSameTypeForNewAncillariesError),
        },
        {
          stepCode: 'AIR_SABRE_SEAT_SELECTION',
          value: () => BasketStore.seatMustBeSelectedForAllOrNoTravellersError || (!BasketStore.canContinueOnSeatsStep && BasketStore.basketSeatsMapError),
        },
        {
          stepCode: 'TRAVELLER_DATA_CHECK',
          value: () => BasketStore.guestBasketTravellersDataLoading || BasketStore.travellersDateOfBirthCheckFailed ||
            BasketStore.bookingStepLoading || BasketStore.customerCodeSectionError,
        },
        {
          stepCode: 'RAIL_SNCF_SEAT_SELECTION',
          value: () => BasketStore.bookingStepLoading || BasketStore.basketRailSncfSeatPreferencesError,
        },
        {
          stepCode: 'RAIL_SNCF_ANCILLARIES_SELECTION',
          value: () => BasketStore.bookingStepLoading || BasketStore.basketRailSncfAncillariesError,
        },
        {
          stepCode: 'PAYMENT_FORM',
          value: () => BasketStore.bookingStepLoading || BasketStore.basketPaymentUnsupported ||
            BasketStore.loadingPaymentMethods || BasketStore.loadingPaymentMethodsFailed,
        },
        {
          stepCode: 'MODIFY_TRAVELLERS_DETAILS',
          value: () => BasketStore.isModifyTravellersDetailsLoading ||
            BasketStore.modifyTravellersDetailsFormInvalid ||
            this.hasNoSectionsToModify ||
            (BasketStore.modifyTravellersDetailsLoyaltiesInvalid && BasketStore.modifyTravellersDetailsLoyaltiesTouched) ||
            (this.shouldShowModifyTravellerDetailsConfirm && !this.checkModifyTravellerDetails),
        },
        {
          stepCode: 'DO_TRIP',
          value: () => BasketStore.bookingStepLoading ||
            ((BasketStore.basketApproverError ||
            BasketStore.hasMissingSelectedApprover) && BasketStore.displayApproverEmpty) || BasketStore.hasDelayTicketingDeadlineError ||
            BasketStore.displayObligatoryConfirmationEmpty,
        },
        {
          stepCode: 'REVIEW_TP_COMPLIANCE',
          value: () => BasketStore.shouldDisableContinueOnTravelPolicyCompliance,
        },
      ];

      if (currentStep) {
        const currentCondition = conditions.find(condition => condition.stepCode === currentStep.code);

        if (currentCondition) {
          return currentCondition.value();
        }
      }
    }

    return false;
  }

  get hasPendingActions() {
    return this.pendingActions.length || (
      0 < this.pendingActions.length &&
      !!this.pendingActions.find(action => action.code === 'APPROVAL_WORKFLOW_ACTION') &&
      !this.canValidateTrips
    );
  }

  get itemHasProviderPendingAction() {
    let value: boolean = false;
    if (this.itemPendingActions && this.itemPendingActions.length) {
      this.itemPendingActions.forEach(item => {
        if (
          item && item.pendingActions &&
          !!item.pendingActions.find(action => !![
            'LHG_UNCONFIRMED_STATUS',
            'SABRE_UNCONFIRMED_STATUS',
            'AMADEUS_UNCONFIRMED_STATUS',
          ].find(item => item === action.code))
        ) {
          value = true;
        }
      });
    }
    return value;
  }

  get itemHasTravellerWithoutRequiredAddressAtDestinationPendingAction() {
    if (!this.itemPendingActions || this.itemPendingActions.length === 0) {
      return false;
    }
    return this.itemPendingActions.some((item) => {
      if (item && item.pendingActions) {
        return item.pendingActions.some((action) => 
          action.code === 'TRAVELLERS_WITHOUT_REQUIRED_ADDRESS_AT_DESTINATION'
        );
      }
      return false;
    });
  }

  get hasStartBookingButton() {
    return !!this.pendingActions.find(obj => obj.code ===  'START_BOOKING_WIZARD');
  }

  get hasAddBookingButton() {
    return !this.isInWizard &&
      !!this.pendingActions.find(obj => obj.code ===  'ADD_BOOKING') &&
      this.$route.name !== 'basketAddSegment';
  }

  get isLastStep() {
    return BasketStore.isLastStep;
  }

  get basketIsCancelled() {
    return BasketStore.basketIsCancelled;
  }

  get hasApprovalWorkflowAction() {
    return BasketStore.hasApprovalWorkflowAction;
  }

  get hasCustomFields() {
    return BasketStore.hasCustomFields;
  }

  get hasCustomFieldsLoaded() {
    return BasketStore.hasCustomFieldsLoaded;
  }

  get hasHoldActions() {
    return !this.isInWizard && !!this.pendingActions.find(obj => obj.code ===  'HOLD');
  }

  get hasDelayedTicketingActions() {
    return !this.isInWizard && !!this.pendingActions.find(obj => obj.code ===  'DELAYED_TICKETING');
  }

  get hasDesynchronizedBooking() {
    return BasketStore.hasDesynchronizedBooking;
  }

  get allBookingsConfirmed() {
    return BasketStore.allBookingsConfirmed;
  }

  get bookingInProgress() {
    return false;
  }

  get approvingLoading() {
    return false;
  }

  get cancellingLoading() {
    return false;
  }

  get showRetrySection() {
    return BasketStore.showRetrySection;
  }

  get isRetryCancelSection() {
    return BasketStore.isRetryCancel;
  }

  get pendingActionErrorTripItem() {
    return BasketStore.pendingActionErrorTripItem;
  }

  get pendingActionCodeItem() {
    return BasketStore.pendingActionCodeItem;
  }

  get lastSegmentName() {
    return BasketStore.lastSegmentName;
  }

  get isSkipping() {
    return BasketStore.skip;
  }

  get isRetry() {
    return BasketStore.retry;
  }

  get isCustomerCodeSectionLoadingError() {
    return BasketStore.customerCodeSectionLoadingError;
  }

  get retryButtonDisabled() {
    return BasketStore.retryButtonDisabled;
  }

  get canApproveTrip() {
    return BasketStore.canApproveTrip;
  }
  
  get scrollTop() {
    return LayoutStore.scrollTop;
  }

  get asideHidden() {
    return LayoutStore.asideHidden;
  }

  get isPostTicketingSalesWizard() {
    return BasketStore.postTicketingExtrasBooking;
  }

  get wrapperStyles() {
    if (!this.fixedAside) {
      return {};
    }

    return {
      position: 'fixed',
      top: this.topPosition + 20 + 'px',
      width: this.asideWidth + 'px',
    };
  }

  get priceChanges() {
    return BasketStore.priceChanges;
  }

  get topPosition() {
    return 80;
  }

  get canUserBookTrip() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canUserAddOfferToTrip() {
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get canChangeApprover() {
    return AccountStore.HasPermission('CanChangeApprover');
  }

  get canBookWithoutConsent() {
    return AccountStore.HasPermission('CanBookWithoutConsent');
  }

  get canValidateTrips() {
    return AccountStore.HasPermission('ValidateTrips');
  }

  get dummyStyles() {
    if (!this.fixedAside) {
      return {};
    }
    return {
      height: this.asideHeight + 'px',
    };
  }

  get introWrapper() {
    return LayoutStore.slots.intro.wrapper;
  }

  get seleniumId() {
    if (this.canUserBookTrip && this.hasStartBookingButton && this.isLastStep && !this.showRetrySection) {
      return '_last';
    } else {
      return '';
    }
  }

  get basketItemsMapped() {
    return BasketStore.basketItemsMapped;
  }

  get isBasketDraft() {
    return BasketStore.status && BasketStore.status.status ? !BasketStore.status.isInProgress && BasketStore.status.status === 'Draft' : false;
  }

  get basketApproverData() {
    return BasketStore.basketApproverData;
  }

  get lastBasketItemId() {
    return BasketStore.lastBasketItemId;
  }

  get lastBasketItem() {
    return BasketStore.lastBasketItem;
  }

  get isApprovalWorkFlow() {
    return this.basketApproverData && this.basketApproverData.level !== null && this.basketApproverData.levelCount !== null;
  }


  triggerPosition() {
    return this.introHeight() + 10;
  }

  introHeight() {
    if (!this.introWrapper) {
      return 0;
    }
    const rect = (this.introWrapper as Element).getBoundingClientRect();

    return rect.height;
  }

  openDesynchronizeModal() {
    this.showDesynchronizeConfirmModal = true;
  }

  confirmHeld() {
    BasketStore.setBasketApprovalDecision('confirm');
    BasketStore.setBasketApprovalAction('Hold');
    BasketStore.setShowApprovalConfirmationPopup(true);
    this.$nextTick(() => {
      this.onResize();
    });
  }

  confirmDelayedTicketing() {
    BasketStore.setBasketApprovalDecision('confirm');
    BasketStore.setBasketApprovalAction('DelayedTicketing');
    BasketStore.setShowApprovalConfirmationPopup(true);
    this.$nextTick(() => {
      this.onResize();
    });
  }

  cancel() {
    BasketStore.setBasketApprovalDecision('cancel');
    BasketStore.setBasketApprovalAction('Hold');
    BasketStore.setShowApprovalConfirmationPopup(true);
    this.$nextTick(() => {
      this.onResize();
    });
  }

  cancelDelayedTicketing() {
    BasketStore.setBasketApprovalDecision('cancel');
    BasketStore.setBasketApprovalAction('DelayedTicketing');
    BasketStore.setShowApprovalConfirmationPopup(true);
    this.$nextTick(() => {
      this.onResize();
    });
  }

  continueBookCapture() {
    if (!this.shouldDisableContinue && !this.notAcceptedBookingConditions) {
      return;
    }
    if (window.innerWidth >= 800) {
      return;
    }
    this.$nextTick(() => {
      this.onResize();
    });
  }

  continueBook() {
    BasketStore.continue();
    this.$nextTick(() => {
      this.onResize();
    });
  }

  goBack() {
    BasketStore.goBack();
    this.$nextTick(() => {
      this.onResize();
    });
  }

  async acceptApproval() {
    if (this.asideHidden) {
      return;
    }

    if (!this.hasCustomFieldsLoaded) {
      return;
    }

    if (this.hasCustomFields) {
      BasketStore.clearCustomFieldsErrors();
      EventBus.$emit('assign-custom-fields');

      if (
        !BasketStore.customFieldsResponded ||
        BasketStore.customFieldsLoading ||
        BasketStore.customFieldsErrors.length
      ) {
        EventBus.$emit('switch-to-custom-fields');
        return;
      }

      await BasketStore.saveCustomFields();
    }
    BasketStore.setBasketApprovalDecision('confirm');
    BasketStore.setBasketApprovalAction('Approval');
    BasketStore.setShowApprovalConfirmationPopup(true);
  }

  rejectApproval() {
    if (this.asideHidden) {
      return;
    }
    BasketStore.setBasketApprovalDecision('cancel');
    BasketStore.setBasketApprovalAction('Approval');
    BasketStore.setShowApprovalConfirmationPopup(true);
  }

  changeApproval() {
    if (this.asideHidden) {
      return;
    }
    BasketStore.setShowChangeApproverPopup(true);
  }

  async desynchronizeItem() {
    this.desyncErrors = [];
    this.desyncInProgress = true;
    try {
      await BasketItemApi.desynchronizeItem(this.lastBasketItemId);

      this.showDesynchronizeConfirmModal = false;
      BasketStore.setShowRetrySection(false);
      BasketStore.processBasket();
    } catch (error) {
      this.desyncErrors = this.$handleErrors(error, true);
    } finally {
      this.desyncInProgress = false;
    }
  }

  async addBooking() {
    setTimeout(() => {
      EventBus.$emit('focus-from', true);
      this.onResize();
    }, 500);

    router.push({
      name: 'basketAddSegment',
      params: router.currentRoute.params,
    });
  }

  skipBooking() {
    BasketStore.skipBooking();
  }

  retryBooking() {
    BasketStore.retryBooking();
  }

  dateTime(date) {
    return moment.parseZone(date).format('Do MMMM YYYY, HH:mm') + ' (GMT ' + moment.parseZone(date).format('Z') + ')';
  }

  bookExtras() {
    BasketStore.continue();
  }

  basketActionTooltip(actionType) {
    if (actionType === 'desynchronize') {
      return [
        translate('basket.desynchronize-tooltip1'),
        translate('basket.desynchronize-tooltip2'),
      ].join('<br/><br/>');
    }

    return '';
  }

  onResize() {
    const perfectScrollbar = this.$refs.perfectScrollbar as any;
    if (!perfectScrollbar) {
      return;
    }
    const container = perfectScrollbar.$refs.container;
    const inner = this.$refs.container as HTMLElement;
    const above = this.$refs.above_container as HTMLElement;
    const aboveHeight = above.offsetHeight;

    if (window.innerWidth <= 1024) {
      container.style.height = inner.clientHeight + 'px';
      if (inner.clientHeight < expandIndicatorsMinHeight) {
        this.shouldShowAnyExpand = false;
      } else {
        this.shouldShowAnyExpand = true;
      }
      return;
    }

    let newHeight = 0;
    if (this.fixedAside) {
      newHeight = Math.max(0, (window.innerHeight - 92 - 20 - aboveHeight));
      container.style.height = newHeight + 'px';
    } else {
      const introHeight = (LayoutStore.slots.intro.wrapper as HTMLElement).offsetHeight;
      const subintroHeight = (LayoutStore.slots.subintro.wrapper as HTMLElement).offsetHeight;

      newHeight = Math.max(0, (window.innerHeight - 92 - 30 + this.scrollTop - introHeight - subintroHeight - aboveHeight));
      container.style.height = newHeight + 'px';
    }
      
    if (newHeight < expandIndicatorsMinHeight) {
      this.shouldShowAnyExpand = false;
    } else {
      this.shouldShowAnyExpand = true;
    }
  }

  checkExpandLessMore() {
    if (this.isBasketExpired) {
      return;
    }
    const container = (this.$refs.perfectScrollbar as any).$refs.container as HTMLElement;
    const inner = this.$refs.container as HTMLElement;

    if (inner.clientHeight > container.clientHeight) {
      if (container.scrollTop > 10) {
        this.shouldShowExpandLess = true;
      } else {
        this.shouldShowExpandLess = false;
      }

      if (container.scrollTop < container.scrollHeight - container.clientHeight - 10) {
        this.shouldShowExpandMore = true;
      } else {
        this.shouldShowExpandMore = false;
      }
    } else {
      this.shouldShowExpandLess = false;
      this.shouldShowExpandMore = false;
    }
  }
  
  startScrollUp($event) {
    $event.preventDefault();
    clearInterval(this.scrollUpInterval);
    this.scrollUpInterval = setInterval(this.scrollUp, 30);
  }

  stopScrollUp() {
    clearInterval(this.scrollUpInterval);
  }

  startScrollDown($event) {
    $event.preventDefault();
    clearInterval(this.scrollDownInterval);
    this.scrollDownInterval = setInterval(this.scrollDown, 30);
  }

  stopScrollDown() {
    clearInterval(this.scrollDownInterval);
  }

  scrollNow(value) {
    const containerVue = this.$refs.perfectScrollbar as Vue;
    const container = containerVue.$refs.container as HTMLDivElement;
    const destY = container.scrollTop + value;

    if (container.scrollTo) {
      container.scrollTo(0, destY);
    } else {
      container.scrollTop = destY;
    }
  }

  scrollUp() {
    this.scrollNow(-this.scrollStep);
  }

  scrollDown() {
    this.scrollNow(this.scrollStep);
  }

  retryTripBooking() {
    BasketStore.sendTripItemErrorAnswer('StartRebooking');
  }

  skipTripItem() {
    BasketStore.sendTripItemErrorAnswer('SkipBooking');
  }

  skipWholeTrip() {
    BasketStore.sendTripItemErrorAnswer('SkipWholeTrip');
  }

  skipCancel() {
    BasketStore.sendTripItemErrorAnswer('SkipCancel');
  }

  retryCancel() {
    BasketStore.sendTripItemErrorAnswer('RetryCancel');
  }

  confirmExtrasBookingError() {
    BasketStore.sendTripItemErrorAnswer('ConfirmExtrasBookingError');
  }

  refreshPage() {
    location.reload();
  }

  reloadStep() {
    EventBus.$emit('reload-customer-codes');
  }

  refreshDateTime() {
    this.currentDateTime = moment();
  }

  calculateAsideWidth() {
    if (!this.$refs.dummy) {
      return;
    }
    const rect = (this.$refs.dummy as Element).getBoundingClientRect();
    this.asideWidth = rect.width;
  }

  @Watch('shouldShowExpandLess')
  onExpandLessChange(value) {
    if (!value) {
      this.stopScrollUp();
    }
  }

  @Watch('shouldShowExpandMore')
  onExpandMoreChange(value) {
    if (!value) {
      this.stopScrollDown();
    }
  }

  @Watch('scrollTop')
  onScroll(scrollTop) {
    if (!this.fixedAside && scrollTop > this.triggerPosition()) {
      this.calculateAsideWidth();
    }
    if (scrollTop > this.triggerPosition() && window.innerWidth <= 1024) {
      this.fixedAside = false;
      return;
    }
    if (this.$refs.wrapper) {
      const wrapperRect = (this.$refs.wrapper as Element).getBoundingClientRect();
      this.asideHeight = wrapperRect.height;
    }
    this.fixedAside = scrollTop > this.triggerPosition();
    this.onResize();
  }

  @Watch('introState')
  onIntroSwapping() {
    setTimeout(() => {
      this.onScroll(LayoutStore.scrollTop);
    }, 100);
  }

  @Watch('bookingStep')
  onBookingStepChange(value) {
    if (value !== 0) {
      return;
    }

    if (!this.asideHidden && !this.showRetrySection && !this.hasPendingActions) {
      LayoutStore.toggleAside(false);
    }
  }

  @Watch('loading')
  async onLoading(value) {
    if (!value) {
      const customActions = [
        {
          condition: () => {
            return this.canUserBookTrip && this.showRetrySection;
          },
          action: async () => {
            LayoutStore.toggleAside(!value);
            this.onResize();
            return true;
          },
        },
        {
          condition: () => {
            return 0 < this.pendingActions.length &&
              !!this.pendingActions.find(action => action.code === 'APPROVAL_WORKFLOW_ACTION');
          },
          action: async () => {
            if (this.basketIsCancelled) {
              return true;
            }
            await BasketStore.canApprove();
            
            if (!this.canApproveTrip && !this.canChangeApprover) {
              return true;
            }
          },
        },
      ];
      const simpleReturnActions = [
        {
          condition: () => {
            return (this.canUserBookTrip && (0 === this.pendingActions.length)) ||
              (this.pendingActions.length && !!this.pendingActions.find(item => item.code === 'PAYMENT_GATEWAY_SUMMARY'));
          },
        },
        {
          condition: () => {
            return !this.canUserBookTrip && !this.canUserAddOfferToTrip;
          },
        },
        {
          condition: () => {
            return 0 < this.pendingActions.length &&
              !!this.pendingActions.find(action => action.code === 'WORKFLOW_ERROR') &&
              !this.isInProgress;
          },
        },
        {
          condition: () => {
            return this.itemHasProviderPendingAction && this.isHeldStatus;
          },
        },
        {
          condition: () => {
            return !this.isDraft && !this.$hasAccess('CanBookTrips');
          },
        },
      ];

      const c = customActions.find(a => !!a.condition());

      if (!!c) {
        const result = await c.action();

        if (result) {
          return;
        }
      } else if (simpleReturnActions.find(a => !!a.condition())) {
        return;
      }
    }
    LayoutStore.toggleAside(!value);
    this.onResize();
  }
  
  @Watch('isBasketExpired')
  onExpirationChange(value, oldVal) {
    if (value && !oldVal && this.isBasketDraft && this.$route.name === 'basket') {
      BasketStore.setBookingStep(0);
      setTimeout(() => {
        router.replace({
          name: 'basket-expired',
          params: this.$route.params,
        });
      });
    }
  }

  mounted() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.expandIntervalId = setInterval(this.checkExpandLessMore, 100);
    this.dateTimeInterval = setInterval(this.refreshDateTime, 1000);
  }

  beforeDestroy() {
    clearInterval(this.expandIntervalId);
    clearInterval(this.dateTimeInterval);
    window.removeEventListener('resize', this.onResize);
  }
}

