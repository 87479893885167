import Contacts from '@/modules/profile/contacts/Contacts.vue';
import { translate } from '@/i18n';

export default {
  path: 'contacts',
  name: 'contacts',
  component: Contacts,
  displayName: 'profile-contacts.contacts',
  meta: {
    permission: 'ReadProfileContacts',
    icon: 'contact_mail',
  }
};