






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from './layout.store';
import {
  CSS_TRANSITION_END_EVENTS,
  COMMENT_NAME,
  SHOULD_ANIMATE_ON_START,
} from '@/modules/layout/layout.const';
import { SlotState } from './layout.model';
import EventBus from '@/services/event-handler';

@Component({})
export default class BackgroundTransition extends Vue {
  transitionViewName: string = 'background';

  get animating(): boolean {
    return layoutStore.slots.background.transitioning;
  }

  get transition() {
    return layoutStore.slots.background.transition;
  }

  get height(): number {
    return layoutStore.slots.background.meta.height;
  }

  get top() {
    return layoutStore.slots.background.meta.top;
  }

  get classes() {
    return {
      'active': this.animating,
      'toggled': !layoutStore.slots.background.meta.empty,
    };
  }

  get styles() {
    return {
      top: this.top + 'px',
      height: this.height + 'px',
      'min-height': this.height + 'px'
    };
  }

  async mounted() {
    EventBus.$on('realign-background', this.updateDimensions);
    layoutStore.setWrapper({
      slot: 'background',
      wrapper: this.$refs.wrapper as HTMLElement,
    });
    this.updateDimensions();
  }

  beforeDestroy() {
    EventBus.$off('realign-background', this.updateDimensions);
  }

  entered() {
    layoutStore.animationFinished({
      slot: 'background'
    });
  }

  entering() {
    this.updateDimensions();
  }

  updateDimensions() {
    layoutStore.updateDimensions({
      slot: 'background',
    });
  }
}

