import BasicExtension from '@/modules/settings/expense-configuration/BasicExtension.vue';

export default {
  path: ':configurationId?/basic-extension/:itemId?/:expenseExtensionId?/:isCopy?',
  name: 'basic-extension',
  component: BasicExtension,
  displayName: 'Basic expense model',
  meta: {
    breadCrumb: 'Expense definition',
    introClass: 'intro-settings',
    permission: 'ReadExpensePolicyDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};