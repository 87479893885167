import AirSearchShortParams from '@/modules/search/air/AirSearchShortParams.vue';
import AirResultDetailsView from '@/modules/search/air/AirResultDetailsView.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/air-results-offer-details-view/:searchId/:recomendationId',
  name: 'airResultDetailsView',
  components: {
    default: AirResultDetailsView,
    intro: AirSearchShortParams,
  },
  meta: {
    introClass: 'intro-air-results',
    permission: 'SearchOffers',
    mobile: true,
    preserveSidebar: {
      airDetails: true,
    },
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        airDetails: AnimationName.DOWN,
      },
      subintro: {
        air: AnimationName.UP,
      },
    }
  }
};
