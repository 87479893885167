























import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator';

const eventName = 'input';
const syncName = 'update:page';

@Component({})
export default class UiPerPage extends Vue {
  @Model(eventName) modelValue!: number;
  @Prop() options!: any[];

  @Emit(eventName)
  updateValue(value) {
    this[syncName]();
    return value;
  }

  @Emit()
  [syncName]() {
    return 1;
  }
}
