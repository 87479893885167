





















import { Vue, Prop, Component } from 'vue-property-decorator';
import BasketApprovalCurrentLevel from './BasketApprovalCurrentLevel.vue';

@Component({
  components: {
    BasketApprovalCurrentLevel
  }
})
export default class BasketApprovalLevelProfileDetails extends Vue {
  @Prop() userFullName!: string;
  @Prop() canViewProfile!: boolean;
  @Prop() approverProfileLink!: string;
  @Prop() approverData!: any;
  @Prop() level!: number;
  @Prop() levelCount!: number;
}
