import Billing from '@/modules/settings/billing/Billing.vue';
import BillingConfigurations from './pnr-remarks-configurations.route';
import BillingConfiguration from './pnr-remarks-configuration.route';
import { translate } from '@/i18n';

export default {
  path: 'pnr-remarks',
  component: Billing,
  displayName: 'settings-sidebar.pnr-remarks-configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPnrRemarks',
    breadCrumb: 'PNR remarks configuration',
    parentName: 'pnr-remarks-configurations',
    icon: 'subject',
  },
  children: [
    BillingConfigurations,
    BillingConfiguration,
  ],
};