



































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import { router } from '@/router';
import SearchStore from '@/modules/search/search.store';
import HotelSearchStore from './hotel-search.store';
import {
  PropertySearchResult,
  AccommodationOfferSearchResult,
  Offer,
} from '@/api/accommodation-engine/accommodation-search.model';
import HotelOfferConditions from './HotelOfferConditions.vue';
import HotelDetailsSection from './HotelDetailsSection.vue';
import { translate } from '@/i18n';
import BasketStore from '@/modules/basket/basket.store';
import { VclCode, VueContentLoading } from 'vue-content-loading';
import EventBus from '@/services/event-handler';

const amenitiesMap = {
  ACCESSIBLE: 'accessible',
  GREEN_AREAS: 'eco',
  FREE_WIFI: 'wifi',
  RESTAURANTS: 'local_dining',
  AIRPORT_SHUTTLE: 'airport_shuttle',
  ROOM_SERVICE: 'room_service',
  FREE_BREAKFAST: 'free_breakfast',
  FITNESS_CENTER: 'fitness_center',
  MEETING_ROOM: 'meeting_room',
  NON_SMOKING_ROOMS: 'smoke_free',
  PARKING: 'local_parking',
};

const IMAGE_RELOAD_ATTEMPT_DELAY = 1000;

@Component({
  components: {
    HotelOfferConditions,
    HotelDetailsSection,
    VclCode,
    VueContentLoading,
  },
  filters: {
    dateTime(value) {
      return moment(value).format('Do MMMM HH:mm');
    },
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
  },
})
export default class HotelResultsRow extends Vue {
  @Prop() result!: PropertySearchResult;
  @Prop() item!: any;
  @Prop({ default: () => {
    return {};
  }, }) basket!: any;
  @Prop({ default: false }) offerSelectionDisabled: boolean = false;
  @Prop({ default: '' }) basketStatus!: string;
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({}) isInPopup!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: '' }) basketProviderStatus!: string;
  @Prop({ default: null }) currentPrice!: any | null;
  @Prop({ default: false }) disableFeeEdit!: boolean;
  @Prop({ default: 0 }) priceChange!: number;

  offerResult: AccommodationOfferSearchResult | null = null;
  showDetails: boolean = false;
  currentOffersVisible: number = 4;
  offerConditionsVisible: boolean = false;
  imageLoading: boolean = true;
  imageVisible: boolean = true;
  imageProcessing: boolean = true;
  imageErrorCounter: number = 0;

  selectedOffer?: any = null;
  offersLoaded: boolean = false;
  selectedOfferId: string = '';
  pricePlusMarkup: number = this.setPricePlusMarkup();
  height: any | null = null;



  get styles() {
    if (this.height) {
      return {
        height: this.height + 'px',
      };
    }
    return {};
  }

  get imageUrl() {
    if (this.imageLoading) {
      return this.result.property.mainImageUrl;
    } else {
      return '';
    }
  }

  get imageUrls() {
    if (this.imageLoading) {
      return [
        this.result.property.mainImageUrl,
        this.result.property.mainImageUrl,
        this.result.property.mainImageUrl
      ];
    } else {
      return '';
    }
  }

  get ratingString(): string {
    let str = '' + this.result.property.review.rating;
    if (1 === str.length) {
      str += '.0';
    }
    return str;
  }

  get hasNoOffers() {
    return this.offersLoaded && 0 === this.offers.length;
  }

  get offers(): Offer[] {
    if (!this.offerResult) {
      return [];
    }
    return this.offerResult.offers;
  }

  get offersCurrent(): Offer[] {
    return this.offers.slice(0, this.currentOffersVisible);
  }

  get feeAmount() {
    if (!this.result || !this.result.agencyFee) {
      return '';
    }
    let currency = '';
    if (
      this.result.agencyFee.currency &&
      (this.result.agencyFee.currency.symbol || this.result.agencyFee.currency.code)
    ) {
      currency = this.result.agencyFee.currency.symbol ? this.result.agencyFee.currency.symbol : this.result.agencyFee.currency.code;
    }
    return currency + ' ' + this.result.agencyFee.amount;
  }

  get agencyMarkup() {
    if (!this.item || !this.item.price || !this.item.price.agencyMarkup) {
      return null;
    }
    return this.item.price.agencyMarkup;
  }

  get systemMarkup() {
    if (!this.item || !this.item.price || !this.item.price.systemMarkup) {
      return null;
    }
    return this.item.price.systemMarkup;
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }
  get hasEditAgencyMarkupPermission() {
    return this.$hasAccess('CanEditAgencyMarkup');
  }

  get hasAnySearchStats() {
    return this.result.propertySearchStatistics && (
      this.result.propertySearchStatistics.isEcoFriendly ||
      this.result.propertySearchStatistics.hasRefundableOffers ||
      this.result.propertySearchStatistics.hasMealOffers ||
      this.result.property.loyaltyProgram
    );
  }

  get hasSelectedOfferWithTags() {
    return this.result &&
      this.result.selectedOffer && (
        -1 < [
          'Breakfast',
          'Room only',
        ].indexOf(this.result.selectedOffer.mealType) ||
        this.result.selectedOffer.isEcoFriendly ||
        this.result.property.loyaltyProgram
      );
  }

  get startDate() {
    if (this.result && this.result.selectedOffer) {
      return this.result.selectedOffer.checkIn;
    }
  }

  get endDate() {
    if (this.result && this.result.selectedOffer) {
      return this.result.selectedOffer.checkOut;
    }
  }

  get sameMonth() {
    return moment(this.startDate).isSame(this.endDate, 'month');
  }

  get canShowEditBookingFee() {
    return this.hasEditFeePermission && this.basketStatus === 'Draft' && this.item.price.agencyFee;
  }

  get canShowEditAgencyMarkup() {
    return this.hasEditAgencyMarkupPermission && this.basketStatus === 'Draft' && this.item.price.agencyMarkup;
  }

  get dividePriceBy() {
    if (this.result.selectedOffer) {
      const travellersCount = this.basket.travellers.length;
      const returnDate = moment(this.result.selectedOffer.checkOutDate);
      const departureDate = moment(this.result.selectedOffer.checkInDate);
      const nights = returnDate.diff(departureDate, 'days');
      return travellersCount * nights;
    }
    const travellersCount = SearchStore.getTravellersState.travellers.length;
    const returnDate = moment(SearchStore.getHotelDefaultState.checkOutDate);
    const departureDate = moment(SearchStore.getHotelDefaultState.checkInDate);
    const nights = returnDate.diff(departureDate, 'days');
    return travellersCount * nights;
  }

  get propertyOffersLoading() {
    return HotelSearchStore.propertyOffersLoading;
  }

  get searchId() {
    return this.$route.params.searchId;
  }

  get resultsAmenities() {
    if (this.result) {
      const array = this.result.property.amenities
        .filter((a: any) => {
          return this.amenityIcon(a.code) !== false;
        });
      const mapped: any = array.map((item: any) => [item.code, item]);
      return [...new Map(mapped).values()];
    } else {
      return [];
    }
  }

  get basketAmenities() {
    if (this.result) {
      const array = this.result.property.amenities.filter(a => {
        return this.amenityIcon(a) !== false;
      });
      return _.uniq(array);
    } else {
      return [];
    }
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get numberOfPerson() {
    return this.travellers.length;
  }

  get numberOfNights() {
    if (this.result && this.result.selectedOffer) {
      const returnDate = moment(this.result.selectedOffer.checkOut);
      const departureDate = moment(this.result.selectedOffer.checkIn);
      return returnDate.diff(departureDate, 'days');
    }

    return null;
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get totalPrice() {
    return {
      ...this.item.totalPrice,
      amount: this.item.totalPrice.amount + this.priceChange,
    };
  }



  setPricePlusMarkup() {
    if (this.isInBasket && this.item && this.item.totalPrice && this.item.agencyMarkup) {
      return this.item.totalPrice.amount + this.item.agencyMarkup.amount;
    } else if (this.isInBasket && this.item && this.item.totalPrice) {
      return this.item.totalPrice.amount;
    }
    return 0;
  }

  pricePerNight(offer) {
    return offer.price.currency.symbol + ' ' + (Math.round(100 * offer.price.amount / this.dividePriceBy) / 100);
  }

  async toggleDetails() {
    if (!this.showDetails) {
      await this.loadOffers();
    }
    this.showDetails = !this.showDetails;
  }

  currentlySelectedOffer(offerId) {
    return offerId === this.selectedOfferId;
  }

  offerTenants(room) {
    if (!room || !room.adultsCount) {
      return '';
    }
    let text = translate('search.adults') + ': ' + room.adultsCount;

    if (room.childrenCount) {
      text += ' | ' + translate('search.children') + ': ' + room.childrenCount;
    }

    return text;
  }

  async loadOffers() {
    this.offerResult = await HotelSearchStore.loadPropertyOffers({
      searchId: this.searchId,
      propertyId: this.result.property.id,
    });
    this.offersLoaded = true;
    HotelSearchStore.updateOfferStats({
      offerId: this.result.property.id,
      stats: this.offerResult.propertySearchStatistics,
    });
  }

  imageLoaded() {
    this.imageProcessing = false;
  }

  imageError() {
    if ('' === this.imageUrl) {
      return;
    }
    this.imageErrorCounter++;
    if (this.imageErrorCounter < 2) {
      this.imageLoading = false;
      setTimeout(() => {
        this.imageLoading = true;
      }, IMAGE_RELOAD_ATTEMPT_DELAY);
    } else {
      this.imageVisible = false;
      this.imageProcessing = false;
    }
  }

  amenityIcon(amenity) {
    return amenitiesMap[amenity] ? amenitiesMap[amenity] : false;
  }

  showConditions(offer) {
    this.selectedOffer = offer;
    this.offerConditionsVisible = true;
  }

  fullConditions(item) {
    this.showConditions(item.selectedOffer);
  }

  showMobileOfferDetails(result) {
    router.push({
      name: 'hotelResultDetails',
      params: {
        searchId: this.$route.params.searchId,
        propertyId: result.property.id,
      },
      });
    }

  goToDetails(result) {
    HotelSearchStore.setDetailedOffer(result);
    if (!this.isInBasket) {
      const routeData = this.$router.resolve({
        name: 'hotelDetails',
        params: {
          searchId: this.$route.params.searchId,
          propertyId: result.property.id,
        },
      });
      window.open(routeData.href, '_blank');
    } else {
      const routeData = this.$router.resolve({
        name: 'hotelDetailsBasket',
        params: {
          propertyId: result.property.id,
        },
      });
      window.open(routeData.href, '_blank');
    }
  }

  showOnMap(result) {

    router.push({
      name: 'hotelMap',
      params: {
        ...this.$route.params,
        selectedId: result.property.id,
      },
    });
  }

  getProviderImage(provider: string) {
    return provider === 'Fake' ? provider.toLowerCase() : provider;
  }

  formatPrice(price) {
    if (!price) {
      return '';
    }
    return price.currency.symbol + ' ' + (Math.round(100 * price.amount) / 100).toFixed(2);
  }

  capitalizeFirstLetter(text) {
    if (typeof text !== 'string') return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  tooltip() {
    if (this.basketProviderStatus === 'Cancelled') return '';

    let system = '';
    let markup = '';

    if (this.item.price.rawProviderPrice) {
      system += `<span>${translate('basket.raw-provider-price')} ${this.formatPrice(this.item.price.rawProviderPrice)}</span>`;
    }
    if (this.item.price.systemMarkup) {
      system += `<span class="mb-3">${translate('basket.system-markup')} ${this.formatPrice(this.item.price.systemMarkup)}</span>`;
    }

    if (this.item.price.agencyMarkup) {
      markup += `<span>${translate('basket.incl')} ${this.formatPrice(this.item.price.agencyMarkup)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if (this.item.price.agencyFee) {
      markup += `<span>${translate('basket.incl')} ${this.formatPrice(this.item.price.agencyFee)} ${translate('search-air.fee')}</span>`;
    }

    return system +
      `<span>${this.formatPrice(this.result.selectedOffer.pricePerRoomPerNight)} ${translate('search-hotel.room-per-night')}</span>
      <span>${translate('basket.incl')} ${this.formatPrice(this.item.price.providerPrice)} ${translate('search-hotel.provider-price')}</span>
      ` + markup;
  }

  @Watch('loading')
  onLoading(value) {
    if (!value) {
      this.showDetails = false;

      if (!this.height) {
        this.$nextTick(() => {
          const rect = (this.$refs.mainCard as HTMLElement).getBoundingClientRect();
          this.height = rect.height;
        });
      }
    }
  }

  editFee() {
    BasketStore.setEditedFeeItem(this.result);
    BasketStore.setEditedFeeOffer(this.item);
    BasketStore.setShowEditHotelFeePopup(true);
  }

  editAgencyMarkup() {
    BasketStore.setEditedFeeItem(this.result);
    BasketStore.setEditedFeeOffer(this.item);
    BasketStore.setShowEditHotelAgencyMarkupPopup(true);
  }

  onHotelRoomEditFee(offer) {
    if (!this.offerResult || !this.offerResult.offers) {
      return;
    }
    const index = this.offerResult.offers.findIndex(item => item.id === offer.id);

    if (index === -1) {
      return;
    }

    HotelSearchStore.setEditedOffer({
      offer,
      result: this.result,
    });
    EventBus.$emit('show-hotel-edit-fee-popup');
  }

  onHotelRoomEditAgencyMarkup(offer) {
    if (!this.offerResult || !this.offerResult.offers) {
      return;
    }
    const index = this.offerResult.offers.findIndex(item => item.id === offer.id);

    if (index === -1) {
      return;
    }

    HotelSearchStore.setEditedOffer({
      offer,
      result: this.result,
    });
    EventBus.$emit('show-hotel-edit-agency-markup-popup');
  }

  async onOfferFeeEdited({
    offerId,
    feeValue,
    agencyMarkupValue,
    systemMarkupValue,
    totalPrice,
    travelPolicy,
  }) {
    if (this.isInPopup) {
      return;
    }
    if (this.isInBasket && this.item && this.item.id === offerId) {
      if (feeValue !== undefined && this.result.agencyFee) {
        this.result.agencyFee.amount = feeValue;
      }
      this.item.totalPrice.amount = totalPrice;
      if (feeValue !== undefined && this.item.price.agencyFee) {
        this.item.price.agencyFee.amount = feeValue;
      }
      this.item.price.total.amount = totalPrice;
      if (agencyMarkupValue && this.item.price.agencyMarkup) {
        this.item.price.agencyMarkup.amount = agencyMarkupValue;
      }
      if (systemMarkupValue && this.item.price.systemMarkup) {
        this.item.price.systemMarkup.amount = systemMarkupValue;
      }
      if (travelPolicy) {
        this.item.travelPolicy = travelPolicy;
      }
      return;
    }
    if (!this.offerResult) {
      return;
    }
    const offer = this.offerResult.offers.find(o => o.id === offerId);

    if (!offer) {
      return;
    }

    const response = await HotelSearchStore.loadPropertyOffers({
      searchId: this.searchId,
      propertyId: this.result.property.id,
    });
    EventBus.$emit('update-hotel-room-travel-policy', response.offers);
    this.offersLoaded = true;
    HotelSearchStore.updateOfferStats({
      offerId: this.result.property.id,
      stats: response.propertySearchStatistics,
    });
  }

  async onOfferAgencyMarkupEdited({ offerId, agencyMarkupValue, totalPrice }) {
    if (this.isInPopup) {
      return;
    }
    if (this.isInBasket && this.item.id === offerId) {
      if (this.result.agencyMarkup) {
        this.result.agencyMarkup.amount = agencyMarkupValue;
      }
      this.item.totalPrice.amount = totalPrice;
      this.item.price.agencyMarkup.amount = agencyMarkupValue;
      this.item.price.total.amount = totalPrice;
      return;
    }
    if (!this.offerResult) {
      return;
    }
    const offer = this.offerResult.offers.find(o => o.id === offerId);

    if (!offer) {
      return;
    }

    const response = await HotelSearchStore.loadPropertyOffers({
      searchId: this.searchId,
      propertyId: this.result.property.id,
    });
    EventBus.$emit('update-hotel-room-travel-policy', response.offers);
    this.offersLoaded = true;
    HotelSearchStore.updateOfferStats({
      offerId: this.result.property.id,
      stats: response.propertySearchStatistics,
    });
  }

  created() {
    EventBus.$on('edit-fee-for-room', this.onHotelRoomEditFee);
    EventBus.$on('edit-agency-markup-for-room', this.onHotelRoomEditAgencyMarkup);
    EventBus.$on('offer-fee-edited', this.onOfferFeeEdited);
    EventBus.$on('offer-agency-markup-edited', this.onOfferAgencyMarkupEdited);
  }

  beforeDestroy() {
    EventBus.$off('edit-fee-for-room', this.onHotelRoomEditFee);
    EventBus.$off('edit-agency-markup-for-room', this.onHotelRoomEditAgencyMarkup);
    EventBus.$off('offer-fee-edited', this.onOfferFeeEdited);
    EventBus.$off('offer-agency-markup-edited', this.onOfferAgencyMarkupEdited);
  }

}

