




























import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';

@Component({})
export default class TrainCabinExit extends Vue {
  @Prop({ default: null }) item!: any | null;

  get x() {
    return consts.margin + (this.item.x + .5) * consts.colWidth - consts.thickness;
  }

  get y() {
    return consts.margin + (this.item.y + .5) * consts.rowHeight;
  }

  get width() {
    return (this.item.width - 1) * consts.colWidth + 2 * consts.thickness;
  }

  get height() {
    return this.item.height * consts.rowHeight;
  }

  get isLeft() {
    return this.item.x === 0;
  }

  get rotation() {
    if (this.isLeft) {
      return '';
    }
    return 'scale(-1,1) translate(-14, 0)';
  }

  get exits() {
    let x = this.x - 10;
    if (!this.isLeft) {
      x = this.x + this.width + 2;
    }
    
    return [
      {
        x,
        y: this.y + 3 - 6,
      },
      {
        x,
        y: this.y + this.height / 2 - 6,
      },
      {
        x,
        y: this.y + this.height - 3 - 6,
      },
    ];
  }
}
