


















































































import { Vue, Component, Prop } from 'vue-property-decorator';

import SeatMapConst from '@/const/seat-map.const';
import { SeatModel } from '@/api/trip/basket.model';

@Component
export default class SeatPlace extends Vue {
  @Prop({ default: 0 }) x!: number;
  @Prop({ default: 0 }) y!: number;
  @Prop() seat!: SeatModel;
  @Prop() unavailableForTraveller!: boolean;

  consts: any = SeatMapConst;

  get margin() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileMargin;
    } else {
      return SeatMapConst.margin;
    }
  }

  get rowHeight() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileRowHeight;
    } else {
      return SeatMapConst.rowHeight;
    }
  }

  get colWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileColWidth;
    } else {
      return SeatMapConst.colWidth;
    }
  }

  get extendedColWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileExtendedColWidth;
    } else {
      return SeatMapConst.extendedColWidth;
    }
  }

  get iconHeight() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileIconHeight;
    } else {
      return SeatMapConst.iconHeight;
    }
  }

  get iconWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileIconWidth;
    } else {
      return SeatMapConst.iconWidth;
    }
  }

  get seatSeleniumId() {
    if (this.seat.isFree && !this.seat.noSeat) {
      return 'seat';
    }
  }
}
