import TravellerCategoriesWrapper from '@/modules/settings/traveller-categories/TravellerCategoriesWrapper.vue';
import TravellerCategoriesRoute from './traveller-categories.route';

export default {
  path: 'traveller-categories',
  component: TravellerCategoriesWrapper,
  displayName: 'settings-sidebar.traveller-categories',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditTravellerCategories',
    icon: 'group',
    parentName: 'traveller-categories',
  },
  children: [
    TravellerCategoriesRoute,
  ],
};
