import TravelPolicyRailManageRule from '@/modules/settings/travel-policy/configurations/configuration/TravelPolicyRailManageRule.vue';

export default {
  path: ':configurationId?/rail-rule:readOnly?',
  name: 'rail-rule',
  component: TravelPolicyRailManageRule,
  displayName: 'Rail Rule',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadTravelPolicy',
    parentName: 'travel-policy-configurations',
    depthCategory: 'travel-policy',
    depth: 3,
  }
};