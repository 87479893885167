export default {
  apiAccommodationEngine: 'https://localhost:5711',
  apiAccommodationExpedia: 'https://localhost:6006',
  apiAccount: 'https://localhost:5101',
  apiApprovalWorkflow: 'https://localhost:5401',
  apiAirEngine: 'https://localhost:5701',
  apiCurrency: 'https://localhost:6005',
  apiRailEngine: 'https://localhost:5721',
  apiCarEngine: 'https://localhost:5731',
  apiProfile: 'https://localhost:5201',
  apiTrip: 'https://localhost:5601',
  apiDictionary: 'https://localhost:5801',
  apiPermissions: 'https://localhost:5202',
  apiWonderSearch: 'https://localhost:5501',
  apiTravelPolicy: 'https://localhost:5901',
  apiExpenseCore: 'https://localhost:6101',
  apiWeb: 'https://localhost:4300',
  apiBilling: 'https://localhost:6401',
  login: 'https://localhost:5001/Identity/Account/Login?ReturnUrl=',
  logout: 'https://localhost:5001/Identity/Account/Logout',
  manageAccount: 'https://localhost:5001/Identity/Account/Manage',
  enableExternalAgencyMarkup: 'true',
  enableExternalSkyscanner: 'false',
  enableExternalHopper: 'false',
  enableExternalAgentsWidget: 'false',
  enableExternalOffline: 'false',
  enableExternalExpense: 'true',
  enableExternalMessagesWidget: 'false',
  enableExternalMessagesWidgetText: '',
  enableExternalTripsControls: 'true',
  enableExternalAccommodationPackageRates: 'false',
  enableExternalSkipTravellers: 'false',
  enableExternalSalesPictures: 'false',
  companyIdsNDCOfferWidget: '',
  trainSearchMode: 'OneWay',
  hotelMapTileServerKey: 'cPqQhfZihVMfSYVzfb8P',
  hotelMapTileServerUrl: 'https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=cPqQhfZihVMfSYVzfb8P',
  hotelMapAttribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
  pciProxy_MerchantId: '',
  pciProxy_WebSecureFieldsUrl: 'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.js',
  pciProxy_MockEnabled: 'true',
  externalMissionBaseUrl: 'https://wondermiles.com/',
  enableBookingHoldAction: 'true',
  isRefundAlwaysAvailable: 'true',
  railGreenPassUrl: '',
  railGreenPassUrlNamePath: '',
  helpLink: 'https://intercom.help/wondermiles-sbt/',
  helpLinkAgency: 'https://intercom.help/wondermiles/',
  pluginProviderEnableIntercom: 'true',

  sncfSeatMapVersion: '2.7.1',
  pkpUrl: '',
  pkpDescription: '',
  pkpAgencyIds: '[]',
  enableDemoWelcomeWidget: 'false',
  reportsSmallIframeUrl: '',
  reportsWideIframeUrl: '',
  companyLogo: {
    width: 640,
    height: 240,
    returnType: 'base64',
    returnFormat: 'png',
    base64SizeLimitInB: 1048576,
  },
  avatar: {
    width: 115,
    height: 115,
    returnType: 'base64',
    returnFormat: 'png',
    base64SizeLimitInB: 1048576,
    maxAvatarCount: 110,
  },
  widgets: {
    offlineRequests: {
      maxItemsCount: 4
    },
    tripsHeld: {
      maxItemsCount: 4
    },
    tripsLastBooked: {
      maxItemsCount: 4
    },
    tripsPendingApproval: {
      maxItemsCount: 4
    },
  },
  expenseImage: {
    width: 382,
    height: 512,
    returnType: 'base64',
    returnFormat: 'png',
    base64SizeLimitInB: 10485760,
    
  },
};
