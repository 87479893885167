





































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import EventBus from '@/services/event-handler';
import { router } from '@/router';
import settings from '@/settings';
import AccountStore from '@/store/account.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from '@/modules/basket/basket.store';
import SearchStore from '@/modules/search/search.store';
import ExpenseStore from '@/modules/expense/expense.store';
import AirCompareLocalStore from './air-compare.local-store';
import { OffersApi } from '@/api/air-engine/offers.api';
import { AirApi } from '@/api/air-engine/air-search.api';
import { OffersModel } from '@/api/air-engine/offers.model';
import { Recommendation } from '@/api/air-engine/air-search.model';
import { AirSeats } from './air-seats.model';
import FlightTimeline from './FlightTimeline.vue';
import FlightTimelineDetails from './FlightTimelineDetails.vue';
import AirFareConditions from './AirFareConditions.vue';
import AirRefundPopup from './AirRefundPopup.vue';
import AirExchangeOfferDetails from './AirExchangeOfferDetails.vue';
import AirResultsFeeEditedOffer from './AirResultsFeeEditedOffer.vue';
import AirResultFlightError from './AirResultFlightError.vue';
import AirPriceDetails from './AirPriceDetails.vue';
import AirSeatMap from './AirSeatMap.vue';
import { translate } from '@/i18n';
import formatPrice from '@/filters/format-price.filter';
import { SupplierName } from '@/services/supplier-name.service';
import SearchConst from '@/const/search.const';


@Component({
  components: {
    FlightTimeline,
    FlightTimelineDetails,
    AirFareConditions,
    AirSeatMap,
    AirRefundPopup,
    AirExchangeOfferDetails,
    AirResultsFeeEditedOffer,
    AirResultFlightError,
    AirPriceDetails,
  },
  filters: {
    momentDate(date) {
      return moment(date).format('ddd, Do MMM');
    },
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
    momentDateTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY HH:mm');
    },
  }
})
export default class AirResultsRow extends Vue {
  @Prop() offer!: Recommendation;
  @Prop() item?: any;
  @Prop({ default: false }) isInBasket!: boolean;
  @Prop({ default: false }) enableFilterByFlightNumber!: boolean;
  @Prop({ default: false }) showingInPopup!: boolean;
  @Prop({ default: false }) shouldHide!: boolean;
  @Prop({ default: true }) searchMode!: boolean;
  @Prop() searchModeType!: string;
  @Prop({ default: '' }) basketStatus!: string;
  @Prop({ default: '' }) basketProviderStatus!: string;
  @Prop({ default: false }) modifyMode!: boolean;
  @Prop({ default: false }) bookingInProgress!: boolean;
  @Prop() basketItemId!: string;
  @Prop({ default: false }) disableAgencyMarkup!: boolean;
  @Prop({ default: true }) showPrice!: boolean;
  @Prop({ default: false }) noFadeLegs!: boolean;
  @Prop({ default: false }) disableFiltering!: boolean;
  @Prop({ default: false }) keepThisOffer!: boolean;
  @Prop({ default: false }) hideShare!: boolean;

  showDetails: boolean = false;
  seatsViewPopup: boolean = false;
  errors: any[] = [];
  selectedSeats: AirSeats | null = null;
  canShow = this.searchMode;
  offerDetails: OffersModel | any = null;
  showFullConditionsPopup: boolean = false;
  showEditSegmentPopup: boolean = false;
  showExchangeDetailsPopup: boolean = false;
  showEditFeePopup: boolean = false;
  showEditAgencyMarkupPopup: boolean = false;
  disabledInfo: string = translate('search-air.max-selected');
  currentTemplateOffers: any[] = [];
  currentSsid: string = '';
  agencyFeeValue: number = !this.modifyMode && this.offer.proposal.agencyFee ? this.offer.proposal.agencyFee.amount : 0;
  agencyMarkupValue: number = !this.modifyMode && this.offer.proposal.agencyMarkup ? this.offer.proposal.agencyMarkup.amount : 0;
  initialAgencyFeeValue: number = this.agencyFeeValue;
  initialAgencyMarkupValue: number = this.agencyMarkupValue;
  basePrice: number = this.offer.proposal.price.amount - this.getOffersAgencyFeeAmount();
  basePriceForMarkups: number = this.offer.proposal.price.amount - this.getOffersAgencyMarkupAmount();
  loaderImagePath: string = '/assets/img/loader/1.gif';
  imagesErrorInfoConst = '/assets/img/ic_error.png';
  ndcImagePath: string = '/assets/img/ndc.png';
  editFeeErrors: any[] = [];
  editFeeLoading: boolean = false;
  editAgencyMarkupErrors: any[] = [];
  editAgencyMarkupLoading: boolean = false;
  showPriceDetailsPopup: boolean = false;
  isFeeValueChanging: boolean = false;
  isAgencyMarkupValueChanging: boolean = false;

  passengerTypeMap = {
    ADT: translate('passenger-types.adt'),
    YTH: translate('passenger-types.yth'),
    CHD: translate('passenger-types.chd'),
    CNN: translate('passenger-types.chd'),
    INF: translate('passenger-types.inf'),
    SRC: translate('passenger-types.src'),
    SEA: translate('passenger-types.sea'),
    MLT: translate('passenger-types.mlt'),
  };

  get timelinesCollapsed() {
    return false;
  }

  get isUpsellView() {
    return this.$route.name === 'airUpsell';
  }

  get basket() {
    return BasketStore.basket;
  }

  get isInWizard() {
    return BasketStore.isInWizard;
  }

  get bookingStepCode() {
    return BasketStore.bookingStepDef && BasketStore.bookingStepDef.code ? BasketStore.bookingStepDef.code : '';
  }

  get canUserBookTrip() {
    return AccountStore.HasPermission('BookTrip');
  }

  get canAddOfferToTrip() {
    if ('airModification' === this.$route.name) {
      return this.canExchangeFlightBooking;
    }
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get canExchangeFlightBooking() {
    return AccountStore.HasPermission('ExchangeFlightBooking');
  }

  get canExchangeFlightSearch() {
    return AccountStore.HasPermission('ExchangeFlightSearch');
  }

  get templateOffers(): any[] {
    return AirSearchStore.templateOffers;
  }

  get localStore() {
    return AirCompareLocalStore.localStore;
  }

  get templateOffersCount() {
    if (this.localStore && this.localStore.length) {
      return this.localStore;
    } else {
      return AirSearchStore.templateOffers;
    }
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get timelinesExpanded() {
    return true;
  }

  get hasFlyingBlue() {
    const programs = this.offer.proposal.loyaltyProgram || [];

    return programs.some(program => program === 'FlyingBlue');
  }

  get selectButtonClasses() {
    return {
      'select-button-thin': -1 < [
        'it',
        'pl',
      ].indexOf(AccountStore.currentLanguage),
    };
  }

  get totalFareTax() {
    if (!this.modifyMode) {
      return 0;
    }
    if ((this.offer.proposal as any).taxDifference.amount < 0) {
      return (this.offer.proposal as any).fareDifference.amount;
    }
    return Math.round(
      100 * ((this.offer.proposal as any).fareDifference.amount +
      (this.offer.proposal as any).taxDifference.amount)
    ) / 100;
  }

  get hasExchangeSearchErrors() {
    return !!AirSearchStore.exchangeErrors.length && this.modifyMode;
  }

  get isDisabled() {
    return (this.$route.name !== 'airUpsell' &&
      (AirSearchStore.isSelected || this.hasExchangeSearchErrors)) ||
      (this.$route.name === 'airUpsell' && AirSearchStore.upsellChosen !== null);
  }

   get flagClasses() {
    return {
      'flag__public': 'Public' === this.offer.proposal.fareType,
      'flag__nego': 'Nego' === this.offer.proposal.fareType,
      'flag__private': 'Private' === this.offer.proposal.fareType,
    };
  }

  get isLCC() {
    return this.offer.isLowCost;
  }

  get classesTooltip() {
    switch (this.offer.proposal.fareType) {
      case 'Public':
        return translate('search-air.public-fare');
      case 'Nego':
        return translate('search-air.nego-fare');
      case 'Private':
        return translate('search-air.private-fare');
      default:
        return translate('search-air.public-fare');
    }
  }

  get isThisOfferSelected() {
    return (this.$route.name !== 'airUpsell' &&
      AirSearchStore.isSelected &&
      this.offer.id === AirSearchStore.currentOfferId) ||
      (this.$route.name === 'airUpsell' &&
      this.offer.id === AirSearchStore.upsellChosen);
  }

  get classes() {
    return {
      'disabled': AirSearchStore.isSelected && this.$route.name !== 'airUpsell',
      'disabled-active': AirSearchStore.isSelected &&
        this.$route.name !== 'airUpsell' &&
        AirSearchStore.currentOfferId === this.offer.id &&
        AirSearchStore.currentProposalId === this.offer.proposal.id,
      'expired': this.isOfferExpired,
    };
  }

  get sameAsExchangedOffer() {
    return AirSearchStore.getExchangedOffer ? AirSearchStore.getExchangedOffer.id === this.offer.id : false;
  }

  get travellers() {
    return BasketStore.basketTravellers;
  }

  get currentTravellers() {
    return AirSearchStore.getExchangeTravellers;
  }

  get modificationBasketId() {
    return AirSearchStore.modificationBasketId;
  }

  get canShowExternalAgencyMarkup() {
    return 'true' === settings.enableExternalAgencyMarkup;
  }

  get passengerTickets() {
    return this.travellers.map(traveller => {
      return {
        ...traveller,
        passengerPrice: (this.offer.proposal.passengerPrices && this.offer.proposal.passengerPrices.length) ?
        this.offer.proposal.passengerPrices.find(passengerPrice => traveller.id === passengerPrice.profileId) : null,
      };
    });
  }

  get sameMonth() {
    if (this.offer) {
      let startDate = this.offer.legFlights[0].flights[0].departure;
      const lastLeg: any = _.last(this.offer.legFlights);
      const lastFlight: any = _.last(lastLeg.flights);
      let endDate = lastFlight.arrival;
      return moment(this.startDate).isSame(this.endDate, 'month');
    }
  }

  get startDate() {
    if (this.offer) {
      return this.offer.legFlights[0].flights[0].departure;
    }
  }

  get endDate() {
    if (this.offer) {
      const lastLeg: any = _.last(this.offer.legFlights);
      const lastFlight: any = _.last(lastLeg.flights);
      return lastFlight.arrival;
    }
  }

  get canCompareOffers() {
    return AccountStore.HasPermission('CanSendOfferProposal');
  }

  get policyId() {
    return ExpenseStore.policyId;
  }

  get user() {
    return AccountStore.current;
  }

  get expenseIdForBasket() {
    return BasketStore.expenseIdForBasket;
  }

  get basketId() {
    return router.currentRoute.params.id;
  }

  get fareUnavailableCode() {
    return AirSearchStore.fareUnavailableErrorParams;
  }

  get totalPriceWithCustomFee() {
    return {
      amount: this.offer.proposal.price.amount - this.initialAgencyFeeValue + this.agencyFeeValue,
      currency: this.offer.proposal.price.currency
    };
  }

  get totalPriceWithCustomAgencyMarkup() {
    return {
      amount: this.offer.proposal.price.amount - this.initialAgencyMarkupValue + this.agencyMarkupValue,
      currency: this.offer.proposal.price.currency
    };
  }

  get feeCurrency() {
    if (this.offer.proposal && this.offer.proposal.agencyFee && this.offer.proposal.agencyFee.currency) {
      return this.offer.proposal.agencyFee.currency.symbol || this.offer.proposal.agencyFee.currency.code;
    } else {
      return '\u20AC';
    }
  }

  get agencyMarkupCurrency() {
    if (this.offer.proposal && this.offer.proposal.agencyMarkup && this.offer.proposal.agencyMarkup.currency) {
      return this.offer.proposal.agencyMarkup.currency.symbol || this.offer.proposal.agencyMarkup.currency.code;
    } else {
      return '\u20AC';
    }
  }

  get hasEditAgencyMarkupPermission() {
    return this.$hasAccess('CanEditAgencyMarkup');
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get hasFeeConfiguration() {
    return !!AirSearchStore.feeConfigurationId;
  }

  get isPriceDetailedComponentsVisible() {
    return AirSearchStore.priceDetailedComponentsVisible;
  }

  get canShowEditBookingFee() {
    return this.hasEditFeePermission && this.searchMode &&
      (this.isInBasket && this.basketStatus ?
        (this.basketStatus !== 'Confirmed' && this.basketStatus !== 'Ticketed' && this.basketStatus !== 'Refused' && this.basketStatus !== 'Cancelled')
        : true);
  }

  get canShowEditAgencyMarkup() {
    return this.hasEditAgencyMarkupPermission && this.searchMode &&
      (this.isInBasket && this.basketStatus ?
        (this.basketStatus !== 'Confirmed' && this.basketStatus !== 'Ticketed' && this.basketStatus !== 'Refused' && this.basketStatus !== 'Cancelled')
        : true);
  }

  get isNewFlightDisplay() {
    return AirSearchStore.newFlightDisplay;
  }

  get offerExpired() {
    return AirSearchStore.offerExpired;
  }

  get isOfferExpired() {
    return this.offerExpired && this.offerExpired.length && this.offerExpired.find(expire => expire.recommendationId === this.offer.id);
  }

  get classesOneWay() {
    return {
      'one-way-div': this.offer && this.offer.legFlights && this.offer.legFlights.length === 1 && !this.modifyMode,
      'one-way-modify':  this.offer && this.offer.legFlights && this.offer.legFlights.length === 1 && this.modifyMode,
    };
  }

  get airSearchParameters() {
    return SearchStore.getAirDefaultState;
  }

  get hasCanSeePriceDetails() {
    return this.$hasAccess('CanSeePriceDetails');
  }
  get showPriceDetailsLink() {
    return this.hasCanSeePriceDetails && !this.modifyMode && this.offer.proposal.passengerPrices;
  }

  get isSabreSupplier() {
    return this.offer.proposal.supplier === 'Sabre';
  }

  get isAmadeusSupplier() {
    return this.offer.proposal.supplier === 'Amadeus';
  }


  isNdc(supplier) {
    return -1 < SearchConst.ndcProviders.indexOf(supplier);
  }

  fareLabelInfo(name) {
    return translate('search-air.fare-basis-label') + '<br>' + name;
  }

  baggageLabelInfoDetails(detail) {
    const pieces = detail.freeItems === 1 ? translate('search-air.piece') : translate('search-air.pieces');
    const weight = detail.weight !== null ? ' ' + translate('search-air.weight-up') + ' ' + detail.weight + ' ' + detail.unit : ' ';

    return translate('search-air.passenger-type') + ' ' + '"' + this.getTranslatedPassengerType(detail.passengerType) + '":' + ' ' + detail.freeItems + ' ' + pieces + ' ' + translate('search-air.of-baggage') + weight;
  }

  baggageLabelInfo(baggage) {
    const pieces = baggage.freeItems === 1 ? translate('search-air.piece') : translate('search-air.pieces');
    const weight = baggage.weight !== null;

    if (weight) {
      return baggage.freeItems + ' ' + pieces + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.weight-up') + ' ' + baggage.weight + ' ' + baggage.unit + ' ' + translate('search-air.per-passenger');
    } else {
      return baggage.freeItems + ' ' + pieces + ' ' + translate('search-air.of-baggage') + ' ' + translate('search-air.per-passenger');
    }
  }

  getOffersAgencyFeeAmount() {
    return this.offer.proposal.agencyFee && this.offer.proposal.agencyFee.amount ? this.offer.proposal.agencyFee.amount : 0;
  }

  getOffersAgencyMarkupAmount() {
    return this.offer.proposal.agencyMarkup && this.offer.proposal.agencyMarkup.amount ? this.offer.proposal.agencyMarkup.amount : 0;
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  priceFormat(price) {
    return formatPrice(price);
  }

  carriers(leg) {
    return leg.flights
      .filter(flight => flight.flightInfo)
      .map(flight => flight.flightInfo.carrier)
      .filter((value, index, self) => {
        return self.findIndex((iv) => iv.name === value.name && iv.code === value.code) === index;
      });
  }

  legsUniqueFareLabels(leg) {
    let fareLabels = leg.flights.filter(entry => {
      return entry.type === 'Flight';
    }).map(flight => {
      return flight.fareInfo ? flight.fareInfo.fareBasisLabel : null;
    }).filter(Boolean);

    return fareLabels.length > 1 ? _.uniq(fareLabels) : fareLabels;
  }

  async registerAsExpense() {
    let itemId = '';
    let name = '';
    if (this.offer) {
      name = translate('basket.flight-to') + this.offer.legFlights[0].flights[this.offer.legFlights[0].flights.length - 1].to.cityName;
    }

    let request = {
      reporterId: this.user!.profile.id,
      policyId: this.policyId,
      companyId: this.user!.profile.rootCompanyId,
      businessUnitId: this.user!.profile.companyId,
      missionId: this.basket!.missionId,
      tripItem: {
        id: this.item.id,
        tripId: this.basketId,
        type: this.item.itemType === 'Air' ? 'Flight' : this.item.itemType,
        creationDate: this.item.createDate ? moment(this.item.createDate).format('YYYY-MM-DD') : null,
        name: name,
        supplier: this.item.supplier
      }
    };

    await BasketStore.registerAsExpense(request);

    if (this.expenseIdForBasket) {
      await BasketStore.saveConnectionTripExpense({itemId: this.item.id, expenseId: this.expenseIdForBasket});
      router.push({
        name: 'modify-expense',
        params: {
          expenseId: this.expenseIdForBasket,
        }
      });
    }
  }

  displayExpenseForItem(id) {
    router.push({
      name: 'modify-expense',
      params: {
        expenseId: id,
      }
    });
  }

  toggleDetails() {
    if (this.isOfferExpired) {
      return;
    }
    this.showDetails = !this.showDetails;

    this.reloadOfferDetails();
  }

  reloadOfferDetails() {
    if (this.showDetails) {
      this.loadOfferDetails();
    }
  }

  allFlightPrices() {
    this.offer.legFlights.forEach((legFlight, index) => {
      if (this.shouldFadeLeg(index)) {
        return;
      }
      this.$emit('flightSelected', {
        legHash: legFlight.flightNumberHash,
        selected: true
      });
    });
  }

  flightSelected(e) {
    this.$emit('flightSelected', e);
  }

  refreshFlightNumberHashState(hashes) {
    this.offer.legFlights.forEach(legFlight => {
      const selected = hashes.includes(legFlight.flightNumberHash);
      const timelineKey = `leg${legFlight.legNumber}`;
      const timelineRefs = this.$refs.flightTimelines as FlightTimeline[];
      const timeline = timelineRefs.find((x: any) => x.$vnode.key === timelineKey);

      if (timeline) {
        timeline.setFlightSelectedState(selected);
      }
    });

  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  showUpdateFeePopup() {
    this.editFeeErrors = [];
    this.showEditFeePopup = true;
  }

  showUpdateAgencyMarkupPopup() {
    this.editAgencyMarkupErrors = [];
    this.showEditAgencyMarkupPopup = true;
  }

  closeUpdateFeePopup() {
    this.agencyFeeValue = !this.modifyMode ? this.offer.proposal.agencyFee.amount : 0;
    this.showEditFeePopup = false;
  }

  closeUpdateAgencyMarkupPopup() {
    this.agencyMarkupValue = !this.modifyMode ? this.offer.proposal.agencyMarkup.amount : 0;
    this.showEditAgencyMarkupPopup = false;
  }

  filterBySsid(templates) {
    return templates.filter((temp) => {
      return temp.ssid === this.currentSsid;
    });
  }

  removeElem(arr, value) {
    return arr.filter((ele) => {
      return ele.proposal.id !== value;
    });
  }

  existInArray(offer) {
    let unique = true;
    this.templateOffers.forEach((template: any) => {
      if (template.proposal.id === offer.proposal.id) {
        unique = false;
      }
    });

    return unique;
  }

  checkPrepareoffer(offer: any) {
    if (offer.prepareOfferCheck) {
      if (this.existInArray(offer)) {
        let idList = this.travellersSearchList.travellers.map(trav => {
          return {
            companyId: trav.companyId,
            travId: trav.id,
          };
        });

        let data = {
          timeStamp: new Date(),
          isNewFlight: this.isNewFlightDisplay,
          offerId: offer.id,
          proposalId: offer.proposal.id,
          searchId: this.$route.params.searchId,
          searchMode: this.airSearchParameters.searchMode,
          travellers: idList,
        };
        AirCompareLocalStore.setLocalTemplateOffersStore(data);

        offer.ssid = this.$route.params.searchId;
        this.templateOffers.push(offer);
      }
      let templates = this.filterBySsid(this.templateOffers);
      AirSearchStore.setTemplateOffers(templates);
    } else {
      AirCompareLocalStore.removeLocalStoreBrowser(offer);

      let templates = this.filterBySsid(this.templateOffers);
      this.currentTemplateOffers = this.removeElem(templates, offer.proposal.id);
      AirSearchStore.setTemplateOffers(this.currentTemplateOffers);
    }
  }

  prepareOffers(offer) {
    if (!offer.prepareOfferCheck) {
      offer.prepareOfferCheck = true;
      (this.$refs.prepareOffer as HTMLInputElement).checked = true;
    }
    this.checkPrepareoffer(offer);
    EventBus.$emit('prepare-offer', true);
  }

  shouldFadeLeg(legIndex) {
    if (this.noFadeLegs) {
      return false;
    }
    if (-1 === [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) {
      return false;
    }
    if (legIndex >= AirSearchStore.exchangeLegsSelectedForEdit.length) {
      return false;
    }
    return !AirSearchStore.exchangeLegsSelectedForEdit[legIndex];
  }

  async loadOfferDetails() {

    if (this.offer.proposal.supplier !== 'AirFranceKlm' && this.offer.proposal.supplier !== 'BritishAirways') {
      return;
    }
    if (this.offer.proposal.supplier === 'AirFranceKlm' && this.modifyMode) {
      return;
    }

    try {
      let searchId = this.$route.params.searchId;
      const offerDetailsResponse = await OffersApi.getShortOfferDetails(this.offer.proposal.supplier, this.offer.id, this.offer.providerSearchId, searchId);
      if (offerDetailsResponse.data) {
        this.offerDetails = offerDetailsResponse.data;
      }

      EventBus.$emit('offer-details-reloaded', this.offer.proposal.id);
    } catch (error) {
      this.offerDetails = {
        error: error.response.data,
      };
    }
  }

  legOfferDetails(index) {
    if (this.offerDetails) {
      return this.offerDetails.error ? this.offerDetails : this.offerDetails.legDetails[index];
    }

    return null;
  }

  mounted() {
    this.currentSsid = this.$route.params.searchId;
    if (this.isInBasket && this.offerDetails == null) {
      this.loadOfferDetails();
    }
  }

  openModifyModal() {
    this.showEditSegmentPopup = true;
  }

  openRefundModal() {
    EventBus.$emit('open-refund-popup', this.basketItemId);
  }

  showExchangeDetails() {
    this.showExchangeDetailsPopup = true;
  }

  showPriceDetails() {
    this.showPriceDetailsPopup = true;
  }

  showMobileOfferDetails(offer) {
    router.push({
      name: 'airResultDetails',
      params: {
        searchId: this.$route.params.searchId,
        recomendationId: offer.id,
      }
    });
  }

  showSeatsView() {
    this.seatsViewPopup = true;
  }

  hideSeatsView() {
    this.seatsViewPopup = false;
  }

  commitSeatsView() {
    this.seatsViewPopup = false;
  }

  formatPrice(price) {
    return formatPrice(price);
  }

  getSystemMarkupPart(proposal) {
    let tooltip = '';

    if (proposal.rawProviderPrice) {
      tooltip += `<span>${translate('basket.raw-provider-price')} ${proposal.rawProviderPrice.currency.symbol || proposal.rawProviderPrice.currency.code} ${formatPrice(proposal.rawProviderPrice.amount)}</span>`;
    }
    if (proposal.systemMarkup) {
      tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${proposal.systemMarkup.currency.symbol || proposal.systemMarkup.currency.code} ${formatPrice(proposal.systemMarkup.amount)}</span>`;
    }

    return tooltip;
  }

  getAdvancedOfferInfoPart(proposal) {
    let tooltip = '';

    if ((this.hasAdvancedOfferInfo || this.hasEditFeePermission) && proposal.providerPrice) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.providerPrice.currency.symbol || proposal.providerPrice.currency.code} ${formatPrice(proposal.providerPrice.amount)} ${translate('search-air.provider-price')}`;

      if (proposal.taxPrice !== undefined && proposal.taxPrice != null) {
        tooltip += ` (${proposal.taxPrice.currency.symbol || proposal.taxPrice.currency.code} ${formatPrice(proposal.taxPrice.amount)} ${translate('search-air.taxes')})`;
      }
      tooltip += `</span>`;
    }

    return tooltip;
  }

  getAgencyMarkupAndFeePart(proposal) {
    let tooltip = '';

    if (proposal.agencyMarkup) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.agencyMarkup.currency.symbol || proposal.agencyMarkup.currency.code} ${formatPrice(proposal.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if ((this.isPriceDetailedComponentsVisible || this.hasEditFeePermission || this.hasAdvancedOfferInfo) && proposal.agencyFee !== undefined && proposal.agencyFee !== null) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.agencyFee.currency.symbol || proposal.agencyFee.currency.code} ${formatPrice(proposal.agencyFee.amount)} ${translate('search-air.fee')}</span>`;
    }

    return tooltip;
  }

  tooltip(proposal) {
    return [
      this.getSystemMarkupPart(proposal),
      this.getAdvancedOfferInfoPart(proposal),
      this.getAgencyMarkupAndFeePart(proposal),
    ].join('');
  }

  getLufthansaPart(proposal) {
    let tooltip = ``;
    if (this.hasAdvancedOfferInfo && proposal.providerPrice) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.providerPrice.currency.symbol || proposal.providerPrice.currency.code}  ${formatPrice(proposal.providerPrice.amount)} ${translate('search-air.provider-price')}`;

      if (proposal.lufthansaProposal.taxPrice !== undefined && proposal.lufthansaProposal.taxPrice !== null) {
        tooltip += ` (${proposal.lufthansaProposal.taxPrice.currency.symbol || proposal.lufthansaProposal.taxPrice.currency.code}  ${formatPrice(proposal.lufthansaProposal.taxPrice.amount)} ${translate('search-air.taxes')})`;
      }
      tooltip += `</span>`;
    }

    return tooltip;
  }

  lufthansaModificationTooltip() {
    const proposal = this.offer.proposal;

    return [
      this.getSystemMarkupPart(proposal),
      this.getLufthansaPart(proposal),
      this.getAgencyMarkupAndFeePart(proposal),
    ].join('');
  }

  getPriceChangeText() {
    if (this.offer) {
      return (this.offer.proposal.grandTotalDifference !== undefined && this.offer.proposal.grandTotalDifference.amount < 0) ? translate('search-air.residual') + ':' : translate('search-air.change-cost');
    } else {
      return '';
    }
  }

  getTranslatedPassengerType(type) {
    if (!this.passengerTypeMap[type]) {
      return type;
    }

    return translate(this.passengerTypeMap[type]);
  }

  async agencyFeeChanged(value) {
    this.agencyFeeValue = value;
  }

  async saveOfferFee() {
    if (this.editFeeLoading || this.isFeeValueChanging) {
      return;
    }
    try {
      this.editFeeLoading = true;
      let agencyFee = {
        amount: this.agencyFeeValue,
        currency: this.offer.proposal.agencyFee.currency,
      };
      let response = await AirApi.updateFeeOnOffer({ searchId: this.$route.params.searchId, offerId: this.offer.id, agencyFee });
      if (response && response.data) {
        AirSearchStore.updateOffer(response.data);
        this.initialAgencyFeeValue = response.data.proposal.agencyFee.amount;
      }
      this.showEditFeePopup = false;
    } catch (error) {
      this.editFeeErrors = this.$handleErrors(error, true);
    } finally {
      this.editFeeLoading = false;
    }
  }

  async agencyMarkupChanged(value) {
    this.agencyMarkupValue = value;
  }

  async saveOfferAgencyMarkup() {
    if (this.editAgencyMarkupLoading || this.isAgencyMarkupValueChanging) {
      return;
    }
    try {
      this.editAgencyMarkupLoading = true;
      let agencyMarkup = {
        amount: this.agencyMarkupValue,
        currency: this.offer.proposal.agencyMarkup.currency,
      };
      let response = await AirApi.updateAgencyMarkupOnOffer({
        searchId: this.$route.params.searchId,
        offerId: this.offer.id,
        agencyMarkup
      });
      if (response && response.data) {
        AirSearchStore.updateOffer(response.data);
        this.initialAgencyMarkupValue = response.data.proposal.agencyMarkup.amount;
      }
      this.showEditAgencyMarkupPopup = false;
    } catch (error) {
      this.editAgencyMarkupErrors = this.$handleErrors(error, true);
    } finally {
      this.editAgencyMarkupLoading = false;
    }
  }

}
