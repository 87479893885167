import settingsBase from './settings.base';

const settings = {
  ...settingsBase,

  update: (appSettings) => {
    Object.keys(appSettings).forEach(key => {
      settings[key] = appSettings[key];
    });
  }
};

export default settings;
