





import { Vue, Component } from 'vue-property-decorator';

import settings from '@/settings';

@Component({})
export default class ShowTicket extends Vue {

  railTicket() {
    const orderId = this.$route.params.orderId;
    const supplier = this.$route.params.supplier;
    const path = settings.apiRailEngine + 
      `/api/orders/${orderId}/tickets/download?railSupplier=${supplier}`;

    window.location.href = path;
  }

  mounted() {
    const type = this.$route.params.type;
    const methodByType = {
      rail: this.railTicket,
    };
    
    if (methodByType[type]) {
      methodByType[type]();
    }

    this.$nextTick(() => {
      this.$router.push('/');
    });
  }

}

