
















































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { HomeApi } from '@/api/home/home.api';
import { TravellerModel } from '@/api/home/home.model';
import { TravellersStateParams } from '@/modules/search/travellers.params';
import EventBus from '@/services/event-handler';

@Component({})
export default class SearchTravellers extends Vue {
  @Prop() travellersSearchList!: TravellersStateParams;
  serverErrors: any[] = [];
  showError: boolean = false;

  $refs!: {
    travellerSearch: HTMLFormElement
  };

  options: TravellerModel[] = [];
  isLoading: boolean = false;
  canGoToSearch = true;

  get travellers() {
    return this.travellersSearchList.travellers;
  }

  set travellers(trav) {
    if (trav) {
      this.travellersSearchList.travellers = trav;
      if (this.travellersSearchList && this.travellersSearchList.travellers && this.travellersSearchList.travellers.length) {
        if (-1 === this.travellersSearchList.travellers.findIndex(item => !!item.isMainTraveller)) {
          this.travellersSearchList.travellers[0].isMainTraveller = true;
        }
      }
    }
  }



  userFullName(user) {
    return userFullName(user);
  }

  limitText(count) {
    if (count < 2) {
      return this.$t('search.other-traveller', {count: count});
    } else {
      return this.$t('search.other-travellers', {count: count});
    }
  }

  addTag(newTag) {
    this.options.push(newTag);
  }

  handleEnterPress($event) {
    if (!$event.target.classList.contains('multiselect__input')) {
      return;
    }
    if (this.travellersSearchList.travellers.length > 0 &&
      this.canGoToSearch &&
      this.options.length === 0 &&
      !this.$refs.travellerSearch.search) {
        this.$refs.travellerSearch.isOpen = false;
        EventBus.$emit('focus-on-search');
    } else {
      this.canGoToSearch = true;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isLoading',
  })
  async asyncFind(query: string) {
    if (query && query.length > 0) {
      this.isLoading = true;
      this.showError = false;
      let response;
      try {
        if (this.travellersSearchList.travellers && this.travellersSearchList.travellers.length) {
          let companyId = this.travellersSearchList.travellers[0].companyId;
          response = await HomeApi.findTravellerInCompany(
            query, companyId
          );
        } else {
          response = await HomeApi.findTraveller({
            query
          });
        }

        if (response && response.data) {
          this.options = [...response.data].slice(0, 100);
          this.options.forEach( option => option.isMainTraveller = false);
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showError = true;
        this.serverErrors = this.$handleErrors(error, true);
      }
    } else {
      this.options = [];
      this.isLoading = false;
    }
  }

  onTagRemove(remove, option) {
    remove(option);
    this.setFocus();
  }

  setFocus() {
    const travellerInput = this.$refs.travellerSearch.$el.getElementsByTagName('input')[0];
    if (travellerInput) {
      setTimeout(() => {
        travellerInput.focus();
      });
    }
  }

  initFocus() {
    this.canGoToSearch = false;
    this.options = [];
    this.setFocus();
  }

  @AnimatedIn()
  animatedIn() {
    this.setFocus();
  }
}
