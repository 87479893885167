






























































































import { Vue, Component, Watch } from 'vue-property-decorator';

import FiltersContentLoading from '../controls/FiltersContentLoading.vue';
import TextBoxFilter from '../controls/TextBoxFilter.vue';
import RangeFilter from '../controls/RangeFilter.vue';
import BoolFilter from '../controls/BoolFilter.vue';
import SliderFilter from '../controls/SliderFilter.vue';
import CategoriesFilter from '../controls/CategoriesFilter.vue';
import HotelSearchStore from './hotel-search.store';
import AccountStore from '@/store/account.store';
import { router } from '@/router';
import { Debounce } from '@/core/decorators/debounce.decorator';
import LayoutStore from '@/modules/layout/layout.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    CategoriesFilter,
    RangeFilter,
    BoolFilter,
    SliderFilter,
    TextBoxFilter,
    FiltersContentLoading,
  }
})
export default class HotelMapFilters extends Vue {
  toggled: boolean = true;

  get filters() {
    return HotelSearchStore.filters;
  }

  get filtersResponse() {
    return HotelSearchStore.filtersResponse;
  }

  get isDisabled() {
    return HotelSearchStore.selectingOffer;
  }

  get height() {
    return LayoutStore.slots.background.meta.height;
  }

  get styles() {
    return {
      height: this.height + 'px',
    };
  }

  get globalClass() {
    return {
      'hidden-mobile': this.$route.meta.mobile,
      'filters-loading': this.isDisabled
    };
  } 

  get sidebarToggled() {
    return !LayoutStore.sidebarHidden;
  }

  get canShowAdvancedOfferInfo() {
    return AccountStore.HasPermission('ShowAdvancedOfferInfo');
  }

  get currency() {
    return HotelSearchStore.currentCurrency;
  }

  filterVisible(filterMetadata) {
    const supplierFilterInvisible = filterMetadata.code === 'PROVIDER' && !this.canShowAdvancedOfferInfo;

    return !supplierFilterInvisible;
  }

  @Watch('sidebarToggled', { immediate: true })
    onToggled(value) {
      this.toggled = value;
  }

  @Watch('toggled')
    onToggledClick(value) {
      if (value !== this.sidebarToggled) {
        LayoutStore.toggleSidebar();
      }
  }

  updateFilter(filter) {
    HotelSearchStore.setFiltersChanging(true);
    HotelSearchStore.updateFilterValue(filter);

    const searchId = this.$route.params.searchId;

    HotelSearchStore.setFiltersRequestBody({
      filtersData: HotelSearchStore.filtersRequest,
      searchId,
    });
    this.forceFiltersUpdate();
  }

  getFilterData(filterCode) {
    const f = this.filters.find(f => f.code === filterCode);
    return f ? f.values : null;
  }

  getRangeFilterData(filterCode) {
    const f = this.filters.find(f => f.code === filterCode);
    return f ? f.data : null;
  }

  refreshFiltersComponents(refs, method) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      const data = method(element.code);
      if (!data) {
        return;
      }
      EventBus.$emit('refresh-filter-component', {
        code: element.code,
        data,
      });
    });
  }

  @Watch('filtersResponse')
  onChangeFilters() {
    this.refreshFiltersComponents(this.$refs.categoriesFilters, this.getFilterData);
    this.refreshFiltersComponents(this.$refs.rangeFilters, this.getRangeFilterData);
  }

  @Debounce({
    delay: 500,
  })
  async forceFiltersUpdate() {
    const searchId = this.$route.params.searchId;

    await HotelSearchStore.updateSearchFilters(searchId);
    await HotelSearchStore.getMapProperties(searchId);
  }
  
  showResults() {
    router.push({ name: 'hotel' });
  }

  clearFilters() {
    EventBus.$emit('clear-filters');
  }

}

