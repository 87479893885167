

























import { Vue, Component, Prop } from 'vue-property-decorator';

import BasketStore from '@/modules/basket/basket.store';

@Component({})
export default class SncfAncillary extends Vue {
  @Prop() offer!: any;
  @Prop() segmentId!: any;
  @Prop() profileId!: any;

  get basketRailSncfAncillaries() {
    return BasketStore.basketRailSncfAncillaries;
  }

  get isSelected() {
    return !!BasketStore.selectedRailAncillaries.find(offer => {
      return offer.profileId === this.profileId &&
        offer.segmentId === this.segmentId &&
        offer.product.code === this.offer.product.code;
    });
  }

  set isSelected(value) {
    // set true for all the same
    if (this.offer && this.offer.product.forAllPassengers) {
      this.basketRailSncfAncillaries.forEach(rail => {
        let selected = rail.ancillaries.find(an => an.product.code === this.offer.product.code);
        BasketStore.setRailAncillarySelected({
          offer: selected,
          segmentId: this.segmentId,
          value: true,
          profileId: rail.traveller.id,
        });
      });
    }

    BasketStore.setRailAncillarySelected({
      offer: this.offer,
      segmentId: this.segmentId,
      value,
      profileId: this.profileId,
    });
  }
}

