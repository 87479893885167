import Roles from '@/modules/settings/roles/Roles.vue';
import rolesList from './roles/roles-list.route';
import createRole from './roles/create-role.route';
import editRole from './roles/edit-role.route';
import { translate } from '@/i18n';

export default {
  path: 'roles',
  component: Roles,
  displayName: 'settings-sidebar.roles-permissions',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadRoles',
    icon: 'admin_panel_settings',
    parentName: 'settings-roles',
  },
  children: [
    rolesList,
    createRole,
    editRole,
  ],
};
