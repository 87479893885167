





























































import { Vue, Prop, Component } from 'vue-property-decorator';

import BasketStore from './basket.store';
import { translate } from '@/i18n';

@Component
export default class BasketApprovalConfirmationPopup extends Vue {
  @Prop() show!: boolean;

  isConfirmInProgress: boolean = false;

  get action() {
    return BasketStore.basketApprovalAction;
  }

  get decision() {
    return BasketStore.basketApprovalDecision;
  }

  get isHold() {
    return this.action && this.action === 'Hold';
  }

  get isDelayedTicketing() {
    return this.action && this.action === 'DelayedTicketing';
  }

  get isApproval() {
    return this.action && this.action === 'Approval';
  }
  
  get wasConfirmed() {
    return this.decision && this.decision === 'confirm';
  }

  async confirmHold() {
    this.isConfirmInProgress = true;
    if (this.wasConfirmed) {
      await BasketStore.confirmTrip();
    } else {
      await BasketStore.cancelTrip();
    }
    this.isConfirmInProgress = false;

    if (BasketStore.shouldShowUnconfirmedNotifications) {
      setTimeout(() => {
        BasketStore.setShowUnconfirmedNotificationsPopup(true);
      }, 200);
    }
    this.closePopup();
  }

  async confirmDelayedTicketing() {
    this.isConfirmInProgress = true;
    if (this.wasConfirmed) {
      await BasketStore.confirmDelayedTicketingTrip();
    } else {
      await BasketStore.cancelTrip();
    }
    this.isConfirmInProgress = false;

    if (BasketStore.shouldShowUnconfirmedNotifications) {
      setTimeout(() => {
        BasketStore.setShowUnconfirmedNotificationsPopup(true);
      }, 200);
    }
    this.closePopup();
  }

  async confirmApproval() {
    this.isConfirmInProgress = true;
    if (this.wasConfirmed) {
      await BasketStore.acceptApproval();
    } else {
      if (BasketStore.shouldApproverProvideReason) {
        setTimeout(() => {
          BasketStore.setShowRejectionReasonPopup(true);
        }, 50);
      } else {
        await BasketStore.rejectApproval();
      }
    }
    this.isConfirmInProgress = false;

    if (BasketStore.shouldShowUnconfirmedNotifications) {
      setTimeout(() => {
        BasketStore.setShowUnconfirmedNotificationsPopup(true);
      }, 200);
    }
    this.closePopup();
  }

  closePopup() {
    BasketStore.setBasketApprovalDecision('');
    BasketStore.setBasketApprovalAction('');
    BasketStore.setShowApprovalConfirmationPopup(false);
  }

  get title() {
    if (this.isApproval) {
      return translate('basket.approval-confirmation-title');
    } else if (this.isHold) {
      return translate('basket.hold-confirmation-title');
    } else if (this.isDelayedTicketing) {
      return translate('basket.delayed-ticketing-confirmation-title');
    }
    return false;
  }
}

