export class AuditRuleModel {
  id?: string = '';
  expensePolicyId: string = '';
  name: string = '';
  description: string = '';
  resultMessage: string = '';
  scope: string | any = '';
  processType: string = '';
  severity: string | any = '';
  isActive: boolean = true;
  expression: string = '';
  order: number | any = 0;
  actionIndicator: string | any = '';
  escalationLevel: string | any = '';

  public constructor(params?: any) {
    if (!params) {
      return;
    }
    if (params.id !== undefined) {
      this.id = params.id;
    }
    if (params.expensePolicyId !== undefined) {
      this.expensePolicyId = params.expensePolicyId;
    }
    if (params.name !== undefined) {
      this.name = params.name;
    }
    if (params.description !== undefined) {
      this.description = params.description;
    }
    if (params.resultMessage !== undefined) {
      this.resultMessage = params.resultMessage;
    }
    if (params.scope !== undefined) {
      this.scope = params.scope;
    }
    if (params.processType !== undefined) {
      this.processType = params.processType;
    }
    if (params.severity !== undefined) {
      this.severity = params.severity;
    }
    if (params.isActive !== undefined) {
      this.isActive = params.isActive;
    }
    if (params.expression !== undefined) {
      this.expression = params.expression;
    }
    if (params.order !== undefined) {
      this.order = params.order;
    }
    if (params.actionIndicator !== undefined) {
      this.actionIndicator = params.actionIndicator;
    }
    if (params.escalationLevel !== undefined) {
      this.escalationLevel = params.escalationLevel;
    }
  }
}