








import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiLabel extends Vue {
  @Prop({ default: '' }) type!: string;
  @Prop({ default: 'before' }) labelPosition!: string;
  @Prop({ default: false }) inline!: boolean;

  get classes() {
    if (!this.type || 'slider' === this.type) {
      return '';
    }
    return 'custom-control-label custom-control-color';
  }

  get wrapperClasses() {
    if (!this.type || 'slider' === this.type) {
      let result = '';
      if ('slider' === this.type) {
        result = 'slider-control-label';
      }
      if (this.inline) {
        result += ' custom-control-inline';
      }
      return result;
    }
    return 'custom-control custom-' + this.type + (this.inline ? ' custom-control-inline' : '');
  }

  get position() {
    if (!this.type || 'slider' === this.type) {
      return this.labelPosition;
    }
    return 'after';
  }
}
