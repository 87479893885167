









































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { AirSearchStateParams } from './air-search.params';
import { TravellersStateParams } from '../travellers.params';
import layoutStore from '@/modules/layout/layout.store';
import SearchTripTravellers from '@/modules/search/SearchTripTravellers.vue';
import AccountStore from '@/store/account.store';
import SearchStore from '@/modules/search/search.store';

@Component({
  components: {
    SearchTripTravellers,
  }
})
export default class AirSearchParamOnResults extends Vue {
  @Prop() travellersSearchList!: TravellersStateParams;
  @Prop() airSearchParameters!: AirSearchStateParams;

  nameFromFirstWith: boolean = false;
  nameToFirstWith: boolean = false;
  nameFromSecondWith: boolean = false;
  nameToSecondWith: boolean = false;

  get searchTravellersCount() {
    return this.travellersSearchList && this.travellersSearchList.travellers
      ? this.travellersSearchList.travellers.length
      : 0;
  }

  get fromName() {
    if (
      this.airSearchParameters &&
      this.airSearchParameters.from
    ) {
      return this.airSearchParameters.from.type === 'City' ? this.airSearchParameters.from.cityName : this.airSearchParameters.from.airportName;
    }

    return '';
  }

  get toName() {
    if (
      this.airSearchParameters &&
      this.airSearchParameters.to
    ) {
      return this.airSearchParameters.to.type === 'City' ? this.airSearchParameters.to.cityName : this.airSearchParameters.to.airportName;
    }

    return '';
  }

  get user() {
    return AccountStore.current;
  }

  get dateFormat() {
    if (this.user!.profile.displayLanguage === 'pl') {
      return 'D MMMM';
    }

    return 'Do MMMM';
  }

  get departureDate() {
    if (this.airSearchParameters) {
      return moment(
        this.airSearchParameters.departureDate,
        'YYYY-MM-DD'
      ).format(this.dateFormat);
    }

    return '';
  }

  get departureDay() {
    if (this.airSearchParameters) {
      return moment(
        this.airSearchParameters.departureDate,
        'YYYY-MM-DD'
      ).format('dddd');
    }

    return '';
  }

  get returnDate() {
    if (this.airSearchParameters) {
      return moment(
        this.airSearchParameters.returnDate,
        'YYYY-MM-DD'
      ).format(this.dateFormat);
    }

    return '';
  }

   get returnDay() {
    if (this.airSearchParameters) {
      return moment(
        this.airSearchParameters.returnDate,
        'YYYY-MM-DD'
      ).format('dddd');
    }

    return '';
  }

  get canCompareOffers() {
    return AccountStore.HasPermission('CanSendOfferProposal');
  }

  get skipTravellers() {
    return SearchStore.skipTravellers;
  }

  multiFromName(leg) {
    if (
      leg &&
      leg.from
    ) {
      return leg.from.type === 'City' ? leg.from.cityName : leg.from.airportName;
    }

    return '';
  }

  multiToName(leg) {
    if (
      leg &&
      leg.to
    ) {
      return leg.to.type === 'City' ? leg.to.cityName : leg.to.airportName;
    }

    return '';
  }

  multiDepartureDate(leg) {
    if (leg) {
      return moment(
        leg.departureDate,
        'YYYY-MM-DD'
      ).format('Do MMMM');
    }

    return '';
  }

   multiDepartureDay(leg) {
    if (leg) {
      return moment(
        leg.departureDate,
        'YYYY-MM-DD'
      ).format('dddd');
    }

    return '';
  }

  checkItemWithMulti() {
    this.airSearchParameters.legs.forEach((leg, index) => {
      const activeClass = 'd-flex';
      const inActiveClass = 'd-none';

      let fromRef = this.$refs['nameFromFirstWith' + index] as HTMLElement;
      let toRef = this.$refs['nameToFirstWith' + index] as HTMLElement;

      if (this.$refs['shadowNameFrom' + index] && (this.$refs['shadowNameFrom' + index] as any).classList) {
        if (fromRef && fromRef.clientWidth > 180) {
          (this.$refs['shadowNameFrom' + index] as any).classList.value = [activeClass];
        } else {
          (this.$refs['shadowNameFrom' + index] as any).classList.value = [inActiveClass];
        }
      }

      if (this.$refs['shadowNameFrom' + index] && (this.$refs['shadowNameTo' + index] as any).classList) {
        if (toRef && toRef.clientWidth > 180) {
          (this.$refs['shadowNameTo' + index] as any).classList.value = [activeClass];
        } else {
          (this.$refs['shadowNameTo' + index] as any).classList.value = [inActiveClass];
        }
      }
    });
  }

  checkItemWidth() {
    const FromFirst = this.$refs.nameFromFirstWith as HTMLElement;
    const ToFirst = this.$refs.nameToFirstWith as HTMLElement;
    const FromSecond = this.$refs.nameFromSecondWith as HTMLElement;
    const ToSecond = this.$refs.nameToSecondWith as HTMLElement;

    if (FromFirst && FromFirst.clientWidth > 180) {
      this.nameFromFirstWith = true;
    } else {
      this.nameFromFirstWith = false;
    }

    if (ToFirst && ToFirst.clientWidth > 180) {
      this.nameToFirstWith = true;
    } else {
      this.nameToFirstWith = false;
    }

    if (FromSecond && FromSecond.clientWidth > 180) {
      this.nameFromSecondWith = true;
    } else {
      this.nameFromSecondWith = false;
    }

    if (ToSecond && ToSecond.clientWidth > 180) {
      this.nameToSecondWith = true;
    } else {
      this.nameToSecondWith = false;
    }
  }

  @Watch('airSearchParameters', { deep: true, immediate: true})
  onPlaceShow() {
    this.$nextTick(() => {
      if (this.airSearchParameters.legs && this.airSearchParameters.legs.length) {
        this.checkItemWithMulti();
      } else {
        this.checkItemWidth();
      }
    });
  }

  toggleSidebar() {
    layoutStore.toggleSidebar();
  }

  @Emit()
  criteria() {}

  @Emit()
  travellers() {}
}
