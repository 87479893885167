


















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { IProfileLoyaltiesCard } from '@/api/profile/loyalties.model';
import LoyaltyPopup from '@/modules/profile/loyalty-programs/LoyaltyPopup.vue';
import LoyaltyRemovePopup from '@/modules/profile/loyalty-programs/LoyaltyRemovePopup.vue';
import { BasketApi } from '@/api/trip/basket.api';
import BasketStore from './basket.store';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    LoyaltyPopup,
    LoyaltyRemovePopup,
  }
})
export default class BasketTravellerLoyaltyPrograms extends Vue {
  @Prop({ default: '' }) profileId!: string;
  $v;
  showLoyaltyCardPopup: boolean = false;
  deleteLoyaltyCardPopup: boolean = false;
  editingCard: any = null;
  formPending: boolean = false;
  serverErrors: any[] = [];
  availableLoyalties: IProfileLoyaltiesCard[] = [];
  showLoyalties: boolean = true;
  areAllLoyaltiesValidForTrip: boolean = true;


  get availableDocumentByTypes() {
    return _.uniqBy(this.availableLoyalties, 'loyalty.serviceType');
  }

  get someAvailableLoyaltyPrograms() {
    return this.availableLoyalties.some((item: any) => {
      return item.loyalty && item.loyalty.loyaltyType === 'Loyalty';
    });
  }

  get basketId() {
    return BasketStore.basketId;
  }

  showAddNewLoyaltyCardPopup() {
    this.editingCard = null;
    this.showLoyaltyCardPopup = true;
  }

  filterLoyaltiesByType(type) {
    return this.availableLoyalties.filter((doc: any) => doc.loyalty.serviceType === type.loyalty.serviceType);
  }

  async loadProfileLoyalties() {
    try {
      let result = await BasketApi.getBasketTravellerLoyalties(this.basketId);

      if (result && result.data) {
        const travellerLoyalties = result.data.find(item => item.profileId === this.profileId);
        if (travellerLoyalties) {
          this.availableLoyalties = travellerLoyalties.loyalties;
          this.areAllLoyaltiesValidForTrip = travellerLoyalties.areAllLoyaltiesValidForTrip;
        }

      }

      if (this.availableLoyalties.length) {
        setTimeout(() => {
          EventBus.$emit('open-traveller-loyalties', this.profileId);
        });
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      EventBus.$emit('travellers-loyalty-programs-loaded', this.profileId);
    }
  }

  editLoyalties(card) {
    this.editingCard = card;
    this.showLoyaltyCardPopup = true;
  }

  removeLoyalties(card) {
    this.editingCard = card;
    this.deleteLoyaltyCardPopup = true;
  }

  async onReceive() {
    await this.loadProfileLoyalties();
  }

  @Watch('someAvailableLoyaltyPrograms')
  onAvailableLoyaltiesChange(value) {
    if (!value) {
      EventBus.$emit('traveller-loyalties-change', { id: this.profileId, isEmpty: true });
    } else {
      EventBus.$emit('traveller-loyalties-change', { id: this.profileId, isEmpty: false });
    }
  }

  async created() {
    await this.onReceive();
    EventBus.$on('reload-loyalty', this.onReceive);
    EventBus.$emit('traveller-loyalties-change', { id: this.profileId, isEmpty: !this.someAvailableLoyaltyPrograms });
  }

  beforeDestroy() {
    EventBus.$off('reload-loyalty', this.onReceive);
  }
}

