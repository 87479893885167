





























import { Vue, Prop, Component } from 'vue-property-decorator';

import { userFullName } from '@/core/user-full-name';
import EventBus from '@/services/event-handler';
import BasketStore from './basket.store';
import searchConst from '@/const/search.const';
import { translate } from '@/i18n';
import BasketTravellerMissingDocumentDocuments from './BasketTravellerMissingDocumentDocuments.vue';

@Component({
  components: {
    BasketTravellerMissingDocumentDocuments,
  },
})
export default class BasketTravellerMissingDocumentForm extends Vue {
  @Prop({}) traveller: any;
  @Prop({}) index: any;



  get documentsObject() {
    return BasketStore.basketTravellersDocuments
      .find(item => item.profileId === this.profileId);
  }

  get profileId() {
    return this.traveller.id;
  }



  userFullName(user) {
    return userFullName(user);
  }

  getCodeTranslation(code) {
    const value = searchConst.guestTravellerOptions.find(item => item.code === code);

    if (!value) {
      return '';
    }

    return translate(value.label);
  }
}
