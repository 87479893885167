











import { Vue, Component, Prop } from 'vue-property-decorator';

import consts from '@/const/rail-seat-map.const';

@Component({})
export default class TrainCabinBulkhead extends Vue {
  @Prop({ default: null }) item!: any | null;

  get x() {
    if (this.item.x === 1) {
      return consts.margin + (this.item.x - .5) * consts.colWidth + consts.thickness;
    }
    if (this.item.width === 1 && ((this.item.angle / 90) % 2 === 0)) {
      return consts.margin + (this.item.x + .5) * consts.colWidth - consts.thickness;
    }

    return consts.margin + (this.item.x) * consts.colWidth + consts.thickness;
  }

  get y() {
    if (this.item.height === 1 && (this.item.angle / 90) % 2 === 1) {
      return consts.margin + (this.item.y + .5) * consts.rowHeight - consts.thickness;
    }
    return consts.margin + (this.item.y) * consts.rowHeight + consts.thickness;
  }

  get width() {
    if (this.item.x === 1 || (this.item.x + this.item.width === this.item.allColumns - 1)) {
      return (this.item.width + .5) * consts.colWidth - 2 * consts.thickness;
    }
    if (this.item.width === 1 && ((this.item.angle / 90) % 2 === 0)) {
      return 2 * consts.thickness;
    }
    return this.item.width * consts.colWidth - 2 * consts.thickness;
  }

  get height() {
    if (this.item.height === 1 && (this.item.angle / 90) % 2 === 1) {
      return 2 * consts.thickness;
    }
    return this.item.height * consts.rowHeight - 2 * consts.thickness;
  }
}
