import HotelSearchShortParams from '@/modules/search/hotel/HotelSearchShortParams.vue';
import HotelFiltersSubintro from '@/modules/search/hotel/HotelFiltersSubintro.vue';
import HotelResultsFilters from '@/modules/search/hotel/HotelResultsFilters.vue';
import { AnimationName } from '@/modules/layout/layout.model';
import SearchMessages from '@/modules/search/SearchMessages.vue';

export default {
  path: '/hotel-results-filters/:searchId',
  name: 'hotelFilters',
  components: {
    default: HotelResultsFilters,
    intro: HotelSearchShortParams,
    subintro: HotelFiltersSubintro,
    messages: SearchMessages,
  },
  meta: {
    introClass: 'intro-hotel-results',
    permission: 'SearchOffers',
    mobile: true,
    transitions: {
      intro: {
        search: AnimationName.DOWN,
        home: AnimationName.LEFT,
        hotelDetails: AnimationName.DOWN,
      },
      subintro: {
        hotel: AnimationName.UP,
      },
    }
  }
};
