import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import { AirSabreRefundApi } from '@/api/trip/air-sabre-refund.api';
import { RefundBasketItemApi } from '@/api/trip/basket-item-refund.api';
import { OffersApi } from '@/api/air-engine/offers.api';
import BasketStore from './basket.store';
import EventBus from '@/services/event-handler';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'BasketCancellationStore'
})
class BasketCancellationStore extends VuexModule {
  processErrors: string[] = [];
  showError: boolean = false;
  sabreRefundProcessScreen: any = null;
  loading: boolean = false;
  loadingCoupons: boolean = true;
  loadingDetails: boolean = false;
  sabreRefundProcessScreenLoaded: boolean = false;
  processing: boolean = false;
  couponsLoaded: boolean = false;
  couponsStatus: any[] = [];
  isSabreRefundChoose: boolean = false;
  lufthansaCancelationDetails: any = null;
  lufthansaRefundDetailsLoaded: boolean = false;
  britishAirwaysCancelationDetails: any = null;
  britishAirwaysRefundDetailsLoaded: boolean = false;
  airDetailsInfo: any = null;
  processWarning: any = null;
  forceCancelRefund: boolean = false;
  refundError: string[] = [];

  get basketItemId() {
    return BasketStore.basketItemId;
  }

  @Mutation
  setProcessErrors(error) {
    this.processErrors = $handleErrors(error, true);
  }

  @Mutation
  setProcessWarning(error) {
    this.processWarning = error;
  }

  @Mutation
  setCustomErrors(error) {
    this.processErrors = error;
  }

  @Mutation
  setRefundError(error) {
    this.refundError = error;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setSabreRefundProcessScreen(data) {
    if (data && data.lines) {
      data.lines.forEach(line => {
        line.fields.forEach(field => {
          if (field.type === 'Input') {
            field.text = field.text.trim();
          }
        });
      });
    }

    this.sabreRefundProcessScreen = data;
  }

  @Mutation
  setLufthansaCancelationDetails(data) {
    this.lufthansaCancelationDetails = data;
  }

  @Mutation
  setBritishAirwaysCancelationDetails(data) {
    this.britishAirwaysCancelationDetails = data;
  }

  @Mutation
  loader(data) {
    this.loading = data;
  }

  @Mutation
  setLoadingCoupons(data) {
    this.loadingCoupons = data;
  }

  @Mutation
  setLoadingDetails(data) {
    this.loadingDetails = data;
  }

  @Mutation
  setCouponsLoaded(data) {
    this.couponsLoaded = data;
  }

  @Mutation
  setSabreRefundProcessScreenLoaded(data) {
    this.sabreRefundProcessScreenLoaded = data;
  }

  @Mutation
  setLufthansaRefundDetailsLoaded(data) {
    this.lufthansaRefundDetailsLoaded = data;
  }

  @Mutation
  setBritishAirwaysRefundDetailsLoaded(data) {
    this.britishAirwaysRefundDetailsLoaded = data;
  }

  @Mutation
  setProcessing(data) {
    this.processing = data;
  }

  @Mutation
  setCouponsStatus(data) {
    this.couponsStatus = data;
  }

  @Mutation
  setIsSabreRefundChoose(data) {
    this.isSabreRefundChoose = data;
  }

  @Mutation
  setAirDetailsInfo(data) {
    this.airDetailsInfo = data;
  }

  @Mutation
  setForceCancelRefund(value) {
    this.forceCancelRefund = value;
  }

  @Action
  async getItemCoupons(basketItemId) {
    this.setProcessErrors([]);
    this.setShowError(false);
    this.setLoadingCoupons(true);
    this.setCouponsLoaded(false);
    let response;
    try {
      response = await RefundBasketItemApi.getItemCouponsStatus(basketItemId);
    } catch (error) {
      this.setProcessErrors(error);
      this.setShowError(true);
    } finally {
      if (response && response.data) {
        this.setCouponsStatus(response.data);
        this.setCouponsLoaded(true);
      }
      this.setLoadingCoupons(false);
    }
  }

  @Action
  async getAirDetails(itemId) {
    this.setLoadingCoupons(true);
    try {
      const response = await OffersApi.getOffer(itemId);
      if (response && response.data) {
        this.setAirDetailsInfo(response.data);
      }
    } catch (error) {
      this.setProcessErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoadingCoupons(false);
    }
  }

  @Action
  async getSabreCurrentRefundProcess(itemId) {
    this.setProcessErrors([]);
    this.setProcessWarning(null);
    this.setCustomErrors([]);
    this.setShowError(false);
    this.setForceCancelRefund(false);
    this.setSabreRefundProcessScreenLoaded(false);
    try {
      const response = await AirSabreRefundApi.getSabreCurrentRefundProcess(itemId);
      if (response && response.data) {
        this.setSabreRefundProcessScreen(response.data);
      }
      this.setProcessing(false);
      this.setSabreRefundProcessScreenLoaded(true);
    } catch (error) {
      this.setProcessErrors(error);
      this.setProcessing(false);
      this.setShowError(true);
    }
  }

  @Action
  async initSabreRefundProcess(data) {
    this.setProcessErrors([]);
    this.setProcessWarning(null);
    this.setShowError(false);
    this.setProcessing(true);
    try {
      const initResponse = await AirSabreRefundApi.initSabreRefundProcess(data.itemId, data.params);
      if (initResponse && initResponse.status === 204) {
        EventBus.$emit('refresh-basket-status');
      }
    } catch (error) {
      this.setProcessing(false);
      this.setProcessErrors(error);
      this.setShowError(true);
    }
  }

  @Action
  forceCancelRefundAction() {
    this.setSabreRefundProcessScreen(null);
    this.setSabreRefundProcessScreenLoaded(false);
    this.setIsSabreRefundChoose(false);
    EventBus.$emit('refresh-basket-status');
  }

  @Action
  async updateSabreCurrentRefundProcess(data) {
    this.setProcessErrors([]);
    this.setCustomErrors([]);
    this.setRefundError([]);
    this.setProcessWarning(null);
    this.setShowError(false);
    this.setProcessing(true);
    try {
      const response = await AirSabreRefundApi.updateSabreCurrentRefundProcess(data.itemId, data.params);
      if (response && response.status === 204) {
        this.setSabreRefundProcessScreen(null);
        this.setSabreRefundProcessScreenLoaded(false);
        this.setIsSabreRefundChoose(false);
        EventBus.$emit('refresh-basket-status');
      }
    } catch (error) {
      if (error.response && error.response.status === 418) {
        if (error.response.data.error.code === 'OPERATION_NOT_ALLOWED' || error.response.data.error.code === 'ONLY_TICKETED_ITEM_CAN_START_REFUND_FLOW') {
          this.setRefundError(error.response.data.error.devDetails.message);
          this.setSabreRefundProcessScreen(null);
          this.setForceCancelRefund(true);
        }
        let warning = error.response.data.error.details;
        if (warning && warning.length) {
          this.setProcessWarning(warning[0].message);
        }
      } else {
        this.setProcessErrors(error);
      }

      this.setProcessing(false);
      this.setShowError(true);
    }
  }

  @Action
  async getOfferCancelationConditions(basketItemId) {
    this.setProcessErrors([]);
    this.setProcessWarning(null);
    this.setShowError(false);
    this.setLoadingDetails(true);
    this.setLufthansaRefundDetailsLoaded(false);
    let initResponse;
    try {
      initResponse = await RefundBasketItemApi.getOfferCancelationConditions(basketItemId);
      
    } catch (error) {
      this.setLoadingDetails(false);
      this.setProcessErrors(error);
      this.setShowError(true);
    } finally {
      if (initResponse && initResponse.data) {
        this.setLufthansaCancelationDetails(initResponse.data);
        this.setLufthansaRefundDetailsLoaded(true);
      }
      this.setLoadingDetails(false);
    }
  }

  @Action
  async getBritishAirwaysOfferCancelationConditions(basketItemId) {
    this.setProcessErrors([]);
    this.setProcessWarning(null);
    this.setShowError(false);
    this.setLoadingDetails(true);
    this.setBritishAirwaysRefundDetailsLoaded(false);
    try {
      const initResponse = await RefundBasketItemApi.getOfferCancelationConditions(basketItemId);
      if (initResponse && initResponse.data) {
        this.setBritishAirwaysCancelationDetails(initResponse.data);
        this.setBritishAirwaysRefundDetailsLoaded(true);
      }
    } catch (error) {
      this.setProcessErrors(error);
      this.setShowError(true);
    } finally {
      this.setLoadingDetails(false);
    }
  }

}

export default getModule(BasketCancellationStore);