import { translate } from '@/i18n';
import Projects from '@/modules/settings/projects-configuration/Projects.vue';
import ProjectsConfigurations from './projects-configurations.route';
import ProjectsConfiguration from './projects-configuration.route';
import ProjectDefinition from './project-definition.route';

export default {
  path: 'projects',
  component: Projects,
  displayName: 'settings-sidebar.projects',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadProjects',
    icon: 'topic',
    breadCrumb: `Projects' configurations' list`,
    parentName: 'projects',
  },
  children: [
    ProjectsConfigurations,
    ProjectsConfiguration,
    ProjectDefinition,
  ],
};