import CurrencyExchangeConfigurations from '@/modules/settings/currency-exchange/CurrencyExchangeConfigurations.vue';

export default {
  path: '',
  name: 'currency-exchange-configurations',
  component: CurrencyExchangeConfigurations,
  displayName: 'Currency Exchange Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditCurrencyConfiguration',
    depthCategory: 'currency-exchange-configurations',
    parentName: 'currency-exchange-configurations',
    depth: 1,
  }
};