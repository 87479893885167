





































































































































































































































































































import { Component } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import { BaseTable, GetItemsResult } from '@/core/base-table.class';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import { OfflineRequestsTableParams } from './offline-requests-table.params';
import { OfflineRequestApi } from '@/api/trip/offline-request.api';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import {
  OfflineRequestSearch,
  OfflineRequestStatus,
  OfflineRequestType,
  SearchOfflineRequestsMessage,
} from '@/api/trip/offline-request.model';
import {
  ProfileModel,
  TravellerModel,
} from '@/api/profile/profile.model';
import { CompanyModel } from '@/api/profile/company.model';
import accountStore from '@/store/account.store';
import OfflineRequestsPopup from '@/modules/offline/OfflineRequestsPopup.vue';
import OfflineRequestStatuses from '@/modules/offline/OfflineRequestStatus.vue';
import { translate } from '@/i18n';

@Component({
  components: {
    OfflineRequestsPopup,
    OfflineRequestStatuses,
  }
})
export default class OfflineRequestsTable extends BaseTable<OfflineRequestSearch> {
  useQueryParams: boolean = true;
  params: OfflineRequestsTableParams = new OfflineRequestsTableParams({});
  fields = {
    requestorName: {
      label: translate('offline.requestor-name'),
      class: 'offline-requests-table__column--requestor-name',
    },
    title: {
      label: translate('offline.title'),
      class: 'offline-requests-table__column--title',
    },
    assignedAgent: {
      label: translate('offline.associated-agent'),
      class: 'offline-requests-table__column--assigned-agent',
    },
    requestDate: {
      label: translate('offline.request-date'),
      class: 'offline-requests-table__column--request-date',
    },
    status: {
      label: translate('offline.status'),
      class: 'offline-requests-table__column--status',
    },
    tripCode: {
      label: translate('offline.trip-code'),
      class: 'offline-requests-table__column--trip-code',
    },
    actionsColumn: {
      label: '',
      class: 'offline-requests-table__column--actions',
    },
  };
  statuses = [
    {
      label: translate('offline.new'),
      value: OfflineRequestStatus.New,
    },
    {
      label: translate('offline.in-progress'),
      value: OfflineRequestStatus.InProgress,
    },
    {
      label: translate('offline.closed'),
      value: OfflineRequestStatus.Closed,
    },
  ];
  types = [
    {
      label: translate('offline.new-booking'),
      value: OfflineRequestType.NewBooking,
    },
    {
      label: translate('offline.modification'),
      value: OfflineRequestType.Modification,
    },
    {
      label: translate('offline.cancellation'),
      value: OfflineRequestType.Cancellation,
    },
    {
      label: translate('offline.additional-service'),
      value: OfflineRequestType.AdditionalService,
    },
  ];
  agents: TravellerModel[] = [];
  agentsLoading: boolean = false;
  requestors: TravellerModel[] = [];
  requestorsLoading: boolean = false;
  companies: CompanyModel[] = [];
  companiesLoading: boolean = false;
  requestDetailsPopupVisible: boolean = false;
  currentRequestId: string = '';
  errors: any[] = [];



  userFullName(user) {
    return userFullName(user);
  }

  get requestDateValue() {
    return {
      start: this.params.requestDate.from ? moment(this.params.requestDate.from).toDate() : null,
      end: this.params.requestDate.to ? moment(this.params.requestDate.to).toDate() : null,
    };
  }

  set requestDateValue(val) {
    if (val && val.start && val.end) {
      this.params.requestDate.from = moment(val.start).format('YYYY-MM-DD');
      this.params.requestDate.to = moment(val.end).format('YYYY-MM-DD');
    } else {
      this.params.requestDate.from = null;
      this.params.requestDate.to = null;
    }
  }

  get userId(): string {
    if (!accountStore.CurrentUser) {
      return '';
    }
    return accountStore.CurrentUser.profile.id;
  }

  async fillParameters(query) {
    if (query.requestStatuses) {
      let statuses = query.requestStatuses;
      if (!(statuses instanceof Array)) {
        statuses = [statuses];
      }
      this.params.requestStatuses = statuses
        .map(value => this.statuses.find(item => item.value === value));
    } else {
      this.params.requestStatuses = [];
    }
    
    if (query.requestTypes) {
      let types = query.requestTypes;
      if (!(types instanceof Array)) {
        types = [types];
      }
      this.params.requestTypes = types
        .map(value => this.types.find(item => item.value === value));
    } else {
      this.params.requestTypes = [];
    }
    
    this.params.requestDate = {
      from: query.requestDate,
      to: query.requestDateTo,
    };
    this.params.associatedTripCode = query.associatedTripCode;
    if (query.agents) {
      let a: string[] | string = query.agents;
      if (!(a instanceof Array)) {
        a = [a];
      }
      const result: AxiosResponse<ProfileModel>[] = await Promise.all(
        a.map(id => ProfileApi.getById(id))
      );

      this.params.agents = result.map(res => res.data);
    } else {
      this.params.agents = [];
    }
    if (query.requestors) {
      let a: string[] | string = query.requestors;
      if (!(a instanceof Array)) {
        a = [a];
      }
      const result: AxiosResponse<ProfileModel>[] = await Promise.all(
        a.map(id => ProfileApi.getById(id))
      );

      this.params.requestors = result.map(res => res.data);
    } else {
      this.params.requestors = [];
    }
    if (query.companies) {
      let a: string[] | string = query.companies;
      if (!(a instanceof Array)) {
        a = [a];
      }
      const result: AxiosResponse<CompanyModel>[] = await Promise.all(
        a.map(id => ProfileCompanyApi.getById(id, ''))
      );

      this.params.companies = result.map(res => res.data);
    } else {
      this.params.companies = [];
    }
  }

  requestorName(item) {
    return {
      firstName: item.requestorName.split(' ').join(''),
      lastName: '',
    };
  }

  fillQuery(query) {
    query.requestStatuses = this.params.requestStatuses.map(item => item.value);
    query.requestTypes = this.params.requestTypes.map(item => item.value);
    query.requestDate = this.params.requestDate.from;
    query.requestDateTo = this.params.requestDate.to;
    query.associatedTripCode = this.params.associatedTripCode;
    query.agents = this.params.agents.map(item => item.id);
    query.requestors = this.params.requestors.map(item => item.id);
    query.companies = this.params.companies.map(item => item.id);
  }

  async getItems(params: OfflineRequestsTableParams): Promise<GetItemsResult<OfflineRequestSearch>> {
    let searchParams: SearchOfflineRequestsMessage = {
      requestStatuses: params.requestStatuses.map(item => item.value),
      requestorsNames: params.requestors.map(item => item.firstName + ' ' + item.lastName),
      agentsNames: params.agents.map(item => item.firstName + ' ' + item.lastName),
      companies: params.companies.map(item => item.name),
      associatedTripCode: params.associatedTripCode,
      requestTypes: params.requestTypes.map(item => item.value),
      requestDate: {
        from: params.requestDate.from ?  moment(params.requestDate.from).format('YYYY-MM-DD') : null,
        to: params.requestDate.to ?  moment(params.requestDate.to).format('YYYY-MM-DD') : null,
      },
    };

    try {
      const result = await OfflineRequestApi.search({
        start: params.start,
        size: params.size,
        userId: this.userId,
      } as any, searchParams);

      return {
        page: {
          pageSize: params.size,
          pageNo: params.start,
          moreResultsAvailable: result.data.isMoreOfflineRequestsAvailable,
        },
        results: result.data.offlineRequests,
      };
    } catch (error) {
      this.errors = this.$handleErrors(error);
      throw(error);
    }
  }

  action(item) {
    this.currentRequestId = item.id;
    this.requestDetailsPopupVisible = true;
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'agentsLoading',
  })
  async loadAgents(query) {
    if (query.length > 0) {
      this.agentsLoading = true;
      const result = await ProfileApi.searchTravellers(query, false, false);
      if (result && result.data) {
        this.agents = result.data;
      } else {
        this.agents = [];
      }
      this.agentsLoading = false;
    } else {
      this.agentsLoading = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'requestorsLoading',
  })
  async loadRequestors(query) {
    if (query.length > 0) {
      this.requestorsLoading = true;
      const result = await ProfileApi.searchTravellers(query, false, false);
      if (result && result.data) {
        this.requestors = result.data;
      } else {
        this.requestors = [];
      }
      this.requestorsLoading = false;
    } else {
      this.requestorsLoading = false;
    }
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'companiesLoading',
  })
  async loadCompanies(query) {
    if (query.length > 0) {
      this.companiesLoading = true;
      const result = await ProfileCompanyApi.autocompleteCompanies(query);
      if (result && result.data) {
        this.companies = result.data;
      } else {
        this.companies = [];
      }
      this.companiesLoading = false;
    } else {
      this.companiesLoading = false;
    }
  }
}

