

























































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import _ from 'lodash';

import { userFullName } from '@/core/user-full-name';
import { translate } from '@/i18n';
import SeatMapConst from '@/const/seat-map.const';
import priceModel from '@/const/price.const';
import SearchStore from '@/modules/search/search.store';
import BasketStore from '@/modules/basket/basket.store';
import { AirSeats } from '@/modules/search/air/air-seats.model';
import FlightTimelineDetails from '@/modules/search/air/FlightTimelineDetails.vue';
import BasketFlightPart from '@/modules/basket/BasketFlightPart.vue';
import BasketItem from '@/modules/basket/BasketItem.vue';
import SeatPassanger from '@/modules/basket/seatmap/SeatPassanger.vue';
import AirSeatMapLegend from '@/modules/basket/seatmap/AirSeatMapLegend.vue';
import AirCabin from '@/modules/basket/seatmap/AirCabin.vue';
import EventBus from '@/services/event-handler';
import BasketItemBusinessErrors from '@/modules/basket/BasketItemBusinessErrors.vue';

@Component({
  components: {
    BasketItem,
    BasketFlightPart,
    FlightTimelineDetails,
    AirSeatMapLegend,
    SeatPassanger,
    AirCabin,
    BasketItemBusinessErrors,
  },
  filters: {
    flightTimelineFilter(val) {
      const momentDeparture = moment(new Date(val.departure).toISOString());
      let momentArrival = moment(new Date(val.arrival).toISOString());
      let result = momentDeparture.format('ddd, Do MMM YYYY | HH:mm');

      if (momentArrival.diff(momentDeparture, 'days') === 0) {
        result += ' - ' + momentArrival.format('HH:mm');
      } else {
        result += ' - ' + momentArrival.format('HH:mm | ddd, Do MMM YYYY');
      }
      return result;
    },
    durationFilter(val) {
      const minutes = val % 60;
      const hours = (val - minutes) / 60;
      let result = hours + ':';
      if (hours < 10) {
        result = '0' + result;
      }
      if (minutes < 10) {
        result += '0';
      }
      result += minutes;
      return result;
    },
    timeConvert(n) {
      let num = n;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + 'h' + rminutes + 'm';
    },
  },
})
export default class AirAirFranceExchangeOfferSeats extends Vue {
  @Prop() offer!: any;
  @Prop() seatMap!: any;

  consts = SeatMapConst;

  deckPicked: any = null;
  travellerPicked: any = null;
  flightPicked: any = null;
  mapPicked: any = null;
  showTooltip: boolean = false;
  tooltipInfo: any = {};

  isDragging: boolean = false;
  dragX: number = 0;
  dragOffset: number = 0;
  destX: number = 0;
  lastSpeed: number = 0;
  scrollTimeout: number = 0;
  lastTime: number = Date.now();
  curTime: number = 0;
  touchStartTime: number = 0;
  scrollInterval = 30;
  isScrolling: boolean = false;
  curX: number = 0;

  tooltipStyles() {
    if (!this.showTooltip) {
      return {};
    }
    return {
      top: this.tooltipInfo.offsetY + 'px',
      left: this.tooltipInfo.offsetX + 'px',
    };
  }

  get margin() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileMargin;
    } else {
      return SeatMapConst.margin;
    }
  }

  get rowHeight() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileRowHeight;
    } else {
      return SeatMapConst.rowHeight;
    }
  }

  get colWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileColWidth;
    } else {
      return SeatMapConst.colWidth;
    }
  }

  get extendedColWidth() {
    if (window.innerWidth <= 1024) { 
      return SeatMapConst.mobileExtendedColWidth;
    } else {
      return SeatMapConst.extendedColWidth;
    }
  }

  get businessErrors() {
    return BasketStore.businessErrors;
  }

  get basket() {
    return BasketStore.basket;
  }

  get value() {
    return BasketStore.selectedSeats;
  }

  set value(val) {
    BasketStore.setSelectedSeats(val);
  }

  get decks() {
    let decks: any = [];
    if (!this.seatMap) {
      return [];
    }
    this.seatsOfTheDeck.forEach(seat => {
      if (!seat.decks) {
        return;
      }
      seat.decks.forEach((item, index) => {
        decks.push({
          ...item,
          name: 'deck' + index,
        });
      });
    });

    return decks;
  }

  get seatsOfTheDeck() {
    if (this.seatMap === null) {
      return [];
    }

    return this.seatMap.filter(item => {
      return item.flight.id === this.flightPicked.id;
    });
  }

  get travellers() {
    if (!SearchStore.getTravellersState) {
      return [];
    }
    return SearchStore.getTravellersState.travellers;
  }

  get availableTravellers() {
    if (!this.mapPicked) {
      return [];
    }

    return this.travellers.filter(traveller => {
      return this.mapPicked.availableSeatsForTravellers.find(id => id === traveller.id);
    });
  }

  get cabins() {
    return this.deckPicked.cabins;
  }

  get columns() {
    let columnOffsets: number[] = [];
    const mapped = (this.seatsOfTheDeck[0].decks || []).filter(deck => {
      return deck.location === this.deckPicked.location;
    });

    if (!mapped[0]) {
      return [];
    }

    mapped[0].cabins[0].columns.forEach((column, index) => {
      if (index === 0) {
        columnOffsets.push(3 * this.colWidth);
      } else if (column.column === 'A' && mapped[0].cabins[0].columns[index - 1].column === 'A') {
        columnOffsets.push(columnOffsets[index - 1] + this.extendedColWidth);
      } else {
        columnOffsets.push(columnOffsets[index - 1] + this.colWidth);
      }
    });
    return mapped[0].cabins[0].columns.map((item, index) => {
      return {
        ...item,
        offset: columnOffsets[index],
      };
    });
  }

  get seatsOfTheDeckMapped() {
    return (this.seatsOfTheDeck[0].decks || []).filter(deck => {
      return deck.location === this.deckPicked.location;
    });
  }

  get rowOffsets() {
    let rowOffsets: number[] = [];

    this.seatsOfTheDeckMapped[0].cabins.forEach((cabin, cabinIndex) => {
      cabin.rows.forEach((row, index) => {
        let current = 0;

        if (index > 0 || cabinIndex > 0) {
          current = rowOffsets[rowOffsets.length - 1];
        }

        if (row.isExitRow) {
          current += 2.5;
        } else {
          current += 1;
        }
        rowOffsets.push(current);
      });
    });

    return rowOffsets;
  }

  get rows() {
    return _.flatten((this.seatsOfTheDeckMapped[0] ? this.seatsOfTheDeckMapped[0].cabins : []).map(cabin => {
      return cabin.rows;
    })).map((row, index) => {
      return {
        ...row,
        offset: this.rowOffsets[index],
      };
    });
  }

  get maxWidth() {
    return this.mapWidth;
  }

  get mapWidth() {
    let cabin;

    this.deckPicked.cabins.forEach(item => {
      if (!cabin || cabin.columns.length < item.columns.length) {
        cabin = item;
      }
    });
    if (!cabin) {
      return 0;
    }
    const colNumber =  cabin.columns.length;
    
    return (colNumber + 4) * this.colWidth + 3 * (this.extendedColWidth - this.colWidth);
  }

  get mapHeight() {
    if (!this.rows.length) {
      return 20;
    }
    return (this.rows[this.rows.length - 1].offset + 3 + this.deckPicked.cabins.length * 1.5) * this.rowHeight;
  }

  get exitRows() {
    if (!this.rows) {
      return [];
    }
    return this.rows.filter(row => row.isExitRow);
  }

  get showSupportedCardsWarning() {
    if (!this.seatsOfTheDeck.length) {
      return false;
    }
    return !!(this.seatsOfTheDeck && this.seatsOfTheDeck[0].validPaymentCards && this.seatsOfTheDeck[0].validPaymentCards.length);
  }

  get mandatorySeatsErrors() {
    if (BasketStore.canContinueOnSeatsStep || !BasketStore.basketSeatsMapError) {
      return [];
    }

    return [{
      message: translate('air-seat-map.select-seats-for-passengers'),
    }];
  }

  getUnsupportedCardsString() {
    if (!this.seatsOfTheDeck.length || !this.seatsOfTheDeck[0].validPaymentCards || !this.seatsOfTheDeck[0].validPaymentCards.length) {
      return '';
    } else {
      const cardsList: any = [];
      this.seatsOfTheDeck[0].validPaymentCards.forEach((card: string) => {
        cardsList.push(card.split(/(?=[A-Z])/).join(' '));
      });
      return cardsList.join(', ');
    }
  }

  userFullName(user) {
    return userFullName(user);
  }

  travellerNumber(id) {
    return this.availableTravellers.findIndex(item => item.id === id) + 1;
  }

  seatOver({ result, $event, cabinIndex }) {
    this.seatMapOver({ result, $event, cabinIndex });
  }

  seatMapOver({ result, $event, cabinIndex }) {
    if (!result || this.isSeatSelected) {
      this.showTooltip = false;
      this.tooltipInfo = {};
      return;
    }

    this.tooltipInfo = {};

    const offer = result.seat.offers.find(item => item.profileId === this.travellerPicked.id);

    if (!offer) {
      this.tooltipInfo = {
        unavailableForTraveller: true,
        offsetX: $event.layerX - 110,
        offsetY: $event.layerY + 30,
        price: null,
      };
      this.showTooltip = true;
      this.$forceUpdate();
      return;
    }

    let offerPrice = offer.price;

    if (!offerPrice) {
      offerPrice = priceModel.priceModel;
    }

    this.tooltipInfo.price = offerPrice;
    this.tooltipInfo.offsetX = $event.layerX - 110;
    this.tooltipInfo.offsetY = $event.layerY + 30;

    this.$forceUpdate();

    this.showTooltip = true;
  }

  get isSeatSelected() {
    if (!this.mapPicked.selectedSeats) {
      return false;
    }

    return !!this.mapPicked.selectedSeats.find(seat => {
      return this.travellerPicked.id === seat.profileId;
    });
  }

  shouldAddSeat(travellerIndex, foundConflict) {
    if (-1 === travellerIndex && -1 === foundConflict) {
      return true;
    }
    return false;
  }

  isSeatExcludedFromSelection() {
    return this.isSeatSelected || this.mapPicked.isUnselectable;
  }

  clickSeat({ row, column }) {

    if (this.isSeatExcludedFromSelection()) {
      return;
    }

    const travellerId = this.travellerPicked.id;
    const flightId = this.flightPicked.id;

    let newValue: AirSeats = {
      ...this.value,
      seats: this.value.seats || [],
    };
    let travellerIndex = newValue.seats
      .findIndex(elem => travellerId === elem.travellerId && flightId === elem.flightId);
    let foundConflict = newValue.seats
      .findIndex(a => row === a.row && column === a.column && flightId === a.flightId);
    let shouldAdd = this.shouldAddSeat(travellerIndex, foundConflict);
    const seatRow = this.rows.find(r => r.rowNumber === row);
    const seat = seatRow.seats.find(s => s.column === column);
    let price = priceModel.priceModel;

    const offer =  seat.offers.find(o => o.profileId === travellerId);

    let offerItemId = offer.offerItemId;

    if (seat.offers) {
      seat.offers
        .filter(o => o.profileId === travellerId)
        .forEach(o => price = o.price);
    }

    const el = {
      travellerId,
      flightId,
      row,
      column,
      price,
      offerItemId
    };

    if (shouldAdd) {
      newValue.seats.push(el);
    } else if (-1 === travellerIndex && -1 < foundConflict) {
      newValue.seats.splice(foundConflict, 1, el);
    } else if (-1 < travellerIndex && travellerIndex === foundConflict) {
      newValue.seats.splice(travellerIndex, 1);

      if (window.innerWidth <= 1024) {
        this.showTooltip = false;
      }
    } else if (-1 < travellerIndex && -1 < foundConflict) {
      newValue.seats.splice(travellerIndex, 1);
      foundConflict = newValue.seats
        .findIndex(buttock => row === buttock.row && column === buttock.column);
      newValue.seats.splice(foundConflict, 1, el);

    } else {
      newValue.seats.splice(travellerIndex, 1, el);
    }
    
    this.value = newValue;    
  }

  passangerSeat(id) {
    if (!this.value) {
      return this.selectedPassengerSeat(id);
    }

    const item = this.value.seats
      .find(elem => 
        elem.flightId === this.flightPicked.id &&
        elem.travellerId === id
      );

    if (!item) {
      return this.selectedPassengerSeat(id);
    }

    return {
      ...item,
      definition: this.rows.filter(row => row.rowNumber === item.row)
        .map(row => row.seats.find(seat => seat.column === item.column))[0],
    };
  }

  selectedPassengerSeat(id) {
    if (!this.mapPicked || !this.mapPicked.selectedSeats) {
      return null;
    }
    let selectedSeat = this.mapPicked.selectedSeats.find(seat => {
      return id === seat.profileId;
    });

    if (selectedSeat) {
      selectedSeat.row = parseInt(selectedSeat.seatNumber.match(/(\d+)/)[0]);
      selectedSeat.column = selectedSeat.seatNumber.match(/\D+/)[0];
      
      const definition = this.rows.filter(row => row.rowNumber === selectedSeat.row)
            .map(row => row.seats.find(a => a.column === selectedSeat.column))[0];

      return {
        column: selectedSeat.column,
        row: selectedSeat.row,
        flightId: this.flightPicked.id,
        travellerId: id,
        price: selectedSeat.price,
        definition: definition
      };
    }

    return null;
  }

  passangerPrice(id) {
    const result = this.passangerSeat(id);

    if (!result) {
      return null;
    }

    const travellersOffer = result.definition && result.definition.offers ? result.definition.offers
      .find(item => item.profileId === id) : null;

    if (!travellersOffer || !travellersOffer.price) {
      return null;
    }

    return travellersOffer.price;
  }

  findSeat($event) {
    let seatClicked: boolean = false;
    let seatColumn: string = '';
    let seatRow: string = '';

    this.columns.forEach(column => {
      if (
        $event.layerX >= column.offset - this.colWidth / 2 &&
        $event.layerX <= column.offset + this.colWidth / 2 - this.margin / 2
      ) {
        seatColumn = column.column;
      }
    });
    this.rows.forEach((line, index) => {
      if (
        $event.layerY >= (line.offset + .7) * this.rowHeight - this.margin / 2 &&
        $event.layerY <= (line.offset + 1.7) * this.rowHeight - this.margin / 2 &&
        line.rowNumber !== null
      ) {
        seatRow = line.rowNumber;
      }
    });

    if (!seatRow || !seatColumn) {
      return;
    }
    // check seat
    const row = this.rows.find(x => x.rowNumber === seatRow);
    if (!row) {
      return;
    }
    const seat = row.seats.find(el => el.column === seatColumn);
    if (!seat || !seat.isFree || seat.noSeat) {
      return;
    }

    return {
      row: seatRow,
      column: seatColumn,
      seat,
    };
  }

  selectFlight(map, index) {
    this.mapPicked = map;
    this.flightPicked = map.flight;
    if (this.availableTravellers.length && this.travellerPicked) {
      const oldTravellerId = this.travellerPicked.id;
      const newTraveller = this.availableTravellers.find(traveller => traveller.id === oldTravellerId) || null;

      if (!newTraveller) {
        this.travellerPicked = this.availableTravellers[0];
      }
    } else if (this.travellerPicked) {
      this.travellerPicked = null;
    } else {
      this.travellerPicked = this.availableTravellers[0];
    }

    const flight = (this.$refs.flight as any[])[index];
    EventBus.$emit('EnsureScrolledElementVisible', flight);
  }

  touchStart(e) {
    if (Number.isNaN(e.touches[0].screenX)) {
      return;
    }

    const area = this.$refs.mobileAirSeatMap as HTMLElement;

    this.isDragging = true;
    this.dragOffset = area.scrollLeft;
    this.dragX = e.touches[0].screenX;
    this.touchStartTime = Date.now();
  }

  touchMove(e) {
    if (!this.isDragging) {
      return;
    }
    if (Date.now() - this.touchStartTime < 200) {
      return;
    }
    if (Number.isNaN(e.touches[0].screenX)) {
      return;
    }
    const curX = e.touches[0].screenX - this.dragX;
    this.scrollTo(this.dragOffset - curX);
  }

  touchEnd(e) {
    if (Date.now() - this.touchStartTime < 200) {
      this.isDragging = false;
      return;
    }
    const step = this.lastSpeed * 300 / Math.max(1, this.curTime);
    this.scrollTo(this.curX + step);
    this.isDragging = false;
  }

  scrollTo(x) {
    const now = Date.now();
    const area = this.$refs.mobileAirSeatMap as HTMLElement;

    this.lastSpeed = x - this.destX;
    this.curTime = now - this.lastTime;
    this.lastTime = now;
    this.destX = Math.max(Math.min(x, area.scrollWidth - area.offsetWidth), 0);

    if (this.isScrolling) {
      return;
    }
    this.isScrolling = true;
    clearInterval(this.scrollTimeout);
    this.scrollTimeout = setInterval(() => {
      this.scrollPositionNow(Math.round(this.curX + (this.destX - this.curX) * .1));

      if (Math.abs(this.destX - this.curX) < 1) {
        this.scrollPositionNow(this.destX);
        this.isScrolling = false;
        clearInterval(this.scrollTimeout);
      }
    }, this.scrollInterval);
  }

  scrollPositionNow(x) {
    this.curX = x;
    const area = this.$refs.mobileAirSeatMap as HTMLElement;
    if (!area) {
      return;
    }
    if (area.scrollTo) {
      area.scrollTo(x, 0);
    } else {
      area.scrollLeft = x;
    }
  }

  hideTooltip() {
    if (this.showTooltip) {
      setTimeout(() => {
        this.showTooltip = false;
      });
    }
  }

  @Watch('flightPicked')
  onFlightChange(value) {
    const decksWrapper = this.seatMap.find(seat => seat.flight.id === value.id);
    if (decksWrapper.decks) {
      this.deckPicked = decksWrapper.decks[0];
    } else {
      this.deckPicked = null;
    }
  }

  created() {
    this.mapPicked = this.seatMap[0];
    this.flightPicked = this.mapPicked && this.mapPicked.flight ? this.mapPicked.flight : null;
    this.deckPicked = this.seatMap && this.seatMap[0] && this.seatMap[0].decks ? this.seatMap[0].decks[0] : null;
    if (this.availableTravellers.length) {
      this.travellerPicked = this.availableTravellers[0];
    }
  }
}
