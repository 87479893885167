












































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { VclCode } from 'vue-content-loading';
import _ from 'lodash';

import AirSearchStore from '@/modules/search/air/air-search.store';
import FlightProposal from './FlightProposal.vue';
import EventBus from '@/services/event-handler';
import HeightTransition from '@/modules/layout/HeightTransition.vue';

@Component({
  components: {
    VclCode,
    FlightProposal,
    HeightTransition,
  },
})
export default class FlightProposalsDetails extends Vue {
  @Prop() offerData!: any;
  @Prop() toggled!: boolean;
  @Prop() showingInPopup!: boolean;
  @Prop() animating!: boolean;

  selectedClass: any = null;
  lastSelectedClass: any = null;
  selectedProposal: any = null;
  proposalsNumber: number = 0;
  proposalsVisible: number = 3;
  lastProposalsVisible: number = 3;
  showLessButton: boolean = false;
  preparing: boolean = false;
  animatingSwitch: boolean = false;
  animatingFinishedID: any = null;
  listHeight: number = 0;
  isAnimationReversed: boolean = false;

  get offer() {
    if (!this.showingInPopup) {
      let selectedProposal = this.offersProposalsList.find(proposal => proposal.id === this.offerData.id);
      return {
        ...this.offerData,
        offerProposals: selectedProposal,
        travelPolicy: selectedProposal ? selectedProposal.travelPolicy : this.offerData.travelPolicy,
        minPrice: selectedProposal ? selectedProposal.minPrice : this.offerData.minPrice,
      };
    }
    return {
      ...this.offerData,
      offerProposals: this.offerData.offerProposals,
    };
  }

  get currentClassIndex() {
    return this.uniqueClasses.findIndex(c => c === this.selectedClass);
  }

  get offersProposalsList() {
    return AirSearchStore.offersProposalsListRefresh;
  }

  get loadingProposals() {
    return this.offer!.loadingProposals || this.animating;
  }

  get renderShowMoreProposals() {
    return this.proposalsNumber && this.proposalsMap.length > this.proposalsVisible;
  }

  get searchModifyState() {
    return (-1 < [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) || AirSearchStore.modifyMode;
  }

  get visibleProposals() {
    return this.proposalsMap.slice(0, this.proposalsVisible);
  }

  get lastProposalsAll() {
    if (this.offer && this.offer.offerProposals && this.offer.offerProposals.selectedProposals && this.offer.offerProposals.selectedProposals.length && this.showingInPopup) {
      return this.offer.offerProposals.selectedProposals.filter(prop => prop.cabinClasses.includes(this.lastSelectedClass));
    } else if (this.offer && this.offer.offerProposals && this.offer.offerProposals.proposals) {
      return this.offer.offerProposals.proposals.filter(prop => prop.cabinClasses.includes(this.lastSelectedClass));
    }
    return [];
  }

  get lastProposals() {
    return this.lastProposalsAll.slice(0, this.lastProposalsVisible);
  }

  get classes() {
    return {
      'train-timeline-details__proposals-list--ready-to-enter': this.preparing,
      'train-timeline-details__proposals-list--entering': this.animatingSwitch,
      'train-timeline-details__proposals-list--reversed': this.isAnimationReversed,
    };
  }

  get lastClasses() {
    return {
      'train-timeline-details__proposals-list--ready-to-leave': this.preparing,
      'train-timeline-details__proposals-list--leaving': this.animatingSwitch,
      'train-timeline-details__proposals-list--reversed': this.isAnimationReversed,
    };
  }

  get wrapperClasses() {
    return {
      'train-timeline-details__proposals-list-wrapper--animating': this.preparing || this.animatingSwitch,
    };
  }

  get wrapperStyles() {
    if (this.preparing || this.animatingSwitch) {
      return {
        height: this.listHeight + 'px',
      };
    }
    return {};
  }

  get styles() {
    if (this.toggled && this.loadingProposals) {
      const proposalsWrapper = this.$refs.proposalsWrapper as HTMLElement;

      if (proposalsWrapper && proposalsWrapper.offsetHeight) {
        return {
          height: proposalsWrapper.offsetHeight + 'px',
        };
      }
    } 
    return {};
  }

  get proposalsMap() {
    if (this.offer && this.offer.offerProposals && this.offer.offerProposals.selectedProposals && this.offer.offerProposals.selectedProposals.length && this.showingInPopup) {
      return this.offer.offerProposals.selectedProposals.filter(prop => prop.cabinClasses.includes(this.selectedClass));
    } else if (this.offer && this.offer.offerProposals && this.offer.offerProposals.proposals) {
      return this.offer.offerProposals.proposals.filter(prop => prop.cabinClasses.includes(this.selectedClass));
    }
    return [];
  }

  get shouldReloadProposals() {
    return AirSearchStore.shouldReloadProposals;
  }

  get searchCompleted() {
    return AirSearchStore.searchCompleted;
  }

  get uniqueClasses() {
    if (this.offer && this.offer.offerProposals) {
      let uniqueArr = _.flatMap(this.offer.offerProposals.proposals, 'cabinClasses');
      return _.uniqBy(uniqueArr);
    }

    return [];
  }

  selectedProposalExist() {
    return this.offer && this.offer.offerProposals && this.offer.offerProposals.selectedProposals;
  }

  uniqueSelectedProposalClasses() {
    if (this.offer && this.offer.offerProposals) {
      let uniqueArr = _.flatMap(this.offer.offerProposals.selectedProposals, 'cabinClasses');
      return _.uniqBy(uniqueArr);
    }

    return [];
  }

  showMoreProposal() {
    this.showLessButton = true;
    this.proposalsVisible = this.proposalsMap.length;
  }

  showLessProposal() {
    this.showLessButton = false;
    this.proposalsVisible = 3;
  }

  resetProposalsVisible() {
    this.proposalsVisible = 3;
  }

  setProposalNumber() {
    this.showLessButton = false;
    this.proposalsNumber = 0;
    this.proposalsNumber = this.proposalsMap.length;
  }

  finishAnimating() {
    this.preparing = false;
    this.animatingSwitch = false;
  }

  ensureFareNameVisible(index) {
    const elements = (this.$refs.fareName as any[]);

    if (!elements || !elements.length) {
      return;
    }
    const element = elements[index];
    EventBus.$emit('EnsureScrolledElementVisible', element);
  }

  commitClassChange(fareClass) {
    this.lastSelectedClass = this.selectedClass;
    this.lastProposalsVisible = this.proposalsVisible;
    this.selectedClass = fareClass;

    this.preparing = true;

    const listWrapperRef = this.$refs.listWrapper as HTMLElement;
    this.listHeight = listWrapperRef.getBoundingClientRect().height;

    this.$nextTick(() => {
      const listRef = this.$refs.newList as HTMLElement;
      this.listHeight = listRef.getBoundingClientRect().height;

      this.animatingSwitch = true;
      this.animatingFinishedID = setTimeout(this.finishAnimating, 600);
    });
  }

  selectClass(fareClass, index) {
    if (index === this.currentClassIndex) {
      return;
    }
    this.ensureFareNameVisible(index);
    this.preparing = false;
    this.animatingSwitch = false;
    this.isAnimationReversed = index < this.currentClassIndex;
    clearTimeout(this.animatingFinishedID);

    this.$nextTick(() => {
      this.commitClassChange(fareClass);
    });
  }

  selectClassNow(fareClass) {
    this.selectedClass = fareClass;
    this.lastSelectedClass = this.selectedClass;
  }

  @Watch('selectedClass')
  onClassChange() {
    this.resetProposalsVisible();
    this.setProposalNumber();
  }

  @Watch('toggled')
  onOToggledChange() {
    if (this.toggled) {
      this.loadProposals();
    }
  }

  @Watch('offer.offerProposals', {deep: true})
  onOfferProposalsChange() {
    this.selectClassProposal();
  }

  selectClassProposal() {
    if (this.offer && this.offer.offerProposals && this.offer.offerProposals.proposals) {
      let uniqueArr = _.flatMap(this.offer.offerProposals.proposals, 'cabinClasses');
      let classes = _.uniqBy(uniqueArr);

      if (!classes.length) {
        return;
      }
      this.selectClassNow(classes[0]);
    }
  }

  async loadProposals() {
    let searchId = this.$route.params.searchId;
    let flightNumberHash: any = [];
    this.offer.legs.forEach(leg => {
      flightNumberHash.push(leg.flightNumberHash);
    });

    if (this.showingInPopup) {
      if (this.selectedProposalExist()) {
        let classes = this.uniqueSelectedProposalClasses();
        if (classes.length) {
          this.selectClassNow(classes[0]);
        }
        AirSearchStore.updateLoadingProposalOffer({offer: this.offer, value: false});
        return;
      }

      this.selectClassProposal();
      AirSearchStore.updateLoadingProposalOffer({offer: this.offer, value: false});
      return;
    }

    if (!this.shouldReloadProposals) {
      await AirSearchStore.loadProposals({searchId, isExchange: this.searchModifyState, id: this.offerData.id, flightNumberHash});
      if (this.offersProposalsList && this.offersProposalsList.length) {
        AirSearchStore.updateLoadingProposalOffer({offer: this.offer, value: false});
      }
    }

    this.selectClassProposal();
  }

  async onReloadProposals(proposalId) {
    if (this.offer && this.offer.offerProposals) {
      if (-1 < this.offer.offerProposals.proposals.findIndex(proposal => { return proposal.id === proposalId; })) {
        AirSearchStore.setShouldReloadProposals(false);
        await this.loadProposals();
      }
    }
  }

  created() {
    EventBus.$on('reload-proposals',  this.onReloadProposals);
  }

  beforeDestroy() {
    EventBus.$off('reload-proposals', this.onReloadProposals);
  }
}
