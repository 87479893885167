import { Country, LocationModel } from '@/api/dictionary/dictionary.model';

export class CreateDrivingLicenses {
  type: string;
  number: string;
  issueDate: Date | string;
  expireDate: Date | string;
  issueCountryCode: string;
  firstName: string;
  middleName: string;
  lastName: string;

  public constructor() {
    this.type = 'Passport';
    this.number = '';
    this.issueDate = '';
    this.expireDate = '';
    this.issueCountryCode = '';
    this.firstName = '';
    this.middleName = '';
    this.lastName = '';
  }
}

export class CreateVisaMessage {
  type: string;
  number: string;
  issueDate: string;
  expireDate: string | null;
  issueCountryCode: string;
  issueCityCode: string | null;
  issueCityName: string | null;
  applicableCountryCode: string;

  public constructor() {
    this.type = 'Visa';
    this.number = '';
    this.issueDate = '';
    this.expireDate = null;
    this.issueCountryCode = '';
    this.issueCityCode = '';
    this.issueCityName = '';
    this.applicableCountryCode = '';
  }
}

export class CreateNationalIdMessage {
  type: string;
  number: string;
  issueDate: string | null;
  issueCountryCode: string;
  nationalityCountryCode: string;
  expireDate: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  gender: string | null;
  birthDate: string | null;

  public constructor() {
    this.type = 'NationalId';
    this.number = '';
    this.issueDate = null;
    this.issueCountryCode = '';
    this.nationalityCountryCode = '';
    this.expireDate = '';
    this.firstName = '';
    this.middleName = null;
    this.lastName = '';
    this.gender = null;
    this.birthDate = null;
  }
}

export class GreenCardDocument {
  type: string;
  number: string;
  nationalityCountryCode: string;
  expireDate: string;
  firstName: string;
  middleName: string | null;
  lastName: string;
  gender: string | null;
  birthDate: string | null;

  public constructor() {
    this.type = 'GreenCard';
    this.number = '';
    this.nationalityCountryCode = '';
    this.expireDate = '';
    this.firstName = '';
    this.middleName = null;
    this.lastName = '';
    this.gender = null;
    this.birthDate = null;
  }
}

export class CreateKnownTravellerMessage {
  type: string;
  number: string;

  public constructor() {
    this.type = 'KnownTraveller';
    this.number = '';
  }
}

export class ProfileDocument {
  id: string;
  type: string | any;
  number: string;
  issueDate: Date | string;
  issueCountryCode: string;
  issueCountry: Country | null;
  issueCityCode: string;
  issueCityName: string;
  issueCity: LocationModel;
  expireDate: Date | string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: Gender | any;
  birthDate: Date | string;
  applicableCountryCode: string;
  applicableCountry: Country;
  nationalityCountryCode: string;
  nationality: Country | null;
  tempId?: string;
  birthCountry: Country;
  birthCountryCode: string;
  birthLocationCityName: string;

  public constructor() {
    this.id = '';
    this.type = '';
    this.number = '';
    this.issueDate = '';
    this.issueCountryCode = '';
    this.issueCountry = null;
    this.issueCityCode = '';
    this.issueCityName = '';
    this.issueCity = <LocationModel> {};
    this.expireDate = '';
    this.firstName = '';
    this.middleName = '';
    this.lastName = '';
    this.gender = null;
    this.birthDate = '';
    this.applicableCountryCode = '';
    this.applicableCountry = new Country();
    this.nationalityCountryCode = '';
    this.nationality = null;
    this.birthCountry = new Country();
    this.birthCountryCode = '';
    this.birthLocationCityName = '';
  }
}

export enum DocumentType {
  Passport = 'Passport',
  NationalId = 'NationalId',
  Visa = 'Visa',
  Esta = 'Esta',
  Eta = 'Eta',
  EVisitor = 'EVisitor',
  KnownTraveller = 'KnownTraveller',
  Redress = 'Redress',
  DrivingLicense = 'DrivingLicense',
  GreenCard = 'GreenCard',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Unspecified = 'Unspecified',
}
