











































import settings from '@/settings';
import { Vue, Component } from 'vue-property-decorator';
import { router } from '@/router';
import OfflineRequestsWidgetItem from './OfflineRequestsWidgetItem.vue';
import OfflineRequestsPopup from './OfflineRequestsPopup.vue';
import { OfflineRequestApi } from '@/api/trip/offline-request.api';
import { OfflineRequestSummary } from '@/api/trip/offline-request.model';
import accountStore from '@/store/account.store';

@Component({
  components: {
    OfflineRequestsPopup,
    OfflineRequestsWidgetItem,
  }
})
export default class OfflineRequestsWidget extends Vue {
  serverErrors: any[] = [];
  offlineRequests: OfflineRequestSummary[] = [];
  limitOfRequests: number = 3;
  showAddNewRequestPopup: boolean = false;

  get currentProfileId() {
    return accountStore.CurrentUser!.profile.id;
  }

  get limitedOfflineRequests() {
    return this.offlineRequests.slice(0, settings.widgets.offlineRequests.maxItemsCount);
  }

  showOfflineRequests() {
    router.push({
      name: 'offline',
    });
  }

  async loadOfflineRequests() {
    this.showAddNewRequestPopup = false;
    try {
      const offlineRequestsSummaryResult = await OfflineRequestApi.summary(this.limitOfRequests);

      if (offlineRequestsSummaryResult && offlineRequestsSummaryResult.data) {
        this.offlineRequests = offlineRequestsSummaryResult.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error);
    }
  }

  created() {
    this.loadOfflineRequests();
  }
}
