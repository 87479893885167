

































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { translate } from '@/i18n';
import SearchStore from '@/modules/search/search.store';

@Component({})
export default class UiClassSelect extends Vue {
  @Prop() options!: any;
  @Prop() value!: [any, any];
  @Prop() labels!: [string, string];

  showFull: boolean = false;

  get wrapperClasses() {
    return {
      'ui-class-select--show-full': this.showFull,
    };
  }

  translateLabel(value) {
    if (!value) {
      return '';
    }
    return translate(value.label);
  }

  onClick(e: Event) {
    if (window.innerWidth >= 800) {
      return;
    }
    const target = e.target as HTMLElement;
    if ((target.className.includes('class-box') || target.className.includes('ui-select-mobile'))) {
      this.showFull = true;
      return;
    }
    this.showFull = !this.showFull;
  }

  onMouseOver(e) {
    if (window.innerWidth < 800) {
      return;
    }
    this.showFull = true;
  }
}
