import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import { CorporateCodeTypeEnum } from '@/api/air-engine/nego-fares-configuration.model';
import { NegoFareConfiguration, UpdateNegoFareConfigurationMessage, UpdateNegoFareConfigurationResponse } from './nego-fares.model';
import { Permission } from '@/const/permission.enum';

export class NegoFaresApiClass {
  public createNegoFareConfiguration(requestMessage: NegoFareConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAirEngine + '/nego-fare-configurations',
      method: RequestMethod.POST,
      permission: Permission.WriteNegoFare,
    }, {}, requestMessage );
  }

  public getAirNegoFareConfiguration(configurationId: string): Promise<AxiosResponse<NegoFareConfiguration>> {
    return http.execute({
      path: settings.apiAirEngine + '/nego-fare-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateAirNegoFareConfiguration(message: UpdateNegoFareConfigurationMessage): Promise<AxiosResponse<UpdateNegoFareConfigurationResponse | Array<any>>> {
    return http.execute({
      path: settings.apiAirEngine + '/nego-fare-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, { name: message.params.name, negoFaresConfigurations: message.params.negoFaresConfigurations });
  }

  public createNegoFareRailConfiguration(requestMessage: NegoFareConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/nego-fare-configurations',
      method: RequestMethod.POST,
      permission: Permission.WriteNegoFare,
    }, {}, requestMessage );
  }

  public getRailNegoFareConfiguration(configurationId: string): Promise<AxiosResponse<NegoFareConfiguration>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/nego-fare-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateRailNegoFareConfiguration(message: UpdateNegoFareConfigurationMessage): Promise<AxiosResponse<UpdateNegoFareConfigurationResponse | Array<any>>> {
    return http.execute({
      path: settings.apiRailEngine + '/api/nego-fare-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, { name: message.params.name, negoFaresConfigurations: message.params.negoFaresConfigurations });
  }

  public createNegoFareAccommodationConfiguration(requestMessage: NegoFareConfiguration): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/nego-fare-configurations',
      method: RequestMethod.POST,
      permission: Permission.WriteNegoFare,
    }, {}, requestMessage );
  }

  public getAccommodationNegoFareConfiguration(configurationId: string): Promise<AxiosResponse<NegoFareConfiguration>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/nego-fare-configurations/{configurationId}',
      method: RequestMethod.GET,
    }, { configurationId }, {});
  }

  public updateAccommodationNegoFareConfiguration(message: UpdateNegoFareConfigurationMessage): Promise<AxiosResponse<UpdateNegoFareConfigurationResponse | Array<any>>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/nego-fare-configurations/{configurationId}',
      method: RequestMethod.PUT,
    }, { configurationId: message.configurationId }, { name: message.params.name, negoFaresConfigurations: message.params.negoFaresConfigurations });
  }
}

export const NegoFaresApi: NegoFaresApiClass = new NegoFaresApiClass();