import ProfileSyncConfiguration from '@/modules/settings/profile-sync/ProfileSyncConfiguration.vue';

export default {
  path: ':configurationId?/profile-sync-configuration',
  name: 'profile-sync-configuration',
  component: ProfileSyncConfiguration,
  displayName: 'Profile Synchronization Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditProfilesSynchronization',
    parentName: 'profile-sync-configurations',
    depthCategory: 'profile-sync-configurations',
    depth: 2,
  }
};