
export enum ImportanceLevel {
  Normal = 'Normal',
  High = 'High',
}

export enum RecipientType {
  Agency = 'Agency',
  Customer = 'Customer',
}

export interface CustomMessagesTranslationDto {
  languageCode: string;
  title: string;
  body: string;
}

export interface CustomMessageDto {
  id: string;
  importance: ImportanceLevel;
  startDate: string;
  endDate: string;
  isEnabled: boolean;
  translations: CustomMessagesTranslationDto[];
  recipients: string[];
}

export interface CustomMessageModel {
  id: string;
  importance: ImportanceLevel;
  title: string;
  body: string;
}
