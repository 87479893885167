import { AxiosResponse } from 'axios';

import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import {
  ResponseTrainModel,
  TrainOffersResponseModel,
} from './train-search.model';
import {
  InitRailExchangeSearchMessage,
  PostSalesOptionsParams,
  PostSalesOptionsResponse,
  SncfPostSaleOptionsResponse,
  RailExchangeSessionForm,
  RailModificationPricing,
  RailSelectedLegMessage,
  RailSelectedLegResponse,
} from './train-exchange.model';

class TrainExchangeApiClass {
  public postSalesOptions(orderId: string, params: PostSalesOptionsParams): Promise<AxiosResponse<PostSalesOptionsResponse | SncfPostSaleOptionsResponse>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{orderId}/post-sales-option',
      method: RequestMethod.POST,
    }, { orderId }, params);
  }

  public exchangeAvailabilities(id: string, supplier: string, params: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange-availabilities/{id}/search-options?railSupplier={supplier}',
      method: RequestMethod.POST,
    }, { id, supplier }, params);
  }

  public initExchange(basketId: string, params: InitRailExchangeSearchMessage): Promise<AxiosResponse<ResponseTrainModel>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange?basketId={basketId}',
      method: RequestMethod.POST,
    }, { basketId }, params);
  }

  public getSearchSession(searchId: string): Promise<AxiosResponse<RailExchangeSessionForm>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{searchId}',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public getTrainOffers(searchId: string, legNumber: number, query): Promise<AxiosResponse<TrainOffersResponseModel>> {
    return http.execute({
      path: settings.apiRailEngine + `/exchange/{searchId}/offers/{legNumber}?${query}`,
      method: RequestMethod.GET,
    }, { searchId, legNumber }, {});
  }

  public loadMoreExchangeResults(searchId, legNumber, action) {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{searchId}/more/{legNumber}',
      method: RequestMethod.POST,
    }, { searchId, legNumber }, {
      moreResultType: action,
    });
  }

  public chooseExchangeOffer(searchId: string, params: RailSelectedLegMessage): Promise<AxiosResponse<RailSelectedLegResponse>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{searchId}/leg',
      method: RequestMethod.PUT,
    }, { searchId }, params);
  }

  public getPricing(searchId: string): Promise<AxiosResponse<RailModificationPricing>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{searchId}/pricing',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public confirm(searchId: string, params): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{searchId}/confirm',
      method: RequestMethod.POST,
    }, { searchId }, params);
  }

  public deleteLeg(searchId: string, legNumber: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiRailEngine + '/exchange/{searchId}/leg/{legNumber}/delete',
      method: RequestMethod.POST,
    }, { searchId, legNumber }, {});
  }
}

export const TrainExchangeApi: TrainExchangeApiClass = new TrainExchangeApiClass();
