
























































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';

import { userFullName } from '@/core/user-full-name';
import { BasketFlatItemModel } from '@/api/trip/basket.model';
import { TrainExchangeApi } from '@/api/train-engine/train-exchange.api';
import {
  PostSalesOptions,
  PostSalesOptionsResponse,
} from '@/api/train-engine/train-exchange.model';
import RailNtvLegSelectionRow from './RailNtvLegSelectionRow.vue';
import TrainSearchStore from '@/modules/search/train/train-search.store';
import { translate } from '@/i18n';

@Component({
  components: {
    RailNtvLegSelectionRow,
  },
})
export default class RailNtvExchangeModifyForm extends Vue {
  @Prop() item!: BasketFlatItemModel;
  @Prop() travellers!: any;
  @Prop() basketId!: string;

  loading: boolean = true;
  errors: any[] = [];
  postSaleOptions: PostSalesOptionsResponse | null = null;
  postSaleData: any = null;
  selectedPostSaleOption: PostSalesOptions = PostSalesOptions.ModifyTravel;
  exchangeLocations: any[] = [];
  exchangeFromLocation: any[] = [];
  exchangeToLocation: any[] = [];
  sendingRequest: boolean = false;
  travellerModificationSelected: boolean = false;
  selectedTravellers: any[] = [];


  get noLegSelected() {
    if (!this.postSaleOptions) {
      return true;
    }
    return !this.postSaleData.legs.some(leg => {
      return leg.isForExchange;
    });
  }



  userFullName(user) {
    return userFullName(user);
  }

  changeSelectedTravellers() {
    this.selectedTravellers = [];
    let options = this.postSaleOptions!.travellers.filter(trav => trav.isSelected);
    if (options.length) {
      options.forEach(option => {
        this.selectedTravellers.push({
          ...option,
          updateData: {
            id: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
          },
        });
      });
    }
  }

  async loadData() {
    this.errors = [];
    this.postSaleOptions = null;
    try {
      const response = await TrainExchangeApi.postSalesOptions(this.item.providerReferenceId || '', {
        railTripItemId: this.item.id,
        supplier: this.item.supplier || '',
        travellers: this.travellers.forEach(trav => {
          return {
            ...trav,
            isSelected: false,
          };
        }),
      });
      this.postSaleOptions = response.data as PostSalesOptionsResponse;

      this.postSaleData = {
        type: null,
        parentOrderId: this.postSaleOptions.parentOrderId,
        railTripItemId: this.postSaleOptions.railTripItemId,
        travellers: this.postSaleOptions.travellers,
        supplier: this.postSaleOptions.supplier,
        legs: this.postSaleOptions.legs
          .sort((a, b) => a.legNumber - b.legNumber)
          .map(leg => {
            return {
              legNumber: leg.legNumber,
              isForExchange: false,
              from: leg.from,
              to: leg.to,
              date: leg.date,
              departureTimeSpan: {
                from: leg.departureTimeSpan ? leg.departureTimeSpan.from : 840,
                to: leg.departureTimeSpan ? leg.departureTimeSpan.to : 960,
              },
            };
          }),
      };
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }

  checkMaxDateForLeg(index) {
    if (index < this.postSaleData.legs.length - 1) {
      if (!this.postSaleData.legs[index + 1].isForExchange) {
        return this.postSaleData.legs[index + 1].date;
      }
      return null;
    }

    return null;
  }

  focusOnNextLeg(leg, index) {
    const nextIndex = index + 1;
    if (nextIndex >= this.postSaleData.legs.length) {
      ((this.$refs.checkAvailabilityButton as Vue).$el as HTMLElement).focus();
      return;
    }

    ((this.$refs['leg' + nextIndex] as any[])[0] as RailNtvLegSelectionRow).focusOnFirstElement();
  }

  onLegDeselect(leg) {
    if (!this.postSaleOptions) {
      return;
    }
    const originalLeg = this.postSaleOptions.legs.find(l => l.legNumber === leg.legNumber);
    if (!originalLeg) {
      return;
    }

    const dataLeg = this.postSaleData.legs.find(l => l.legNumber === leg.legNumber);
    if (!dataLeg) {
      return;
    }
    dataLeg.from = originalLeg.from;
    dataLeg.to = originalLeg.to;
    dataLeg.date = originalLeg.date;
  }

  isLegDisabledForChosenOption(leg, index) {
    if (!this.postSaleOptions || this.sendingRequest) {
      return true;
    }

    const legInfo = this.postSaleOptions.legs[index];

    if (!legInfo.availablePostSales) {
      return true;
    }

    return !legInfo.availablePostSales
      .find(option => option === this.selectedPostSaleOption);
  }

  isLegDisabledAndShouldShowTooltip(leg, index) {
    if (!this.postSaleOptions || this.sendingRequest || !this.selectedPostSaleOption) {
      return false;
    }

    return this.isLegDisabledForChosenOption(leg, index);
  }

  @Emit('close')
  hideForm() {
    // This is intentional
  }

  dateFormValidation() {
    return this.postSaleData.legs && this.postSaleData.legs.length > 1 ? moment(this.postSaleData.legs[1].date).isSameOrAfter(this.postSaleData.legs[0].date, 'day') :  true;
  }

  async submitForm() {
    if (!this.postSaleOptions) {
      return;
    }

    if (!this.dateFormValidation()) {
      this.errors.push({ message: translate('common-error.check-date-validation') });
      return;
    }
    this.sendingRequest = true;
    this.errors = [];
    try {
      const response = await TrainExchangeApi.initExchange(this.basketId, {
        ...this.postSaleData,
        type: this.selectedPostSaleOption,
      });

      const firstLeg = this.postSaleData.legs.find(leg => {
        return leg.isForExchange;
      });

      TrainSearchStore.setExchangeLoaded(false);
      TrainSearchStore.setProvidersErrors([]);

      this.$router.push({
        name: 'trainModification',
        params: {
          searchId: response.data.searchId,
          basketId: response.data.basketId,
          leg: firstLeg.legNumber,
        },
      });
      this.hideForm();
    } catch (error) {
      this.sendingRequest = false;
      this.errors = this.$handleErrors(error, true);
    }
  }

  goBack() {
    this.loading = true;
    setTimeout(() => {
      this.travellerModificationSelected = false;
      this.loading = false;
    }, 500);
  }

  mounted() {
    this.loadData();
  }
}

