












































































































import { Vue, Component } from 'vue-property-decorator';
import CustomFieldsStore from '@/modules/settings/custom-fields-configuration/custom-fields.store';
import SettingsStore from '@/modules/settings/settings.store';
import { CustomFieldsApi } from '@/api/custom-fields/custom-fields.api';
import { CustomFieldDictionariesApi } from '@/api/custom-fields/custom-fields-dictionary.api';
import {
  UpdateCustomFieldDictionaryMessage,
  CreateCustomFieldDictionaryMessaage,
  CustomFieldDictionaryItemEntry,
  CustomFieldDictionaryDefinitionModel,
} from '@/api/custom-fields/custom-fields.model';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { ForEachPromise } from '@/services/for-each-promise.service';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import CustomFieldDictionaryItems from './CustomFieldDictionaryItems.vue';
import { UpdateFieldConfigurationMessage } from '../../../api/custom-fields/custom-fields.model';

const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

@Component({
  components: {
    CustomFieldDictionaryItems,
  }
})
export default class CustomFieldDictionary extends Vue {
  $v;
  formHasErrors: boolean = false;
  saving: boolean = false;
  valueCannotDisabled: boolean = false;

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/custom-fields/${this.configurationId !== undefined ? this.configurationId + '/field-configuration' : 'field-configuration'}`;
  }

  get configuration() {
    return CustomFieldsStore.configuration;
  }

  get loading() {
    return CustomFieldsStore.loading;
  }

  get dictionary() {
    return CustomFieldsStore.dictionaryDefinition;
  }

  get dictionaryEntries() {
    return this.dictionary.items;
  }

  get errors() {
    return CustomFieldsStore.getErrMessages;
  }

  get dictionaries() {
    return CustomFieldsStore.dictionaries;
  }

  @Validation()
  validationObject() {
    return {
      dictionary: {
        name: {
          required,
          maxLength: maxLength(64),
          isUnique: (value: string, { id }: CustomFieldDictionaryDefinitionModel) => {
            if (!value) {
              return true;
            }
            return !this.dictionaries.find((item) => item.name === value && item.id !== id);
          }
        },
        description: {
          maxLength: maxLength(1024)
        }
      }
    };
  }

  checkForm() {
    this.formHasErrors = false;
    this.$v.dictionary.$touch();
    if (this.$v.dictionary.$pending || this.$v.dictionary.$error) {
      this.formHasErrors = true;
    }
  }

  redirectEvent() {
    if (!this.errors.length && !this.valueCannotDisabled) {
      const obj = {
        type: translate('common.success'),
        title: translate('settings-custom-fields.dictionary-saved'),
        message: translate('settings-custom-fields.dictionary-saved-message')
      };
      EventHandler.$emit('show-toast', obj);
      this.goToConfiguration();
    }
  }

  async addDictionaryItem(item: CustomFieldDictionaryItemEntry) {
    if (item.id) {
      try {
        const message = {
          customFieldDictionaryItemId: item.id,
          item: {
            name: item.name,
            description: item.description,
            code: item.code,
            isActive: item.isActive,
          }
        };
        await CustomFieldDictionariesApi.updateCustomFieldDictionaryItem(message);
      } catch (error) {
        if (error.response && error.response.status === 418 && error.response.data.error.code === 'ELEMENT_IS_USED_AND_CANNOT_BE_DISABLED') {
          this.valueCannotDisabled = true;
        } else {
          CustomFieldsStore.setErrMessages(error);
          CustomFieldsStore.setShowError(true);
        }

        CustomFieldsStore.setLoading(false);
      }
    } else {
      try {
        if (this.dictionary.id) {
          await CustomFieldDictionariesApi.createCustomFieldDictionaryItem(this.dictionary.id, item);
        }
      } catch (error) {
        CustomFieldsStore.setErrMessages(error);
        CustomFieldsStore.setShowError(true);
        CustomFieldsStore.setLoading(false);
      }
    }
  }

  goToConfiguration() {
    CustomFieldsStore.setActiveTab('dictionaries');
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'field-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async saveDictionary() {
    CustomFieldsStore.clearErrMessages();
    this.checkForm();
    if (!this.formHasErrors) {
      let configId = this.$route.params.configurationId;
      if (configId === undefined) {
        const message: UpdateFieldConfigurationMessage = {
          rootCompanyId: this.currentCompany!.rootCompanyId,
          name: this.configuration.name,
        };
        const newConfig = await this.createConfig(message);
        CustomFieldsStore.setConfigurationId(newConfig.configurationId);
        configId = newConfig.configurationId;
      }
      await this.saveDictionaryConfiguration(configId);
    }
  }

  async removeDictionary() {
    try {
      if (this.dictionary && this.dictionary.id) {
        CustomFieldsStore.setLoading(true);
        await CustomFieldDictionariesApi.deleteCustomFieldDictionary(this.dictionary.id);
        const obj = {
          type: translate('common.success'),
          title: translate('settings-custom-fields.configuration-removed'),
          message: translate('settings-gds.info-removed')
        };

        EventHandler.$emit('show-toast', obj);
      }
    } catch (error) {
      CustomFieldsStore.setErrMessages(error);
    } finally {
      if (!this.errors.length) {
        this.goToConfiguration();
      }
      CustomFieldsStore.setLoading(false);
    }
  }

  async createConfig(data: UpdateFieldConfigurationMessage) {
    try {
      CustomFieldsStore.setLoading(true);
      const response = await CustomFieldsApi.createCustomFieldConfiguration(data);
      if (response && response.data) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      CustomFieldsStore.setErrMessages(error);
      CustomFieldsStore.setShowError(true);
    } finally {
      CustomFieldsStore.setLoading(false);
    }
  }

  async saveDictionaryConfiguration(configurationId: string) {
    this.valueCannotDisabled = false;
    this.saving = true;
    try {
      CustomFieldsStore.setShowError(false);
      CustomFieldsStore.setLoading(true);
      const isExisting = !!this.dictionary.id;
      if (isExisting && this.dictionary.id !== undefined) {
        const message: UpdateCustomFieldDictionaryMessage = {
          customFieldDictionaryId: this.dictionary.id,
          dictionary: {
            Name: this.dictionary.name,
            Description: this.dictionary.description,
            IsActive: this.dictionary.isActive
          }
        };
        await CustomFieldDictionariesApi.updateCustomFieldDictionary(message);
        await ForEachPromise(this.dictionaryEntries, this.addDictionaryItem);
        if (!this.valueCannotDisabled) {
          this.redirectEvent();
        }
      } else {
        const message: CreateCustomFieldDictionaryMessaage = {
          rootCompanyId: this.$route.params.id,
          Name: this.dictionary.name,
          Description: this.dictionary.description,
          IsActive: this.dictionary.isActive,
          configurationId: configurationId,
        };
        const dictionary = await CustomFieldDictionariesApi.createCustomFieldDictionary(message);
        if (dictionary && dictionary.data) {
          const newDictionary = {
            id: dictionary.data.customFieldDictionaryId,
            name: this.dictionary.name,
            description: this.dictionary.description,
            isActive: this.dictionary.isActive,
            items: this.dictionaryEntries,
          };
          CustomFieldsStore.setDictionaryData(newDictionary);
        }
        await ForEachPromise(this.dictionaryEntries, this.addDictionaryItem);
        if (!this.valueCannotDisabled) {
          this.redirectEvent();
        }
        CustomFieldsStore.setDictionaryItems(this.dictionaryEntries);
      }
    } catch (error) {
      this.saving = false;
      CustomFieldsStore.setErrMessages(error);
      CustomFieldsStore.setShowError(true);
    } finally {
      CustomFieldsStore.setLoading(false);
    }
  }

  created() {
    this.$v.dictionary.$reset();
    this.valueCannotDisabled = false;
    if (!this.configuration.name) { // no configuration data, return to config route
      if (this.$route.params.configurationId === undefined) {
        router.push({
          name: 'fields-configuration',
          params: {
            id: this.$route.params.id,
          }
        });
      } else {
        router.push({
          name: 'field-configuration',
          params: {
            id: this.$route.params.id,
            configurationId: this.$route.params.configurationId,
          }
        });
      }
    }
  }
}

