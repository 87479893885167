import SystemMarkupConfiguration from '@/modules/settings/system-markup/SystemMarkupConfiguration.vue';

export default {
  path: ':configurationId/system-markup-configuration',
  name: 'system-markup-configuration',
  component: SystemMarkupConfiguration,
  displayName: `System markup's configuration`,
  meta: {
    breadCrumb: 'System markup configuration',
    introClass: 'intro-settings',
    permission: 'CanEditSystemMarkupConfiguration',
    parentName: 'system-markup',
    depthCategory: 'system-markup',
    depth: 2,
  }
};