var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('UiBusyIndicator',{attrs:{"size":120}}):(_vm.changes.length > 0)?[_c('h3',{staticClass:"mt-6 pb-2 mb-3 basket-trip-notes__title"},[_c('span',[_vm._v(_vm._s(_vm.$t('basket.trip-notes')))])]),_c('div',[_c('b-table',{ref:"table",attrs:{"fields":_vm.fields,"items":_vm.changes,"per-page":_vm.params.size,"current-page":_vm.params.page,"sort-by":_vm.params.sort,"sort-desc":_vm.params.desc,"no-sort-reset":true,"striped":"","stacked":"md"},on:{"update:sortBy":function($event){return _vm.$set(_vm.params, "sort", $event)},"update:sort-by":function($event){return _vm.$set(_vm.params, "sort", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.params, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.params, "desc", $event)}},scopedSlots:_vm._u([{key:"actionBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"trips-table__traveller-name-column"},[_c('div',{staticClass:"traveller-name-wrapper"},[_c('div',{staticClass:"trips-table__traveller-avatar"},[_c('UiAvatar',{attrs:{"user":item.invokerMapped,"selected":item.invokerMapped.isMainTraveller,"variant":"small"}})],1),_c('div',{staticClass:"trips-table__traveller"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.userFullName(item.invokerMapped) }),expression:"{ content: userFullName(item.invokerMapped) }"}],staticClass:"trips-table__traveller-name text-overflow-dotted"},[_vm._v("\n                  "+_vm._s(_vm.userFullName(item.invokerMapped))+" "),(item.invokerMapped.isGuest)?_c('span',[_vm._v("("+_vm._s(_vm.$t('search.guest'))+")")]):_vm._e()]),_c('div',{staticClass:"trips-table__traveller-unit"},[_c('span',[_vm._v(_vm._s(item.invokerMapped.businessUnitName))])])])])])]}},{key:"actionType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("basket-trip-history-trip-notes.change-type-" + (item.changeType)))))])]}},{key:"actionDatetime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.changeDateFormated))])]}},{key:"changes",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t(("basket-trip-history-trip-notes." + (item.changeType)))))])]),(item.changeType !== 'Delete')?_c('div',{staticClass:"d-flex align-items-center"},[(item.changeType === 'Edit')?[_c('span',[_vm._v(_vm._s(item.changeParameters[0].valueFrom))]),_c('UiIcon',{staticClass:"basket-trip-history__change-arrow",attrs:{"icon":"forward"}})]:_vm._e(),_c('span',[_vm._v(_vm._s(item.changeParameters[0].valueTo))])],2):_vm._e()]}}])})],1)]:_vm._e(),(_vm.errors.length > 0)?_c('UiErrors',{attrs:{"errors":_vm.errors}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }