




































































































import { Vue, Component, Emit, Watch, Prop } from 'vue-property-decorator';
import { router } from '@/router';
import { NotificationModel } from '@/api/expense/expense.model';
import ExpenseStore from '../expense.store';

const severityIcon = {
  Risk: 'notification_important',
  Violation: 'block',
  Warning: 'warning',
  Information: 'info',
};

@Component({
  components: {},
})

export default class NotificationItem extends Vue {
  @Prop() notification!: any;
  shouldShowMoreDetails: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  loadingDetails: boolean = false;

  typeClasses(notification) {
    return {
      'error': notification.severity === 'Violation',
      'warning': notification.severity === 'Warning',
      'info-level': notification.severity === 'Information',
      'risk': notification.severity === 'Risk',
    };
  }

  deliveredClass(notification) {
    return {
      'read': notification.delivered === true,
      'unread': notification.delivered === false,
    };
  }

  typeIcons(notification) {
    return severityIcon[notification.severity];
  }

  showDetails(notification) {
    this.shouldShowMoreDetails = !this.shouldShowMoreDetails;
    this.loadDetails(notification);
  }

  async loadDetails(notification) {
    if (this.shouldShowMoreDetails) {
      this.loadingDetails = true;
      await ExpenseStore.getNotificationDetails(notification.id);
      this.notification.details = ExpenseStore.notificationDetails;
      this.notification.delivered = this.notification.details.delivered;
      this.loadingDetails = false;
    }
  }

  get showGoToExpense() {
    return this.shouldShowMoreDetails && this.notification.details !== null && this.notification.details.expense;
  }

  get showGoToMission() {
    return this.shouldShowMoreDetails && this.notification.details !== null && this.notification.details.mission;
  }

  goToExpense() {
    router.push({
      name: 'modify-expense',
      params: {
        expenseId: this.notification.details.expense.id,
      },
    });
  }

  goToMission() {
    router.push({
      name: 'modify-mission',
      params: {
        missionId: this.notification.details.mission.id,
      },
    });
  }

}

