import MissedSavings from '@/modules/settings/missed-savings/MissedSavings.vue';
import MissedSavingsConfigurations from './missed-savings-configurations.route';
import MissedSavingsConfiguration from './missed-savings-configuration.route';

export default {
  path: 'missed-savings',
  component: MissedSavings,
  displayName: 'settings-sidebar.missed-savings',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditMissedSavings',
    icon: 'savings',
    parentName: 'missedSavings',
  },
  children: [
    MissedSavingsConfigurations,
    MissedSavingsConfiguration,
  ],
};