import ProvidersNotifications from '@/modules/settings/providers-notifications/ProvidersNotifications.vue';
import ProvidersNotificationsList from './providers-notifications-list.route';
import NewProvidersNotification from './new-providers-notification.route';
import EditProvidersNotification from './edit-providers-notification.route';

export default {
  path: 'providers-notifications',
  component: ProvidersNotifications,
  displayName: 'settings-sidebar.providers-notifications',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditProvidersNotificationConfigurations',
    icon: 'mark_email_unread',
    parentName: 'providers-notifications',
  },
  children: [
    ProvidersNotificationsList,
    NewProvidersNotification,
    EditProvidersNotification,
  ],
};
