import PaymentAccommodationConfiguration from '@/modules/settings/payment-methods/PaymentAccommodationConfiguration.vue';

export default {
  path: ':configurationId?/accommodation-configuration',
  name: 'accommodation-configuration',
  component: PaymentAccommodationConfiguration,
  displayName: 'Accommodation configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadPaymentMethodConfiguration',
    depthCategory: 'payment-methods',
    depth: 3,
    parentName: 'payment-methods-configurations',
  },
};
