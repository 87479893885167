

































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import { router } from '@/router';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import PaymentMethodsCarStore from './payment-methods-car.store';
import PaymentCarConfigurationVoucherType from './PaymentCarConfigurationVoucherType.vue';
import DictionaryStore from '@/store/dictionary.store';
import { NegoFareConfigurationSupplierEnum } from '@/api/nego-fares/nego-fares.model';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';

const isRegexStartingWithC = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  
  return /^[{C}][0-9a-zA-Z]*$/.test(value);
};

@Component({
  components: {
    PaymentCarConfigurationVoucherType,
  },
})
export default class PaymentCarConfiguration extends Vue {
  $v;

  providerOptions = [
    NegoFareConfigurationSupplierEnum.Sabre,
    NegoFareConfigurationSupplierEnum.Amadeus,
  ];
  vendors = [];

  Form: any = {
    supplier: null,
    defaultPaymentMethod: null,
    centralPaymentCheckbox: false,
    enabledPaymentMethod: 'CentralPayment',
    enablePaymentGateway: false,
    paymentGatewayOption: 'TotalBookingAmount',
    hidePaymentMethodIfItIsNotNeeded: false,
    billingIds: [],
    voucher: {
      value: '',
      name: '',
    },
  };

  @Validation()
  validationObject() {
    
    let billingId: any = {
      required,
      maxLength: maxLength(100)
    };
    
    if (this.Form.supplier === 'Amadeus') {
      billingId.isRegexStartingWithC = isRegexStartingWithC;
    }

    return {
      Form: {
        supplier: {
          required,
        },
        enabledPaymentMethod: {
          required,
        },
        billingIds: {
          required: () => {
            return this.shouldShowBillingIds ? this.isBillingIdsValid : true;
          },
          $each: {
            carVendorCode: {
              required,
            },
            billingId,
            cdNumber: {
              maxLength: maxLength(100)
            },
            bookingSource: {
              maxLength: maxLength(100)
            },
            voucherType: {
              maxLength: maxLength(100)
            },
          }
        },
      }
    };
  }

  get filterProviderList() {
    if (this.currentPaymentList && this.currentPaymentList.length && this.isNew) {
      return this.providerOptions.filter(e => !this.currentPaymentList.some(a => a.supplier === e && a.succeeded));
    }

    return this.providerOptions;
  }

  get filteredTranslatedProviderList() {
    return this.filterProviderList.map(supplier => {
      return {
        value: supplier,
        label: SupplierName(supplier),
      };
    });
  }

  get selectedSupplier() {
    return this.filteredTranslatedProviderList.find(item => item.value === this.Form.supplier) || null;
  }

  set selectedSupplier(value) {
    if (value == null) {
      this.Form.supplier = null;
      return;
    }

    this.Form.supplier = value.value;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get currentConfigurationName() {
    return PaymentMethodsCarStore.configurationName;
  }

  get selectedPayment() {
    return PaymentMethodsCarStore.selectedPayment;
  }

  get currentPaymentList() {
    return PaymentMethodsCarStore.paymentList;
  }

  get shouldShowBillingIds() {
    return this.Form.enabledPaymentMethod === 'CentralPayment';
  }

  get isNew() {
    if (this.selectedPayment) {
      return Object.entries(this.selectedPayment as any).length === 0;
    }
    return false;
  }

  get readOnly() {
    return this.$route.query.readOnly === 'true';
  }

  get carVendors() {
    return DictionaryStore.carVendors;
  }

  get carVendorsFiltered() {
    return (this.carVendors || [])
      .sort((a, b) => {
        let first = a.name.toUpperCase();
        let second = b.name.toUpperCase();
        if (first < second) {
          return -1;
        }
        if (first > second) {
          return 1;
        }
        return 0;
      });
  }

  get isBillingIdsValid() {
    return this.Form.billingIds.some(e => {
      return e.billingId && e.carVendorCode;
    });
  }


  firstItem(index) {
    return {
      'first-item': !index,
    };
  }

  focusOnId(index) {
    const vueEl = this.$refs['billingId_' + index] as Vue;
    if (!vueEl) {
      return;
    }
    const inputValue = vueEl[0].$el as HTMLInputElement;
    setTimeout(() => {
      inputValue.getElementsByTagName('input')[0].focus();
    });
  }

  goToConfiguration() {
    PaymentMethodsCarStore.setIsFromManage(true);
    router.push({
      name: 'payment-methods-configuration',
      params: {
        id: router.currentRoute.params.id,
        configurationId: router.currentRoute.params.configurationId ? router.currentRoute.params.configurationId : 'new'
      }
    });
  }

  addBilling() {
    this.Form.billingIds.push({
      carVendorCode: null,
      billingId: null,
      cdNumber: null,
      bookingSource: null,
      voucherType: 'FullCredit',
    });
  }

  removeBilling(index) {
    this.Form.billingIds.splice(index, 1);
  }

  changeCentralPayment(value) {
    this.Form.enabledPaymentMethod = value ? 'CentralPayment' : '';
    if (!value) {
      this.Form.hidePaymentMethodIfItIsNotNeeded = false;
    }
  }
 
  mapFormData(data?: any) {
    if (data && Object.keys(data).length) {
      this.Form = {
        supplier: data.supplier,
        enabledPaymentMethod: data.enabledPaymentMethod,
        centralPaymentCheckbox: data.enabledPaymentMethod === 'CentralPayment',
        enablePaymentGateway: !!data.paymentGatewayOption ? true : false,
        paymentGatewayOption: data.paymentGatewayOption ? data.paymentGatewayOption : 'TotalBookingAmount',
        hidePaymentMethodIfItIsNotNeeded: data.hidePaymentMethodIfItIsNotNeeded,
        billingIds: data.billingIds && data.billingIds.length ? this.mapBillingIdsToForm(data.billingIds) :
          [{
            carVendorCode: null,
            billingId: null,
            cdNumber: null,
            bookingSource: null,
            voucherType: 'FullCredit',
            issueEVoucher: false,
          }]
      } as any;
    } else {
      this.Form = {
        supplier: null,
        enabledPaymentMethod: 'CentralPayment',
        centralPaymentCheckbox: true,
        enablePaymentGateway: false,
        paymentGatewayOption: 'TotalBookingAmount',
        hidePaymentMethodIfItIsNotNeeded: false,
        billingIds: [{
          carVendorCode: null,
          billingId: null,
          cdNumber: null,
          bookingSource: null,
          voucherType: 'FullCredit',
          issueEVoucher: false,
        }],
      } as any;
    }
  }

  mapBillingIdsToForm(billingIds: any[]) {
    return billingIds.map(e => {
      return {
        carVendorCode: this.carVendors!.find(vendor => {
          return vendor.code === e.carVendorCode;
        }),
        billingId: e.billingId,
        cdNumber: e.cdNumber,
        bookingSource: e.bookingSource,
        voucherType: e.voucherType,
        issueEVoucher: e.issueEVoucher,
      };
    });
  }

  mapBillingIds(billingIds: any[]) {
    const mapped = billingIds.map(e => {
      return (e.carVendorCode && e.billingId) ?
        {
          carVendorCode: e.carVendorCode && e.carVendorCode.code ? e.carVendorCode.code : e.carVendorCode,
          billingId: e.billingId,
          cdNumber: e.cdNumber,
          bookingSource: e.bookingSource,
          voucherType: e.voucherType,
          issueEVoucher: e.issueEVoucher,
        }
        : null;
    });
    const filtered = mapped.filter(Boolean);
    return filtered.length ? filtered : false;
  }

  createPayment() {
    let item = {
      supplier: this.Form.supplier,
      paymentGatewayOption: this.Form.enablePaymentGateway ? this.Form.paymentGatewayOption : null,
      billingIds: this.Form.enabledPaymentMethod === 'CentralPayment' ? this.mapBillingIds(this.Form.billingIds) : [],
      status: PaymentMethodsCarStore.draftNewStatus,
      enabledPaymentMethod: this.Form.enabledPaymentMethod,
      hidePaymentMethodIfItIsNotNeeded: this.Form.hidePaymentMethodIfItIsNotNeeded,
      succeeded: true,
      id: _.uniqueId()
    };
    PaymentMethodsCarStore.setIsFromManage(false);
    if (
      Object.entries(this.selectedPayment as any).length === 0 || 
      !this.currentPaymentList.find(entry => {
        return entry.supplier === item.supplier;
      })
    ) {
      PaymentMethodsCarStore.addSelectedPayment(item);
    } else {
      PaymentMethodsCarStore.editSelectedPayment(item);
    }
  }

  confirmAndGoBack() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.createPayment();
      this.goToConfiguration();
    }
  }

  @Watch('selectedPayment', { deep: true, immediate: true })
  onCodeChange(payment) {
    if (!payment) {
      router.go(-1);
    }
  }

  async created() {
    await DictionaryStore.loadCarVendors();
    if (this.selectedPayment) {
      this.mapFormData(JSON.parse(JSON.stringify(this.selectedPayment)));
    } else {
      this.mapFormData();
    }
  }
}
