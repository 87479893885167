













































































import { Vue, Component, Watch } from 'vue-property-decorator';

import EventBus from '@/services/event-handler';
import FiltersContentLoading from '../controls/FiltersContentLoading.vue';
import TextBoxFilter from '../controls/TextBoxFilter.vue';
import RangeFilter from '../controls/RangeFilter.vue';
import BoolFilter from '../controls/BoolFilter.vue';
import SliderFilter from '../controls/SliderFilter.vue';
import CategoriesFilter from '../controls/CategoriesFilter.vue';
import HotelSearchStore from './hotel-search.store';
import { router } from '@/router';
import { Debounce } from '@/core/decorators/debounce.decorator';

@Component({
  components: {
    CategoriesFilter,
    RangeFilter,
    BoolFilter,
    SliderFilter,
    TextBoxFilter,
    FiltersContentLoading,
  }
})
export default class HotelResultsFilters extends Vue {

  get filters() {
    return HotelSearchStore.filters;
  }

  get filtersResponse() {
    return HotelSearchStore.filtersResponse;
  }

  get isDisabled() {
    return HotelSearchStore.selectingOffer;
  }

  get currency() {
    return HotelSearchStore.currentCurrency;
  }

  get globalClass() {
    return {
      'md-hidden-mobile': !this.$route.meta.mobile,
      'filters-loading': this.isDisabled,
      'filters-container': this.$route.meta.mobile,
    };
  }

  get loading() {
    return HotelSearchStore.loading;
  }

  get hasFiltersError() {
    return HotelSearchStore.filtersError;
  }

  filterVisible(filterMetadata) {
    return (filterMetadata.type === 'category' &&
      filterMetadata.values !== undefined) ||
      filterMetadata.data !== undefined;
  }

  updateFilter(filter) {
    HotelSearchStore.setFiltersChanging(true);
    HotelSearchStore.updateFilterValue(filter);

    const searchId = this.$route.params.searchId;

    HotelSearchStore.setFiltersRequestBody({
      filtersData: HotelSearchStore.filtersRequest,
      searchId,
    });
    this.forceFiltersUpdate();
  }

  getFilterData(filterCode) {
    const f = this.filters.find(f => f.code === filterCode);
    return f ? f.values : null;
  }

  getRangeFilterData(filterCode) {
    const f = this.filters.find(f => f.code === filterCode);
    return f ? f.data : null;
  }

  refreshFiltersComponents(refs, method) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      const data = method(element.code);
      if (!data) {
        return;
      }
      EventBus.$emit('refresh-filter-component', {
        code: element.code,
        data,
      });
    });
  }

  @Watch('filtersResponse')
  onChangeFilters() {
    this.refreshFiltersComponents(this.$refs.categoriesFilters, this.getFilterData);
    this.refreshFiltersComponents(this.$refs.rangeFilters, this.getRangeFilterData);
  }

  @Debounce({
    delay: 300,
  })
  async forceFiltersUpdate() {
    const requestId = HotelSearchStore.filtersRequestId + 1;
    const searchId = this.$route.params.searchId;

    if (!searchId) {
      HotelSearchStore.setFiltersChanging(false);
      return;
    }

    await HotelSearchStore.updateSearchFilters(searchId);
    HotelSearchStore.setFiltersChanging(false);
    if (!this.hasFiltersError && requestId === HotelSearchStore.filtersRequestId) {
      HotelSearchStore.getOffers(searchId);
    }
  }

  showResults() {
    router.push({ 
      name: 'hotel',
      params: this.$router.currentRoute.params
     });
  }

  created() {
    if (this.$route.meta.mobile) {
      let searchId = this.$route.params.searchId;

      if (searchId && ('-' !== searchId)) {
        HotelSearchStore.setSearchCompleted(false);
        HotelSearchStore.getSearchSession(searchId);
        HotelSearchStore.getOffers(searchId);
      }
    }
  }
}

