




























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TravelDoc extends Vue {
  @Prop() travelData!: any;

  get title() {
    if (!this.travelData.ResultsList && !this.travelData.ResultsList.length) {
      return '';
    }
    return this.travelData.ResultsList[0].Title;
  }

  classes(status) {
    return {
      'status__green': status === 1,
      'status__amber': status === 2,
      'status__red': status === 3,
    };
  }

  BackToResults() {
    this.$emit('close');
    return;
  }

}
