























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { translate } from '@/i18n';
import FlightTimeline from './FlightTimeline.vue';
import formatPrice from '@/filters/format-price.filter';
import AirSearchStore from '@/modules/search/air/air-search.store';
import BasketStore from '@/modules/basket/basket.store';
import { SupplierName } from '@/services/supplier-name.service';

@Component({
  components: {
    FlightTimeline,
  }
})
export default class AirExchangeModifiedOffer extends Vue {
  @Prop() offer: any;



  get flagClasses() {
    return {
      'flag__public': 'Public' === this.offer.proposal.fareType,
      'flag__nego': 'Nego' === this.offer.proposal.fareType,
      'flag__private': 'Private' === this.offer.proposal.fareType,
    };
  }

  get classesTooltip() {
    switch (this.offer.proposal.fareType) {
      case 'Public':
        return translate('search-air.public-fare');
      case 'Nego':
        return translate('search-air.nego-fare');
      case 'Private':
        return translate('search-air.private-fare');
      default:
        return translate('search-air.public-fare');
    }
  }

  get basketItems() {
    return BasketStore.basketItems;
  }

  get item() {
    return this.basketItems.find(basketItem => {
      return basketItem.id === this.offer.tripItemId;
    });
  }

  get modifiedItemAgencyFee() {
    return this.item && this.item.price.agencyFee ? this.item.price.agencyFee : false;
  }

  get modifiedItemAgencyMarkup() {
    return this.item && this.item.price.agencyMarkup ? this.item.price.agencyMarkup : false;
  }

  get modifiedItemSystemMarkup() {
    return this.item && this.item.price.systemMarkup ? this.item.price.systemMarkup : false;
  }

  get modifiedItemProviderPrice() {
    let item = this.basketItems.find(item => {
      return item.id === this.offer.tripItemId;
    });
    if (item && item.price.providerPrice) {
      return item.price.providerPrice;
    } else if (this.offer && this.offer.proposal && this.offer.proposal.price) {
      return this.offer.proposal.price;
    } else {
      return false;
    }
  }

  get totalPriceAmount() {
    let item = this.basketItems.find(entry => {
      return entry.id === this.offer.tripItemId;
    });
    if (item && item.price && item.price.total && item.price.total.amount) {
      return item.price.total.amount;
    } else {
      let price = this.offer.price.totalPrice.amount;
      if (this.modifiedItemAgencyFee && this.modifiedItemAgencyFee.amount) {
        price += this.modifiedItemAgencyFee.amount;
      }
      return price;
    }
  }

  get hasEditFeePermission() {
    return this.$hasAccess('CanEditBookingFee');
  }

  get hasAdvancedOfferInfo() {
    return this.$hasAccess('CanReadAdvancedOfferInfo');
  }

  get isPriceDetailedComponentsVisible() {
    return AirSearchStore.priceDetailedComponentsVisible;
  }

  get canShowBookingFeeAddon() {
    return !!(this.modifiedItemAgencyFee && this.modifiedItemAgencyFee.amount);
  }

  get canShowBookingAgencyMarkupAddon() {
    return !!(this.modifiedItemAgencyMarkup && this.modifiedItemAgencyMarkup.amount);
  }

  get canShowBookingSystemMarkupAddon() {
    return !!(this.modifiedItemSystemMarkup && this.modifiedItemSystemMarkup.amount);
  }

  fareLabelInfo(name) {
    if (!name) {
      return '';
    }

    return translate('search-air.fare-basis-label') + '<br>' + name;
  }

  priceFormat(price) {
    return formatPrice(price);
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  shouldFadeLeg(legIndex) {
    if (-1 === [
      'airModification',
      'airModificationTravellers'
    ].indexOf(this.$route.name || '')) {
      return false;
    }
    if (legIndex >= AirSearchStore.exchangeLegsSelectedForEdit.length) {
      return false;
    }
    return !AirSearchStore.exchangeLegsSelectedForEdit[legIndex];
  }

  formatPrice(price) {
    if (!price) {
      return '';
    }
    return price.currency.symbol + ' ' + (Math.round(100 * price.amount) / 100).toFixed(2);
  }

  getSystemMarkupPart(proposal) {
    let tooltip = '';

    if (proposal.rawProviderPrice) {
      tooltip += `<span>${translate('basket.raw-provider-price')} ${proposal.rawProviderPrice.currency.symbol || proposal.rawProviderPrice.currency.code} ${formatPrice(proposal.rawProviderPrice.amount)}</span>`;
    }
    if (proposal.systemMarkup) {
      tooltip += `<span class="mb-3">${translate('basket.system-markup')} ${proposal.systemMarkup.currency.symbol || proposal.systemMarkup.currency.code} ${formatPrice(proposal.systemMarkup.amount)}</span>`;
    }

    return tooltip;
  }

  getAdvancedOfferInfoPart(proposal) {
    let tooltip = '';

    if (this.hasAdvancedOfferInfo && this.modifiedItemProviderPrice) {
      tooltip += `<span>${translate('basket.incl')} ${this.modifiedItemProviderPrice.currency.symbol || this.modifiedItemProviderPrice.currency.code} ${formatPrice(this.modifiedItemProviderPrice.amount)} ${translate('search-air.provider-price')}`;

      if (proposal.taxPrice !== undefined && proposal.taxPrice != null) {
        tooltip += ` (${proposal.taxPrice.currency.symbol || proposal.taxPrice.currency.code} ${formatPrice(proposal.taxPrice.amount)} ${translate('search-air.taxes')})`;
      }
      tooltip += `</span>`;
    }

    return tooltip;
  }

  getAgencyMarkupAndFeePart(proposal) {
    let tooltip = '';

    if (proposal.agencyMarkup) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.agencyMarkup.currency.symbol || proposal.agencyMarkup.currency.code} ${formatPrice(proposal.agencyMarkup.amount)} ${translate('basket.agency-markup-smallcaps')}</span>`;
    }
    if ((this.isPriceDetailedComponentsVisible || this.hasEditFeePermission || this.hasAdvancedOfferInfo) && (this.modifiedItemAgencyFee && this.modifiedItemAgencyFee.amount > 0)) {
      tooltip += `<span>${translate('basket.incl')} ${proposal.taxPrice.currency.symbol || proposal.taxPrice.currency.code} ${formatPrice(this.modifiedItemAgencyFee.amount)} ${translate('search-air.fee')}</span>`;
    }

    return tooltip;
  }

  tooltip() {
    return [
      this.getSystemMarkupPart(this.offer.price),
      this.getAdvancedOfferInfoPart(this.offer.price),
      this.getAgencyMarkupAndFeePart(this.offer.price),
    ].join('');
  }
}
