import MissedSavingsConfiguration from '@/modules/settings/missed-savings/MissedSavingsConfiguration.vue';

export default {
  path: ':configurationId/configuration',
  name: 'missed-savings-configuration',
  component: MissedSavingsConfiguration,
  displayName: `Client email notifications configuration`,
  meta: {
    breadCrumb: 'Fees configuration',
    introClass: 'intro-settings',
    permission: 'CanEditMissedSavings',
    parentName: 'missedSavings',
    depthCategory: 'missedSavings',
    depth: 2,
  }
};