
export interface Query {
  query: string;
}

export interface TravellerModel {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  companyId: string;
  businessUnitId: string;
  isMainTraveller?: boolean;
  isVirtual?: boolean;
  isGuest?: boolean;
  passengerTypeCode?: string;
  businessUnitName?: string;
  dateOfBirth?: string;
  dateOfBirthObligation?: DateOfBirthObligation;
  dateOfBirthRequired?: boolean;
  dateOfBirthRecommended?: boolean;
  age: number | null;
}

export enum DateOfBirthObligation {
  None = 'None',
  Recommended = 'Recommended',
  Required = 'Required',
}

export interface LocationModel {
  code: string;
  type: string;
  airportName?: string;
  cityName?: string;
  countryCode?: string;
  name?: string;
}

export interface AirLocationModel {
  countryName: string;
  countryCode: string;
  cityName: string;
  cityCode: string;
  airportName: string;
  airportCode: string;
  type: string;
}

export interface RailLocationModel {
  codes: Codes;
  latitude: number;
  longitude: number;
  type: string;
  stationName: string;
  stationCodes: Codes;
  cityName: string;
  cityCodes: Codes;
  countryCode: string;
  countryName: string;
}

export interface Codes {
  Ntv: string;
}

export interface AirportsName {
  airportName: string;
  airportCode: string;
}

export interface ServerError {
  code: string;
  message: string;
  field: string | null;
}

export interface DisplayTimeLabels {
  start: string;
  end: string;
}

export enum searchModes {
  oneWay = 'OneWay',
  roundTrip = 'RoundTrip',
  multiLeg = 'MultiLeg',
}

export enum cabinClassEnum {
  AnyClass = 'Any',
  Economy = 'Economy',
  PremiumEconomy = 'PremiumEconomy',
  Business = 'Business',
  First = 'First',
}

export enum serviceClassEnum {
  None = 'None',
  Flight = 'Flight',
  Train = 'Train',
  Hotel = 'Hotel',
  Car = 'Car',
  Pkp = 'PKP',
}
