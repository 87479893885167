import notifications from '@/router/routes/expense/notifications.route';
import myMissions from '@/router/routes/expense/my-missions.route';
import approvalTasks from '@/router/routes/expense/approval-tasks.route';
import expenseList from '@/router/routes/expense/expense-list.route';

export default {
  menu: [
    myMissions,
    expenseList,
    notifications,
    approvalTasks,
  ],
};
