import { AxiosResponse } from 'axios';
import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';

import {
  CustomFieldDictionaryModel,
  UpdateCustomFieldDictionaryMessage,
  UpdateCustomFieldDictionaryItemMessage,
  EditedCustomFieldDictionaryItem,
  CustomFieldDictionaryItemEntry,
  CreateCustomFieldDictionaryMessaage,
} from './custom-fields.model';

export class CustomFieldDictionariesApiClass {
  public createCustomFieldDictionary(message: CreateCustomFieldDictionaryMessaage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaries',
      method: RequestMethod.POST
    }, {}, message);
  }

  public getCustomFieldDictionaries(companyId: string, configurationId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaries?CompanyId={companyId}&configurationId={configurationId}',
      method: RequestMethod.GET
    }, { companyId, configurationId }, {});
  }

  public updateCustomFieldDictionary(message: UpdateCustomFieldDictionaryMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/customFieldDictionaries/${message.customFieldDictionaryId}`,
      method: RequestMethod.PUT
    }, { customFieldDictionaryId: message.customFieldDictionaryId }, message.dictionary);
  }

  public deleteCustomFieldDictionary(customFieldDictionaryId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaries/{customFieldDictionaryId}',
      method: RequestMethod.DELETE
    }, { customFieldDictionaryId }, {});
  }

  public getSingleCustomFieldDictionary(customFieldDictionaryId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaries/{customFieldDictionaryId}',
      method: RequestMethod.GET,
    }, { customFieldDictionaryId }, {});
  }

  public getCustomFieldDictionaryNames(configurationId: string, companyId: string, active?: boolean): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/customFieldDictionaries/names?configurationId=${ configurationId }&companyId=${ companyId }${ active ? '&active={active}' : '' }`,
      method: RequestMethod.GET,
    }, { configurationId, companyId, active }, {});
  }

  public createCustomFieldDictionaryItem(customFieldDictionaryId: string, item: EditedCustomFieldDictionaryItem): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaries/{customFieldDictionaryId}/items',
      method: RequestMethod.POST,
    }, { customFieldDictionaryId }, item);
  }

  public updateCustomFieldDictionaryItem(message: UpdateCustomFieldDictionaryItemMessage): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/customFieldDictionaryItems/${message.customFieldDictionaryItemId}`,
      method: RequestMethod.PUT,
    }, message.customFieldDictionaryItemId, message.item);
  }

  public deleteCustomFieldDictionaryItem(customFieldDictionaryItemId: any): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaryItems/{customFieldDictionaryItemId}',
      method: RequestMethod.DELETE,
    }, { customFieldDictionaryItemId }, {});
  }

  public getCustomFieldDictionaryItem(customFieldDictionaryItemId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + '/customFieldDictionaryItems/{customFieldDictionaryItemId}',
      method: RequestMethod.GET,
    }, { customFieldDictionaryItemId }, {});
  }

  public getCustomFieldDictionaryItems(customFieldDictionaryId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/customFieldDictionaries/{customFieldDictionaryId}/items`,
      method: RequestMethod.GET,
    }, { customFieldDictionaryId }, {});
  }

  public getCustomFieldDictionaryItemNames(customFieldDictionaryId: string, active?: boolean): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiProfile + `/customFieldDictionaries/{customFieldDictionaryId}/itemNames${ active ? '?active={active}' : '' }`,
      method: RequestMethod.GET,
    }, { customFieldDictionaryId, active }, {});
  }
}

export const CustomFieldDictionariesApi: CustomFieldDictionariesApiClass = new CustomFieldDictionariesApiClass();