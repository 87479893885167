





















































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

import AccountStore from '@/store/account.store';
import { AnimatedIn } from '@/core/decorators/animated-in.decorator';
import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import EventBus from '@/services/event-handler';
import SearchStore from '../search.store';
import { DictionaryApi } from '@/api/dictionary/dictionary.api';
import { LanguageCode, HotelLocationModel} from '@/api/dictionary/dictionary.model';
import { searchModes } from '@/api/home/home.model';
import settings from '@/settings';
import HotelSearchStore from './hotel-search.store';
import BasketStore from '@/modules/basket/basket.store';

@Component({
  components: {
    Multiselect,
  }
})
export default class HotelSearch extends Vue {
  @Prop({ default: false }) isGuestMode!: boolean;
  @Prop({ default: false }) isInBasketAddSegment!: boolean;
  toLocations: HotelLocationModel[] = [];
  isToLoading: boolean = false;
  withMeal: boolean = false;
  refundable: boolean = false;
  shouldFocus: boolean = false;
  withNego: boolean = false;
  formats = {
    weekdays: 'WWW',
  };

  distanceOptions = [...Array(15)].map((item, index) => ({
    value: index + 1,
    label: `${index + 1} km`
  }));
  
  searchModes = searchModes;
  today = moment();

  get searchDate() {
    return {
      start: moment(this.hotelSearchParameters.checkInDate).toDate(),
      end:  moment(this.hotelSearchParameters.checkOutDate).toDate(),
    };
  }

  set searchDate(data: any) {
    if (data && data.start) {
        this.hotelSearchParameters.checkInDate = data.start;
        this.hotelSearchParameters.checkOutDate = data.end;
    }
  }

  get hotelSearchParameters() {
    return SearchStore.getHotelDefaultState;
  }

  get canSearchMixedHotelRooms() {
    return AccountStore.HasPermission('CanSearchMixedHotelRooms');
  }

  get travellersSearchList() {
    return SearchStore.getTravellersState;
  }

  get basketLoaded() {
    return BasketStore.basket;
  }

  get searchTravellers() {
    if (this.isInBasketAddSegment && this.basketLoaded) {
      return this.basketLoaded.travellers;
    }
    return this.travellersSearchList.travellers;
  }

  get minCheckOutDate() {
    return this.hotelSearchParameters.checkInDate
      ? moment(this.hotelSearchParameters.checkInDate, 'YYYY-MM-DD').add(1, 'days')
      : moment();
  }

  get canShowAccommodationPackageRates() {
    return 'true' === settings.enableExternalAccommodationPackageRates;
  }

  get mobileDistance() {
    if (this.hotelSearchParameters.distance) {
      return this.distanceOptions.find(distance => { return distance.value === this.hotelSearchParameters.distance; });
    } else {
      return null;
    }
  }

  set mobileDistance(value) {
    if (value && value.value !== this.hotelSearchParameters.distance) {
      this.hotelSearchParameters.distance = value.value;
    }
  }

  get languageCode() {
    return AccountStore.current!.profile.displayLanguage.toUpperCase() as LanguageCode;
  }

  get rooms() {
    return HotelSearchStore.roomsOptions;
  }

  set rooms(value) {
    HotelSearchStore.setRoomsOptions(value);
  }


  optimalRadiusSwitch(value: boolean) {
    SearchStore.updateHotelDefaultState({
      ...this.hotelSearchParameters,
      distance: value ? null : 5,
    });
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isToLoading',
  })
  async findToLocation(query) {
    if (query && query.length > 2) {
      this.isToLoading = true;
      const response = await DictionaryApi.findHotelToLocation(query, this.languageCode);

      if (response && response.data) {
        this.toLocations = response.data;
      }

      this.isToLoading = false;
    } else {
      this.isToLoading = false;
      this.toLocations = [];
      if (this.hotelSearchParameters.to) {
        this.toLocations.push(this.hotelSearchParameters.to);
      }
    }
  }

  onReceive(data) {
    if (data) {
      const element: Vue = this.$refs['hotel-search-to'] as Vue;
      const from: Multiselect = element.$refs.multiselect as Multiselect;
      const searchInput: HTMLInputElement = from.$refs.search as HTMLInputElement;

      if (!searchInput) {
        return;
      }
      return searchInput && searchInput.focus();
    }
  }

  @AnimatedIn()
  animatedIn() {
    if (this.$router.currentRoute.name === 'basketAddSegment') {
      this.dateEnter();
    } else if (this.shouldFocus) {
      this.onReceive(true);
    }
  }

  onFocus() {
    this.shouldFocus = true;
  }

  focusOn(elementId) {
    if (window.innerWidth < 800) {
      return;
    }
    const vueEl = this.$refs[elementId] as Vue;
    if (!vueEl) {
      return;
    }
    const inputValue = vueEl.$el as HTMLInputElement;
    inputValue.getElementsByTagName('input')[0].focus();
  }

  prepareTo() {
    this.toLocations = [];
    if (this.hotelSearchParameters.to) {
      this.toLocations.push(this.hotelSearchParameters.to);
    }
  }

  dateEnter() {
    this.$emit('focusSearch');
  }

  isDateValid(date) {
    let timestamp = Date.parse(date);
    return isNaN(timestamp) ? false : true;
  }

  handleEnterPress() {
    EventBus.$emit('focus-on-search');
  }

  checkRooms() {
    let checkedPositive = false;
    if (!this.isInBasketAddSegment && HotelSearchStore.roomsOptions.length) {
      const numberOfProfiles = HotelSearchStore.roomsOptions.reduce((prev, cur) => {
        return cur.profiles.length + prev;
      }, 0);
      if (numberOfProfiles === this.searchTravellers.length) {
        const roomOptionsTravellers = HotelSearchStore.roomsOptions.reduce((prev, cur) => {
          return [...prev, ...cur.profiles];
        }, []);
        const items = (this.searchTravellers as any)
          .map(traveller => {
            return -1 < roomOptionsTravellers.indexOf(traveller.id);
          })
          .filter(item => !!item);

        if (items.length === this.searchTravellers.length) {
          checkedPositive = true;
        }
      }
    }

    if (!checkedPositive) {
      let rooms: any[] = [];
      this.searchTravellers.forEach((traveller, index) => {
        if (rooms.length < 3) {
          rooms.push({
            profiles: [
              traveller.id,
            ],
          });
        } else {
          rooms[index % 3].profiles.push(traveller.id);
        }
      });
      HotelSearchStore.setRoomsOptions(rooms);
    }
  }

  @Watch('hotelSearchParameters.checkInDate')
  onChangedDeparture(val) {
    if (val) {
      this.hotelSearchParameters.checkInDate = moment(this.hotelSearchParameters.checkInDate).format('YYYY-MM-DD');
    }
    if (moment(this.hotelSearchParameters.checkInDate).add(1, 'days').isAfter(this.hotelSearchParameters.checkOutDate)) {
        this.hotelSearchParameters.checkOutDate = moment(this.hotelSearchParameters.checkInDate).add(1, 'days').format('YYYY-MM-DD');
    }
    if (new Date(val).getTime() < new Date().getTime()) {
      this.hotelSearchParameters.checkInDate = moment().format('YYYY-MM-DD');
    }
  }

  @Watch('hotelSearchParameters.checkOutDate')
  onChangedReturn(val) {
    if (this.hotelSearchParameters.checkOutDate) {
      this.hotelSearchParameters.checkOutDate = moment(this.hotelSearchParameters.checkOutDate).format('YYYY-MM-DD');
    }
  }

  @Watch('searchTravellers')
  onSearchTravellersChange() {
    if (!this.isInBasketAddSegment) {
      return;
    }
    this.checkRooms();
  }



  created() {
    EventBus.$on('focus-from', this.onFocus);

    this.checkRooms();
  }

  beforeDestroy() {
    EventBus.$off('focus-from', this.onFocus);
  }
}
