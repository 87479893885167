
















































































import { Vue, Component, Watch } from 'vue-property-decorator';

import TrainSearchStore from './train-search.store';
import { router } from '@/router';
import TrainDetails from './TrainDetails.vue';
import TrainResultsShortRow from './TrainResultsShortRow.vue';
import accountStore from '@/store/account.store';
import EditTrainFeePopup from './EditTrainFeePopup.vue';
import EditTrainAgencyMarkupPopup from './EditTrainAgencyMarkupPopup.vue';

@Component({
  components: {
    TrainDetails,
    TrainResultsShortRow,
    EditTrainFeePopup,
    EditTrainAgencyMarkupPopup,
  }
})
export default class TrainResultDetailsView extends Vue {
  searchId: string | null = null;
  recomendationId: string | null = null;
  forcing: boolean = false;
  
  get offers() {
    return TrainSearchStore.offers;
  }

  get offer() {
    return this.offers.length ? this.offers.find(x => x.id === this.recomendationId) : null; 
  }

  get showModal() {
    return TrainSearchStore.showModal;
  }

  get prevSelectedOffer() {
    return TrainSearchStore.prevSelectedOffer;
  }

  get selectedOffer() {
    return TrainSearchStore.selectedOffer;
  }

  get basketId() {
    return TrainSearchStore.basketId;
  }

  get currentUser() {
    return accountStore.CurrentUser;
  }

  get popupButtonsClasses() {
    return {
      'disabled': this.forcing,
    };
  }

  get popupForceButtonClasses() {
    return {
      'disabled': this.forcing,
      'disabled-active': this.forcing,
    };
  }

  get modifyMode() {
    return -1 < [
      'trainModificationDetailsView'
    ].indexOf(this.$route.name || '');
  }

  @Watch('$route.params.searchId', { immediate: true })
  routeHandler() {
    this.initView();
  }

  initView() {
    this.searchId = this.$route.params.searchId;
    this.recomendationId = this.$route.params.recomendationId;
    let legNumber = this.$route.params.leg ? this.$route.params.leg : 1;
    let searchId = this.$route.params.searchId;

    if (this.searchId) {   
      TrainSearchStore.getOffers({ searchId, legNumber}); 
    }
  }

  GoToBasketWithoutChanges() {
    TrainSearchStore.stopSearch();
    router.push({
      name: 'basket',
      params: { id: this.basketId }
    });
  }

  async BackToResults() {
    let searchId = this.$route.params.searchId;
    let legNumber = this.$route.params.leg;
    TrainSearchStore.stopSearch();
    TrainSearchStore.setSelectedOfferId('');
    await TrainSearchStore.deleteSelectedLegAndGetSession({searchId, legNumber});
    TrainSearchStore.setShowModal(false);
  }

  AddOfferToCartForce() {
    if (this.forcing) {
      return;
    }
    this.forcing = true;
    this.AddOfferToCart(true);
    window.scrollTo(0, 0);
  }

  AddOfferToCart(force = false) {
    let request = {
      forceAdd: force,
      bookerId: this.currentUser!.profile.id,
      type: 'Rail',
      searchId: this.$route.params.searchId
    };

    TrainSearchStore.addOfferToCart(request);
  }

  async selectOffer(offer) {
    let data = {
       searchId: this.$route.params.searchId,
       leg: this.$route.params.leg,
       offer: offer
    };

    await TrainSearchStore.selectOffer(data);
  }
  
  showResults() {
    if (this.modifyMode) {
      router.push({
        name: 'trainModification',
        params: {
          searchId: this.$route.params.searchId,
          basketId: this.$route.params.basketId,
          leg: this.$route.params.leg,
        },
      });
    } else {
      router.push({ 
        name: 'train',
        params: {
          searchId: this.$route.params.searchId,
          leg: this.$route.params.leg,
        } 
      });
      }
    
  }

}
