import MarkupRuleAir from '@/modules/settings/agency-markup/MarkupRuleAir.vue';

export default {
  path: ':configurationId?/air-markup-rule',
  name: 'markup-rule-air',
  component: MarkupRuleAir,
  displayName: 'Air markup rule definition',
  meta: {
    breadCrumb: 'Air markup rule definition',
    introClass: 'intro-settings',
    permission: 'CanEditAgencyMarkupsConfiguration',
    parentName: 'markups',
    depthCategory: 'markups',
    depth: 3,
  }
};