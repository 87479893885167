

























import { Vue, Prop, Component } from 'vue-property-decorator';

import BasketStore from './basket.store';

@Component
export default class TripActionErrorPopup extends Vue {
  @Prop() show!: boolean;

  closeTripActionErrorPopup() {
    BasketStore.setShowTripActionErrorPopup(false);
    BasketStore.processBasket();
  }
}

