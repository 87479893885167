










































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { maxLength, required, email } from 'vuelidate/lib/validators';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';

import ClientEmailNotificationsStore from '@/modules/settings/client-email-notifications/client-email-notifications.store';
import SettingsStore from '../settings.store';
import ClientEmailClientEmailNotificationsManyEmailsConfiguration from './ClientEmailNotificationsManyEmailsConfiguration.vue';

@Component({
  components: {
    ClientEmailClientEmailNotificationsManyEmailsConfiguration
  }})
export default class ClientEmailNotificationsConfiguration extends Vue {
  $v;
  formPending: boolean = false;
  imagesConst: string = '/assets/img/loader/1.gif';
  languageOptions: LanguagesModel[] = [];
  Form: any = {
    name: '',
    noPricesInEmail: false,
    includeInCc: [],
    includeEnabled: false,
    incidentEmailReceivers: [],
    incidentEmailReceiversEnabled: false,
    sendEmailToManager: false,
    customContactDetails: [],
    incidentEmailLanguageCode: null,
  };

  @Validation()
  validationObject() {
    return {
      Form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        includeInCc: {
          $each: {
            value: {
              email,
              maxLength: maxLength(320),
              required,
            },
          }
        },
        customContactDetails: {
          $each: {
            label: {
              required,
              maxLength: maxLength(100),
            },
            value: {
              required,
              maxLength: maxLength(250),
            },
          },
        },
        incidentEmailReceivers: {
          $each: {
            value: {
              email,
              maxLength: maxLength(320),
              required,
            },
          }
        }
      }
    };
  }

  get currentConfiguration() {
    return ClientEmailNotificationsStore.currentConfiguration;
  }
  
  get readOnly() {
    return !this.$hasAccess('CanEditClientEmailNotifications');
  }

  get inEditMode() {
    return this.$route.params.configurationId !== 'new';
  }

  get errMessages() {
    return ClientEmailNotificationsStore.errMessages;
  }

  get showErrMessages() {
    return ClientEmailNotificationsStore.showError;
  }

  get loading() {
    return ClientEmailNotificationsStore.loading;
  }

  get canShowCustomError() {
    return ClientEmailNotificationsStore.canShowCustomError;
  }



  addCustomContactDetails() {
    this.Form.customContactDetails.push({
      label: '',
      value: '',
    });
  }

  deleteCustomContactDetails(index) {
    this.Form.customContactDetails.splice(index, 1);
  }

  get language() {
    if (this.languageOptions) {
      const searchOption = this.Form.incidentEmailLanguageCode ? this.Form.incidentEmailLanguageCode : 'en';
      return this.languageOptions.find((option) => {
        return option.code === searchOption;
      });
    }
  }

  set language(option) {
    if (option) {
      this.Form.incidentEmailLanguageCode = option.code;
    }
  }

  goToConfigurationList() {
    this.$router.push({
      name: 'client-email-notifications-configurations',
      params: {
        id: this.$route.params.id
      }
    });
  }

  resetStoreErrors() {
    ClientEmailNotificationsStore.clearErrors();
  }

  removeEmail(index) {
    this.Form.includeInCc.splice(index, 1);
  }

  addEmail() {
    this.Form.includeInCc.push({
      value: '',
    });
  }

  removeIncidentEmailReceiver(index) {
    this.Form.incidentEmailReceivers.splice(index, 1);
  }

  addIncidentEmailReceiver() {
    this.Form.incidentEmailReceivers.push({
      value: '',
    });
  }

  async saveConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        rootCompanyId: this.$route.params.id,
        name: this.Form.name,
        noPricesInEmail: this.Form.noPricesInEmail,
        includeInCc: this.Form.includeInCc.map(o => o.value),
        incidentEmailReceivers: this.Form.incidentEmailReceivers.map(o => o.value),
        sendEmailToManager: this.Form.sendEmailToManager,
        customContactDetails: this.Form.customContactDetails,
        incidentEmailLanguageCode: this.Form.incidentEmailLanguageCode,
      };
      await ClientEmailNotificationsStore.createConfiguration(request);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-email-notifications.config-saved'),
        message: translate('settings-email-notifications.config-saved-message')
      };
      EventHandler.$emit('show-toast', obj);
      if (!this.errMessages.length) {
        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  async updateConfiguration() {
    this.$v.Form.$touch();
    if (!this.$v.Form.$invalid) {
      this.formPending = true;
      this.resetStoreErrors();
      let request = {
        configurationId: this.$route.params.configurationId,
        name: this.Form.name,
        noPricesInEmail: this.Form.noPricesInEmail,
        includeInCc: this.Form.includeInCc.map(o => o.value),
        incidentEmailReceivers: this.Form.incidentEmailReceivers.map(o => o.value),
        sendEmailToManager: this.Form.sendEmailToManager,
        customContactDetails: this.Form.customContactDetails,
        incidentEmailLanguageCode: this.Form.incidentEmailLanguageCode,
      };
      await ClientEmailNotificationsStore.updateConfiguration(request);
      const obj = {
        type: translate('common.success'),
        title: translate('settings-email-notifications.config-saved'),
        message: translate('settings-email-notifications.config-saved-message')
      };
      EventHandler.$emit('show-toast', obj);
      if (!this.errMessages.length) {
        this.goToConfigurationList();
      }
      this.formPending = false;
    }
  }

  async loadLanguages() {
    try {
      const result = await LanguagesApi.getLanguages();
      if (result && result.data) {
        this.languageOptions = result.data;
      }

    } catch (error) {
      ClientEmailNotificationsStore.setErrors(this.$handleErrors(error, true));
      ClientEmailNotificationsStore.setShowError(true);
    }
  }

  mapFormData(data?: any) {
    if (data) {
      this.Form = {
        configurationId: data.configurationId,
        name: data.name,
        rootCompanyId: data.rootCompanyId,
        providerConfigurations: data.providerConfigurations,
        noPricesInEmail: data.noPricesInEmail,
        includeInCc: [],
        includeEnabled: !!data.includeInCc.length,
        incidentEmailReceivers: [],
        incidentEmailReceiversEnabled: data.incidentEmailReceivers && !!data.incidentEmailReceivers.length,
        sendEmailToManager: data.sendEmailToManager,
        customContactDetails: data.customContactDetails,
        incidentEmailLanguageCode: data.incidentEmailLanguageCode,
      };
      this.$nextTick(() => {
        this.Form.includeInCc = data.includeInCc
          .map(value => {
            return {
              value,
            };
          });
      });
      this.$nextTick(() => {
        this.Form.incidentEmailReceivers = data.incidentEmailReceivers 
          ? data.incidentEmailReceivers.map(value => {
              return {
                value,
              };
            })
          : [];
      });
    } else {
      this.Form = {
        name: '',
        configurationId: '',
        rootCompanyId: SettingsStore.currentCompany!.rootCompanyId,
        providerConfigurations: [],
        includeInCc: [],
        includeEnabled: false,
        incidentEmailReceivers: [],
        incidentEmailReceiversEnabled: false,
        sendEmailToManager: false,
        customContactDetails: [],
        incidentEmailLanguageCode: null,
      };
    }
  }

  @Watch('Form.includeEnabled')
  onIncludeEnabledChange(value) {
    if (value) {
      this.Form.includeInCc = [{
        value: '',
      }];
    } else {
      this.Form.includeInCc = [];
    }
  }

  @Watch('Form.incidentEmailReceiversEnabled')
  onIncidentEmailReceiversEnabled(value) {
    if (value) {
      this.Form.incidentEmailReceivers = [{
        value: '',
      }];
    } else {
      this.Form.incidentEmailReceivers = [];
    }
  }

  async created() {
    if (this.inEditMode) {
      await ClientEmailNotificationsStore.getConfiguration(this.$route.params.configurationId);
      this.mapFormData(JSON.parse(JSON.stringify(this.currentConfiguration)));
    } else {
      this.mapFormData();
    }

    await this.loadLanguages();
    
    if (!this.Form.incidentEmailLanguageCode) {
      this.language = this.languageOptions.find((option) => option.code === 'en');
    }
  }
}
