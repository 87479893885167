import YourLatestBasket from '@/modules/basket/YourLatestBasket.vue';
import { AnimationName } from '@/modules/layout/layout.model';

export default {
  path: '/your-latest-basket',
  name: 'yourLatestBasket',
  components: {
    default: YourLatestBasket,
  },
  meta: {
    introClass: 'intro-choose-travellers',
    permission: 'SelectTravellersForSearch',
    mobile: true,
    transitions: {
      intro: {
        home: AnimationName.LEFT,
        $default: AnimationName.FADE_UP
      },
    }
  }
};
