export interface PaymentCardProfileModel {
  id: string;
  displayName: string;
  holderFirstName: string;
  holderLastName: string;
  maskedNumber: string;
  expireDate: string;
  type: any | PaymentCardType | null;
  email: string;
  phoneNumber: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateRegion: string;
  postalCode: string;
  countryCode: string;
}

export interface PaymentCardModelResponse {
  expireDate: string;
  holderName: string;
  id: string;
  maskedNumber: string;
  displayName: string;
  type: any | PaymentCardType | null;
}

export interface PaymentCardModel {
  transactionId: string;
  expireDate: string;
  holderFirstName: string;
  holderLastName: string;
  displayName: string;
  type: any | PaymentCardType | null;
  email: string | null;
  phoneNumber: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateRegion: string;
  postalCode: string;
  countryCode?: string;
  country?: any;
  selectedProfile?: any;
  phone?: Phone;
}

export interface Phone {
  code?: string | null;
  number?: string;
  isMobile?: boolean;
}

export interface UpdatePaymentCardMessage {
  expireDate: string;
  holderFirstName: string;
  holderLastName: string;
  displayName: string;
  type: any | PaymentCardType | null;
  email: string | null;
  phoneNumber: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateRegion: string;
  postalCode: string;
  countryCode: string;
  phone?: Phone;
}

export enum PaymentCardType {
  Unknown = 'Unknown',
  VisaCredit = 'VisaCredit',
  VisaElectron = 'VisaElectron',
  VisaDebit = 'VisaDebit',
  MasterCardCredit = 'MasterCardCredit',
  MasterCardDebit = 'MasterCardDebit',
  AmericanExpress = 'AmericanExpress',
  Maestro = 'Maestro',
  DinersClub = 'DinersClub',
  Discover = 'Discover',
  JapanCreditBureau = 'JapanCreditBureau',
  ChinaUnionPay = 'ChinaUnionPay',
  UATP = 'UATP',
}

export enum PaymentCardMainType {
  Unknown = 'Unknown',
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'AmericanExpress',
  Maestro = 'Maestro',
  DinersClub = 'DinersClub',
  Discover = 'Discover',
  UATP = 'UATP',
}