import settings from '@/settings';
import { http, RequestMethod } from '@/services/http';
import { AxiosResponse, CancelTokenSource } from 'axios';
import {
  AccommodationSearchSessionMetadata,
  InitAccommodationSearchMessage,
  AccommodationSearchSessionForm,
  AccommodationSearchResult,
  OffersQuery,
  AccommodationOfferSearchResult,
  AgencyMarkup,
  AccommodationSearchSessionState,
  MapPropertiesResponse
} from './accommodation-search.model';

class AccommodationSearchApiClass {

  public initSearch(message: InitAccommodationSearchMessage): Promise<AxiosResponse<AccommodationSearchSessionMetadata>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/v2/search',
      method: RequestMethod.POST,
    }, {}, message);
  }

  public initSearchForMission(message: InitAccommodationSearchMessage, missionId: string): Promise<AxiosResponse<AccommodationSearchSessionMetadata>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/v2/search?missionId={missionId}',
      method: RequestMethod.POST,
    }, { missionId }, message);
  }

  public addSegment(message: InitAccommodationSearchMessage, basketId: string): Promise<AxiosResponse<AccommodationSearchSessionMetadata>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/v2/search?basketId={basketId}',
      method: RequestMethod.POST,
    }, { basketId }, message);
  }

  public addSegmentForMission(message: InitAccommodationSearchMessage, basketId: string, missionId: string): Promise<AxiosResponse<AccommodationSearchSessionMetadata>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/v2/search?basketId={basketId}&missionId={missionId}',
      method: RequestMethod.POST,
    }, { basketId, missionId }, message);
  }

  public getSearchSession(searchId: string): Promise<AxiosResponse<AccommodationSearchSessionForm>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/v2/search/{searchId}',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public getProperties(query: OffersQuery, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<AccommodationSearchResult>> {
    return http.execute({
      path: settings.apiAccommodationEngine + `/search/{searchId}/properties?${query.sortQuery}&async=true`,
      method: RequestMethod.GET,
    }, query, {}, false, cancelTokenSource);
  }

  public getOffers(searchId: string, propertyId: string): Promise<AxiosResponse<AccommodationOfferSearchResult>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/search/{searchId}/properties/{propertyId}/offers',
      method: RequestMethod.GET,
    }, { searchId, propertyId }, {});
  }

  public getOfferConditions(provider: string, offerId: string, searchId: string): Promise<AxiosResponse<AccommodationOfferSearchResult>> {
    let url = settings.apiAccommodationEngine + '/v2/offers/{offerId}/conditions?provider={provider}';
    if (searchId !== undefined) {
      url += '&searchId={searchId}';
    }
    return http.execute({
      path: url,
      method: RequestMethod.GET,
    }, { provider, offerId, searchId }, {});
  }

  public updateSearchFilters(searchId: string, request: any, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<AccommodationSearchResult>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/search/{searchId}/offers/filters',
      method: RequestMethod.PUT,
    }, { searchId }, request, false, cancelTokenSource);
  }

  public getPropertyContent(propertyId: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/properties/{propertyId}',
      method: RequestMethod.GET,
    }, { propertyId }, {});
  }

  public setAgencyMarkupFilters(searchId: string, request: any): Promise<AxiosResponse<AgencyMarkup>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/search/{searchId}/offers/agency-markup',
      method: RequestMethod.PUT,
    }, { searchId }, request, false);
  }

  public getSearchState(searchId, stateId?, cancelTokenSource?: CancelTokenSource): Promise<AxiosResponse<AccommodationSearchSessionState>> {
    let url = '/search/{searchId}/state';
    if (stateId) {
      url += '?stateId={stateId}';
    }
    return http.execute({
      path: settings.apiAccommodationEngine + url,
      method: RequestMethod.GET,
    }, { searchId, stateId }, {}, false, cancelTokenSource);
  }

  public getMapProperties(searchId: string): Promise<AxiosResponse<MapPropertiesResponse>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/search/{searchId}/map-properties?async=true',
      method: RequestMethod.GET,
    }, { searchId }, {});
  }

  public getProviderBookingDetails(bookingId: string, provider: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/bookings/{bookingId}/status?provider={provider}',
      method: RequestMethod.GET,
    }, { bookingId, provider}, {});
  }

  public getProviderRawBookingDetails(bookingId: string, provider: string): Promise<AxiosResponse<any>> {
    return http.execute({
      path: settings.apiAccommodationEngine + '/bookings/{bookingId}/status/raw?provider={provider}',
      method: RequestMethod.GET,
    }, { bookingId, provider}, {});
  }
}

export const AccommodationSearchApi: AccommodationSearchApiClass = new AccommodationSearchApiClass();
