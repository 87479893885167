































import { Vue, Component, Prop } from 'vue-property-decorator';
import AirSearchStore from '@/modules/search/air/air-search.store';

@Component({})
export default class UiNoOffersTemplate extends Vue {
  @Prop() anyOfferFromFilter!: number;
  @Prop({ default: false }) anyProposals!: boolean;
  get noOffersImg() {
    return '/assets/img/NoContentGraphics.png';
  }
}
