import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import $handleErrors from '@/core/errors/handle-errors.service';
import _ from 'lodash';
import FaresMappingStore from './fare-mapping.store';
import  { FareMappingConfigurationApi } from '@/api/air-engine/fare-mapping-configuration.api';
import  { FareMappingConfigurations, FareMapping } from '@/api/air-engine/fare-mapping-configuration.model';
import { AirApi } from '@/api/air-engine/air-search.api';
import { SupplierName } from '@/services/supplier-name.service';
import { AirlinesString } from '@/services/airline-name.service';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'airFaresMapping'
})
class AirFaresMappingStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: FareMappingConfigurations = new FareMappingConfigurations();
  fareMappingList: any[] = [];
  selectedFareMapping: FareMapping | null = null;
  draftNew: string = 'draft-new';
  draftEdit: string = 'draft-edit';
  errors: any[] = [];
  enabledAirSuppliers: any[] = [];

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get fareMapping() {
    return this.fareMappingList;
  }

  get currentFlightFareMapping() {
    return this.selectedFareMapping;
  }

  get draftNewStatus() {
    return this.draftNew;
  }

  get draftEditStatus() {
    return this.draftEdit;
  }

  @Mutation
  selectFareMapping(value) {
    this.selectedFareMapping = value;
  }

  @Mutation
  setConfiguration(value) {
    this.currentConfiguration = value;
  }

  @Mutation
  clearCurrentConfiguration() {
    this.currentConfiguration = new FareMappingConfigurations();
  }

  @Mutation
  setFareMappingConfigurations(value) {
    this.currentConfiguration.fareMappingConfigurations = value;
  }

  @Mutation
  addFareMapping(value) {
    this.fareMappingList.unshift({
      ...value
    });
  }

  @Mutation
  setFareMappingList(value) {
    this.fareMappingList = value;
  }

  @Mutation
  setErrors(value) {
    this.errors = value;
  }

  @Mutation
  clearErrors() {
    this.errors = [];
  }

  @Mutation
  setEnabledAirSuppliers(value) {
    this.enabledAirSuppliers = value;
  }

  @Action
  editFareMapping(value) {
    let oldValIndex = this.fareMappingList.findIndex(code => {
      return _.isEqual(code, this.selectedFareMapping);
    });
    if (-1 < oldValIndex) {
      this.fareMappingList.splice(oldValIndex, 1, value);
    }
  }

  @Action
  removeFareMapping(value) {
    let oldValIndex = this.fareMappingList.findIndex(code => {
      return _.isEqual(code, value);
    });
    if (-1 < oldValIndex) {
      this.fareMappingList.splice(oldValIndex, 1);
    }
  }

  @Action
  async getAirFareMappingConfiguration(configurationId: string) {
    try {
      FaresMappingStore.setLoading(true);
      const result = await FareMappingConfigurationApi.getById(configurationId);
      if (result && result.data) {
        FaresMappingStore.setCurrentConfigurationName(result.data.name);
        FaresMappingStore.setConfigurationId(configurationId);
        this.setConfiguration(result.data);
        if (result.data.fareMappingConfigurations) {
          this.mapFareMapping(result.data.fareMappingConfigurations);
          this.setFareMappingConfigurations(result.data.fareMappingConfigurations);
        }
        FaresMappingStore.setLoading(false);
      }
    } catch (error) {
      FaresMappingStore.setErrMessages(error);
      FaresMappingStore.setLoading(false);
    }
  }

  @Action
  mapFareMapping(configuration: any[]) {
    let fares: any = [];
    let errors: any = [];
    configuration.forEach(cfg => {
      if (cfg.fareMapping && cfg.fareMapping.length) {
        let cfgFares = cfg.fareMapping.map(fare => {
          return {
            supplierName: SupplierName(cfg.supplier),
            supplier: cfg.supplier,
            airlinesText: AirlinesString(fare.airlines),
            ...fare,
            id: _.uniqueId()
          };
        });
        fares.push(...cfgFares);
      }
      else if (cfg.error && !cfg.succeeded) {
        errors.push({
          supplier: cfg.supplier,
          errorData: cfg.error,
          error: $handleErrors(cfg.error)
        });
      }
    });
    this.setErrors(errors);
    this.setFareMappingList(fares);
  }

  @Action
  async updateConfiguration({ configurationId, name, fareMappingConfigurations }) {
    try {
      FaresMappingStore.setLoading(true);
      FaresMappingStore.clearErrMessages();
      const result = await FareMappingConfigurationApi.update({ configurationId, params: { name, fareMappingConfigurations }});
      if (result && result.data) {
        this.getAirFareMappingConfiguration(configurationId);
        if (result.data && (result.data as any[]).length) {
          let errorElements = (result.data as any[]).filter(element => {
            return element.error !== null;
          });
          if (errorElements && errorElements.length) {
            FaresMappingStore.setErrMessages([...errorElements.map(elem => { return elem.error; })]);
          }
        }
      }
    } catch (error) {
      FaresMappingStore.setErrMessages(error);
      FaresMappingStore.setLoading(false);
    }
  }

  @Action
  async getEnabledAirSuppliers() {
    try {
      FaresMappingStore.clearErrMessages();
      FaresMappingStore.setLoading(true);
      const result = await AirApi.getEnabledAirSuppliers();
      if (result && result.data) {
        this.setEnabledAirSuppliers(result.data);
      }
    } catch (error) {
      FaresMappingStore.setErrMessages(error);
    } finally {
      FaresMappingStore.setLoading(false);
    }
  }
}

export default getModule(AirFaresMappingStore);