



























import { Vue, Prop, Component } from 'vue-property-decorator';
import { translate } from '@/i18n';

@Component({})
export default class PaymentCarConfigurationVoucherType extends Vue {
    @Prop() item!: any;

    voucherOptions = [
    {
      value: 'FullCredit',
      label: translate('settings-payment-methods.voucher-type-full-credit'),
    },
    {
      value: 'VoucherValue',
      label: translate('settings-payment-methods.voucher-type-value'),
    },
  ];


  get voucherType() {
    return this.voucherOptions.find(item => item.value === this.item.voucherType);
  }

  set voucherType(val) {
    if (!val) {
      this.item.voucherType = '';
      return;
    }

    this.item.voucherType = val.value;
  }
}
