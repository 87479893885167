









import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UiCustomMessageError extends Vue {
  @Prop() error!: any;
}

