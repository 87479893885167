import ExpenseWorkflowSettings from '@/modules/settings/expense-configuration/ExpenseWorkflowSettings.vue';

export default {
  path: ':configurationId?/workflow-settings/:itemId?/:isCopy?',
  name: 'workflow-settings',
  component: ExpenseWorkflowSettings,
  displayName: 'Workflow settings',
  meta: {
    breadCrumb: 'Workflow settings',
    introClass: 'intro-settings',
    permission: 'ReadPaymentOptionDefinition',
    parentName: 'expense-policies',
    depthCategory: 'expense-policies',
    depth: 3,
  }
};