







































































































































































import { Vue, Component } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { ExpenseConfigurationsApi } from '@/api/profile/expense-configuration.api';
import { ExpenseDefinitionModel } from '@/api/expense/expense.model';
import { CurrencyApi } from '@/api/currency/currency.api';
import { router } from '@/router';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, sameAs } from 'vuelidate/lib/validators';
import { Permission } from '@/const/permission.enum';
import moment from 'moment';

@Component({})
export default class ExpenseDefinition extends Vue {
  $v;
  currencyOptions: any[] = [];
  serverErrors: any[] = [];
  definition = new ExpenseDefinitionModel();

  linksOption = [
    {
      name: translate('settings-expense.manage-workflow-settings'),
      canShow: true,
      href: `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/workflow-settings/${router.currentRoute.params.itemId}`,
    },
    {
      name: translate('settings-expense.manage-expense-categories'),
      canShow: this.$hasAccess('ReadExpenseCategories'),
      href: `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/expense-categories/${router.currentRoute.params.itemId}`,
    },
    {
      name: translate('settings-expense.manage-payment-methods'),
      canShow: this.$hasAccess('ReadPaymentOptionDefinition'),
      href: `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/payment-methods/${router.currentRoute.params.itemId}`,
    },
    {
      name: translate('settings-expense.expense-model'),
      canShow: true,
      href: `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/expense-model/${router.currentRoute.params.itemId}`,
    },
    {
      name: translate('settings-expense.manage-per-diems'),
      canShow: true,
      href: `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/per-diems/${router.currentRoute.params.itemId}`,
    },
  ];

  @Validation()
  validationObject() {
    if (this.expensesList.length === 0) {
      return {
        definition: {
          name: {
            required,
            maxLength: maxLength(64)
          },
          description: {
            maxLength: maxLength(1024)
          },
          currency: {
            required,
          },
          validFrom: {
          },
          validTo: {
          },
          isActive: {
            sameAs: sameAs( () => true ),
          },
        },
      };
    } else {
      return {
        definition: {
          name: {
            required,
            maxLength: maxLength(64)
          },
          description: {
            maxLength: maxLength(1024)
          },
          currency: {
            required,
          },
          validFrom: {
          },
          validTo: {
          },
          isActive: {
          },
        },
      };
    }
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  get expensesList() {
    return ExpensePolicyStore.configurations;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get redirecToExpenseModel() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/expense-model/${this.definition.id}`;
  }

  async getCurrencies() {
    if (this.currencyOptions.length === 0) {
      try {
        const response = await CurrencyApi.getCurrencies();
        this.currencyOptions = response.data;
      } catch (error) {
        this.serverErrors = this.$handleErrors(error, true);
      }
    }
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }
    router.push({
      name: 'expense-configuration',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async createConfig() {
    ExpensePolicyStore.setErrMessages([]);
    try {
      ExpensePolicyStore.setLoading(true);
      const response = await ExpenseConfigurationsApi.createExpensePolicyConfiguration(
        {
          rootCompanyId: this.currentCompany!.rootCompanyId,
          name: this.configuration.name,
        },
        Permission.WriteExpensePolicyDefinition,
      );
      if (response && response.data) {
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      ExpensePolicyStore.setErrMessages(this.$handleErrors(error, true));
      ExpensePolicyStore.setShowError(true);
    } finally {
      ExpensePolicyStore.setLoading(false);
    }
  }

  async saveDefinition() {
    let configId = this.configuration.id ? this.configuration.id : undefined;
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);
    this.$v.definition.$touch();
    if (this.$v.definition.$pending || this.$v.definition.$error) {
      return;
    }

    if (configId === undefined) {
      const newConfig = await this.createConfig();
      if (newConfig) {
        configId = newConfig.id;
        ExpensePolicyStore.setConfigurationId(configId);
      }

    }
    if (this.errors && this.errors.length === 0) {
      let request = {
        companyId: this.currentCompany!.rootCompanyId,
        configurationId: configId,
        name: this.definition.name,
        description: this.definition.description,
        currency: this.definition.currency ? this.definition.currency.code : '',
        validFrom: this.definition.validFrom ? moment(this.definition.validFrom).format('YYYY-MM-DD') : '',
        validTo: this.definition.validTo ? moment(this.definition.validTo).format('YYYY-MM-DD') : '',
        isDefault: this.expensesList && this.expensesList.length === 0 ?  true : this.definition.isDefault,
        isActive: this.definition.isActive,
      };

      const isEdit = !!this.definition.id;
      if (isEdit && this.definition.id) {
        const message = {
          expensePolicyId: this.definition.id,
          params: request,
        };
        await ExpensePolicyStore.updateExpenseDefinition(message);
      } else {
        await ExpensePolicyStore.createExpenseDefinition(request);
      }

      if (this.errors && this.errors.length === 0) {
        router.push({
          name: 'expense-configuration',
          params: {
            configurationId: configId
          }
        });
      }
    }
  }

  validModelChange(date) {
    if (!date && this.definition && this.definition.validTo) {
      this.definition.validFrom = moment(this.definition.validTo).format('YYYY-MM-DD');
    }
  }

  async mapFormData() {
    if (this.expensePolicyId) {
      await ExpensePolicyStore.getExpensePolicyDetails(this.expensePolicyId);
      if (this.expenseDetails && this.currencyOptions) {
        this.definition = new ExpenseDefinitionModel(this.expenseDetails);
        this.definition.currency = this.currencyOptions.find(opt => opt.code === this.expenseDetails.currency);
      }
    }
  }

  created() {
    ExpensePolicyStore.clearErrMessages();
    if (!this.configuration.name) {
      if (this.$route.params.configurationId === undefined) {
        router.push({
          name: 'expense-configurations',
        });
      } else {
        ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
      }
    }

    this.getCurrencies().then(() => {
      this.mapFormData();
    });
  }
}
