import ApprovalTask from '@/modules/expense/approval-tasks/ApprovalTask.vue';
import ExpenseSubmenu from '@/modules/expense/ExpenseSubmenu.vue';

export default {
  path: '/expenses/approval-tasks/task/:id?/:taskId?',
  name: 'approval-task',
  components: {
    default: ApprovalTask,
    submenu: ExpenseSubmenu,
  },
  meta: {
    permission: 'Client',
  },
};
