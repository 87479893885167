import { render, staticRenderFns } from "./BackgroundTransition.vue?vue&type=template&id=3450c8a8"
import script from "./BackgroundTransition.vue?vue&type=script&lang=ts"
export * from "./BackgroundTransition.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports