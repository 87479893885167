import DisplayPreferencesConfiguration from '@/modules/settings/display-preferences/configurations/configuration/DisplayPreferencesConfiguration.vue';

export default {
  path: ':configurationId?/display-preferences-configuration',
  name: 'display-preferences-configuration',
  component: DisplayPreferencesConfiguration,
  displayName: 'Display Preferences Configuration',
  meta: {
    introClass: 'intro-settings',
    permission: 'CanEditDisplayPreferences',
    parentName: 'display-preferences-configurations',
    depthCategory: 'display-preferences',
    depth: 2,
  },
};
