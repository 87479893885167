export interface ConfigurationModel {
  rootCompanyId: string;
  name: string;
}

export class ProjectModel {
  id: string = '';
  rootCompanyId: string = '';
  name: string = '';
  description: string = '';
  code: string = '';
  validFrom: Date | string = '';
  validTo: Date | string = '';
  active: boolean = false;

  constructor(entry?) {
    if (!entry) {
      return;
    }
    if (entry.id !== undefined) {
      this.id = entry.id;
    }
    if (entry.name !== undefined) {
      this.name = entry.name;
    }
    if (entry.description !== undefined) {
      this.description = entry.description;
    }
    if (entry.code !== undefined) {
      this.code = entry.code;
    }
    if (entry.validFrom !== undefined) {
      this.validFrom = entry.validFrom ? new Date(entry.validFrom) : '';
    }
    if (entry.validTo !== undefined) {
      this.validTo = entry.validTo ? new Date(entry.validTo) : '';
    }
    if (entry.active !== undefined) {
      this.active = entry.active;
    }
  }
}