






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import layoutStore from './layout.store';
import {
  CSS_TRANSITION_END_EVENTS,
  COMMENT_NAME,
  SHOULD_ANIMATE_ON_START,
} from '@/modules/layout/layout.const';
import { SlotState } from './layout.model';

@Component({})
export default class AsideTransition extends Vue {
  transitionViewName: string = 'aside';

  get animating(): boolean {
    return layoutStore.slots.aside.transitioning;
  }

  get transition() {
    return layoutStore.slots.aside.transition;
  }

  get height(): number {
    return layoutStore.slots.aside.meta.height;
  }

  get width(): number {
    if (
      layoutStore.slots.aside.meta.empty ||
      layoutStore.asideHidden
    ) {
      return 0;
    }
    return layoutStore.slots.aside.meta.width || 0;
  }

  get classes() {
    return {
      'active': this.animating,
      'aside': !this.animating && !layoutStore.slots.aside.meta.empty,
      'toggling': layoutStore.asideToggling,
      'fullscreen': this.isLayoutFullscreen
    };
  }

  get styles() {
    if (!this.animating && layoutStore.asideHidden) {
      return {
        width: this.width + 'px',
        opacity: layoutStore.asideHidden ? 0 : 1,
        'pointer-events': 'none'
      };
    } else if (!this.animating) {
      return {};
    }
    return {
      height: this.height + 'px',
      width: this.width + 'px',
    };
  }

  get isLayoutFullscreen() {
    return layoutStore.isLayoutFullscreen;
  }

  async mounted() {
    layoutStore.setWrapper({
      slot: 'aside',
      wrapper: this.$refs.wrapper as HTMLElement,
    });
    this.updateDimensions();
  }

  entered() {
    layoutStore.animationFinished({
      slot: 'aside'
    });
  }

  entering() {
    this.updateDimensions();
  }

  updateDimensions() {
    layoutStore.updateDimensions({
      slot: 'aside',
    });
  }
}

