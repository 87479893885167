









































































































































































































import { Vue, Component} from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import ExpensePolicyStore from './expense-policy.store';
import { CustomFieldsExpenseApi } from '@/api/expense/custom-fields-expense.api';
import { CustomFieldExpenseModel } from '@/api/expense/custom-fields-expense.model';
import { router } from '@/router';
import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, numeric, minValue } from 'vuelidate/lib/validators';
import consts from '@/const/table.const';
import _ from 'lodash';

@Component({})
export default class CustomFieldExpense extends Vue {
  $v;
  serverErrors: any[] = [];
  definition = new CustomFieldExpenseModel();
  perPageOptions = consts.perPageOptions;
  perPage = consts.defaultPerPage;
  currentPage = 1;
  fields = {
    state: {
      label: translate('settings-expense.expense-state'),
      sortable: true,
    },
    required: {
      label: translate('settings-expense.is-required'),
      sortable: true,
    },
  };
  removePopup: boolean = false;
  expenseOptions = [
    'Expenditure', 'Mileage', 'PerDiem'
  ];

  @Validation()
  validationObject() {
    return {
      definition: {
        expenseType: {
          required,
        },
        customFieldDefinition: {
          required
        },
        order: {
          required,
          numeric,
          minValue
        },
      },
    };
  }

  get customFieldDefinition() {
    return ExpensePolicyStore.customFieldDefinition;
  }

  get expenseDetails() {
    return ExpensePolicyStore.expenseDetails;
  }

  get expenseExtensionId() {
    return router.currentRoute.params.expenseExtensionId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get configurationId() {
    if (router.currentRoute.params.configurationId) {
      return router.currentRoute.params.configurationId;
    } else {
      return this.configuration.id ? this.configuration.id : undefined;
    }
  }

  get expensePolicyId() {
    return router.currentRoute.params.itemId;
  }

  get configurationUrl() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId !== undefined ? this.configurationId + '/configuration' : 'configuration'}`;
  }

  get configurationManage() {
    return `/settings/${this.currentCompany!.rootCompanyId}/expense-policies/${this.configurationId}/expense-model/${this.$route.params.itemId}`;
  }

  get loading() {
    return ExpensePolicyStore.loading;
  }

  get customField() {
    return ExpensePolicyStore.customField;
  }

  get configuration() {
    return ExpensePolicyStore.configuration;
  }

  get errors() {
    return ExpensePolicyStore.errMessages;
  }

  get showError() {
    return ExpensePolicyStore.showError;
  }

  goToConfiguration() {
    let params: any = {
      id: this.$route.params.id,
      itemId: this.$route.params.itemId
    };
    if (this.configurationId !== undefined) {
      params.configurationId = this.configurationId;
    }

    router.push({
      name: 'expense-model',
      params: {
        id: this.$route.params.id,
        ...params
      }
    });
  }

  async saveDefinition() {
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);
    this.$v.definition.$touch();
    if (this.$v.definition.$pending || this.$v.definition.$error) {
      return;
    }
    if (this.errors && this.errors.length === 0) {
      let createRequest = {
        policyId: this.expensePolicyId,
        expenseType: this.definition.expenseType,
        conditions: this.definition.conditions,
        propertyType: 'CustomField',
        isActive: this.definition.isActive
      };

      let request = {
        policyId: this.expensePolicyId,
        expenseType: this.definition.expenseType,
        conditions: this.definition.conditions,
        customFieldDefinitionId: this.definition.customFieldDefinition.id,
        type: this.definition.customFieldDefinition.type,
        order: this.definition.order,
        isActive: this.definition.isActive
      };

      const isEdit = !!this.definition.expenseExstensionId;
      if (isEdit && this.definition.expenseExstensionId) {
        const message = {
          expenseExtensionId: this.definition.expenseExstensionId,
          params: request,
        };
        await ExpensePolicyStore.updateCustomField(message);
      } else {
        await ExpensePolicyStore.createCustomField({createRequest, request});
      }

      if (this.errors && this.errors.length === 0) {
        router.push({
          name: 'expense-model',
          params: {
            id: this.$route.params.id,
            configurationId: this.configurationId,
            itemId: this.$route.params.itemId
          }
        });
      }
    }
  }

  remove() {
    ExpensePolicyStore.setShowError(false);
    ExpensePolicyStore.setErrMessages([]);
    this.removePopup = true;
  }

  closeRemovePopup() {
    this.removePopup = false;
  }

  async removeDefinition() {
    try {
      if (this.definition && this.definition.id) {
        ExpensePolicyStore.setLoading(true);
        await CustomFieldsExpenseApi.removeCustomField(this.definition.expenseExstensionId);
        const obj = {
          type: translate('common.success'),
          title: translate('settings-expense.configuration-removed'),
          message: translate('settings-gds.info-removed')
        };
        EventHandler.$emit('show-toast', obj);
      }
    } catch (error) {
      ExpensePolicyStore.setErrMessages(error);
    } finally {
      if (!this.errors.length) {
        this.goToConfiguration();
      }
      ExpensePolicyStore.setLoading(false);
    }
  }

  async mapFormData() {
    if (this.expenseExtensionId) {
      await ExpensePolicyStore.getCustomField(this.expenseExtensionId);
      if (this.customField) {
        this.definition = new CustomFieldExpenseModel(this.customField);
        if (this.customFieldDefinition.length) {
          this.definition.customFieldDefinition = this.customFieldDefinition.find(field => field.id === this.customField.customFieldDefinitionId);
        }
      }
    }
    this.definition.conditions = _.sortBy(this.definition.conditions, c => c.index);
  }

  async created() {
    ExpensePolicyStore.clearErrMessages();
    if (this.$route.params.configurationId === undefined) {
      router.push({
        name: 'expense-model',
        params: {
          id: this.$route.params.id,
          configurationId: this.configurationId,
        }
      });
    } else {
      await ExpensePolicyStore.getExpensePolicyDetails(this.expensePolicyId);
      await ExpensePolicyStore.getExpenseConfiguration({ companyId: this.$route.params.id, configurationId: this.$route.params.configurationId });
    }
    await ExpensePolicyStore.getCustomFieldDefinition();
    this.mapFormData();
  }
}
