






























import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import EventBus from '@/services/event-handler';

@Component({})
export default class UiPopup extends Vue {
  @Prop() show!: boolean;
  @Prop() title!: string;
  @Prop() unclosable!: boolean;
  @Prop({ default: '' }) sizeType!: string;

  freezed: boolean = false;
  shown: boolean = false;
  postponedFreeze: number = -1;

  get classes() {
    const style = {
      'modal-show': this.shown,
      'freezed': this.freezed,
      'slim': this.sizeType === 'slim',
      'medium': this.sizeType === 'medium',
    };

    return style;
  }

  get showing() {
    return this.show || this.shown;
  }

  @Emit()
  close() {}

  @Watch('show', { immediate: true })
  onShow(value) {
    if (value) {
      this.$nextTick(() => {
        this.shown = true;
        const cont = this.$refs.container as HTMLElement;
        if (!cont) {
          return;
        }
        if (cont.scrollTo) {
          cont.scrollTo(0, 0);
        } else {
          cont.scrollTop = 0;
          cont.scrollLeft = 0;
        }
      });
    } else {
      this.$nextTick(() => {
        this.shown = false;
      });
    }
  }

  mounted() {
    EventBus.$on('freeze-popup', this.onFreezePopup);
    EventBus.$on('unfreeze-popup', this.onUnfreezePopup);
  }

  beforeDestroy() {
    EventBus.$off('freeze-popup', this.onFreezePopup);
    EventBus.$off('unfreeze-popup', this.onUnfreezePopup);
  }

  onFreezePopup(item) {
    if (item !== this.$refs.container) {
      return;
    }

    clearTimeout(this.postponedFreeze);
    this.postponedFreeze = setTimeout(() => {
      this.freezed = true;
    });
  }

  onUnfreezePopup(item) {
    if (item !== this.$refs.container) {
      return;
    }

    clearTimeout(this.postponedFreeze);
    this.freezed = false;
  }
}
