import ApprovalWorkflowConfigurations from '@/modules/settings/approval-workflow/ApprovalWorkflowConfigurations.vue';

export default {
  path: '',
  name: 'approval-workflow-configurations',
  component: ApprovalWorkflowConfigurations,
  displayName: 'Approval Workflow Configurations',
  meta: {
    introClass: 'intro-settings',
    permission: 'ReadApprovalWorkflow',
    depthCategory: 'approval-workflow',
    depth: 1,
    parentName: 'approval-workflow-configurations',
  }
};