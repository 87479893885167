
export interface TrainFeesConfiguration {
  configurationId?: string;
  isVisibleToCustomer: boolean;
  isFeeAdjustableToTravelPolicy: boolean;
  isEnabled: boolean;
  feesRules: TrainFeeRule[];
}

export interface TrainFeeRule {
  id?: any;
  status?: any;
  order: number;
  feeType: FeeTypeEnum;
  fixedFee: {
    amount: number;
    currency: Currency;
  };
  percentageFee: number;
  calculationMethod: FeesCalculationMethodEnum;
  paymentMethod: string;
  isLodgeCardInformationSelected: boolean;
  supplier: string[];
  tripTypes: string[];
  fareTypes: string[];
}

export interface Currency {
  code: string;
  symbol: string | null;
}

export enum FeeTypeEnum {
  Fixed = 'Fixed',
  Percentage = 'Percentage',
}

export enum FeesCalculationMethodEnum {
  PerBooking = 'PerBooking',
  PerPassengerInBooking = 'PerPassengerInBooking',
  PerLeg = 'PerLeg',
  PerPassengerInLeg = 'PerPassengerInLeg',
}

export enum SupplierEnum {
  Unknown = 'Unknown',
  Fake = 'Fake',
  Ntv = 'Ntv',
  Trenitalia = 'Trenitalia',
  Sncf = 'Sncf',
  Ouigo = 'Ouigo',
  Benerail = 'Benerail',
}
