
























































































































import { Vue, Component } from 'vue-property-decorator';
import SettingsStore from '@/modules/settings/settings.store';
import PaymentMethodsStore from './payment-methods.store';
import PaymentMethodsTrainStore from './payment-methods-train.store';
import PaymentMethodsCarStore from './payment-methods-car.store';
import PaymentMethodsAccommodationStore from './payment-methods-accommodation.store';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength } from 'vuelidate/lib/validators';
import PaymentMethodsAir from './PaymentMethodsAir.vue';
import PaymentMethodsTrain from './PaymentMethodsTrain.vue';
import PaymentMethodsCar from './PaymentMethodsCar.vue';
import PaymentMethodsAccommodation from './PaymentMethodsAccommodation.vue';

@Component({
  components: {
    PaymentMethodsAir,
    PaymentMethodsCar,
    PaymentMethodsAccommodation,
    PaymentMethodsTrain,
  }
})
export default class PaymentMethodsConfiguration extends Vue {
  $v;
  errors: any[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  loadingData: boolean = false;
  navLinks = [
    {
      value: 'Flight',
      name: translate('settings-travel-policy.flight'),
    },
    {
      value: 'Rail',
      name: translate('settings-travel-policy.rail'),
    },
    {
      value: 'Accommodation',
      name: translate('settings-travel-policy.accomodation')
    },
    {
      value: 'Car',
      name: translate('settings-travel-policy.car'),
    }
  ];

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(128)
      }
    };
  }

  get canShowCustomError() {
    return PaymentMethodsStore.canShowCustomError || PaymentMethodsTrainStore.canShowCustomError || PaymentMethodsCarStore.canShowCustomError || PaymentMethodsAccommodationStore.canShowCustomError;
  }

  get errMessages() {
    return PaymentMethodsStore.errMessages;
  }

  get showMessages() {
    return PaymentMethodsStore.showMessages;
  }

  get links() {
    return this.navLinks;
  }

  get loading() {
    return PaymentMethodsStore.loading || PaymentMethodsCarStore.loading;
  }

  get configurationId() {
    return this.$router.currentRoute.params.configurationId || '';
  }

  get storeConfigurationId() {
    return PaymentMethodsStore.configurationId;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get isFromManage() {
    return PaymentMethodsStore.isFromManage ||
      PaymentMethodsTrainStore.isFromManage ||
      PaymentMethodsCarStore.isFromManage ||
      PaymentMethodsAccommodationStore.isFromManage;
  }

  get currentConfiguration() {
    return PaymentMethodsStore.configuration;
  }

  get currentConfigurationName() {
    return PaymentMethodsStore.configurationName;
  }

  get configIsEdit() {
    return !!((this.$route.params.configurationId && this.$route.params.configurationId !== 'new') || this.configurationId);
  }

  set currentConfigurationName(value) {
    PaymentMethodsStore.setCurrentConfigurationName(value);
  }

  get selectedTab() {
    return PaymentMethodsStore.selectedTab;
  }

  get paymentListAir() {
    return PaymentMethodsStore.paymentList
      .map(item => ({
        ...item.paymentMethodConfiguration,
      }));
  }

  get paymentListTrain() {
    return PaymentMethodsTrainStore.paymentList;
  }

  get paymentListCar() {
    return PaymentMethodsCarStore.paymentList;
  }

  get paymentListAccommodation() {
    return PaymentMethodsAccommodationStore.paymentList;
  }

  changeSelectedTab(value) {
    PaymentMethodsStore.setSelectedTab(value);
  }

  backToConfigurationsList() {
    PaymentMethodsStore.setIsFromManage(false);
    PaymentMethodsTrainStore.setIsFromManage(false);
    PaymentMethodsCarStore.setIsFromManage(false);
    PaymentMethodsAccommodationStore.setIsFromManage(false);
    router.push({
      name: 'payment-methods-configurations',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  removeData(config) {
    config.forEach((cfg: any) => {
      delete cfg.error;
      delete cfg.succeeded;
      delete cfg.status;
    });
  }

  confirmAndRedirect() {
    EventBus.$emit('show-toast', {
      type: translate('common.success'),
      title: translate('settings-payment-methods.configuration-saved'),
      message: translate('settings-payment-methods.configuration-saved-payment')
    });

    router.push({
      name: 'payment-methods-configuration',
      params: {
        id: this.$route.params.id,
        configurationId: this.storeConfigurationId
      }
    });
  }

  async saveExistConfigId() {
    let flightConfigs = [];
    let trainConfigs = [];
    let carConfigs = [];
    let accommodationConfigs = [];

    flightConfigs = JSON.parse(JSON.stringify(this.paymentListAir));
    this.removeData(flightConfigs);

    if (this.paymentListTrain && this.paymentListTrain.length) {
      trainConfigs = JSON.parse(JSON.stringify(this.paymentListTrain));
      this.removeData(trainConfigs);
    }

    if (this.paymentListCar && this.paymentListCar.length) {
      carConfigs = JSON.parse(JSON.stringify(this.paymentListCar));
      this.removeData(carConfigs);
    }

    if (this.paymentListAccommodation && this.paymentListAccommodation.length) {
      accommodationConfigs = JSON.parse(JSON.stringify(this.paymentListAccommodation));
      this.removeData(accommodationConfigs);
    }

    let paramsAir = {
      name: this.currentConfigurationName,
      paymentMethodConfigurations: flightConfigs
    };

    let trainMessage = {
      configurationId: this.storeConfigurationId,
      params: {
        name: this.currentConfigurationName,
        paymentMethodConfigurations: trainConfigs
      }
    };

    let carMessage = {
      configurationId: this.storeConfigurationId,
      params: {
        name: this.currentConfigurationName,
        paymentMethodConfigurations: carConfigs
      }
    };

    let accommodationMessage = {
      configurationId: this.storeConfigurationId,
      params: {
        name: this.currentConfigurationName,
        paymentMethodConfigurations: accommodationConfigs
      }
    };

    try {
      await PaymentMethodsStore.updatePaymentMethodsConfiguration({ configurationId: this.storeConfigurationId, params: paramsAir });
      await PaymentMethodsTrainStore.updatePaymentMethodsConfiguration(trainMessage);
      await PaymentMethodsCarStore.updatePaymentMethodsConfiguration(carMessage);
      await PaymentMethodsAccommodationStore.updatePaymentMethodsConfiguration(accommodationMessage);
      
      EventBus.$emit('show-toast', {
        type: translate('common.success'),
        title: translate('settings-payment-methods.configuration-updated'),
        message: translate(
          'settings-payment-methods.configuration-updated-payment'
        )
      });
    } catch (error) {
      PaymentMethodsStore.setShowMessages(true);
      PaymentMethodsStore.setErrMessages(error);
    }
  }

  async saveEmpyConfigId() {
    let flightConfigs = [];
    let trainConfigs = [];
    let carConfigs = [];
    let accommodationConfigs = [];

    if (this.paymentListAir && this.paymentListAir.length) {
      flightConfigs = JSON.parse(JSON.stringify(this.paymentListAir));
      this.removeData(flightConfigs);
    }
    if (this.paymentListCar && this.paymentListCar.length) {
      carConfigs = JSON.parse(JSON.stringify(this.paymentListCar));
      this.removeData(carConfigs);
    }
    if (this.paymentListAccommodation && this.paymentListAccommodation.length) {
      accommodationConfigs = JSON.parse(JSON.stringify(this.paymentListAccommodation));
      this.removeData(accommodationConfigs);
    }

    if (this.paymentListTrain && this.paymentListTrain.length) {
      trainConfigs = JSON.parse(JSON.stringify(this.paymentListTrain));
      this.removeData(trainConfigs);
    }

    let airMessage = {
      rootCompanyId: router.currentRoute.params.id,
      name: this.currentConfigurationName,
      paymentMethodConfigurations: flightConfigs
    };


    try {
      await PaymentMethodsStore.createPaymentMethodsConfiguration(airMessage);
      if (this.storeConfigurationId) {
        PaymentMethodsStore.setCurrentConfigurationName(this.currentConfigurationName);

        let trainMessage = {
          configurationId: this.storeConfigurationId,
          params: {
            name: this.currentConfigurationName,
            paymentMethodConfigurations: trainConfigs
          }
        };

        let carMessage = {
          configurationId: this.storeConfigurationId,
          params: {
            name: this.currentConfigurationName,
            paymentMethodConfigurations: carConfigs
          }
        };

        let accommodationMessage = {
          configurationId: this.storeConfigurationId,
          params: {
            name: this.currentConfigurationName,
            paymentMethodConfigurations: accommodationConfigs
          }
        };

        await PaymentMethodsTrainStore.updatePaymentMethodsConfiguration(trainMessage);
        await PaymentMethodsCarStore.updatePaymentMethodsConfiguration(carMessage);
        await PaymentMethodsAccommodationStore.updatePaymentMethodsConfiguration(accommodationMessage);
        await PaymentMethodsStore.getPaymentMethodsConfiguration(this.storeConfigurationId);
        await PaymentMethodsAccommodationStore.getPaymentMethodsConfiguration(this.storeConfigurationId);

        this.confirmAndRedirect();
      }
    } catch (error) {
      PaymentMethodsStore.setShowMessages(true);
      PaymentMethodsStore.setErrMessages(error);
    }
  }

  async savePaymentMethodsConfiguration() {
    this.$v.currentConfigurationName.$touch();
    if (this.$v.$error) {
      return;
    }
    PaymentMethodsStore.setErrMessages([]);
    PaymentMethodsStore.setPaymentErrMessages([]);
    PaymentMethodsCarStore.clearErrMessages();
    PaymentMethodsTrainStore.clearErrMessages();

    this.errors = [];

    if (!this.storeConfigurationId) {
      this.saveEmpyConfigId();
    } else {
      this.saveExistConfigId();
    }
  }

  async created() {
    this.loadingData = true;
    await PaymentMethodsStore.getEnabledAirSuppliers();
    PaymentMethodsStore.clearErrMessages();
    PaymentMethodsTrainStore.clearErrMessages();
    PaymentMethodsCarStore.clearErrMessages();
    PaymentMethodsAccommodationStore.clearErrMessages();
    if ((this.configurationId && this.configurationId !== 'new') && !this.isFromManage) {
      await Promise.all([
        PaymentMethodsStore.getPaymentMethodsConfiguration(this.configurationId),
        PaymentMethodsTrainStore.getPaymentMethodsConfiguration(this.configurationId),
        PaymentMethodsCarStore.getPaymentMethodsConfiguration(this.configurationId),
        PaymentMethodsAccommodationStore.getPaymentMethodsConfiguration(this.configurationId),
      ]);
      this.loadingData = false;
    }
    this.loadingData = false;
  }
}

