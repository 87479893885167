




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class UiCollapsAbleFilter extends Vue {
  @Prop() label!: string;
  @Prop() timeValue?: string;

  visible = true;

}
