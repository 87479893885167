













































































































































import { Vue, Prop, Component, Emit } from 'vue-property-decorator';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import { sanitizeUrl } from '@braintree/sanitize-url';

import DictionaryStore from '@/store/dictionary.store';
import { userFullName } from '@/core/user-full-name';
import BasketStore from './basket.store';
import { BasketItemIdentifier as Identifiers } from '@/api/trip/basket.model';
import { BasketItemApi } from '@/api/trip/basket-item.api';
import { Permission } from '@/const/permission.enum';
import settings from '@/settings';
import handleErrors from '@/core/errors/handle-errors.service';
import { translate } from '@/i18n';
import { DeepSearch } from '@/services/deep-search.service';

@Component({
  filters: {
    deadlineFilter(date) {
      return moment.parseZone(date).format('YYYY-MM-DD HH:mm');
    }
  }
})
export default class BasketItemIdentifiers extends Vue {
  @Prop({}) item!: any;
  loading: boolean = true;
  identifiers: Identifiers | null = null;
  errors: any[] = [];
  loadingTickets: boolean = false;
  imagesConst = '/assets/img/loader/1.gif';

  fields = {
    departureDate: {
      label: translate('basket.departure-date'),
    },
    segment: {
      label: translate('basket.segment'),
    },
    bookingReference: {
      label: translate('basket.booking-reference'),
    },
    ticketNumber: {
      label: translate('basket.ticket-number'),
    }
  };

  get travellerTickets() {
    return BasketStore.railTickets;
  }

  get referencesSorted() {
    return (this.identifiers && this.identifiers.providerReferences && this.identifiers.providerReferences.length) ?
      _.orderBy(this.identifiers.providerReferences, ['type'], ['asc']) : [];
  }

  get references() {
    return this.referencesSorted.filter(ref => {
      return (this.item.supplier !== 'Sncf' && this.item.supplier !== 'Ouigo') || ref.providerRefName !== 'PNR';
    });
  }

  get hasAnyTickets() {
    return this.identifiers && this.identifiers.travellers ? this.identifiers.travellers.some((e: any) => {
      return e.ticketNumber && e.ticketNumber.length;
    }) : false;
  }

  get hasAnyTicketsToDownload() {
    if ((this.item.supplier === 'Trenitalia' || this.item.supplier === 'Benerail') && this.item.status !== 'Cancelled' && this.item.status !== 'Held') {
      return true;
    }

    if (this.travellerTickets && this.travellerTickets.length === 0) {
      return false;
    }

    return DeepSearch(this.travellerTickets, 'link');
  }

  get airLines() {
    return DictionaryStore.airLines;
  }

  userFullName(user) {
    return userFullName(user);
  }

  remarksName(identifier) {
    if (this.airLines && this.airLines.length) {
      let selected = this.airLines.find(air => air.code === identifier.remarks);
      if (selected) {
        if (identifier.type === 'AirlineReferenceNumber') {
          return selected.businessName;
        } else {
          return selected.code;
        }
      } else {
        return identifier.remarks;
      }
    } else {
      return identifier.remarks;
    }
  }

  getTicketNumber(tickets: any[]) {
    return tickets.map(t => {
      return t;
    }).join(', ');
  }

  @Emit()
  close() {
    return false;
  }

  ticketLink(ticket) {
    if (ticket && ticket.link) {
      return sanitizeUrl(ticket.link);
    }
    return '///';
  }

  ticketTooltipContent(ticket) {
    const infos = ticket.ticketSupportKindEligibilities;
    
    return infos.reduce((prev, cur) => {
      let items = '';
      if (cur.deliveryKindEligibilities && cur.deliveryKindEligibilities.length) {
        items = '<ul>';
        items += cur.deliveryKindEligibilities.reduce((p, c) => {
          return p + '<li>' + c.label + '</li>';
        }, '');
        items += '</ul>';
      }
      return prev + '<div>' + cur.supportKind.label + '</div>' + items;
    }, '');
  }

  downloadTickets() {
    let self = this;
    self.loadingTickets = true;
    
    axios({
      method: 'GET',
      url: settings.apiRailEngine + `/api/orders/${this.item.providerReferenceId}/tickets/download?railSupplier=${this.item.supplier}`,
      responseType: 'blob'
    })
    .then((response) => {
      let filename;
      if (response && response.headers && response.headers['content-disposition']) {
        filename = response.headers['content-disposition'].split('"')[1];
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url as any;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      self.loadingTickets = false;
    })
    .catch(function (error) {
      BasketStore.setErrors(handleErrors(error, true));
    }).finally(function() {
      self.loadingTickets = false;
    });
  }

  async created() {
    try {
      this.errors = [];
      this.loading = true;
      this.identifiers = null;
      BasketStore.setRailTickets(null);
      
      if (this.item.type === 'Rail'  && this.item.providerReferenceId && (this.item.supplier === 'Sncf' || this.item.supplier === 'Ouigo')) {
        await BasketStore.getRailTickets({orderId: this.item.providerReferenceId, railSupplier: this.item.supplier});
      }

      const response = await BasketItemApi.getBasketItemIdentifiers(this.item.id, Permission.ReadTrip);
      if (response && response.data) {
        this.identifiers = response.data;
      }

      this.loading = false;
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.loading = false;
    }
  }
}

