import { getModule, Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { store } from '@/store';
import { router } from '@/router';
import { AirSearchStateParams } from './air/air-search.params';
import { HotelSearchStateParams } from './hotel/hotel-search.params';
import { TrainSearchStateParams } from './train/train-search.params';
import { CarSearchStateParams } from './car/car-search.params';
import { TravellersStateParams } from './travellers.params';
import { OfflineRequestApi } from '@/api/trip/offline-request.api';
import { ProfileApi } from '@/api/profile/profile.api';
import { serviceClassEnum } from '@/api/home/home.model';
import _ from 'lodash';
import AccountStore from '@/store/account.store';
import AirSearchStore from '@/modules/search/air/air-search.store';
import AirCompareLocalStore from '@/modules/search/air/air-compare.local-store';
import { ExpenseApi } from '@/api/expense/expense.api';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import EventBus from '@/services/event-handler';
import { Legs } from '@/api/air-engine/air-search.model';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'search',
})
class SearchStore extends VuexModule {
  airSearchParameters: AirSearchStateParams = new AirSearchStateParams({});
  hotelSearchParameters: HotelSearchStateParams = new HotelSearchStateParams({});
  trainSearchParameters: TrainSearchStateParams = new TrainSearchStateParams({});
  carSearchParameters: CarSearchStateParams = new CarSearchStateParams({});
  travellersSearch: TravellersStateParams = new TravellersStateParams({});
  airSearchCurrentState: any = null;
  isBackFlag: boolean = true;
  shouldHideShowLess: boolean = true;
  serviceNavigating: serviceClassEnum = serviceClassEnum.None;
  service: serviceClassEnum = serviceClassEnum.None;
  basketId: string = '';
  skipTravellers: boolean = false;
  skipTravellersCompany: any | null = null;
  missionId: string = '';
  mission: any | null = null;
  editedTravellersState: TravellersStateParams = new TravellersStateParams({});
  searchedCompanies = [];

  get getAirDefaultState(): AirSearchStateParams {
    return this.airSearchParameters;
  }

  get getHotelDefaultState(): HotelSearchStateParams {
    return this.hotelSearchParameters;
  }

  get getTrainDefaultState(): TrainSearchStateParams {
    return this.trainSearchParameters;
  }

  get getCarDefaultState(): CarSearchStateParams {
    return this.carSearchParameters;
  }

  get getTravellersState() {
    return this.travellersSearch;
  }

  get editedTravellers() {
    return this.editedTravellersState;
  }

  get companies() {
    return this.searchedCompanies;
  }

  @Mutation
  setStateMainTraveller({ traveller, list }) {
    if (this.skipTravellers) {
      return;
    }

    const newMain = list.find(item => item.id === traveller.id);

    if (!newMain) {
      return;
    }

    const oldMain = list.find(item => !!item.isMainTraveller);

    if (oldMain && oldMain.id === newMain.id) {
      return;
    }
    if (oldMain) {
      oldMain.isMainTraveller = false;
    }
    newMain.isMainTraveller = true;
  }

  @Mutation
  selectNavigated(name: serviceClassEnum) {
    this.serviceNavigating = name;
  }

  @Mutation
  selectBasket(id) {
    this.basketId = id;
  }

  @Mutation
  setMissionId(id: string) {
    this.missionId = id;
  }

  @Mutation
  setMission(data) {
    this.mission = data;
  }

  @Mutation
  select(name: serviceClassEnum) {
    this.service = name;
  }

  @Mutation
  updateAirCurrentState(state) {
    this.airSearchCurrentState = state;
  }

  @Mutation
  public updateAirDefaultState(payload) {
    this.airSearchParameters = {
      ...payload,
      legs: payload.legs.map(leg => {
        return new Legs(leg);
      }),
    };
  }

  @Mutation
  public updateHotelDefaultState(payload) {
    this.hotelSearchParameters = payload;
  }

  @Mutation
  public updateTrainDefaultState(payload) {
    this.trainSearchParameters = payload;
  }

  @Mutation
  public updateCarDefaultState(payload) {
    this.carSearchParameters = payload;
  }

  @Mutation
  public updateSearchModeStatus(payload) {
    this.airSearchParameters.searchMode = payload;
  }

  @Mutation
  public updateTravellersDefaultState(payload) {
    this.travellersSearch = payload;
  }

  @Mutation
  public updateTravellersBirthDates(payload) {
    const travellers = this.travellersSearch.travellers;

    payload.forEach(item => {
      const traveller = travellers.find(t => t.id === item.id);
      if (!traveller) {
        return;
      }
      traveller.dateOfBirth = item.dateOfBirth;
    });
  }

  @Mutation
  public updateEditedTravellers(payload) {
    this.editedTravellersState = payload;
  }

  @Mutation
  public updateBackFlag(isBackFlag: boolean) {
    this.isBackFlag = isBackFlag;
  }

  @Mutation
  public updateSkipTravellers(skipTravellers: boolean) {
    this.skipTravellers = skipTravellers;
  }

  @Mutation
  public setSkipTravellersCompany(company) {
    this.skipTravellersCompany = company;
  }

  @Mutation
  public updateHideShowLess(payload: boolean) {
    this.shouldHideShowLess = payload;
  }

  @Mutation
  updateAirSearchLegs(legs) {
    if (!this.airSearchParameters) {
      return;
    }
    this.airSearchParameters.legs = legs;
  }



  @Action
  init() {
    this.resetService();
  }

  @Action
  setMainTraveller(traveller) {
    this.setStateMainTraveller({
      traveller,
      list: this.travellersSearch.travellers,
    });
    this.setStateMainTraveller({
      traveller,
      list: this.editedTravellersState.travellers,
    });
  }

  @Action
  resetService() {
    if (AccountStore.HasPermission('SearchOffersAir')) {
      this.select(serviceClassEnum.Flight);
      this.selectNavigated(serviceClassEnum.Flight);
    } else if (AccountStore.HasPermission('SearchOffersRail')) {
      this.select(serviceClassEnum.Train);
      this.selectNavigated(serviceClassEnum.Train);
    } else if (AccountStore.HasPermission('SearchOffersAccomodation')) {
      this.select(serviceClassEnum.Hotel);
      this.selectNavigated(serviceClassEnum.Hotel);
    } else if (AccountStore.HasPermission('SearchOffersCar')) {
      this.select(serviceClassEnum.Car);
      this.selectNavigated(serviceClassEnum.Car);
    } else {
      this.select(serviceClassEnum.None);
      this.selectNavigated(serviceClassEnum.None);
    }
  }

  @Mutation
  initSearchParams() {
    this.airSearchParameters = new AirSearchStateParams({});
    this.hotelSearchParameters = new HotelSearchStateParams({});
    this.trainSearchParameters = new TrainSearchStateParams({});
    this.carSearchParameters = new CarSearchStateParams({});
    this.travellersSearch = new TravellersStateParams({});
    this.airSearchCurrentState = null;
  }

  @Action
  resetSearchState() {
    if (this.isBackFlag) {
      this.updateAirDefaultState(new AirSearchStateParams({}));
      this.updateHotelDefaultState(new HotelSearchStateParams({}));
      this.updateTrainDefaultState(new TrainSearchStateParams({}));
      this.updateCarDefaultState(new CarSearchStateParams({}));
      this.resetService();
    }
  }

  @Action
  async resetTravellerSearchState() {
    if (this.isBackFlag) {
      let travellers: any[] = [];
      this.updateTravellersDefaultState(new TravellersStateParams({}));
      if (router.currentRoute.query.offlineRequestId) {
        const requestResult = await OfflineRequestApi.get(router.currentRoute.query.offlineRequestId as string);
        const requestorResult = await ProfileApi.getById(requestResult.data.requestorId);
        travellers = [requestorResult.data];
      }
      this.updateTravellersDefaultState(new TravellersStateParams({
        travellers,
      }));
    }
  }

  @Action 
  async getMissionDetails() {
    if (this.missionId) {
      const response = await ExpenseApi.getMissionItem(this.missionId);
      this.setMission(response.data);
    }
  }

  @Mutation
  public setCompanies(value) {
    this.searchedCompanies = value;
  }

  @Action
  public async loadCompanies(phrase: string) {
    if (phrase && phrase.length > 1) {      
      let response = await ProfileCompanyApi.autocompleteCompaniesIncludingProfileAgency(phrase);

      if (response && response.data) {
        this.setCompanies(response.data);
      }
    } else {
      this.setCompanies([]);
    }
  }

  @Action
  resetTemplateOffers() {
    AirSearchStore.setTemplateOffers([]);
    localStorage.removeItem('Air_compare_offer');
    AirCompareLocalStore.resetLocalStore();
    EventBus.$emit('template-offer', true);
  }
}

export default getModule(SearchStore);
