import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { ConfigurationRow } from '@/api/profile/configurations.model';
import { ApprovalWorkflowApi } from '@/api/approval-workflow/approval-workflow.api';
import {
  ApprovalWorkflowConfiguration,
  ApprovalWorkflowConfigurationLevel,
} from '@/api/approval-workflow/approval-workflow.model';
import { router } from '@/router';
import $handleErrors from '@/core/errors/handle-errors.service';
import EventBus from '@/services/event-handler';
import { translate } from '@/i18n';

@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'approvalWorkflow'
})
class ApprovalWorkflowStore extends VuexModule {
  loading: boolean = false;
  loaded: boolean = false;
  currentConfiguration!: ConfigurationRow;
  approvalWorkflow: ApprovalWorkflowConfiguration = new ApprovalWorkflowConfiguration();
  isCopy: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get canShowCustomError() {
    return this.customError;
  }

  get approvalWorkflowConfiguration() {
    return this.approvalWorkflow;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  @Mutation
  setApprovalWorkflowConfiguration(payload) {
    this.approvalWorkflow = {
      ...payload,
      configurationLevels: payload.configurationLevels.length ? payload.configurationLevels : [
        new ApprovalWorkflowConfigurationLevel(),
      ]
    };
  }

  @Mutation
  setName(value) {
    this.approvalWorkflow.name = value;
  }

  @Mutation
  setShowError(value) {
    this.showError =  value;
  }

  @Mutation
  setErrMessages(error) {
    this.errMessages = error;
  }

  @Mutation
  setCopy(value) {
    this.isCopy = value;
  }

  @Mutation
  clearData() {
    this.approvalWorkflow = new ApprovalWorkflowConfiguration();
  }

  @Mutation
  setConfigurationId(value) {
    router.push({
      name: 'configuration',
      params: {
        configurationId: value
      }
    });
  }

  @Mutation
  setLoading(payload) {
    this.loading = payload;
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async getApprovalWorkflowConfiguration(currentSelectedId) {
    this.setLoading(true);
    this.setShowError(false);
    this.setCustomError(false);
    if (currentSelectedId) {
      try {
        const result = await ApprovalWorkflowApi.getApprovalWorkflowConfiguration(currentSelectedId);
        if (result && result.data) {
          result.data.configurationLevels
            .sort((a, b) => a.approvalLevel - b.approvalLevel);
          this.setApprovalWorkflowConfiguration(result.data);
          if (this.isCopy) {
            this.setName(null);
          }
          this.setLoading(false);
        }
      } catch (error) {
        if (error && error.response && error.response.status === 404) {
          this.setCustomError(true);
        } else {
          this.setErrMessages($handleErrors(error, true));
          this.setShowError(true);
        }
      } finally {
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }

  @Action
  async addApprovalWorkflowConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    try {
      const result = await ApprovalWorkflowApi.addApprovalWorkflowConfiguration(data.params);
      if (result && result.data) {
        this.setLoading(false);
        this.setConfigurationId(result.data.id);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-approval.config-saved')
        });

        this.getApprovalWorkflowConfiguration(result.data.id);
      }
    } catch (error) {
      this.setErrMessages($handleErrors(error, true));
      this.setShowError(true);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async updateApprovalWorkflowConfiguration(data) {
    this.setLoading(true);
    this.setShowError(false);
    if (data.id) {
      try {
        await ApprovalWorkflowApi.updateApprovalWorkflowConfiguration(data.id, {...data.params});
        this.setLoading(false);

        EventBus.$emit('show-toast', {
          type: translate('common.success'),
          title: translate('common.saved'),
          message: translate('settings-approval.config-saved')
        });

      } catch (error) {
        this.setErrMessages($handleErrors(error, true));
        this.setShowError(true);
      } finally {
        this.setLoading(false);
      }
    }
    else {
      this.setLoading(false);
    }
  }
}

export default getModule(ApprovalWorkflowStore);
