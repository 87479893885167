





































































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, requiredIf, numeric } from 'vuelidate/lib/validators';
import moment from 'moment';
import { translate } from '@/i18n';
import { LoyaltiesApi } from '@/api/profile/loyalties.api';
import { IRestrictChoice, ILoyaltyCard, LoyaltyForm, LoyaltyType } from '@/api/profile/loyalties.model';
import { ValidRoutes, LoyaltyTypes } from '@/const/loyalty-const';
import AccountStore from '@/store/account.store';
import { Debounce } from '@/core/decorators/debounce.decorator';
import UiAutocomplete from '@/controls/UiAutocomplete.vue';

@Component
export default class LoyaltyAirForm extends Vue {
  @Prop() value!: LoyaltyForm;
  @Prop() serviceType!: string;
  @Prop() card!: any;
  @Prop() dirty!: boolean;
  @Prop({ default: () => ([]) }) restrictChoices!: IRestrictChoice[];

  LoyaltyTypes = LoyaltyTypes;
  ValidRoutes = ValidRoutes;
  programTypeLoading: boolean = false;
  programTypeList: ILoyaltyCard[] = [];
  serverErrors: any[] = [];
  loyaltyType: string | null = null;
  selectedProgramType: ILoyaltyCard | null = null;
  $v;

  @Validation()
  validationObject() {
    return {
      loyaltyType: { required },
      value: {
        loyaltyId: { required },
        cardNumber: {
          required: requiredIf(() => this.programType && this.programType.isNumberRequired),
          maxLength: maxLength(50),
          numeric: this.programType && this.programType.cardNumberWithOnlyDigits ? numeric : {},
        },
        issueDate: {
          required: requiredIf(() => this.programType && this.programType.isIssueDateRequired),
        },
        expireDate: {
          required: requiredIf(() => this.programType && this.programType.isExpirationRequired),
        },
        validRoutes: {
          type: {
            required: requiredIf(() => this.programType && this.programType.isRoutingRequired),
          }
        },
      },
    };
  }

  get programType() {
    return this.selectedProgramType;
  }
  set programType(value) {
    this.selectedProgramType = value;
    this.$emit('input', { loyaltyId: value && value.id });
    this.autocompleteBlur();
  }

  get issueDate() {
    if (this.value.issueDate) {
      const momentDate = moment(this.value.issueDate, 'YYYY-MM-DD');
      return new Date(momentDate.toISOString());
    }
    return null;
  }
  set issueDate(value) {
    this.$emit('input', {
      ...this.value,
      issueDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }
  get validFromDate() {
    if (this.value.validFromDate) {
      const momentDate = moment(this.value.validFromDate, 'YYYY-MM-DD');
      return new Date(momentDate.toISOString());
    }
    return null;
  }
  set validFromDate(value) {
    this.$emit('input', {
      ...this.value,
      validFromDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }
  get expireDate() {
    if (this.value.expireDate) {
      const momentDate = moment(this.value.expireDate, 'YYYY-MM-DD');
      return new Date(momentDate.toISOString());
    }
    return null;
  }
  set expireDate(value) {
    this.$emit('input', {
      ...this.value,
      expireDate: value ? moment(value).format('YYYY-MM-DD') : null,
    });
  }

  get tomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  }

  get expireDateMinDate() {
    if (this.validFromDate) {
      const date = new Date(this.validFromDate);
      date.setDate(date.getDate() + 1);
      date.setHours(0, 0, 0, 0);
      return date;
    }
    return this.tomorrow;
  }

  get validFromDateMaxDate() {
    if (this.expireDate) {
      const date = new Date(this.expireDate);
      date.setDate(date.getDate() - 1);
      date.setHours(0, 0, 0, 0);
      return date;
    }
    return new Date();
  }

  get issueDateMaxDate() {
    return this.validFromDate || new Date();
  }

  get isLoyalty() {
    return this.loyaltyType === LoyaltyType.Loyalty;
  }

  get hasRestrictedLoyaltyType() {
    return !!this.restrictChoices.find(e => e.key === 'loyaltyType');
  }

  get hasRestrictedProgramType() {
    return !!this.restrictChoices.find(e => e.key === 'programType');
  }

  @Debounce({
    delay: 50,
  })
  autocompleteBlur() {
    const programTypeRef = this.$refs.programTypeRef as UiAutocomplete;
    const input = document.getElementById(programTypeRef.currentId);
    if (input) {
      input.blur();
    }
  }

  loyaltyTypeSelect() {
    this.selectedProgramType = null;
    this.$emit('input', new LoyaltyForm());
  }

  getCardNumberLabel(label) {
    if (label) {
      switch (AccountStore.currentLanguage) {
        case 'en':
          return `"${label}" ${translate('profile-loyalty.card-number-lowercase')}`;
        case 'fr':
          return `${translate('profile-loyalty.card-number')} "${label}"`;
        case 'it':
          return `${translate('profile-loyalty.card-number')} "${label}"`;
        default:
          return '';
      }
    }
  }

  updateProgramList() {
    this.getLoyaltiesByService('');
  }

  async onReset() {
    await this.getLoyaltiesByService('');
  }

  async getLoyaltiesByService(phrase: string) {
    try {
      let loyaltiesResult = await LoyaltiesApi.getLoyaltiesByService(
        this.serviceType,
        this.loyaltyType,
        phrase,
      );

      if (loyaltiesResult && loyaltiesResult.data) {
        this.programTypeList = loyaltiesResult.data;
      }
    } catch (error) {
      this.serverErrors = this.$handleErrors(error, true);
    } finally {
      this.programTypeLoading = false;
    }
  }


  @Debounce({
    delay: 200,
    flag: 'programTypeLoading',
  })
  async loadProgramType(phrase: string) {
    if (!this.loyaltyType || !this.serviceType || phrase.length < 1) {
      this.programTypeLoading = false;
      return;
    }
    await this.getLoyaltiesByService(phrase);
  }

  isValid() {
    this.$v.$touch();
    return !this.$v.$error;
  }

  async created() {
    if (this.restrictChoices && this.restrictChoices.length > 0) {
      const loyaltyType = this.restrictChoices.find(e => e.key === 'loyaltyType');
      this.loyaltyType = (loyaltyType && loyaltyType.value) || null;
      const programType = this.restrictChoices.find(e => e.key === 'programType');
      if (programType) {
        await this.getLoyaltiesByService(programType.value);
        const selectedProgramType = this.programTypeList.find((item) => {
          return item.programName === programType.value;
        });
        this.selectedProgramType = selectedProgramType || null;
        this.$emit('input', { loyaltyId: selectedProgramType && selectedProgramType.id });
      }
    }
    if (this.card) {
      this.selectedProgramType = this.card.loyalty;
      this.programTypeList = [this.card.loyalty];
      this.loyaltyType = this.card.loyalty.loyaltyType;
    }
    if (this.dirty) {
      this.$v.$touch();
    }
  }
}
