















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, maxLength, email } from 'vuelidate/lib/validators';

import EventHandler from '@/services/event-handler';
import { translate } from '@/i18n';
import AccountStore from '@/store/account.store';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import SettingsStore from '../settings.store';
import ProvidersNotificationsStore from './providers-notifications.store';

@Component({})
export default class ProvidersNotificationsConfiguration extends Vue {
  $v;
  configuration: any = null;
  languageOptions: LanguagesModel[] = [];
  languageErrors: any[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  
  get loading() {
    return ProvidersNotificationsStore.loading;
  }
  
  get saving() {
    return ProvidersNotificationsStore.saving;
  }

  get errors() {
    return ProvidersNotificationsStore.errors;
  }

  get configurationId() {
    return this.$router.currentRoute.params.configurationId || '';
  }

  get configIsEdit() {
    return !!((this.$route.params.configurationId && this.$route.params.configurationId !== 'new') || this.configurationId);
  }

  get currentConfigurationName() {
    return ProvidersNotificationsStore.currentConfigurationName;
  }

  set currentConfigurationName(value) {
    ProvidersNotificationsStore.setCurrentConfigurationName(value);
  }

  get language() {
    return this.languageOptions.find(item => item.code === this.configuration.language);
  }

  set language(value) {
    if (!value || !value.code) {
      this.configuration.language = this.languageOptions[0].code;
      return;
    }
    this.configuration.language = value.code;
  }

  get currentCompany() {
    return SettingsStore.currentCompany;
  }

  get addDisabled() {
    const list = this.configuration.emailAddresses;
    return !list[list.length - 1].length;
  }

  get notUniqueIndices() {
    return this.configuration.emailAddresses
      .map((value, index) => {
        return {
          value,
          index,
        };
      })
      .filter(item => {
        const sameElements = this.configuration.emailAddresses.filter(value => value === item.value);

        return sameElements.length > 1;
      })
      .map(item => item.index);
  }

  get canShowCustomError() {
    return ProvidersNotificationsStore.canShowCustomError;
  }

  @Validation()
  validationObject() {
    return {
      currentConfigurationName: {
        required,
        maxLength: maxLength(128)
      },
      configuration: {
        emailAddresses: {
          $each: {
            required,
            email,
            maxLength: maxLength(320),
          },
        },
      },
    };
  }

  showSaveSuccessMessage() {
    const obj = {
      type: translate('settings-providers-notifications.success'),
      title: translate('common.saved'),
      message: translate('settings-providers-notifications.configuration-saved')
    };
    EventHandler.$emit('show-toast', obj);
  }

  backToConfigurationsList() {
    this.$router.push({
      name: 'providers-notifications',
      params: {
        id: this.$router.currentRoute.params.id
      }
    });
  }

  async saveConfiguration() {
    this.$v.currentConfigurationName.$touch();
    this.$v.configuration.$touch();
    if (this.$v.$error || this.notUniqueIndices.length) {
      return;
    }

    const message = {
      name: this.currentConfigurationName,
      isEmailSendingEnabled: this.configuration.isEmailSendingEnabled,
      emailAddresses: this.configuration.emailAddresses,
      language: this.configuration.language,
    };

    ProvidersNotificationsStore.setErrors([]);

    if (this.configIsEdit) {
      await ProvidersNotificationsStore.updateConfiguration({
        id: this.configurationId,
        message,
      });
    } else {
      await ProvidersNotificationsStore.createConfiguration({
        ...message,
        rootCompanyId: this.currentCompany!.rootCompanyId,
      });
    }

    if (!this.errors.length) {
      this.showSaveSuccessMessage();

      if (!this.configIsEdit) {
        this.backToConfigurationsList();
      }
    }
  }

  addEmail() {
    if (this.addDisabled) {
      return;
    }
    this.configuration.emailAddresses.push('');
  }

  removeEmail(index) {
    this.configuration.emailAddresses.splice(index, 1);
  }

  async loadData() {
    ProvidersNotificationsStore.setConfigurationData(null);
    await ProvidersNotificationsStore.loadConfiguration(this.configurationId);

    const config = ProvidersNotificationsStore.configurationData;
    if (!ProvidersNotificationsStore.errors.length && config) {
      this.configuration = {
        isEmailSendingEnabled: config.isEmailSendingEnabled,
        emailAddresses: config.emailAddresses,
        language: config.language,
      };
    }
  }

  async loadLanguages() {
    try {
      const result = await LanguagesApi.getLanguages();

      let options: LanguagesModel[] = [];
      options = result.data;
      this.languageOptions = options;
    } catch (error) {
      this.languageErrors = this.$handleErrors(error, true);
    }
  }

  @Watch('configurationId', { immediate: true })
  onLoad(value) {
    ProvidersNotificationsStore.setErrors([]);
    this.configuration = {
      isEmailSendingEnabled: true,
      language: AccountStore.current!.profile.displayLanguage,
      emailAddresses: [
        '',
      ],
    };
    if ('' !== value) {
      this.loadData();
    }
  }

  created() {
    this.loadLanguages();
  }
}

