
















































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import CarFareConditions from './CarFareConditions.vue';
import { translate } from '@/i18n';
import settings from '@/settings';
import { CarType } from '@/api/car-engine/car-search.model';
import { CarCategory } from '@/const/car-search.const';
import formatPrice from '@/filters/format-price.filter';
import CarSearchStore from './car-search.store';
import BasketStore from '@/modules/basket/basket.store';
import AccountStore from '@/store/account.store';
import _ from 'lodash';
import { SupplierName } from '@/services/supplier-name.service';
import { VclCode } from 'vue-content-loading';
import { AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';

const amenitiesMap = {
  TRANSMISSION: 'settings',
  MAX_PASSENGERS: 'people',
  MAX_BAGGAGE: 'work',
  MAX_SMALL_BAGGAGE: 'style',
  MILEAGE: 'done'
};

@Component({
  components: {
    CarFareConditions,
    VclCode,
  },
  filters: {
    dayFrom(date) {
      return moment(date).format('dddd, MMMM D');
    },
    dayToShort(date) {
      return moment(date).format('D');
    },
    dayToLong(date) {
      return moment(date).format('MMMM D');
    },
  },
})
export default class CarResultsRow extends Vue {
  @Prop({}) offer!: any;
  @Prop({}) item!: any;
  @Prop({}) isInBasket!: boolean;
  @Prop({ default: '' }) basketStatus!: string;
  @Prop({ default: false }) isInWizard!: boolean;
  @Prop({ default: false }) loading!: boolean;
  @Prop({ default: '' }) basketProviderStatus!: string;
  @Prop({ default: 0 }) priceChange!: number;

  showFullConditionsPopup: boolean = false;
  smallBagIcon: string = '/assets/img/small_bag-icon.png';
  agencyMarkupValue: number = this.isInBasket && this.offer ? this.offer.agencyMarkup.amount : 0;
  height: any | null = null;
  
  get styles() {
    if (this.height) {
      return {
        height: this.height + 'px',
      };
    }
    return {};
  }

  get pricePlusMarkup() {
    return this.isInBasket && this.offer ? this.offer.totalPrice.amount + this.agencyMarkupValue : 0;
  }

  get isDisabled() {
    return CarSearchStore.isSelectedOffer;
  }

  get isThisOfferLoading() {
    return CarSearchStore.isSelectedOffer && CarSearchStore.selectedOfferId === this.offer.offerId;
  }

  get priceWithMarkup() {
    return {
      amount: this.offer.totalPrice.amount + this.offer.agencyMarkup.amount + this.priceChange,
      currency: this.offer.totalPrice.currency
    };
  }

  get carCategoryType()  {
    if (this.offer.vehicle.name) {
      return this.offer.vehicle.name;
    }
    
    const category = CarCategory.find(e => {
      return e.code === this.offer.vehicle.acrissCode.charAt(0);
    });
    if (category) {
      return `${translate(category.label)} | ${CarType[this.offer.vehicle.acrissCode.charAt(1)]}`;
    } else {
      return `${CarType[this.offer.vehicle.acrissCode.charAt(1)]}`;
    }
  }

  get vehicleApproxPrice() {
    if (this.offer.rate.priceDetails) {
      return this.offer.rate.priceDetails.find(e => {
        return e.chargeType === 'ApproximateTotalPrice';
      });
    } else return;
  }

  get fullAddressString() {
    if (
      this.offer.pickupLocation.fullAddress.addressLine1 &&
      this.offer.pickupLocation.fullAddress.addressLine2
    ) {
      return `${this.offer.pickupLocation.fullAddress.addressLine1} ${this.offer.pickupLocation.fullAddress.addressLine2}`;
    } else if (
      this.offer.pickupLocation.fullAddress.addressLine1 &&
      !this.offer.pickupLocation.fullAddress.addressLine2
    ) {
      return this.offer.pickupLocation.fullAddress.addressLine1;
    } else {
      return '';
    } 
  }

  get startDate() {
    if (this.offer) {
      return this.offer.pickupDateTime;
    }
  }

  get endDate() {
    if (this.offer) {
      return this.offer.returnDateTime;
    }
  }

  get sameMonth() {
    if (this.offer) {
      return moment(this.offer.pickupDateTime).isSame(this.offer.returnDateTime, 'month');
    }
    return false;
  }

  get carLocation() {
    if (this.offer && this.offer.pickupLocation) {
      return _.startCase(this.offer.pickupLocation.fullAddress.cityName.toLowerCase());
    }
  }

  get canShowExternalAgencyMarkup() {
    return 'true' === settings.enableExternalAgencyMarkup;
  }

  get canAddOfferToTrip() {
    return AccountStore.HasPermission('AddOfferToTrip');
  }

  get flagClasses() {
    return {
      'car-results-row__public-fare': 'Public' === this.offer.fareType,
      'car-results-row__nego-fare': 'Nego' === this.offer.fareType,
    };
  }

  get classesTooltip() {
    switch (this.offer.fareType) {
      case 'Public':
        return translate('search-air.public-fare');
      case 'Nego':
        return translate('search-air.nego-fare');
      default:
        return translate('search-air.public-fare');
    }
  }

  get currentDateFormat() {
    return AccountStore.current!.profile.shortDateFormat || AvailableDateFormats.AvailableDateFormat1;
  }

  get currentTimeFormat() {
    return AccountStore.current!.profile.timeFormat || AvailableTimeFormats.AvailableTimeFormat1;
  }

  get currentDateTimeFormat() {
    return `${this.currentDateFormat} ${this.currentTimeFormat}`;
  }

  get refundableUntil() {
    if (!this.offer || !this.offer.rate.cancellationRefund.length || !this.offer.rate.cancellationRefund[0].refundableUntil) {
      return '';
    }
    return moment.parseZone(this.offer.rate.cancellationRefund[0].refundableUntil).format(this.currentDateTimeFormat);
  }

  getSupplierName(name) {
    return SupplierName(name);
  }

  amenityCode(index) {
    return Object.keys(amenitiesMap)[index];
  }

  amenityIcon(code) {
    return amenitiesMap[code];
  }

  vehicleMileageAllowance() {
    if (!this.vehicleApproxPrice) {
      return false;
    } else {
      if (this.vehicleApproxPrice.mileageAllowance) {
        const unit = this.vehicleApproxPrice.mileageAllowance.unit === 'Kilometers' ? 'KM' : this.vehicleApproxPrice.mileageAllowance.unit;
        return `${this.vehicleApproxPrice.mileageAllowance.amount} ${unit}`;
      } else {
        return false;
      }
    }
  }

  showFullConditions() {
    this.showFullConditionsPopup = true;
  }

  selectOffer(offer) {
    CarSearchStore.selectOffer({ offer });
  }

  tooltip(offer) {
    if (this.basketProviderStatus === 'Cancelled') return '';

    return `<span>${translate('basket.incl')} ${offer.totalPrice.currency.symbol || offer.totalPrice.currency.code} ${formatPrice(offer.totalPrice.amount)} ${translate('search-car.provider-price')}</span>`;
  }

  @Watch('loading')
  onLoading(value) {
    if (!value) {

      if (!this.height) {
        this.$nextTick(() => {
          const rect = (this.$refs.mainCard as HTMLElement).getBoundingClientRect();
          this.height = rect.height;
        });
      }
    }
  }
}
