


































































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { router } from '@/router';
import EventBus from '@/services/event-handler';
import {
  hasSomeParentTheClass,
  getParentByClass,
} from '@/modules/layout/scroll-manager';
import {
  CarExtra
} from '@/api/dictionary/dictionary.model';

@Component({})
export default class UiExtrasSelect extends Vue {
  @Prop({}) value!: Array<any>;
  @Prop({}) options!: Array<CarExtra>;
  @Prop({}) trackBy!: string;

  showMenu: boolean = false;
  showFull: boolean = false;
  isInPopup: boolean = false;
  popupContainer: HTMLElement | null = null;
  isActive: boolean = false;
  blurTimeout: number = -1;
  touchedIndex: number = -1;
  touchedMoreIndex: number = -1;
  

  get priorOptions() {
    if (this.options) {
      return this.options.filter(opt => {
        if (opt.priority === 1) {
          return opt;
        }
      }).map(opt => {
        return {
          ...opt,
          selected: false,
        };
      });
    } else {
      return [];
    }
  }

  get laterOptions() {
    if (this.options && this.showFull) {
      return this.options.filter(opt => {
        if (opt.priority === 2) {
          return opt;
        }
      }).map(opt => {
        return {
          ...opt,
          selected: false,
        };
      });
    } else {
      return [];
    }
  }

  get allOptions() {
    return [...this.priorOptions, ...this.laterOptions];
  }

  get hasMobileSubMenu() {
    if (
      router.currentRoute.matched[0].meta &&
      router.currentRoute.matched[0].meta.hasMobileSubMenu
    ) {
      return true;
    }
    return false;
  }

  get mobileClasses() {
    return {
      'ui-autocomplete__mobile--has-submenu': this.hasMobileSubMenu && !this.isInPopup,
      'ui-autocomplete__mobile--active': this.isActive,
    };
  }

  @Emit('extrasUpdate')
  extrasUpdate(value) {
    return value;
  }

  @Watch('value', { immediate: true })
  onValueChange(value) {
    value.forEach(val => {
      let found = this.allOptions.find(opt => {
        return val === opt.code;
      });
      if (found) {
        found.selected = true;
      }
    });
  }

  deselectAll($event) {
    this.allOptions.forEach(opt => {
      opt.selected = false;
    });
    this.extrasSelected();
  }

  extrasSelected() {
    let extrasMerged = [
      ...this.priorOptions.filter(opt => {
        return opt.selected;
      }),
      ...this.laterOptions.filter(opt => {
        return opt.selected;
      })
    ].map(extra => {
      return extra.code;
    });
    this.extrasUpdate(extrasMerged);
  }

  onClick(e: Event) {
    if (window.innerWidth >= 800) {
      return;
    }
    const target = e.target as HTMLElement;
    if ((target.className.includes('prior-wrapper') || target.className.includes('ui-select-mobile'))) {
      this.showMenu = true;
      return;
    }
    this.showMenu = !this.showMenu;
  }

  onMouseOver(e) {
    if (window.innerWidth < 800) {
      return;
    }
    this.showMenu = true;
  }

  onMouseLeave(e) {
    if (!e.relatedTarget.className.includes('tooltip')) {
      this.showFull = false;
      this.showMenu = false;
    }
  }

  mobileFocus() {
    clearTimeout(this.blurTimeout);
    this.isActive = true;
    if (this.isInPopup && window.innerWidth < 800) {
      EventBus.$emit('freeze-popup', this.popupContainer);
    }
  }

  mobileClick($event) {
    const el = $event.target;
    const isUiAutocomplete = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-field');
    const isUiAutocompleteToggle = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-toggle');
    const isUiAutocompleteOption = hasSomeParentTheClass(el, 'option-item');
    const isUiAutocompleteOptions = hasSomeParentTheClass(el, 'ui-autocomplete__mobile-options');
    
    if (this.isActive && isUiAutocompleteOption) {
      const parent = getParentByClass($event.target, 'option-item');
      const index = parent.attributes.data.nodeValue;
    } else if (
      this.isActive && ((isUiAutocomplete && isUiAutocompleteToggle) ||
      (!isUiAutocompleteOption && isUiAutocompleteOptions))
    ) {
      return this.$refs.extrasMobileInput && 
        (this.$refs.extrasMobileInput as HTMLInputElement).blur();
    }

    this.$nextTick(() => {
      return this.$refs.extrasMobileInput && 
        (this.$refs.extrasMobileInput as HTMLInputElement).focus();
    });
  }

  confirm() {
    clearTimeout(this.blurTimeout);
    this.blurTimeout = setTimeout(() => {
      this.isActive = false;
      if (this.isInPopup && window.innerWidth < 800) {
        EventBus.$emit('unfreeze-popup', this.popupContainer);
      }
    }, 100);
  }

  onTouchStart(index) {
    this.touchedIndex = index;
  }

  onTouchEnd(index) {
    if (index === this.touchedIndex) {
      this.touchedIndex = -1;
    }
  }

  onTouchMoreStart(index) {
    this.touchedMoreIndex = index;
  }

  onTouchMoreEnd(index) {
    if (index === this.touchedMoreIndex) {
      this.touchedMoreIndex = -1;
    }
  }

  classesForIndex(index) {
    return {
      'option-highlighted': this.touchedIndex === index,
    };
  }

  classesForMoreIndex(index) {
    return {
      'option-highlighted': this.touchedMoreIndex === index,
    };
  }
}
