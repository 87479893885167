import {
  getModule,
  Module,
  VuexModule,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { store } from '@/store';
import { NegoFareConfigurationModel, CorporateCodesModel } from '@/api/air-engine/nego-fares-configuration.model';
import { NegoFareConfiguration } from '@/api/nego-fares/nego-fares.model';
import { NegoFaresApi } from '@/api/nego-fares/nego-fares.api';
import $handleErrors from '@/core/errors/handle-errors.service';
@Module({
  dynamic: true,
  namespaced: true,
  store: store,
  name: 'negoFares'
})
class NegoFaresStore extends VuexModule {
  loading: boolean = false;
  currentConfiguration: NegoFareConfigurationModel = new NegoFareConfigurationModel();
  configuration: NegoFareConfiguration | null = new NegoFareConfiguration();
  configurationName: string = '';
  configurationId: string = '';
  selectedTab: string = 'Flight';
  corporateCodes: CorporateCodesModel[] = [];
  selectedCode: CorporateCodesModel | null = null;
  isCopy: boolean = false;
  isFromManage: boolean = false;
  errMessages: string[] = [];
  showError: boolean = false;
  customError: boolean = false;

  get CurrentConfiguration() {
    return this.currentConfiguration;
  }

  get getErrMessages() {
    return this.errMessages;
  }

  get canShowError() {
    return this.showError;
  }

  get CorporateCodes() {
    return this.corporateCodes;
  }

  get Loading() {
    return this.loading;
  }

  get canShowCustomError() {
    return this.customError;
  }

  @Mutation
  setLoading(value) {
    this.loading = value;
  }

  @Mutation
  setConfiguration(value) {
    this.configuration = new NegoFareConfiguration(value);
  }

  @Mutation
  setCurrentConfiguration(value) {
    this.currentConfiguration = new NegoFareConfigurationModel(value);
  }

  @Mutation
  setCurrentConfigurationName(value) {
    this.configurationName = value;
  }

  @Mutation
  setSelectedTab(value) {
    this.selectedTab = value;
  }

  @Mutation
  setIsFromManage(value) {
    this.isFromManage = value;
  }

  @Mutation
  setConfigurationId(value) {
    this.configurationId = value;
  }

  @Mutation
  setCorporateCodes(value) {
    this.corporateCodes = value;
  }

  @Mutation
  selectCorporateCode(value?) {
    this.selectedCode = value ? new CorporateCodesModel(value) : null;
  }

  @Mutation
  setErrMessages(value) {
    this.errMessages = $handleErrors(value, true); 
  }

  @Mutation
  clearErrMessages() {
    this.errMessages = [];
  }

  @Mutation
  setCustomError(value) {
    this.customError = value;
  }

  @Action
  async createNegoFareConfiguration(request) {
    try {
      this.setLoading(true);
      this.clearErrMessages();
      const response = await NegoFaresApi.createNegoFareConfiguration(request);
      if (response && response.data) {
        this.setConfigurationId(response.data.configurationId);
        let errorElements = response.data.result.filter(element => {
          return element.error !== null;
        });
        if (errorElements && errorElements.length) {
          this.setErrMessages([...errorElements.map(elem => { return elem.error; })]);
        }
      }
    } catch (error) {
      this.setErrMessages(error);
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async getAirNegoFareConfiguration(configurationId: string) {
    try {
      this.setLoading(true);
      this.setCustomError(false);
      const result = await NegoFaresApi.getAirNegoFareConfiguration(configurationId);
      if (result && result.data) {
        this.setConfiguration(result.data);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        this.setCustomError(true);
      } else {
        this.setErrMessages(error);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(NegoFaresStore);