import { PaymentCardType } from '@/api/profile/payment-cards.model';

export const PaymentCardTypeList = [
  {
    value: PaymentCardType.VisaCredit as PaymentCardType | null,
    name: 'Visa Credit',
  },
  {
    value: PaymentCardType.VisaElectron as PaymentCardType | null,
    name: 'Visa Electron',
  },
  {
    value: PaymentCardType.VisaDebit as PaymentCardType | null,
    name: 'Visa Debit',
  },
  {
    value: PaymentCardType.MasterCardCredit as PaymentCardType | null,
    name: 'MasterCard Credit',
  },
  {
    value: PaymentCardType.MasterCardDebit as PaymentCardType | null,
    name: 'MasterCard Debit',
  },
  {
    value: PaymentCardType.AmericanExpress as PaymentCardType | null,
    name: 'American Express',
  },
  {
    value: PaymentCardType.Maestro as PaymentCardType | null,
    name: 'Maestro',
  },
  {
    value: PaymentCardType.DinersClub as PaymentCardType | null,
    name: 'Diners Club',
  },
  {
    value: PaymentCardType.Discover as PaymentCardType | null,
    name: 'Discover',
  },
  {
    value: PaymentCardType.JapanCreditBureau as PaymentCardType | null,
    name: 'Japan Credit Bureau',
  },
  {
    value: PaymentCardType.ChinaUnionPay as PaymentCardType | null,
    name: 'China Union Pay',
  },
  {
    value: PaymentCardType.UATP as PaymentCardType | null,
    name: 'UATP',
  },
];