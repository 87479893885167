





import { Vue, Component } from 'vue-property-decorator';

import settingsStore from './settings.store';

@Component({})
export default class Settings extends Vue {
  switchActive: boolean = false;

  get currentCompany() {
    return settingsStore.currentCompany;
  }
}

