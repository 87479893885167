









































import { Vue, Component, Watch } from 'vue-property-decorator';

import { Debounce } from '@/core/decorators/debounce.decorator';
import filtersConst from '@/const/filter.const';
import FiltersContentLoading from '../controls/FiltersContentLoading.vue';
import SliderFilter from '../controls/SliderFilter.vue';
import CategoriesFilter from '../controls/CategoriesFilter.vue';
import TrainSearchStore from './train-search.store';
import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';
import { router } from '@/router';
import EventBus from '@/services/event-handler';

@Component({
  components: {
    CategoriesFilter,
    SliderFilter,
    FiltersContentLoading,
  }
})
export default class TrainResultsFilters extends Vue {

  get loading() {
    return TrainSearchStore.loading;
  }

  get filters() {
    return TrainSearchStore.filters;
  }

  get isDisabled() {
    return TrainSearchStore.selectingOffer;
  }

  get currency() {
    return TrainSearchStore.currency;
  }

  get filtersMetadata() {
    const mapper = (item) => {
      return {
        ...item,
        label: translate(item.label),
        type: item.type,
      };
    };
    return {
      categoriesFilters: filtersConst.train.filters.categoriesFilters.map(mapper),
    };
  }

  get shouldHaveOffset() {
    return TrainSearchStore.filtersShouldHaveOffset;
  }

  get filtersOffset() {
    return TrainSearchStore.filtersOffset;
  }

  get globalStyles() {
    if (!this.shouldHaveOffset) {
      return {};
    }
    return {
      top: -this.filtersOffset + 'px',
    };
  }

  get globalClass() {
    return {
      'filters-loading': this.isDisabled,
      'filters-with-offset': this.shouldHaveOffset,
      'md-hidden-mobile': !this.$route.meta.mobile,
      'filters-container': this.$route.meta.mobile,
    };
  }

  get hasFiltersError() {
    return TrainSearchStore.filtersError;
  }

  get categoriesFilters() {
    return this.filtersMetadata.categoriesFilters.map((m) => {
      const data = {
        code: m.code,
        label: m.label,
        type: m.type,
        maxCategoriesInCollapsedMode: m.maxCategoriesInCollapsedMode,
        data: this.getFilterData(m.code),
      };

      if (m.translate && data.data) {
        data.data.forEach(d => {
          if (d.name) {
            d.name = translate('search-train-filters.' + d.code);
          }
        });
      }

      return data;
    });
  }

  filterVisible(filterMetadata) {
    const supplierFilterInvisible = filterMetadata.code === 'SUPPLIER' && !this.$hasAccess('CanSeeProviderName');

    return !supplierFilterInvisible;
  }

  getFilterData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.values : null;
  }

  getFilterTimeData(filterCode) {
    const filter = this.filters.find(f => f.code === filterCode);
    return filter ? filter.data : null;
  }

  convertCategoryFilter(filter) {
    return {
      code: filter.code,
      requiredCategories: filter.data.filter(v => v.selected).map(v => v.code),
    };
  }

  buildFiltersDataPayload() {
    return {
      stringCategoriesFilters: this.categoriesFilters.map(f => this.convertCategoryFilter(f)),
    };
  }

  refreshFiltersComponents(refs) {
    if (!refs) {
      return;
    }
    (refs as any[]).forEach(element => {
      EventBus.$emit('refresh-filter-component', {
        code: element.code,
        data: this.getFilterData(element.code),
      });
    });
  }

  @Watch('filters')
  onChangeFilters() {
    this.refreshFiltersComponents(this.$refs.categoriesFilters);
  }

  callFiltersChanged() {
    const searchId = this.$route.params.searchId;
    const request = {
      filtersData: this.buildFiltersDataPayload(),
      searchId
    };

    TrainSearchStore.setFiltersRequest(request);
    TrainSearchStore.setFiltersChanging(true);
    this.filtersChanged();
  }

  @Debounce({
    delay: 300,
  })
  filtersChanged() {
    this.filterOffersMethod();
  }

  async filterOffersMethod() {
    const requestId = TrainSearchStore.filtersRequestId + 1;
    const searchId = this.$route.params.searchId;
    const legNumber = this.$route.params.leg ? this.$route.params.leg : 1;

    if (!searchId) {
      TrainSearchStore.setFiltersChanging(false);
      return;
    }

    await TrainSearchStore.updateFilterOffers({ searchId });
    TrainSearchStore.setFiltersChanging(false);
    TrainSearchStore.setFiltersChanged(true);
    if (!this.hasFiltersError && requestId === TrainSearchStore.filtersRequestId) {
      TrainSearchStore.getOffers({searchId, legNumber});
    }
  }

  categoriesFilterUpdated(value) {
    const filter: any = this.filters.find((f: any) => f.code === value.code);
    filter.values = value.data;
    this.callFiltersChanged();
  }

  showResults() {
    router.push({ 
      name: 'train',
       params: {
        searchId: this.$route.params.searchId,
        leg: this.$route.params.leg,
      }
    });
  }

  created() {
    if (this.$route.meta.mobile) {
      let searchId = this.$route.params.searchId;
      let legNumber = this.$route.params.leg ? this.$route.params.leg : 1;

      if (searchId === TrainSearchStore.searchId) {
        return;
      }

      if (searchId) {
        TrainSearchStore.getOffers({searchId, legNumber});
      }
    }
  }

}

