




























































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Validation } from 'vue-plugin-helper-decorator';
import { required, requiredIf, maxLength, minLength, email } from 'vuelidate/lib/validators';
import moment from 'moment';

import { Debounce } from '@/core/decorators/debounce.decorator';
import DebounceConst from '@/const/debounce.const';
import EventHandler from '@/services/event-handler';
import { ProfileCompanyApi } from '@/api/profile/company.api';
import { ProfileApi } from '@/api/profile/profile.api';
import { ProfileAgencyApi } from '@/api/profile/agency.api';
import { CompanyDataModel, AvailableDateFormats, AvailableTimeFormats } from '@/api/profile/company.model';
import { Permission } from '@/const/permission.enum';
import SettingsStore from '../settings.store';
import AccountStore from '@/store/account.store';
import { translate } from '@/i18n';
import { LanguagesApi } from '@/api/profile/languages.api';
import { LanguagesModel } from '@/api/profile/languages.model';
import { SearchInitModes, TravellersSelectionModes } from '@/api/profile/profile.model';
import { isGDSRecordLocator } from '@/core/validation/gds-record-locator.validator';

const phoneRegex = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true;
  }
  return /^\+?(?:\d{1,4}?){6,14}\d$/g.test(value);
};

@Component({})
export default class CompanyInfo extends Vue {
  company: CompanyDataModel | null = null;
  parentCompanyOptions: any[] = [];
  isParentCompanyLoading: boolean = false;
  selectedParentCompany: any = null;
  cleanCompanyDisabledStatus: boolean = false;
  formPending: boolean = false;
  loading: boolean = false;
  errors: any[] = [];
  $v;
  agencyAssigned: any | null = null;
  agencyList: any[] = [];
  filterAgencyList: any[] = [];
  filterParentCompanies: any[] = [];

  isAgencyAssignedLoading: boolean = false;
  showShortInfo: boolean = false;
  userCompanyName: string = '';
  userCompanyCode: string = '';
  selectedShortDateFormat: any = null;
  selectedTimeFormat: any = null;
  delayTimer: any | undefined = null;
  languageOptions: LanguagesModel[] = [];
  imagesConst: string = '/assets/img/loader/1.gif';
  loadingPage: boolean = false;

  availableSearchModes = [
    {
      label: translate('settings-company.enter-traveller-names'),
      value: SearchInitModes.EnterNames,
    },
    {
      label: translate('settings-company.book-for-guests'),
      value: SearchInitModes.SelectGuests,
    },
    {
      label: translate('settings-company.book-for-guests-with-no-company-selection'),
      value: SearchInitModes.SelectGuestsWithNoCompanySelection,
    },
  ];
  availableTravellersSelectionModes = [
    {
      label: translate('settings-company.selection-mode-by-scope'),
      value: TravellersSelectionModes.ByScope,
    },
    {
      label: translate('settings-company.selection-mode-by-custom-fields'),
      value: TravellersSelectionModes.ByCustomFields,
    },
    /* remove comment when ByDesignatedTravellers is implemented
    {
      label: translate('settings-company.selection-mode-by-designated-travellers'),
      value: TravellersSelectionModes.ByDesignatedTravellers,
    },
    */
  ];
  availableDateFormats = [
    {
      label: AvailableDateFormats.AvailableDateFormat1 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat1) + ')',     
      value: AvailableDateFormats.AvailableDateFormat1,
    },
    {
      label: AvailableDateFormats.AvailableDateFormat2 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat2) + ')' ,     
      value: AvailableDateFormats.AvailableDateFormat2,
    },
    {
      label: AvailableDateFormats.AvailableDateFormat3 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat3) + ')',     
      value: AvailableDateFormats.AvailableDateFormat3,
    },

    {
      label: AvailableDateFormats.AvailableDateFormat4 + ' (' + moment().format(AvailableDateFormats.AvailableDateFormat4) + ')',    
      value: AvailableDateFormats.AvailableDateFormat4,
    },        
  ];

  availableTimeFormats = [
    {
      label: AvailableTimeFormats.AvailableTimeFormat1 + ' (' + moment().format(AvailableTimeFormats.AvailableTimeFormat1) + ')',
      value: AvailableTimeFormats.AvailableTimeFormat1,
    },
    {
      label: AvailableTimeFormats.AvailableTimeFormat2 + ' (' + moment().format(AvailableTimeFormats.AvailableTimeFormat2) + ')',
      value: AvailableTimeFormats.AvailableTimeFormat2,
    }      
  ];

  @Validation()
  validationObject() {
    return {
      company: {
        name: {
          maxLength: maxLength(64),
          required,
        },
        code: {
          maxLength: maxLength(32),
          required,
        },
        address: {
          maxLength: maxLength(512),
        },
        phoneNumber: {
          required: requiredIf(() => {
            return this.company ? this.company.isAgency : false;
          }),
          minLength: minLength(3),
          maxLength: maxLength(16), 
          phoneRegex,
        },
        faxNumber: {
          maxLength: maxLength(30),
          phoneRegex,
        },
        gdsRecordLocator: {
          maxLength: maxLength(6),
          isGDSRecordLocator,
        },
        invoicingCode: {
          maxLength: maxLength(128)
        },
        emailAddress: {
          required: requiredIf(() => {
            return this.company ? this.company.isAgency : false;
          }),
          maxLength: maxLength(320),
          email,
        },
      },
      selectedShortDateFormat: {
        required,
      },
      selectedTimeFormat: {
        required,
      },
      agencyAssigned: {
        required: (value) => {
          return this.company!.isAgency ? true : !!value;
        }
      },
      selectedSearchMode: {
        required,
      },
      selectedTravellersSelectionMode: {
        required,
      },
      language: {}
    };
  }

  get companyId() {
    return this.$route.params.id;
  }

  get canReadCompanyInfo() {
    return AccountStore.HasPermission('ReadCompanyInfo');
  }

  get canWriteCompanyInfo() {
    return AccountStore.HasPermission('WriteCompanyInfo');
  }

  get canReadAgencyAssignment() {
    return AccountStore.HasPermission('ReadAgencyAssignment');
  }

  get canWriteAgencyAssignment() {
    return AccountStore.HasPermission('WriteAgencyAssignment');
  }

  get isRootCompany() {
    return this.company!.id === this.company!.rootId;
  }

  get isAdministrativeDataModificationEnabled() {
    return this.company && this.company.isAdministrativeDataModificationEnabled;
  }

  get language() {
    if (this.languageOptions) {
      if (this.company && this.company.defaultLanguage) {
        return this.languageOptions.find(option => option.code === this.company!.defaultLanguage);
      } else {
        return this.languageOptions.find(option => option.code === 'en');
      }
    }
  }

  set language(option) {
    if (option && this.company) {
      this.company.defaultLanguage = option.code;
    }
  }

  get selectedSearchMode() {
    if (!this.company) {
      return null;
    }
    return this.availableSearchModes.find(item => item.value === this.company!.defaultSearchMode);
  }

  set selectedSearchMode(value) {
    if (!this.company || !value) {
      return;
    }
    this.company.defaultSearchMode = value.value;
  }

  get selectedTravellersSelectionMode() {
    return this.availableTravellersSelectionModes.find(item => item.value === this.company!.travellersSelectionMode);
  }

  set selectedTravellersSelectionMode(value) {
    if (!this.company || !value) {
      return;
    }
    this.company.travellersSelectionMode = value.value;
  }

  changeParentCompanyId(company) {
    this.company!.parentId = company.id;
    this.focusOnButton();
  }

  tooltip() {
    return `<span>${this.selectedParentCompany.name} (${this.selectedParentCompany.code})</span>`;
  }

  async loadCompany(companyId) {
    try {
      this.errors = [];
      this.formPending = true;
      const response = await ProfileCompanyApi.getCompanyDataById(companyId, Permission.ReadCompanyInfo);
      if (response && response.data) {
        this.company = response.data;
        this.selectedParentCompany = {
          id: this.company.parentId,
          name: this.company.parentCompanyName,
          code: this.company.parentCompanyCode,
          isDisabled: this.company.parentCompanyIsDisabled,
          availableParentCompanies: this.company.availableParentCompanies
        };
        this.company.isDisabled = { value: this.company.isDisabled };

        if (this.company.timeFormat) { 
          this.selectedTimeFormat = this.availableTimeFormats.find(availableTimeFormat => { return availableTimeFormat.value === this.company!.timeFormat; });
        } else {
          this.selectedTimeFormat = this.availableTimeFormats[0];
        }
        if (this.company.shortDateFormat) { 
          this.selectedShortDateFormat = this.availableDateFormats.find(availableDateFormat => { return availableDateFormat.value === this.company!.shortDateFormat; });
        } else {
          this.selectedShortDateFormat = this.availableDateFormats[0];
        }
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
      this.loadingPage = false;
    } finally {
      this.formPending = false;
    }
  }

  searchAgency(phrase: string) {
    this.errors = [];
    this.findAgencyAssigned(phrase);
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isAgencyAssignedLoading',
  })
  async findAgencyAssigned(phrase: string) {
    this.isAgencyAssignedLoading = true;
    try {
      const agencies = await ProfileAgencyApi.getAgencies(phrase);
      if (phrase.length > 0 && agencies && agencies.data && agencies.data.length) {
        this.filterAgencyList = agencies.data;
      } else if (phrase.length === 0 && agencies && agencies.data && agencies.data.length) {
        this.filterAgencyList = agencies.data;
      } else {
        this.filterAgencyList = [];
      }
      this.isAgencyAssignedLoading = false;
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.isAgencyAssignedLoading = false;
    }
  }

  agencyAssignedData() {
    this.agencyAssigned = this.filterAgencyList[0];
    if (this.company && !this.company.parentId) {
      SettingsStore.setAgencyAssigned(null);
    }
  }

  async loadAgencyInfo() {
    try {
      this.loading = true;
      if (this.company && this.company.id) {
        const agencies = await ProfileAgencyApi.getAgencies('');
        if (agencies && agencies.data) {
          this.agencyList = agencies.data;
        }

        this.isAgencyAssignedLoading = true;
        const selectedAgency = await ProfileApi.getAssignedAgency(this.company.id);
        if (selectedAgency && selectedAgency.data && selectedAgency.data !== '') {
          this.agencyAssigned = selectedAgency.data;
          if (!this.company.parentId) {
            SettingsStore.setAgencyAssigned(selectedAgency.data);
          }
        } else {
          this.agencyAssignedData();
        }
        this.isAgencyAssignedLoading = false;
      }
    } catch (error) {
      this.errors = this.$handleErrors(error, true);
      this.loadingPage = false;
    } finally {
      this.loading = false;
      this.loadingPage = false;
    }
  }

  focusOnButton() {
    setTimeout(() => {
      this.$forceUpdate();
      ((this.$refs.saveCompanyInfo as Vue).$refs.button as HTMLElement).focus({preventScroll: true});
    });
  }

  @Watch('$route', { immediate: true, deep: true })
  async onRouteChange() {
    this.loadingPage = true;
    await this.loadLanguages();
    await this.loadCompany(this.companyId);
    await this.loadAgencyInfo();
  }

  @Debounce({
    delay: DebounceConst.defaultDelay,
    flag: 'isParenCompanyLoading'
  })
  async findParentCompanies(query: string) {
    query = query.toLowerCase();
    if (this.selectedParentCompany.availableParentCompanies && this.selectedParentCompany.availableParentCompanies.length) {
      const response = this.selectedParentCompany.availableParentCompanies.filter((agency: any) => agency.name.toLowerCase().includes(query));

      this.filterParentCompanies = response;
    }
  }

  async loadLanguages() {
    if (this.$hasAccess('WriteCompanyInfo')) {
      try {
        const result = await LanguagesApi.getLanguages();
        if (result && result.data) {
          this.languageOptions = result.data;
        }
      } catch (error) {
        this.errors = this.$handleErrors(error, true);
        this.loadingPage = false;
      }
    }
  }

  async saveCompanyInfo() {
    this.$v.company.$touch();
    this.$v.selectedShortDateFormat.$touch();
    this.$v.selectedTimeFormat.$touch();
    if (
      this.$v.company.$anyError ||
      this.$v.selectedShortDateFormat.$anyError ||
      this.$v.selectedTimeFormat.$anyError) {
      return;
    }
    try {
      this.formPending = true;
      this.company!.shortDateFormat = this.selectedShortDateFormat.value;
      this.company!.timeFormat = this.selectedTimeFormat.value;

      const request = {
        ...this.company!,
        isDisabled: this.company!.isDisabled.value,
      } as any;
      await ProfileCompanyApi.updateCompanyDataById(request, this.companyId, Permission.WriteCompanyInfo);
      let obj = {
        type: translate('settings-travel-policy.success'),
        title: translate('common.saved'),
        message: translate('settings-company.company-save-message')
      };
      EventHandler.$emit('show-toast', obj);
      this.loadCompany(this.companyId);

      const result: any = await SettingsStore.getCompanyInitData(this.companyId);
      SettingsStore.setBreadcrumbstCompanyInfo(result.data.breadcrumbs);

    } catch (error) {
      this.errors = this.$handleErrors(error, true);
    } finally {
      this.formPending = false;
    }
  }

  onPhoneBlur() {
    if (!this.company) {
      return;
    }
    this.company.phoneNumber = this.company.phoneNumber.replace(/ /g, '');
  }

  async saveAgencyAssignment() {
    try {
      this.$v.agencyAssigned.$touch();
      if (this.$v.agencyAssigned.$error) {
        return;
      }
      this.loading = true;
      let params = {
        agencyId: this.agencyAssigned.id
      };

      await ProfileApi.assignedAgency(this.company!.id, params);
      const obj = {
        type: translate('common.success'),
        title: translate('common.data-saved'),
        message: translate('common.saved')
      };
      EventHandler.$emit('show-toast', obj);
      await this.loadAgencyInfo();
    } catch (error) {
      this.errors = this.$handleErrors(error);
    } finally {
      this.loading = false;
    }
  }
}
