import Documents from '@/modules/profile/documents/Documents.vue';
import { translate } from '@/i18n';

export default {
  path: 'documents',
  name: 'documents',
  component: Documents,
  displayName: 'profile-document.documents',
  meta: {
    permission: 'ReadProfileDocuments',
    icon: 'document_scanner',
  }
};